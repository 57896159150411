module.exports = function (app) {
   app.directive('textContent',
       function ($compile) {
           return {
               restrict: 'AE',
               link: function (scope, element, attrs) {
                   var linkParams={};
                   var ordinal = attrs.ordinal;
                   var productID = scope.productID;
                   var projectID = scope.projectTypeID;
                   linkParams.productID = scope.productID;
                   linkParams.projectTypeID = scope.projectTypeID;
                   linkParams.genContentID = scope.genContentID;
                   var title = attrs.title;
                   var defaultPage=true;
                   scope.ordinal=attrs.ordinal;

                   processPresentationTextContent();
                   processInDepthReportTextContent();
                   processGlossaryTextContent();
                   processPregnancyGlossaryTextContent();
                   processBMICalculatorTextContent();
                   processTHRCalculatorTextContent();

                   if(defaultPage){
                       processDefaultPage();
                   }

                   $compile(element.contents())(scope);

                   function processPresentationTextContent() {
                       if ((productID === '101' && projectID === '3') || (productID === '13')) {
                           defaultPage=false;
                           scope.subTitles[ordinal - 1] = attrs.title;
                           scope.description[ordinal - 1] = element[0].innerHTML;
                           element.html("");
                           //element.html("<div  data-ng-show='currentPage === " + ordinal + "'>"  +  element[0].innerHTML + "</div>");
                       }
                   }

                   function processInDepthReportTextContent() {
                       if (productID === '10') {
                           defaultPage=false;
                           var selected=false;
                           if (title !== 'Description' && title !== 'Alternative Names') {
                               element.html("<div data-ng-show='sidebar.currentPage === " + ordinal + "' ordinal='" + ordinal + "'><span class='subHeader'>" + attrs.title + "</span>" + element[0].innerHTML + "</div>");

                               if(ordinal === "2"){
                                   scope.sidebar.links.push({
                                       label: "<span adam-side-bar-nav=''>"+attrs.title+"</span>",
                                       onClick: function(){
                                           scope.goToSection(ordinal);
                                       },
                                       selected: selected

                                   });
                               }else{
                                   scope.sidebar.links.push({
                                       label: "<span >"+attrs.title+"</span>",
                                       onClick: function(){
                                           scope.goToSection(ordinal);
                                       },
                                       selected: selected
                                   });
                               }

                           } else {
                               element.html("");
                           }
                       }
                   }

                   function processPregnancyGlossaryTextContent() {
                       if (scope.glossaryPage && scope.genContentID === '000124') {
                           defaultPage=false;
                           var glossaryElement = angular.element(element[0]);
                           var indexes = glossaryElement.find("b");
                           var paragraphs = glossaryElement.find("p");
                           for (var i = 0; i < indexes.length; i++) {
                               var j = indexes[i].innerHTML.charCodeAt(0);
                               var gTitle = indexes[i].innerHTML.substring(0, indexes[i].innerHTML.length - 1);
                               if (scope.letters[j - 65]) {
                                   scope.letters[j - 65].exists = true;
                               }
                               scope.terms.push({ title: gTitle, description: paragraphs[i].innerHTML });
                           }
                           element.html("");
                       }
                   }

                   function processGlossaryTextContent() {
                       if (scope.glossaryPage && scope.genContentID !== '000124') {
                           defaultPage=false;
                           var n = title.charCodeAt(0);
                           if (scope.letters[n - 65]) {
                               scope.letters[n - 65].exists = true;
                           }
                           scope.terms.push({ title: title, description: element[0].innerHTML });
                           element.html("");
                       }
                   }

                   function processDefaultPage() {
                       if (scope.removeTitles.indexOf(title) >= 0) {
                           element.html("");
                       }else if(title === '*IMAGE SERIES'){
                           element.html("<div data-ng-include=\"'pages/wellness/adamImageSeries.html'\"></div>");
                       }
                       else if(title.charAt(0) === '*'){
                           element[0].attributes.title.value="";
                           addClass();
                       }
                       else if (title !== scope.title) {
                           element.html("<h2>" + scope.filterTitle(title) + "</h2>" + element[0].innerHTML);
                       }
                       else{
                           element.html(element[0].innerHTML);

                       }
                   }

                   function processBMICalculatorTextContent(){
                       if( title === "*BMI CALCULATOR"){
                           element.html("<div data-ng-include=\"'pages/wellness/adamBMICalculator.html'\"></div>");
                       }
                   }

                   function processTHRCalculatorTextContent(){
                       if( title === "*THR CALCULATOR"){
                           element.html("<div data-ng-include=\"'pages/wellness/adamTHRCalculator.html'\"></div>");
                       }
                   }

                   function addClass(){
                       element.addClass('noTitle');
                   }
               }
           };
       }
   );
};