module.exports = function(bootstrap) {
    bootstrap.run(['Service', function(Service) {
        new Service(
            {
                name: 'statementArchiveDocument',
                group: 'rbsmbl',
                url: '/rbsmbl/x-services/asp/statementArchive/v2/document',

                fetch: function(docType, effFromDate, effToDate) {
                    return this.get(
                        {
                            docType: docType,
                            effFromDate: effFromDate,
                            effToDate: effToDate
                        }
                    );
                }
            }
        );
    }]);
};