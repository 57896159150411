module.exports = function(app) {
    app.directive('prescriptsCoveredDrugPreAuth', function() {

        return {
            restrict: 'A',
            replace: true,
            template: require('directives/prescriptsCoveredDrugPreAuth.html'),
            controller: ['$scope', 'displayOrHideItemsWrapper', 'finder-logic2', 'coremetrics', 'sessionData', '$state', 'teamsite', 'modal', 'requestAPrintedCopyService',
                function($scope, displayOrHideItemsWrapper, finderLogic, coremetrics, sessionData, $state, teamsite, modal, requestAPrintedCopyService) {
                    $scope.uiModules.drugList = {};
                    var medExceptionLink = {};

                    $scope.cmFormularyLink = function() {
                        coremetrics.elementTag('Search drug lists (Formulary)', 'Member:Prescriptions');
                    };
                    $scope.cmRxReqForm = function() {
                        coremetrics.elementTag('Prescription Drug Medication Request', 'Member:Prescriptions');
                    };
                    $scope.cmMedExceptionReq = function() {
                        coremetrics.elementTag('Medication Exception Request', 'Member:Prescriptions');
                    };
                    $scope.cmDEPharmPolicy = function() {
                        coremetrics.elementTag('Pharmacy Policy', 'Member:Prescriptions');
                    };

                    //Formulary Request Printed Copy
                    teamsite.get('MBR_COV_CRT_YR_PRNT_COPY').then(function(response){
                        $scope.showCurrentPrintedcopy = response;
                        return $scope.showCurrentPrintedcopy;
                    });
                    teamsite.get('MBR_COV_NXT_YEAR_PRNTD_COPY').then(function(response){
                        $scope.showNextPrintedcopy = response;
                        return $scope.showNextPrintedcopy;
                    });
                    $scope.showCurrentYr = false;
                    $scope.showNextYr = false;
                    var currentDate = new Date();
                    $scope.currentYear = currentDate.getFullYear();
                    $scope.nextYear = currentDate.getFullYear()+1;
                    switch (currentDate.getMonth()+1) {
                        case 10:
                        case 11:
                            $scope.showCurrentYr = true;
                            $scope.showNextYr = true;
                            break;
                        case 12:
                            if (currentDate.getDate() <= 7) {
                                $scope.showCurrentYr = true;
                                $scope.showNextYr = true;
                            }else {
                                $scope.showNextYr = true;
                            }
                            break;
                        default:
                            $scope.showCurrentYr = true;
                    }
                    $scope.requestPrintedCopy = function(data) {
                         requestAPrintedCopyService.requestCopy(data).then(function(response){
                            if (response === 'success') {
                                modal.open({
                                      template: require('modals/requestPrintedDrugCoverageModal.html')
                                     });
                            } else {
                                modal.open({
                                    template: require('modals/requestPrintedDrugCoverageErrorModal.html')
                                })
                            }
                         });
                    }
                    //checks if current user has medicareAdvantageflag indicator set to true
                    // teamsite.get('EOC_AND_FORM_GROUPS').then(function (data){
                    //     $scope.EOCGROUP = data;
                    //     var eocGroupNumber = sessionData.memberInfo.clientList[0].groupList[0].groupNumber;
                    //     var shortGroupNumber = eocGroupNumber.slice(1, -1);
                    var isMedicareAdv = sessionData.memberInfo.clientList[0].groupList[0].medicareAdvantageFlag;

                        if(isMedicareAdv){
                            $scope.isEOCFlag = true;
                        }
                    // });

                    var appConfigRequestCopy = (sessionData.appConfig && sessionData.appConfig.PRESCRIPTIONS && sessionData.appConfig.PRESCRIPTIONS.SHOW_EOC_REQUEST_COPY && sessionData.appConfig.PRESCRIPTIONS.SHOW_EOC_REQUEST_COPY.booleanValue) ? true : false;
                    $scope.showRequestCopy = false;

                    if(appConfigRequestCopy === true){
                        $scope.showRequestCopy = true;
                    }

                    var o = $scope.directiveOptions;
                    var myIsMn = (o && o.theBrand && (o.theBrand === 'mincr')),
                        isDe = finderLogic.getPropertyByBrandCode(o.theBrand, 'regionIsDe');
                    var isNd = o.theBrand==='ndbcbs';
                    $scope.isND = isNd;
                    //IBC code change
                    $scope.isIBC = o.theBrand==='ibc';
                    var displayOrHideItemsResponse = displayOrHideItemsWrapper.displayOrHideItemsCall(o.glbClientNumber, o.glbGroupNumber, ['drugFormularyDisplay'], o.theBrand);
                    displayOrHideItemsResponse.then(function(servicePayload) {
                        var showDrugFormulary = (servicePayload.qualifierInfo.drugFormularyDisplay === 'true');
                        $scope.uiModules.drugList = {
                            showThisMod: showDrugFormulary,
                            isMn: myIsMn,
                            isDe: isDe
                        };
                        $scope.showDEMedPolicy = isDe;
                        $scope.isSeniorMarket = finderLogic.hasSeniorProduct() || finderLogic.isOver65();
                        console.log("isSM",$scope.isSeniorMarket);

                    });

                    //for WV Step Therapy Exception Request
                    $scope.showStepTherapyExceptionRequest = false;
                    var theBrandCode = sessionData.memberInfo.brand.toLowerCase();
                    var brandCodeArray = ["hmbcbswv", "ms", "highmarkbcbswv", "mbh:wv", "hmbcbsde", "highmarkbcbsde", "de"];

                    if (brandCodeArray.indexOf(theBrandCode)!== -1 ) {
                        // If the brand is valid for step therapy AND is enabled via app config, then show the link.
                        var smileForHealthConfig = (sessionData.appConfig && sessionData.appConfig.PRESCRIPTIONS && sessionData.appConfig.PRESCRIPTIONS.STEP_THERAPY && sessionData.appConfig.PRESCRIPTIONS.STEP_THERAPY.booleanValue === true) ? true : false;
                        $scope.showStepTherapyExceptionRequest = smileForHealthConfig;
                    }

                    $scope.goToStepTherapyRequestForm = function(){
                        coremetrics.elementTag('Step Therapy', 'Member:Prescriptions');
                        $state.go('prescriptions.step-therapy-exception-form');
                    };

                    $scope.goToMedExceptionRequestForm = function() {
                        coremetrics.elementTag('Medication Exception Request', 'Member:Prescriptions');

                        if (medExceptionLink && medExceptionLink.isStateChange === true) {
                            $state.go(medExceptionLink.state);
                        } else if (medExceptionLink && medExceptionLink.isURL === true) {
                            window.location.href = medExceptionLink.url;
                        }
                    };

                    teamsite.get("MBR_PRESCRIPTION_MEDEXCEPTREQ_LINK").then(function (data) {
                        medExceptionLink = JSON.parse(data);
                    });

                }
            ]
        };
    });
};