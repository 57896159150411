module.exports = function (app) {
    // global vars may have to go here
    app.controller('customerService', ["$state", "$window", "$scope", "teamsite", "$q", "mapServices", "session", "brand", "content", 
    function ($state, $window, $scope, teamsite, $q, mapServices, session, brand, content) {
        
        $scope.headings = "Customer Service";
        $scope.sidebar.links = [];
        var sidebarItems = [];
        teamsite.get("MBR_CONTACTUS_SIDEBAR_LINKS").then(function(sidebarLinks){
           var sidebarContentIds = sidebarLinks.split(",");
           angular.forEach(sidebarContentIds, function(ids){
               sidebarItems.push(teamsite.get(ids.trim()));
           });
            $q.all(sidebarItems).then(function(items){
                angular.forEach(items,function(item){

                    item = JSON.parse(item);

                        var link = {
                            heading: item.heading,
                            label: item.label,
                            state: item.state,
                            href: "",
                            description:  item.description,
                            newWindow: false
                        };                    

                        $scope.sidebar.links.push(link);

                });
            });
        });

        function getContactUsTeamsiteContent(){

            teamsite.get("MBR_CONTACTUS_ONLINE_FORM_SHOW").then(function(response){
                $scope.onlineFormContent = {
                    show: (response === "true")
                }
            });
        };

        function getPurchaseAssistance(){
            var purchaseAssitance = {
                content : teamsite.get("MBR_CONTACTUS_PURCHASEASSISTANCE_CONTENT_TEXT"),
                shopURL : teamsite.get("MBR_CONTACTUS_SHOP_LINK")
            };

            $q.all(purchaseAssitance).then(function(content){
                $scope.purchaseAssistanceContent = {                    
                    content : content.content,
                    shopURL : findShopUri() + content.shopURL
                }
            });            
        }

        function getByPhoneTeamsiteContent(){
            $scope.drawerOpen = false;
            var byPhoneContent = {
                plans: teamsite.get("MBR_CONTACTUS_BYPHONE_PLAN_INFORMATION")
            };

            $q.all(byPhoneContent).then(function(content){
                var plans = [];
                var rows = content.plans.split(";");
                var headers = rows[0].split(",");
                var headersForObject = [];
                for(var headerIndex=0; headerIndex<headers.length; headerIndex++){
                    headersForObject.push(headers[headerIndex].trim().replace(" ","_"));
                };

                for(var index = 1; index<rows.length; index++){
                    var row = rows[index].split(",");
                    var plan = {};
                    plan[headers[0]] = row[0].trim();
                    plan[headers[1]] = row[1].trim();
                    for(var i=2; i<row.length; i++){
                        var planData = {
                            label: headers[i].trim(),
                            value: row[i].trim()
                        }
                        plan[headersForObject[i]] = planData;
                    }

                    plans.push(plan);
                }

                $scope.byPhone = {
                    headers: headersForObject,
                    plans: plans,
                    planTitle:headers[0],
                    phoneNumber: headers[1]
                };

            });
        }

        function getByMailTeamsiteContent(){
            $scope.defaultDrawer = false;
            $scope.showMap = brand.current.sharedBrandCode !== "mincr";

            if(brand.current.sharedBrandCode !== "mincr"){
                var map = [];
                var addresses = {
                    addressJson : teamsite.get("MBR_CONTACTUS_LOCATIONS")
                }

                $q.all(addresses).then(function(content){

                    var response = JSON.parse(content.addressJson);
                    $scope.locations = response;
                    angular.forEach(response,function(addr){
                        var address = {
                            address1:addr.address1,
                            city:addr.city,
                            state:addr.state,
                            zipCode:{
                                base:addr.zipCode.base
                            }
                        };
                        map.push(address);
                    });

                    $scope.markers = [];

                    for(var count=0; count<map.length; count++){
                        var address = mapServices.formatMailingAddressObject(map[count]);
                        mapServices.getLatLngFromFormattedAddress(address,map[count]).then(function(response){
                            var marker = {
                                address : address,
                                latLng : response.latLng
                            }
                            $scope.markers.push(marker);
                        });
                    }

                });
            }

        }

        function getRetailStoreContent(){

            var response = JSON.parse(content.addresses);
            $scope.locations = response;
            $scope.markers = getMarkers(response);
            $scope.retailStore = {
                text: content.text
            }
        };

        function getLocationsContent(){

            var response = JSON.parse(content.addresses);
            $scope.locations = response;
            $scope.markers = getMarkers(response);
            $scope.locationText = content.text;

        }

        function getMarkers(contentResponse){
            var map = [];
            angular.forEach(contentResponse,function(addr){
                var address = {
                    address1:addr.address1,
                    city:addr.city,
                    state:addr.state,
                    zipCode:{
                        base:addr.zipCode.base
                    }
                };
                map.push(address);
            });

            var markers = [];

            for(var count=0; count<map.length; count++){
                var address = mapServices.formatMailingAddressObject(map[count]);
                mapServices.getLatLngFromFormattedAddress(address,map[count]).then(function(response){
                    var marker = {
                        address : address,
                        latLng : response.latLng
                    }
                    markers.push(marker);
                });
            }

            return markers;
        }

        function findShopUri(){
            var uri = "https://shop.highmark.com";

            if($window.location.origin.includes("env2")){
                uri = "https://shoptenv2.highmark.com";
            }else if($window.location.origin.includes("env3")){
                uri = "https://shoptenv3.highmark.com";
            }else if($window.location.origin.includes("env4")){
                uri = "https://shoptenv4.highmark.com";
            }else if($window.location.origin.includes("env6")){
                uri = "https://shoptenv6.highmark.com";
            }else if($window.location.origin.includes("env7")){
                uri = "https://shoptenv7.highmark.com";
            }

            return uri;
        };
        
        if($state.current.name == "customerService"){
            $state.go("customerService.landing");
        }else if($state.current.name == "customerService.landing"){
            getContactUsTeamsiteContent();
        }else if($state.current.name === "customerService.byPhone"){
            getByPhoneTeamsiteContent();
        }else if($state.current.name === "customerService.byMail"){
            getByMailTeamsiteContent();
        }else if($state.current.name === "customerService.retailStore"){
            getRetailStoreContent();
        }else if($state.current.name === "customerService.locations"){
            getLocationsContent();
        }else if($state.current.name === "customerService.purchaseAssistance"){
            getPurchaseAssistance();
        }
        }
    ]);

    return [
        {
            state: "customerService",
            postPassword: false,
            url: "/customerService",         
            views: {
                'body@': {
                    template: bootstrap.templates['page-templates/categoryCustom.html'],
                    controller: "customerService"
                },
                'content@customerService':{
                    template: require('pages/contact-us/contact-us.html')
                }
            },
            resolve:{
                content: ["$q", "$state", function($q, $state){

                }]
            }
        },
        {
            state: "customerService.landing",
            postPassword: false,
            url: "/contact-us",
            views: {
                'body@': {
                    template: bootstrap.templates['page-templates/categoryCustom.html'],
                    controller: "customerService"
                },
                'content@customerService.landing':{
                    template: require('pages/contact-us/contact-us.html')
                }
            }
        },
        {
            state: "customerService.byPhone",
            postPassword: false,
            url: "/by-phone",
            views: {
                'body@': {
                    template: bootstrap.templates['page-templates/categoryCustom.html'],
                    controller: "customerService"
                },
                'content@customerService.byPhone':{
                    template: require('pages/contact-us/byPhone.html')
                }
            }
        },
        {
            state: "customerService.byMail",
            postPassword: false,
            url: "/by-mail",
            views: {
                'body@': {
                    template: bootstrap.templates['page-templates/categoryCustom.html'],
                    controller: "customerService"
                },
                'content@customerService.byMail':{
                    template: require('pages/contact-us/byMail.html')
                }
            }
        },
        {
            state: "customerService.purchaseAssistance",
            postPassword: false,
            url: "/purchaseAssistance",
            views: {
                'body@': {
                    template: bootstrap.templates['page-templates/categoryCustom.html'],
                    controller: "customerService"
                },
                'content@customerService.purchaseAssistance':{
                    template: require('pages/contact-us/purchaseAssistance.html')
                }
            }
        },
        {
            state: "customerService.retailStore",
            postPassword: false,
            url: "/retail-store",
            resolve:{
                content: function(teamsite,$q){
                    var teamsiteContent = {
                        text:teamsite.get("MBR_CONTACTUS_RETAIL_STORE_TEXT"),
                        addresses: teamsite.get("MBR_CONTACTUS_RETAIL_LOCATIONS")
                    }

                    return $q.all(teamsiteContent);
                }
            },
            views: {
                'body@': {
                    template: bootstrap.templates['page-templates/categoryCustom.html'],
                    controller: "customerService"
                },
                'content@customerService.retailStore':{
                    template: require('pages/contact-us/retail-store.html')
                }
            }
        },
        {
            state: "customerService.locations",
            postPassword: false,
            url: "/locations",
            resolve:{
                content : function(teamsite, $q){
                    var teamsiteContent = {
                        text: teamsite.get("MBR_CONTACTUS_LOCATIONS_TEXT"),
                        addresses: teamsite.get("MBR_CONTACTUS_LOCATIONS")
                    }

                    return $q.all(teamsiteContent);
                }
            },
            views: {
                'body@': {
                    template: bootstrap.templates['page-templates/categoryCustom.html'],
                    controller: "customerService"
                },
                'content@customerService.locations':{
                    template: require('pages/contact-us/locations.html')
                }
            }
        }
    ];
};