module.exports = function(app) {
    app.controller('pages.claimsAndSpending.salesDemo', ['sessionData', '$scope', 
    	'$rootScope', '$q', '$cookies', 'claimService', 'services', '$timeout', 
    	'storage', 'coremetrics', '$filter', 'updateVisited', 'session', 'waysToSaveUtil','$window',
        function(sessionData, $scope, $rootScope, $q, $cookies, claimService, services, 
        	$timeout, storage, coremetrics, $filter, updateVisited, session, waysToSaveUtil, $window) {

            $scope.headings = "Claims and Spending - Sales Demo";
            
            var brandCode = sessionData.memberInfo.brand.toLowerCase();

            $scope.changeUrls = function(){
            	var ssoUrl;
            	switch (brandCode){
            		case 'hmbcbs':
            			ssoUrl = "https://highmark-beta2.wealthcareportal.com/Authentication/Handshake";
            			$window.open(ssoUrl, '_blank');
            		break;

            		case 'hbs':
            		case 'pbs':
            			ssoUrl = "https://centralpa-beta2.wealthcareportal.com/Authentication/Handshake";
            			$window.open(ssoUrl, '_blank');
            		break;

            		case 'hmbcbsde':
            		case 'de':
            			ssoUrl = "https://delaware-beta2.wealthcareportal.com/Authentication/Handshake";
            			$window.open(ssoUrl, '_blank');
            		break;

            		case 'hmbcbswv':
            		case 'ms':
            			ssoUrl = "https://westvirginia-beta2.wealthcareportal.com/Authentication/Handshake";
            			$window.open(ssoUrl, '_blank');
            		break;

            		// case 'mincr':
            		// 	var ssoUrl = ;
            		// break;
                    
                    case 'pp':
					case 'az':
					case 'la':
            		case 'fl':
            		case 'neb':
            			ssoUrl = "https://mybenefitshome-beta2.wealthcareportal.com/Authentication/Handshake";
            			$window.open(ssoUrl, '_blank');
            		break;

            		case 'ibc':
            			ssoUrl = "https://alliance-beta2.wealthcareportal.com/Authentication/Handshake";
            			$window.open(ssoUrl, '_blank');
            		break;

            		default:
		                window.alert("Invalid brandCode");
		                break;
            	}
            }
        }
    ]);
};