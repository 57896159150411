
module.exports = function(app) {
    app.directive('pbsTvTopicList', function() {

        return {
            restrict: 'A',
            replace: true,
            template: '<ul data-teamsite="MBR_WELLNESS_PBS_TV_TOPICS_LIST as topicList">' +
            '<li data-ng-repeat="topic in topicList">{{topic}}</li>' +
            '</ul>'

        };
    });
}