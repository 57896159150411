module.exports = function (app) {
    require("./phishing-notification/phishingNotification")(app);

    app.controller("pages.phishingNotification", ["$scope", function ($scope) {

    }]);

    return [
        {
            state: "phishingNotification",
            url: "/phishing-notification",
            postPassword: true,
            views: {
                "body@": {
                    template: require('./phishing-notification/phishingNotification.html'),
                    controller: "pages.phishingNotification.phishingNotification"
                },
            }
        }
    ];
};