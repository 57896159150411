module.exports = function(app) {
    app.directive('downloadReportMessage', ['session', 'teamsite', '$q', function(session, teamsite, $q) {

        return {
            restrict: 'A',
            replace: true,
            template: require('directives/downloadReportMessage.html'),

            scope: {},

            controller: ['$scope', '$cookies', function($scope, $cookies) {

                $scope.shouldShow = !session.data.blueEdgeDentalIndv;
                $scope.heading = 'Download Summary Report';
                $scope.viewState = 'loading';
                $scope.isWYBCBS = $cookies.get('umsBrand').toLowerCase() === 'wybcbs';

                // $scope.message = "<span data-teamsite='MBR_DOWNLOAD_SUMMARY_REPORT_WY'></span>" MBR_Claims_toolTipMarkAsPaid;

                if ($scope.shouldShow) {
                    $scope.viewState = 'success';
                }
            }]
        };
    }]);
}