module.exports = function(app) {
    app.factory('receiptImageDataService', ['$q', 'services',
        function($q, services) {

            var getData = function(getImageRequestPayload) {
                return services.rbsmbl.getShoeboxImage().fetch(getImageRequestPayload).then(
                    function(response) {
                        return response;
                    },
                    function(err) {
                        console.error('fetch failed in getData', err);
                        return err;
                    }
                );
            };

            return {
                getData: getData
            };
        }
    ]);
};