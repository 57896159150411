module.exports = function(bootstrap) {
    bootstrap.run(['Service', 'uuidService', 'appId', function(Service, uuidService, appId) {
        new Service(
            {
                name: 'dependentNotification',
                group: "rbsmbl",
                url: "/rbsmbl/x-services/member/dependentNotification",

                fetch: function(payload) {
                    var
                        uuid,
                        requestPayload;

                    uuid = uuidService.getUUID();

                    requestPayload = {
                        "baseMessage": {
                            "exchangeType": "SENDREQUEST",
                            "headerVersion": "2.2",
                            "uuid": uuid,
                            "username": appId,
                            "businessProcessDifferentiatorId": ["1"]
                        },
                        "payload": payload
                    };

                    return this.post(requestPayload);
                }
            }
        );
    }]);
};