/**
 * Created by lid9aap on 1/13/2016.
 */
module.exports = function (app) {
    app.directive('numberRangeValidation', function () {
        return {
            restrict: "A",
            require: "ngModel",
            link: function (scope, element, attr, ngModel) {
                ngModel.$validators.numberRangeValidation = function (modelValue) {
                    var minValue = attr.min ?  attr.min : scope.min ;
                    var maxValue = attr.max ? attr.max : scope.max ;
                    var bal= scope.accountBal ? parseFloat(scope.accountBal): 0;
                    //console.log("minValue : "+minValue+" maxValue : "+maxValue+" modelValue : "+modelValue);
                    ngModel.$setValidity('greaterThanZeroAccountBalance', bal !== 0);
                    if(bal !== 0){

                      //  ngModel.$setValidity('lessThanOne', modelValue >= minValue);

                        if(bal> scope.remainingBal){
                            ngModel.$setValidity('greaterThanClaimTotal',modelValue <= maxValue );
                        }

                        if(scope.remainingBal > bal){
                            ngModel.$setValidity('greaterThanSpendingAccountBalance',modelValue <= maxValue );
                        }
                    }

                    if (modelValue >= minValue && modelValue <= maxValue) {
                        return true;
                    } else {
                        return false;
                    }

                };
            }
        };
    });
};
