module.exports = function(app) {
    app.directive('wellnessBlog', function() {

        return {
            restrict: 'A',
            replace: true,
            template: require('directives/wellnessBlog.html'),

            // scope: {},
            controller: ['$scope',
                function($scope) {
                   // is $scope.uiMods.theBlog.blogItems is not populated as an array, do that
                    if (!($scope.uiMods.theBlog && $scope.uiMods.theBlog.blogItems && Array.isArray($scope.uiMods.theBlog.blogItems))) {
                   //      var blogItems = localWellnessBlog.getWellnessBlogItems();
                   //      blogItems.then(function (theItems) {
                   //     if (theItems && theItems.articles && theItems.articles.length && (theItems.articles.length > 0)) {
                   //             // ToDo: the isArray test is needed cause the code comes through here twice. Make it only come through here once.
                   //         if (!Array.isArray($scope.uiMods.theBlog.blogItems)) {
                   //             // format date for proper display
                   //             var months = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ]; // Store month names in array
                   //
                   //             var arr = theItems.articles;
                   //             for (var g = 0; g < arr.length; g++) {
                   //                 var d = new Date(arr[g].publishedDate); // Create date object with current date
                   //                 // d.setDate(d.getDate()); // Add 15 days to date object
                   //                 var month = months[d.getMonth()];
                   //                 var day = d.getDate(); // d.getDay() + 6;
                   //                 theItems.articles[g].formattedDate = day + ' ' + month + ' ' + d.getFullYear();
                   //             }
                   //             var maxItems = (theItems.articles.length >= 3) ? 3 : theItems.articles.length;
                   //             var arrTopThree = theItems.articles.splice(0, maxItems);
                   //             arrTopThree[arrTopThree.length - 1].isLast = true;
                   //             arrTopThree[0].isFirst = true;
                   //             $scope.uiMods.theBlog.blogItems = arrTopThree;
                   //         }
                   //     } else {
                   //         if (Array.isArray(theItems.articles)) {
                   //             $scope.uiMods.theBlog.showThis = false;
                   //             return;
                   //         } else {
                   //             $scope.uiMods.theBlog.showThis = false;
                   //             throw new Error('The blog service did not return expected response to ' +
                   //                                 'src/ directives/ wellnessBlog.js')
                   //         }
                   //     }
                   // }
                   //    )
                    }


                }
            ]
        };
    });
}