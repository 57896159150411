module.exports = function(app) {
    app.filter('adamFilter', function() {
        return function(string,title) {
            //adam filter to remove *Close Menu* *Close Menu* Anaphylaxis Anaphylax.... *Close Menu* *Close Menu*
            var result = '';
            if (!title){
                result = string.replace(/\*Close Menu\*/gi, '');
            }else{
                result = string.replace(title+'.', '');
                result = result.replace(/\*Close Menu\*/gi, '');
            }
            return result;
        };
    });
};
