module.exports = function(bootstrap) {
    bootstrap.run(['Service', '$window', function (Service, $window) {
        new Service({
            name: 'userLogin_old',
            group: 'rbsmbl',
            headers: {},
            url: '/rbsmbl/x-services/userInfo/userLogin',
            fetch: function(loginId, loginPwd) {

                if (loginId && loginPwd) {
                    this.headers.Authorization = 'Basic ' + $window.btoa(loginId + ':' + loginPwd.toUpperCase());
                }

                return this.post({});
            }
        });
    }]);
};
