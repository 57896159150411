module.exports = function (app) {
	app.service('memberProfileService', ['$cookies', '$q', 'services', function ($cookies, $q, services) {
		this.getRaces1 = function () {
			var deferred = $q.defer();
			
			deferred.resolve([
				{label: 'American Indian or Alaska Native', value: 'American Indian or Alaska Native'},
				{label: 'Asian', value: 'Asian'},
				{label: 'Black or African American', value: 'Black or African American'},
				{label: 'Native Hawaiian/Other Pacific Islander', value: 'Native Hawaiian/Other Pacific Islander'},
				{label: 'White', value: 'White'},
				{label: 'Other', value: 'Other'},
				{label: 'Choose not to answer', value: 'Choose not to answer'} 
			]);

			return deferred.promise;
		};

		this.getRaces2 = function () {
			var deferred = $q.defer();
			
			deferred.resolve([
				{label: 'American Indian or Alaska Native', value: 'American Indian or Alaska Native'},
				{label: 'Asian', value: 'Asian'},
				{label: 'Black or African American', value: 'Black or African American'},
				{label: 'Native Hawaiian/Other Pacific Islander', value: 'Native Hawaiian/Other Pacific Islander'},
				{label: 'White', value: 'White'},
				{label: 'Other', value: 'Other'}
			]);

			return deferred.promise;
		};

		this.getEthnicities = function () {
			var deferred = $q.defer();

			deferred.resolve([
	            {label: 'Hispanic or Latino', value: 'Hispanic or Latino'},
	            {label: 'Not Hispanic or Latino', value: 'Not Hispanic or Latino'},
	            {label: 'Unknown', value: 'Unknown'},
	            {label: 'Choose not to answer', value: 'Choose not to answer'}
			]);

			return deferred.promise;
		};

		this.getPrimLanguage = function () {
			var deferred = $q.defer();

			deferred.resolve([
	            {label: 'English', value: 'English'},
	            {label: 'Spanish', value: 'Spanish'},
	            {label: 'Other', value: 'Other'},
	            {label: 'Choose not to answer', value: 'Choose not to answer'}  
			]);

			return deferred.promise;
		};

		this.getComfortLang = function () {
			var deferred = $q.defer();

			deferred.resolve([
		        {label: 'English', value: 'English'},
	            {label: 'Spanish', value: 'Spanish'},
	            {label: 'American Sign Language', value: 'American Sign Language'},
	            {label: 'Other', value: 'Other'},
	            {label: 'Choose not to answer', value: 'Choose not to answer'}   
			]);

			return deferred.promise;
		};

		this.getEnglishComfort = function () {
			var deferred = $q.defer();

			deferred.resolve([
		        {label: 'Very well', value: 'Very well'},
	            {label: 'Well', value: 'Well'},
	            {label: 'Not well', value: 'Not well'},
	            {label: 'Not at all', value: 'Not at all'},
	            {label: 'Choose not to answer', value: 'Choose not to answer'}
			]);

			return deferred.promise;
		};

		this.getEnglishComfort = function () {
			var deferred = $q.defer();

			deferred.resolve([
		        {label: 'Very well', value: 'Very well'},
	            {label: 'Well', value: 'Well'},
	            {label: 'Not well', value: 'Not well'},
	            {label: 'Not at all', value: 'Not at all'},
	            {label: 'Choose not to answer', value: 'Choose not to answer'}
			]);

			return deferred.promise;
		};

		this.getEduCompleted = function () {
			var deferred = $q.defer();

			deferred.resolve([
			    {label: '8th grade or less', value: '8th grade or less'},
	            {label: 'Some high school, but did not graduate', value: 'Some high school, but did not graduate'},
	            {label: 'High school graduate or GED', value: 'High school graduate or GED'},
	            {label: 'Some college or 2-year degree', value: 'Some college or 2-year degree'},
	            {label: '4-year college graduate', value: '4-year college graduate'},
	            {label: 'More than 4-year college degree', value: 'More than 4-year college degree'},
	            {label: 'Choose not to answer', value: 'Choose not to answer'} 
			]);

			return deferred.promise;
		};

		this.getDemographicInfo = function () {
			return services.rbsmbl.demographicsInfo().getDemographicInfo();
		};


		this.saveDemographicInfo = function (demographicInfo) {
			var payload = {
				demographicInfo: {
					clientId: $cookies.get('mbrClientNumber'),
					groupId: $cookies.get('mbrGroupNumber'),
					demographicList: demographicInfo
				}
			};
			console.log(payload);
			return services.rbsmbl.demographicsInfo().saveDemographicInfo(payload);
		} ;
	}]);	
};