module.exports = function(app) {
    app.controller('pages.claims.payClaim.confirm', ['$scope', '$state', 'coremetrics', 'services', '$q', 'storage', 'claimService',
        function($scope, $state, coremetrics, services, $q, storage, claimService) {
            if (!$state.params.payFrom) {
                // refresh detected, return user to service selection page
                $state.go('^.select');
                return;
            }

            $scope.headings = 'PAY CLAIM';

            $scope.selectedServices = $state.params.selectedServices || [];
            $scope.payToName = $state.params.payTo.label;
            var payToValue = $state.params.payTo.value;
            $scope.payFromName = $state.params.payFrom;

            $scope.totalPayment = $scope.selectedServices.reduce(
                function(x, y) {
                    return x + parseFloat(y.amountOwed);
                }, 0
            );

            $scope.goBack = function() {
                coremetrics.elementTag('BACK:CLAIMS','MEMBER:CLAIMS');
                $state.go('claims.claimsAndPayments.select', { claimNumber: $state.params.claimNumber }, { location: true });
            };

            $scope.confirm = function() {
                coremetrics.elementTag('CONFIRM:CLAIMS','MEMBER:CLAIMS');
                $scope.isSubmitting = true;
                var toPayDetails = [];
                for (var i = 0; i < $scope.selectedServices.length; i++) {
                    if ($scope.selectedServices[i].acclarisPaymentList && $scope.selectedServices[i].acclarisPaymentList.length > 0) {
                        for (var x = 0; x < $scope.selectedServices[i].acclarisPaymentList.length; x++) {
                            if ($scope.selectedServices[i].acclarisPaymentList[x].status && $scope.selectedServices[i].acclarisPaymentList[x].status.toLowerCase() === "pay now") {
                                break;
                            }
                        }
                        toPayDetails.push(services.dpsext.claimsToPay().fetch($state.params.claim.umi13, $state.params.claim.clientId, $scope.selectedServices[i].acclarisPaymentList[x].acclarisClaimID));
                    }
                }
                $q.all(toPayDetails).then(function(toPayResponse) {
                    coremetrics.pageviewTag('CLAIMS:PAY CLAIM COMPLETE','MEMBER:CLAIMS');
                    var claimsPayload = [];
                    for (var z = 0; z < toPayResponse.length; z++) {
                        var toPayData = toPayResponse[z].data.repGetClaimsToPayDetailsReply.payload.repGetClaimsToPayDetailsData;
                        var payMode = payToValue;
                        var claimsObj = {
                            "highmarkClaimNumber": toPayData.externalCode, //done
                            "highmarkClaimLine": $scope.selectedServices[z].lineNumber,
                            "umi": $state.params.claim.umi13, // done
                            "clientNumber": $state.params.claim.clientId, //done
                            "externalId":  toPayData.externalCode, //done
                            "externalLineId": $scope.selectedServices[z].lineNumber,
                            "pendingClaimNumber": toPayData.pendingClaimID, // done
                            "payMode": payMode, //done
                            "actpCD": toPayData.accountList[0].labelAssociatedValue, // done
                            "groupCode": $state.params.claim.clientId, // done
                            "amount": toPayData.claimAmount //done
                        };
                        if ((payMode.toLowerCase() == "other") || (payMode.toLowerCase() == "provider")) {
                            // Only pass payEEID attribute to the service if the pay to is 'other' (provider)
                            claimsObj.payeeID = toPayData.payeeID;
                            claimsObj.payMode = "Other";
                        }
                        claimsPayload.push(claimsObj);
                    }
                    services.mhsimb.submitClaims().fetch(claimsPayload).then(function () {
                        $scope.isSubmitted = true;
                        services.rbsmbl.claimSummary().flushCache();
                        claimService.getSummary(storage.get('claimRefineDateRange'));
                        services.rbsmbl.claimDetail().flushCache();
                        claimService.getDetails($state.params.claim);
                    }, function() {
                        $scope.isSubmitted = true;
                        services.rbsmbl.claimSummary().flushCache();
                        claimService.getSummary(storage.get('claimRefineDateRange'));
                        services.rbsmbl.claimDetail().flushCache();
                        claimService.getDetails($state.params.claim);
                    });
                });
                coremetrics._conversionEventTagWithAttrs(2, "PAY CLAIM PROCESS", "MEMBER:CLAIMS", "CLAIMS:PAY CLAIM COMPLETE");
            };

            $scope.close = function() {
                coremetrics.elementTag('CLOSE:CLAIMS','MEMBER:CLAIMS');
                $state.go('claims.claimsAndPayments');
            };
        }
    ]);
};