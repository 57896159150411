/**
 * Created by lid3ip9 on 06/25/2017.
 */
module.exports = ['$scope', '$http', 'coremetrics', function($scope, $http, coremetrics) {

    $scope.custom = true;
    //Vendor replaced from Retrofit to  Livongo
    $scope.connectToLivongo = function(theTarget, isHref){
        coremetrics.elementTag('LIVONGO LINK ', 'MEMBER:WELLNESS:DIABETES PREVENTION PROGRAM');
        $scope.myCloseModal();
        if (isHref) {
            window.open(theTarget);

        }
    };

    $scope.myCloseModal = function() {
        this.closeModal();
    };

}];