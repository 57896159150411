module.exports = function (app) {

    var prenatalPlusSuccessModal = require("../../modals/prenatalPlusSuccessModal");
    var prenatalPlusErrorModal = require("../../modals/prenatalPlusErrorModal");

    app.controller("pages.prenatal.prenatalPlusForm", ["$state", "$timeout", "storage", "$scope", "services", "teamsite", "$q","session","sessionData","$cookies", "modal", "$window", "coremetrics",
        function ($state, $timeout, storage, $scope, services, teamsite, $q, session, sessionData, $cookies, modal, $window, coremetrics) {
            
            coremetrics.pageviewTag('MEMBER:MEMBER PRENATAL:PRENATAL PLUS FORM', 'MEMBER:MEMBER PRENATAL');

            var _messagePlans = { "messagePlans": [] };
            var arrProdNames = [];
            var ahPartner = 'None',
            temp = '',
            medicare = 'false',
            medigap ='false',
            blueExtraBasic='false',
            blueExtraPlus ='false',
            isPPO ='false',
            isHMO  = 'false',
            _memberInfoValues,
            _filterOutServicePlans;
            
            $scope.openCalendar = function($event,item) {
                $event.preventDefault();
                modal.open({
                    heading: 'SELECT A DATE',
                    classes: "prenatalCalendarModal",
                    scope: $scope,
                    controller: ["$scope", function ($scope) {
                        if(item == "estimatedDueDate"){
                            $scope.calendarDate = $scope.estimatedDueDate;
                        }else{
                            $scope.calendarDate = $scope.lastMenstrualPeriod;
                        }
                        $scope.save = function () {
                            if(item == "estimatedDueDate"){
                                $scope.estimatedDueDate = $scope.calendarDate;
                            }else{
                                $scope.lastMenstrualPeriod = $scope.calendarDate;
                            }
                            
                            $scope.closeModal();
                        };
                    }],
                    template:   '<div class="prenatalDateCalendar">' +
                                    '<div data-calendar="calendarDate"></div>' +
                                '</div>' +
                                '<div class="margin-top text-right">' +
                                    '<button data-ng-click="closeModal()" class="secondary">CANCEL</button> <button data-ng-click="save()" class="primary">OK</button>' +
                                '</div>'
                });
            };
            //if dates equal return 0; if compareDate greater than Date return 1; if compareDate less than Date then return -1
            var dateCompare = function(compareDate, endDate) {
                while(compareDate.indexOf("/") != -1) {
                    compareDate = compareDate.replace("/", "-");
                }
                while(endDate.indexOf("/") != -1) {
                    endDate = endDate.replace("/", "-");
                }
                var compDt = new Date(compareDate).getTime();
                var dt = new Date(endDate).getTime();

                if(compDt > dt) return 1;
                if(compDt == dt) return 0;
                if(compDt < dt) return -1;
            };
            /*end*/
            _memberInfoValues = function(sessionData){
                for (var iClntIdx = 0; iClntIdx < sessionData.memberInfo.clientList.length; ++iClntIdx) {
                    var objClnt = sessionData.memberInfo.clientList[iClntIdx];
                    for (var iGrpIdx = 0; iGrpIdx < objClnt.groupList.length; ++iGrpIdx) {
                        var objGrp = objClnt.groupList[iGrpIdx];
                        if(objGrp.planPartnerCode==='373' || objGrp.planPartnerCode==='374')
                            ahPartner='AHNJ';
                        else if(objGrp.planPartnerCode==='375')
                            ahPartner='AHPA';

                        if(objGrp.medicareIndicator  === 'true')
                            medicare =    'true';

                        if(objGrp.medigapIndicator==='true')
                            medigap   =   'true';

                        for (var iPrdIdx = 0; iPrdIdx < objGrp.productList.length; ++iPrdIdx) {
                            var objPrd = objGrp.productList[iPrdIdx];
                            if(objPrd.isBlueExtraBasic==='true')
                                blueExtraBasic =  'true';
                            if(objPrd.isBlueExtraPlus==='true')
                                blueExtraPlus =   'true';
                            if(objPrd.lob  === 'PPO')
                                isPPO =   'true';
                            if(objPrd.lob  === 'HMO')
                                isHMO =   'true';
                            //check benefitPlanType to determine icon used
                            var benefitPlanIcon;
                            if (objPrd.benefitPlanType == "Medical"){
                                benefitPlanIcon = "<span class='hm-icon icon-provider'></span>";
                            }
                            if (objPrd.benefitPlanType == "Vision"){
                                benefitPlanIcon = "<span class='hm-icon icon-vision'></span>";
                            }
                            if (objPrd.benefitPlanType == "Dental"){
                                benefitPlanIcon = "<span class='hm-icon icon-dental'></span>";
                            }
                            if (objPrd.benefitPlanType == "Wellness Programs"){
                                benefitPlanIcon = "<span class='hm-icon icon-nutrition'></span>";
                            }
                            if (objPrd.benefitPlanType == "Spending Accounts"){
                                benefitPlanIcon = "<span class='hm-icon icon-cost'></span>";
                            }
                            if (objPrd.benefitPlanType == "Drug"){
                                benefitPlanIcon = "<span class='hm-icon icon-pharmacy'></span>";
                            }

                            //get proper plan name for display
                            var planNameDisplay = "";
                            if(objGrp.hiosName != "" && objGrp.hiosName != null) {
                                planNameDisplay = objGrp.hiosName;
                                if(objGrp.qhpLevel != null && objGrp.qhpLevel != "")
                                    planNameDisplay = planNameDisplay + " " + objGrp.qhpLevel;
                            }
                            else {
                                planNameDisplay = objPrd.benefitPlanName;
                            }
                                
                            for (var iMbrIdx = 0; iMbrIdx < objPrd.memberList.length; ++iMbrIdx) {
                                var objMbr = objPrd.memberList[iMbrIdx];         
                            
                                if(objPrd.coverageBeginDate != null && objPrd.coverageBeginDate != "" && (objMbr.coverageCancellationDate == null || objMbr.coverageCancellationDate == ""))
                                    var strPrdName = benefitPlanIcon + '<strong>' + planNameDisplay + '</strong> <div>(' + objPrd.coverageBeginDate + ' - ' + objPrd.coverageEndDate + ')</div>';
                                else if (objPrd.coverageBeginDate != null && objPrd.coverageBeginDate != "" && objMbr.coverageCancellationDate != null && objMbr.coverageCancellationDate != "" 
                                    && (dateCompare(objMbr.coverageCancellationDate, objPrd.coverageEndDate) < 1))
                                    var strPrdName = benefitPlanIcon + '<strong>' + planNameDisplay+ '(Cancelled)</strong> <div>(' + objPrd.coverageBeginDate + ' - ' + objMbr.coverageCancellationDate + ')</div>';
                                else if ((objPrd.coverageBeginDate == null || objPrd.coverageBeginDate == "") && (objMbr.coverageCancellationDate != null && objMbr.coverageCancellationDate != "")
                                        && (dateCompare(objMbr.coverageCancellationDate, objPrd.coverageEndDate) < 1))
                                    var strPrdName = benefitPlanIcon + '<strong>' + planNameDisplay  + '(Cancelled)</strong> <div>(' + objMbr.coverageCancellationDate + ')</div>';
                                else
                                    var strPrdName = benefitPlanIcon + '<strong>' + planNameDisplay  + '</strong> <div>(' + objPrd.coverageEndDate + ')</div>';
                            }

                            if (arrProdNames.indexOf(strPrdName) == -1) {    
                                //If Blue Extra and Medical, then do not show the plan. Else follow BAU
                                if(!((objPrd.isBlueExtraBasic ===  'true'  ||  objPrd.isBlueExtraPlus  === 'true')
                                            && objPrd.benefitPlanType   === 'Medical')){
                                        // if there is more than one plan, we need the user to make a selection so that the progressive display can proceed.  So, we need to remove the default selection, which happens below.
                                        _messagePlans.messagePlans.push({
                                            "id": objPrd.lob + objGrp.groupNumber + objPrd.productNumber + '_' + iPrdIdx,
                                            "label": strPrdName,
                                            "value": objPrd.lob + objGrp.groupNumber + objPrd.productNumber + '_' + iPrdIdx,
                                            "checked": (_messagePlans.messagePlans.length < 0)
                                        });
                                }

                                arrProdNames.push(strPrdName);
                            }

                        }
                    }
                }
                 if(arrProdNames.length == 1){
                    _messagePlans.messagePlans[0].checked = true;
                }
                return  _filterOutServicePlans(_messagePlans.messagePlans);
            }
            _filterOutServicePlans = function(plans){
                if (plans.length == 0){
                    $scope.prenatalPlusFormShow = false;
                }
                else{
                    $scope.prenatalPlusFormShow = true;
                }
                var filteredPlans = [];
                for (var i = 0; i < plans.length; i++){
                    //If contains service splice it out
                     if(plans[i].label.indexOf('Service -') == -1)
                    {
                       filteredPlans.push(plans[i])
                    }
                }
                // If filtered plans have more than 0 show that list
                if (filteredPlans.length>0) {
                    return filteredPlans;
                }
                //else show original list
                else {
                    return plans;
                }   
            }
            $scope.planData = _memberInfoValues(sessionData);
            
            var selectedPlanValue = "";
            $scope.prenatalMember = "";
            $scope.bestTimeToContact = "";
            $scope.bestNumberToContact = "";
            $scope.leaveMessage = "";
            $scope.estimatedDueDate = "";
            $scope.lastMenstrualPeriod = "";
            $scope.doctorName = "";
            $scope.doctorNumber = "";
            $scope.doctorCity = "";
            $scope.doctoryZipCode = "";
            $scope.doctorState = "";
            $scope.hospitalName = "";
            $scope.hospitalCity = "";
            $scope.hospitalState = "";
            $scope.hospitalZipCode = "";
            $scope.isSubmit = false;

            $scope.doctorStateValue = function(selectedItem){
                if(selectedItem !== undefined){
                    $scope.doctorState = selectedItem;
                }else{
                    $scope.prenatalForm.doctorState.$setValidity('required', false);
                    $scope.doctorState = "";
                }
            }

            $scope.hosptialStateValue = function(selectedItem){
                if(selectedItem !== undefined){
                    $scope.hospitalState = selectedItem;
                }else{
                    $scope.prenatalForm.hospitalState.$setValidity('required', false);
                    $scope.hospitalState = "";
                }
            }
            $scope.prenatalFormReset = function(form){
                if(form){
                    $scope.prenatalMember = "";
                    $scope.bestTimeToContact = "";
                    $scope.bestNumberToContact = "";
                    $scope.leaveMessage = "";
                    $scope.estimatedDueDate = "";
                    $scope.lastMenstrualPeriod = "";
                    $scope.doctorName = "";
                    $scope.doctorNumber = "";
                    $scope.doctorCity = "";
                    $scope.doctoryZipCode = "";
                    $scope.doctorState = "";
                    $scope.hospitalName = "";
                    $scope.hospitalCity = "";
                    $scope.hospitalState = "";
                    $scope.hospitalZipCode = "";
                    $scope.selectedPlan = "";
                    form.$setPristine();
                    form.$setUntouched();
                    $scope.prenatalForm.doctorState.$setValidity('required', false);
                    $scope.prenatalForm.hospitalState.$setValidity('required', false);
                }
            }

            $scope.prenatalFormSubmit = {
                submit: function(form) {
                    //console.log(form.$valid);
                    if($scope.prenatalForm.$invalid){
                        
                    }else{
                        var planValue = $scope.selectedPlan;
                        for(var i = 0; i < $scope.planData.length; i++) {
                            if($scope.planData[i].value == planValue) {
                                selectedPlanValue = $scope.planData[i].value;
                                break
                            }
                        }
                        var selectedClientIndex,
                        prodIndex,
                        selectedPlanObjArrIndex;
                        var selectedProduct = {};
                        var selectedPlanObjId = '';
                        var selectedGroup = 0;
                        var selectedPlan = 0;
                        if (selectedPlanValue) {
                            var valueArr = selectedPlanValue.split("_");
                            selectedPlanObjId = valueArr[0];
                            selectedPlanObjArrIndex = valueArr[1];
                            prodIndex = selectedPlanObjArrIndex;
                            //get selected client
                            for (var iClntIdx = 0; iClntIdx < sessionData.memberInfo.clientList.length; ++iClntIdx) {
                                var objClnt = sessionData.memberInfo.clientList[iClntIdx];
                                for (var iGrpIdx = 0; iGrpIdx < objClnt.groupList.length; ++iGrpIdx) {
                                    var objGrp = objClnt.groupList[iGrpIdx];
                                    for (var iPrdIdx = 0; iPrdIdx < objGrp.productList.length; ++iPrdIdx) {
                                        var objPrd = objGrp.productList[iPrdIdx];
                                        if (selectedPlanObjId && selectedPlanObjId === objPrd.lob + objGrp.groupNumber + objPrd.productNumber && parseInt(selectedPlanObjArrIndex) === iPrdIdx) {
                                            selectedProduct = objPrd;
                                            selectedClientIndex = iClntIdx;
                                            break;
                                        }
                                    }
                                }
                            }
                            //get selected group and product
                            for (var i = 0; i < sessionData.memberInfo.clientList[selectedClientIndex].groupList.length; i++) {
                                for (var j = 0; j < sessionData.memberInfo.clientList[selectedClientIndex].groupList[i].productList.length; j++) {
                                    var matchValue1 = sessionData.memberInfo.clientList[selectedClientIndex].groupList[i].productList[j].lob + sessionData.memberInfo.clientList[selectedClientIndex].groupList[i].groupNumber+sessionData.memberInfo.clientList[selectedClientIndex].groupList[i].productList[j].productNumber + '_' + j;
                                    var matchValue2 = selectedPlanValue;
                                    if (matchValue1 === matchValue2) {
                                        selectedGroup = i;
                                        selectedPlan = j;
                                        break;
                                    }
                                }
                            }
                            var selectedMember = 0;
                            var memberECID = sessionData.memberInfo.clientList[selectedClientIndex].groupList[selectedGroup].productList[selectedPlan].memberList[0].ecId;
                            for (var d = 0; d < sessionData.memberInfo.clientList[selectedClientIndex].groupList[selectedGroup].productList[selectedPlan].memberList.length; d++) {
                                if(sessionData.memberInfo.clientList[selectedClientIndex].groupList[selectedGroup].productList[selectedPlan].memberList[d].ecId === memberECID){
                                        selectedMember = d;
                                        break;
                                    }
                            }
                            var coverageStartDate = sessionData.memberInfo.clientList[selectedClientIndex].groupList[selectedGroup].productList[selectedPlan].coverageBeginDate;
                            if(coverageStartDate == null || coverageStartDate == ""){
                                coverageStartDate = sessionData.memberInfo.clientList[selectedClientIndex].groupList[selectedGroup].productList[selectedPlan].coverageConBeginDate;
                            }
                        }
                        if (selectedPlanValue != "" && $scope.prenatalMember != "" && $scope.bestTimeToContact != "" && $scope.bestNumberToContact != "" && $scope.leaveMessage != "" && $scope.estimatedDueDate != "" && $scope.lastMenstrualPeriod != "" && $scope.doctorName != "" && $scope.doctorNumber != "" && $scope.doctorCity != "" && $scope.doctoryZipCode != "" && $scope.doctorState != "" && $scope.hospitalName != "" && $scope.hospitalCity != "" && $scope.hospitalState != "" && $scope.hospitalZipCode != "") {
                            $scope.isSubmit =true;
                            var formData = {
                                    "baseMessage": {
                                    "headerVersion": "2.2",
                                    "sentTS": 1526571739937
                                },
                                "payload": {
                                    "agreementId": sessionData.memberInfo.agreementId,
                                    "conversationID": "",
                                    "ecId": sessionData.memberInfo.clientList[selectedClientIndex].groupList[selectedGroup].productList[selectedPlan].memberList[selectedMember].ecId,
                                    "umi": sessionData.memberInfo.umi,
                                    "coveredIndividualId": sessionData.memberInfo.clientList[selectedClientIndex].groupList[selectedGroup].productList[selectedPlan].memberList[selectedMember].coveredIndividualId,
                                    "clientNumber": sessionData.memberInfo.clientList[selectedClientIndex].clientNumber,
                                    "clientName": sessionData.memberInfo.clientList[selectedClientIndex].clientName,
                                    "groupNumber": sessionData.memberInfo.clientList[selectedClientIndex].groupList[selectedGroup].groupNumber,
                                    "productNumber": sessionData.memberInfo.clientList[selectedClientIndex].groupList[selectedGroup].productList[selectedPlan].productNumber,
                                    "benefitPlanType": sessionData.memberInfo.clientList[selectedClientIndex].groupList[selectedGroup].productList[selectedPlan].benefitPlanType,
                                    "benefitPlanName": sessionData.memberInfo.clientList[selectedClientIndex].groupList[selectedGroup].productList[selectedPlan].benefitPlanName,
                                    "bdUserId" : 'B'+ sessionData.memberInfo.bvUserId,
                                    "clientId" : sessionData.memberInfo.clientList[selectedClientIndex].clientId,
                                    "internalGroupNumber" : sessionData.memberInfo.clientList[selectedClientIndex].groupList[selectedGroup].internalGroupNumber,
                                    "firstName": sessionData.memberInfo.clientList[selectedClientIndex].groupList[selectedGroup].productList[selectedPlan].memberList[selectedMember].contact.firstName,
                                    "lastName": sessionData.memberInfo.clientList[selectedClientIndex].groupList[selectedGroup].productList[selectedPlan].memberList[selectedMember].contact.lastName,
                                    "middleName": sessionData.memberInfo.clientList[selectedClientIndex].groupList[selectedGroup].productList[selectedPlan].memberList[selectedMember].contact.middleName,
                                    "suffix": sessionData.memberInfo.clientList[selectedClientIndex].groupList[selectedGroup].productList[selectedPlan].memberList[selectedMember].contact.suffix,
                                    "birthDate": sessionData.memberInfo.clientList[selectedClientIndex].groupList[selectedGroup].productList[selectedPlan].memberList[selectedMember].contact.birthDate,
                                    "relationshipCode": sessionData.memberInfo.clientList[selectedClientIndex].groupList[selectedGroup].productList[selectedPlan].memberList[selectedMember].relationshipCode,
                                    "messageID": "MGC-WNP-NDBCBS",
                                    "direction": "inbound",
                                    "messageSource": "Member Portal",
                                    "subject": "Wellness Programs",
                                    "phoneNumber": $scope.bestNumberToContact,
                                    "callTimePreference": $scope.bestTimeToContact,
                                    "leaveMessageInd": $scope.leaveMessage,
                                    "emailAddress": "",
                                    "notificationMethod": "",
                                    "notificationAddress": "",
                                    "createIdentifier": "mbr",
                                    "dcn": "",
                                    "languageCode": "",
                                    "channelCode": "IS",
                                    "gender": sessionData.memberInfo.clientList[selectedClientIndex].groupList[selectedGroup].productList[selectedPlan].memberList[selectedMember].gender,
                                    "enrollmentSourceCode": sessionData.memberInfo.clientList[selectedClientIndex].enrollmentSourceCode,
                                    "brand": sessionData.memberInfo.brand,
                                    "lob": sessionData.memberInfo.clientList[selectedClientIndex].groupList[selectedGroup].productList[selectedPlan].lob,
                                    "alphaPrefix": sessionData.memberInfo.clientList[selectedClientIndex].groupList[selectedGroup].productList[selectedPlan].alphaPrefix,
                                    //"coverageBeginDate": msgUser.payload.clientList[selectedClientIndex].groupList[selectedGroup].productList[selectedPlan].coverageBeginDate,
                                    "coverageBeginDate": coverageStartDate,
                                    "coverageEndDate": sessionData.memberInfo.clientList[selectedClientIndex].groupList[selectedGroup].productList[selectedPlan].coverageEndDate,
                                    "externalSystemIdentifier": "",
                                    "messages": [
                                      {
                                        "message": "Prenatal Plus Survey"
                                      }
                                    ],
                                    "attachments": [],
                                    "prenatalPlusForm": {
                                        "memberName": $scope.prenatalMember,
                                        "bestTimeToBeContacted": $scope.bestTimeToContact,
                                        "bestPhoneNumberToBeContacted": $scope.bestNumberToContact,
                                        "estimatedDueDate": $scope.estimatedDueDate.toLocaleDateString(),
                                        "lastMenstrualPeriodDate": $scope.lastMenstrualPeriod.toLocaleDateString(),
                                        "doctorName": $scope.doctorName,
                                        "doctorPhoneNumber": $scope.doctorNumber,
                                        "doctorCity": $scope.doctorCity,
                                        "doctorState": $scope.doctorState,
                                        "doctorZip": $scope.doctoryZipCode,
                                        "hospitalName": $scope.hospitalName,
                                        "hospitalCity": $scope.hospitalCity,
                                        "hospitalState": $scope.hospitalState,
                                        "hospitalZip": $scope.hospitalZipCode
                                    }
                                }
                            };
                            services.rbsmbl.prenatalFormService().fetch(formData).then(function(response){
                                $scope.isSubmit = false;
                                $timeout(function() {
                                    angular.element('#prenatalReset').trigger('click');
                                }, 0);
                                $scope.prenatalFormReset();
                                //console.log('response: '+JSON.stringify(response));
                                if(response !== null && response !== "" && response !== "undefined"){
                                    modal.open({
                                        template: require('modals/prenatalPlusSuccessModal.html'),
                                        controller: prenatalPlusSuccessModal,
                                        scope: $scope
                                    });
                                }else{
                                    modal.open({
                                        template: require('modals/prenatalPlusErrorModal.html'),
                                        controller: prenatalPlusErrorModal,
                                        scope: $scope
                                    });
                                }
                            }, function(error){
                                 modal.open({
                                    template: require('modals/prenatalPlusErrorModal.html'),
                                    controller: prenatalPlusErrorModal,
                                    scope: $scope
                                });
                                $scope.isSubmit = false;
                                $scope.isSuccess = false;
                            });
                        }else{
                            return;
                        }
                    }
                }
            }
        }
    ]);
};
