module.exports = function (app) {
   app.directive('versionInfo',
       function () {
           return {
               restrict: 'EA',
               scope: false,
               link: function (scope, element, attrs) {
                   scope.reviewdate=attrs.reviewdate;
                   scope.reviewedby=attrs.reviewedby;
               }
           };
       }
   );
};