module.exports = function(bootstrap) {
    bootstrap.run(['Service', function (Service) {
        new Service({
            name: 'exactTarget',
            group: 'cmcrst',
            url: '/cmcrst/x-services/exactTarget',
            fetch: function(memberInfo) {
                var requestPayload = {
                    baseMessage: {
                        headerVersion: '1.0',
                        payloadVersion: '2.0',
                        additionalInfo: null
                    },
                    payload: {
                        member: false,
                        deliveryRecipient: memberInfo.email,
                        deliveryMethod: 'email'
                    }
                };

                var templateName;
                var emailRegionName;
                var emailRegion;
                var date = new Date();
                var formattedDate = date.getMonth() + 1 + '/' + date.getDate() + '/' + date.getFullYear();

                if (memberInfo.market === 'VMED') {
                    var brand = memberInfo.brand.toLowerCase();

                    switch (brand) {
                        case 'hmbcbs':
                            templateName = 'SNRRegistrationHMBCBS';
                            emailRegionName = 'Blue Cross Blue Shield';
                            emailRegion = 'bcbs';
                        break;

                        case 'hbs':
                            templateName = 'SNRRegistrationPBS';
                            emailRegionName = 'Blue Shield';
                            emailRegion = 'pbs';
                        break;

                        case 'mbh:wv':
                            templateName = 'SNRRegistrationWV';
                            emailRegionName = 'Blue Cross Blue Shield West Virginia';
                            emailRegion = 'wv';
                        break;

                        case 'de':
                            templateName = 'SNRRegistrationDE';
                            emailRegionName = 'Blue Cross Blue Shield Delaware';
                            emailRegion = 'de';
                        break;

                        default:
                            templateName = 'WelcometoHighmarkPBS';
                    }

                    requestPayload.payload.etAttributes = [
                        {
                            name : 'Region',
                            value : emailRegion
                        },
                        {
                            name : 'Region Name',
                            value : emailRegionName
                        },
                        {
                            name : 'Account Creation Date',
                            value : formattedDate
                        },
                        {
                            name : 'Login',
                            value : memberInfo.loginId
                        },
                        {
                            name : 'Highmark Representative Contact Number',
                            value : 'Customer Service Number'
                        },
                        {
                            name : 'Representative Availability Time',
                            value : 'Customer Service Hours'
                        }
                    ];
                }

                requestPayload.payload.templateName = templateName || 'WelcometoHighmarkPBS';

                return this.post(requestPayload);
            }
        });
    }]);
};
