module.exports = function(app) {
    app.directive('adamContent',
        function($window,$location,$http,$compile) {
            return {
                restrict: 'EA',
                controller : ['$scope','$element','$attrs', function ($scope,$element,$attrs) {
                    var title=$attrs.title;
                    title=$scope.filterTitle(title);
                    title=title.toLowerCase();
                    if(title.indexOf("glossary")>=0){
                        $scope.glossaryPage=true;
                    }
                    $scope.title=title;
                    $scope.glossaryPageURI = bootstrap.buildURI('/bootstrap/{version}/articles/providers/glossary') + 'glossary.html';
                }]
            };
        }
    );
};