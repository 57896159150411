module.exports = function (app) {
	var _enabledCostSavingTools = [
		"MBR_SPENDING_CST_CARE_COST_ESTIMATOR",
		"MBR_SPENDING_CST_RX_COST_COMPARISON",
		"MBR_SPENDING_CST_SAVINGS_OPPORTUNITIES",
		"MBR_SPENDING_CST_CASLIGHT"
	];

	app.controller('pages.spending.costSavingTools', ['cceService', 'contentResourceServices', 'resourcesList', 'rxccService', 'savOppService',  'services', 'sessionData', '$scope', '$state', '$q', 'modal', 'brand','finder-logic2', '$cookies', '$window',
		function (cceService, contentResourceServices, resourcesList, rxccService, savOppService,  services, sessionData, $scope, $state, $q, modal, brand, finderLogic, $cookies, $window) {
            window.document.title = "Cost saving tools";
		$scope.isVitalsActive = (sessionData && sessionData.appConfig && sessionData.appConfig.PROVIDER &&
			sessionData.appConfig.PROVIDER.VITALS_LIVE && sessionData.appConfig.PROVIDER.VITALS_LIVE.booleanValue == true)
			&& (brand.current.isVitalsActive);
			$scope.isVitalsLiveCCE =  (sessionData.appConfig && sessionData.appConfig.PROVIDER && sessionData.appConfig.PROVIDER.VITALS_LIVE_CCE) ? sessionData.appConfig.PROVIDER.VITALS_LIVE_CCE.booleanValue : false;
			$scope.isMincr = brand.current.sharedBrandCode.toLowerCase() === 'mincr';

			$scope.showVitalsModal = function(){
            $scope.siteToLeave = (brand.current.domain.toLowerCase() != "mybenefitshome.com")?"Highmark": "Your health plan";
				var targUrl = finderLogic.getSapphireSSOUrl(brand.current.sharedBrandCode);
				if( $scope.isVitalsLiveCCE && ['wybcbs', 'ndbcbs', 'mincr'].indexOf(brand.current.sharedBrandCode) > -1){
					window.open(targUrl);
					return;
				}
				$scope.continueToDestination = function () {
					window.open(targUrl);
					$scope.closeModal();
				};

				modal.open({
					heading: "YOU WILL BE REDIRECTED TO SAPPHIRE DIGITAL",
					template: require('modals/vitalsDisclaimerModal.html'),
					scope:$scope
				});
        };
		// Get display information for certain Cost Saving Tools
		function updateToolVisibility () {
			$q.all([cceService.shouldDisplay(), rxccService.shouldDisplay(), savOppService.shouldDisplay()]).then(function (resolution) {

				//setting this to false for RF664648
				//SP 1280- Remove Castlight link on the GMI post-login member and show care cost estimator link instead
				var shouldDisplayCaslight = false;

				$scope.enabledTools = {
					cce: {
						shouldDisplay: resolution[0] && !shouldDisplayCaslight
					},
					//caslight: {
					//	shouldDisplay: shouldDisplayCaslight
					//},
					rxcc: {
						shouldDisplay: resolution[1],
						doSSO: function() { rxccService.doSSORequest(); }
					},
					savopp: {
						shouldDisplay: resolution[2],
						doSSO: function () { savOppService.doSSORequest(); }
					}
				};
			}, function (rejection) {
				/* Don't do anything. They'll all resolve. I promise. */
			});
		}


		// Determine whether or not the user's account has multiple plans associated with it.
 		var isUserWithMultiplePlans = function () {
			var result = false;

			if (sessionData.loggedIn) {
				var clientList = sessionData.memberInfo && sessionData.memberInfo.clientList ? sessionData.memberInfo.clientList : [];
				result = clientList.length > 1;

				clientList.forEach(function (client) {
					if (client.groupList.length > 1) {
						result = true;
					}
				});
			}

			return result;
		};

		updateToolVisibility();

		$scope.session = sessionData;
        $scope.multiplePlans = isUserWithMultiplePlans();
		$scope.navigateToViewSiteByPlan = function () {
			$state.go('vsbp', {redirect: '/login/#/spending/cost-saving-tools'});
		};

		var filterFunction = function (item) {
			return item.sections
				&& item.sections.memberSpendingCostTools;
		}

		$scope.$watch("session.loggedIn", function (newValue, oldValue) {
			$scope.tools = [];

			if (newValue !== oldValue) {
				// $scope.enabledTools = getEnabledToolsDisplayInfo();
				updateToolVisibility();
				$scope.multiplePlans = isUserWithMultiplePlans();
			}

			contentResourceServices.updateResourcesAsync(resourcesList, filterFunction).then(function (resources) {
			 	var costSavingTools = resources;
				$scope.tools = costSavingTools.filter(function (tool) {
					return _enabledCostSavingTools.indexOf(tool["id"]) === -1;
				})
			}, function (error) {
				$scope.tools = [];
			});
		});

		$scope.navigateToCCE = function(){
            var groupNumber= $cookies.get('mbrGroupNumber');
            var effectiveDate = $cookies.get('mbrGroupEffectiveDate');
            var cceURL ='/cce/landing-page.html?gn=' + groupNumber + '&ed=' + effectiveDate;
            $window.open(cceURL, '_self');
        };

        }]);
};
