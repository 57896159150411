module.exports = function(app) {
    app.directive('archiveStatement',
        function() {

            return {
                restrict: 'A',
                template: require('directives/archiveStatement.html'),

                scope: {
                    statement: '=archiveStatement'
                },


                controller: ['$scope','coremetrics', 'services', '$window',
                    function($scope, coremetrics, services, $window) {

                        $scope.downloadStatement = function(queryParams) {
                            services.rbsmbl.statementFile().fetch(queryParams).then(
                                function(response) {
                                    coremetrics.elementTag('DOWNLOAD:CLAIMS STATEMENT','MEMBER:CLAIMS');
                                    $window.open(response.url, '_self');
                                },
                                function(err) {
                                    console.error('statementFile().fetch failed with error', err);
                                }
                            );
                        };
                    }
                ]
            };
        }
    );
};