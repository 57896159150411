module.exports = function(bootstrap) {
    bootstrap.run(['Service', function(Service) {
        new Service(
            {
                name: 'eobDocByUmi',
                group: 'rbsmbl',
                url: '/rbsmbl/x-services/claimsData/v2/eobDocByUmiService',

                fetch: function() {
                    return this.get();
                }
            }
        );
    }]);
};