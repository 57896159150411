module.exports = function(app) {
    app.directive('educationSummary', function() {

        return {
            restrict: 'A',
            replace: true,
            template: require('directives/educationSummary.html'),

            scope: {},

            controller: ['$scope', 'educationalMaterialService', 'spendingAccountService', 'contentResourceServices','spendingResourceServices',
                function($scope, educationalMaterialService, spendingAccountService, contentResourceServices,spendingResourceServices) {
                    $scope.heading = 'Educational Material';
                    $scope.viewState = 'loading';
                    $scope.materials=[];

                    var contentResourcePromise=educationalMaterialService.getResourceIds(),
                        getSpendingAccountPromise=spendingResourceServices.getSpendingAccounts();

					// TODO: separate these calls so that you can render the list faster.
                    contentResourcePromise.then(
                        function(resourcesList){
                            getSpendingAccountPromise.then(
                                function(spendingAccounts){
                                    var emPromise = educationalMaterialService.getFilteredEducationalMaterialV2(resourcesList, 'memberClaimsEducationalMaterialsWidget',false,spendingAccounts);

                                    emPromise.then(
                                        function(rawMaterials) {
                                            $scope.materials=rawMaterials;
                                            $scope.viewState = 'success';
                                        },

                                        function(err) {
                                            console.error('getFilteredEducationalMaterial failed with error', err);
                                            $scope.viewState = 'error';
                                        }
                                    );
                                },
                                function(err) {
                                    console.error('getSpendingAccounts failed with error', err);
                                    $scope.viewState = 'error';
                                }
                            );
                        },
                        function(err){
                            console.error('getResourceList failed with error', err);
                            $scope.viewState = 'error';
                        }
                    );

                }
            ]
        };
    });
}