module.exports = function (app) {
    require("./vision-health/vision-wellness.js")(app);
    require("./vision-health/vision-library.js")(app);
    require("./vision-health/vision-library-content.js")(app);

    app.controller("pages.vision-health.davisSSO", ["$scope", "$state", "services", "teamsite", "$q", "$sce", "sessionData", "$timeout", "invisibleFormService", "$window", "modal", function($scope, $state, services, teamsite, $q, $sce, sessionData, $timeout, invisibleFormService, $window, modal){
        var ssoInfo = {
            ssoMemberInfo: "",
            verifyKey:"",
            forwardPage:"",
            groupNumber:sessionData.groupNumber,
            umGrp:"1"
        }
        $scope.davisSSOUrl = "";

        if(window.location.hostname.indexOf("env") > -1){
            $scope.davisSSOUrl = $sce.trustAsResourceUrl("https://idoc.davisvision.com/DavisVision.Member/Highmark/Authentication/HighmarkHandoff");
        }else{
            $scope.davisSSOUrl = $sce.trustAsResourceUrl("https://idoc.davisvision.com/DavisVision.Member/Highmark/Authentication/HighmarkHandoff");
        }


        //Davis Vision Forward Page Logic..
        if($state.$current.name == "vision-health.vision-claims"){
            ssoInfo.forwardPage = "CLAIMSHISTORY";
        }else if($state.$current.name == "vision-health.vision-eligibility"){
            ssoInfo.forwardPage = "CHECKVISIONELIGIBILITY";
        }else if($state.$current.name == "vision-health.laser-vision"){
            ssoInfo.forwardPage = "LASERVISIONCORRECTION";
        }
        services.rbsmbl.visionSso().get().then(function(response){
            var payload = response.data.payload;
            ssoInfo.ssoMemberInfo = payload.ssoMemberInfo;
            ssoInfo.verifyKey = payload.verifyKey;
            $scope.ssoInfo = ssoInfo;

            $timeout(function(){
                document.getElementById("davis-form").submit();
            },500);
        }, function (error) {
        });
    }]);

    app.controller("pages.vision-health", ["$scope", "$state", "services", "teamsite", "$q", "$sce", "sessionData", "$timeout", "invisibleFormService", "$window", "modal", function($scope, $state, services, teamsite, $q, $sce, sessionData, $timeout, invisibleFormService, $window, modal){

        $scope.headings = "Vision Health & Wellness";
        $scope.backText = "Back";
        $scope.backAction = function () {
            $state.go("^");
        };

        $scope.sidebar.links = [];
        var visionUrl = "";
        var navLinks = [
             teamsite.get("MBR_VISION_HEALTH_NAVIGATION_LINK1"),
             teamsite.get("MBR_VISION_HEALTH_NAVIGATION_LINK2"),
             teamsite.get("MBR_VISION_HEALTH_NAVIGATION_LINK3"),
             teamsite.get("MBR_VISION_HEALTH_NAVIGATION_LINK4"),
             teamsite.get("MBR_VISION_HEALTH_NAVIGATION_LINK5"),
             teamsite.get("MBR_VISION_HEALTH_NAVIGATION_LINK6"),
             teamsite.get("MBR_VISION_HEALTH_NAVIGATION_LINK7")
         ];


        $q.all(navLinks).then(function (results) {
        var myLinks = ["vision-health.vision-library",
                    "vision-health.vision-claims",
                    "vision-health.eye-care-provider",
                    "vision-health.vision-eligibility",
                    "vision-health.laser-vision"];
            angular.forEach(results, function(value){
                try {
                    var jsonValue = JSON.parse(value);
                    $scope.sidebar.links.push(jsonValue);
                    } catch(e) {}
            });
        });

    



        //Find eye care provider iframe
        teamsite.get("MBR_VISION_HEALTH_FINDPROVIDER").then(function(response) {
            var jsonResponse = JSON.parse(response);
            $scope.visionUrl = $sce.trustAsResourceUrl(jsonResponse.href);
        });

        $scope.nav = function(state){
            $state.go(state);
        };

        $scope.iframeSrc = function(){
            teamsite.get("MBR_VISION_HEALTH_FINDPROVIDER").then(function(response) {
                var jsonResponse = JSON.parse(response);
                visionUrl = jsonResponse.href;
                return visionUrl;
            });
         };
    }]);

    return [
        {
            state: "vision-health",
            postPassword:true,
            url: "/vision-health",
            views: {
                "body@": {
                    template: bootstrap.templates["page-templates/categoryCustom.html"],
                    controller: "pages.vision-health"
                },
                "content@vision-health":{
                    template: require("./vision-health/vision-wellness.html"),
                    controller: "pages.vision-health.vision-wellness"
                }
            }
        },
        {
            state: "vision-health.vision-claims",
            postPassword:true,
            url: "/vision-claims",
            views: {
                "body@": {
                    template: bootstrap.templates["page-templates/categoryCustom.html"],
                    controller: "pages.vision-health"
                },
                "content@vision-health.vision-claims":{
                    template: require("./vision-health/vision-claims.html"),
                    controller: "pages.vision-health.davisSSO"
                }
            }
        },
        {
            state: "vision-health.eye-care-provider",
            postPassword:true,
            url: "/eye-care-provider",
            views: {
                "content@vision-health":{
                    template: require("./vision-health/eye-care-provider.html")
                }
            }
        },
        {
            state: "vision-health.vision-eligibility",
            postPassword:true,
            url: "/vision-eligibility",
            views: {
                "content@vision-health":{
                    template: require("./vision-health/vision-eligibility.html"),
                    controller: "pages.vision-health.davisSSO"
                }
            }
        },
        {
            state: "vision-health.laser-vision",
            postPassword:true,
            url: "/laser-vision",
            views: {
                "content@vision-health":{
                    template: require("./vision-health/laser-vision.html"),
                    controller: "pages.vision-health.davisSSO"
                }
            }
        },
        {
            state: "vision-health.vision-library",
            postPassword:true,
            url: "/vision-wellness-library",
            views: {
                'content@vision-health':{
                    template: require('./vision-health/vision-library.html'),
                    controller: "pages.vision-health.vision-library"
                }
            }
        },
        {
            state: "vision-health.vision-library.article",
            postPassword: true,
            url: "/:articleID",
            views: {
                "body@": {
                    template: bootstrap.templates["page-templates/detail.html"],
                    controller: "pages.vision-health"
                },
                "content@vision-health.vision-library.article": {
                    template: require("./vision-health/vision-library-content.html"),
                    controller: "pages.vision-health.vision-library.vision-library-content"
                }
            }
        }
    ]

};

