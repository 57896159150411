module.exports = function(app) {
    app.directive("checkPwdStrength",
        function() {

            return {
                restrict: "A",
                replace: true,
                template: "<ul><li class=\"bar\"></li><li class=\"bar\"></li><li class=\"bar\"></li><li class=\"bar\"></li><p data-ng-model=\"strengthText\" class=\"strength-text\">{{stengthText}}</p></ul>",

                scope: {
                    password: "=checkPwdStrength"
                },

                controller: ["$scope", "$element", "$attrs", function($scope, $element, $attrs) {
        
                    var mesureStrength = function(pwd){
                        var id = 0;
                        var colors = ["#e31b23", "#c15119", "#f09628", "#8dc63f"];

                        if(pwd.length < 5){
                            id = 0;
                            $scope.stengthText = "Weak";
                        }else if(pwd.length < 10){
                            id = 1;
                            $scope.stengthText = "Medium";
                        }else if(pwd.length < 15){
                            id = 2;
                            $scope.stengthText = "Medium";
                        }else{
                            id = 3;
                            $scope.stengthText = "Strong";
                        }

                        return {id: id+1, color: colors[id]};
                    };

                    $scope.$watch("password", function(val){
                        if(!val || val === ""){
                            $element.css({"display": "none"});
                        }else{
                            var obj = mesureStrength(val);
                            $element.css({"display": "block"});
                            $element.children("li").css({"background": "#DDD"})
                                                   .slice(0, obj.id)
                                                   .css({"background": obj.color});
                            $element.children("p").css({color: obj.color});
                        }
                    });
                }]
            };
        }
    );
};