module.exports = function(app) {
    app.service("claimsMtm", ["services", "$timeout", function(services, $timeout) {
        this.queueDelay = 200; // delay in MS to wait for the queue to populate before sending a service request
        this.queue = [];
        this.addSummary = function(data) {
            this.queue.push(data);
            this.processSummaryQueue();
        };
        this.processSummaryQueue = function() {
            if (this.processSummaryQueueTimeout) {
                $timeout.cancel(this.processSummaryQueueTimeout);
            }
            this.processSummaryQueueTimeout = $timeout((function() {
                var currentQueue = this.queue;
                this.queue = [];
                services.rbsmbl.capabilityInfo().persistMTMInfo({
                    'claims': currentQueue
                }, 'claimSummary');
            }).bind(this), this.queueDelay);
        };
    }]);
    app.directive('claimsMatchingDrawer', [function() {
        return {
            restrict: 'A',
            template: require('directives/claimsMatchingDrawer.html'),
            scope: {
                claim: '=claimsMatchingDrawer'
            },
            controller: ['$scope', '$rootScope', '$state', 'services', 'claimService', 'isTruthyOrZero', 'modal', 'storage', '$timeout', 'claimsMtm', '$q', 'coremetrics', 'session', 'brand',
                function($scope, $rootScope, $state, services, claimService, isTruthyOrZero, modal, storage, $timeout, claimsMtm, $q, coremetrics, session, brand) {
                    var
                        claim = $scope.claim,
                        buttons = {},
                        _mapToothNumbers,
                        _hasEOB,
                        _hasEOP,
                        _hasPAS,
                        _hasCDN,
                        _toggleMarkAsPaid,
                        _setDownloadAvailability,
                        _configureFooterButtons;

                    $scope.currentState = $state.current.name;



                    if ($scope.claim.sysSrcCode === 'medical') {
                        claimsMtm.addSummary($scope.claim.originalData);
                    }

                    // TODO: Revisit eobDoc/footer-buttons implementation
                    // *** Begin footer-buttons helper methods *** //
                    _hasEOB = function(docTypeCode, seqNumPrefix) {
                        return (docTypeCode === 'EOB' || docTypeCode === 'DEB' || docTypeCode === 'MEB' || docTypeCode === 'IEB') &&
                            (seqNumPrefix !== 'HC' && seqNumPrefix !== 'WC' && seqNumPrefix !== 'DC' && seqNumPrefix !== 'IC');
                    };

                    _hasEOP = function(docTypeCode, seqNumPrefix) {
                        return docTypeCode === 'EOP' &&
                            seqNumPrefix !== 'HC' &&
                            seqNumPrefix !== 'WC' &&
                            seqNumPrefix !== 'DC' &&
                            seqNumPrefix !== 'IC';
                    };


                    _hasPAS = function(docTypeCode, seqNumPrefix) {
                        return (docTypeCode === 'EOB' || docTypeCode === 'DEB' || docTypeCode === 'IEB' || docTypeCode === 'EOP') &&
                            (seqNumPrefix === 'HC' || seqNumPrefix === 'WC' || seqNumPrefix === 'DC' || seqNumPrefix === 'IC');
                    };

                    _hasCDN = function(docTypeCode) {
                        return docTypeCode === 'ALG';
                    };

                    _setDownloadAvailability = function(buttons, documentTypeCode, sequenceNumberPrefix, endDate, queryPayload) {
                        buttons = buttons || {};
                        var isSubscriber = session.data.isSubscriber;
                        var isMINCR = brand.current.sharedBrandCode === 'mincr';
                        // this replicates the prior implementation wherein a true occurrence takes precedence
                        // however, it is unclear if there will ever actually be more than one object in the eobDataList array
                        buttons.dependentWithEob = (!isSubscriber && !isMINCR) && (buttons.dependentWithEob || _hasEOB(documentTypeCode, sequenceNumberPrefix));
                        buttons.hasEOB = (isSubscriber || isMINCR) && (buttons.hasEOB || _hasEOB(documentTypeCode, sequenceNumberPrefix));
                        buttons.hasEOP = (isSubscriber || isMINCR) && (buttons.hasEOP || _hasEOP(documentTypeCode, sequenceNumberPrefix));
                        buttons.hasPAS = (isSubscriber || isMINCR) && (buttons.hasPAS || _hasPAS(documentTypeCode, sequenceNumberPrefix));
                        buttons.hasCDN = (isSubscriber || isMINCR) && (buttons.hasCDN || _hasCDN(documentTypeCode));
                        buttons.queryPayload = queryPayload || {};

                        if (buttons.hasEOB) {
                            buttons.eobDate = endDate;
                        }

                        if (buttons.hasPAS) {
                            buttons.pasDate = endDate;
                        }

                        return buttons;
                    };
                    // TODO: Figure out 3rd param
                    _configureFooterButtons = function(claim, buttons, mobileFlag) {
                        if (!claim.claimType.isDrug && !claim.claimType.isDental
                            && claim.clmPartialSupressInd && claim.clmPartialSupressInd.toLowerCase() === 'n') {
                            services.rbsmbl.eobDocByClaim().fetch(claim.claimNumber).then(
                                function(response) {
                                    var
                                        payload = response.data.repEobDocumentByClaimResp.payload || {},
                                        eobDataList = payload.repEobDocumentByClaimData || [],
                                        firstEobObject = eobDataList[0] || {};

                                    eobDataList.forEach(function(eobObject) {
                                        var
                                            docTypeCode = eobObject.docTypeCode.toUpperCase(),
                                            seqNumberPrefix,
                                            docKeyParams = {},
                                            statementFileParams;


                                        eobObject.docKey = eobObject.docKey || [];
                                        eobObject.docKey.forEach(function(obj) {
                                            var
                                                keyName,
                                                keyValue;

                                            if (obj.docKeyName.toLowerCase() === 'sequence number') {
                                                seqNumberPrefix = obj.docKeyValue.slice(0, 2).toUpperCase();
                                            }

                                            keyName = obj.docKeyName.toUpperCase().replace(' ', '_');
                                            keyValue = obj.docKeyValue.toUpperCase();

                                            docKeyParams[keyName] = keyValue;
                                        });


                                        if (docTypeCode === 'EOB' || docTypeCode === 'DEB' || docTypeCode === 'IEB' || docTypeCode === 'EOP' || docTypeCode === 'MEB') {
                                            statementFileParams = {
                                                ODFolder: eobObject.docOdFolderName.toUpperCase() || '',
                                                BEGIN_DATE: eobObject.docBeginDate || '',
                                                END_DATE: eobObject.docEndDate || '',
                                                MTM_CLAIM: claim.claimNumber,
                                                MTM_PT: '',
                                                // TODO: actually pass a mobileFlag at some point
                                                MTM_ISM: mobileFlag || 'No',
                                                CONTENT_DISPOSITION: 'inline'
                                            };

                                            statementFileParams = angular.extend(statementFileParams, docKeyParams);

                                            $scope.buttons = _setDownloadAvailability(buttons, docTypeCode, seqNumberPrefix, firstEobObject.docEndDate, statementFileParams);
                                        }
                                    });
                                },
                                function(err) {
                                    console.error('eobDocByClaim.fetch failed with error', err);
                                    return err;
                                }
                            );
                        }
                    };
                    // *** End footer-buttons helper methods *** //

                    Object.keys(claim && claim.claimType || {}).forEach(function(key) {
                        $scope[key] = claim.claimType[key]; // Example: 'claim.claimType.isVision: true' sets '$scope.isVision: true'
                    });

                    var detailCachePromise;
                    $scope.getClaimDetails = function() {
                        if (detailCachePromise) {
                            return detailCachePromise;
                        }
                        var detailPromise = claimService.getDetails(claim);

                        detailPromise.then(function(details) {
                            if (claim.sysSrcCode === 'medical') {
                                services.rbsmbl.capabilityInfo().persistMTMInfo({
                                    claim: claim.originalData,
                                    services: details.originalData
                                }, 'claimDetail');
                            }

                            if (claim.claimType.isDental) {
                                details.forEach(function(detail) {
                                    _mapToothNumbers(detail);
                                });
                            }

                            claim.details = details;
                            _configureFooterButtons(claim, buttons);
                        });

                        detailCachePromise = detailPromise;
                        return detailPromise;
                    };

                    var mhsClaimIsClicked;
                    $scope.$on('drawerOpen', function() {

                        if(!mhsClaimIsClicked){
                            coremetrics.elementTag('MHS CLAIM EXPAND: SPENDING','MEMBER:SPENDING');
                            mhsClaimIsClicked = true;
                        }

                        $scope.getClaimDetails();
                    });

                    $scope.isOpen = false;
                    if ($scope.claim && $scope.claim.isOpen) {
                        $scope.getClaimDetails().then(function() {
                            $timeout(function() {
                                $scope.isOpen = true;
                            }, 100);
                        });
                    }

                    $scope.isTruthyOrZero = isTruthyOrZero;
                    $scope.isInProcess = claim && claim.bilgProvClmStaCd === '5';
                    //$scope.showMarkedAsPaidControls = claim && (claim.alreadyPaid === 'TRUE' || claim.alreadyPaid === 'FALSE');
                    $scope.showMarkedAsPaidControls = false;
                    $scope.isAlreadyPaid = claim && claim.alreadyPaid === 'TRUE';
                    $scope.showsPayButton = $scope.showMarkedAsPaidControls && !$scope.isAlreadyPaid;

                    $scope.isConfirmation = $rootScope.confirmationState;

                    $scope.addClaimToMatchArray = function(claimNumber) {
                        var claimsArray,
                            claimsMatchTotal;
                        $rootScope.cancelMatchFundThresholdError();
                        if (!storage.get("claimsToMatch")) {
                            claimsArray = [];
                            claimsMatchTotal = 0;
                        } else {
                            claimsArray = JSON.parse(storage.get("claimsToMatch"));
                            claimsMatchTotal = parseFloat(JSON.parse(storage.get("claimsMatchTotal")));
                        }
                        var claimAlreadyInArray = claimsArray.indexOf(claimNumber) >= 0;

                        if ($scope.selection) {
                            if (!claimAlreadyInArray) {
                                claimsArray.push(claimNumber);
                                claimsMatchTotal = claimsMatchTotal + claim.bilgProvTotMbrLiab;
                                claimsMatchTotal = claimsMatchTotal.toFixed(2);
                                storage.set("claimsToMatch", JSON.stringify(claimsArray));
                                storage.set("claimsMatchTotal", JSON.stringify(claimsMatchTotal));
                            }

                        } else {
                            if (claimAlreadyInArray) {
                                claimsArray.splice(claimsArray.indexOf(claimNumber), 1);
                                claimsMatchTotal = claimsMatchTotal - claim.bilgProvTotMbrLiab;
                                claimsMatchTotal = claimsMatchTotal.toFixed(2);
                                storage.set("claimsToMatch", JSON.stringify(claimsArray));
                                storage.set("claimsMatchTotal", JSON.stringify(claimsMatchTotal));
                            }

                        }
                        $rootScope.selectedClaimAmount = Number(claimsMatchTotal);

                    };


                    var servicesRunning = [];
                    $scope.markAsPaidServiceLine = function(claim, line) {
                        if (servicesRunning[line.lnNo] && servicesRunning[line.lnNo].$$state && servicesRunning[line.lnNo].$$state.status !== 1) {
                            servicesRunning[line.lnNo].reject();
                        }
                        if (line.isIgnored) {
                            servicesRunning[line.lnNo] = $q.defer();
                            servicesRunning[line.lnNo].promise.then(paidModal);
                            services.rbsmbl.spendingAccountIgnore().fetch(claim.clientId, claim.claimNumber, line.lnNo).then(function(response) {
                                servicesRunning[line.lnNo].resolve(response);
                            });
                        } else {
                            servicesRunning[line.lnNo] = $q.defer();
                            servicesRunning[line.lnNo].promise.then(paidModal);
                            services.rbsmbl.spendingAccountUnignore().fetch(claim.clientId, claim.claimNumber, line.lnNo).then(function(response) {
                                servicesRunning[line.lnNo].resolve(response);
                            });
                            claim.alreadyPaid = 'FALSE';
                            $scope.isAlreadyPaid = false;
                            $scope.showsPayButton = true;
                        }
                        var count = claim.details.reduce(function(sum, line) {
                            if (line.isPotentiallyPayable) {
                                sum++;
                            }
                            return sum;
                        }, 0);
                        var match = 0;
                        claim.details.forEach(function(line) {
                            if (line.isPotentiallyPayable && line.isIgnored) {
                                match++;
                            }
                        });
                        if (count === match) {
                            claim.alreadyPaid = 'TRUE';
                            $scope.isAlreadyPaid = true;
                            $scope.showsPayButton = false;
                        }

                        services.rbsmbl.claimDetail().flushCache();
                        detailCachePromise = null;
                        services.rbsmbl.claimSummary().flushCache();
                    };

                    var markAllRunning;
                    $scope.markAsPaidAll = function(claim) {
                        if (markAllRunning && markAllRunning.$$state && markAllRunning.$$state.status !== 1) {
                            markAllRunning.reject();
                        }
                        markAllRunning = $q.defer();

                        claim.alreadyPaid = $scope.isAlreadyPaid ?
                            'TRUE' :
                            'FALSE';

                        $scope.showsPayButton = !$scope.isAlreadyPaid;

                        $scope.getClaimDetails().then(function() {
                            var allPromises = [];
                            claim.details.forEach(function(line) {
                                if ($scope.isAlreadyPaid && !line.isIgnored) {
                                    line.isIgnored = true;
                                    allPromises.push(services.rbsmbl.spendingAccountIgnore().fetch(claim.clientId, claim.claimNumber, line.lnNo));
                                } else if (!$scope.isAlreadyPaid && line.isIgnored) {
                                    line.isIgnored = false;
                                    allPromises.push(services.rbsmbl.spendingAccountUnignore().fetch(claim.clientId, claim.claimNumber, line.lnNo));
                                }
                            });
                            markAllRunning.promise.then(paidModal);
                            $q.all(allPromises).then(function() {
                                markAllRunning.resolve();
                                services.rbsmbl.claimDetail().flushCache();
                                detailCachePromise = null;
                                services.rbsmbl.claimSummary().flushCache();
                            });
                        });
                    };
                }
            ]
        };
    }]);
};