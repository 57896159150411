module.exports = function(app) {
    app.directive('dateOfBirthValidation', ["$window", "$compile", function($window, $compile) {
        return {
            restrict: "A",
            require: "ngModel",
            link: function (scope, elm, attrs, ctrl) {
                ctrl.$validators.dateOfBirthValidation = function(modelValue, viewValue) {
                    var now = new Date();
                    var past = new Date('1890-01-01');
                    var enteredDate = new Date(viewValue);

                    if (ctrl.$isEmpty(modelValue)) {
                        // consider empty models to be valid
                        return true;
                    }

                    if (enteredDate >= past && enteredDate < now) {
                        return true;
                    }

                    return false;
                };
            }
        };
    }]);
}