module.exports = function(app) {
    var modalController = require("../../modals/error");

    app.controller('pages.secureRegistration.secureRegister.step2', ["$state", "$scope", "services", "coremetrics", "modal",
        function($state, $scope, services, coremetrics, modal) {

            var isMCUser = false;
            $scope.isWY = $scope.brandData.ubk == '6012' ? true : false;
            $scope.sectionHeading = "Please confirm the following details and affirm the legal language below.  If any of the information is incorrect, please contact us at ";

            //CR00779941 - Wrong phone number issue
            if(($scope.brandData.sharedBrandCode.toLowerCase() !== "ibcah" || $scope.brandData.sharedBrandCode.toLowerCase() !== ibccr) && $scope.brandData.phoneNumbers.languageEmployer[0] === '1-866-823-0925'){
                $scope.csPhoneNumber = "1-866-306-1059";
            }else{
                $scope.csPhoneNumber = $scope.brandData.phoneNumbers.customerService || $scope.brandData.phoneNumbers.languageEmployer[0];
            }

            $scope.sectionHeadingWY = "Please confirm the following details and affirm the legal language below.  If any of the information is incorrect, please contact your BCBSWY Agent.";


            $scope.user = {};
            $scope.termsAccepted = false;

            var userId = $state.params.userId || "";
            var token = $state.params.token || "";

            if (userId === "" || token === "") {
                $state.go('secureRegistration.error', {status: "NO_USER"});
            }

            services.rbsmbl.demographics({userid: userId}).fetch(token).then(
                function(response) {
                    if(response && response.data && response.data.payload && response.data.payload.userType){
                        isMCUser = response.data.payload.userType == "MCU_ADMIN";
                    }
                    var base = services.rbsmbl.demographics().parseBase(response);
                    if (base && base.returnCode && base.returnCode !== "200") {
                        $state.go('secureRegistration.error', { status: base.returnCode, statusText: base.returnCodeDescription });
                    } else {
                        $scope.user = services.rbsmbl.demographics().parse(response);
                    }
                },
                function(error) {
                    //console.log(error);
                    $state.go('secureRegistration.error', error);
                }
            );

            $scope.nextStep = function() {
                if ($scope.termsConditionsForm.$valid) {

                    var payload = {
                        userId: userId,
                        accept: "yes"
                    };
                    services.rbsmbl.legalTerms().postData(payload, token).then(
                        function(response) {
                            var base = services.rbsmbl.legalTerms().parseBase(response);
                            if (base && base.returnCode && base.returnCode !== "200") {
                                $state.go('secureRegistration.error', { status: base.returnCode, statusText: base.returnCodeDescription });
                            } else {
                                $scope.$parent.myChevron.state = "secureRegistration.step3";
                                $scope.$parent.myChevron.step = 3;
                                $scope.$parent.goto(3, { userId: userId, token: token, loginId: $scope.user.loginId });
                            }
                        },
                        function(error) {
                            //console.log(error);
                            $state.go('secureRegistration.error', error);
                        }
                    );
                }
            };

            $scope.cancelButton = function() {
                var payload = {
                    userId: userId,
                    accept: "no"
                };
                // Call service to trigger cancel terms email.
                var response = services.rbsmbl.legalTerms().postData(payload, token);
                $state.go('secureRegistration.error', { status: "CANCEL_TERMS", statusText: "" });
            };

            $scope.showTermsModal = function(){
                if(isMCUser){
                    modal.open({
                                   template: require('modals/mcuTermsModal.html'),
                                   controller: modalController
                               });
                } else {
                    modal.open({
                                   template: require('modals/planAdminTermsModal.html'),
                                   controller: modalController
                               });
                }

            };

        }
    ]);

};