/**
* cceService.js
*
* Display rules for Care Cost Estimator.
*/

module.exports = function (app) {
	app.service('cceService', ["RuleEngine", "services", "sessionData", "$q", function (RuleEngine, services, sessionData, $q) {
		function init() {
			var deferred = $q.defer();
			var showCCEEntryLink = false;

			// App Config Override/Suppression Rules
			function checkCCEAppConfigKey(key) {
				return sessionData &&
					sessionData.appConfig &&
					sessionData.appConfig.LANDING &&
					sessionData.appConfig.LANDING[key] &&
					sessionData.appConfig.LANDING[key].booleanValue;
			}

			function getClientNumber(groupNumber) {
				var clientNumber = false;

				// Get the clientList if it exists on sessionData.
				var clientList = [];
				if (sessionData
					&& sessionData.memberInfo
					&& sessionData.memberInfo.clientList) {
						clientList = sessionData.memberInfo.clientList;
				}

				clientList.forEach(function (client) {
					var groupList = client && client.groupList ? client.groupList : [];
					var currentClientNumber = client.clientNumber;

					groupList.forEach(function (group) {
						if (group.groupNumber === groupNumber) {
							return clientNumber = currentClientNumber;
						}
					});

					if (clientNumber) {
						return;
					}
				});

				return clientNumber;
			}

			// Check if the given value is in the key's Override Value.
			function keyHasOverrideValue(key, value) {
				var overrideValue = sessionData &&
					sessionData.appConfig &&
					sessionData.appConfig.LANDING &&
					sessionData.appConfig.LANDING[key] ?
					sessionData.appConfig.LANDING[key].overrideValue : "";

				return overrideValue.split(',').indexOf(value) > -1;
			}
			
			if (sessionData.loggedIn) {
				var selectedPlan = services.rbsmbl.memberInfo().getPortalSelectedPlan();

				var userUMI = sessionData.memberInfo.umi;
				var userGroupNumber = sessionData.groupNumber;
				var userClientNumber = getClientNumber(userGroupNumber);

				// Check SUPPRESS_CCE_LINK[_BY_UMI|GROUP|CLIENT] AppConfig Keys.
				if (checkCCEAppConfigKey("SUPPRESS_CCE_ENTRY_LINK_BY_UMI")
					&& keyHasOverrideValue("SUPPRESS_CCE_ENTRY_LINK_BY_UMI", userUMI)) {
					showCCEEntryLink = false;
				} else if (checkCCEAppConfigKey("SUPPRESS_CCE_ENTRY_LINK_BY_GROUP")
					&& keyHasOverrideValue("SUPPRESS_CCE_ENTRY_LINK_BY_GROUP", userGroupNumber)) {
					showCCEEntryLink = false;
				} else if (checkCCEAppConfigKey("SUPPRESS_CCE_ENTRY_LINK_BY_CLIENT")
					&& keyHasOverrideValue("SUPPRESS_CCE_ENTRY_LINK_BY_CLIENT", userClientNumber)) {
					showCCEEntryLink = false;
				} else if (checkCCEAppConfigKey("SUPPRESS_CCE_ENTRY_LINK")) {
					showCCEEntryLink = false;
				}

				// Check SHOW_CCE_ENTRY_LINK[_BY_UMI|GROUP|CLIENT] AppConfig Keys.
				else if (checkCCEAppConfigKey("SHOW_CCE_ENTRY_LINK_BY_UMI")
					&& keyHasOverrideValue("SHOW_CCE_ENTRY_LINK_BY_UMI", userUMI)) {
					showCCEEntryLink = true;
				} else if (checkCCEAppConfigKey("SHOW_CCE_ENTRY_LINK_BY_GROUP")
					&& keyHasOverrideValue("SHOW_CCE_ENTRY_LINK_BY_GROUP", userGroupNumber)) {
					showCCEEntryLink = true;
				} else if (checkCCEAppConfigKey("SHOW_CCE_ENTRY_LINK_BY_CLIENT")
					&& keyHasOverrideValue("SHOW_CCE_ENTRY_LINK_BY_CLIENT", userClientNumber)) {
					showCCEEntryLink = true;
				} else if (checkCCEAppConfigKey("SHOW_CCE_ENTRY_LINK")) {
					showCCEEntryLink = true;
				}

				// Default - Check if the user hasCareCostEstimator.
				else {
					showCCEEntryLink = selectedPlan.hasCareCostEstimator && selectedPlan.hasCareCostEstimator === "true"
				}

				deferred.resolve(showCCEEntryLink);
			} else { // Else if the user is logged out, don't display the tool.
				deferred.resolve(false);
			}

			return deferred.promise;
		}; 

		/*
		* Exposed Methods
		*/

		this.shouldDisplay = function () {
			return init();
		};
	}]);
};
