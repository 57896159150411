module.exports = function (app) {
    app.factory('benefitsDeserializer', [function () {

        function Lob(benefitsData) {
            this.lob = benefitsData.lob;
            this.benefitsData = this.getBenefitsData(benefitsData);
        }

        function assignIfKey(val, key){
            if (val.hasOwnProperty(key)){
                if (val[key]){
                    return val[key];
                } else {
                    return false;
                }
            } else {
                return false;
            }
        }

        String.prototype.toCamelCase = function () {
            return this.replace(/\w\S*/g, function (txt) {
                return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
            });
        };

        function benefitIcon(planType){
            planType = planType.toLowerCase();
            if (planType === 'dental') {
                return 'icon-dental';
            }
            else if (planType === 'vision') {
                return 'icon-vision';
            }
            else if (planType === 'drug') {
                return 'icon-pharmacy';
            }
            else if (planType === 'medical') {
                return 'icon-provider';
            }
            else {
                return '';
            }
        }

        function errorCheck(benefitsData){

            var checkedData = {};
            checkedData.error = false;
            if (benefitsData.hasOwnProperty('data')){
                if (benefitsData.data){
                    if (benefitsData.data.hasOwnProperty('payload')) {

                        if (benefitsData.data.payload){
                            if (benefitsData.data.payload.hasOwnProperty('clientList')) {

                                return benefitsData.data.payload.clientList;

                            } else {
                                console.log("no client list from member info");
                                checkedData.error = true;
                                return checkedData;
                            }
                        } else {
                            console.log("payload is null");
                            checkedData.error = true;
                            return checkedData;
                        }

                    } else {
                        console.log("empty payload from member info");
                        checkedData.error = true;
                        return checkedData;
                    }
                } else {
                    console.log("data is null");
                    checkedData.error = true;
                    return checkedData;
                }
            } else {
                console.log("no data from member info");
                checkedData.error = true;
                return checkedData;
            }
        }

        Lob.prototype.getBenefitsData = function (data) {
            const nEmployerNamesNumChars = 30;

            var benefitsData = {};
            benefitsData.error = false;
            var benefitsResponse = errorCheck(data);

            if (benefitsResponse.error){
                benefitsData.error = true;
                return benefitsData;
            }

            benefitsData.umi = data.data.payload.umi;
            benefitsData.numEmployers = benefitsResponse.length;
            benefitsData.employers = [];
            benefitsData.hasPlan = false;

            for (var i=0; i<benefitsData.numEmployers; i++){
                var employer = {};
                var benefitsYears = 0;
                employer.longName = benefitsResponse[i].clientName.toCamelCase();
                if (employer.longName.length > (nEmployerNamesNumChars)){
                    employer.shortName = benefitsResponse[i].clientName.substring(0, nEmployerNamesNumChars).toCamelCase() + " ...";
                } else {
                    employer.shortName = employer.longName;
                }
                employer.years = [];
                var curDate = new Date();
                var curYear = {};
                var prevYear = {};
                curYear.products = [];
                prevYear.products = [];
                for (var j=0; j<benefitsResponse[i].groupList.length; j++){

                    for (var k=0; k<benefitsResponse[i].groupList[j].productList.length; k++){
                        var product = {};
                        var curProduct = benefitsResponse[i].groupList[j].productList[k];

                        var coverageStartDate = new Date(curProduct.coverageBeginDate);
                        var coverageEndDate = new Date(curProduct.coverageEndDate);
                        var benAccStartDate = assignIfKey(curProduct, 'benefitAccumulationStartDate');
                        var benAccEndDate = assignIfKey(curProduct, 'benefitAccumulationEndDate');

                        product.currentYear = (curDate < coverageEndDate);

                        if (benAccStartDate){
                            benAccStartDate = new Date(benAccStartDate);
                        }
                        if (benAccEndDate){
                            benAccEndDate = new Date(benAccEndDate);
                            product.currentYear = (curDate < coverageEndDate && curDate < benAccEndDate);
                        }

                        if (benAccStartDate){
                            if (curProduct.benefitAccumulationStartDate !== curProduct.coverageBeginDate && product.currentYear){
                                product.startDate = curProduct.benefitAccumulationStartDate;
                            } else {
                                product.startDate = (coverageStartDate < benAccStartDate) ? curProduct.coverageBeginDate : curProduct.benefitAccumulationStartDate;
                            }
                        } else {
                            product.startDate = curProduct.coverageBeginDate;
                        }
                        if (benAccEndDate){
                            if (curProduct.benefitAccumulationEndDate !== curProduct.coverageEndDate && product.currentYear){
                                product.endDate = curProduct.benefitAccumulationEndDate;
                            } else {
                                product.endDate = (coverageEndDate < benAccEndDate) ? curProduct.coverageEndDate : curProduct.benefitAccumulationEndDate;
                            }
                        } else {
                            product.endDate = curProduct.coverageEndDate;
                        }

                        product.planName = curProduct.benefitPlanName;
                        product.planType = curProduct.benefitPlanType;
                        product.icon = benefitIcon(curProduct.benefitPlanType);
                        var prefix = curProduct.alphaPrefix;
                        if (prefix){
                            product.memberId = prefix + ((curProduct.clientAssignedId) ? curProduct.clientAssignedId : benefitsData.umi);
                        } else {
                            product.memberId = (curProduct.clientAssignedId) ? curProduct.clientAssignedId : benefitsData.umi;
                        }
                        product.groupNumber = benefitsResponse[i].groupList[j].idCardEobGroupNumber || benefitsResponse[i].groupList[j].groupNumber;

                        product.members = [];
                        for (var m=0; m<curProduct.memberList.length; m++){
                            var member = {};
                            member.firstName = curProduct.memberList[m].contact.firstName.toCamelCase();
                            member.lastName = curProduct.memberList[m].contact.lastName.toCamelCase();
                            member.suffix = curProduct.memberList[m].contact.suffix || '';
                            // modifiers
                            member.modifiers = '';
                            var cancelDate = assignIfKey(curProduct.memberList[m], 'coverageCancellationDate');
                            if (cancelDate){
                                cancelDate = new Date(cancelDate);
                                if (cancelDate >= coverageStartDate && cancelDate <= coverageEndDate){
                                    member.modifiers += '(CANCELLED)';
                                }
                            }
                            product.members.push(member);
                        }

                        if (product.currentYear) {
                            curYear.products.push(product);
                        } else {
                            prevYear.products.push(product);
                        }
                        benefitsData.hasPlan = true;
                    }
                }
                if (curYear.products.length){
                    employer.years.push(curYear);
                    benefitsYears++;
                }
                if (prevYear.products.length){
                    employer.years.push(prevYear);
                    benefitsYears++;
                }
                employer.benefitsYears = benefitsYears;

                benefitsData.employers.push(employer);
            }


            return benefitsData;
        };

        return function (benefitsPayload) {
            return new Lob(benefitsPayload);
        };
    }]);
};