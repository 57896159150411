module.exports = function(bootstrap) {
    bootstrap.run(['Service', 'appId','$http', '$cookies', function(Service, appId,$http, $cookies) {
        new Service(
            {
                name: 'eobDocByClaim',
                group: 'rbsmbl',
                url: '/rbsmbl/x-services/claimsData/v1/eobDocByClaim',

                fetch: function(claimNumber) {
                    var
                        claimNumberAsString = String(claimNumber),
                        requestPayload;

                    requestPayload = {
                        "reqEobDocumentByClaimRequest": {
                            "baseMessage": {
                                "exchangeType": "SENDREQUEST",
                                "headerVersion": "1.0",
                                "username": appId
                            },
                            "payload": {
                                "reqEobDocumentByClaim": {
                                    "claimNumber": claimNumberAsString
                                }
                            }
                        }
                    };

                    return this.post(requestPayload);
                },

                getKey: function(payload){
                    var hostnameArray = window.location.hostname.split('.');
                    var subDomain = hostnameArray[0];
                    var ubk = {
                        "mincr": "6010",
                        "ndbcbs": "6011",
                        "wybcbs": "6012",
                        "de": '9011',
                        "hmbcbs": "9001",
                        "pbs": "6002",
                        "neny": "9018",
                        "wny": '9017',
                        "ms": "9009",
                        "az": "9007",
                        "fl": "9005",
                        "ibc": '9003',
                        "neb": "9006",
                        "idh": "9015",
                        "ub": '9019',
                        "mn": "9004",
                        "nd": "9021"
                    };  
                
                
                    if (subDomain == "www" || subDomain == "penv") {
                        var axwayHost= "https://api.hmhs.com";
                    }else{
                        var axwayHost = "https://apiqa.hmhs.com";
                    }

                    this.headers = {
                        Authorization: 'Bearer ' + $cookies.get("Oauth_access_token"),
                        brandUbk: ubk[$cookies.get("umsBrand").toLowerCase()],
                        "Content-Type": 'application/json'
                    };

                  
                    this.url=  axwayHost + "/digital-member/v2/ext/common/eobs/status"
                    var serviceCall = $http({
                        url: this.url,
                        method: "POST",
                        data: payload,
                        headers: this.headers
                    });
                    
                    return serviceCall;
                },
                getDoc: function(payload){
                    var hostnameArray = window.location.hostname.split('.');
                    var subDomain = hostnameArray[0];
                    var ubk = {
                        "mincr": "6010",
                        "ndbcbs": "6011",
                        "wybcbs": "6012",
                        "de": '9011',
                        "hmbcbs": "9001",
                        "pbs": "6002",
                        "neny": "9018",
                        "wny": '9017',
                        "ms": "9009",
                        "az": "9007",
                        "fl": "9005",
                        "ibc": '9003',
                        "neb": "9006",
                        "idh": "9015",
                        "ub": '9019',
                        "mn": "9004",
                        "nd": "9021"
                    };  
                
                
                    if (subDomain == "www" || subDomain == "penv") {
                        var axwayHost= "https://api.hmhs.com";
                    }else{
                        var axwayHost = "https://apiqa.hmhs.com";
                    }

                    this.headers = {
                        Authorization: 'Bearer ' + $cookies.get("Oauth_access_token"),
                        brandUbk: ubk[$cookies.get("umsBrand").toLowerCase()],
                       
                    };

                  
                    this.url=  axwayHost + "/digital-member/v2/ext/common/eobs/file"
                    var serviceCall = $http({
                        url: this.url,
                        method: "POST",
                        responseType: 'arraybuffer',
                        data: payload,
                        headers: this.headers,
                    });
                    
                    return serviceCall;
                }
            }
        );
    }]);
};