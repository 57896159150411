module.exports = function(app) {
    app.directive('claimsFormsLibrary', function() {

        return {
            restrict: 'A',
            replace: true,
            template: require('directives/claimsFormsLibrary.html'),

            scope: {},

            controller: ['$scope', 'coremetrics', '$cookies', function($scope, coremetrics, $cookies) {

				$scope.claimsFormsLibraryAccess = false;
				var claimsFormsLibraryBrands = ['hmbcbs', 'hbs', 'hmbcbswv', 'hmbcbsde', 'pp', 'de'];
	            var claimsBrandCode = $cookies.get('umsBrand').toLowerCase();
	            if (claimsFormsLibraryBrands.indexOf(claimsBrandCode) > -1) {         
	                $scope.claimsFormsLibraryAccess = true;
	            }

            }]
        };
    });
}