module.exports = function(app) {

    app.controller('pages.secureRegistration.secureRegister.step3', ["$state", "$scope", "services", "coremetrics",
        function($state, $scope, services, coremetrics) {

            $scope.sectionHeading = "To create your password, please enter the information below.  Remember, Your new password cannot include your name. your password needs to be 12 or more characters in length and must contain Uppercase letters, Lowercase letters, Numbers and Special character except <, >, +.  You will also need to choose a security question and answer.  When you have supplied all of the information, click submit.";
            $scope.showPasswordMismatch = false;
            $scope.showSpinner = false;
            $scope.oldPasswordUsed = false;
            $scope.usedPwdError = false;            
            $scope.serverError = false;
            var userId = $state.params.userId || "";
            var token = $state.params.token || "";
            var loginId = $state.params.loginId || "";

            if (userId === "" || token === "") {

             $state.go('secureRegistration.error', {status: "NO_USER"});

            }

            $scope.user = {
                newPassword: "",
                verifyPassword: "",
                securityQuestion: "",
                securityAnswer: ""
            };

            $scope.securitySelect = {
                options: [
                    {label: "In what city were you born?", value: "1"},
                    {label: "What was the name of your first pet?", value: "2"},
                    {label: "What is your favorite food?", value: "3"},
                    {label: "What was your first car?", value: "4"},
                    {label: "What is your favorite vacation spot?", value: "5"}
                ],
                defaultOption: "Please choose your question"
            };

            $scope.nextStep = function() {
                $scope.verifyPasswordMatch();
                if ($scope.createPasswordForm.$valid && !$scope.showPasswordMismatch) {
                    $scope.showSpinner = true;
                    var payload = {
                        username: userId,
                        oldPassword:"",
                        newPassword: $scope.user.newPassword,
                        newSecurityQuestion:$scope.user.securityQuestion.label,
                        newSecurityAnswer:$scope.user.securityAnswer,
                        brand: $scope.brandData.sharedBrandCode.toLowerCase()
                    };

                    services.rbsmbl.passwordSetup().postData(payload, token).then(function (response) {
                        $scope.showSpinner = false;
                        $scope.serverError = false;
                        $scope.oldPasswordUsed = false;
                        $scope.usedPwdError = false;                        
                        $scope.$watch(function () {
                            return response.data.baseMessage;
                        }, function () {
                            if (response.status != 200) {
                                $state.go('secureRegistration.error', { status: response.status, statusText: response.statusText });
                            } else {


                                if (response && response.status && response.data.baseMessage.returnCode === 200) {
                                    var hostname = location.host.split('.');                                  
                                    var hostUrl = hostname[0] === 'www' ? "."+hostname[1] +"."+hostname[2] : location.host;
                                    var HighmarkhostUrl = hostname[0] === 'www' ? ".highmark" +"."+hostname[2] : hostname[0]+".highmark"+"."+hostname[2];
                                    var mbhHostUrl = hostname[0] === 'www' ? ".allmyhealth" +"."+hostname[2] : hostname[0]+".allmyhealth"+"."+hostname[2];

                                    if ($scope.brandData.sharedBrandCode.toLowerCase() === 'mincr') {

                                        //https://employer.bluecrossmnonline.com/login/mn
                                        //https://employertenv3.bluecrossmnonline.com/login/mn
                                        window.location = "https://employer" + hostUrl + "/login/mn";
                                    } else if ($scope.brandData.sharedBrandCode.toLowerCase() === 'ndbcbs') {
                                        window.location = "https://employer" + hostUrl + "/login/nd";
                                    } else if ($scope.brandData.sharedBrandCode.toLowerCase() === 'wybcbs') {
                                        window.location = "https://employer" + hostUrl + "/login/wy";
                                    } else if ($scope.brandData.sharedBrandCode.toLowerCase() === 'hmbcbs' || $scope.brandData.sharedBrandCode.toLowerCase() === 'hmbcbsde' || $scope.brandData.sharedBrandCode.toLowerCase()==='hbs'|| $scope.brandData.sharedBrandCode.toLowerCase()==='wny' || $scope.brandData.sharedBrandCode.toLowerCase()==='neny') {
                                         window.location = "https://employer" + HighmarkhostUrl + "/login";
                                    }
                                    else if ($scope.brandData.sharedBrandCode.toLowerCase()=== 'pp' || hostname[1]==="mybenefitshome") {
                                        window.location = "https://employer" + mbhHostUrl + "/login";
                                   }
                                    else {
                                    services.rbsmbl.login().login(loginId, $scope.user.newPassword).then(function (response) {
                                        if (response.status == 200) {
                                            if (response.data && response.data.payload && response.data.payload.loginInfo && response.data.payload.loginInfo.role.toLowerCase().indexOf("mcu_admin") >= 0) {
                                                window.location = "/employer/client-selection"
                                            }
                                            else {
                                                window.location = "/employer/home"
                                            }


                                        } else {
                                            $state.go('secureRegistration.error', {
                                                status: response.status,
                                                statusText: response.statusText
                                            });
                                        }
                                    })

                                }} else {
                                    //PI found 
                                    //var errorDetail = (response.data.returnCode === 500) ? ' Server Error 500' : ' Error: ' + response.status;
                                    if (response.data.baseMessage.returnCode === 406) {
                                        if (response.data.baseMessage.returnCodeDescription == 'PI-FOUND') {
                                            $scope.oldPasswordUsed = true;
                                            $scope.serverError = false;
                                            $scope.usedPwdError=false
                                        } else if(response.data.baseMessage.returnCodeDescription == 'The provided new password was found in the password history for the user'){
                                            $scope.usedPwdError=true;
                                            $scope.serverError = false;
                                            $scope.oldPasswordUsed = false;                                           
                                        }
                                    } 
                                    else {
                                        $scope.serverError = true;
                                        $scope.oldPasswordUsed = false;
                                        $scope.usedPwdError=false;
                                    }

                                }




                            }
                        })
                    },
                        function (error) {
                            //console.log(error);

                            $state.go('secureRegistration.error', error);
                        }
                    );
                }
            };

            $scope.cancelButton = function() {
               $state.go('secureRegistration.error', { status: "CANCEL_PWD", statusText: "" });
            };

            var hasUpperCase = function(s) {
                return (/[A-Z]/).test(s) ? true : false;
            };
    
            var hasLowerCase = function(s) {
                return (/[a-z]/).test(s) ? true : false;
            };
    
            var hasSpecialChar = function(s) {
                return (/[#!@$%^&*()\-_=|;:'",.?`~\\\/\[\]\{\}]/).test(s) && !(/[<>+]/).test(s) ? true : false;
            };
    
            var hasNumber = function(s) {
                return (/\d/).test(s) ? true : false;
            };
            var hasSpace = function(s){
                
                return /\s/g.test(s) ? true : false;
            
             };

             //new password validation for jan 2023 release
             $scope.overAllPattern = (function() {
                var regexp =/^[a-zA-Z0-9 #!@$%^&*()\-_=|;:\x22\x27,.?`~\\\/\[\]\{\}]+$/;
                return {
                    test: function(value) {
                        if( $scope.requireTel === false ) {
                            return true;
                        }                    
                        return regexp.test(value) && !hasSpace(value) && hasUpperCase(value) && hasLowerCase(value) && hasSpecialChar(value) && hasNumber(value);
                    }
                };
            })();
    
          



            $scope.verifyPasswordMatch = function () {
                if ($scope.user && $scope.user.newPassword && $scope.user.verifyPassword && $scope.user.newPassword !== $scope.user.verifyPassword) {
                    $scope.showPasswordMismatch = true;
                } else {
                    $scope.showPasswordMismatch = false;
                }
            };
        }
    ]);

};
