module.exports = function(app) {
    "use strict";
    app.service('formsLibraryService', ['services', 'teamsite', '$cookies', '$q', 'sessionData', 
        'finder-logic2', 'pharmacyServiceWrapper', 'vendorSSOUtility', 'brand',
            function(services, teamsite, $cookies, $q, sessionData, 
                finderLogic, pharmacyServiceWrapper, 
                vendorSSOUtility, brand) {

        this.getAllForms = function(){
            var deferred = $q.defer();
            teamsite.getAllBranded('Forms Library Item').then(function (forms) {
                var formsArray = [];
                
                try {                
                    for (var form in forms){
                        var formData = JSON.parse(forms[form]);
                        formData["id"] = form;
                        formsArray.push(formData);
                    }

                    deferred.resolve(formsArray);
                } catch (e) {
                    deferred.reject(e);
                }

            }, function (error) {
                return deferred.reject(error);
            });

            return deferred.promise;
        };

        this.filterFormsByEntitlements = function (sessionData) {
            var deferred = $q.defer();

            this.getAllForms().then(function (forms) {
                var filteredForms = [];
                var filterFormForWV = function (forms) {
                    if(brand && brand.current && brand.current.brandVisibility) {
                      var region = brand.current.brandVisibility || {};
                      var places;
                        for(places in region) {
                          region = region[places];
                            if(region === undefined) {
                                region = "";
                            }
                            var regionLowCase = region.toLowerCase();
                            if((regionLowCase === "hmbcbswv") || (regionLowCase === "ms") 
                                || (regionLowCase === "highmarkbcbswv") || (regionLowCase === "mbh:wv")) {

                                forms.filter(function (form, index) { 
                                    if(form.name === "Patient Request for Medical Records Transfer") {
                                        
                                        forms.splice(index,1);
                                        deferred.resolve(forms); 
                                    }
                                });  
                            } 
                        };   
                    }
                };
                
                if (!sessionData.loggedIn) {
                    filterFormForWV (forms);
                    deferred.resolve(forms); 
                }
               
                filteredForms = forms.filter(function (form) {

                    var shouldShowForm = true;
                    var isEntitled = false;

                    var formOptions = form.suppressionOptions;

                    if (formOptions && formOptions.isSuppressible == true) {
                        var clientNumber = $cookies.get('mbrClientNumber');
                        var groupNumber = $cookies.get('mbrGroupNumber');

                        if (formOptions.clientSuppressionList != null) {
                            if (formOptions.clientSuppressionList.indexOf(clientNumber) != -1){
                                var shouldShowForm = false;
                            };
                        }

                        if (formOptions.groupSuppressionList != null) {
                            if (formOptions.groupSuppressionList.indexOf(groupNumber) != -1) {
                                var shouldShowForm = false;
                            }
                        }
                    }

                    if (form.entitlements && sessionData.benefits) {                    
                        var formEntitlements = form.entitlements;
                        var entitlements = {
                            hasMedical: formEntitlements.hasMedical || false,
                            hasDental: formEntitlements.hasDental || false,
                            hasVision: formEntitlements.hasVision || false,
                            hasSpending: formEntitlements.hasSpending || false,
                            hasPharmacy: formEntitlements.hasPharmacy || false,
                            hasMedicare: formEntitlements.hasMedicare || false,
                            hasDirectPay: formEntitlements.hasDirectPay || false
                        };
                        for (var i in entitlements) {
                            if (entitlements[i]) {
                                isEntitled = true;
                                break;
                            }
                        }

                        if (!isEntitled) {
                            return true;
                        }

                        var portalSelectedPlan = services.rbsmbl.memberInfo().getPortalSelectedPlan(sessionData.memberInfo);

                        if (entitlements.hasMedical) {
                            shouldShowForm = (shouldShowForm
                                && sessionData.benefits.medical);
                        }
                      
                        if (entitlements.hasDental) {
                            shouldShowForm = (shouldShowForm
                                && sessionData.benefits.dental);
                        }

                        if (entitlements.hasVision) {
                            shouldShowForm = (shouldShowForm
                                && sessionData.benefits.vision);
                        }

                        if (entitlements.hasSpending) {
                            shouldShowForm = (shouldShowForm
                                && sessionData.benefits.spending);
                        }

                        if (entitlements.hasPharmacy) {
                            shouldShowForm = (shouldShowForm
                                && sessionData.benefits.drug && sessionData.pharmacyBenefitProviderIndicator!=='Prime');
                        }

                        if (entitlements.hasMedicare) {
                            shouldShowForm = false; // Alawys filter Medicare forms post-login.
                        }

                        if (entitlements.hasDirectPay) {
                            shouldShowForm = (shouldShowForm
                                && portalSelectedPlan.directPayIndicator === 'true');
                        }
                    }

                    return shouldShowForm;
                });

                filterFormForWV (filteredForms);    
                deferred.resolve(filteredForms);

            }, function (error) {
                deferred.reject(error);
            })

            return deferred.promise;
        };

        this.isUserEntitledToForm = function (form) {
            if (form.entitlements && sessionData.benefits) {
                var formEntitlements = form.entitlements;
                var entitlements = {
                    hasMedical: formEntitlements.hasMedical || false,
                    hasDental: formEntitlements.hasDental || false,
                    hasVision: formEntitlements.hasVision || false,
                    hasSpending: formEntitlements.hasSpending || false,
                    hasPharmacy: formEntitlements.hasPharmacy || false,
                    hasMedicare: formEntitlements.hasMedicare || false,
                    hasDirectPay: formEntitlements.hasDirectPay || false
                };

                var portalSelectedPlan = services.rbsmbl.memberInfo().getPortalSelectedPlan(sessionData.memberInfo);

                if (entitlements.hasMedical
                    && !sessionData.benefits.medical) {
                    return false;
                } else if (entitlements.hasDental
                    && !sessionData.benefits.dental) {
                    return false;
                } else if (entitlements.hasVision
                    && !sessionData.benefits.vision) {
                    return false;
                } else if (entitlements.hasSpending
                    && !sessionData.benefits.spending) {
                    return false
                } else if (entitlements.hasPharmacy
                    && !sessionData.benefits.drug) {
                    return false;
                } else if (entitlements.hasMeicare
                    && portalSelectedPlan.medicareIndicator !== 'true') {
                    return false;
                } else if (entitlements.hasDirectPay
                    && portalSelectedPlan.directPayIndicator !== 'true') {
                    return false;
                } else {
                    return true;
                }
            }

            return true;
        };

        this.getDrawersAndCategories = function(forms) {
            var drawers = [];
            var otherForms = [];
            var categories = {};

            function getDrawerSubtext (drawer) {
                var deferred = $q.defer();
                var drawerTitle = drawer.split(' ');
                if (drawerTitle && drawerTitle.length > 0) {
                    drawerTitle = drawerTitle[0];
                    teamsite.get("FORMS_LIBRARY_CATEGORY_DESC_" + drawerTitle.toUpperCase()).then(function(data) {
                        deferred.resolve(data);
                    });
                } else {
                    deferred.reject();
                }
                return deferred.promise;
            };

            forms.forEach(function(item) {     
                if (item.isOtherTypeOfForm) {
                    otherForms.push(item);
                    return;
                }
                if (drawers.indexOf(item.category) < 0) { 
                    drawers.push(item.category);
                    categories[item.category] = {
                        drawerOpen: false,
                        forms: []
                    };
                    getDrawerSubtext(item.category).then(function(response) {
                        categories[item.category].drawerDesc = response;
                    });
                }
                categories[item.category].forms.push(item);
            });
            return {
                drawers: drawers,
                categories: categories,
                otherForms: otherForms
            }
        };

        this.performSso = function (theTargetType, coreMetricsTagInfo) {
            var handleSsoResponse = function (prescriptServiceSsoResponse, windowName, theTargetType, coreMetricsTagInfo) {
                var coreMetricsPromoTags = function(target, coreMetricsType) {
                    var cmBrand = $cookies.get('umsBrand').toUpperCase();

                    switch (target) {
                    case 'MAILRX':
                        if (cmBrand == 'IBCCR' || cmBrand == 'IBCAH') {
                            if (coreMetricsType == 'cmLeftNav') {
                                return '?cm_sp=Catamaran-_-Prescription%20Services%20Catamaran-_-Left%20Nav%20Mail%20Order%20Pharmacy';
                            } else {
                                return '?cm_sp=Catamaran-_-Prescription%20Services%20Catamaran-_-Mail%20Order%20Pharmacy';
                            }
                        } else {
                            if (coreMetricsType == 'cmLeftNav') {
                                return '?cm_sp=Medco-_-Prescription%20Services%20Medco-_-Left%20Nav%20Refill%20&%20Order%20Status';
                            } else {
                                return '?cm_sp=Medco-_-Prescription%20Services%20Medco-_-Refill%20&%20Order%20Status';
                            }
                        }

                    case 'PRESCRIPTION_COSTS_AND_SAVINGS':
                        if (cmBrand == 'MINCR') {
                            if (coreMetricsType == 'cmLeftNav') {
                                return '?cm_sp=Prime-_-Prescription%20Services%20Prime-_-Left%20Nav%20Cost%20Savings';
                            } else {
                                return '?cm_sp=Prime-_-Prescription%20Services%20Prime-_-Cost&%20Savings';
                            }
                        } else {
                            if (coreMetricsType == 'cmLeftNav') {
                                return '?cm_sp=Medco-_-Prescription%20Services%20Medco-_-Left%20Nav%20Cost%20&%20Savings';
                            } else {
                                return '?cm_sp=Medco-_-Prescription%20Services%20Medco-_-Cost%20&%20Savings';
                            }
                        }

                    case 'PRESCRIPTION_CLAIMS':
                        if (coreMetricsType == 'cmLeftNav') {
                            return '?cm_sp=Medco-_-Prescription%20Services%20Medco-_-Left%20Nav%20Claims%20&%20History';
                        } else {
                            return '?cm_sp=Medco-_-Prescription%20Services%20Medco-_-Claims%20&%20History';
                        }

                    case 'locatePharmacy':
                        if (cmBrand == 'MINCR') {
                            return '?cm_sp=Prime-_-Prescription%20Services%20Prime-_-Search%20pharmacies';
                        } else if (cmBrand == 'IBCCR' || cmBrand == 'IBCAH') {
                            return '?cm_sp=Catamaran-_-Prescription%20Services%20Catamaran-_-Search%20pharmacies';
                        } else {
                            return '?cm_sp=Medco-_-Prescription%20Services%20Medco-_-Search%20pharmacies';
                        }

                    case 'PRESCRIPTION_MAIL_ORDER_FORMS':
                        return '?cm_sp=Medco-_-Prescription%20Services%20Medco-_-Mail%20order%20How%20it%20works';

                    case 'PRESCRIPTION_TRANSFERS':
                        return '?cm_sp=Medco-_-Prescription%20Services%20Medco-_-Mail%20order%20savings';

                    case 'drugFormularyDisplay':
                        if (cmBrand == 'MINCR' || cmBrand == 'NDBCBS') {
                            return '?cm_sp=Prime-_-Prescription%20Services%20Prime-_-Search%20drug%20list%20Formulary';
                        }
                    
                    case 'PRESCRIPTION_BENEFITS':
                        if (coreMetricsType == 'cmLeftNav') {
                            return '?cm_sp=Medco-_-Prescription%20Services%20Medco-_-Left%20Nav%20Benefit%20Highlights';
                        } else {
                            return '?cm_sp=Medco-_-Prescription%20Services%20Medco-_-Benefit%20Highlights';
                        }

                    default:
                        throw new Error('The value passed to the coreMetricPromoTags method was not recognized.');
                    }
                };

                // TODO: Update once IE fix is determined...
                var cmExt = coreMetricsPromoTags(theTargetType, coreMetricsTagInfo);
                //vendorSSOUtility.launchNewSsoWindow(windowName);
                windowName = "_blank";

                var isWpaCre = finderLogic.getPropertyByBrandCode(theBrand, 'regionIsWpaCre');
                var isDe = finderLogic.getPropertyByBrandCode(theBrand, 'regionIsDe');
                var isWv = finderLogic.getPropertyByBrandCode(theBrand, 'regionIsWv');
                var isAllMbh = finderLogic.getPropertyByBrandCode(theBrand, 'regionIsAllMbh');

                if(glbPsp.pharmacyBenefitProviderIndicator.toLowerCase() === 'medco' && (isWpaCre || isDe || isWv || isAllMbh)  && (theTargetType !== 'drugFormularyDisplay')){
                    var ssoPPB = (isWpaCre || isDe) ? '':'PPB';
                    var ssoUrl = window.location.origin+'/emssvc/x-services/secure/sso/outbound/auto/v1/vendor/MEDCO' + ssoPPB + '?redirectInfo=envelopes';
                    var vendorSSODetails = {
                        ssoUrl : ssoUrl,
                        ssoRequestType:'REDIRECT'
                    };
                    const promoTag = '?cm_sp=Medco-_-Prescription%20Services%20Medco-_-Mail%20order%20How%20it%20works';
                    vendorSSOUtility.genericHandleSSOResponse(vendorSSODetails, promoTag , windowName);
                } else if (prescriptServiceSsoResponse !== null) {
                    prescriptServiceSsoResponse.then(function (vendorSSOStuff) {
                        vendorSSOUtility.genericHandleSSOResponse(vendorSSOStuff, cmExt, windowName);
                    }, function(error) {
                        vendorSSOUtility.genericHandleSSOFailure(error, windowName);
                    });
                } else {
                    vendorSSOUtility.genericHandleSSOFailure("", windowName);
                }
            };

            var ssoWindowName = "";

            if (!theTargetType) {
                // console.log('The hitSsoService was not sent a theTargetType parameter');
                return;
            }
            if (!coreMetricsTagInfo) {
                coreMetricsTagInfo = 'cmMain';
            }

            var glbClientNumber = $cookies.get('mbrClientNumber');
            var glbGroupNumber = $cookies.get('mbrGroupNumber');
            var theBrand = $cookies.get('umsBrand');
            var glbFullMemberInfo = sessionData.memberInfo;
            var glbCurMember = finderLogic.setLibStorageAndReturnCurMember(glbFullMemberInfo);
            var glbPsp = finderLogic.getPortalSelectedPlan();


            if (theTargetType == 'drugFormularyDisplay') {
                var callDrugVendorWrapper = drugVendorWrapper.getDrugVendor(glbClientNumber, glbGroupNumber, 'LANDING', 'DRUG_FORMULARY', theBrand.toUpperCase());
                ssoWindowName = ('SSO_DRUG_FORMULARY').toLowerCase();
                handleSsoResponse(callDrugVendorWrapper, ssoWindowName, theTargetType, coreMetricsTagInfo);

            }
            else if((theTargetType==='PRESCRIPTION_MAIL_ORDER_FORMS') && (brand.current.sharedBrandCode==='ndbcbs')){
                window.open('https://www.walgreens.com/register/pharmacyRegistration.jsp','_blank');
            }
            else {
                var prescriptServiceSsoResponse = pharmacyServiceWrapper.doPharmacySsoCall(glbFullMemberInfo, glbCurMember, glbPsp, theTargetType);
                ssoWindowName = ('sso_pharmacy' + glbPsp.pharmacyBenefitProviderIndicator).toLowerCase();
                handleSsoResponse(prescriptServiceSsoResponse, ssoWindowName, theTargetType, coreMetricsTagInfo);
            }
        };
    }]);
};