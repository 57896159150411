module.exports = function(app) {
    app.controller('pages.claims.dentalProcedureHistory', ['$scope', '$state', 'dentalService', 'coremetrics',
        function($scope, $state, dentalService, coremetrics) {
            coremetrics.elementTag('REFINE:DENTAL PROCEDURE HISTORY','MEMBER:CLAIMS');

            var
                groupNumber = $state.params.groupNumber,
                previousState = $state.params.previousState || 'claims.activitySummary',
                pin,
                currentPin;


            $scope.headings = 'DENTAL PROCEDURE HISTORY';
            $scope.viewState = 'loading';
            $scope.backText = 'Back to Claims';
            $scope.backAction = function() {
                $state.go(previousState);
            };

            $scope.sidebar.links = [
                {
	                heading: '',
	                label: 'Dental History',
	                state: 'claims.dentalProcedureHistory',
	                description: '',
			        refineTemplate: 'pages/claims/dentalProcedureHistoryRefine.html',
			        refineController: 'claims.dentalProcedureHistory'
	            },
            ];

            var procedureCache = {};
            var fetchHistory = function(pin) {
                currentPin = pin;
                if (Array.isArray(procedureCache[pin])) {
                    $scope.procedureList = procedureCache[pin];
                }
                else {
                    dentalService.getDentalProcedureHistory(groupNumber, pin).then(
                        function (responseList) {
                            $scope.procedureList = procedureCache[pin] =
                                responseList.reduce(function (prev, curr) {
                                    if (curr.pin === pin) {
                                        return prev.concat(curr.pfTable);
                                    }
                                }, []);
                            $scope.viewState = 'success';
                        },
                        function (err) {
                            $scope.viewState = 'error';
                            console.error('getDentalProcedureHistory failed with error:', err);
                        }
                    );
                }
            };

            var initForPinPromise = dentalService.getDentalMembersByGroupNumber(groupNumber).then(
                function(dentalMembers) {
                    $scope.sidebar.dentalMembers = dentalMembers;
                    pin = $state.params.pinNumber || dentalMembers[0].pin;
                    $scope.sidebar.selectedPin = pin;

                    return pin;
                },
                function(err) {
                    $scope.viewState = 'error';
                    console.error('getDentalMembersByGroupNumber failed with error', err);
                }
            );


            initForPinPromise.then(
                fetchHistory,

                function(err) {
                    $scope.viewState = 'error';
                    console.error('initForPinPromise failed in dentalProcedureHistory with error', err);
                }
            );

            $scope.$watch('sidebar.selectedPin',
                function(pin) {
                    if (pin !== currentPin) {
                        fetchHistory(pin);
                    }
                }
            );
        }
    ]);
};