module.exports = function(app) {
    require('./preFindDoctor/prescription-drug')(app);
    require('./preFindDoctor/prePassFindDoctor')(app);

    app.controller('pages.preFindDoctor', ["$state", "$scope", "$filter", "$sce", "sessionData", "modal", "brand", "teamsite", "coremetrics",
        function($state, $scope, $filter, $sce, sessionData, modal, brand, teamsite, coremetrics) {

            $scope.headings = "Find A Doctor";
            coremetrics.pageviewTag('FIND A DOCTOR: HOME', 'FIND A DOCTOR');
            $scope.providerKey = sessionData && sessionData.appConfig && sessionData.appConfig.PROVIDER;

            $scope.sidebar.links = [{
                heading: "DOCTORS, FACILITIES & MORE",
                label: "Find Providers",
                state: "prepassFindDoctor",
                refineOverrideOpen: false,
                description: "Looking for an in-network doctor? Need to find a facility near you? You're in the right place. Let us help you find what you need."
            },{
                heading: "DOCTORS, FACILITIES & MORE 124",
                label: "Find a Prescription Drug",
                state: "drug",
                active: false,
                description: "Looking for an in-network doctor? Need to find a facility near you? You're in the right place. Let us help you find what you need."
            }];

            sessionData.isMedicare = window.location.href.search(/medicare=yes/i) !== -1 || sessionData.isMedicare;
            $scope.isWVNonMedicareMem = !((brand.current.sharedBrandCode === "hmbcbswv") && (window.location.href.search(/medicare=yes/i) === -1));

        }
    ]);

    return [
        {
            state: "prepassFindDoctor",
            postPassword: false,
            url: "/find-a-doctor",
            views: {
                'body@': {
                    template: bootstrap.templates['page-templates/categoryCustom.html'],
                    controller: 'pages.preFindDoctor'
                },
                'content@prepassFindDoctor': {
                    template: require('pages/preFindDoctor.html')
                },
                "banner@prepassFindDoctor":      { template: require("pages/preFindDoctor/banner.html") },
                "modules@prepassFindDoctor":     { template: require("pages/preFindDoctor/bottomModules.html") },
                "prepass@prepassFindDoctor": {
                    template: require("pages/preFindDoctor/prePassFindDoctor.html"),
                    controller: "prePassFindDoctor",
                    controllerAs: "prepass"
                },
                "rightColumn@prepassFindDoctor": {}
            }
        },
        {
            state: "drug",
            postPassword: false,
            url: "/drug",
            views: {
                'body@': {
                    template: bootstrap.templates['page-templates/categoryCustom.html'],
                    controller: 'pages.preFindDoctor.prescription-drug'
                },
                "content@drug": {
                    template: require('pages/preFindDoctor/prescription-drug.html'),
                    controller: 'pages.preFindDoctor.prescription-drug'
                }
            }
        }
    ];
};