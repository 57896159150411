module.exports = function(app) {
    app.directive('wellnessHealthyLinks', function() {

        return {
            restrict: 'A',
            replace: true,
            template: require('directives/wellnessHealthyLinks.html'),

            // scope: {},
            controller: ['$scope',
                function($scope) {

                    $scope.showDownloadIcon = true;
                    $scope.noIconClass = "";
                }
            ]
        };
    });
}