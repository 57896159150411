module.exports = function(app) {
    app.directive('virtualMemberServices', [
		function(){
        return {
            restrict: 'A',
            replace: false,

            template:
            '<section class="bottom-modules">' +
            '<div>' +
            '<h2 class="margin-left module-header" data-teamsite="PVD_HOMEMODULES_TRAVELLING_HEADING"></h2>' +
            '<hr>' +
            '<div class="greyGarden">' +
            '<div class="padding-all padding-top">' +
            '<div>' +
            '<p data-teamsite="PVD_HOMEMODULES_TRAVELLING_CONTENT"></p>' +

            '</div>' +
            '</div>' +
            '</div>' +
            '</div>' +
            '</section>'
        };
    }]);
};
