module.exports = function (bootstrap) {
    bootstrap.run(['Service', function(Service) {
        new Service({
            name: "eBillRegistration",
            group: "rbsmbl",
            url: "/rbsmbl/x-services/ebill/v1/register",

            fetch: function (params) {
                var opts = params || {};
                return this.post(opts);
            }
        });
    }]);
};