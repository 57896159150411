module.exports = function(app) {
    app.directive('footerMessage', ['$window', function($window) {

        return {
            restrict: 'A',
            replace: true,
            scope: {
                messageText: '=',
                headerText: '=',
                iconClass: '='
            },

            template: require('directives/footerMessage.html'),
        };
    }]);
};
