module.exports = function (app) {
    // global vars may have to go here
    app.controller('pages.vsbp', ["$state", "$scope", "teamsite", "coremetrics", "session", "$rootScope",
        function ($state, $scope, teamsite, coremetrics, session, $rootScope) {
            $scope.redirectURL = $state.params.redirect ?  decodeURIComponent($state.params.redirect) : "/mbr/#!/landing";
            teamsite.get('VSBP_CHOOSE_PLAN_TITLE').then(function(data) {
                // putting a class name in imgHtml did not work for me (pds)  class="leftNavImage"
                var imgHtml = '<img src="./resources/img/Summary_image_VSBP.png" alt="" ' +
                    ' style="margin-bottom: 12px; " /><br />';
                $scope.sidebar.heading = imgHtml + data;
            });
            teamsite.get('VSBP_CHOOSE_PLAN_DESC1').then(function(data1) {
                teamsite.get('VSBP_CHOOSE_PLAN_DESC2').then(function(data2) {
                    $scope.sidebar.description = data1 + '<br /><br />' + data2;
                });
            });
            $scope.sidebar = {}
            $scope.headings = "View Site by Plan";
        }
    ]);

    return [
        {
            state: "vsbp",
            postPassword: true,
            appConfigMaintenanceApp: 'VSBP',
            appConfigMaintenanceKey: 'FULL_MAINTENANCE',
            data: {
                headerText: 'View Site By Plan'
            },
            url: "/vsbp?redirect",
            views: {
                'body@': {
                    template: bootstrap.templates['page-templates/categoryCustom.html'],
                    controller: 'pages.vsbp'
                },
                'content@vsbp': {
                    template: require('pages/vsbp.html')
                },
                "rightColumn@vsbp": {
                }
            }
        }
    ];
};
