module.exports = function(bootstrap) {
    bootstrap.run(['Service', 'appId', function(Service, appId) {
        new Service(
            {
                name: 'dentalProcedureHistory',
                group: 'rbsmbl',
                url: '/rbsmbl/x-services/claimsData/v2/dentalProcedureHistory',

                fetch: function(groupNumber, pin) {
                    var requestPayload = {
                        "reqDentalProcedureHistoryRequest": {
                            "baseMessage": {
                                "exchangeType": "SENDREQUEST",
                                "headerVersion": "1.0",
                                "username": appId
                            },
                            "payload": {
                                "reqDentalProcedureHistory": {
                                    "groupNum": groupNumber,
                                    "pin": pin
                                }
                            }
                        }
                    };

                    return this.post(requestPayload);
                }
            }
        );
    }]);
};