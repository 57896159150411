module.exports = function (app) {
    app.factory('updateVisited', ['services',
        function (services) {

            var updateVisited = function (destination) {
                destination = typeof destination === 'string'
                    ? destination.toLowerCase()
                    : 'No destination supplied as an argument to updateVisited';

                return function() {
                    var memberInfoPromise = services.rbsmbl.memberInfo().fetch();

                    var getBvUserId = memberInfoPromise.then(
                        function (response) {
                            return response.data.payload.bvUserId;
                        },
                        function (err) {
                            console.error('memberInfo().fetch() failed with error', err);
                            return memberInfoPromise;
                        }
                    );

                    return getBvUserId.then(
                        function (bvUserId) {
                            switch (destination) {
                                case 'mhslanding':
                                    return services.rbsmbl.visitedMHSLanding().fetch(bvUserId);

                                case 'claimsandpayments':
                                    return services.rbsmbl.visitedClaimsAndPayments().fetch(bvUserId);

                                case 'messagecenter':
                                    return services.rbsmbl.visitedMessageCenter().fetch(bvUserId);

                                case 'educationalmaterial':
                                    return services.rbsmbl.visitedEducationalMaterial().fetch(bvUserId);
                                    
                                case 'myoralhealth':
                                	return services.rbsmbl.visitedMyOralHealth().fetch(bvUserId);

                                default:
                                    return console.error('could not find updateVisited service for unknown destination: ', destination);
                            }
                        },
                        function (err) {
                            console.error('getBvUserId failed with error', err);
                            return getBvUserId;
                        }
                    );
                };
            };

            return {
                mhsLanding: updateVisited('MHSLanding'),
                claimsAndPayments: updateVisited('ClaimsAndPayments'),
                messageCenter: updateVisited('MessageCenter'),
                educationalMaterial: updateVisited('EducationalMaterial'),
                myOralHealth: updateVisited('MyOralHealth')
            };
        }
    ]);
};