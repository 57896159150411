module.exports = function (bootstrap) {
    bootstrap.run(["Service", function (Service) {
        new Service({
            name: "pdfPrepopulation",
            group: "rbsmbl",
            url: "/rbsmbl/x-services/secure/member/prefill/pdfforms",
            rootUrl: "/rbsmbl/x-services/secure/member/prefill/pdfforms",

            fetch: function (formName, downloadPdf) {
                
                this.url = this.rootUrl + "?formName=" + formName + "&downloadPdf=" + downloadPdf;
                
                return this.get();
            }
        });
    }]);
};