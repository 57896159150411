module.exports = function(app) {
    "use strict";
    app.service('myChartUtil', ['services', 'sessionData', function(services, sessionData) {

        this.hasActivePlan = function(){
            var products = services.rbsmbl.memberInfo().getUniqueProducts(sessionData.memberInfo),
                i = 0,
                coverageBeginDate,
                coverageEndDate,
                today = new Date();

            today.setHours(0);
            today.setMinutes(0);
            today.setSeconds(0);
            today.setMilliseconds(0);

            for(i; i < products.length; i++){
                if(products[i].productData.benefitPlanType === 'Medical'){
                    coverageBeginDate = new Date(products[i].productData.coverageBeginDate);
                    coverageEndDate = new Date(products[i].productData.coverageEndDate);

                    if(today >= coverageBeginDate && today <= coverageEndDate){
                        return true;
                    }
                }
            }

            return false;
        };

        this.hasEligibleClaims = function(summary){
            var i = 0;

            for(i; i<summary.length; i++){
                if(summary[i].sysSrcCode === 'medical'){
                    return true;
                }
            }
            return false;
        };

    }]);
};