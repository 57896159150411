module.exports = function(app) {
    app.controller("pages.spending.receiptRequired", ["$state", "$scope", "$rootScope", "session", "$location", "$filter", "services", "storage", "receiptImageDataService", "virusScanService", "receiptRequiredSubmitService", 'claimService', "smoothFocus", "coremetrics",
        function($state, $scope, $rootScope, session, $location, $filter, services, storage, receiptImageDataService, virusScanService, receiptRequiredSubmitService, claimService, smoothFocus, coremetrics) {

            $scope.$parent.backText = 'Back to Spending Claims';
            $scope.$parent.backAction = function() {
                if($scope.claimType) {
                    window.location.href = "/spa/#/spendingClaims?path=" + $scope.claimType;
                } else{
                    window.location.href = "/spa/#/spendingClaims";
                }
            };



            var appConfig = (session.data && session.data.appConfig && session.data.appConfig.SPENDING) ? session.data.appConfig.SPENDING : {},
                claimsMatchingToggle = (appConfig && appConfig.CLAIMS_MATCHING && appConfig.CLAIMS_MATCHING.booleanValue === true) ? true : false;

            $scope.viewState = 'loading';

            $scope.pagination = {
                itemsPerPage: '10'
            };
            $scope.pagination.page = 1;

            $scope.pathSelection = "newReceipt";
            $scope.resetPathSelection = function() {
                if (!$scope.existingReceiptSubmitSuccess) {
                    $scope.receiptStorageData = "";
                    receiptStorage = "";
                }
            };

            var claimStorage = JSON.parse(sessionStorage.hmSessionStorage2).summaryData.data;
            var receiptStorage = JSON.parse(sessionStorage.hmSessionStorage2).selectedReceipt;
            var clientStorage = JSON.parse(sessionStorage.hmSessionStorage2).selectedClient;

            $scope.spendingClaimData = claimStorage ? claimStorage : false;
            $scope.claimType = ($scope.spendingClaimData && $scope.spendingClaimData.claimCategory && $scope.spendingClaimData.claimCategory.toLowerCase() == "debit card") ? "debit" : "manual";
            $scope.claimProvider = ($scope.spendingClaimData && $scope.spendingClaimData.provider) ? $scope.spendingClaimData.provider : "no provider";
            $scope.receiptStorageData = receiptStorage ? receiptStorage : false;

            $scope.deleteChar = String.fromCharCode(215);
            $scope.viewFileButtonLabel = "view receipt";
            $scope.loadReceiptDataClass = "not-loading";

            // add new file
            $scope.hasFileInfo = false;
            $scope.fileStatusIcon = "";
            $scope.hasFileData = false; //hide example table
            $scope.pluginState = false; // disable button until plugin has initialized
            $scope.fileValid = "disabled";
            $scope.virusFound = false;
            $scope.fileChoice = false;
            $scope.showPicker = true;
            $scope.fileCount = 0;
            $scope.newFilePayload = false;
            $scope.newSubmitState = false; // disable submit until new file data ready
            $scope.newReceiptSubmissionMade = false;
            var fileArray = [];

            //existing file
            var returnedBinary = "",
                orbFileBinary = "";
            $scope.existingReceiptSubmissionMade = false;

            $scope.receiptRequiredPayload = "";

            // claims matching
            $scope.weHaveClaims = false;
            $scope.weHaveNoClaims = false;
            $scope.claimsCallLoader = false;
            $scope.matchFundThreshold = false;
            $scope.claimsCallResolved = false;
            $scope.claimsCallError = false;
            $rootScope.cancelMatchFundThresholdError = function() {
                $scope.matchFundThresholdError = false;
            };
            $rootScope.confirmationState = false;
            storage.set("claimsToMatch", null); // set claims to match array to false to begin

            $scope.fetchClaimsToMatch = function() {
                $scope.claimsCallLoader = true;
                var dateRange = JSON.stringify({
                    startDate: $filter('date')(new Date() - 730 * 60 * 60 * 24 * 1000, 'yyyyMMdd'),
                    endDate: $filter('date')(new Date(), 'yyyyMMdd')
                });
                claimService.getSummary(dateRange,$scope.claimProvider).then(function(claimsData) {

                        var claims = [];
                        var unfilteredClaims = claimsData.summary;
                        for (var x = 0; x < unfilteredClaims.length; x++) {
                            if (unfilteredClaims[x].claimMatchFlag) {
                                console.log("claimsMatchFlag hit",unfilteredClaims[x].claimMatchFlag);
                                claims.push(unfilteredClaims[x]);
                            }
                        }

                        $scope.claimsCallLoader = false;
                        $scope.claimsCallResolved = true;
                        $scope.weHaveClaims = (claims.length) ? true : false;
                        $scope.weHaveNoClaims = (claims.length) ? false : true;

                        var provArray = [];
                        for (var i = 0; i < claims.length; i++) {
                            var providerAlreadyInArray = provArray.indexOf(claims[i].bilgProvOrgNm) >= 0;
                            if (!providerAlreadyInArray) {
                                provArray.push(claims[i].bilgProvOrgNm);
                            }
                        }
                        for (var z = 0; z < provArray.length; z++) {
                            provArray[z] = provArray[z].toUpperCase();
                        }

                        provArray.sort(function(a, b){
                            if (a < b) {
                                return -1;
                            }
                            else if (a > b) {
                                return 1;
                            }
                            else {
                                return 0;
                            }
                        });

                        // get the provider names from the summary call, create an array
                        $scope.providerNameArray = provArray;
                        var doctorArray = [];
                        var claimArray = [];
                        $scope.filteredClaimsArray = [];
                        $scope.providersFiltered = 0;

                        // use the checkboxes to add and remove providers (and their claims) from the filter
                        $scope.providerFilterArray = function(provider) {
                            var providerAlreadyInArray = doctorArray.indexOf(provider) >= 0;

                            if (!providerAlreadyInArray) {
                                doctorArray.push(provider);
                                $scope.providersFiltered++;
                                console.log("doctor array", doctorArray);
                                // add this provider's claims to the filtered list
                                for (var x = 0; x < claims.length; x++) {
                                    if (provider === claims[x].bilgProvOrgNm.toUpperCase()) {
                                        claimArray.push(claims[x]);
                                    }
                                }
                            } else {
                                doctorArray.splice(doctorArray.indexOf(provider), 1);
                                $scope.providersFiltered--;
                                console.log("doctor array", doctorArray);
                                // remove this provider's claims from the filtered list
                                for (var y = 0; y < claims.length; y++) {
                                    if (provider === claims[y].bilgProvOrgNm.toUpperCase()) {
                                        claimArray.splice(claimArray.indexOf(claims[y]), 1);
                                    }
                                }
                            }

                            // each time we check a provider box, we need to sort the claims descending by service begin date
                            claimArray.sort(function (a, b) {
                                var dateA = a.bilgProvSvcBgnDt.replace(/(\d{4})(\d{2})(\d{2})/, "$1/$2/$3");
                                var dateB = b.bilgProvSvcBgnDt.replace(/(\d{4})(\d{2})(\d{2})/, "$1/$2/$3");
                                return new Date(dateB) - new Date(dateA);
                            });

                            $scope.filteredClaimsArray = claimArray;
                        };

                        $scope.filterProviders = function() {
                            $scope.filteredClaimsArray = [];
                            $scope.filteredClaimsArray = claimArray;
                        };

                        $scope.confirmationSetup = function() {
                            if (!$scope.matchFundThreshold) {
                                $scope.matchFundThresholdError = true;
                            } else {
                                $scope.matchFundThresholdError = false;

                                if (storage.get("claimsToMatch")) {
                                    var selectedClaimsArray = JSON.parse(storage.get("claimsToMatch"));
                                    var confirmationClaims = [];

                                    for (var a = 0; a < selectedClaimsArray.length; a++) {
                                        for (var b = 0; b < claimArray.length; b++) {
                                            if (selectedClaimsArray[a] === claimArray[b].claimNumber) {
                                                confirmationClaims.push(claimArray[b]);
                                            }
                                        }
                                    }

                                    $scope.claimsForConfirmation = confirmationClaims;
                                    storage.set("confirmationClaims", confirmationClaims);

                                    $rootScope.confirmationState = true;
                                    coremetrics.elementTag('NEXT: SPENDING', 'MEMBER:SPENDING');
                                    $state.go('spending.claimsMatchingConfirm');


                                }

                            }

                        };

                    },
                    function(err) {
                        $scope.claimsCallLoader = false;
                        $scope.claimsCallError = true;
                        console.error('getSummary failed with error', err);
                    });
            };


            if ($scope.spendingClaimData) {

                $scope.spendingClaimAmount = Number($scope.spendingClaimData.amount);
                $rootScope.selectedClaimAmount = 0;
                $rootScope.$watch('selectedClaimAmount', function() {

                    if ($rootScope.selectedClaimAmount < $scope.spendingClaimAmount) {
                        $scope.matchFundThreshold = false;
                    } else {
                        $scope.matchFundThreshold = true;
                    }
                });

                /*
                 //this will open the spending claim drawer initially, if we need to do that
                 $scope.spendingClaimData.isOpen = 'true';
                 */

                // if claims matching and debit card transaction, show the Match radio button
                $scope.isClaimsMatching = (claimsMatchingToggle && $scope.spendingClaimData.claimCategory.toLowerCase() == "debit card") ? true : false;

                // if ORB receipt selected
                if ($scope.receiptStorageData) {
                    console.log("2222", $scope.receiptStorageData);
                    $scope.pathSelection = "existingReceipt";
                    $scope.orbFileName = $scope.receiptStorageData.shoeboxImages[0].name;
                    $scope.orbFileID = $scope.receiptStorageData.ID;
                    $scope.orbFileSize = $scope.receiptStorageData.shoeboxImages[0].size;
                    $scope.getImageRequestPayload = {
                        "participantCode": $scope.receiptStorageData.umi,
                        "groupCode": $scope.receiptStorageData.clientNumber,
                        "clientName": "Mobile",
                        "userType": "EE",
                        "shoeboxID": $scope.receiptStorageData.shoeboxImages[0].shoeboxID,
                        "imageID": $scope.receiptStorageData.shoeboxImages[0].ID
                    };

                    $scope.receiptRequiredPayload = {
                        "participantCode": clientStorage.participantCode,
                        "groupCode": claimStorage.groupCode,
                        "clientName": "Mobile",
                        "userType": "EE",
                        "clmID": claimStorage.claimID,
                        "NewShoeboxItem": {
                            "ID": $scope.orbFileID,
                            "category": claimStorage.claimCategory,
                            "amount": claimStorage.amount,
                            "serviceDate": claimStorage.serviceDate,
                            "provider": claimStorage.provider,
                            "note": "",
                            "patientName": claimStorage.participantName,
                            "paymentMode": claimStorage.paymentType,
                            "shoeboxImage": {
                                "name": $scope.orbFileName,
                                "size": $scope.orbFileSize,
                                "imageData": ""
                            }
                        }
                    };

                }

                $scope.goToORB = function() {
                    coremetrics.elementTag('CHOOSE RECEIPT: SPENDING', 'MEMBER:SPENDING');
                    window.location.href = "/spa/#/receipts?path=receiptrequired";
                };
                $scope.goToSpendingClaims = function() {
                    window.location.href = "/spa/#/spendingClaims";
                };

                $scope.resetNewReceipt = function() {
                    $scope.refreshPathSelection();
                    $scope.newReceiptSubmissionMade = false;
                    $scope.newReceiptSubmitError = false;
                    $scope.existingReceiptSubmitError = false;
                };

                $scope.getReceiptImageData = function() {
                    if (!$scope.existingReceiptSubmitSuccess) {
                        $scope.loadReceiptDataClass = "loading";
                        $scope.viewFileButtonLabel = "loading...";
                        console.log("receiptStorageData", $scope.receiptStorageData);
                        var userName = session.data.userName;
                        receiptImageDataService.getData($scope.getImageRequestPayload).then(
                            function(response) {
                                coremetrics.elementTag('VIEW RECEIPT: SPENDING', 'MEMBER:SPENDING');
                                $scope.loadReceiptDataClass = "not-loading";
                                $scope.viewFileButtonLabel = "view receipt";
                                returnedBinary = response.data.GetImageResponseMessage.payload.shoeboxImage.imageData;
                                orbFileBinary = "data:image/png;base64," + returnedBinary;
                                window.URL = window.URL || window.webkitURL;
                                if (window.URL) {
                                    window.open(orbFileBinary);
                                }
                            },
                            function(err) {
                                $scope.loadReceiptDataClass = "loading";
                                $scope.viewFileButtonLabel = "file cannot be loaded.";
                                console.error('fetch failed in getData', err);
                                return err;
                            }
                        );
                    }
                };



                // function to format bytes, will move to a filter
                var formatBytes = function(bytes, decimals) {
                    if (bytes === 0) {
                        return '0 bytes'
                    }
                    var k = 1000,
                        dm = decimals + 1 || 3,
                        sizes = [' bytes', ' kb', ' mb'],
                        i = Math.floor(Math.log(bytes) / Math.log(k));
                    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + '' + sizes[i];
                };

                $scope.removeFile = function() {
                    if (!$scope.newReceiptSubmitSuccess) {
                        $scope.hasFileInfo = false;
                        $scope.hasFileData = false;
                        //console.log("file array", fileArray);
                        $scope.showPicker = true;
                        $scope.pluginState = true;
                        fileInput.disable(false);
                        fileInput.refresh();
                    }
                };

                $scope.removeReceiptCMTag = function () {
                    coremetrics.elementTag('REMOVE RECEIPT: SPENDING', 'MEMBER:SPENDING');
                };

                $scope.refreshPathSelection = function() {
                    console.log("pathSelection",$scope.pathSelection);

                    if ($scope.pathSelection === 'newReceipt') {
                        setTimeout(function() {
                            fileInput.refresh();
                        }, 250);
                        $scope.pluginState = true;
                        $scope.fileCount = 0;
                        $scope.fileChoice = false;
                        fileArray = [];
                        $scope.removeFile();
                        $scope.newFilePayload = false;
                        $scope.newSubmitState = false; // disable submit until new file data ready
                        resetClaimSelections();
                    } else if ($scope.pathSelection === 'existingReceipt') {
                        $scope.resetPathSelection();
                        resetClaimSelections();
                    } else if ($scope.pathSelection === 'match') {
                        $scope.resetPathSelection();
                        $scope.fetchClaimsToMatch();
                        resetClaimSelections();
                    }
                };

                $scope.cancelClick = function () {
                    coremetrics.elementTag('CANCEL: SPENDING', 'MEMBER:SPENDING');
                };

                var resetClaimSelections = function () {
                    $('.provider-list input:checkbox').removeAttr('checked');
                    storage.set("claimsToMatch", "");
                    storage.set("claimsMatchTotal", "");
                    storage.set("confirmationClaims", "");
                };

                // new file

                // designate a DOM element as the file picker button
                var fileInput = new mOxie.FileInput({
                    browse_button: 'file-picker'
                });

                fileInput.onmousedown = function(e) {
                    $scope.hasFileData = false; // hide example table
                    $scope.hasFileInfo = false; // hide file info
                    //$scope.pluginState = false; // disable button while processing
                    $scope.fileChoice = false;
                    $scope.showPicker = true;
                    $scope.virusFound = false;
                    coremetrics.elementTag('UPLOAD FILE: SPENDING', 'MEMBER:SPENDING');
                };

                // fires when user closes file dialog after a file is selected
                fileInput.onchange = function(e) {

                    $scope.fileChoice = true;
                    // array of selected files
                    fileArray = e.target.files;
                    $scope.fileCount++;

                    for (var i = 0, theFile; theFile = fileArray[i]; i++) {

                        // check mime type and handle error
                        var fileFilter = "application/pdf,image/jpeg,image/tif,image/x-tif,image/tiff,image/x-tiff,application/tif,application/x-tif,application/tiff,application/x-tiff,image/ibm_ondemand";
                        if (!(theFile.type.match(fileFilter.replace(/,/g, "|")))) {
                            console.log("type: ", theFile.type);
                            $scope.$apply(function() {
                                $scope.pluginState = true;
                                $scope.fileValid = false;
                                fileInput.disable(false);
                                $scope.showPicker = true;
                                $scope.fileErrorText = "Invalid file type. File must be either TIFF, PDF, or JPEG.";
                            });
                        }
                        // check file size
                        else if (theFile.size > (Math.pow(1024, 2) * 5)) {
                            console.log("size: ", theFile.size);
                            $scope.$apply(function() {
                                $scope.pluginState = true;
                                $scope.fileValid = false;
                                fileInput.disable(false);
                                $scope.showPicker = true;
                                $scope.fileErrorText = "\"File is too big.\"";
                            });
                        }
                        // handle the file that passes the type and size tests
                        else {

                            fileInput.disable(true);

                            $scope.fileValid = true;
                            $scope.fileName = theFile.name;
                            $scope.fileMIME = theFile.type;
                            $scope.fileSize = formatBytes(theFile.size);
                            $scope.rawFileSize = theFile.size;

                            // preload state
                            $scope.$apply(function() {
                                $scope.fileStatusIcon = "loader";
                                $scope.hasFileInfo = true;
                                $scope.showPicker = false;
                                //smoothFocus.clearTab();
                                $scope.virusFound = false;
                            });

                            var reader = new mOxie.FileReader();

                            reader.onload = (function(theFile) {
                                return function(e) {
                                    $scope.fileBinary = e.target.result;
                                };
                            })(theFile);

                            console.log("rr", theFile);

                            reader.onloadend = function(theFile) {

                                var binaryOnly = $scope.fileBinary.split(',')[1];

                                // virus scan
                                virusScanService.getData(binaryOnly).then(
                                    function(response) {
                                        var parsedResult = response.data;
                                        console.log("result scan", response.data);
                                        // virus is there
                                        if (parsedResult && parsedResult.baseMessage && parsedResult.baseMessage.returnCodeDescription === "Virus detected") {
                                            //$scope.$apply(function () {
                                            $scope.fileBinary = "";
                                            $scope.pluginState = true;
                                            $scope.virusFound = true;
                                            $scope.fileErrorText = "\"Virus detected.  Please try another file\"";
                                            fileInput.disable(false);
                                            $scope.showPicker = true;
                                            fileInput.refresh();
                                            $scope.hasFileInfo = false;
                                            //});
                                        }
                                        // no virus
                                        else {
                                            $scope.pluginState = false;
                                            $scope.hasFileData = true;
                                            $scope.fileStatusIcon = "view-eye";
                                            fileInput.disable(true);
                                            $scope.showPicker = false;
                                            fileInput.refresh();


                                            // new file receipt required payload

                                            var claimInfo = claimStorage;
                                            $scope.receiptRequiredPayload = {
                                                "participantCode": clientStorage.participantCode,
                                                "groupCode": claimInfo.groupCode,
                                                "clientName": "Mobile",
                                                "userType": "EE",
                                                "clmID": claimInfo.claimID,
                                                "NewShoeboxItem": {
                                                    "ID": "",
                                                    "category": claimInfo.claimCategory,
                                                    "amount": claimInfo.amount,
                                                    "serviceDate": claimInfo.serviceDate,
                                                    "provider": claimInfo.provider,
                                                    "note": "",
                                                    "patientName": claimInfo.participantName,
                                                    "paymentMode": claimInfo.paymentType,
                                                    "shoeboxImage": {
                                                        "name": $scope.fileName,
                                                        "size": $scope.rawFileSize,
                                                        "imageData": binaryOnly
                                                    }
                                                }
                                            };

                                            $scope.newFilePayload = true;
                                            $scope.newSubmitState = true;

                                        }
                                    },
                                    function(err) {
                                        console.error('fetch failed in getData', err);
                                        return err;
                                    }
                                );
                                // end virus scan


                            };

                            reader.readAsDataURL(theFile);

                            $scope.showFile = function() {
                                if (!$scope.newReceiptSubmitSuccess){
                                    if ($scope.hasFileData) {
                                        coremetrics.elementTag('VIEW RECEIPT: SPENDING', 'MEMBER:SPENDING');
                                        window.URL = window.URL || window.webkitURL;
                                        if (window.URL) {
                                            window.open($scope.fileBinary);
                                        }
                                    }
                                }
                            };


                        }

                    }

                };

                // initialize plugin
                fileInput.init();

                // when the plugin has been initialized, remove disabled class from the button
                fileInput.onready = function() {
                    $scope.$apply(function() {
                        $scope.pluginState = true;
                    });
                };

                // handle back to Verify
                var backToVerify = storage.get("backToVerify") ? true : false;
                if (backToVerify) {
                    $scope.pathSelection = "match";
                    $scope.refreshPathSelection();
                    storage.set("backToVerify", false);
                    console.log("claimsMatchTotal",storage.get("claimsMatchTotal"));
                    console.log("confirmationClaims",storage.get("confirmationClaims"));

                    //storage.set("claimsMatchTotal", false);
                    //storage.set("confirmationClaims", false);
                }

                //end new file

                $scope.newFileSubmit = function(mode) {
                    //smoothFocus.clearTab();
                    coremetrics.elementTag('SUBMIT: SPENDING', 'MEMBER:SPENDING');
                    if (mode === "new") {
                        $scope.newReceiptSubmissionMade = true;
                        $scope.newReceiptLoad = true;
                        receiptRequiredSubmitService.getData($scope.receiptRequiredPayload).then(
                            function(response) {
                                $scope.newReceiptLoad = false;
                                if (response && response.statusText && response.statusText === "OK") {
                                    $scope.newReceiptSubmitSuccess = true;
                                    coremetrics.elementTag('ATTACH RECEIPT RADIO: SPENDING', 'MEMBER:SPENDING');
                                } else {
                                    $scope.newReceiptSubmitError = true;
                                }
                            },
                            function(err) {
                                $scope.newReceiptSubmitError = true;
                                $scope.newReceiptLoad = false;
                            }
                        );
                    } else if (mode === "existing") {
                        $scope.existingReceiptSubmissionMade = true;
                        $scope.existingReceiptLoad = true;
                        receiptRequiredSubmitService.getData($scope.receiptRequiredPayload).then(
                            function(response) {
                                $scope.existingReceiptLoad = false;
                                if (response && response.statusText && response.statusText === "OK") {
                                    $scope.existingReceiptSubmitSuccess = true;
                                    coremetrics.elementTag('CHOOSE RECEIPT RADIO: SPENDING', 'MEMBER:SPENDING');
                                } else {
                                    $scope.existingReceiptSubmitError = true;
                                }
                            },
                            function(err) {
                                $scope.existingReceiptSubmitError = true;
                                $scope.existingwReceiptLoad = false;
                            }
                        );
                    }
                };

                $scope.viewState = 'success';

            } else {
                $scope.viewState = 'error';
            }

        }
    ]);
};