module.exports = function(bootstrap) {
    bootstrap.run(['Service', 'appId', '$cookies', function(Service, appId, $cookies) {
        new Service(
            {
                name: 'messageDeleteFlag',
                group: 'rbsmbl',
                url: '/rbsmbl/member-info/v1/ext/member/messages/v1/delstatusflag',
                headers:{ Authorization: 'Bearer '+  $cookies.get("Oauth_access_token")},
                update: function(messageList) {
                    var requestPayload = {
                        "reqUpdateDeleteStatusFlagRequest": {
                            "baseMessage": {
                                "exchangeType": "SENDREQUEST",
                                "headerVersion": "1.0",
                                "username": appId
                            },
                            "payload": {
                                "reqUpdateDeleteStatusFlagData": messageList
                            }
                        }
                    };
                    var hostnameArray = window.location.hostname.split('.');
                    var subDomain = hostnameArray[0];
                    if (subDomain == "www" || subDomain == "penv") {
                        var axwayHost= "https://api.hmhs.com";
                    }else{
                        this.headers["x-hmhs-test-backend"] = "wasintertenv" + subDomain.slice(-1) + ".highmark.com" ;
    
                    }
                    var axwayHost = "https://apiqa.hmhs.com";
                    this.url= axwayHost + this.url;

                    return this.post(requestPayload);
                }
            }
        );
    }]);
};