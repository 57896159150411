module.exports = function(app) {
    app.filter('formatPhone', function() {
        return function (phoneNumber, mask) {
            if (!phoneNumber) { //no number provided
                return '';
            }

            var
                origVal = phoneNumber.replace(/[^\d+]/gi, '').split(''),  //clean up the input
                formatMask = mask || "(ddd) ddd-dddd",                    //set a defult mask
                result = [], i = formatMask.length;

            // not a valid phone number
            if (origVal.length < 10) {
                return phoneNumber;
            }

            while (i--) {
                if (formatMask[i] === 'd') {
                    if (origVal.length > 0) {
                        result.unshift(origVal.pop());
                    }
                }
                else {
                    result.unshift(formatMask[i]);
                }
            }
            //if there are remaining digits in the original value, prepend them to the result
            result = (origVal.length > 0 ? origVal.join('') + ' ' : '') + result.join('');

            return result;
        };

    });
};