module.exports = function(app) {
    var securityUpdatesModal = require("../../modals/securityUpdatesModal");
    app.controller('pages.sapphireLanding.sapphireLogin', ["$state", "$scope", "$location", "services", "teamsite", "navigation", "brand", "brandConfig", "coremetrics", "modal", "storage", "session",
        "storageOld", "$window", "sessionData",
        function($state, $scope, $location, services, teamsite, navigation, brand, brandConfig, coremetrics, modal, storage, session,
                 storageOld, $window, sessionData) {

            $scope.loginErrorTitle = null;
            $scope.loginErrorText = null;
            $scope.oauthLogin = false;
            $scope.guideWellRedirect = $location.search().returnTo;

            $scope.theDomain = function() {
                var theDomain = 'https://' + window.location.host;
                return theDomain;
            };

            var origPrePasswordMemberFooter = {};
            var origPrePasswordMemberHeader = {};
            if( brand.current.sharedBrandCode == "mincr"){
                var env = window.location.host.split(".")[0];
                var sufix = env === 'www' ? "" : ".qa";
                window.location="https://www"+sufix+".bluecrossmn.com/fadlogin";
                return;
            }
            removeNavigation();

            $scope.goToRegistration = function () {
                storageOld.set('vitalsLanding', 'true');
                window.open('/login/#/register');
            };
            var hostnameArray = window.location.hostname.split('.'),
                subDomain = hostnameArray[0],
                brandDomain= hostnameArray[1],
                currentConfig ={};
            var oAuthMfaRedirect = function(){

                var environmentConfigs =
                {
                    highmarkbcbs:{
                        test: {
                            environmentType: 'test.',
                            client_id: '526f617-04d5-64b5-84a637376414845716c44304755673d3d'

                        },
                        prod: {
                            environmentType: '',
                            client_id: '64302b7-7724-2736-4737239755430515a2f61596e6a413d3d'
                        },
                        domain:'legacy-portal-apps-domain'

                    },
                    highmarkbcbswv:{
                        test: {
                            environmentType: 'test.',
                            client_id: '4e52355-96a5-8304-462456e435569577a6971414633673d3d'

                        },
                        prod: {
                            environmentType: '',
                            client_id: '71774c4-54d7-a4c7-341726556344b44416549424f79773d3d'
                        },
                        domain:'legacy-portal-apps-domain'

                    },
                    highmarkbcbsde:{
                        test: {
                            environmentType: 'test.',
                            client_id: '69326d6-8496-2726-c57726271676b74745a4b6f3975673d3d'

                        },
                        prod: {
                            environmentType: '',
                            client_id: '5463576-76e7-8417-26b307a59797949553179563374773d3d'
                        },
                        domain:'legacy-portal-apps-domain'

                    },
                    highmarkblueshield:{
                        test: {
                            environmentType: 'test.',
                            client_id: '4d704e6-b435-86c6-e495849576e4f335a496d345976413d3d'

                        },
                        prod: {
                            environmentType: '',
                            client_id: '4533796-b4e3-4437-5596861317445544738444f4137773d3d'
                        },
                        domain:'legacy-portal-apps-domain'

                    },
                    mybenefitshome:{
                        test:{
                            environmentType:"https://test.auth.allmyhealth.com/oauth2/rest/authz?",
                            client_id:"39597a4-4426-a2b4-f4c693846585557786c37533164773d3d"
                        },
                        prod: {
                            environmentType: "https://auth.allmyhealth.com/oauth2/rest/authz?",
                            client_id: "466f623-0544-44b6-3565242546e4262544439694162413d3d"
                        },
                        domain:'allmyhealth'
                    }

                };

                if (subDomain == 'www' || subDomain == 'penv'){
                    currentConfig = environmentConfigs[brandDomain].prod;

                }else{
                    currentConfig = environmentConfigs[brandDomain].test;

                }
                var
                    oamAuthorizeRoot = brandDomain=="mybenefitshome"? currentConfig.environmentType:'https://'+currentConfig.environmentType+"auth.highmark.com/oauth2/rest/authz?",
                    redirectUri = "https://"+window.location.hostname+"/login/#/loggingIn",

                    params = [
                        "response_type=code",
                        "client_id=" + currentConfig.client_id,
                        "scope=resource.READ",
                        "domain="+ environmentConfigs[brandDomain].domain,
                        "redirect_uri=" + encodeURIComponent(redirectUri)
                    ];
                window.location.href = oamAuthorizeRoot + params.join('&');



            };
            $scope.oauthLogin = function(){
                switch (brandDomain) {
                    case "highmarkbcbs":
                    case "highmarkblueshield":
                    case "highmarkbcbsde":
                    case "highmarkbcbswv":
                        return true;
                        break;

                    case "mybenefitshome":
                        return sessionData.appConfig.ACCOUNT_SETTINGS &&
                            sessionData.appConfig.ACCOUNT_SETTINGS.MFA_ENROLL_ELIGIBLE &&
                            sessionData.appConfig.ACCOUNT_SETTINGS.MFA_ENROLL_ELIGIBLE.booleanValue == true;
                        break;

                    case "bluecrossmnonline":
                    case "bcbsndportals":
                    case "yourwyoblue":
                    default:
                        return false;
                        break;}
            };

            $scope.login = function () {

                coremetrics.startConversion('Vitals Landing Page Login','Login');

                $scope.loginErrorTitle = null;
                $scope.loginErrorText = null;
                if($scope.oauthLogin()){
                    var hostname = window.location.hostname.split('.')[1];
                    document.cookie = "sapphireLogin=true; domain=." + hostname + ".com;path=/;Secure";
                    oAuthMfaRedirect();
                }else if ($scope.memberLoginForm.$valid) {
                    $scope.loginDisable = true;
                    services.rbsmbl.loginversion().fetch($scope.memberLoginForm.username.$viewValue.toUpperCase()).then(function (response) {
                        var payload = ((response && response.data && response.data.payload) ? response.data.payload : ((response) ? response.payload : null));

                        if (payload.convertCase) {
                            $scope.memberLoginForm.password.$viewValue = $scope.memberLoginForm.password.$viewValue.toUpperCase();
                        }

                        services.rbsmbl.login().fetch($scope.memberLoginForm.username.$viewValue.toUpperCase(), $scope.memberLoginForm.password.$viewValue).then(function (response) {
                            coremetrics.finishConversion('Sapphire Digital Landing Page Login','Login');

                            var payload = ((response && response.data && response.data.payload) ? response.data.payload : ((response) ? response.payload : null));

                            if (payload && payload.loginInfo && payload.loginInfo.isTempPassword && payload.loginInfo.isTempPassword === 'true') {
                                location.replace("/login/#/change-password");
                            } else if (payload && payload.loginInfo.securityStrengthEnforcement === 'FORCED') {
                                storage.set("forcedPassword", true);
                                modal.open({
                                    template: require('modals/securityUpdatesModal.html'),
                                    onClose: function() {
                                        window.location = "/login/#/change-password";
                                    }
                                });
                            } else {

                                var userInfo = payload.loginInfo.userInfo;
                                var zipCode = userInfo.userProfileVO.zip;
                                var ssoLinkBase = '/rbsmbl/x-services/public/sapphire/sso/' + $scope.brandData.sharedBrandCode + '/member?zip=' + zipCode;
                                location.href = ssoLinkBase;
                            }
                        }, function (error) {
                            $scope.loginDisable = false;
                            handleErrorResponse(error);
                        });
                    }, function (error) {
                        $scope.loginDisable = false;
                        handleErrorResponse(error);
                    });
                }
            };

            $scope.forgotPassword = function () {
                coremetrics.elementTag('FORGOT: USERNAME OR PASSWORD', 'FORGOT');
                window.open('/login/#/forgot-password/step1');
            };

            function removeNavigation() {
                brand.current.socialUpper = [];
                origPrePasswordMemberFooter = angular.copy(navigation.prePasswordMemberFooter);
                for (var x in navigation.prePasswordMemberFooter) {
                    if (navigation.prePasswordMemberFooter.hasOwnProperty(x)) {
                        navigation.prePasswordMemberFooter[x] = {};
                    }
                }
                origPrePasswordMemberHeader = angular.copy(navigation.prePasswordMemberHeader);
                for (var y in navigation.prePasswordMemberHeader) {
                    if (navigation.prePasswordMemberHeader.hasOwnProperty(y)) {
                        navigation.prePasswordMemberHeader[y] = {};
                    }
                }
                navigation.update();
            }

            function handleErrorResponse(error) {
                var specificErrorContent = {title:"",content:"", message:""};

                if (error && error.data) {
                    var response = error.data;
                    var errorMessage = response.errors ? response.errors[0].errorMessage : response.baseMessage.returnCodeDescription;

                    // Handle mapping of service error to error messages.
                    if (errorMessage === 'GEN-SUSPEND') {
                        var appConfig = (session.data && session.data.appConfig && session.data.appConfig.FORGOT) ? session.data.appConfig.FORGOT : {};
                        var isAccountRecoveryActive = (appConfig && appConfig.ACCOUNT_RECOVERY && appConfig.ACCOUNT_RECOVERY.booleanValue === true) ? true : false;
                        specificErrorContent.content = (isAccountRecoveryActive)? "MBR_GEN_SUSPEND_ACCOUNT_LOCKOUT_RECOVERY" : "MBR_GEN_SUSPEND_ACCOUNT_LOCKOUT";

                    } else if (errorMessage === 'GEN-NOACCESS') {
                        var errorSpecifics = response.baseMessage.additionalInfo[0].value;

                        if (errorSpecifics === 'NO_ACCESSIBLE_COVERAGE_EXCEPTION') {
                            specificErrorContent.content = "MBR_ERROR_NO_ACCESSIBLE_COVERAGE_EXCEPTION";

                        } else if (errorSpecifics === 'OVER_CHIP14') {
                            specificErrorContent.content = "MBR_ERROR_OVER_CHIP14";

                        } else if (errorSpecifics === 'CHIP14_GUARDIAN_VERIFICATION_FAIL') {
                            specificErrorContent.content = "MBR_ERROR_CHIP14_GUARDIAN_VERIFICATION_FAIL";

                        } else {
                            specificErrorContent.content = "MBR_LOGIN_GENERIC_ERROR";
                        }
                    } else if (errorMessage === 'GEN-CONFCOMM') {
                        specificErrorContent.content = "MBR_ERROR_GEN_CONFCOMM";

                    } else if (errorMessage === 'GEN-NOMATCH' ||
                            errorMessage === 'GEN-NORECCHMECS' ||
                            errorMessage === 'USER-NOT-ON-FILE') {
                        specificErrorContent.content = "MBR_ERROR_NOMATCH_NORECCHMECS_USER_NOT_ON_FILE";

                    } else if (errorMessage === 'ACC-ECS') {
                        specificErrorContent.content = "MBR_ERROR_ACC_ECS";

                    } else if (errorMessage === 'ACC-ICIS') {
                        specificErrorContent.content = "MBR_ERROR_ACC_ICIS";

                    } else if (errorMessage === 'ACC-CRMPP') {
                        specificErrorContent.content = "MBR_ERROR_ACC_CRMPP";

                    } else if (errorMessage === 'INVALID-LOGIN-BRANDCONFLICT') {
                        specificErrorContent.content = "MBR_ERROR_INVALID_LOGIN_BRANDCONFLICT";

                    } else if (errorMessage === 'ERROR_BAD_REQUEST') {
                        specificErrorContent.content = "MBR_ERROR_BAD_REQUEST";

                    } else if (errorMessage === 'UNAUTHORIZED_SIMULATION_ACCESS') {
                        specificErrorContent.content = "MBR_ERROR_UNAUTHORIZED_SIMULATION_ACCESS";

                    } else if (errorMessage === 'NO_BRAND_ACCESS') {
                        specificErrorContent.content = "MBR_ERROR_NO_BRAND_ACCESS";

                    } else if (errorMessage === 'NO_INTRACOMPANY_ACCESS') {
                        specificErrorContent.content = "MBR_ERROR_NO_INTRACOMPANY_ACCESS";

                    } else if (errorMessage === 'VALID-LOGIN-BRANDCONFLICT') {
                        specificErrorContent.content = "MBR_ERROR_VALID_LOGIN_BRANDCONFLICT";

                    }  else if (errorMessage === 'GEN-NOTONPLATFORM') {
                        var valueNotOnPlatform = response.baseMessage.additionalInfo[0].value;
                        var userNotOnPlatform = response.baseMessage.additionalInfo[0].key;
                        switch (userNotOnPlatform) {
                            case "showBanner":
                                specificErrorContent.content = "MBR_ERROR_REGISTER_UNION_PACIFIC_MESSAGE";
                                break;
                            case "showBanner-2":
                                specificErrorContent.content = "MBR_ERROR_REGISTER_CHS_CLIENT_LOGIN_FAIL";
                                break;
                            case "showBanner-3":
                                specificErrorContent.content ="MBR_ERROR_REGISTER_SELECT_MED_LOGIN_FAIL";
                                break;
                            default:
                                $window.location = valueNotOnPlatform;
                        }
                    } else {
                        specificErrorContent.content = "MBR_LOGIN_GENERIC_ERROR";
                    }
                } else {
                    specificErrorContent.content = "MBR_LOGIN_GENERIC_ERROR";
                }
                teamsite.get(specificErrorContent.content).then(function (response){
                    specificErrorContent.message = response;
                    $scope.loginErrorTitle = "<span class='hm-icon icon-alert' aria-hidden='true'></span>" + ((specificErrorContent.title.length > 0)? "<span>"+ specificErrorContent.title + "</span>" : " ");
                    $scope.loginErrorText = "<span>" + specificErrorContent.message + "</span>";
                });
            }

        }
    ]);
};