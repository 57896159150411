module.exports = function (app) {
    // global vars may have to go here
    app.controller('pages.landing', ["$state", "$window", "$scope", "teamsite", "coremetrics", "sessionData", "$rootScope", "getPlans", "finder-logic2", "spendingActionsNeeded", "claimService", "vendorSSOUtility", "modal", "ebillUserExists", "session", "wellnessProfile", "invisibleFormService", "displayOrHideItemsWrapper", "$filter", "wellnessLogic", "session",
        function ($state, $window, $scope, teamsite, coremetrics, sessionData, $rootScope, getPlans, finderLogic, spendingActionsNeeded, claimService, vendorSSOUtility, modal, ebillUserExists, session, wellnessProfile, invisibleFormService, displayOrHideItemsWrapper, $filter, wellnessLogic, session) {
			//$scope.showAlertMessage = true;
			var positionTools = function(){
				var toolsModule = $(".the-tools");
				var heroModule = $(".landing-page-hero");
				var rightSideModule = $(".sidebarContent");

				if (parseInt($('.content-wrapper').css('width'), 10) > 1243) {
					rightSideModule.append(toolsModule);
				}
				else {
					heroModule.after(toolsModule);
				}

			};

			$scope.$on('$viewContentLoaded', positionTools);
			$($window).on('resize', positionTools);

//			$scope.funcs = {};
//            $scope.finderLogic = finderLogic;
//            $scope.targetUrls = {};
//            $scope.serverDate = session.startTime.toJSON().substring(0,10);
//            $scope.funcs.urlBuilder = function(thePathWithPrecedingSlash) {
//                var fullUrl = 'https://' + window.location.host + thePathWithPrecedingSlash;
//                fullUrl = fullUrl.replace('denv', 'tenv');
//                return fullUrl;
//            }
//            // get volatile info for lastClaimPaymentVisitDate
//            var getVolatileStuff = services.rbsmbl.volatileMemberInfo().fetch();
//            getVolatileStuff.then(function(volatileStuff) {
//                if (volatileStuff && volatileStuff.data && volatileStuff.data.payload && volatileStuff.data.payload.lastClaimPaymentVisitDate) {
//                    $scope.lastClaimPaymentVisitDate = volatileStuff.data.payload.lastClaimPaymentVisitDate;
//                }
//            }, function(err) {
//                throw new Error(err);
//            });
//            // we call the service that was created for View Site By Plan
//            getPlans.thePlans(theBrand).then(
//                function(plans) {
//                    $scope.plans = plans;
//                    // erroneously the service returns a plans.plans.selected object in this case
//                    // plans.plans.selected.currentlySelectedPlan.indicators.isSelected == false
//                    // all this is caused by bad data and is being ignored for now
//                    var currentlySelectedPlan = plans.plans.selected;
//                    // currentlySelectedPlan.indicators.isSelected
//                    $scope.groupNumber = currentlySelectedPlan.groupNumber;
//                    $scope.planName = currentlySelectedPlan.planName.display.vsbp;
//
//
//                    var loggedInMember = $scope.plans.members.loggedIn
//                    $scope.fullName = loggedInMember.firstName + ' ' + loggedInMember.lastName;
//					$scope.memberId = sessionData.memberInfo.umi;
//
//                    var effectiveDate = currentlySelectedPlan.memberEffectiveDate;
//                    effectiveDate = effectiveDate.split('/').join('');
//                    //  plans.plans.selected.idCardEOBGroupNumber
//                    $scope.memberInfoSectionGroupNumber = currentlySelectedPlan.idCardEOBGroupNumber;
//                    var qs = "?gn={groupNumber}&amp;ed={effectiveDateMMDDYYYY}";
//                    qs = qs.replace('{groupNumber}', $scope.groupNumber);
//                    qs = qs.replace('{effectiveDateMMDDYYYY}', effectiveDate);
//                    qs = qs.replace('&amp;', '&');
//                    $scope.groupNumberAndEffectiveDateQuerystring = qs;
//
////                    theTarget = 'https://' + window.location.host + '/' + theTarget;
////                    if (window.location.host.indexOf('denv') > -1) {
////                        theTarget = theTarget.replace('denv', 'tenv');
////                    }
//
//                    // hit the qualifier service =================================================================
//                    var cov = $scope.plans.members.loggedIn.coverageMap[0];
//                    var displayOrHideItemsResponse = displayOrHideItemsWrapper
//                        .displayOrHideItemsCall(cov.clientNumber, cov.groupNumber, ['webMDRewardsIndicator'], sessionData.memberInfo.brand.toLowerCase());
//                    displayOrHideItemsResponse.then(function (servicePayload) {
//                        // var tt = servicePayload;
//                        if (servicePayload && servicePayload.qualifierInfo && servicePayload.qualifierInfo.webMDRewardsIndicator
//                            && servicePayload.qualifierInfo.webMDRewardsIndicator == 'true') {
//                            $scope.showRewardsProgram = true;
//                            // $scope.eSpots.findAProvider.showThis = true;
//                        } else {
//                            $scope.showRewardsProgram = false;
//                            // $scope.eSpots.findAProvider.showThis = true;
//                        }  });
//
//                } );
//
//            // portal selected plan =======================================================================
//            var fullMemberInfo = sessionData.memberInfo;
//            var theDomain = finderLogic.theDomain();
//            var fm = fullMemberInfo;
//            finderLogic.setFullMemberInfo(fm);
//            $scope.psp = finderLogic.getPortalSelectedPlan();
//            $scope.activeProducts = finderLogic.portalSelectedPlanActiveProducts();
//            // handle sso service response ===================================================
//            $scope.handleSsoResponse = function(ssoStuff, theTargetType, coreMetricsTagInfo) {
//                if ((ssoStuff.ssoRequestType != 'REDIRECT') && (ssoStuff.ssoFormParams)) {
//                    if (ssoStuff && ssoStuff.ssoFormParams && ssoStuff.ssoFormParams.length && ssoStuff.ssoFormParams.length > 0 && ssoStuff.ssoRequestType && ssoStuff.ssoUrl) {
//
//                        var myCmSitePromotion = ($scope.gblVars.cmKeyValue) ? $scope.gblVars.cmKeyValue : '';
//                        var theUrl = ssoStuff.ssoUrl + myCmSitePromotion;
//                        var arrSameWindow = ['WELLNESS_REWARDS', 'WELLNESS_PERSONAL_HEALTH_RECORD', 'WELLNESS_HEALTH_TRACKER', 'WELLNESS_HEALTH_ASSISTANT', 'WELLNESS_SYMPTOM_CHECKER', 'WELLNESS_PROFILE'];
//                        var sameWindowValue = (arrSameWindow.indexOf(theTargetType)>-1) ? '_self' : '_target';
//
//                        var ssoFormConfig = {
//                            "name": "PrescriptionSvcsFormName",
//                            "id": "PrescriptionSvcsFormId",
//                            "formTarget": "PrescriptionSvcs",
//                            "method": "POST",
//                            "action": theUrl,
//                            "target": sameWindowValue,
//                            "inputs": {}
//                        };
//                        var formParams = ssoStuff.ssoFormParams;
//                        for (var i = 0; i < formParams.length; i++) {
//                            ssoFormConfig.inputs[formParams[i].name] = formParams[i].value;
//                        }
//
//                        // next line sends the user where they should go
//                        var invForm = invisibleFormService.submit(ssoFormConfig);
//                    } else {
//                        // the initial service did not return the stuff we expected
//                        return;
//                    }
//                } else {
//                    var effectiveDate = $scope.plans.plans.selected.memberEffectiveDate;
//                    var theGroupNumber = $scope.plans.plans.selected.groupNumber;
//                    effectiveDate = effectiveDate.split('/').join('');
//                    var theUrl = ssoStuff.ssoUrl;
//                    // = "wts/?gn={groupNumber}&amp;ed={effectiveDateMMDDYYYY}"
//                    theUrl = theUrl.replace('{groupNumber}', theGroupNumber);
//                    theUrl = theUrl.replace('{effectiveDateMMDDYYYY}', effectiveDate);
//                    theUrl = theUrl.replace('&amp;', '&');
//                    var theTarget = theUrl;
//
//                    theTarget = 'https://' + window.location.host + '/' + theTarget;
//
//
//                    if (window.location.host.indexOf('denv') > -1) {
//                        theTarget = theTarget.replace('denv', 'tenv');
//                    }
//                    window.open(theTarget);
//                }
//            }

        }
    ]);

    return [
        {
            state: "landing",
            postPassword: true,
            appConfigMaintenanceApp: 'landing',
            appConfigMaintenanceKey: 'FULL_MAINTENANCE',
            url: "/landing",
            views: {
                'body@': {
                    template: require('pages/landing.html'),
							/* "<h1 class='font32' data-teamsite='MBR_MBRHOME_TOPHEADER_CATEGORYTITLE'></h1>"+
							  "<div class='landing-page-content' data-ui-view='pageContent'></div>"+
						      "<div custom-client-article='landing' ></div>"+
							  "<aside class='landing-page-sidebar' data-ui-view='sidebarContent'></aside>",*/
					controller: "pages.landing"

				}
            }
        }

    ];
};
