module.exports = function(app) {
    require('./prescriptions/step-therapy-exception-form')(app);
    require('./prescriptions/medication-exception-form')(app);
    var myModal = require("../modals/act207Modal");
    var myPharmacyModal = require("../modals/pharmacyModal");
    var theBrand;
    var glbFullMemberInfo;
    var glbCurMember;
    var glbPsp;
    var glbGroupNumber;
    var glbClientNumber;
    // var userSeesAct207Module = false;
    app.controller('pages.prescription', ["$state", "$scope", "$filter", "$sce", "finderService", "pharmacyServiceWrapper",
        "vendorSSOUtility", "drugVendorWrapper", "modal", "brand", 'finder-logic2', "teamsite", "coremetrics", "session",
        function($state, $scope, $filter, $sce, finderService, pharmacyServiceWrapper, vendorSSOUtility,
                 drugVendorWrapper, modal, brand, finderLogic, teamsite, coremetrics, session) {
            var links = [];
            window.document.title = "Prescriptions";
            /*Determines if the user has active, cancelled or future drug coverage.
             If the user's drug coverage status is cancelled or future effective,
             the regular page content is removed and the user sees an appropriate message related to their coverage */

            $scope.hasDrugCoverage = session.data.benefits.drug;
            $scope.futureDrugCoverage = session.data.benefits.drugFuture;
            $scope.cancelledDrugCoverage = session.data.benefits.drugCancelled;
            $scope.activeDrugCoverage = session.data.benefits.drugActive;            

            $scope.showNewRx = false;
            $scope.isWpaNewRx = finderLogic.isWpaNewRx;
            $scope.isCpaNewRx = finderLogic.isCpaNewRx;
            $scope.isWvNewRx = finderLogic.isWvNewRx;
            $scope.rxHeroAltText = "";
            
            try {
            	teamsite.get("MBR_PRESCRIPTION_BANNER_TEXT").then(function(response){
            		$scope.rxHeroAltText = response;
				});
            } catch(e) {
            	// Leave alt text blank in case of exception
            }

            if ($scope.activeDrugCoverage === true) {
                $scope.showPrescriptionContent = true;
            } else if ($scope.futureDrugCoverage === true) {
                $scope.showPrescriptionContent = false;
                $scope.coverageMessage = "MBR_PRESCRIPTION_FUTUREDRUG_MESSAGE";
            } else if ($scope.cancelledDrugCoverage === true) {
                $scope.showPrescriptionContent = false;
                $scope.coverageMessage = "MBR_PRESCRIPTION_CANCELLEDDRUG_MESSAGE";
            }

            var cmPageView = coremetrics.pageviewTag('MEMBER:PRESCRIPTIONS', 'MEMBER:PRESCRIPTIONS');

            var fullMemberInfo = session.data.memberInfo;
            var theDomain = finderLogic.theDomain();
            var fm = glbFullMemberInfo = fullMemberInfo;
            theBrand = fm.brand.toLowerCase();

            finderLogic.setFullMemberInfo(fm);
            glbCurMember = finderLogic.setLibStorageAndReturnCurMember(fullMemberInfo);

            finderLogic.currentActiveProducts(fullMemberInfo); // sets an object that the next line needs

            glbPsp = finderLogic.getPortalSelectedPlan();
            glbClientNumber = fm.clientList[0].clientNumber;
            glbGroupNumber = glbPsp.groupNumber;
            var userHasMedco = false;
            var userHasPrime = false;

            if (session.data.memberInfo.brand === 'NDBCBS' && glbPsp.isNDMedicaid) {
                $scope.showPrescriptionContent = false;
                $scope.coverageMessage = "MBR_PRESCRIPTION_NDMEDICAID_MESSAGE";
            }

            if (glbPsp && glbPsp.pharmacyBenefitProviderIndicator) {
                userHasMedco = (glbPsp.pharmacyBenefitProviderIndicator.toLowerCase() == 'medco');
                userHasPrime = (glbPsp.pharmacyBenefitProviderIndicator.toLowerCase() == 'prime');
            }
            $scope.directiveOptions = {
                theBrand: theBrand,
                glbClientNumber: glbClientNumber,
                glbGroupNumber: glbGroupNumber,
                userHasMedco: userHasMedco,
                userHasPrime:userHasPrime
            };

            // var currentMedicalProduct = finderLogic.specificActiveProduct('Medical');

            $scope.isMedicare = finderLogic.hasSeniorProduct();
            $scope.isNewRx = finderLogic.isNewRx;

            // Set up Icons hide/show by drug plan ------------------------------
            var hasIntegratedDrug = (finderLogic.specificActiveProduct('Medical') && finderLogic.specificActiveProduct('Medical').drugCoverage &&
            finderLogic.specificActiveProduct('Medical').drugCoverage == 'true') ? true : false;

            var hasStandAlone = (session && session.data && session.data.benefits && session.data.benefits.drugActive &&
            session.data.benefits.drugActive == true) ? true : false;
            // finderLogic.specificActiveProduct('Drug');

            var isWpaCre = finderLogic.getPropertyByBrandCode(theBrand, 'regionIsWpaCre');
            var isDe = finderLogic.getPropertyByBrandCode(theBrand, 'regionIsDe');
            var isWv = finderLogic.getPropertyByBrandCode(theBrand, 'regionIsWv');
            var isMbhAndNotIbcNA = finderLogic.getPropertyByBrandCode(theBrand, 'regionIsMbh');
            var isAllMbh = finderLogic.getPropertyByBrandCode(theBrand, 'regionIsAllMbh');
            var isMnCr = theBrand === 'mincr' ;
            var isND=  theBrand === 'ndbcbs';
            var isWY = theBrand === 'wybcbs';
            $scope.isWY = false;
            if(isWY=="wybcbs" || isWY=="WYBCBS"){
                $scope.isWY = true;
            }
            
            var isWpaCreWv = finderLogic.getPropertyByBrandCode(theBrand, 'regionIsWpaCreWv');
            
            $scope.showFormText = true;
            if ((isWpaCre || isDe || isWv) && userHasPrime) {
                $scope.showFormText = false;
            }
            $scope.showChipMeetingMin = false;
            if(fm.chip === true && isWpaCre) {
                $scope.showChipMeetingMin = true;
            }
            //select speciality - Retail program text
            var retailProgramId = '';
            $scope.showRetailText = false;
            var retailProgramText = '';
            if (isWpaCre || isDe || isWv) {
                var portalProductDrug = finderLogic.portalSelectedPlanSpecificProductByLatestCoverageEffectiveDate('Drug');
                var portalProductMedical = finderLogic.portalSelectedPlanSpecificProductByLatestCoverageEffectiveDate('Medical');
                if(portalProductDrug){
                    retailProgramId = portalProductDrug.retailExclusivityProgramId;
                    retailProgramText = portalProductDrug.retailExclusivityProgramText;
                } else if (portalProductMedical) {
                    retailProgramId = portalProductMedical.retailExclusivityProgramId;
                    retailProgramText = portalProductMedical.retailExclusivityProgramText;
                }
                $scope.retailProgramTopText = '';
                $scope.retailProgramBottomText = '';
                $scope.showToolTip = false;
                $scope.showRetailText = true;
                $scope.ppToolTip = '<span>Specialty medications have special handling, storage and temperature requirements, so not all pharmacies are equipped to carry them.</span>';
                var retailExclusivityData={};
                teamsite.get("MBR_PRESCRIPTIONS_RETAILEXCLUSIVITY_PROGRAMS").then(function(data) {
                    retailExclusivityData = angular.fromJson(data);
                    var programID = retailProgramText.includes("Copay Armor")? "CopayArmor" :
                         retailExclusivityData[retailProgramId] && retailExclusivityData[retailProgramId].retailProgramTopText ?
                          retailProgramId : "default"
                    $scope.retailProgramTopText = retailExclusivityData[programID].retailProgramTopText;
                    $scope.showToolTip = retailExclusivityData[programID].showToolTip;
                    if (retailExclusivityData[programID].retailProgramButtomText){
                        $scope.retailProgramButtomText = retailExclusivityData[programID].retailProgramButtomText;
                    }
                    if(retailExclusivityData[programID].retailAdditionalText) {
                        $scope.retailAdditionalText = retailExclusivityData[programID].retailAdditionalText;
                    }
                    $scope.ppToolTip = retailExclusivityData[programID].ppToolTip;
                });
             }

            $scope.PRESCRIPTION_HIDE_DEFAULT_HERO = false;
            //TODO: Switch to using AppConfig to check if we should use the default hero or not...
            if (isMnCr || isND) {
                $scope.PRESCRIPTION_HIDE_DEFAULT_HERO = true;
            }
            // Set up Icons hide/show by Region ---------------------------------
            var userIsInClaimsHistoryRegion;
            var userIsInRefillRenewRegion;
            var showFAQs;
            var showIbcMailOrderIcon = (theBrand == 'ibc');
            var userIsInCostsAndSavingsRegion = false;
            if (isAllMbh || (theBrand == 'mincr' || theBrand == 'ndbcbs') || isWpaCreWv || isDe || isWv || (theBrand == 'wybcbs')) {
                userIsInCostsAndSavingsRegion = true;
                showFAQs = true;
            }
            $scope.directiveOptions.showFAQs = showFAQs;
            if (isWpaCreWv || isAllMbh || isDe || (theBrand == 'mn') || (theBrand=="ndbcbs")) {
                userIsInClaimsHistoryRegion = userIsInRefillRenewRegion = true;
            } else {
                userIsInClaimsHistoryRegion = userIsInRefillRenewRegion = false;
            }
            var showRefillRenewIcon = (userIsInRefillRenewRegion && (hasStandAlone || hasIntegratedDrug) && (userHasMedco || userHasPrime)) ? true : false;
            var showCostSavingsIcon = (userIsInCostsAndSavingsRegion && (hasStandAlone || hasIntegratedDrug) && (userHasMedco || userHasPrime));
            var showClaimsHistoryIcon = (userIsInClaimsHistoryRegion && (hasStandAlone || hasIntegratedDrug) && (userHasMedco || userHasPrime)) ? true : false;

            var showBenefitHighlightsIcon = false;
            showBenefitHighlightsIcon = (
            ((userHasMedco) && (isMbhAndNotIbcNA || isWpaCreWv || isDe)) ||
            (isMnCr || isND  || isWY)
            ) ? true : false;

            $scope.showingOneOrTwoIcons = finderLogic.showingOneOrTwoIcons([showIbcMailOrderIcon, showRefillRenewIcon, showCostSavingsIcon, showClaimsHistoryIcon, showIbcMailOrderIcon]);

            //                userSeesAct207Module = prescriptionLogic.userSeesAct207Module(theBrand);

            // BEGIN FORMS AND ACTIONS DETAILS JS =========================================================
            var showFormsAndActionsModule = false;
            // TODO: (pds) we have to hide the whole module if none of the items are to show
            var showFaaMedicalException = false;
            var showFormsAndActionsModule = showFaaMedicalException = isWpaCre || isDe || isWv || isAllMbh || isMnCr || isND || (theBrand == 'mn') || (theBrand == 'ibc') || (theBrand=='wybcbs');

            teamsite.get("MBR_PRESCRIPTIONS_FORMS_BRAND_LIST").then(function(data) {
                var BrandCodes = angular.fromJson(data);
                var BrandCodeCheck= BrandCodes.indexOf(theBrand) > -1;
                showFormsAndActionsModule = BrandCodeCheck;

                $scope.uiModules.theForms = {
                    faaItemsArray: [{
                        // faaTitle: 'Get a reimbursement',  // MBR_PRESCRIPTION_FORMS_SUBHEAD
                        faaTitle: 'MBR_PRESCRIPTION_FORMS_SUBHEAD',
                        // faaText: 'Click here if you want this form. It may serve quite a few purposes.',
                        faaText: 'MBR_PRESCRIPTION_FORMS_TEXT',
                        docTypesArray: [{
                            faaClickablePdf: faaReimbursePdf,
                            ts: 'MBR_PRESCRIPTION_FORMS_LINK',
                            linkText: 'MBR_PRESCRIPTION_FORMS_LINKTEXT',
                            formIsPdf: true,
                            needsFullWidth: true,
                            coreMetricTag: 'reimbursementForm'
                        }],
                        showThisItem: faaReimbursePdf
                    }, {
                        // faaTitle: 'Mail order a prescription',
                        faaTitle: 'MBR_PRESCRIPTION_FORMS_SUBHEAD2',
                        faaText: 'MBR_PRESCRIPTION_FORMS_TEXT2',
                        docTypesArray: [{
                            faaClickablePdf: faaMailOrderPdf,
                            ts: 'MBR_PRESCRIPTION_FORMS_LINK2',
                            ts2: 'MBR_PRESCRIPTION_FORMS_PRIORAUTHORIZATION',
                            linkText: 'MBR_PRESCRIPTION_FORMS_LINKTEXT2',
                            linkText2: 'MBR_PRESCRIPTION_FORMS_PRIORAUTHORIZATION_LINK',
                            linkText3: 'MBR_PRESCRIPTION_FORMS_MOREFORMS',
                            formIsPdf: true,
                            coreMetricTag: 'mailOrderForm'
                        }, {
                                faaClickablePdf: faaMailOrderPdf,
                                ts: 'MBR_PRESCRIPTION_FORMS_LINK3',
                                ts2: 'MBR_PRESCRIPTION_FORMS_PRIORAUTHORIZATION',
                                linkText: 'MBR_PRESCRIPTION_FORMS_LINKTEXT3',
                                //linkText2: 'MBR_PRESCRIPTION_FORMS_PRIORAUTHORIZATION_LINK',
                                //linkText3: 'MBR_PRESCRIPTION_FORMS_MOREFORMS',
                                formIsPdf: true,
                                coreMetricTag: 'mailOrderForm'
                            }],
                        showThisItem: faaMailOrderPdf
                    }],
                    hasBlueBackground: false,
                    // rightSortOrder: 4,
                    isAct207Module: false,
                    isFormsAndActions: showFormsAndActionsModule,
                    isGenericModule: false,
                    isFAAModule: true
                };
                
                

                //excludes this client from seeing Mail order on prescription tab within Forms To Manage Your Plan
                   if (fullMemberInfo.clientList[0].clientNumber === '098024') {
                        // add in logic to test if null for $scope.uiModules.theForms.faaItemsArray[1]
                        if ($scope.uiModules.theForms.faaItemsArray[1] != null) {
                            var carveOutLHC =  $scope.uiModules.theForms.faaItemsArray[1]
                            carveOutLHC.faaText = '';
                            carveOutLHC.faaTitle = '';
                            carveOutLHC.docTypesArray[0] = [];
                        }
                    }
            });
            // BEGIN PRIOR AUTHORIZATION - FORMS AND ACTIONS =============
            var faaPriorAuthPdf = null;
            var faaPriorAuthOnline = null;
            var faaPriorAuthIsPdf = false;
            var faaPriorAuthIsOnline = false;
            if (showFormsAndActionsModule) {
                // the reqs call this "PRESCRIPTION DRUG MEDICATION REQUEST"
                // ************************************************************************
                // ******  NOTE: the code uses teamsite content for the     ***************
                // ******        urls.  The hardcoded ones here need        ***************
                // ******        to be cleaned up at some point.            ***************
                // ************************************************************************

                if (isWpaCre || isDe || isWv) {
                    faaPriorAuthIsOnline = true;
                    faaPriorAuthOnline = 'https://blog.highmark.com/medical-prescription-and-spending-account-forms-for-highmark-members/';
                }
                if (theBrand == 'mn' || isMnCr || isND|| isAllMbh || (theBrand == 'ibc')) {
                    faaPriorAuthIsPdf = true;
                    if (isMnCr || isND) {
                        faaPriorAuthPdf = theDomain + '/pdffiles/drug_prior_auth_formulary_excep_access.pdf';
                    } else if (isAllMbh) {
                        faaPriorAuthPdf = theDomain + '/redesign/pdfs/mhs/Prescription_Drug_Medication.pdf';
                    } else {
                        faaPriorAuthPdf = theDomain + '/redesign/pdfs/mhs/Prescription_Drug_Medication.pdf';
                    }
                }
                // GET REIMBURSEMENT & MEDICAL EXCEPTIONS - FORMS AND ACTIONS  =============
                var faaReimbursePdf = null;
                var faaMedicalExcepLink = null;
                if (isWpaCreWv || isAllMbh || isDe || (theBrand == 'ibc')) {
                    faaReimbursePdf = theDomain + '/redesign/pdfs/mhs/PrescriptionDrugClaim.pdf';
                    faaMedicalExcepLink = theDomain + '/login/#/prescriptions/medication-exception-request';
                }
                if (isMnCr || isND) {
                    faaReimbursePdf = theDomain + '/redesign/pdfs/mhs/MN_Prescription_Reimbursement_Claim.pdf'
                    if (theDomain.indexOf('tenv')) {
                        faaMedicalExcepLink = 'https://qa.myprime.com/content/myprime/en/coverage-exception-form.html';
                    } else {
                        faaMedicalExcepLink = 'www.myprime.com/content/myprime/en/coverage-exception-form.html';
                    }
                }


                // BEGIN MAIL ORDER FORM - FORMS AND ACTIONS ================
                var faaMailOrderPdf = null;
                faaMailOrderPdf = (isWpaCreWv || isAllMbh || isDe || (theBrand == 'ibc') || (theBrand == 'wybcbs')) ? 'https://blog.highmark.com/wp-content/uploads/2014/06/PrescriptionMailOrderMedicationQuestionnaire.pdf' : faaMailOrderPdf;
            }
            // END FORMS AND ACTIONS DETAILS JS ============================================================
            // var formsAndActionsDetailsArray = ;

            $scope.uiModules = [];

            $scope.pharmImages = {};
            //SAML PKI changes.
            var vendorUrlExtObj = {
                'MAILRX':'orderstatus',
                'PRESCRIPTION_COSTS_AND_SAVINGS':'rxpricing',
                'PRESCRIPTION_CLAIMS':'claimsandbalances',
                'PRESCRIPTION_BENEFITS':'rxbenefits',
                'PRESCRIPTION_TRANSFERS':'orderstatus',
                'PRESCRIPTION_MAIL_ORDER_FORMS':'envelopes',
                'locatePharmacy':'locatepharmacy'
            };

            var handleSsoResponse = function(prescriptServiceSsoResponse, windowName, theTargetType, coreMetricsTagInfo) {
                // TODO: Update once IE fix is determined...
                var cmExt = coreMetricsPromoTags(theTargetType, coreMetricsTagInfo);
                //vendorSSOUtility.launchNewSsoWindow(windowName);
                windowName = "_blank";
                //SAML PKI changes..
                if(glbPsp.pharmacyBenefitProviderIndicator.toLowerCase() === 'medco' && (isWpaCre || isDe || isWv || isAllMbh)  && (theTargetType !== 'drugFormularyDisplay')){
                    var ssoPPB = (isWpaCre || isDe) ? '':'PPB';
                    var ssoUrl = window.location.origin+'/emssvc/x-services/secure/sso/outbound/auto/v1/vendor/MEDCO' + ssoPPB + '?redirectInfo=' + vendorUrlExtObj[theTargetType];
                    var vendorSSODetails = {
                        ssoUrl : ssoUrl,
                        ssoRequestType:'REDIRECT'
                    };
                    vendorSSOUtility.genericHandleSSOResponse(vendorSSODetails,cmExt , windowName);
                } else if (prescriptServiceSsoResponse !== null) {
                    prescriptServiceSsoResponse.then(function (vendorSSOStuff) {
                        vendorSSOUtility.genericHandleSSOResponse(vendorSSOStuff, cmExt, windowName);
                    }, function(error) {
                        vendorSSOUtility.genericHandleSSOFailure(error, windowName);
                    });
                } else {
                    vendorSSOUtility.genericHandleSSOFailure("", windowName);
                }
            };

            $scope.funcs = {};
            $scope.funcs.hitSsoService = function(theTargetType, coreMetricsTagInfo) {
                var ssoWindowName = "";

                if (!theTargetType) {
                    // console.log('The hitSsoService was not sent a theTargetType parameter');
                    return;
                }
                if (!coreMetricsTagInfo) {
                    coreMetricsTagInfo = 'cmMain';
                }
                if (theTargetType == 'drugFormularyDisplay') {
                    var callDrugVendorWrapper = drugVendorWrapper.getDrugVendor(glbClientNumber, glbGroupNumber, 'LANDING', 'DRUG_FORMULARY', theBrand.toUpperCase());
                    ssoWindowName = ('SSO_DRUG_FORMULARY').toLowerCase();
                    handleSsoResponse(callDrugVendorWrapper, ssoWindowName, theTargetType, coreMetricsTagInfo);

                }
                else if((theTargetType==='PRESCRIPTION_MAIL_ORDER_FORMS') && (brand.current.sharedBrandCode==='ndbcbs')){
                    window.open('https://www.walgreens.com/register/pharmacyRegistration.jsp','_blank');
                }
                else {
                    var prescriptServiceSsoResponse = pharmacyServiceWrapper.doPharmacySsoCall(glbFullMemberInfo, glbCurMember, glbPsp, theTargetType);
                    ssoWindowName = ('sso_pharmacy' + glbPsp.pharmacyBenefitProviderIndicator).toLowerCase();
                    handleSsoResponse(prescriptServiceSsoResponse, ssoWindowName, theTargetType, coreMetricsTagInfo);
                }
            };

            $scope.funcs.popAct207Modal = function() {
                coremetrics.pageviewTag('MEMBER:PRESCRIPTIONS: PHARMACY ACT 207 MODAL', 'MEMBER:PRESCRIPTIONS');
                modal.open({
                    template: require('modals/act207Modal.html'),
                    controller: myModal,
                    scope: $scope
                });
            };
            $scope.funcs.popPharmacyModal = function() {
                // if CPA, WPA, WV, DE, MBH then pop pharmacy modal
                $scope.currentYear = new Date().getFullYear();
                if (isWpaCreWv || isAllMbh || isDe) {
                    if (!$scope.pharmacyContent) {
                        teamsite.get("MBR_FINDAPHARMACY_CONTENT").then(function(data) {
                            $scope.pharmacyContent = angular.fromJson(data);
                            $scope.pharmacyLinks = finderLogic.getPharmacyNetworks($scope.pharmacyContent, theBrand);
                        });
                    }
                    modal.open({
                        template: require('modals/pharmacyModal.html'),
                        controller: myPharmacyModal,
                        scope: $scope
                    });
                } else {
                    $scope.funcs.hitSsoService('locatePharmacy');
                }
            };

            $scope.funcs.coreMetrics = function(coreMetricsTag) {
                switch (coreMetricsTag) {
                    case 'reimbursementForm':
                        coremetrics.elementTag('Prescription drug reimbursement (PDF)', 'Member:Prescriptions');
                        break;
                    case 'mailOrderForm':
                        coremetrics.elementTag('Mail order form & health questionnaire', 'Member:Prescriptions');
                        break;
                    case 'MBR_PRESCRIPTION_PRIORAUTH_LINK':
                    case 'prescriptionDrugPDF':
                        coremetrics.elementTag('Prescription drug medication request', 'Member:Prescriptions');
                        break;
                    case 'MBR_PRESCRIPTION_MEDEXCEPTREQ_LINK':
                        coremetrics.elementTag('Medication exception request', 'Member:Prescriptions');
                        break;
                    case 'FAQ':
                        coremetrics.elementTag('Prescriptions Frequently asked questions', 'Member:Prescriptions');
                        break;
                    case 'Message':
                        coremetrics.elementTag('Prescriptions Send us a message', 'Member:Prescriptions');
                        break;
                    case 'drugListFormulary':
                        coremetrics.elementTag('Search drug lists (Formulary)', 'Member:Prescriptions');
                        break;
                    case 'prescriptionSummary':
                        coremetrics.elementTag('Prescriptions Summary', 'Member:Prescriptions');
                        break;
                }
            };
            var coreMetricsPromoTags = function(target, coreMetricsType) {
                var cmBrand = brand.override.toUpperCase();
                switch (target) {
                    case 'MAILRX':
                        if (cmBrand == 'IBCCR' || cmBrand == 'IBCAH') {
                            if (coreMetricsType == 'cmLeftNav') {
                                return '?cm_sp=Catamaran-_-Prescription%20Services%20Catamaran-_-Left%20Nav%20Mail%20Order%20Pharmacy';
                            } else {
                                return '?cm_sp=Catamaran-_-Prescription%20Services%20Catamaran-_-Mail%20Order%20Pharmacy';
                            }
                        } else {
                            if (coreMetricsType == 'cmLeftNav') {
                                return '?cm_sp=Medco-_-Prescription%20Services%20Medco-_-Left%20Nav%20Refill%20&%20Order%20Status';
                            } else {
                                return '?cm_sp=Medco-_-Prescription%20Services%20Medco-_-Refill%20&%20Order%20Status';
                            }
                        }
                    case 'PRESCRIPTION_COSTS_AND_SAVINGS':
                        if (cmBrand == 'MINCR') {
                            if (coreMetricsType == 'cmLeftNav') {
                                return '?cm_sp=Prime-_-Prescription%20Services%20Prime-_-Left%20Nav%20Cost%20Savings';
                            } else {
                                return '?cm_sp=Prime-_-Prescription%20Services%20Prime-_-Cost&%20Savings';
                            }
                        } else {
                            if (coreMetricsType == 'cmLeftNav') {
                                return '?cm_sp=Medco-_-Prescription%20Services%20Medco-_-Left%20Nav%20Cost%20&%20Savings';
                            } else {
                                return '?cm_sp=Medco-_-Prescription%20Services%20Medco-_-Cost%20&%20Savings';
                            }
                        }
                    case 'PRESCRIPTION_CLAIMS':
                        if (coreMetricsType == 'cmLeftNav') {
                            return '?cm_sp=Medco-_-Prescription%20Services%20Medco-_-Left%20Nav%20Claims%20&%20History';
                        } else {
                            return '?cm_sp=Medco-_-Prescription%20Services%20Medco-_-Claims%20&%20History';
                        }
                    case 'locatePharmacy':
                        if (cmBrand == 'MINCR') {
                            return '?cm_sp=Prime-_-Prescription%20Services%20Prime-_-Search%20pharmacies';
                        } else if (cmBrand == 'IBCCR' || cmBrand == 'IBCAH') {
                            return '?cm_sp=Catamaran-_-Prescription%20Services%20Catamaran-_-Search%20pharmacies';
                        } else {
                            return '?cm_sp=Medco-_-Prescription%20Services%20Medco-_-Search%20pharmacies';
                        }
                    case 'PRESCRIPTION_MAIL_ORDER_FORMS':
                        return '?cm_sp=Medco-_-Prescription%20Services%20Medco-_-Mail%20order%20How%20it%20works';
                    case 'PRESCRIPTION_TRANSFERS':
                        return '?cm_sp=Medco-_-Prescription%20Services%20Medco-_-Mail%20order%20savings';
                    case 'drugFormularyDisplay':
                        if (cmBrand == 'MINCR' || cmBrand == 'NDBCBS') {
                            return '?cm_sp=Prime-_-Prescription%20Services%20Prime-_-Search%20drug%20list%20Formulary';
                        }
                    case 'PRESCRIPTION_BENEFITS':
                        if (coreMetricsType == 'cmLeftNav') {
                            return '?cm_sp=Medco-_-Prescription%20Services%20Medco-_-Left%20Nav%20Benefit%20Highlights';
                        } else {
                            return '?cm_sp=Medco-_-Prescription%20Services%20Medco-_-Benefit%20Highlights';
                        }
                    default:
                        throw new Error('The value passed to the coreMetricPromoTags method was not recognized.')
                }
            };
            // the big icons ******************************************************
            $scope.pharmImages = {};
            $scope.pharmImages.theIcons = [{
                //theSrc: 'images/prescription/Refill_renew.png',
                textUnderIcon: 'MBR_PRESCRIPTION_ICONLABEL_REFILL_ORDERSTAT', // Refill & Renew
                showThisIcon: showRefillRenewIcon,
                targetType: 'MAILRX'
            }, {
                //theSrc: 'images/prescription/costs&Savings.png',
                textUnderIcon: 'MBR_PRESCRIPTION_ICONLABEL_COSTSAVINGS', //'Costs & savings',
                showThisIcon: showCostSavingsIcon,
                targetType: 'PRESCRIPTION_COSTS_AND_SAVINGS'
            }, {
                textUnderIcon: 'MBR_PRESCRIPTION_ICONLABEL_CLAIMSHISTORY', // 'Claims History',
                showThisIcon: showClaimsHistoryIcon,
                targetType: 'PRESCRIPTION_CLAIMS'
            }, {
                textUnderIcon: 'MBR_PRESCRIPTION_ICONLABEL_MAILORDERPHARM',
                showThisIcon: showIbcMailOrderIcon,
                targetType: 'MAILRX'
            }, {
                textUnderIcon: 'MBR_PRESCRIPTION_LEFTNAV_BENEFITHIGHLIGHTS',
                showThisIcon: showBenefitHighlightsIcon,
                targetType: 'PRESCRIPTION_BENEFITS',
                isMncr: isMnCr,
                isND: isND
            }];
            //Retrieves TS content for the icons
            teamsite.get("MBR_PRESCRIPTION_ICON_REFILL_ORDERSTAT").then(function(content) {
                $scope.pharmImages.theIcons[0].theSrc = content;
            });
            teamsite.get("MBR_PRESCRIPTION_ICON_COSTSAVINGS").then(function(content) {
                $scope.pharmImages.theIcons[1].theSrc = content;
            });
            teamsite.get("MBR_PRESCRIPTION_ICON_CLAIMSHISTORY").then(function(content) {
                $scope.pharmImages.theIcons[2].theSrc = content;
            });
            teamsite.get("MBR_PRESCRIPTION_ICON_MAILORDERPHARM").then(function(content) {
                $scope.pharmImages.theIcons[3].theSrc = content;
            });
            teamsite.get("MBR_PRESCRIPTION_ICON_BENEFITHIGHLIGHTS").then(function(content) {
                $scope.pharmImages.theIcons[4].theSrc = content;
            });
            teamsite.get("MBR_PRESCRIPTION_RETAIL_PROGRAM").then(function(content) {
                $scope.pharmImages.theIcons[4].theSrc = content;
            });

            //Sets up the sidebar *****************************************************
            var leftNavInfo = {
                heading: '<span data-teamsite="MBR_PRESCRIPTION_LEFTNAV_HEADLINE"></span>',
                label: '<span data-teamsite="MBR_PRESCRIPTION_LEFTNAV_PRESCRIP"></span>',
                description: '<span data-teamsite="MBR_PRESCRIPTION_LEFTNAV_TEXT"></span>',
                onClick: function() {
                    $scope.funcs.coreMetrics('prescriptionSummary');
                }
            };

            var refillRenewLink = {
                label: '<span data-teamsite="MBR_PRESCRIPTION_LEFTNAV_REFILL_ORDERSTAT"></span>',
                onClick: function() {
                    $scope.funcs.hitSsoService('MAILRX', 'cmLeftNav');
                },
                newWindow: true
            };

            var rxCostSavings = {
                label: '<span data-teamsite="MBR_PRESCRIPTION_LEFTNAV_COSTSAVINGS"></span>',
                onClick: function() {
                    $scope.funcs.hitSsoService('PRESCRIPTION_COSTS_AND_SAVINGS', 'cmLeftNav');
                },
                newWindow: true
            };

            var rxClaimsHistory = {
                label: '<span data-teamsite="MBR_PRESCRIPTION_LEFTNAV_CLAIMHISTORY"></span>',
                onClick: function() {
                    $scope.funcs.hitSsoService('PRESCRIPTION_CLAIMS', 'cmLeftNav');
                },
                newWindow: true
            };

            var mailOrderPharmacy = {
                label: 'Pharmacy Coverage through Future Scripts',
                onClick: function() {
                    $scope.funcs.hitSsoService('MAILRX', 'cmLeftNav');
                },
                newWindow: true
            };

            var rxBenefits = {
                label: '<span data-teamsite="MBR_PRESCRIPTION_LEFTNAV_BENEFITHIGHLIGHTS"></span>',
                onClick: function() {
                    $scope.funcs.hitSsoService('PRESCRIPTION_BENEFITS', 'cmLeftNav')
                },
                newWindow: !isMnCr
            };

            if (isMnCr || isND || isWY ) {
                rxBenefits.href = "/mbr/#!/coverage/coverage-summary";
                rxBenefits.onClick = null;
            }

            links.push(leftNavInfo);

            if ($scope.showPrescriptionContent) {

                if (showRefillRenewIcon) {
                    links.push(refillRenewLink);
                }
                if (showCostSavingsIcon) {
                    links.push(rxCostSavings);
                }
                if (showClaimsHistoryIcon) {
                    links.push(rxClaimsHistory);
                }
                if (showIbcMailOrderIcon) {
                    links.push(mailOrderPharmacy);
                }
                if (showBenefitHighlightsIcon) {
                    links.push(rxBenefits);
                }
            }


            $scope.headings = "Prescriptions";
            $scope.sidebar.links = links;
            //AGB-206229 redirect to prescription services
            $scope.$on('$stateChangeSuccess',function() {
                var showPrescription="showPrescription=true";
                var url = window.location.href;
                if(url.includes(showPrescription)) {
                    rxCostSavings.onClick();
                }
            })

        }
    ]);

    return [
        {
            state: "prescriptions",
            postPassword: true,
            appConfigMaintenanceApp: 'PRESCRIPTIONS',
            appConfigMaintenanceKey: 'FULL_MAINTENANCE',
            url: "/prescriptions",
            views: {
                'body@': {
                    template: bootstrap.templates['page-templates/categoryCustom.html'],
                    controller: 'pages.prescription'
                },
                'content@prescriptions': {
                    template: require('pages/prescription.html')
                },
                "rightColumn@prescriptions": {}
            }
        },
        {
            state: "prescriptions.step-therapy-exception-form",
            postPassword: true,
            appConfigMaintenanceApp: 'PRESCRIPTIONS',
            appConfigMaintenanceKey: 'PRESCRIPTIONS_STEP_THERAPY_MAINTENANCE',
            url: "/step-therapy-exception-request",
            views: {
                'body@': {
                    template: bootstrap.templates['page-templates/detailCustom.html'],
                    controller: 'pages.prescriptions.step-therapy-exception-form'
                },
                "content@prescriptions.step-therapy-exception-form": {
                    template: require('pages/prescriptions/step-therapy-exception-form.html'),
                    controller: 'pages.prescriptions.step-therapy-exception-form'
                }
            }
        },
        {
            state: "prescriptions.medication-exception-form",
            postPassword: true,
            appConfigMaintenanceApp: 'PRESCRIPTIONS',
            appConfigMaintenanceKey: 'PRESCRIPTIONS_MEDICATION_EXCEPTION_MAINTENANCE',
            url: "/medication-exception-request",
            views: {
                'body@': {
                    template: bootstrap.templates['page-templates/detailCustom.html'],
                    controller: 'pages.prescriptions.medication-exception-form'
                },
                "content@prescriptions.medication-exception-form": {
                    template: require('pages/prescriptions/medication-exception-form.html'),
                    controller: 'pages.prescriptions.medication-exception-form'
                }
            }
        }
    ];
};