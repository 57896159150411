module.exports = function(bootstrap) {
    bootstrap.run(['Service', function(Service) {
        new Service(
            {
                name: 'spendingAccountIgnore',
                group: 'rbsmbl',
                cache: false,
                url: '/rbsmbl/x-services/spendingAccount/claim/v1/ignore',

                fetch: function(groupCode, claimBatchID, externalLineID) {
                    return this.get(
                        {
                            groupCode: groupCode,
                            claimBatchID: claimBatchID,
                            externalLineID: externalLineID
                        }
                    );
                }
            }
        );
    }]);
};