module.exports = function(app) {
    app.directive('compileHtml', ['$compile', '$parse',
        function($compile, $parse) {
            return {
                restrict: 'A',
                link: function (scope, element, attrs){
                        scope.$watch(attrs.compileHtml,
                          function (value) {
                            if(value){
                                element.html($parse(attrs.compileHtml)(scope));
                                $compile(element.contents())(scope);
                            }
                          }
                        );
                }
            };
        }
    ]);
};
