module.exports = function (app) {
	app.directive('userNotification', function(){
		return {
			restrict: 'A',
			transclude: true,
			scope: {
				title: '@',
				type: '@',
				icon: '@',
				displayLogic: '@'
			},
			template:
				'<div class="user-notification {{type}}">' +
					'<span data-ng-if="icon" class="hm-icon {{icon}}"></span>' +
					'<div data-ng-class="{\'indent-right\': icon}">' +
						'<h3>{{title}}</h3>' +
						'<data-ng-transclude></data-ng-transclude>'+
					'</div>' +
				'</div>'
		};
	});
};