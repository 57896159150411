/**
* @file This factory checks the truthiness of a collection boolean functions.
* 
* @author J.T. Gralka <James.Gralka@highmark.com>
* @author Michael Bauer <Michael.Bauer@highmark.com>
*/

module.exports = function (app) {
	
	app.factory('RuleEngine', ['$rootScope', function ($rootScope) {
		var _relations = ["AND", "OR"];
		
		return function (params) {
			params = params || {};

			var rules = params.rules || [];
			var relation = params.hasOwnProperty('relation') && (_relations.indexOf(params.relation.toUpperCase()) > -1) ? params.relation : "AND";

			var pass = (relation === "AND");
			rules.forEach(function (rule) {
				var ruleResult;
				if (typeof(rule) !== 'function') {
					return;
				} else {
					ruleResult = rule.call();
				}

				if (relation === "AND") {
					pass = pass && ruleResult == true;
				} else if (relation === "OR") {
					pass = pass || ruleResult == true;
				}
			});

			return pass;
		}
	}]);
};