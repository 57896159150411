module.exports = function(app) {

    require('./spending/receiptRequired')(app);
    require('./spending/claimsMatchingConfirm')(app);
    require('./spending/costSavingTools')(app);

    app.service("spendingResourceServices", ["$q", "services", "sessionData", "$cookies", function($q, services, session, $cookies){

        var _accountInfoPromises = function(accountList){
            var promisesArray = [];
            var clientNumber = $cookies.get("mbrClientNumber");
            if(accountList){
                for(var y in accountList) {
                    var accountSummaryData = accountList[y];
                    var detailDeferred = services.dpsext.spendingAccountInfo().fetch(session.memberInfo,clientNumber,accountSummaryData.accountType);
                    promisesArray.push(detailDeferred);
                }
                return promisesArray;
            } else {
                return [];
            }
        };

        this.getSpendingAccounts = function(memberInfo, clientNumber){
            var memberInfo = memberInfo || session.memberInfo;
            var clientNumber = clientNumber || $cookies.get("mbrClientNumber");

            var hasDebitCard = false;
            var actionItems = null;
            var defaultSpendingObject = {
                accountFlag : {},
                accountList : [],
                hasDebitCard : false
            };

            if (!(memberInfo && clientNumber)) {
                var defer = $q.defer();
                defer.resolve(defaultSpendingObject);
                return defer.promise;
            }

            if (session.benefits && !session.benefits.spending) {
                var defer = $q.defer();
                defer.resolve(defaultSpendingObject);
                return defer.promise;
            }

            return services.dpsext.spendingAccountGlance().fetch(memberInfo, clientNumber).then(function(response){
                    var accountDataArray = response
                        && response.data
                        && response.data.repGetAccountsAtAGlanceReply
                        && response.data.repGetAccountsAtAGlanceReply.payload
                        && response.data.repGetAccountsAtAGlanceReply.payload.repGetAccountsAtAGlanceData
                        && response.data.repGetAccountsAtAGlanceReply.payload.repGetAccountsAtAGlanceData.accountList
                        && response.data.repGetAccountsAtAGlanceReply.payload.repGetAccountsAtAGlanceData.accountList.accountInfo || [];

                    var accountFlagsObject = {};
                    var accountList = response.data.repGetAccountsAtAGlanceReply.payload.repGetAccountsAtAGlanceData.accountList.accountInfo || [];
                    
                    //Filter Account List - remove all election details for plans that ran out over a year ago and update election list count
                    if(accountList) {
                        for(var i = 0; i < accountList.length;) {
                            if(accountList[i].electionList && accountList[i].electionList.electionDetails) {
                                var electionDetails = accountList[i].electionList.electionDetails;                              
                                for(var j = 0; j < electionDetails.length;) {
                                    if(electionDetails[j].runOutEndDate && ((new Date() - new Date(electionDetails[j].runOutEndDate)) / 86400000) > 365) {
                                        accountList[i].electionList.electionDetails.splice(j, 1);
                                        accountList[i].electionListCount--;
                                    } else {
                                        j++;
                                    }
                                }
                                if(accountList[i].electionListCount == 0) {
                                    accountList.splice(i, 1);
                                } else {
                                    i++;
                                }
                            }
                        }
                    }
                    
                    var index = accountDataArray.length;
                    
                    var accountInfoPromises = _accountInfoPromises(accountList);

                    while(index--){
                        var info = accountDataArray[index];
                        var accountShortName = info.accountShortName;
                        accountFlagsObject[accountShortName] = true;
                    }

                    // individual flags still exist, however, these are combined indicators
                    accountFlagsObject["hasFSA"] = accountFlagsObject["HCRA"] || accountFlagsObject["DCRA"] || accountFlagsObject["HCRA_L"];
                    accountFlagsObject["hasHSA"] = accountFlagsObject["HSA"];
                    accountFlagsObject["hasHRA"] = accountFlagsObject["HRAW"] || accountFlagsObject["HRA"] || accountFlagsObject["HRAR"] || accountFlagsObject["HRAV"];


                    return $q.all(accountInfoPromises).then(
                        function(response){
                            for (var z=0; z < response.length; z++) {
                                var detailAccountInfo = response[z].data.repAccountInfoReply.payload.repAccountInfoData.accountList;
                                for (var x=0; x < detailAccountInfo.length; x++) {
                                    if(hasDebitCard){
                                        break;
                                    } else {
                                        for (var y=0; y < detailAccountInfo[x].accountOptionList.length; y++) {
                                            if (detailAccountInfo[x].accountOptionList[y].name == "Debit Card" && detailAccountInfo[x].accountOptionList[y].value == "Yes") {
                                                hasDebitCard = true;
                                                break;
                                            }
                                        }
                                    }
                                }
                            }

                            return {
                                accountFlag : accountFlagsObject,
                                accountList : accountList,
                                hasDebitCard : hasDebitCard
                            };
                        },
                        function(error){
                            return defaultSpendingObject;
                        }
                    );
                },
                function(error){
                    return defaultSpendingObject;
                }
            );
        };
    }]);

    app.controller('pages.spending', ["brand", "$scope", "spendingNavigation", "contentNavigationService", "spendingResourceServices", "session", "$window", "sessionData", "services", "$cookies", "spendingActionsNeeded", "brand", "storageOld", "$q", function (brand, $scope, spendingNavigation, contentNavigationService, spendingResourceServices, session, $window, sessionData, services, $cookies, spendingActionsNeeded, brand, storageOld, $q) {
        $window.document.title = "Member Spending";

        $scope.backText = 'Back';
        $scope.backAction = function () {
            // defined later
        };

        $scope.headings = "Spending";

        var clientNumber = $cookies.get("mbrClientNumber");
        var umiWithSuffix = sessionData.memberInfo.umi + '0';
        var portalSelectedPlan = services.rbsmbl.memberInfo().getPortalSelectedPlan(sessionData.memberInfo);

        var sidebarContentIDs = {
            "MBR_NAVIGATION_SPENDING_SUMMARY": "MBR_NAVIGATION_SPENDING_SUMMARY",
            "MBR_COST_SAVING_TOOLS_NAVIGATION": "MBR_COST_SAVING_TOOLS_NAVIGATION",
            "MBR_NAVIGATION_SPENDING_FORMS_POST": "MBR_NAVIGATION_SPENDING_FORMS_POST",
            "MBR_NAVIGATION_SPENDING_COVERED_EXPENSES": "MBR_NAVIGATION_SPENDING_COVERED_EXPENSES",
            "MBR_NAVIGATION_SPENDING_EDUCATIONALMATERIALS": "MBR_NAVIGATION_SPENDING_EDUCATIONALMATERIALS",
            "MBR_NAVIGATION_SPENDING_CLAIMS": "MBR_NAVIGATION_SPENDING_CLAIMS",
            "MBR_NAVIGATION_SPENDING_RECEIPTS": "MBR_NAVIGATION_SPENDING_RECEIPTS",
            "MBR_NAVIGATION_SPENDING_DEBIT_CARD": "MBR_NAVIGATION_SPENDING_DEBIT_CARD",
            "MBR_NAVIGATION_SPENDING_TRANSACTIONS": "MBR_NAVIGATION_SPENDING_TRANSACTIONS",
            "MBR_NAVIGATION_SPENDING_ACCOUNT": "MBR_NAVIGATION_SPENDING_ACCOUNT"
        };

        function registerSidebarLinkPromise(promise, sidebarLink) {
            $q.when(promise, function (response) {
                if (response) {
                    $scope.sidebar.links.push(sidebarLink);
                    $scope.sidebar.links.sort(function (a, b) {
                        return a.order - b.order;
                    });
                }
            });
        }

        $scope.sidebar.links = [];

        $scope.$watch(function () {
            return sessionData.loggedIn;
        }, function () {
           services.rbsmbl.contentInfo().getContent(sidebarContentIDs, {}).then(function (response) {
                var linksObj = {};
                var links = (response
                    && response.data
                    && response.data.payload
                    && response.data.payload.contentResponse)
                        ? response.data.payload.contentResponse: [];

                links.forEach(function (link) {
                    try {
                        var content = JSON.parse(link["value"]);
                        
                        if (content.externalLink && content.externalLink === "Yes") {
                            content.newWindow = true;
                        }

                        linksObj[link["name"]] = content;
                    } catch(e) {
                        throw new Error(e);
                    }
                });

                var conditionalContentIDs = [
                    "MBR_NAVIGATION_SPENDING_ACCOUNT",
                    "MBR_NAVIGATION_SPENDING_COVERED_EXPENSES",
                    "MBR_NAVIGATION_SPENDING_CLAIMS",
                    "MBR_NAVIGATION_SPENDING_TRANSACTIONS",
                    "MBR_NAVIGATION_SPENDING_RECEIPTS",
                    "MBR_NAVIGATION_SPENDING_DEBIT_CARD",
                    "MBR_NAVIGATION_SPENDING_FORMS_POST",
                    "MBR_NAVIGATION_SPENDING_EDUCATIONALMATERIALS"
                ];

                // Add unconditional links to the sidebar.
                for (var key in linksObj) {
                    if (conditionalContentIDs.indexOf(key) == -1) {
                        $scope.sidebar.links.push(linksObj[key]);
                    }
                }

                // Manage Spending Account logic
                if (portalSelectedPlan.spendingAccountExistsIndicator === "true") {
                    $scope.sidebar.links.push(linksObj["MBR_NAVIGATION_SPENDING_ACCOUNT"]);
                }

                // Covered Expenses logic
               if (brand.current.ubk !== 6010 && brand.current.ubk !== 6011 && brand.current.ubk !== 6012) {
                   $scope.sidebar.links.push(linksObj["MBR_NAVIGATION_SPENDING_COVERED_EXPENSES"]);
               }

               // remove educational material for wy
               if (brand.current.ubk !== 6012) {
                   $scope.sidebar.links.push(linksObj["MBR_NAVIGATION_SPENDING_EDUCATIONALMATERIALS"]);
               }

               // remove for forms library for wy and nd
               if (brand.current.ubk !== 6012 && brand.current.ubk !== 6011) {
                   $scope.sidebar.links.push(linksObj["MBR_NAVIGATION_SPENDING_FORMS_POST"]);
               }

                // Spending Claims logic
                if (portalSelectedPlan.spendingAccountExistsIndicator === "true") {
                    $scope.sidebar.links.push(linksObj["MBR_NAVIGATION_SPENDING_CLAIMS"]);
                }

                // Receipts logic
                if (portalSelectedPlan.spendingAccountExistsIndicator === "true") {
                    $scope.sidebar.links.push(linksObj["MBR_NAVIGATION_SPENDING_RECEIPTS"]);
                }

                // Do an initial sort based on the assigned order of each of the sidebar links.
                $scope.sidebar.links.sort(function (a, b) {
                    return a.order - b.order;
                });

                // Transaction History logic
                registerSidebarLinkPromise(function () {
                    var deferred = $q.defer();
                    if (brand.current.ubk == 6010 || brand.current.ubk == 6012 ) {
                        services.rbsmbl.vendorDetails().fetch({
                            "memberid": sessionData.memberInfo.umi,
                            "groupnumber": portalSelectedPlan.groupNumber,
                            "clientnumber": clientNumber
                        }).then(function (response) {
                            var vendorCode = (response
                                && response.data
                                && response.data.payload
                                && response.data.payload.vendorCode
                                && typeof(response.data.payload.vendorCode) === 'string')
                                    ? response.data.payload.vendorCode : "";
                            
                            deferred.resolve(vendorCode.toUpperCase() !== "PAYFLEX" && vendorCode !== "" && sessionData.isSubscriber);
                        }, function (error) {
                            deferred.resolve(false);
                        });
                    } else {
                        deferred.resolve(portalSelectedPlan.spendingAccountExistsIndicator === "true");
                    }
                    return deferred.promise;
                }(), linksObj["MBR_NAVIGATION_SPENDING_TRANSACTIONS"]);

                // Debit Card logic
                registerSidebarLinkPromise(function () {
                    var deferred = $q.defer();
                    spendingResourceServices.getSpendingAccounts(sessionData.memberInfo, clientNumber).then(function (spendingAccounts) {
                        deferred.resolve(spendingAccounts.hasDebitCard);
                    }, function (error) {
                        deferred.resolve(false);
                    });
                    return deferred.promise;
                }(), linksObj["MBR_NAVIGATION_SPENDING_DEBIT_CARD"]);
           }, function (error) {
                throw new Error(error);
           });
        });
    }]);

    return [
        {
            state: "spending",
            postPassword: true,
            appConfigMaintenanceApp: 'SPENDING',
            //appConfigMaintenanceKey: 'FULL_MAINTENANCE',
            url: "/spending",
            resolve: {
                spendingNavigation: ["contentNavigationService", function(contentNavigationService) {
                    return contentNavigationService.getNavigationIdListAsync();
                }],
                redirect: ['sessionData', '$q', '$window', function (sessionData, $q, $window) {
                    var deferred = $q.defer();
                    if (sessionData.isClaimsAndSpending) {
                        if ($cookies.get('statePriorToSpending')) {
                            deferred.reject();
                        } else {
                            var route = $window.location.hash;
                            route = route.replace('#', '');
                            route = route.replace('/spending', '');
                            route = route.replace('/', '');

                            switch (route) {
                            case "spendingAcccount":
                            case "":
                            default:
                                $window.location = "#/claims-and-spending/spending-accounts";
                            }

                            deferred.reject();
                        }
                    } else {
                        deferred.resolve();
                    }

                    return deferred.promise;
                }]
            },
            views: {
                'body@': {
                    template: bootstrap.templates['page-templates/detail.html'],
                    controller: "pages.spending"
                },
                "leftColumn@spending": {
                    //empty
                },
                "content@spending": {
                    template: require('pages/spending/receiptRequired.html'),
                    controller: "pages.spending.receiptRequired"
                }
            }
        },
        {
            state: "spending.receiptRequired",
            appConfigMaintenanceApp: 'SPENDING',
            //appConfigMaintenanceKey: 'ACTIVITY_SUMMARY_MAINTENANCE',
            url: "/receiptRequired",
            views: {
                'body@': {
                    template: bootstrap.templates['page-templates/detailCustom.html'],
                    controller: "pages.spending"
                },
                "leftColumn@spending.receiptRequired": {
                    //empty
                },
                "content@spending.receiptRequired": {
                    template: require('pages/spending/receiptRequired.html'),
                    controller: "pages.spending.receiptRequired"
                },
                "rightColumn@claims": {
                    // empty
                }
            }
        },
        {
            state: "spending.claimsMatchingConfirm",
            appConfigMaintenanceApp: 'SPENDING',
            //appConfigMaintenanceKey: 'ACTIVITY_SUMMARY_MAINTENANCE',
            url: "/claimsMatchingConfirm",
            views: {
                'body@': {
                    template: bootstrap.templates['page-templates/detailCustom.html'],
                    controller: "pages.spending"
                },
                "leftColumn@spending.receiptRequired": {
                    //empty
                },
                "content@spending.claimsMatchingConfirm": {
                    template: require('pages/spending/claimsMatchingConfirm.html'),
                    controller: "pages.spending.claimsMatchingConfirm"
                },
                "rightColumn@claims": {
                    // empty
                }
            }
        },
        {
            state: "spending.forms",
            postPassword: true,
            appConfigMaintenanceApp: 'SPENDING',
            appConfigMaintenanceKey: 'FORMS_MAINTENANCE',
            url: "/forms",
            resolve: {
                resourcesList: ["contentResourceServices", function(contentResourceServices){
                    return contentResourceServices.getResourceIdListAsync();
                }]
            },
            views: {
                "body@": {
                    template: bootstrap.templates["page-templates/category.html"],
                    controller: "pages.spending"
                },
                "content@spending.forms": {
                    template:
                    "<section class='spending-forms' data-coremetrics-pageview='SPENDING:FORMS 1' data-coremetrics-category='MEMBER:SPENDING'>"+
                    "<h2>Forms Library</h2>"+
                    "<div data-ng-if='!session.loggedIn'>"+
                    "<div class='margin-top'  data-teamsite='MBR_SPENDING_FORMS_PRE_PASSWORD as content' data-ng-bind-html='content'></div>"+
                    "</div>"+
                    "<div data-ng-if='session.loggedIn'>"+
                    "<div class='margin-top' data-teamsite='MBR_SPENDING_FORMS_POST_PASSWORD as content' data-ng-bind-html='content'></div>"+
                    "</div>"+
                    "<div class='margin-top' data-ng-if='loading'>Loading...</div>"+
                    "<div class='margin-top' data-ng-if='!resources.length && !loading'>No forms available</div>"+
                    "<div class='margin-top' data-ng-if='resources.length > 0'>" +
                    "<h3>Recommended Items ({{filteredResources.length}})</h3>" +
                    "<ul class='margin-top' >" +
                    "<li data-drawer='item.open' data-ng-repeat='item in filteredResources = (resources | filter:sidebar.keywordRefine.value | orderBy:\"+title\") | pagination: {page: pagination.page, itemsPerPage: pagination.itemsPerPage}'>" +
                    "<div data-drawer-header tabindex='0' role='button' aria-expanded='{{isExpanded}}'>" +
                    "<div class='margin-bottom'>" +
                    "<h4 class='margin-bottom'>" +
                    "<span data-ng-if='item.type==\"video\"' class='hm-icon icon-video'></span>"+
                    "<span data-ng-if='item.type==\"pdf\"' class='hm-icon icon-product-info'></span>"+
                    "{{item.title}}</h3>" +
                    "</div>"+
                    "</div>" +
                    "<div data-drawer-body>" +
                    "<div data-ng-if='item.description' class='margin-bottom'>" +
                    "<div data-ng-bind-html='item.description'></div>" +
                    "</div>" +
                    "<button type='button' data-ng-click='download(item);' class='margin-top download'>" +
                    "<span data-ng-if='item.type==\"video\"'><span class='hm-icon icon-video'></span> Download Video</span>"+
                    "<span data-ng-if='item.type==\"pdf\"'><span class='hm-icon icon-product-info'></span> Download PDF</span>"+
                    "</button>" +
                    "</div>" +
                    "</li>"+
                    "</ul>" +
                    "<div data-pagination-controls data-length='filteredResources.length' data-items-per-page='pagination.itemsPerPage' data-page='pagination.page'></div>"+
                    "</div>" +
                    "</section>",
                    controller: "pages.spending.forms"
                }
            }
        },
        {
            state: 'spending.costSavingTools',
            appConfigMaintenanceApp: 'SPENDING',
            appConfigMaintenanceKey: 'COST_SAVING_TOOLS_MANTENACE',
            url: '/cost-saving-tools',
            postPassword: true,
            resolve: {
                resourcesList: ["contentResourceServices", function(contentResourceServices){
                    return contentResourceServices.getResourceIdListAsync();
                }]
            },
            views: {
                "body@": {
                    template: bootstrap.templates["page-templates/category.html"],
                    controller: "pages.spending"
                },
                "content@spending.costSavingTools": {
                    template: require("./spending/costSavingTools.html"),
                    controller: "pages.spending.costSavingTools"
                }
            }
        },
        {
            state: "spending.educationalMaterial",
            appConfigMaintenanceApp: 'SPENDING',
            appConfigMaintenanceKey: 'EDUCATIONAL_MATERIALs_MAINTENANCE',
            postPassword: true,
            url: "/educational-materials",
            resolve: {
                resourcesList: ["contentResourceServices", function(contentResourceServices){
                    return contentResourceServices.getResourceIdListAsync();
                }]
            },
            views: {
                'body@': {
                    template: bootstrap.templates['page-templates/categoryCustom.html'],
                    controller: "pages.spending"
                },
                "content@spending.educationalMaterial": {
                    template: require('pages/claims/educationalMaterial.html'),
                    controller: "pages.claims.educationalMaterial"
                }
            }
        }
    ];
};