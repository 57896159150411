module.exports = function(app) {

    app.factory('dentalService', ['services',
        function(services) {
            var
                getDentalProcedureHistory,
                getDentalMembersByGroupNumber;



            getDentalProcedureHistory = function(groupNumber, pin) {
                var dentalProcedureHistoryPromise = services.rbsmbl.dentalProcedureHistory().fetch(groupNumber, pin);

                return dentalProcedureHistoryPromise.then(
                    function(response) {
                        return response.data.repDentalProcedureHistoryResp.payload.repDentalProcedureHistoryData;
                    },
                    function(err) {
                        console.error('dentalProcedureHistory.fetch failed with error:', err);
                        return dentalProcedureHistoryPromise;
                    }
                );
            };


            getDentalMembersByGroupNumber = function(groupNumber) {
                var uniqueProductsPromise = services.rbsmbl.memberInfo().fetch().then(
                    function(response) {
                        return services.rbsmbl.memberInfo().getUniqueProducts(response.data.payload);
                    },
                    function(err) {
                        console.error('memberInfo.fetch failed with error', err);
                        return err;
                    }
                );

                return uniqueProductsPromise.then(
                    function(uniqueProducts) {
                        var memberList = [];
                        for (var i = 0, product, len = uniqueProducts.length; i < len; ++i) {
                            product = uniqueProducts[i];

                            if (product.groupData instanceof Object && product.groupData.groupNumber === groupNumber) {
                                memberList = product.productData.memberList;
                                break;
                            }
                        }

                        return memberList.map(function(member) {
                            member.contact = member.contact || {};

                            var
                                firstName = typeof member.contact.firstName === 'string'
                                          ? member.contact.firstName.toLowerCase()
                                          : '',

                                lastName = typeof member.contact.lastName === 'string'
                                         ? member.contact.lastName.toLowerCase()
                                         : '',

                                birthDate = member.contact.birthDate || '',
                                fullName = firstName + ' ' + lastName,
                                uniqueName = fullName + ' (' + birthDate + ')',
                                pin = member.pinNumber || '';


                            return {
                                firstName: firstName,
                                lastName: lastName,
                                fullName: fullName,
                                birthDate:  birthDate,
                                uniqueName: uniqueName,
                                pin: pin
                            };
                        });
                    },

                    function(err) {
                        console.error('uniqueProductsPromise failed with error', err);
                        return err;
                    }
                );
            };

            return {
                getDentalProcedureHistory: getDentalProcedureHistory,
                getDentalMembersByGroupNumber: getDentalMembersByGroupNumber
            };
        }
    ]);
};