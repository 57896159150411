module.exports = function(bootstrap) {
	bootstrap.directive('superiortooltip', ["$compile", "$q", function ($compile, $q) {
		return {
			link: function(scope, element, attrs) {

				scope.$watch(attrs.superiortooltip, function(v) {
					$q.when(v).then(function(dv) {
						scope.tooltip = dv;
					});
				});

				var accessibleNode = $compile('<div class="offscreen"><div data-compile="tooltip"></div></div>')(scope);
				var visualNode = $compile('<div class="tooltip"><div class="caretUp"><div></div></div><div class="tooltipClose"><span class="hm-icon icon-x" style="color: #39454e"></span></div><div data-compile="tooltip"></div></div>')(scope);
				element.addClass('tooltipAction');
				element.after(accessibleNode);

				var tooltipOpen = false;

				var relocateTooltip = function() {
                    var responsiveOffset = 0;
                    var tooltipRight = element.offset().left + visualNode[0].offsetWidth;
                    var rightBound = $(window).width();

                    if (tooltipRight > rightBound) {
                        responsiveOffset = tooltipRight - rightBound + 12;
                    }

                    visualNode.css(
                        { // Update it's CSS
                            left: element.offset().left - responsiveOffset,
                            top: element.offset().top + element.height()
                        }
                    );

                    var $caretUp = $(visualNode[0].firstChild);
                    $caretUp.css({ left: responsiveOffset + 5 });
                };

                var openTip = function(evt) {
					if ($(evt.target).closest(element).length) {
						$('body').append(visualNode);
						relocateTooltip();
						tooltipOpen = true;
						$(window).on('resize', relocateTooltip);
					}
				};

				var closeTip = function() {
					if (tooltipOpen) { // necessary for $stateChangeStart
						visualNode.remove();
						tooltipOpen = false;
						$(window).unbind('resize', relocateTooltip);
					}
				};

				var toggleTip = function(evt) {
					if (tooltipOpen) {
						closeTip();
					}
					else {
						openTip(evt);
					}
				};

				$(document).on('click', toggleTip);
				scope.$on('$stateChangeStart', closeTip);
			}
		};
	}]);
};
