module.exports = function(app) {
    app.controller('pages.claimsAndSpending.casMessageCenter', ['$scope', '$state', 'messageService', 'storage', '$filter', 'coremetrics', 'updateVisited', '$location',
        function($scope, $state, messageService, storage, $filter, coremetrics, updateVisited, $location) {

            coremetrics.elementTag('REFINE:CLAIMS MESSAGES','MEMBER:CLAIMS AND SPENDING');

            $scope.headings = 'CLAIMS & BENEFITS MESSAGES';
            $scope.viewState = 'loading';
            $scope.sidebar.messageMembers = [];
            $scope.sidebar.messageTypes = [];

            $scope.firstDrowerInitialCheck = false;

            $scope.pagination = {
                itemsPerPage: '10'
            };
            $scope.pagination.page = 1;
            $scope.$watch('pagination.page', function(v) {
                storage.set('messagesRefinePage', v);
            });
            $scope.sidebar.filtersChanged = function() {
                $scope.pagination.page = 1;
            };

            $scope.sidebar.removePaQueryParam = function(name){
                if(name.toLowerCase() === 'prior authorization' && ($state.params && $state.params.filterType === 'priorAuth')){
                    //Remove Query Param for Prior Auth, if exists, to handle refresh.
                    $location.search({});
                }
            };

            $scope.$on('prevClick', function() {
                var prevPage = $scope.pagination.page;
                prevPage -= 1;
                coremetrics.pageviewTag('CLAIMS AND SPENDING:CLAIMS AND PAYMENTS ' + prevPage,'MEMBER:CLAIMS AND SPENDING');
                coremetrics.elementTag('PREV:CLAIMS AND SPENDING','MEMBER:CLAIMS AND SPENDING');
            });

            $scope.$on('nextClick', function() {
                var nextPage = $scope.pagination.page;
                nextPage += 1;
                coremetrics.pageviewTag('CLAIMS AND SPENDING:CLAIMS AND PAYMENTS ' + nextPage, 'MEMBER:CLAIMS AND SPENDING');

            });

            $scope.$on('goToPageClick', function() {
                coremetrics.elementTag('SELECT PAGE NUMBER:CLAIMS AND SPENDING','MEMBER:CLAIMS AND SPENDING');
            });

            var toggleAll = function(checked, messages) {

                coremetrics.elementTag('SELECT ALL BOX:CLAIMS AND SPENDING','MEMBER:CLAIMS AND SPENDING');

                if (checked) {
                    messages.forEach(function(message) {
                        message.isChecked = true;
                    });
                }
                else {
                    messages.forEach(function(message) {
                        message.isChecked = false;
                    });
                }
            };



            $scope.sidebar.fetchMessages = function(dateRange) {
                var updateFilter = function(original, updated, keyCheck) {
                    var retVal = angular.copy(updated);
                    for (var i = 0; i < original.length; i++) {
                        for (var x = 0; x < retVal.length; x++) {
                            if (angular.equals(original[i][keyCheck], retVal[x][keyCheck])) {
                                retVal[x].value = original[i].value;
                            }
                        }
                    }
                    return retVal;
                };

                dateRange = JSON.parse(dateRange || createDateRange(365));
                var startDate = Number(new Date(dateRange.startDate.substr(4,2) + '/' + dateRange.startDate.substr(6,2) + '/' + dateRange.startDate.substr(0,4))),
                    endDate = Number(new Date(dateRange.endDate.substr(4,2) + '/' + dateRange.endDate.substr(6,2) + '/' + dateRange.endDate.substr(0,4)));

                messageService.getMessages(startDate, endDate).then(
                    function(messages) {
                        $scope.viewState = 'success';
                        $scope.messageList = messages.messageList;


                        // Convert dates to yyyy-MM-dd format for sorting in the view.
                        $scope.messageList.map(function(message){
                            message.messageDate = $filter('date')(new Date(message.messageDate), 'yyyy-MM-dd');
                        });

                        $scope.isFirstVisit = !messages.lastMessageCenterVisitDate;
                        $scope.toggleAll = toggleAll;

                        $scope.sidebar.messageMembers = updateFilter($scope.sidebar.messageMembers, messageService.getMembers(messages.messageList), 'name');
                        $scope.sidebar.messageTypes = updateFilter($scope.sidebar.messageTypes, messageService.getCategories(messages.messageList), 'name');

                        //To display Prior Auth messages only
                        if($state.params.filterType && $state.params.filterType === 'priorAuth'){
                            for(var xx=0; xx < $scope.sidebar.messageTypes.length; xx++){
                                if($scope.sidebar.messageTypes[xx].name.toLowerCase() === 'prior authorization'){
                                    $scope.sidebar.messageTypes[xx].value = true;
                                }
                            }
                        }

                    },
                    function(err) {
                        $scope.viewState = 'error';
                        console.error('getMessages failed with error', err);
                    }
                );
            };

            $scope.messageFilter = function(message) {
                var visible = $scope.messageMemberFilter(message) && $scope.messageTypeFilter(message);
                if (typeof message.messageCategory === 'string' && message.messageCategory.toLowerCase() === 'special'){
                    visible = $scope.messageTypeFilter(message) && filterSpecialMessage(message, $scope.currentMemberList);
                }
                else {
                    visible = $scope.messageMemberFilter(message) && $scope.messageTypeFilter(message);
                }
                message.visible = visible;
                // console.log("is open filter",$scope.messageList[0].isOpen);
                //  console.log("$scope.firstDrowerInitialCheck",$scope.firstDrowerInitialCheck);
                if(!$scope.firstDrowerInitialCheck && !$scope.messageList[0].isOpen){
                    $scope.messageList[0].isOpen = true;
                    $scope.firstDrowerInitialCheck = true;
                    //   console.log("came inside")
                }
                return visible;
            };

            $scope.currentMemberList = [];
            var filterSpecialMessage = function(message, currentMemberList) {
                while (currentMemberList.length) {
                    currentMemberList.pop();
                }

                for (var i = 0; i < $scope.sidebar.messageMembers.length; ++i) {
                    if ($scope.sidebar.messageMembers[i].value) {
                        currentMemberList.push($scope.sidebar.messageMembers[i].name);
                    }
                }

                if (currentMemberList.length === 0) {
                    message.messageContent.memberList.forEach(function(member) {
                        currentMemberList.push(member);
                    });
                    return true;
                }
                else {
                    return message.messageContent.memberList.some(function (member) {
                        return currentMemberList.some(function (selectedMember) {
                            return member.toLowerCase() === selectedMember.toLowerCase();
                        });
                    });
                }
            };


            $scope.messageMemberFilter = function(message) {
                var checkedCount = 0;
                var itemName;

                for (var i = 0; i < $scope.sidebar.messageMembers.length; ++i) {
                    if ($scope.sidebar.messageMembers[i].value) {
                        checkedCount++;
                    }
                }

                if (checkedCount === 0 || checkedCount === $scope.sidebar.messageMembers.length) {
                    return true;
                }

                for (var k = 0; k < $scope.sidebar.messageMembers.length; ++k) {
                    itemName = typeof $scope.sidebar.messageMembers[k].name === 'string' && $scope.sidebar.messageMembers[k].name.toLowerCase();

                    if (typeof message.firstName === 'string' && message.firstName.toLowerCase() === itemName) {
                        return Boolean($scope.sidebar.messageMembers[k].value);
                    }
                }
            };

            $scope.messageTypeFilter = function(message) {
                var checkedCount = 0;

                for (var i = 0; i < $scope.sidebar.messageTypes.length; ++i) {
                    if ($scope.sidebar.messageTypes[i].value) {
                        checkedCount++;
                    }
                }

                if (checkedCount === 0 || checkedCount === $scope.sidebar.messageTypes.length) {
                    return true;
                }

                for (var j = 0, type = message.messageType, item; j < $scope.sidebar.messageTypes.length; ++j) {
                    item = $scope.sidebar.messageTypes[j];
                    if (type === item.name) {
                        return Boolean(item.value);
                    }
                }
            };

            $scope.doDelete = function(listOfMessages) {
                if ($scope.allChecked){
                    coremetrics.elementTag('DELETE ALL:CLAIMS AND SPENDING','MEMBER:CLAIMS AND SPENDING');
                }
                else {
                    coremetrics.elementTag('DELETE:CLAIMS AND SPENDING','MEMBER:CLAIMS AND SPENDING');
                }

                if (!(listOfMessages && listOfMessages.length)) { return; }

                var queuedForDeletion = listOfMessages.filter(
                    function(message) {
                        return message.isChecked && !message.isSpecial;
                    }
                );

                if (!queuedForDeletion.length) { return; }

                messageService.deleteMessages(queuedForDeletion).then(
                    function() {
                        $scope.sidebar.fetchMessages($scope.sidebar.messageDateRange);
                        $scope.allChecked = false;
                    },
                    function(err) {
                        console.error('messageService.deleteMessages failed with error', err);
                    }
                );
            };



            var createDateRange = function(startDaysAgo, endDaysAgo) {
                var
                    startDate = $filter('date')(new Date() - startDaysAgo*60*60*24*1000, 'yyyyMMdd'),

                    endDate = endDaysAgo
                        ? $filter('date')(new Date() - endDaysAgo*60*60*24*1000, 'yyyyMMdd')
                        : $filter('date')(new Date(), 'yyyyMMdd');

                return JSON.stringify({ startDate: startDate, endDate: endDate });
            };

            $scope.sidebar.messageDateRangeOptions = [
                { label: "Last 12 Months", value: createDateRange(365) },
                { label: "Last 6 Months", value: createDateRange(180) },
                { label: "Last 90 Days", value: createDateRange(90) },
                { label: "Last 60 Days", value: createDateRange(60) },
                { label: "Last 30 Days", value: createDateRange(30) }
            ];

            var value = storage.get('messageRefineDateRange');
            if (value) {
                var parsedValue = JSON.parse(value);
                var label =
                    parsedValue.startDate.substr(4,2) + '/' +
                    parsedValue.startDate.substr(6,2) + '/' +
                    parsedValue.startDate.substr(0,4) + ' - ' +
                    parsedValue.endDate.substr(4,2) + '/' +
                    parsedValue.endDate.substr(6,2) + '/' +
                    parsedValue.endDate.substr(0,4);

                var found = false;
                for (var i = 0; i < $scope.sidebar.messageDateRangeOptions.length; i++) {
                    if ($scope.sidebar.messageDateRangeOptions[i].value === value) {
                        $scope.sidebar.messageDateRange = value;
                        found = true;
                    }
                }
                if (!found) {
                    $scope.sidebar.messageDateRangeOptions.push({ label: label, value: value });
                    $scope.sidebar.messageDateRange = value;
                }
            }
            else {
                $scope.sidebar.messageDateRange = createDateRange(365);
            }

            $scope.$watch('sidebar.messageDateRange', function(r) {
                for (var i = 0, options = $scope.sidebar.messageDateRangeOptions, value; i < options.length; i++) {
                    value = options[i].value;

                    if (value === r) {
                        if (i < 5) {
                            options.splice(5, 1);
                        }
                    }
                }
                $scope.sidebar.fetchMessages($scope.sidebar.messageDateRange);
            });

            updateVisited.messageCenter();
        }
    ]);
}