module.exports = function(app){
    app.controller("pages.vision-health.vision-wellness", ["$scope", "$state", "modal", function($scope, $state, modal){

        $scope.visionLinks = [
            "MBR_VISION_HEALTH_NAVIGATION_LINK2",
            "MBR_VISION_HEALTH_NAVIGATION_LINK3",
            "MBR_VISION_HEALTH_NAVIGATION_LINK4",
            "MBR_VISION_HEALTH_NAVIGATION_LINK5",
            "MBR_VISION_HEALTH_NAVIGATION_LINK6",
            "MBR_VISION_HEALTH_NAVIGATION_LINK7"
        ]
    }]);
};