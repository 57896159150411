module.exports={
    "Bariatric Surgery" : {
        "header" : "Blue Distinction Centers for Bariatric Surgery provide a full range of bariatric surgery care services, including: ",
        "servicesList" : [
            "Inpatient care",
            "Post-operative care",
            "Outpatient follow-up care",
            "Patient education"
        ],
        "footer" : "Review your benefit details or contact Member Service to see if Blue Distinction benefits apply to your particular procedure"
    },
    "Cardiac Care" : {
        "header" : "Blue Distinction Centers for Cardiac Care provide a full range of cardiac care services, including: ",
        "servicesList" : [
            "Inpatient cardiac care",
            "Cardiac rehabilitation",
            "Cardiac catheterization",
            "Cardiac surgery (including coronary artery bypass graft surgery)"
        ],
        "footer" : "Review your benefit details or contact Member Service to see if Blue Distinction benefits apply to your particular procedure."
    },
    "Complex and Rare Cancers" : {
        "header" : "Blue Distinction's Complex and Rare Cancers program focuses on treatments for complex and rare cancers, including: ",
        "servicesList" : [
                "Bladder",
                "Bone - Primary",
                "Brain - Primary",
                "Esophageal",
                "Gastric",
                "Head and Neck",
                "Liver - Primary",
                "Ocular Melanoma",
                "Pancreatic",
                "Rectal",
                "Soft Tissue Sarcomas",
                "Thyroid - Medullary or Anaplastic",
                "Acute Leukemia (Inpatient, Non-surgical)"
        ],
        "footer" : "Review your benefit details or contact Member Service to see if Blue Distinction benefits apply to your particular procedure."
    },
    "Knee and Hip Replacement" : {
        "header" : "Blue Distinction Centers for Knee and Hip Replacement provide comprehensive inpatient knee and hip replacement services, including: ",
        "servicesList" : [
                "Total Knee Replacement",
                "Total Hip Replacement"
        ],
        "footer" : "Review your benefit details or contact Member Service to see if Blue Distinction benefits apply to your particular procedure."
    },
    "Spine Surgery" : {
        "header" : "Blue Distinction Centers for Spine Surgery provide comprehensive inpatient spine surgery services, including: ",
        "servicesList" : [
                "Discectomy",
                "Spinal fusion",
                "Spinal decompression procedures"
        ],
        "footer" : "Review your benefit details or contact Member Service to see if Blue Distinction benefits apply to your particular procedure."
    },
    "Transplant" : {
        "header" : "The Blue Distinction Centers for Transplants program can help you find the transplant program that meets your needs. Blue Distinction Centers provide a range of services for transplant, including: ",
        "servicesList" : [
                "Heart",
                "Lung (single or bilateral)",
                "Liver (deceased and living donor)",
                "Simultaneous pancreas kidney (SPK)",
                "Pancreas (PAK/PTA)",
                "Bone marrow/stem cell (autologous and allogeneic)"
        ],
        "footer" : "Review your benefit details or contact Member Service to see if Blue Distinction benefits apply to your particular procedure."
    },
    
    "Transplants" : {
        "header" : "The Blue Distinction Centers for Transplants program can help you find the transplant program that meets your needs. Blue Distinction Centers provide a range of services for transplant, including: ",
        "servicesList" : [
                "Heart",
                "Lung (single or bilateral)",
                "Liver (deceased and living donor)",
                "Simultaneous pancreas kidney (SPK)",
                "Pancreas (PAK/PTA)",
                "Bone marrow/stem cell (autologous and allogeneic)"
        ],
        "footer" : "Review your benefit details or contact Member Service to see if Blue Distinction benefits apply to your particular procedure."
    }




}


