module.exports = function(app) {
    app.directive('splashEspotGroup', ['$compile', function($compile) {
        var createTemplate = function(scope) {
            var $tmpl = $('<article class="espotList"></article>');

            scope.espotList.forEach(function(espot, i) {
                var element;
                scope['espot' + i] = espot;
                element = $compile('<article data-splash-espot=espot' + i +' class="splashEspot"></article>')(scope);
                $tmpl.append(element[0]);
            });
            return $tmpl;
        };

        return {
            restrict: 'A',
            replace: false,
            scope: {
                espotList: '=splashEspotGroup'
            },
            link: function(scope, elem) {
                elem.html(createTemplate(scope));
                $compile(elem.contents())(scope);
            }
            };
    }]);
};
