module.exports = function(app) {
    app.factory('invisibleFormService', ['$window',
        function($window) {

            var submit = function(formConfig) {
                var
                    defaults,
                    formData,
                    inputs,
                    form;

                defaults = {
                    'name': 'aFormName',
                    'method': 'POST',
                    'action': '/',
                    'target': '_self',
                    'inputs': {
                        'aName': 'aValue'
                    }
                },

                formData = angular.extend(defaults, formConfig),
                inputs = formData.inputs,

                // form = '<form style="display: none;" name="' +  formData.name + '" method="' + formData.method + '" action="' + formData.action + '" >';
                    form = '<form target="' + formData.target + '" style="display: none;" name="' +  formData.name + '" method="' + formData.method + '" action="' + formData.action + '" >';

                var inputArray =[];
                Object.keys(inputs).forEach(function(key) {
                    // form += '<input name="' + key + '" value=\'' + inputs[key] + '\' />';
                    inputArray[inputArray.push($('<input name="' + key + '">'))-1][0].value = inputs[key];
                });

                form += '</form>';

                form = $window.document.body.appendChild($(form).append(inputArray)[0]);
                // console.log(form);

                form.submit();
            };

            return { submit: submit };
        }
    ]);
}