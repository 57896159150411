module.exports = function(app) {
    require("./secure-registration/secureRegister.step1")(app);
    require("./secure-registration/secureRegister.step2")(app);
    require("./secure-registration/secureRegister.step3")(app);
    require("./secure-registration/secureRegister.error")(app);

    app.controller("pages.secureRegistration", ["$state", "$scope", "services", function($state, $scope, services) {
        $scope.headings = "Create Password / Security Q&A";
        $scope.showSection = false;
        $scope.chevron = {};
        $scope.goto = function(step, params) {
            $state.go('secureRegistration.step' + step, params);
        };

        $scope.myChevron = {
            steps: [
                { label: "Security questions", state: "secureRegistration.step1", validSteps: [] },
                { label: "Terms & conditions", state: "secureRegistration.step2", validSteps: [] },
                { label: "Create a password", state: "secureRegistration.step3", validSteps: [] }
            ]
        };

        var brandCode = $scope.brandData.sharedBrandCode.toUpperCase();
        if(brandCode === 'HBS'){
            brandCode = 'PBS';
        }
        services.cmcrst.getList({applicationId: 'SPLASH', brandId: brandCode }).fetch().then(
            function(response) {
                if (response && response.data && response.data.payload &&
                        response.data.payload.SECURE_REGISTRATION &&
                        response.data.payload.SECURE_REGISTRATION.booleanValue === true) {
                    $scope.showSection = true;
                } else {
                    location.replace("/home/");
                }
            },
            function(error) {
                location.replace("/home/");
            }
        );
    }]);
    return [
        {
            state: "secureRegistration",
            url: "/secure-registration",
            views: {
                "body@": {
                    template: require('pages/secure-registration.html'),
                    controller: "pages.secureRegistration"
                },
                "content@secureRegistration": {
                    template: require('pages/secure-registration/secureRegister.step1.html'),
                    controller: "pages.secureRegistration.secureRegister.step1"
                }
            }
        },
        {
            state: "secureRegistration.step1",
            url: "^/secure-registration/step1/:userId/?token",
            params: { token: '', showMessage: '' },
            views: {
                "content@secureRegistration": {
                    template: require('pages/secure-registration/secureRegister.step1.html'),
                    controller: "pages.secureRegistration.secureRegister.step1"
                }
            }
        },
        {
            state: "secureRegistration.step2",
            url: "^/secure-registration/step2",
            params: { userId: '',  token: '' },
            views: {
                "content@secureRegistration": {
                    template: require('pages/secure-registration/secureRegister.step2.html'),
                    controller: "pages.secureRegistration.secureRegister.step2"
                }
            }
        },
        {
            state: "secureRegistration.step3",
            url: "^/secure-registration/step3",
            params: { userId: '',  token: '', loginId: '' },
            views: {
                "content@secureRegistration": {
                    template: require('pages/secure-registration/secureRegister.step3.html'),
                    controller: "pages.secureRegistration.secureRegister.step3"
                }
            }
        },
        {
            state: "secureRegistration.error",
            url: "^/secure-registration/error/:errorCode",
            params : { status: '', statusText: '' },
            views: {
                "content@secureRegistration": {
                    template: require('pages/secure-registration/secureRegister.error.html'),
                    controller: "pages.secureRegistration.secureRegister.error"
                }
            }
        }
    ];
};
