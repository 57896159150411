module.exports = function(bootstrap) {
    bootstrap.run(['Service', function(Service) {
        new Service(
            {
                name: 'visitedMessageCenter',
                group: 'rbsmbl',
                cache: false,
                url: '/rbsmbl/x-services/messagecenteractivity/insert',

                fetch: function(userId) {
                    return this.post({ bvUserId: userId });
                }
            }
        );
    }]);
};