module.exports = function (app) {
    require("./dispute/dispute")(app);

    app.controller("pages.dispute", ["$scope", function ($scope) {
        $scope.heading = 'DISPUTE AND APPEAL PROCESS';
        $scope.backText = "Back";
        $scope.backAction = function(){
            window.location = "/home/";  
        }
    }]);

    return [
        {
            state: "dispute",
            url: "/disputes",
            postPassword: false,
            views: {
                "body@": {
                    template: require("./dispute/dispute.html"),
                    controller: "pages.dispute"
                },
                "content@dispute": {
                    template: require("./dispute/dispute.html"),
                    controller: "pages.dispute.dispute"
                }
            }
        }
    ];
};