module.exports = function(app) {
    app.filter('fromyyyyMMdd', function() {

        return function(yyyyMMdd) {
            if (typeof yyyyMMdd !== 'string' || yyyyMMdd.length !== 8) {
                return '';
            }

            var
                yyyy = yyyyMMdd.slice(0, 4),
                MM = yyyyMMdd.slice(4, 6),
                dd = yyyyMMdd.slice(6, 8);

            return MM + '/' + dd + '/' + yyyy;
        };
    });
};