module.exports = ['$scope', '$http', '$cookies','coremetrics', function($scope, $http, $cookies, coremetrics) {
    $scope.testclick = function() {
		alert('worked');
	}
    //Used only for forgotten password success modals
    $scope.redirectHome = function(){
        coremetrics.startConversion('Your Username is on its Way','Splash Page FORGOT USERNAME OR PASSWORD','FORGOT USERNAME OR PASSWORD');
        location.replace("/home/");
    }
    $scope.phoneNumber = "1-877-298-3918";
    $scope.getPhoneNumber = function(){
        var brand = $scope.brandData.sharedBrandCode.toLowerCase();
        if(brand == "mincr") {
            $scope.phoneNumber = "1-866-582-1166";
        }
    }
    $scope.runPhoneNumber = $scope.getPhoneNumber();
}];
