module.exports = function (bootstrap) {
    bootstrap.run(['Service', '$cookies', 'brand', function(Service, $cookies, brand){
        new Service({
            name: 'demographicsInfo',
            group: 'rbsmbl',
            originalUrl: '/rbsmbl/x-services/demographics/info',
            url: '/rbsmbl/x-services/demographics/info',

            getDemographicInfo: function() {
                this.url = this.originalUrl + '?clientId=' + $cookies.get('mbrClientNumber') + '&groupId=' + $cookies.get('mbrGroupNumber');

                return this.get();
            },

            saveDemographicInfo: function(data) {
                if (brand.current.umsBrandCode) {
                    var currentBrand = brand.current.umsBrandCode;
                }
                else {
                    var currentBrand = brand.current.sharedBrandCode;
                }
                var appId = "CHMMBR";
                this['headers'] = {"APPID": appId, "BRAND": currentBrand};


                return this.post({
                    'payload': data
                });
            }
        });

    }]);
};