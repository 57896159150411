
module.exports = function(app) {
    app.directive('wellnessNeedSomeHelp', function() {

        return {
            restrict: 'A',
            replace: true,
            template: require('directives/wellnessNeedSomeHelp.html'),
            controller: ['$scope','coremetrics','services','sessionData',
                function($scope,coremetrics, services,sessionData) {


                    var customContentIDs = {
                        "MBR_WELLNESS_CUSTOM_PHONE_NUMBER_IDH": "MBR_WELLNESS_CUSTOM_PHONE_NUMBER_IDH"

                    };
                    $scope.phoneNumberCoremetrics = function(){
                        coremetrics.elementTag('WebMD Phone Number', 'Member: Wellness');
                    };

                    $scope.coachLinkCoremetrics = function(){
                        coremetrics.elementTag('How coaches can help', 'Member: Wellness');
                    };


                    $scope.miniClass = ($scope.isMINCR ? 'miniHealthCoach' : '');

                    var brand = sessionData && sessionData.memberInfo && sessionData.memberInfo.brand;
                    services.rbsmbl.contentInfo().getContent(customContentIDs, {}).then(function (response) {
                        $scope.customClientRes = JSON.parse(response.data.payload.contentResponse[0].value);
                    }, function (error) {
                        console.log(error);
                        });
                }


            ]
        };
    });
};