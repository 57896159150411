module.exports = function(app) {
    app.directive('findTheBest', ["session", function(session) {

        return {
            restrict: 'A',
            replace: true,
            controller : ['$scope', function ($scope) {

				$scope.sp = "-_-FIND%20A%20DOCTOR-_-HOME";
            }],
            template: require('directives/preFindDoctor/findTheBest.html')
        };
    }]);
};
