module.exports = function(app) {
    app.service('wellnessLogic', [ '$q', 'services',
        function($q, services) {

            this.getWellnessScore = function() {
                var theScore = services.rbsmbl.wellnessProfileScore().fetch();
                return theScore;

            }

        }
    ]
    )
}