module.exports = function(bootstrap) {
    bootstrap.run(['Service', function (Service) {

        new Service({
            name: 'countyList',
            group: 'cmcrst',
            url: '/cmcrst/x-services/zipCode/countyList',

            fetch: function(zipCode) {
                return this.post({
                    payload: {
                        zipCode: zipCode || '00000'
                    }
                });
            }
        });
    }]);
};
