module.exports = function(app) {

    app.factory('requestAPrintedCopyService', ['services',
        function(services) {

           var requestCopy;

           requestCopy = function(data) {
                return new Promise(function(resolve) {
                      var requestPrintedCopyPromise = services.rbsmbl.requestPrintedCopy().fetch(data);
                        var getResult = requestPrintedCopyPromise.then(
                            function(response) {
                                if (response.data === true) {
                                    resolve('success');
                                } else
                                {
                                    resolve('error');
                                }
                            },
                            function(err) {
                                resolve(err);
                            }
                        );
                });
           }
           return {
              requestCopy: requestCopy
              };
           }
    ]);
};
