module.exports = function(app) {
    app.directive('adamIframe',
        function($window,$location) {

            return {
                restrict: 'A',
                link: function($scope, element, attrs) {
                    element.css('visibility','hidden');
                    $scope.directoryMappings={
                        "28":"careguides",
                        "102":"imagepages",
                        "101":"",
                        "118":"presentations",
                        "10":"patientguide",
                        "14":"preg",
                        "13":"sp"
                    };
                    $scope.error=false;

                    element.on('load', function(){
                        try {
                              element.css('visibility', 'hidden');
                              $scope.modifyLinks();
                              $scope.modifySelects();
                              $scope.includeStyles();
                              /*if(!$scope.error) {
                                  $scope.checkError();
                              }else{
                                  $scope.error=false;
                              }
                              if(!$scope.error){
                                  element.css('visibility', 'visible');
                              }*/
                              $scope.optimizeDocument();
                            element.css('visibility', 'visible');
                        }catch(exception){
                            console.log(exception);
                            element.css('visibility', 'visible');
                        }
                    });

                    $scope.checkError=function(){
                        var boldText=element.contents().find("b")[0];
                        var src=element[0].src;
                        var fileExtension=src.substring(src.lastIndexOf('.')+1,src.length);
                        if(boldText && fileExtension==='htm' && boldText.innerHTML.indexOf('You have encountered an error. Please check back again soon. We apologize for any')>=0){
                            $scope.error=true;
                            element.attr('src',src+'l');
                        }
                    };

                    $scope.modifyLinks=function(){
                        var links=element.contents().find("a");
                        for(var i=0;i<links.length;i++) {
                            var tempHref=links[i].href;
                            // if links contains javascript:getURL('?BV_UseBVCookie=Yes&id=
                            if(tempHref.indexOf("javascript:getURL('?BV_UseBVCookie=Yes&id=") >= 0){
                                if(tempHref.indexOf('bodyguide/bodyGuide.html') >=0 ){
                                    links[i].href='frameParent.html';
                                }else {
                                    tempHref = tempHref.substring(tempHref.lastIndexOf("=") + 1, tempHref.length - 1)
                                    var folder=tempHref.substring(0,tempHref.indexOf('/'));
                                    //javascript:getURL('?BV_UseBVCookie=Yes&id=imagepages/8856.html')
                                    if (tempHref.indexOf("/") >= 0 && attrs.ngSrc.indexOf(folder)>=0 ) {
                                        tempHref = tempHref.substring(tempHref.indexOf("/")+1, tempHref.length - 1);
                                        //links[i].href = tempHref;
                                        links[i].href = $scope.modifyExtension(tempHref);
                                    }else{//javascript:getURL('?BV_UseBVCookie=Yes&id=surgidxa.html')
                                        tempHref = tempHref.substring(0, tempHref.length - 1);
                                        //links[i].href = tempHref;
                                        links[i].href = $scope.modifyExtension(tempHref);
                                    }
                                    $scope.attachClickHandler(links[i]);
                                }
                            }else if(tempHref.indexOf("javascript:goPage") >= 0 ){ //javascript:goPage('sp/200000.html')
                                tempHref = tempHref.substring(tempHref.indexOf('/')+1, tempHref.length - 2);
                                //links[i].href = tempHref;
                                links[i].href = $scope.modifyExtension(tempHref);
                                $scope.attachClickHandler(links[i]);
                            }else if(tempHref.indexOf("javascript:getUrlReference") >= 0){ //javascript:getUrlReference('10','000059.html')
                                var id=tempHref.substring(tempHref.indexOf('(')+2,tempHref.indexOf(',')-1);
                                var fileName=tempHref.substring(tempHref.indexOf(',')+2,tempHref.length-2);
                                if(attrs.ngSrc.indexOf($scope.directoryMappings[id])>=0 && $scope.directoryMappings[id]!="") {
                                    //links[i].href =  fileName;
                                    links[i].href = $scope.modifyExtension(fileName);
                                }else{
                                    var newHref=attrs.ngSrc.substring(0,attrs.ngSrc.lastIndexOf("/"));
                                    newHref=newHref.substring(0,newHref.lastIndexOf("/"));
                                    //links[i].href = newHref+$scope.directoryMappings[id] + "/" + fileName;
                                    links[i].href = newHref+$scope.directoryMappings[id] + "/" + $scope.modifyExtension(fileName);
                                }

                                $scope.attachClickHandler(links[i]);
                            }else if(tempHref.indexOf("javascript:history.back()")>=0){
                                links[i].href="";
                                links[i].innerHTML="";
                            }

                           if(links[i].href.toString().indexOf("javascript:Nav")>=0){
                                $scope.attachClickHandler(links[i]);

                            }

                        }
                    };

                    $scope.modifyExtension=function(value){
                        return value.replace(/\.[^/.]+$/,'.htm');
                    };

                    $scope.attachClickHandler=function(link){
                        angular.element(link).on("click",function(event){
                            /*event.preventDefault();
                            console.log('preventDefault');*/
                            element.css('visibility', 'hidden');
                            element.attr('src',this.href);
                        });
                    };

                    $scope.modifySelects=function(){
                        var selects=element.contents().find('select');
                        for(var i=0;i<selects.length;i++){
                            // making sure that only select option that have getMenuUrl function get modified
                            if(selects[i].onchange.toString().indexOf('getMenuURL') >= 0){
                                selects[i].onchange="";
                                angular.element(selects[i]).on('change',function(event) {
                                    var path=attrs.ngSrc;
                                    var s = path.substring(0, path.lastIndexOf("/"))+"/"+this.value;
                                    element.css('visibility', 'hidden');
                                    element.attr('src',s);
                                });
                            }
                        }
                    };

                    $scope.includeStyles=function(){
                        element.contents().find('head').append('<link rel="stylesheet" href="/redesign/css/adam.css" type="text/css" />');
                        element.contents().find('head').append('<link rel="stylesheet" href="/redesign/css/global.css" type="text/css" />');
                        element.contents().find('head').append('<link rel="stylesheet" href="/login/resources/styles/adam_global_override.css" type="text/css" />');
                    };

                    $scope.optimizeDocument=function(){
                            if(element[0].contentWindow && element[0].contentWindow.document && element[0].contentWindow.document.body){
                                var clientHeight=(element[0].contentWindow.document.body.clientHeight);
                                var scrollHeight=(element[0].contentWindow.document.body.scrollHeight);
                                var paddingHeight=30; //padding=20px + 10px space

                                if(clientHeight==scrollHeight){
                                    element[0].style.height='470px'; //reset height
                                    clientHeight=(element[0].contentWindow.document.body.clientHeight);
                                    scrollHeight=(element[0].contentWindow.document.body.scrollHeight);
                                }
                                //adjusts if pageHeight is less than needed
                                if(clientHeight<scrollHeight){
                                    element[0].style.height=(scrollHeight+paddingHeight)+'px'; //scrollHeight+padding
                                    if(element[0].src.indexOf("/sp/")>0){
                                        element[0].style.height=(scrollHeight+paddingHeight+50)+'px';//scrollHeight+padding+extra image height
                                    }
                                }

                                //console.log("adjusted iframe height",element[0].style.height);
                            }
                    };
                }
            };
        }
    );
};