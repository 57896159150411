module.exports = function(bootstrap) {
    bootstrap.directive('progressMeter2', ['$rootScope', function($rootScope) {

        return {
            restrict: 'A',
            replace: true,

            scope: {
                name: '=?',
                pin: '=?',
                max: '=',
                value: '=',
                memberIndex: '=?',
                typeCode: '=?',
                isMainProgressMeter: '=?'
            },

            template:   '<article class="casProgressMeter">' +
            '<h6 data-ng-if = "!isMainProgressMeter" class="pin">{{getMembershipRelationshipType(pin)}}</h6>' +
            '<h6 class="name" data-ng-if="name">{{name}}</h6>' +
            '<div class="meter" data-ng-class="isBreakpointCalculation">' +
            '<div class="max">' +
            '<div class="progressMeterValue current"></div>' +
            '<div class="progressMeterValue low"></div>' +
            '<div class="progressMeterValue high"></div>' +
            '<p class="valueText original" data-ng-show="showOriginalAccumValue" data-ng-attr-alt="Current Accumulation Total: {{value | currency}}" data-ng-attr-title="Current Accumulation Total: {{value | currency}}">({{value | currency: "$"}})&nbsp;</p>' +
            '<p class="valueText left" data-ng-attr-alt="{{minValueAltDescription}}" data-ng-attr-title="{{minValueAltDescription}}">{{updatedAccumValueMin | currency: "$"}}</p>' +
            '<p class="valueText divider" data-ng-show="updatedAccumValueMax">&nbsp;-&nbsp;</p>' +
            '<p class="valueText right" data-ng-show="updatedAccumValueMax">{{updatedAccumValueMax | currency: "$"}}</p>' +
            '<p class="maxText" data-ng-attr-alt="Maximum Accumulation Amount: ${{max | currency}}" data-ng-attr-title="Maximum Accumulation Amount: ${{max}}">{{max | currency: "$": 0}}</p>' +
            '</div>' +
            '</div>' +
            '</article>',

            link: function(scope, element) {
                scope.isBreakpointCalculation = scope.typeCode === 'BKP' ? 'bkp' : 'non-bkp';
                scope.getMembershipRelationshipType = function (pin) {
                    if (pin === "001") {
                        return "Subscriber";
                    } else if (pin === undefined) {
                        return "";
                    }else {
                        return "Dependent";
                    }
                };
                var ANIMATION_DELAY = 500,
                    accumBarConfig = {
                        initializeMeter: true,
                        allowAnimation: false,
                        selectedMember: undefined,
                        accumValueMin: undefined,
                        accumValueMax: undefined,
                        targetClass: element.find('.meter')
                    };

                function drawAccumBar(){

                    scope.showOriginalAccumValue = false;

                    accumBarConfig.accumValueMin = accumBarConfig.accumValueMin || 0;
                    accumBarConfig.accumValueMax = accumBarConfig.accumValueMax || 0;
                    accumBarConfig.selectedMember = accumBarConfig.selectedMember || null;

                    var valueWidthMin = setBarPercentWidth(accumBarConfig.accumValueMin),
                        valueWidthMax = setBarPercentWidth(accumBarConfig.accumValueMax);

                    //if min is equal to max, OR if bar is for an individual but not the member we're looking to update, only update min
                    if(valueWidthMin === valueWidthMax ||
                        (Number(scope.memberIndex) !== Number(accumBarConfig.selectedMember) && !memberIndexUndefined())){
                        scope.updatedAccumValueMin = correctOutOfBoundsValue(Number(scope.value));
                        scope.updatedAccumValueMax = null;
                    }
                    //else, if min and max are not equal, update both
                    else if(valueWidthMin !== valueWidthMax || memberIndexUndefined()){
                        scope.updatedAccumValueMin = Number(scope.value);
                        scope.updatedAccumValueMax = Number(scope.value);
                    }

                    if(accumBarConfig.initializeMeter){
                        if(accumBarConfig.allowAnimation){
                            element.find('.progressMeterValue').animate({ width: valueWidthMin + '%' }, ANIMATION_DELAY);
                        }
                        else{
                            //element.find('.progressMeterValue').css({ width: valueWidthMin + '%' }).addClass('animatePlanProgress');
                            element.find('.progressMeterValue').addClass('animatePlanProgress');
                            element.find('.max').css({
                                'width': valueWidthMin + '%'
                            });
                            jQuery('.expandPlanProgress').mouseover(function() {
                                jQuery('.slidedown .progressMeterValue').removeClass('animatePlanProgress');
                            });
                            jQuery('.expandPlanProgress').click(function() {
                                jQuery('.slidedown .progressMeterValue').addClass('animatePlanProgress');
                            });
                        }
                    }
                    else{
                        if(memberIndexUndefined() || (!memberIndexUndefined() && Number(scope.memberIndex) === Number(accumBarConfig.selectedMember))){
                            accumBarConfig.targetClass.find('.progressMeterValue.low').animate({ width: valueWidthMin + '%' }, ANIMATION_DELAY);
                            accumBarConfig.targetClass.find('.progressMeterValue.high').animate({ width: valueWidthMax + '%' }, ANIMATION_DELAY);
                            scope.showOriginalAccumValue = true;

                            scope.updatedAccumValueMin = setUpdatedAccumValue(accumBarConfig.accumValueMin);
                            if(scope.updatedAccumValueMax !== null){
                                scope.updatedAccumValueMax = setUpdatedAccumValue(accumBarConfig.accumValueMax);
                            }
                        }
                    }
                    scope.minValueAltDescription = scope.showOriginalAccumValue ? "Estimated Accumulation Total if procedure is performed by selected provider: $" + scope.updatedAccumValueMin  : "Current Accumulation Total: $" + scope.value;
                }

                function memberIndexUndefined(){
                    return scope.memberIndex === undefined;
                }

                function correctOutOfBoundsAccums(value){
                    if(value > 0 && value < 3) {
                        return 3;
                    }
                    if(value > 100) {
                        return 100;
                    }
                    return value;
                }

                function correctOutOfBoundsValue(val){
                    return Number(scope.value) > Number(scope.max) ? Number(scope.max) : Number(scope.value);
                }

                function setBarPercentWidth(accumValue){
                    return correctOutOfBoundsAccums((((Number(scope.value) + Number(accumValue)) / Number(scope.max)) * 100));
                }

                function setUpdatedAccumValue(accumValue){
                    return Number(scope.value) + Number(accumValue) > scope.max ?
                        scope.max : Number(scope.value) + Number(accumValue);
                }

                drawAccumBar();

                $rootScope.$on('updatePlanProgressAccums', function(event, progressObj, selectedMember){
                    accumBarConfig = {
                        initializeMeter: false,
                        allowAnimation: true,
                        selectedMember: selectedMember
                    };
                    switch(scope.typeCode){
                        case 'BKP':
                            accumBarConfig.accumValueMin = progressObj.breakpointMin;
                            accumBarConfig.accumValueMax = progressObj.breakpointMax;
                            accumBarConfig.targetClass = element.find('.meter.bkp');
                            break;
                        default:
                            accumBarConfig.accumValueMin = progressObj.nonBreakpointMin;
                            accumBarConfig.accumValueMax = progressObj.nonBreakpointMax;
                            accumBarConfig.targetClass = element.find('.meter.non-bkp');
                    }
                    drawAccumBar();
                });

                $rootScope.$on('recompilePlanProgressMeter', function(evt, animation){
                    accumBarConfig = {
                        initializeMeter: true,
                        allowAnimation: animation || false,
                        selectedMember: undefined,
                        accumValueMin: undefined,
                        accumValueMax: undefined,
                        targetClass: element.find('.meter')
                    };
                    drawAccumBar();
                });
            }
        };
    }]);
};