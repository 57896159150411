module.exports = function(app) {
    app.directive('slidedownInternalCloseButton', ['$timeout',
        function($timeout) {

            return {
                restrict: 'A',
                template: '<button class="openCloseSection" data-ng-click="collapseSlidedown()">Hide Details<span class="dblArrowUp"></span></button>',
                scope: {
                    toggleBoolean: "="
                },
                link: function(scope, el, attrs){
                    scope.collapseSlidedown = function(){
                        $timeout(function(){
                            var target = el.closest('.slidedown-wrapper').find('.toggle-slidedown');
                            target[0].focus();
                            target.trigger('click');
                            scope.toggleBoolean = false;
                            scope.$apply();
                        });

                    };
                }
            };
        }
    ]);
};