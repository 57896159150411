module.exports = function(app) {
    app.controller("pages.formsLibrary.aemForms", ["$scope", "navigation", "$location", "$http", "$sce",  function($scope, navigation, $location, $http, $sce) {
        
                var term = window.location.origin;

                var aemURL = term + '/' +'content/forms/af/highmark/en/highmarkbcbs/claim-forms/zostavax-prescription-drug-claim-form.html';
               // {
               //      url  : aemURL,
               //      type : "GET",
               //      data : {
               //          // Set the wcmmode to be disabled
               //          wcmmode : "disabled"
               //      }
               //      // Set the data reference, if any
               //          // "dataRef": options.dataRef,
               //          // Specify a different theme for the form object
               //          // "themeOverride" : options.themepath
               //  }
               // test
                $http({
                    url  : aemURL,
                    method : "GET",
                    data : {
                        // Set the wcmmode to be disabled
                        wcmmode : "disabled"
                    }
                    }).then(function (response) {
                     $scope.page = $sce.trustAsHtml(response);
                   
                }, function (error) {
                    $scope.page = error;
                });

    }]);
};