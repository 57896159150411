module.exports = function(app) {
    app.directive('specilityContent', function() {

        return {
            restrict: 'A',
            replace: true,
            template: '<span><h3>{{ specilityName }}</h3><p data-teamsite="{{teamSiteContent}}"></p></span>',
            scope: {
                specilityContent: '=specilityContent'
            },

            controller: ['$scope',
                function($scope) {
                    $scope.specilityName=$scope.specilityContent;
                    $scope.teamSiteHead= $scope.specilityName.replace(/\s/g, '_').toLocaleUpperCase();
                    $scope.teamSiteContent= "CCOE_SPECIALITY_"+$scope.teamSiteHead;
                }
            ]
        };
    });

    app.directive('drawLine', function () {

        return {
            restrict: 'A',
            replace: true,
            template: '<hr data-ng-if="createBorder">',
            scope: {
                drawLine: '=drawLine',   //index (always starts with zero)
                drawLine1: '=drawLine1'  // length
            },
            controller: ['$scope',
                function($scope) {
                    // making index 1 rather than 0. To make the logic easier.
                    $scope.drawLine= $scope.drawLine +1;
                    if($scope.drawLine1 > $scope.drawLine ) {
                        $scope.createBorder = true;
                    } else  {
                        $scope.createBorder = false;
                    }


                }
            ]
        };

    });
}
