module.exports = function (app) {
	app.service('passwordSecurityService', ['services','teamsite', 'brand', 'sessionData', '$q', function (services, teamsite, brand, sessionData, $q) {
		var _brand = (brand
			&& brand.current
			&& brand.current.sharedBrandCode
				? brand.current.sharedBrandCode : '');

		var _username = (sessionData
			&& sessionData.memberInfo
			&& sessionData.memberInfo.userName
				? sessionData.memberInfo.userName : '');

        this.updatePassword = function (oldPassword, newPassword, verifiedPassword) {
            var deferred = $q.defer();

            if (newPassword === verifiedPassword) {
            	var payload = {
    				"payload": {
                        "loginRequestInstance": {
                            "brand": _brand,
                            "username": _username,
                            "oldPassword": oldPassword,
                            "newPassword": verifiedPassword
                        }
                    }
                };

                services.rbsmbl.updateUserProfile().update(payload).then(function (response) {
                    var status = (response
                        && response.data
                        && response.data.payload
                        && response.data.payload.status
                            ? response.data.payload.status : '');

                    deferred.resolve(status);
                }, function (error) {
                    deferred.reject(error);
                });
            } else {
                deferred.reject();
            }

			return deferred.promise;
		};

        this.getCurrentSecurityQuestion = function ()  {
            var deferred = $q.defer();

            services.rbsmbl.volatileMemberInfo().fetch().then(function (response) {
                var securityQuestion = (response
                    && response.data
                    && response.data.payload
                    && response.data.payload.securityQuestion
                        ? response.data.payload.securityQuestion
                        : '');

                deferred.resolve(securityQuestion);
            }, function (error) {
                deferred.reject(error);
            });

            return deferred.promise;
        };

        this.updateSecurityQuestions = function (question, answer) {
            var deferred = $q.defer();

            var payload = {
                "payload": {
                    "loginRequestInstance": {
                        "brand": _brand,
                        "username": _username,
                        "newSecurityQuestion": question,
                        "newSecurityAnswer": answer
                    }
                }
            };

            services.rbsmbl.updateUserProfile().update(payload).then(function (response) {
               var responsePayload = (response
                    && response
                    && response.data
                    && response.data.payload
                        ? response.data.payload
                        : '');

               deferred.resolve(responsePayload)
            }, function (error) {
                deferred.reject(error);
            });

            return deferred.promise; 
        };

        this.getAllSecurityQuestions = function () {
            var deferred = $q.defer();
            var _SECURITY_QUESTIONS_CONTENT_ID = 'MBR_SECURITY_QUESTIONS';

            teamsite.get(_SECURITY_QUESTIONS_CONTENT_ID).then(function (response) {
                try {
                    var content = JSON.parse(response);
                    deferred.resolve(content.questions);
                } catch (e) {
                    deferred.reject (e);
                }
            });

            return deferred.promise;
        };
	}]);
};