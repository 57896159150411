/**
* @file Display rules for Rx Cost Comparison cost saving tool.
* @author J.T. Gralka <James.Gralka@Highmark.com>
*/

module.exports = function (app) {
	app.service('rxccService', ["displayOrHideItemsWrapper", "services", "sessionData", "vendorSSOUtility", "$q", function (displayOrHideItemsWrapper, services, sessionData, vendorSSOUtility, $q) {
		function init() {
			var deferred = $q.defer();
			var res = {
				shouldDisplay: false,
				clientNumber: "",
				vendorName: ""
			}

			// This function checks AppConfig keys in spending.
			function checkRxCCAppConfigKey(key) {
				return sessionData && sessionData.appConfig
					&& sessionData.appConfig.SPENDING
					&& sessionData.appConfig.SPENDING[key]
					&& sessionData.appConfig.SPENDING[key].booleanValue;
			}

			function getClientNumber(groupNumber) {
				var clientNumber = false;

				// Get the clientList if it exists on sessionData.
				var clientList = [];
				if (sessionData
					&& sessionData.memberInfo
					&& sessionData.memberInfo.clientList) {
						clientList = sessionData.memberInfo.clientList;
				}

				clientList.forEach(function (client) {
					var groupList = client && client.groupList ? client.groupList : [];
					var currentClientNumber = client.clientNumber;

					groupList.forEach(function (group) {
						if (group.groupNumber === groupNumber) {
							return clientNumber = currentClientNumber;
						}
					});

					if (clientNumber) {
						return;
					}
				});

				return clientNumber;
			};

			// If the user is logged in.
			if (sessionData.loggedIn) {
				var selectedPlan = services.rbsmbl.memberInfo().getPortalSelectedPlan();
				var brand = sessionData.memberInfo.brand || "";
				var seniorIndicator = sessionData.isSeniorProductIndicator;
				var groupNumber = selectedPlan.groupNumber || "";
				var clientNumber = getClientNumber(groupNumber) || "";
				var hasDrugCoverage = false;
				res.clientNumber = clientNumber;

				if (((brand === "DE" && seniorIndicator === "false")
					|| (brand === "MS" && seniorIndicator === "false")
					|| brand === "CRE" || brand === "PBS"
					|| brand === "HMBCBS" || brand === "MINCR")) {

					// A default client should have drug coverage on at least one product.
					selectedPlan.productList.forEach(function (product) {
						if (product.drugCoverage === "true") {
							hasDrugCoverage = true;
							res.shouldDisplay = true;
							res.vendorName = selectedPlan.pharmacyBenefitProviderIndicator;
							deferred.resolve(res);
						}
					});
				}

				// A user without drugCoverage might have an Rx carve out vendor.
				if(!hasDrugCoverage) {
					var rxCarveOutVendorPromise = displayOrHideItemsWrapper.displayOrHideItemsCall(clientNumber, groupNumber, ['rxCarveOutVendor'], sessionData.memberInfo.brand);

					rxCarveOutVendorPromise.then(function (response) {
						if (response.qualifierInfo && response.qualifierInfo.rxCarveOutVendor) {
							res.shouldDisplay = true;
							res.vendorName = response.qualifierInfo.rxCarveOutVendor;
						}
						deferred.resolve(res);
					}, function (error) {
						deferred.resolve(res);
					});
				}

			} else { // Else if the user is logged out, don't display the tool.
					deferred.resolve(res);
			}

			return deferred.promise;
		};


		/**
		* Determines whether or not to show the Rx Cost Comparison tool.
		* @returns {Promise} the promise of running the display logic for Rx Cost comparison.
		*/
		this.shouldDisplay = function () {
			var deferred = $q.defer();

			init().then(function (response){
				deferred.resolve(response.shouldDisplay);
			}, function (error) {
				deferred.reject(error);
			});

			return deferred.promise;
		};

		/**
		* Performs the pharmacy SSO request.
		* @throws Will throw an error if plan information or display logic doesn't resolve.
		*/
		this.doSSORequest = function () {
			init().then(function (response) {
				if (response.shouldDisplay) {
					var selectedPlan = services.rbsmbl.memberInfo().getPortalSelectedPlan();
					var userData = services.rbsmbl.memberInfo().currentMember(sessionData.memberInfo);

					var sitePromotionStr = "";

					// TODO: No Coremetrics site promo for Carve Outs?
					if (sessionData.memberInfo.brand == 'MINCR') {
						sitePromotionStr = '?cm_sp=Prime-_-Prescription%20Services%20Prime-_-Cost&%20Savings';
					} else {
						sitePromotionStr = '?cm_sp=Medco-_-Prescription%20Services%20Medco-_-Cost%20&%20Savings';
					}

					var ssoParams = {
						"APPID": 'CHMHOM',
						"brand": sessionData.memberInfo.brand,
						"umi": sessionData.memberInfo.umi,
						"memberUserName": sessionData.basicInfo.loginId,
						"memberFirstName": userData.contact.firstName,
						"memberLastName": userData.contact.lastName,
						"memberBirthDate": userData.contact.birthDate,
						"memberGender": userData.gender,
						"relationshipCode": userData.relationshipCode,
						"directPayIndicator": sessionData.directPayIndicator,
						"theTargetType": 'PRESCRIPTION_COSTS_AND_SAVINGS',
						"memberRelationship": userData.relationship,
						"clientNumber": response.clientNumber,
						"vendorName": response.vendorName
					};

					var ssoWindowName = ('sso_pharmacy' + ssoParams.vendorName).toLowerCase();

					vendorSSOUtility.performSSO(ssoParams, ssoWindowName, sitePromotionStr, true, true);
				}
			}, function (error) {
				throw new Error(error);
			});
		};
	}]);
};
