module.exports = function(app) {
    var YMCAController = require("../../modals/ymcaModal");
    var RetrofitController = require("../../modals/retrofitModal");
    app.controller('pages.wellness.dppDetails', ['$scope', '$state','teamsite','$stateParams','modal','services','coremetrics','sessionData',
        function ($scope, $state, teamsite, $stateParams, modal, services, coremetrics,sessionData) {

            $scope.dPPbrandVisibility = false;

            // date logic for DPP - HM
            $scope.provisionDate = function(startDate){
                 var today = new Date();
                 if (today < new Date(startDate)){
                 $scope.provisionStartDate = true; //get help on start date
                 } else {
                 $scope.provisionStartDate = false; // we help
                 }
            };

            $scope.dppIndicator = '';

            coremetrics.pageviewTag('DIABETES PREVENTION PROGRAM', 'MEMBER:WELLNESS:DIABETES PREVENTION PROGRAM');

            $scope.backAction = function () {
                if($state.params.wellnessOverviewModule == true){
                    $state.go('wellness');
                }else{
                    location.replace("/mbr/#!/landing");
                }

            };

            // Diabetes Prevention Check

              services.rbsmbl.eligibility().getData().then(function (objSuccessResponse){
                $scope.dppIndicator = objSuccessResponse.data.payload.dppIndicator;

                var brandName = sessionData.memberInfo.brand;
                  if( $scope.dppIndicator === "MA"){
                      if (brandName !== undefined && brandName.toLowerCase() === ("MINCR").toLowerCase()) {
                          $scope.dPPbrandVisibility = true;
                      }
                  } else if (($scope.dppIndicator === "C")){
                        $scope.provisionDate(objSuccessResponse.data.payload.dppStartDate);
                        $scope.dppStartDate = objSuccessResponse.data.payload.dppStartDate;
                        $scope.dPPbrandVisibility = false;
                        teamsite.setQualifier('dppIndicator', $scope.dppIndicator);
                        $scope.headings = "DIABETES PREVENTION PROGRAM";
                        $scope.backText = "Back";
                  } else{
                    $state.go("wellness");
                }
              }, function (objErrorResponse) {

              });

            $scope.visitYMCA = function(){
                coremetrics.elementTag('YMCA LINK ', 'MEMBER:WELLNESS:DIABETES PREVENTION PROGRAM');
                modal.open({
                               template: require('modals/ymcaModal.html'),
                               controller:  YMCAController,
                               scope: $scope
                           });
            };

            //Vendor replaced from Retrofit to  Livongo
            $scope.visitLivongo = function(){
                coremetrics.elementTag('LIVONGO LINK ', 'MEMBER:WELLNESS:DIABETES PREVENTION PROGRAM');
                modal.open({
                               template: require('modals/retrofitModal.html'),
                               controller:  RetrofitController,
                               scope: $scope
                           });
            }
        }
    ]);
};

