module.exports = function(app) {
    // TODO: messageService needs to be completed.
    app.factory('messageCenterService', ['services', '$filter', 'session',
            function(services, $filter, session) {
                var
                    _parseMessages,
                    getMessages,
                    getCategories,
                    _formatForRequestObject,
                    deleteMessages,
                    setStatuses,
                    setStatus;


                _parseMessages = function(messages) {
                    var assignMessageVars = function(message) {
                        message.subject = message.subject || '';
                        message.messageDate = $filter('date')(new Date(message.createTimestamp), 'MM/dd/yy');
                        message.messageTime = $filter('date')(new Date(message.createTimestamp), 'hh:mm a');
                        message.messageTimestamp = Date.parse(message.createTimestamp)
                        message.messageAllText = '';
                        message.custProp = message.communicationActionCode == "U" ? "font-bold" : "";
                        message.fromAddress = message.direction == "OUTBOUND" ? "Customer Service" : message.memberFirstName + ' ' + message.memberLastName;
                        message.displayCustProp = message.messageStatus == "D" ? "display-none" : "";

                        for (var iMsgIdx = 0; iMsgIdx < message.messageList.length; ++iMsgIdx) {
                            message.messageAllText += (message.messageAllText === '' ? '' : '<br />') + message.messageList[iMsgIdx].messageText;
                        }
                        if (message.messageAllText.length > 35) {
                            message.messagePreview = message.messageAllText.substring(0, message.messageAllText.lastIndexOf(' ', 35)) + ' ...';
                        }
                        else
                            message.messagePreview = message.messageAllText;


                        message.bIsTruncated = message.messageAllText.length > 500;
                        if (message.bIsTruncated) {
                            message.messageContent = message.messageAllText.substring(0, 500) + '. . .';
                        }
                        else
                            message.messageContent = message.messageAllText;

                        };
                    messages.forEach(function(message) {
                       assignMessageVars(message);
                    })
                    return messages;
                }


                // $scope.HSASpendBy = $scope.accountsArray[j].beginDate+"T00:00:00";  in js
                getMessages = function (startDate, endDate) {
                    var getUnreadMessagesPromise = services.rbsmbl.countUnreadMessages().fetch(session.data.memberInfo)
                        return getUnreadMessagesPromise.then(
                            function(response) {
                                var numNewMessages = response && response.data && response.data.payload &&
                                    response.data.payload[0] && response.data.payload[0].unreadMessagesCount || 0;

                                var getMessagesPromise = services.rbsmbl.getMsgCenterMessages().fetch(startDate, endDate);
                                return getMessagesPromise.then(
                                    function (msg) {
                                        var messages = msg.data.payload;
                                        var messageArray = [];
                                        messages.forEach(function (message) {
                                            if (message.messageStatus != "D") {
                                                messageArray.push(message)
                                            }
                                        });
                                        messageList = _parseMessages(messageArray);
                                        return {
                                            messageList: messageList,
                                            unreadMessages: numNewMessages
                                        }
                                    },
                                    function (err) {
                                        console.error('rbsmbl.getMessages.fetch failed with error:', err);
                                        return getMessagesPromise;
                                    }
                                );
                            },
                            function(err) {
                                console.error('rbsmbl.countUnreadMessages.fetch failed with error:', err);
                                return getUnreadMessagesPromise;
                            }
                        );
                };

                _formatForRequestObject = function(process) {
                    var processStatus;

                    switch (process) {
                        case 'forDeletion': processStatus = 'D';
                            break;
                        case 'asRead': processStatus = 'R';
                            break;
                        default: processStatus = '';
                    }

                    return function(message) {
                        message = message || {};
                        var pendingStatus = typeof message.pendingStatus === 'string'
                            ? message.pendingStatus.toUpperCase()
                            : '';
                        return {
                            "ecid": message.ecid,
                            "umi": message.umi,
                            "clientNumber": message.clientNumber,
                            "groupNumber": message.groupNumber,
                            "productNumber": message.productNumber,
                            "conversationID": message.conversationID,
                            "communicationID": message.communicationID,
                            "campaignMessageID": message.campaignMessageId,
                            "processStatus": processStatus || pendingStatus,
                            "messageRcdMntnId": "mbr"
                        };
                    };
                };

                deleteMessages = function(messagesToDelete) {
                    var payloadMessageList = messagesToDelete.map(_formatForRequestObject('forDeletion'));
                    var deleteMessagePromise = services.mhsimb.putMessageDeleteFlag().delete(payloadMessageList);

                    return deleteMessagePromise.then(
                        function() {
                            services.rbsmbl.getMsgCenterMessages().flushCache();
                        },
                        function(err) {
                            console.error('deleteMessages().update() failed with error', err);
                        }
                    );
                };
                setStatuses = function(messageList) {
                    var payloadMessageList = messageList.map(_formatForRequestObject('asRead'));

                    return services.mhsimb.putUpdateReadFlag().update(payloadMessageList);
                };


                setStatus = function(message, newStatus) {
                    message.pendingStatus = newStatus;

                    return setStatuses([message]);
                };

                getCategories=function(){
                    return services.rbsmbl.getSubCategories().fetch();
                }

                return {
                    setStatus: setStatus,
                    getMessages: getMessages,
                    deleteMessages: deleteMessages,
                    getCategories: getCategories
                }
            }])
    }
