module.exports = function(app) {
	app.controller("pages.logout", ["$scope", "session", function($scope, session){
		$scope.headings = "Logout";
		console.log("session", session);
		if (session.data && session.data.loggedIn) {
			session.logoutWithRedirect();
		}
	}]);
	return [
		{
			state: "memberLogout",
			url: "/logout",
			postPassword: true,
			views: {
				'body@': {
					template: bootstrap.templates['page-templates/landing.html'],
					controller: "pages.logout"
				},
				"hero@memberLogout": {
					template:   '<p class="margin-top">You have been logged out. You may close your browser window.</p>'
				}
			}
		}
	];
};