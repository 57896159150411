module.exports = function (app) {
    'use strict';
    app.controller('prePassFindDoctor', ['$scope', 'brand', 'coremetrics', 'modal','teamsite', '$q', 'sessionData',
        function ($scope, brand, coremetrics, modal, teamsite, $q, sessionData) {
            /****
             * This is used to show/hide items in the find-a-doc landing page
             * and direct users to the new Sapphire pages.
             */
            $scope.showPharmacy = false;
            $scope.showVision = false;
            $scope.showDental = false;
            $scope.activeDrawer = "";
            $scope.content = {};
            $scope.activeModal = null;
            var contentIds = ['MBR_FINDADOCTOR_PHARMACY_LINKSCURRENT', 'MBR_FINDADOCTOR_PHARMACY_LINKSPAST'];
            var contentIdMap = {
                'MBR_FINDADOCTOR_PHARMACY_LINKSCURRENT': 'pharmacyLinksCurrent',
                'MBR_FINDADOCTOR_PHARMACY_LINKSPAST': 'pharmacyLinksPast',
                'MBR_FINDADOCTOR_PHARMACY_LINKSCURRENT_MEDICARE': 'pharmacyLinksCurrent',
                'MBR_FINDADOCTOR_PHARMACY_LINKSCURRENT_MEDICARE_WV': 'pharmacyLinksCurrent',
                'MBR_FINDADOCTOR_PHARMACY_LINKSPAST_MEDICARE': 'pharmacyLinksPast'
            };
            $scope.chipMap = {
                "940":"14",
                "1087":"15",
                "1088":"85"
            };


            $scope.openDrawer = function(drawer){
                $scope.showPharmacy = false;
                $scope.showVision = false;
                $scope.showDental = false;

                switch(drawer){
                    case "pharmacy":
                        $scope.showPharmacy = true;
                        break;
                    case "vision":
                        window.open('https://idoc.davisvision.com/DavisVision.Member/Highmark/Authentication/MemberHandoff/?GroupMember=2015&Group=H1Z&DestinationURL=Highmark/FindAProvider/Index');
                        break;
                    case "dental":
                        var url=window.location.href;
                        $scope.isChoice=url.split('?');
                        if($scope.isChoice[1]=='medicare=yes'){
                            $scope.showDental = false;
                            $scope.isMedcareYes=true;
                            window.open('https://www.unitedconcordia.com/find-a-dentist/?network=842');
                        }
                        else{
                            $scope.isMedcareYes=false;
                            $scope.showDental = true;
                        }
                        break;
                }
                $scope.activeDrawer = ((drawer === 'pharmacy') || ($scope.showDental)) ? drawer : '';
            };


            $scope.showMedicalVitalsSearch = function(){
                $scope.siteToLeave = (brand.current.domain.toLowerCase() !== "mybenefitshome.com")?"Highmark": "Your health plan";

                $scope.activeModal = modal.open({
                    heading: "You are about to visit the Sapphire Digital site.",
                    template: require("modals/preVitalsDisclaimerModal.html"),
                    scope: $scope
                });
            };


            $scope.continueToDestination = function(){
                var qs = (location.href.split("?").length > 0)? location.href.split("?")[1] : "";
                if (qs) {
                    var prodId = qs.match(/productid=\d+/gi);
                }
                if(prodId != null){
                    prodId = prodId[0].split("=")[1];
                }
                var bcPrefix = ($scope.showEmployerSection) ? "e" : "";
                if(qs && qs.search(/medicare=yes/gi) > -1){
                    bcPrefix = "m";
                }
                if(prodId != null){
                    switch(prodId){
                        case "15":
                            //CHIP-WPA
                            bcPrefix = "c1";
                            break;
                        case "14":
                            //CHIP-NEPA
                            bcPrefix = "c2";
                            break;
                        default:
                            //CHIP-HBS
                            bcPrefix = "c";
                    }
                }
                // we have to add a specific prefix for CHIP plans, and we also have to send the proper network_id
                var targUrl = "/rbsmbl/x-services/public/sapphire/find-a-doctor/" + bcPrefix + brand.current.sharedBrandCode;
                if(prodId != null){
                    targUrl += "?network_id=" + $scope.chipMap[prodId];
                } else if((brand.current.sharedBrandCode === 'hmbcbs') && (window.location.href.indexOf('medicare=yes') < 0)){
                    targUrl += "?network_id=172";
                }
                window.open(targUrl);
                $scope.closeModal();
            };


            function init() {
                // Make the calls to teamsite and store the promises in an arry
                var contentIdObj = {};
                angular.forEach(contentIds, function(id) {
                    id = sessionData.isMedicare ? id + '_MEDICARE' : id;
                    if(('MBR_FINDADOCTOR_PHARMACY_LINKSCURRENT' === id
                        || 'MBR_FINDADOCTOR_PHARMACY_LINKSCURRENT_MEDICARE' === id)
                        && brand.current.sharedBrandCode === 'hmbcbswv') {
                        // TODO UNCOMMENT WHEN CONTENT HAS BEEN CREATED AND MOVED TO TEAMSITE
                        // id = id + '_WV';
                    }
                    contentIdObj[id] = teamsite.get(id);
                });

                $q.all(contentIdObj).then(function (results) {
                    // Handle each promise by mapping the contentId to the scope var
                    for (var res in results) {
                        $scope.content[contentIdMap[res]] = results[res];
                    }
                });
            }

            init();
        }
    ]);

};