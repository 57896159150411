module.exports = function(app) {
    app.directive('wellDivOne', function() {

        return {
            restrict: 'A',
            replace: true,
            template: require('directives/wellDivOne.html'),
            // scope: {},
            controller: ['$scope',
                function($scope) {

                }
            ]
        };
    });
}