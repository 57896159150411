module.exports={
    "blueDistinctionPanel": {
        "headlineText": "What is Blue Distinction?",
        "detailText": "A designation awarded by the Blue Cross and Blue Shield Association (BCBSA) to hospitals proven to deliver superior results for complicated and costly procedures. Blue Distinction Centers have been evaluated to meet strict criteria set by expert doctors and medical certifying organizations.",
        "panelImg": "/login/resources/img/bdc.png"
    },
    "blueDistinctionSpecialtiesPanel": {
        "headlineText": "Blue Distinction Specialties",
        "detailText": "Your plan recommends Blue Distinction Centers for the following procedures:"
    },
    "blueDistinctionValuePanel": {
        "headlineText": "Blue Distinction Value",
        "detailText": "Overall, patients treated at Blue Distinction centers have:",
        "resultList": [
            "Better outcomes",
            "Fewer complications",
            "Fewer Readmissions",
            "Higher survival rates",
            "Expert treatment"
        ]
    },
    "coverageFeaturesPanel": {
        "headlineText": "Coverage Features",
        "detailText": "To learn more about some features of your coverage, please watch these videos:"
    },
    "blueDistinctionSearchPanel": {
        "headlineText": "Find A Blue Distinction Center",
        "detailText": "Looking for Blue Distinction Centers near you? Find Blue Distinction Centers by specialty or state with the Blue Distinction Center Finder."
    },
    "copayFlavor": {
        "No Coverage when services not Performed at a Blue Distinction Center": "Note: Care at facilities that are not Blue Distinction Centers may not be covered by your plan. Review your benefit details before you schedule your procedure. To learn more about how you can save, use our Care Cost Estimator.",
        "Increased Copayment when services not Performed at a Blue Distinction Center": "Note: Your copays will be higher at facilities that are not Blue Distinction Centers. Review your benefit details before you schedule your procedure. To learn more about how you can save, use our Care Cost Estimator.",
        "Increased Coinsurance when services not performed at a Blue Distinction Center": "Note: You can save money when you schedule your procedure at a Blue Distinction Center. Review your benefit details and use the Care Cost Estimator to plan for your care costs.",
        "Else": "Note: You can save money at Blue Distinction Centers."
    }
}