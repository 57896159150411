module.exports = function(app) {
    app.filter('capitalize', function() {

        return function(string) {
            if (!(typeof string === 'string' || string instanceof String)) {
                return '';
            }

            return string.toLocaleLowerCase().replace( /(^|\s|\/)[a-z]/g , function(captured){ return captured.toUpperCase(); });
        };
    });
};