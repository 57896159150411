module.exports = ['$scope', 'sessionData', 'vendorSSOUtility', 'teamsite', '$cookies', function($scope, sessionData, vendorSSOUtility, teamsite, $cookies) {

    teamsite.get('MBR_MBRHOME_VITALS_MODAL').then(function (data){$scope.vitalsModal = JSON.parse(data);});

    $scope.ssoToVitals = function(){

        var sd = sessionData;
        var clientNumber = '';
        var umsBrand = $cookies.get('umsBrand');

        if (sd.memberInfo && sd.memberInfo.clientList && sd.memberInfo.clientList) {
            var clientList = sd.memberInfo.clientList;
            for (var i = 0; i < clientList.length; i++) {
                var arrGroups = clientList[i].groupList;
                for (var j = 0; j < arrGroups.length; j++) {
                    if (arrGroups[j].groupNumber == sd.groupNumber) {
                        clientNumber = clientList[i].clientNumber;
                    }
                }
            }
        }

        var sitePromotionStr = 'Vitals%20SmartShopper-_-SmartShopper';
        var ssoParameters = {
            clientNumber: clientNumber,
            groupNumber: sessionData.groupNumber,
            targetType: 'LANDING',
            vendorName: 'vitalsSmartShopper',
            brand: umsBrand
        };
        vendorSSOUtility.performSSO(ssoParameters, '_blank', sitePromotionStr);

    };
}];