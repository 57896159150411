/**
 * Created by lid9aap on 07/19/2016.
 */
module.exports = function(app) {
    app.factory('partialPayService', ['$q', 'services','teamsite','$filter',
        function($q, services, teamsite, $filter) {

            var
                getSpendingAccount,
                _isMedicalEligibleAccount;

            /**
             * private method to check if given account is eligible for medical(medical, drug, vision, dental) claims payment
             *
             * @param electionDetails electionDetails Object from spendingAccountAmount (amount) service
             * @returns {boolean} true if account type code is one of HSA/HCRA(=FSA)/HRA/HRAV/HRAR/HRAW, else false
             * @private method
             * @author lid9aap
             */
            _isMedicalEligibleAccount=function(electionDetails){
                //  console.log('inside _isMedicalEligibleAccount ', electionDetails);
                var isMedicalEligible=false;
                if(electionDetails.accountType === "HSA" || electionDetails.accountType === "FSA" || electionDetails.accountType === "HRA" || electionDetails.accountType === "HCRA" || electionDetails.accountTypeCode === "HRAV" ||electionDetails.accountType === "HRAR" || electionDetails.accountType === "HRAW" ){
                    isMedicalEligible=true;
                }
                return isMedicalEligible;
            };

            /**
             *private method to check if given account type is eligible for prior fund pay claims, valid types are HCRA(=FSA)/HRA
             *
             * @param electionDetails Account Object from spendingAccountGlance service
             * @returns {boolean}
             * @private method
             * @author lid9aap
             */

            _isValidPriorYearFundAccountType=function(electionDetails){
                // console.log('inside _isValidPriorYearFundAccountType ', electionDetails);
                var isPriorFundValid=false;
                if(electionDetails.accountType === "FSA" || electionDetails.accountType === "HRA" || electionDetails.accountType === "HCRA" ){
                    isPriorFundValid=true;
                }
                return isPriorFundValid;
            };

            /**
             *private method to grab current and prior year fund from spending account of type HRA/HCRA(=FSA) if applicable and picks max 2 balances from electionList
             *
             * @param electionDetails electionDetails Object from spendingAccountGlance service
             * @param dateOfService
             * @returns {number} currentBalance from prior year
             * @private method
             * @author lid9aap
             */
            _getCurrentAndPriorYearFundIfClaimIsBeforeRunOut=function(electionDetails, dateOfService){
                // console.log('inside _getCurrentAndPriorYearFundIfClaimIsBeforeRunOut ', electionDetails);
                var amount=parseFloat(0.00);
                var today=new Date();
                var max=2;
                var count=0;
                for(var index=0;index < electionDetails.length; index++){
                    //add planYearType logic
                    var planYearType="";
                    if(electionDetails[index].planYearType){
                        planYearType=electionDetails[index].planYearType.trim();
                    }
                    if(planYearType && planYearType.toLowerCase() ==="cur"){
                        if(count < max){
                            amount+=parseFloat(electionDetails[index].currentBalance);
                            count++;
                        }
                    }else if(!planYearType || planYearType.toLowerCase() !== "fut"){
                        if(count < max && _isElectionWithinTwoYears(electionDetails[index].electionPeriodStartDate)){
                            amount+=parseFloat(electionDetails[index].currentBalance);
                            count++;
                        }
                    }

                    if(count>= max){
                        break;
                    }
                }
                return amount;
            };
            /***
             * method to get current balance of user checking plan period, will skip checking once found
             * @param electionDetails
             * @param dateOfService  TODO: might be needed for future
             * @returns {number}
             * @private
             */
            _getCurrentBalanceForSpendingAccount=function(electionDetails,dateOfService){
                //  console.log('inside _getCurrentBalanceForSpendingAccount ', electionDetails);
                var amount=parseFloat(0.00);
                var today=new Date();

                for(var index=0;index < electionDetails.length; index++){
                    var planYearType="";
                    if(electionDetails[index].planYearType){
                        planYearType=electionDetails[index].planYearType.trim();
                    }
                    //check if active plan and date of service is in current plan period to pick active balance
                    if(planYearType && planYearType.toLowerCase()==="cur"){
                        amount=parseFloat(electionDetails[index].currentBalance);
                        break;
                    }else if(!planYearType){
                        var startDate=_getDateFromMMDDYYYY_slash_separtator(electionDetails[index].electionPeriodStartDate);
                        var endDate= _getDateFromMMDDYYYY_slash_separtator(electionDetails[index].electionPeriodEndDate);
                        if(today.getTime() <= endDate.getTime() && today.getTime() >= startDate.getTime()){
                            amount=parseFloat(electionDetails[index].currentBalance);
                            break;
                        }
                    }
                }
                return amount;
            };


            /**
             *private method to check if given dateOfService is between dates planBeginDate and runOutDate
             *
             * @param planBeginDate
             * @param runOutDate
             * @param dateOfService
             * @returns {boolean} true dateOfService is between planBeginDate and runOutDate, else returns false
             * @private method
             * @author lid9aap
             * TODO: might be needed for future
             */
            _isClaimsServiceDateIsBetweenGivenPlanBeginDateAndRunOutEndDate=function(planBeginDate,planRunOutDate,dateOfService){
                //  console.log('inside _isClaimsServiceDateIsBetweenGivenPlanBeginDateAndRunOutEndDate ', planBeginDate,planRunOutDate,dateOfService);
                var beginDate=  _getDateFromMMDDYYYY_slash_separtator(planBeginDate);
                var runOutDate= _getDateFromMMDDYYYY_slash_separtator(planRunOutDate);
                //check two years window with effectiveDate
                var claimServiceDate=_getDateFromYYYYMMDD(dateOfService);
                // console.log(dateOfService+' is between '+planBeginDate+' and '+planRunOutDate,claimServiceDate.getTime()>=beginDate.getTime() && claimServiceDate.getTime()<= runOutDate.getTime());
                var isBetweenDate=false;
                if(claimServiceDate.getTime()>=beginDate.getTime() && claimServiceDate.getTime()<= runOutDate.getTime()){
                    isBetweenDate=true;
                }
                return isBetweenDate;
            };

            /**
             * private method to check if given planStartDate is within two years from today
             *
             * @param planStartDate
             * @returns {boolean} true if given planStartDate is within two years from today, else false
             * @private method
             * @author lid9aap
             */

            _isElectionWithinTwoYears=function(planStartDate){
               // console.log('plan start date in _isElectionWithinTwoYears',planStartDate);
                var  today=new Date();
                var diff= today.getTime()-_getDateFromMMDDYYYY_slash_separtator(planStartDate);
                var days=diff/(24*60*60*1000);
              //  console.log('planStartDate is past days :', days);
                var withInTwoYears=false;
                if(days<= 2*365  && days >= 0){
                    withInTwoYears=true;
                }
                return withInTwoYears;
            };


            _getDateFromYYYYMMDD=function(dateStr){
                //  console.log("inside _getDateFromYYYYMMDD : "+dateStr);
                return new Date(dateStr.substring(0,4),dateStr.substring(4,6),dateStr.substring(6,8));
            };
            /**
             *
             * @param dateStr e.g 12/01/2015
             * @returns {Date}
             * @private
             */
            _getDateFromMMDDYYYY_slash_separtator=function(dateStr){
                //  console.log("inside _getDateFromYYYYMMDD : "+dateStr);
                return new Date(dateStr.substring(6,10),dateStr.substring(0,2),dateStr.substring(3,5));
            };


            getSpendingAccount = function(clientId, isMedicalType,claimsDateOfService) { // clientId (formerly known as the clientNumber because wot?
                // TODO: Figure out if we're gonna use the claimNumber or the dates to ensure claims (cont'd below)
                // TODO: can only be paid from contemporary spending accounts
                var memberInfoPromise = services.rbsmbl.memberInfo().fetch();
                return memberInfoPromise.then(
                    function(response) {
                        var glancePromise = services.dpsext.spendingAccountGlance().fetch(response.data.payload, clientId, 30);

                        return glancePromise.then(
                            function(result) {
                                var
                                    glance = result.data.repGetAccountsAtAGlanceReply.payload.repGetAccountsAtAGlanceData,
                                    accountDisplayName, currentBalance,availableBalance, teamsiteField;

                                availableBalance=parseFloat(0.00);

                                var accountList=glance.accountList.accountInfo;

                                var isClaimBelongToPriorYearOnly=false;
                                if(accountList instanceof Object){
                                    for(var acct in accountList) {

                                        var electionList=accountList[acct].electionList.electionDetails;
                                        if(electionList instanceof Object){

                                            //HRA/HCRA applicable for both medical and non medical claims
                                            //console.log(accountList[acct].accountType+ " is perpetual ", accountList[acct].planPeriodType.toUpperCase() === 'PERPETUAL');
                                            if(typeof accountList[acct].planPeriodType === 'string' && accountList[acct].planPeriodType.toUpperCase() === 'PERPETUAL'){
                                                if(isMedicalType){
                                                    if(_isMedicalEligibleAccount(accountList[acct])){
                                                        availableBalance+=parseFloat(electionList[0].currentBalance);
                                                    }
                                                }else{
                                                    availableBalance+=parseFloat(electionList[0].currentBalance);
                                                }
                                            }else if(_isValidPriorYearFundAccountType(accountList[acct])){
                                                availableBalance += _getCurrentAndPriorYearFundIfClaimIsBeforeRunOut(electionList,claimsDateOfService);
                                            }else if(isMedicalType){
                                                //skips account like PRA
                                                if(_isMedicalEligibleAccount(accountList[acct])){
                                                    availableBalance += _getCurrentBalanceForSpendingAccount(electionList,claimsDateOfService);
                                                }
                                            }else{
                                                //considers all accounts
                                                availableBalance += _getCurrentBalanceForSpendingAccount(electionList,claimsDateOfService);
                                            }
                                        }
                                    }

                                    // work for payfrom account display
                                    var noOfAcct=accountList.length;
                                    if(noOfAcct === 1){
                                        if( isMedicalType && !_isMedicalEligibleAccount(accountList[0])){
                                            teamsiteField='MBR_CLAIMS_SA_NOT_ELIGIBLE';
                                        }else{
                                            accountDisplayName =  accountList[0].accountDisplayName;
                                        }
                                    }else if(noOfAcct > 1){
                                        teamsiteField='MBR_CLAIMS_SPENDING_ACCOUNT';
                                        if(isMedicalType){
                                            var medicalEligibleAccountFound=false;
                                            for(var acc in accountList) {
                                                console.log("accountList "+acc,accountList[acc]);
                                                if(_isMedicalEligibleAccount(accountList[acc])){
                                                    medicalEligibleAccountFound=true;
                                                    break;
                                                }
                                            }
                                            if(!medicalEligibleAccountFound){
                                                teamsiteField='MBR_CLAIMS_SA_NOT_ELIGIBLE';
                                            }
                                        }
                                    }else{
                                        teamsiteField='MBR_CLAIMS_NO_SA';
                                        availableBalance=parseFloat(0.0);
                                    }
                                }

                                if(teamsiteField === undefined){
                                    return {
                                        accountDisplayName: accountDisplayName,
                                        currentBalance: availableBalance
                                    };
                                }else{
                                    return teamsite.get(teamsiteField).then(
                                        function(data){
                                            return{
                                                accountDisplayName: data,
                                                currentBalance: availableBalance
                                            }
                                        }
                                    );
                                }
                            },
                            function(err) {
                                console.error('spendingAccountGlance failed in getSpendingAccount', err);
                                return glancePromise;
                            }
                        );
                    },
                    function(err) {
                        console.error('memberInfo.fetch failed with error', err);
                        return memberInfoPromise;
                    }
                );
            };

            return {
                getSpendingAccount: getSpendingAccount
            };
        }
    ]);

};
