module.exports = function(app) {
    app.directive('messageSummary', ['messageService', 'session', function(messageService, session) {

        return {
            restrict: 'A',
            replace: true,
            template: require('directives/messageSummary.html'),

            scope: {},

            controller: ['$scope', 'coremetrics', '$cookies', function($scope, coremetrics, $cookies) {

                $scope.shouldShow = !session.data.blueEdgeDentalIndv;

                if ($scope.shouldShow) {
                    $scope.heading = 'Messages';
                    $scope.viewState = 'loading';
                    $scope.isNDBCBS = $cookies.get('umsBrand').toLowerCase() === 'ndbcbs' ? true : false;
                    /*Coremetrics Element Tag for Messages Summary Arrow link/button*/
                    $scope.messagesSummaryArrowCoremetrics = function () {
                        coremetrics.elementTag('CLAIMS:MESSAGES ARROW', 'MEMBER:CLAIMS');
                    };

                    messageService.getMessages().then(
                        function (messages) {
                            $scope.messageList = messages.messageList.map(function(message) {
                                if (typeof message.messageCategory === 'string' && message.messageCategory.toLowerCase() === 'special') {
                                    var memberList = message.messageContent.memberList.map(function(member) {
                                        member = typeof member === 'string'
                                               ? member.toLowerCase()
                                               : '';

                                        return member.charAt(0).toUpperCase() + member.slice(1);
                                    });
                                    message.messageContent.Subject = message.messageContent.Subject.replace(/\$<Names>/, memberList.join(', '));
                                }
                                return message;
                            });
                            $scope.totalCount = messages.totalCount;
                            $scope.unread = messages.unread;
                            $scope.viewState = 'success';
                        },
                        function (err) {
                            $scope.viewState = 'error';
                            console.error('getMessages failed with error', err);
                        }
                    );
                }
            }]
        };
    }]);
}