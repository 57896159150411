module.exports = function(app) {
    require("./custom-landing/member-login")(app);
    app.controller('pages.customLanding', ["$window", "$scope", "services", "sessionData", "$location", "brand",
        function($window, $scope, services, sessionData, $location, brand) {
            $window.document.title = "Home";
            if(brand.current.sharedBrandCode.toLowerCase() == 'mincr'){
               var env = window.location.host.split(".")[0];
                window.location="https://www"+(env === 'www' ? "" : ".qa")+".bluecrossmn.com/member-login";
            }
            $scope.guideWellRedirect = $location.search().returnTo;
            $scope.isDeepLinkIdCard = window.location.hash.search('idCard') > 1;
            $scope.msgCenterDeepLink = window.location.hash.search('msgCenter') > 1;
            $scope.isBcbsNdPortal = window.location.host.split('.')[1] === 'bcbsndportals';

            $scope.nonGuideWellHeading = true;
            if ($scope.guideWellRedirect === '/emssvc/x-services/secure/sso/outbound/auto/v1/vendor/GUIDEWELLMEMBER') {
                $scope.nonGuideWellHeading = false;
                $scope.guideWellHeading = true;
                $scope.sidebar = { description: '<span data-teamsite="HOM_CUSTOMLANDING_GUIDEWELL_DESCRIPTION"></span> ' };
            } else {
                $scope.showSection = false;
                $scope.sidebar = {
                    description: '<span data-teamsite="MBR_CUSTOM_CLIENT_LOGIN as content"> ' +
                    '<span ng-if="isDeepLinkIdCard && isBcbsNdPortal">{{content.leftNav.idCardDescription}}</span>' +
                    '<span ng-if="msgCenterDeepLink && isBcbsNdPortal">{{content.leftNav.msgCenterDescription}}</span>' +
                    '<span ng-if="!isDeepLinkIdCard && !msgCenterDeepLink">{{content.leftNav.description}}</span>' +
                    '</span>',
                };
                if (sessionData.loggedIn) {
                    $window.location = "/member/home";
                }
            }
        }
    ]);
    return [
        {
            state: "customLanding",
            appConfigMaintenanceApp: "SPLASH",
            appConfigMaintenanceKey: "CUSTOM_LANDING",
            url: "/custom-landing",
            views: {
                'body@': {
                    template: require('pages/custom-landing.html'),
                    controller: "pages.customLanding",
                },
                "content@customLanding": {
                    template: require('pages/custom-landing/member-login.html'),
                    controller: "pages.customLanding.memberLogin"
                }
            },
        }, {
            state: "customLanding.uom",
            url: "/:client"
        }
    ];
};
