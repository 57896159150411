module.exports = function(app) {

    app.factory('pdfRequestPrepService', ['$filter','brand',
        function($filter, brand) {
            var
                augmentAcclarisPaymentLine,
                decorateClaimForRequestPayload;

            augmentAcclarisPaymentLine = function(line, claim, getClaimStatus) {
                // Below are for PDF service at the very least
                line.isAllowed = '';
                line.isSelected = false;
                line.provider = line.perfProviderName;
                line.lineNumber = line.lnNo;
                line.dateOfService = $filter('fromyyyyMMdd')(line.lineSvcBgnDt);
                line.serviceType = line.procedureShortDesc;
                line.procedureCode = line.adjuProcRevCd;
                line.quantity = line.lineUnitOfSvc;
                line.finalizedDate = $filter('fromyyyyMMdd')(claim.renoPaidDt);
                line.lineStatusCode = line.hsclPayStatusCd;
                line.lineStatus = $filter('lineStatus')(line.hsclPayStatusCd);
            };

            decorateClaimForRequestPayload = function(claim, status) {

                var _makeMemberName = function(first, middle, last) {
                    if (first && middle && last) {
                        return first + ' ' + middle + ' ' + last;
                    }
                    else {
                        return first + ' ' + last;
                    }
                };

                var brandCode = brand.current.umsBrandCode||brand.current.sharedBrandCode;
                var claimsDownloadImg;
                if(brand.current.sharedBrandCode == 'ndbcbs'){
                    claimsDownloadImg = 'images/bcbs-northdakota-logo.png';
                } else if(brand.current.sharedBrandCode == 'wybcbs') {
                    claimsDownloadImg = 'images/bcbs-wyoming-logo2.png';
                } else {
                    claimsDownloadImg = status.icon;
                }
                        // TODO: Add defensive checks
                var pdfRequestPayload = {
                    brand: brand.current.sharedBrandCode,
                    memberFirstName: claim.mbrFirstNm || '',
                    memberMiddleName: claim.mbrMiddleNm || '',
                    memberLastName: claim.mbrLastNm || '',
                    memberSuffix: claim.mbrSuffixNm || '',
                    memberName: _makeMemberName(claim.mbrFirstNm, claim.mbrMiddleNm, claim.mbrLastNm),
                    memberDob: claim.mbrBirthDate || '',
                    planType: claim.sysSrcCode === 'ods' ? 'drug' : claim.sysSrcCode,
                    planTypeIconClass: claim.sysSrcCode === 'ods' ? 'drug' : claim.sysSrcCode,
                    providerName: claim.bilgProvOrgNm || '',
                    dateOfService: $filter('fromyyyyMMdd')(claim.bilgProvSvcBgnDt),
                    dateOfServiceISO: claim.bilgProvSvcBgnDt || '',
                    claimStatusCode: claim.bilgProvClmStaCd | '',
                    claimStatusText: status.description || '',
                    claimStatusIconClass: claimsDownloadImg || '',
                    claimNumber: claim.claimNumber,
                    yourCost: (claim.bilgProvTotMbrLiab || 0).toFixed(2),
                    costNotAvailableMsg: 'In Process',
                    inputMedium: claim.inputMedium,
                    groupNumber: claim.displayGroupNumber,
                    claimPayButton: claim.claimPayButton === 'true',
                    billingProviderId: claim.bilgProviderId,
                    eobDocSeqNo: claim.eobDocSeqNo,
                    eobPrintCd: claim.eobPrintCd,
                    drugPlanPaid: (claim.drugTotAprPymAt || 0).toFixed(2),
                    drugMemberDiscountedRate: claim.drugAllChrAmt,
                    drugProviderCharge: claim.bilgTotCharge,
                    drugRefillNumber: claim.drugPrsnRflNo,
                    drugQuantity: claim.drugPrsnQoddCt,
                    drugRxNumber: claim.drugPrsnNo,
                    drugName: claim.drugPrNm,
                    yourDrugCost: (claim.bilgProvTotMbrLiab || 0).toFixed(2),
                    paymentInfo: claim.paymentInfo,
                    drugMathLines: claim.mathLines || claim.details.mathLines,
                    claimsDetailData: claim.details
                };

                if (claim.claimType.isDrug) {
                    claim.details.forEach(function(detail) {
                        detail.providerName = claim.bilgProvOrgNm || '';
                        detail.dateOfService = $filter('fromyyyyMMdd')(claim.bilgProvSvcBgnDt);
                        detail.dateOfServiceISO = claim.bilgProvSvcBgnDt || '';
                        detail.drugMathLines = claim.mathLines || detail.mathLines;
                        detail.drugPlanPaid = (claim.drugTotAprPymAt || 0).toFixed(2);
                        detail.drugMemberDiscountedRate = claim.drugAllChrAmt;
                        detail.drugProviderCharge = claim.bilgTotCharge;
                        detail.drugRefillNumber = claim.drugPrsnRflNo;
                        detail.drugQuantity = claim.drugPrsnQoddCt;
                        detail.drugRxNumber = claim.drugPrsnNo;
                        detail.drugName = claim.drugPrNm;
                        detail.yourDrugCost = (claim.bilgProvTotMbrLiab || 0).toFixed(2);
                        detail.paymentInfo = claim.paymentInfo;
                    });
                }

                return angular.extend(pdfRequestPayload, { claimsDetailData: claim.details });
            };


            return {
                augmentAcclarisPaymentLine: augmentAcclarisPaymentLine,
                decorateClaimForRequestPayload: decorateClaimForRequestPayload
            };
        }
    ]);
};