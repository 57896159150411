module.exports = function(app) {
    app.directive('prescriptsActTwoOhSeven', function() {

        return {
            restrict: 'A',
            replace: true,
            template: require('directives/prescriptsActTwoOhSeven.html'),

            // scope: {},

            controller: ['$scope', 'prescriptionLogic',
                function($scope, prescriptionLogic) {
                    var o = $scope.directiveOptions;
                    var userSeesAct207Module = prescriptionLogic.userSeesAct207Module(o.theBrand);
                    $scope.uiModules.Act207 = {
                        theTitle: "MBR_PRESCRIPTION_ACT207_HEADLINE",
                        theText: "MBR_PRESCRIPTION_ACT207_TEXT",
                        hasBlueBackground: false,
                        // rightSortOrder: 3,
                        theHref: 'MBR_PRESCRIPTION_ACT207_LINKTEXT',
                        //isAct207Module: true,
                        isAct207Module: userSeesAct207Module,
                        isFormsAndActions: false,
                        isGenericModule: false,
                        isLastRCol: true
                    };
                }
            ]
        };
    });
}