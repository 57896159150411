module.exports = function(bootstrap) {
    bootstrap.run(['Service', 'brand', function(Service, brand) {
        new Service(
            {
                name: 'espot',
                group: 'wcs',
                url: '/wcs/x-services/resources/store',

                getEspotPrefix: function(brandCode) {
                        var prefixMap = {
                            'hmbcbs': 'HighmarkBCBS',
                            'hmbcbsde': 'HighmarkDE',
                            'mincr': 'BlueCrossMN',
                            'hmbcbswv': 'HighmarkWV',
                            'hbs': 'HighmarkBlueShield',
                            'inac': 'independencenationalalliance',
                            'pp': 'MyBenefitsHome',
                            'ndbcbs': 'BCBSND',
                            'wybcbs': 'BCBSWY'
                        };

                    return prefixMap[brandCode];
                },

                fetch: function(espotList) {
                    espotList = espotList || [1, 5, 6, 7];
                    var storeNumber = parseInt(brand.current.storeId);
                    var espotPrefix = this.getEspotPrefix(brand.current.sharedBrandCode);

                    var espotCollection = espotList.map(function(espotNumber) {
                        return espotPrefix + '_Espot' + espotNumber;
                    });

                    this.url += '/' + storeNumber + '/espot/' + espotCollection + '/';

                    return this.get().then(function(response) {
                        if (response.data instanceof Object && Array.isArray(response.data.hmdataList)) {
                            var viewports = ['Desktop', 'Mobile', 'Notebook', 'Tablet'];
                            var prefixedViewports = viewports.map(function(viewport) {
                                return espotPrefix + '_' + viewport;
                            });

                            return response.data.hmdataList.map(
                                function(imgObject, i) {
                                    if (i === 0) {
                                        var newKey;
                                        for (var j = 0; j < prefixedViewports.length; ++j ) {
                                            for (var key in imgObject) {
                                                if (imgObject.hasOwnProperty(key)) {
                                                    if (key === prefixedViewports[j]) {
                                                        newKey = 'image' + key.split('_')[1];
                                                        imgObject[newKey] = imgObject[key];
                                                        delete imgObject[key];
                                                        break;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    return imgObject;
                                }
                            );
                        }
                    });
                }
            }
        );
    }]);
};