module.exports = function(app) {
    app.controller('pages.preFindDoctor.prescription-drug', ['$scope', '$state', 'teamsite', 'sessionData',
        function ($scope, $state, teamsite, sessionData) {
            $scope.acaLinkContent = '';

            $scope.sidebar.links = [{
                heading: "DOCTORS, FACILITIES & MORE",
                label: "Find Providers",
                state: "prepassFindDoctor",
                refineOverrideOpen: false,
                description: "Looking for an in-network doctor? Need to find a facility near you? You're in the right place. Let us help you find what you need."
            },{
                heading: "DOCTORS, FACILITIES & MORE",
                label: "Find a Prescription Drug",
                state: "drug",
                refineOverrideOpen: false,
                description: "Looking for an in-network doctor? Need to find a facility near you? You're in the right place. Let us help you find what you need."
            }];

            $scope.headings = "Find A Doctor";

            function init() {
                // Get teamsite content based on if user is coming from Medicare
                var acaLinkContentId = 'MBR_FINDADOCTOR_PRESCRIPTIONDRUG_ACAPLANLINKS';
                if(sessionData.isMedicare){
                    acaLinkContentId = acaLinkContentId + "_MEDICARE";
                }
                teamsite.get(acaLinkContentId).then(function(result) {
                    $scope.acaLinkContent = result;
                });

                // Get teamsite content for the formulary links
                var formularyLinkContent = 'MBR_FINDADOCTOR_PRESCRIPTIONDRUG_FORMULARYLINKS';
                teamsite.get(formularyLinkContent).then(function(result) {
                    $scope.formularyLinkContent = result;
                });
            }

            init();

        }
    ]);
};
