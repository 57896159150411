module.exports = function(app) {
    app.directive('spendingClaimDrawer',
        function() {

            return {
                restrict: 'A',
                replace: true,
                template: require('directives/spendingClaimDrawer.html'),

                scope: {
                    claim: '=spendingClaimDrawer'
                },


                controller: ['$scope','coremetrics', function($scope,coremetrics) {
                    var spendingClaimIsClicked;
                    $scope.$on('drawerOpen', function() {
                        if(!spendingClaimIsClicked){
                            coremetrics.elementTag('DEBIT CLAIM EXPAND: SPENDING','MEMBER:SPENDING');
                            spendingClaimIsClicked = true;
                        }
                    });

                }]
            };
        }
    );
};