module.exports = function(bootstrap) {
    bootstrap.run(['Service', function (Service) {
        new Service({
            name: 'coverage',
            group: 'rbsmbl',
            url: '/rbsmbl/x-services/registration/coverage',
            fetch: function(infoObj) {
                // var payload = getCoverageModalData();
                //removing SSN from activeCoverages - causing coverageService to fail
                angular.forEach(infoObj.activeCoverages, function (item) {
                    delete item.ssn;
                });

                if (infoObj.brand.toUpperCase() === 'HMBCBS') {
                    infoObj.brand = 'hbcbs';
                } else {
                    infoObj.brand = infoObj.brand;
                }

                var requestPayload = {
                    baseMessage: {
                        headerVersion: '1.0',
                        payloadVersion: '2.0',
                        additionalInfo: null
                    },
                    payload:  {
                        addressLine1: infoObj.address ? infoObj.address.toUpperCase(): '',
                        birthDate: infoObj.dob,
                        brand: infoObj.brand,
                        city: infoObj.city ? infoObj.city.toUpperCase(): '',
                        email: infoObj.email,
                        firstName: infoObj.firstName ? infoObj.firstName.toUpperCase(): '',
                        lastName: infoObj.lastName ? infoObj.lastName.toUpperCase(): '',
                        memberId: infoObj.memberId || '',
                        phoneNumber: infoObj.phoneNumber || '',
                        phoneType: infoObj.phoneType ? infoObj.phoneType.value : '',
                        shoppingZipCode: infoObj.shopZipCode || '',
                        zipCode: infoObj.zipCode,
                        cpFlow: infoObj.cpFlow,
                        ecid: infoObj.ecid,
                        activeCoverages: infoObj.activeCoverages,
                        fromRetail: 'Y',
                        relCode: infoObj.policyHolder.value
                    }
                };

                return this.post(requestPayload);
            }
        });
    }]);
};
