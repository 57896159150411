module.exports = function(app) {
    app.directive('prescriptsFaqs', function() {

        return {
            restrict: 'A',
            replace: true,
            template: require('directives/prescriptsFaqs.html'),
            // scope: {},
            controller: ['$scope','teamsite',
                function($scope, teamsite) {
                    var showFAQs = $scope.directiveOptions.showFAQs;
                    $scope.showLink = false;
                    //IBC code change
                    if($scope.directiveOptions.theBrand === 'ibc'){
                        showFAQs = false;
                    }

                    $scope.uiModules.FAQs = {
                        // FAQs -------------------------------------
                        theTitle: "MBR_PRESCRIPTION_FAQ_HEADLINE",
                        theText: "MBR_PRESCRIPTION_FAQ_TEXT",
                        theURL: "MBR_PRESCRIPTION_FAQ_LINK",
                        theURLTEXT: 'MBR_PRESCRIPTION_FAQ_LINKTEXT',
                        theText2Top:'MBR_PRESCRIPTION_FAQ_TEXT2TOP',
                        theText2Bottom: 'MBR_PRESCRIPTION_FAQ_TEXT2BOTTOM',
                        theURL2: 'MBR_PRESCRIPTION_FAQ_LINK2',
                        theUrlText2: 'MBR_PRESCRIPTION_FAQ_LINKTEXT2',
                        theText2:'MBR_PRESCRIPTION_FAQ_TEXT2',
                        hasBlueBackground: false,
                        // rightSortOrder: 2,
                        isAct207Module: false,
                        isFormsAndActions: false,
                        isGenericModule: showFAQs,
                        isFAQBottom: true
                    };

                    teamsite.get('MBR_PRESCRIPTION_FAQ_LINK2').then(function(response){
                        if(response != null || response!= undefined) $scope.showLink = true;
                        else $scope.showLink = false;
                    });



                }
            ]
        };
    });
};