module.exports = function(app){
	app.directive('cmsArticle',function(teamsite){
		return {
			restrict: 'EA',
			template: require('directives/cms/cmsArticle.html'),
			scope: {
				contentId: "@cmsArticle",
				type: "@type",
				finish: '&success'
			},
			controller : ['$scope','$sce', function ($scope,$sce) {
				$scope.getTrustedHtml=function(text){
					return $sce.getTrustedHtml(text);
				};
			}],
			link: function($scope, element, attrs){
				if(element[0].tagName !== 'ARTICLE'){
					console.error("You cannot use the cmsArticle directive on non-article tags.");
				}else{
					element.hide();
					$scope.article={};
					teamsite.get($scope.contentId).then(function(response){
						$scope.article = JSON.parse(response);
						element.show();
						$scope.finish({contentId: $scope.contentId});
					});
				}

			}
		};
	});
};