module.exports = function (app) {
    app.controller('pages.account', ['$scope', '$state', 'services', 'sessionData', 'teamsite', 'finder-logic2', '$cookies', 'brand',
        function ($scope, $state, services, sessionData, teamsite, finderLogic, cookies, brand) {

            $scope.headings = "Account Settings";
            $scope.showContactInformation = true; //
            $scope.showContactPreference = true; //
            $scope.showMemberProfile = false;
            $scope.showOtherInsurance = false;
            $scope.showPartnerProducts = false;
            $scope.showPasswordSecurity = true; 
            $scope.showPhysicianInformation = false;
            $scope.showTermsAndConditions = false;
            $scope.showTobaccoInformation = false;
            $scope.showViewIdCard = false;

            var planStates = {
                past: false,
                current: false,
                future: false
            };
            var planTimes = {
                value: 'current'
            };

            var moreThanOneTimeFrame = false;
            var pcpActiveWindow = false;

            var theBrand = brand.current.sharedBrandCode.toLowerCase();

            var ums = sessionData.memberInfo;
            var lob;
            if (finderLogic.portalSelectedPlanSpecificProductByLatestCoverageEffectiveDate('Medical')) {
                lob = finderLogic.portalSelectedPlanSpecificProductByLatestCoverageEffectiveDate('Medical').lob;
            }
            //var PSPums = ums.getPortalSelectedPlan()
            //console.log("angular portal selected plan",PSPums);

            var planInfo = finderLogic.getPortalSelectedPlan();
            //console.log("portal selected plan finder logic",planInfo);

            var oidNumber = function () {
                switch (theBrand) {
                    case 'ibccr':
                        return '440899';
                    case 'ibcah':
                        return '440306';
                    case 'hmbcbs':
                    case 'hbs':
                    case 'hmbcbsde':
                    case 'hhic':
                    case 'ibc': //This is the shared brand code for inac
                        return '359288';
                    default:
                        return '440304';
                }
            };

            // ====================Getting past, current, and future coverage info====================
            var today = new Date();
            var dd = today.getDate();
            var mm = today.getMonth() + 1;
            var yyyy = today.getFullYear();

            if (dd < 10) {
                dd = '0' + dd;
            }
            if (mm < 10) {
                mm = '0' + mm;
            }

            today = mm + '/' + dd + '/' + yyyy;

            for (var i = 0; i < ums.clientList.length; i++) {
                var currClient = ums.clientList[i];
                for (var j = 0; j < currClient.groupList.length; j++) {
                    var currGroup = currClient.groupList[j];
                    for (var k = 0; k < currGroup.productList.length; k++) {
                        var theProduct = currGroup.productList[k];
                        var currentDate = new Date(today);
                        var beginDate = new Date(theProduct.coverageBeginDate ? theProduct.coverageBeginDate : theProduct.benefitAccumulationStartDate);
                        var endDate = new Date(theProduct.coverageEndDate ? theProduct.coverageEndDate : theProduct.benefitAccumulationEndDate);

                        if (beginDate > currentDate) {
                            planStates.future = true;
                        }

                        if ((beginDate < currentDate) && (endDate > currentDate)) {
                            planStates.current = true;
                        }

                        if (endDate < currentDate) {
                            planStates.past = true;
                        }

                        localCounter = 0;

                        var hasActivePlans = planStates.current;
                        var hasPastPlans = planStates.past;
                        var hasFuturePlans = planStates.future;

                        if (planStates.current) {
                            localCounter += 1;
                        }
                        if (planStates.past) {
                            localCounter += 1;
                        }
                        if (planStates.future) {
                            localCounter += 1;
                        }
                        if (localCounter > 1) {
                            moreThanOneTimeFrame = true;
                        }

                        if (hasActivePlans == false) {
                            planTimes.value = "past";
                        }
                        if (hasActivePlans == false && hasPastPlans == false) {
                            planTimes.value = "future";
                        }
                    }
                }
            }

            if (planInfo.productList.length > 0) {
                for (var x = 0; x < planInfo.productList.length; x++) {
                    var selectedPlan = planInfo.productList[x];

                    // =========================Physician Information=========================
                    if (selectedPlan.benefitPlanType.toUpperCase() === 'MEDICAL') {
                        // does this plan have active coverage? For PCP we check coverageEndDate and add 45 days grace period.  For POR, we just check the model for active coverage.
                        var currentDate = new Date();
                        var laterDate = "";
                        var gracePeriod = 45;
                        var finalDate = "";
                        var eDate = null;
                        var cDate = null;
                        var piLink;
                        var planCoverageEndDate = selectedPlan.coverageEndDate;
                        if (planCoverageEndDate) {
                            eDate = new Date(Date.parse(planCoverageEndDate));
                        }
                        var planCoverageCancelDate = selectedPlan.coverageCancelDate;
                        if (planCoverageCancelDate) {
                            cDate = new Date(Date.parse(planCoverageCancelDate));
                        }

                        // TODO: This date check needs to be revised.
                        // just to be safe, we need to check the cancel date vs. the coverage end date, and use the one that is later.
                        if (eDate && cDate) {
                            if (eDate <= cDate) {
                                laterDate = cDate;
                            } else {
                                laterDate = eDate;
                            }
                        } else {
                            laterDate = eDate;
                        }
                        finalDate = laterDate;
                        finalDate.setDate(finalDate.getDate() + gracePeriod);

                        // if a plan has multiple products, if one of them is in the active coverage window, we set the pcpActiveWindow flag to true.
                        if (finalDate <= currentDate) {
                            //TODO: This used to be a console.log. This if statement is ridiculous.
                        } else {
                            pcpActiveWindow = true;
                        }

                        // now let's set the PCP cookie and hide or show the PI link based on LOB and the active coverage window.
                        if (selectedPlan.lob.toUpperCase() === 'HMO' || selectedPlan.lob.toUpperCase() === 'POS') {
                            cookies.put("PCP", true); // this is PCP
                            if (pcpActiveWindow) {
                                $scope.showPhysicianInformation = true;

                                piLink = '/login/#/account/pi';

                                // piLink = teamsite.get("MBR_PHYSICIANINFO_LEFTNAV_LINK");
                            }
                        }
                        if ((lob) && (lob.toUpperCase() === 'PPO' || lob.toUpperCase() === 'EPO')) {
                            cookies.put("PCP", false); // this is POR, and we check for active coverage differently
                            if (hasActivePlans) {
                                $scope.showPhysicianInformation = true;

                                piLink = '/login/#/account/pi';

                                // piLink = teamsite.get("MBR_PHYSICIANINFO_LEFTNAV_LINK");
                            }
                        }
                        if (selectedPlan.benefitPlanName.toUpperCase() === 'IPA') {
                            cookies.put("PCP", true); // this is PCP
                            if (pcpActiveWindow) {
                                $scope.showPhysicianInformation = true;

                                piLink = '/login/#/account/pi';

                                // piLink = teamsite.get("MBR_PHYSICIANINFO_LEFTNAV_LINK");
                            }
                        }
                        if ((lob) && (lob.toUpperCase() === 'GPO')) {
                            cookies.put("PCP", true); // This is PCP
                            if (pcpActiveWindow) {
                                $scope.showPhysicianInformation = true;

                                piLink = '/login/#/account/pi';

                                // piLink = teamsite.get("MBR_PHYSICIANINFO_LEFTNAV_LINK");
                            }
                        }
                        if (theBrand === "mincr") {
                            // MN Core does not get POR
                            if ($scope.showPhysicianInformation && lob &&
                                (lob.toUpperCase() === 'PPO' || lob.toUpperCase() === 'EPO')) {
                                $scope.showPhysicianInformation = false;
                                piLink = '';
                            }
                        }

                        var getAge = function (dob) {
                            var today = new Date();
                            var birthday = new Date(dob);
                            var age;

                            today.setHours(0);
                            today.setMinutes(0);
                            today.setSeconds(0);
                            today.setMilliseconds(0);
                            
                            age = ((today - birthday) / (1000 * 60 * 60 * 24 * 365));
                            
                            return age
                        };

                        if (selectedPlan.hcrProduct) {
                            var age = getAge(selectedPlan.memberList[0].contact.birthDate);
                            //console.log("tobacco age",age);
                            if (theBrand === 'ibccr' || theBrand === 'ibcah') {
                                if (age >= 21) {
                                    $scope.showTobaccoInformation = true;
                                }
                            } else {
                                if (selectedPlan.effectuatedDate && age >= 18) {
                                    $scope.showTobaccoInformation = true;
                                }
                            }
                        }
                    }


                    // =========================Other Insurance=========================
                    if ((selectedPlan.benefitPlanType.toUpperCase() === 'MEDICAL') && (selectedPlan.seniorProductIndicator === 'false' && planInfo.medicareIndicator === 'false') || (planInfo.medigapIndicator === 'true')) {
                        $scope.showOtherInsurance = true;
                    }

                    // =========================Member Profile=========================
                    if ((selectedPlan.seniorProductIndicator === 'false' || selectedPlan.isBlueExtraBasic === 'true' ||
                        selectedPlan.isBlueExtraPlus === 'true') && theBrand !== "mincr") {
                        $scope.showMemberProfile = true;
                    }

                    // =========================Contact Preference=========================
                    if (selectedPlan.isBlueExtraBasic === 'true' || selectedPlan.isBlueExtraPlus === 'true') {
                        $scope.showContactPreference = false;
                    }
                }
            }

            // =========================Push links to sidebar=========================
            $scope.sidebar.links = [];

            if (moreThanOneTimeFrame) {
                $scope.sidebar.links.push({
                    heading: '<span data-teamsite="MBR_MBRINFO_LEFTNAV_HEADING"></span>',
                    label: '<span data-teamsite="MBR_MBRINFO_LEFTNAV_TITLE"></span>',
                    href: '/login/#/account/membership-information',
                    state: "account.membershipInformation",
                    description: '<span data-teamsite="MER_MBRINFO_LEFTNAV"></span>',
                    refineTemplate: 'pages/account/membership-information/refine-membership-information.html',
                    refineController: 'pages.account.membership-information'
                });
            } else {
                $scope.sidebar.links.push({
                    heading: '<span data-teamsite="MBR_MBRINFO_LEFTNAV_HEADING"></span>',
                    label: '<span data-teamsite="MBR_MBRINFO_LEFTNAV_TITLE"></span>',
                    href: '/login/#/account/membership-information',
                    state: "account.membershipInformation",
                    description: '<span data-teamsite="MER_MBRINFO_LEFTNAV"></span>'
                });
            }

            if ($scope.showContactInformation) {
                $scope.sidebar.links.push({
                    heading: '<span data-teamsite="MBR_CNTCTINFO_LEFTNAV_HEADING"></span>',
                    label: '<span data-teamsite="MBR_CNTCTINFO_LEFTNAV_TITLE"></span>',
                    state: "account.contactInformation",
                    description: '<span data-teamsite="MER_CNTCTINFO_LEFTNAV"></span>'
                });
            }
            if ($scope.showPasswordSecurity) {
                $scope.sidebar.links.push({
                    heading: '<span data-teamsite="MBR_PSWDSEC_LEFTNAV_HEADING"></span>',
                    label: '<span data-teamsite="MBR_PSWDSEC_LEFTNAV_TITLE"></span>',
                    state: "account.passwordSecurity",
                    description: '<span data-teamsite="MER_PSWDSEC_LEFTNAV"></span>'
                });
            }
            if ($scope.showContactPreference) {
                $scope.sidebar.links.push({
                    // heading: '<span data-teamsite="MBR_CNTCTPREF_LEFTNAV_HEADING"></span>', //No teamsite content
                    heading: 'Set Contact Preferences',
                    label: '<span data-teamsite="MBR_CNTCTPREF_LEFTNAV_TITLE"></span>',
                    state: "account.contactPreferences",
                    description: '<span data-teamsite="MER_CNTCTPREF_LEFTNAV"></span>'
                });
            }
            if ($scope.showMemberProfile && theBrand !== 'wybcbs') {
                $scope.sidebar.links.push({
                    heading: '<span data-teamsite="MBR_MEMBERPROFILE_LEFTNAV_HEADING"></span>',
                    label: '<span data-teamsite="MBR_MEMBERPROFILE_LEFTNAV_TITLE"></span>',
                    state: "account.memberProfile",
                    description: '<span data-teamsite="MBR_MEMBERPROFILE_LEFTNAV_DESC"></span>'
                });
            }
            if ($scope.showTobaccoInformation && theBrand !== 'wybcbs' && theBrand !== 'ndbcbs') {
                $scope.sidebar.links.push({
                    heading: '<span data-teamsite="MER_TOBACCOINFO_LEFTNAV_HEAD"></span>',
                    label: '<span data-teamsite="MER_TOBACCOINFO_LEFTNAV_TITLE"></span>',
                    state: "account.tobaccoInformation",
                    description: '<span data-teamsite="MER_TOBACCOINFO_LEFTNAV_DESC"></span>'
                });
            }
            if ($scope.showOtherInsurance) {
                $scope.sidebar.links.push({
                    heading: '<span data-teamsite="MBR_COB_LeftNav_Headline"></span>',
                    label: '<span data-teamsite="MBR_COB_LeftNav_Title"></span>',
                    state: "account.otherInsurance",
                    description: '<span data-teamsite="MBR_COB_LeftNav_Description"></span>'
                });
            }
            if ($scope.showPartnerProducts) {
                $scope.sidebar.links.push({
                    heading: 'Partner Products',
                    label: 'Partner Products',
                    state: "account.partnerProducts",
                    description: '<span>Partner products description here.</span>'
                });
            }
            if ($scope.showTermsAndConditions) {
                $scope.sidebar.links.push({
                    heading: 'Terms and Conditions',
                    label: 'Terms and Conditions',
                    state: "account.termsAndConditions",
                    description: '<span>Terms and conditions description here.</span>'
                });
            }

            if ($scope.showViewIdCard) {
                $scope.sidebar.links.push({
                    heading: 'View ID Card',
                    label: 'View ID Card',
                    state: "account.viewIdCard",
                    description: '<span>View ID card description here.</span>'
                });
            }
            if (($scope.showPhysicianInformation) && theBrand !== 'wybcbs' && theBrand !== 'ndbcbs') {
                $scope.sidebar.links.push({
                    heading: 'Physician Information',
                    label: 'Physician Information',

                    state: 'physicianInformation',

                    href: piLink
                });}
            //} else if (theBrand.toLowerCase() === 'ndbcbs') {
            //    $scope.sidebar.links.push({
            //        heading: 'Physician Information',
            //        label: 'Physician Information',
            //
            //        state: 'physicianInformation',
            //
            //        href: piLink
            //    });
            //}
            if (theBrand.toLowerCase() === 'ndbcbs') {
                $scope.sidebar.links.push({
                        label: '<span data-teamsite="MBR_HIPAAAUTH_LEFTNAV_TITLE"></span>',
                        // href: '#/HIPAA'
                        state: 'HIPAA'
                    },
                    {
                        label: '<span data-teamsite="MBR_PRENATALPLUS_LEFTNAV_TITLE"></span>',
                        // href: '#/prenatal'
                        state: 'prenatal'
                    });
            }


            //console.log("THE STATE", $state.current.name);
            

        }
    ]);

    return [{
        state: "account",
        postPassword: true,
        appConfigMaintenanceApp: 'ACCOUNT_SETTINGS',
        AppConfigMaintenanceKey: 'FULL_MAINTENANCE',
        url: "/account",
        views: {
            abstract: true,
            'body@': {
                template: bootstrap.templates['page-templates/categoryCustom.html'],
                controller: "pages.account"
            }
        },
        "content@account": {
            template: require('pages/account.html'),
            controller: "pages.account"
        }
    },
        {
            state: "account.membershipInformation",
            postPassword: true,
            appConfigMaintenanceApp: 'ACCOUNT_SETTINGS',
            appConfigMaintenanceKey: 'MEMBERSHIP_INFORMATION_MAINTENANCE',
            url: "/membership-information",
            views: {
                "content@account": {
                    template: require('pages/account/membership-information/membership-information.html'),
                    controller: "pages.account.membership-information"
                }
            }
        },
        {
            state: "account.contactInformation",
            postPassword: true,
            appConfigMaintenanceApp: 'ACCOUNT_SETTINGS',
            appConfigMaintenanceKey: 'CONTACT_INFORMATION_MAINTENANCE',
            url: "/contact-information",
            views: {
                "content@account": {
                    template: require('pages/account/contact-information/contact-information.html'),
                    controller: "pages.account.contact-information"
                }
            }
        },
        {
            state: "account.passwordSecurity",
            postPassword: true,
            //appConfigMaintenanceApp: 'ACCOUNT_SETTINGS',
            //appConfigMaintenanceKey: 'PASSWORD_SECURITY_MAINTENANCE',
            url: "/password-security",
            views: {
                "content@account": {
                    template: require('pages/account/password-security/password-security.html'),
                    controller: "pages.account.password-security"
                }
            }

        },
        {
            state: "account.contactPreferences",
            postPassword: true,
            appConfigMaintenanceApp: 'ACCOUNT_SETTINGS',
            appConfigMaintenanceKey: 'CONTACT_PREFERENCES_MAINTENANCE',
            url: "/contact-preferences",
            views: {
                "content@account": {
                    template: require('pages/account/contact-preferences/contact-preferences.html'),
                    controller: "pages.account.contact-preferences"
                }
            }
        },
        {
            state: "account.memberProfile",
            postPassword: true,
            appConfigMaintenanceApp: 'ACCOUNT_SETTINGS',
            appConfigMaintenanceKey: 'MEMBER_PROFILE_MAINTENANCE',
            url: "/member-profile",
            views: {
                "content@account": {
                    template: require('pages/account/member-profile/member-profile.html'),
                    controller: "pages.account.member-profile"
                }
            }
        },

        {
            state: "account.partnerProducts",
            postPassword: true,
            appConfigMaintenanceApp: 'ACCOUNT_SETTINGS',
            appConfigMaintenanceKey: 'PARTNER_PRODUCTS_MAINTENANCE',
            url: "/partner-products-and-services-authorization",
            views: {
                "content@account": {
                    template: require('pages/account/partner-products/partner-products.html'),
                    controller: "pages.account.partner-products"
                }
            }
        },
        {
               state: "physicianInformation",

               postPassword: true,
               appConfigMaintenanceApp: 'ACCOUNT_SETTINGS',
               appConfigMaintenanceKey: 'PHYSICIAN_INFORMATION_MAINTENANCE',
               url: "/pi",
               views: {
                   "body@": {
                       template: require('pages/account/physician-information/physician-information.html'),
                       controller: "pages.account.physician-information"
                   }
               }
        },
        {
            state: "account.termsAndConditions",
            postPassword: true,
            appConfigMaintenanceApp: 'ACCOUNT_SETTINGS',
            appConfigMaintenanceKey: 'TERMS_AND_CONDITIONS_MAINTENANCE',
            url: "/terms-and-conditions",
            views: {
                "content@account": {
                    template: require('pages/account/terms-and-conditions/terms-and-conditions.html'),
                    controller: "pages.account.terms-and-conditions"
                }
            }
        },
        {
            state: "account.tobaccoInformation",
            postPassword: true,
            appConfigMaintenanceApp: 'ACCOUNT_SETTINGS',
            appConfigMaintenanceKey: 'TOBACCO_INFORMATION_MAINTENANCE',
            url: "/tobacco-information",
            views: {
                "content@account": {
                    template: require('pages/account/tobacco-information/tobacco-information.html'),
                    controller: "pages.account.tobacco-information"
                }
            }
        },
        {
            state: "account.viewIdCard",
            postPassword: true,
            appConfigMaintenanceApp: 'ACCOUNT_SETTINGS',
            appConfigMaintenanceKey: 'VIEW_ID_CARD_MAINTENANCE',
            url: "/view-id-card",
            views: {
                "content@account": {
                    template: require('pages/account/view-id-card/view-id-card.html'),
                    controller: "pages.account.view-id-card"
                }
            }
        }
    ];
};