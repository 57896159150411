module.exports = function (app) {
    app.controller('pages.account.physician-information', ['$scope','$cookies','brand','services',
        function ($scope,cookies,brand,services) {

            //content variables
            var MAINDEFINITION_ND = "Your Physician of Record or Primary Care Provider (PCP) takes care of your routine/preventive care, like physicals and screenings and helps to manage your health care.";
            var PCP_MAINDEFINITION = "Your Primary Care Physician (PCP) is a doctor who takes care of routine or preventive care, like physicals and immunizations, and coordinates any other care you need.";
            var POR_MAINDEFINITION = "Your Physician of Record takes care of routine/preventive care, like physicals and immunizations, and coordinates any other care you may need.";

            var piBrand = brand.current.sharedBrandCode.toLowerCase();
            var pcpFlag = (cookies.get('PCP') == "true") ? "PCP" : "POR";

            $scope.pcpCheck = cookies.get('PCP');
            $scope.mainHeader = (pcpFlag =="PCP")? "ABOUT YOUR PRIMARY CARE PHYSICIAN (PCP)":"Physician of Record:";
            $scope.mainDefinition = (pcpFlag =="PCP")? (piBrand ==='ndbcbs'? MAINDEFINITION_ND : PCP_MAINDEFINITION) : (piBrand ==='ndbcbs'? MAINDEFINITION_ND : POR_MAINDEFINITION);

            $scope.isAriaExpanded = function (user) {
                return !!(user && user.drawerOpen && user.drawerOpen === 'open');
            };

            services.rbsmbl.memberInfo().fetch().then(function (fullMemberInfo) {

                var typeMember = fullMemberInfo.data.payload.emktUserType;
                var groupNo = fullMemberInfo.data.payload.clientList[0].groupList[0].groupNumber;
                var ums = fullMemberInfo.data.payload;
                var fullMemberList =[];
                var fullMemberList = services.rbsmbl.memberInfo().getUniqueMemberNamesArray(ums);

                var clientLen = fullMemberList.length;
                if(clientLen == 1 && fullMemberList[0].entireMemberObj.relationship == "Subscriber") {
                    var loopSubDepTimes = 1;
                } else {
                    if(clientLen == 1 && (fullMemberList[0].entireMemberObj.relationship == "Dependent" || fullMemberList[0].entireMemberObj.relationship == "Spouse")) {
                        var loopSubDepTimes = 1;
                    } else {
                        var loopSubDepTimes = 2;
                    }
                }
            });



        }
    ]);
}