module.exports = function(app) {
    app.service('ssoHelper', ['$q', '$cookies', 'services', 'session', 'vendorSSOUtility',
        function($q, $cookies, services, session, vendorSSOUtility) {
            this.chcSsoCall = function(targetType){
                targetType = targetType || "landingPage";

                var portalSelectedPlan = services.rbsmbl.memberInfo().getPortalSelectedPlan(session.memberInfo),
                    clientNumber = $cookies.get('mbrClientNumber'),
                    brand = $cookies.get('umsBrand').toLowerCase();

                console.log("client number: ", clientNumber);
                console.log("group number: ", portalSelectedPlan.groupNumber);
                var targetLocation = '_blank',
                    ssoParameters = {
                        "clientNumber": clientNumber,
                        "groupNumber": portalSelectedPlan.groupNumber,
                        "targetType": targetType,
                        "vendorName": "",
                        "category":"savingsAdvisor",
                        "brand": brand
                    };

                var sitePromotionStr = "";

                if (brand && brand == 'mincr') {
                    sitePromotionStr = '?cm_sp=Change%20Healthcare-_-Compare%20Cost%20For%20Care-_-Compare%20Cost%20For%20Care';
                }

                vendorSSOUtility.performSSO(ssoParameters, targetLocation,sitePromotionStr);

            };
        }
    ]);
};