module.exports = function(app) {
    app.controller('pages.claims.healthPlans', ['$scope', '$rootScope', '$cookies', 'benefitsDeserializer', 'services', 'coremetrics', 'updateVisited',
        function($scope, $rootScope, $cookies, benefitsDeserializer, services, coremetrics, updateVisited) {
            $scope.headings = 'BENEFITS OVERVIEW';
            $scope.sidebar.data.viewState = 'loading';

            $scope.nonDirectiveButtonClicked = function() {
                $scope.benefitsOverviewDrawer = !$scope.benefitsOverviewDrawer;
            }
            $scope.benefitsOverviewDrawer = false;

            services.rbsmbl.memberInfo().fetch().then(function (response) {

                    console.log('memberInfo response', response);

                    var b = benefitsDeserializer(response);
                    $scope.sidebar.data.benefits = b.benefitsData;
                    $scope.sidebar.data.benefits.curYear = 0;
                    $scope.sidebar.data.benefits.filterPlan = '';
                    $scope.sidebar.data.benefits.yearSelect = function(year){
                        $scope.sidebar.data.benefits.filterPlan = '';
                        $scope.sidebar.data.accumsFilter = (year) ? 'previousPlans' : 'activePlans';
                    };
                    console.log('benefits', $scope);
                    // console.log('benefits', $scope.sidebar.data.benefits);
                    if ($scope.sidebar.data.benefits.error){
                        $scope.sidebar.data.viewState = 'error';
                    } else {
                        $scope.sidebar.data.viewState = 'success';
                    }

                }, function (error){
                $scope.sidebar.data.viewState = 'error';
                    console.error('memberInfo failed with error', error);
                }
            );

            updateVisited.mhsLanding();
        }
    ]);
};