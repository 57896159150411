module.exports = function(app) {
    app.factory('comboPDFSubmitService', ['services','session',
        function(services,session) {

            var submitClaimsMatchData = function(claimsMatchPayload) {

                // this is necessary to test this service locally

                // get and fix the host string
                var getHost = document.location.host,
                    getDevDomain = getHost.substr(0,1);
                if (getDevDomain === 'd') {
                    getHost = "t" + getHost.substr(1);
                }

                var tempParams = {
                    appId : "chmhom",
                    fromDP : (session && session.data && session.data.userName) ? session.data.userName : null,
                    clientHost : getHost
                };

                return services.rbsmbl.submitClaimsMatchData().fetch(claimsMatchPayload,tempParams).then(
                    function(response) {
                        return response;
                    },
                    function(err) {
                        console.error('fetch failed in getData', err);
                        return err;
                    }
                );
            };

            return {
                submitClaimsMatchData: submitClaimsMatchData
            };
        }
    ]);
};