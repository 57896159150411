/**
* @file Display rules for Savings Opportunities cost saving tool.
* @author J.T. Gralka <James.Gralka@Highmark.com>
*/

module.exports = function (app) {
	app.service("savOppService", ["services", "sessionData", "teamsite", "vendorSSOUtility", "$q", function (services, sessionData, teamsite, vendorSSOUtility, $q) {
		var selectedPlan = services.rbsmbl.memberInfo().getPortalSelectedPlan();
		var brand = sessionData.memberInfo.brand || "";

		function init() {
			var deferred = $q.defer();

			function getClientNumber(groupNumber) {
				var clientNumber = false;

				var clientList = [];
				if (sessionData && sessionData.memberInfo
					&& sessionData.memberInfo.clientList) {
						clientList = sessionData.memberInfo.clientList;
				}

				clientList.forEach(function (client) {
					var groupList = client && client.groupList ? client.groupList : [];
					var currentClientNumber = client.clientNumber;

					groupList.forEach(function (group) {
						if (group.groupNumber === groupNumber) {
							return clientNumber = currentClientNumber;
						}
					});

					if (clientNumber) {
						return;
					}
				});

				return clientNumber;
			};

			var res = {
				"shouldDisplay": false,
				"ssoParams": {
					"category": "savingsAdvisor",
					"vendor": "",
					"targetType": "landingPage",
					"groupNumber": selectedPlan.groupNumber || "",
					"clientNumber": getClientNumber(selectedPlan.groupNumber) || "",
					"brand": brand
				}
			};

			if (sessionData.loggedIn) {
				if (selectedPlan.chcIndicator === "true") {
					if (brand === "MINCR") {
						res.shouldDisplay = true;
					} else if (sessionData.isSubscriber) {
						res.shouldDisplay = true;
					}
				}
			}

			deferred.resolve(res);

			return deferred.promise;
		}

		/**
		* Determines whether or not to show the Savings Opportunities tool.
		* @returns {Promise} the promise of running the display logic for Rx Cost comparison.
		*/
		this.shouldDisplay = function () {
			var deferred = $q.defer();

			init().then(function (response) {
				deferred.resolve(response.shouldDisplay);
			}, function (error) {
				deferred.reject(error);
			});

			return deferred.promise;
		};

		/**
		* Performs the Change Health Care SSO request.
		* @throws Will throw an error if plan information or display logic doesn't resolve.
		*/
		this.doSSORequest = function () {
			init().then(function (response) {
				if (response.shouldDisplay) {
					var ssoWindowName = response && response.ssoParams && response.ssoParams.vendorName ? ('chc_' + response.ssoParams.vendorName).toLowerCase() : "_blank";
					var sitePromotionStr = "";

					if (brand && brand == 'mincr') {
						sitePromotionStr = '?cm_sp=Change%20Healthcare-_-Compare%20Cost%20For%20Care-_-Compare%20Cost%20For%20Care';
					}

					vendorSSOUtility.performSSO(response.ssoParams, ssoWindowName, sitePromotionStr, true, false);
				}
			}, function (error) {
				throw new Error()
			})
		};
	}]);
};
