module.exports = function(app) {
    app.directive('wellnessConnected', function() {

        return {
            restrict: 'A',
            scope:false,
            template: require('directives/wellnessConnected.html')

        };
    });
}
