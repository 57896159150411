module.exports = function(app) {
    "use strict";
    app.service('waysToSaveUtil', ['$q', '$filter', 'services', 'sessionData', 'finder-logic2', 'appConfig',
        function($q, $filter, services, sessionData, finderLogic, appConfigService) {

            var waysToSaveUtil = this;

            this.suppressionCodes = {};

            this.getTierText = function(tierCode){
                // TODO : move these codes logic to BRE/CMS
                switch(tierCode){
                    case "INS":
                        return "Standard";
                    case "INE":
                        return "Enhanced";
                    case "INP":
                    case "PRE":
                        return "Preferred";
                    case "INN":
                    case "INA":
                    case "INC":
                    case "INH":
                    case "INW":
                    case "INX":
                        return "In-Network";
                    case "OON":
                        return "Out of Network";
                    default:
                        return "";
                }
            };

            this.hasTiers = function(costShareBenefit){
                return costShareBenefit && costShareBenefit.coverageSummary && costShareBenefit.coverageSummary.numberOfTiers &&
                    Number(costShareBenefit.coverageSummary.numberOfTiers > 1);
            };

            this.getBestTierAvailable = function(csbData){
                return csbData && csbData.coverageSummary && csbData.coverageSummary.costShareBenefits &&
                    csbData.coverageSummary.costShareBenefits.tierCode;
            };

            this.memberEligibleForWaysToSave = function(csbData){ //use to check if member/plan/whatever is eligible to get ways to save messages
                return !isHomeHost(csbData);
            };

            this.claimEligibleForWaysToSave = function(claim){
                return !isDeniedClaim(claim) && !isAmbulatoryClaim(claim) && !isEmergencyClaim(claim);
            };

            this.getCsbParams = function(){
                var deferred = $q.defer(),
                    csbParams = {},
                    brand = sessionData.memberInfo && sessionData.memberInfo.brand,
                    currentProduct = finderLogic.portalSelectedPlanSpecificProductByLatestCoverageEffectiveDate("Medical"),
                    coverageEndDate = $filter('date')(new Date(currentProduct.coverageEndDate), 'MMddyyyy');

                services.rbsmbl.vsbpPlans().fetch(brand).then(
                    function(response){
                        var selectedPlan = response.data.payload.planInfo.plans.selected,
                            endDate = (selectedPlan.memberCancellationDate) ? selectedPlan.memberCancellationDate.split("/").join("") : coverageEndDate;

                        csbParams = {
                            brand: brand,
                            payload: {
                                // TODO : Set Proper values here from curGrp and curPrd
                                "startDate": selectedPlan.memberEffectiveDate.split("/").join(""),
                                "endDate": endDate,
                                "groupNum": selectedPlan.groupNumber,
                                "lob": "M",
                                "productId": currentProduct.productNumber,
                                "memberPin": currentProduct.memberList[0].pinNumber
                            }
                        };

                        deferred.resolve(csbParams);
                    },
                    function(error){
                        console.debug("Error calling vsbpPlans service =>", error);
                        deferred.reject();
                    }
                );

                return deferred.promise;
            };

            this.checkForLowerTierOrOON = function(claimNetwork, claimTier, bestTier){
                var responseObj = {
                    usedOONProvider: false,
                    usedLowerTierProvider: false
                };

                switch(claimNetwork){
                    case "INN":
                        responseObj.usedLowerTierProvider = usedLowerTierThanAvailable(claimTier, bestTier);
                        break;
                    case "OON":
                        responseObj.usedOONProvider = true;
                        break;
                }

                return responseObj;
            };

            this.showWaysToSave = function(){
                var appConfig = sessionData && sessionData.appConfig && sessionData.appConfig.CLAIMS;

                if(appConfig){
                    if (appConfigService.checkBooleanValue(appConfig, "SHOW_WAYS_TO_SAVE")) {
                        return true;
                    } else if (appConfigService.checkBooleanValue(appConfig, "SUPPRESS_WAYS_TO_SAVE")) {
                        return false;
                    }

                    var memberObj = services.rbsmbl.memberInfo().getClientGroupMemberInfo(sessionData.memberInfo);

                    if (memberObj.umi && memberObj.clients && memberObj.groups) {
                        if (appConfigService.checkKeysByClientGroupMember(appConfig, memberObj.umi, memberObj.clients, memberObj.groups, "SUPPRESS_WAYS_TO_SAVE")) {
                            return false;
                        }

                        else if (appConfigService.checkKeysByClientGroupMember(appConfig, memberObj.umi, memberObj.clients, memberObj.groups, "SHOW_WAYS_TO_SAVE")) {
                            return true;
                        }
                    }
                }

                return false;
            };

            var usedLowerTierThanAvailable = function(claimTier, bestTier){
                var usedLowerTierProvider = false;

                switch(claimTier){
                    case "INP":
                    case "PRE":
                        break;
                    case "INE":
                        if(bestTier === "INP" || bestTier === "PRE"){
                            usedLowerTierProvider = true;
                        }
                        break;
                    default:
                        if(bestTier === "INE" || bestTier === "INP" || bestTier === "PRE"){
                            usedLowerTierProvider = true;
                        }
                        break;
                }

                return usedLowerTierProvider;
            };

            var isHomeHost = function(csbData){
                return csbData && csbData.coverageSummary && csbData.coverageSummary.isHomeHost;
            };

            var isAmbulatoryClaim = function(claim){
                var jsonString = sessionData && sessionData.appConfig && sessionData.appConfig.CLAIMS &&
                    sessionData.appConfig.CLAIMS.SHOW_WAYS_TO_SAVE && sessionData.appConfig.CLAIMS.SHOW_WAYS_TO_SAVE.overrideValue;
                if(jsonString){
                    var codeArray = getSuppressionCodes(jsonString, "ambulatoryCodes");
                    return doesArrayContainValue(codeArray, claim.adjustedProcRevenueCodeList);
                }
                return false;
            };

            var isEmergencyClaim = function(claim){
                var jsonString = sessionData && sessionData.appConfig && sessionData.appConfig.CLAIMS &&
                    sessionData.appConfig.CLAIMS.SHOW_WAYS_TO_SAVE && sessionData.appConfig.CLAIMS.SHOW_WAYS_TO_SAVE.overrideValue;
                if(jsonString){
                    var codeArray = getSuppressionCodes(jsonString, "emergencyCodes");
                    return doesArrayContainValue(codeArray, claim.adjustedProcRevenueCodeList);
                }
                return false;
            };

            var isDeniedClaim = function(claim){
                return claim.bilgProvClmStaCd === "3";
            };

            var getSuppressionCodes = function(jsonString, keyName){
                if(angular.equals({}, waysToSaveUtil.suppressionCodes)){
                    try{
                        waysToSaveUtil.suppressionCodes = JSON.parse(jsonString);
                    }
                    catch(e){
                        waysToSaveUtil.suppressionCodes = {};
                    }
                }
                if(typeof(waysToSaveUtil.suppressionCodes === "object") && waysToSaveUtil.suppressionCodes[keyName]){
                    return waysToSaveUtil.suppressionCodes[keyName];
                }
                else{
                    return [];
                }
            };

            function doesArrayContainValue(array, value){
                if(Array.isArray(value)){
                    for(var i = 0; i < array.length; i++){
                        if(value.includes(array[i]) || value.includes(array[i].toUpperCase() || array[i].toLowerCase())){
                            return true;
                        }
                    }
                }
                else if(typeof(value) === "string" || typeof(value) === "number"){
                    return array.includes(value) || array.includes(value.toUpperCase()) || array.includes(value.toLowerCase());
                }

                return false;
            }

    }]);
};
