//https://....../login/#/coverage/coverage-summary
    module.exports = function (app){


        app.controller('pages.coverage.coverageSummary',['$scope','services','session','teamsite','finder-logic2','$cookies', 'brand','sessionData',
            function($scope,services,session,teamsite,finderLogic,cookies,brand, sessionData){

                $scope.noShow = false;
                var medCovs = [] , drugCovs = [] , dentalCovs = [] , visionCovs = [], wellnessCovs = [],  SpendingCovs = [] ;
                var arrrWellnessCovNames = [] , arrSpendingCovNames = [] ;

                var base64ToArrayBuffer = function(convertBase64PDF)
                {
                    var binaryString = window.atob(convertBase64PDF);
                    var binaryLen = binaryString.length;
                    var conversionBytes = new Uint8Array(binaryLen);
                    for (var i = 0; i < binaryLen; i++){
                        var ascii = binaryString.charCodeAt(i);
                        conversionBytes[i] = ascii;
                    }
                    return conversionBytes;
                };
                var saveByteArray = function(reportName, pdfArr)
                {
                    var blob = new Blob([pdfArr], {type: "application/pdf"});
                    var link = document.createElement('a');
                    var evtClick = document.createEvent('MouseEvents');
                    link.href = window.URL.createObjectURL(blob);
                    var fileName = reportName;
                    link.download = fileName;
                    evtClick.initEvent("click", true, true);
                    link.dispatchEvent(evtClick);
                };

                //Benefit booklet banner
                var hasCoverage = function (curGrp, strPlan) {
                    var bFound = false;
                    for (iPrdIdx = 0; iPrdIdx < curGrp.productList.length; ++iPrdIdx) {
                        var curPrd = curGrp.productList[iPrdIdx];
                        if (curPrd.benefitPlanType === strPlan) {
                            bFound = true;
                            break;
                        }
                    }
                    return bFound;
                };
                var ums = sessionData.memberInfo;
                var curClnt = ums.clientList[0];
                var curGrp = finderLogic.getPortalSelectedPlan(ums);
                if(hasCoverage(curGrp,"Medical")){
                    var curPrd = finderLogic.portalSelectedPlanSpecificProductByLatestCoverageEffectiveDate("Medical");
                }
                else if(hasCoverage(curGrp,"Drug")){
                    var curPrd = finderLogic.portalSelectedPlanSpecificProductByLatestCoverageEffectiveDate("Drug");
                }
                else if(hasCoverage(curGrp,"Dental")){
                    var curPrd = finderLogic.portalSelectedPlanSpecificProductByLatestCoverageEffectiveDate("Dental");
                }
                else if(hasCoverage(curGrp,"Vision")){
                    var curPrd = finderLogic.portalSelectedPlanSpecificProductByLatestCoverageEffectiveDate("Vision");
                }



                var bbParams = {
                    brand: ums.brand
                };
                //alertCounterReq - Y is to show banner ; can be replaced with N to hide banner..
                bbParams.payload = {
                    "visionCoverage": hasCoverage(curGrp, "Vision"),
                    "icisClientId": curClnt.clientId,
                    "icisClientNumber": curClnt.clientNumber,
                    "groupNumber": curGrp.groupNumber,
                    "medicalCoverage": hasCoverage(curGrp, "Medical"),
                    "drugCoverage": hasCoverage(curGrp, "Drug"),
                    "coverageCancelDate": (curPrd.memberList[0].coverageCancellationDate != "" ? curPrd.memberList[0].coverageCancellationDate : "00/00/0000"),
                    "curEffDate": (curPrd.coverageBeginDate != "" ? curPrd.coverageBeginDate : "00/00/0000"), // thePlan.coverageBeginDate,..
                    "dentalCoverage": hasCoverage(curGrp, "Dental"),
                    "alertCounterReq": "Y"
                };
                services.rbsmbl.BenefitBooklet().fetch(bbParams).then(
                    function (data) {
                        $scope.BookletChanged = data && data.data && data.data.payload && data.data.payload.alertCounter;
                        return $scope.BookletChanged;
                    });
                $scope.benefitBookletChanged = function() {
                    var bbChanged = false;
                    if ($scope.BookletChanged === 'true') {
                        bbChanged = true;
                    }
                   return bbChanged;
                };

                $scope.closeBenefitBookletBanner = function() {
                    $scope.showBenefitBookletBanner = false;
                    $scope.benefitBookletBannerCoverage =false;
                };

                services.rbsmbl.memberInfo().fetch().then(function (fullMemberInfo) {

                    finderLogic.setFullMemberInfo(fullMemberInfo.data.payload);

                    var ums = fullMemberInfo.data.payload;
                    console.log("ums",ums);
                    //var curClnt = ums.clientList[0];.js 
                    var currGrp = finderLogic.getPortalSelectedPlan(fullMemberInfo).groupNumber;

                    var currentUserBrand = brand.current.sharedBrandCode.toLowerCase();
                    // Based on Brand show cms banner
                    if (currentUserBrand !== 'mincr' && currentUserBrand !== 'ndbcbs' && currentUserBrand !== 'wybcbs' ) {
//                                var priorYear = new Date().getFullYear() - 1;
                        var today = new Date();
                        var dd = today.getDate();
                        var mm = today.getMonth()+1; //January is 0!
                        var yyyy = today.getFullYear();

                        if(dd<10) {
                            dd = '0' + dd
                        }

                        if(mm<10) {
                            mm = '0' + mm
                        }
                        // formats date and sets beginDate as prior year
                        var today = mm + '/' + dd + '/' + yyyy;
                        var beginDate = "10/01/" + yyyy;

                        var cmsParams = {
                            "umi": ums.umi,
                            "beginDate": beginDate,
                            "endDate": today,
                            "groupNumber": currGrp,
                            "documentType": "CMSDOC"
                        };

                        services.rbsmbl.retrieveCMSpdf().getCMSToken(cmsParams).then(function (resp) {
                            var result = resp.data;
                            var convertResult = {};
                            if (resp.status === 200 && resp.statusText !== '') {
                                convertResult = angular.fromJson(result);
                                console.log("convert result", convertResult);
                                if (convertResult.baseMessage != 500 && convertResult.onDemandDocuments != undefined && convertResult.onDemandDocuments.length !== 0) {
                                    $scope.showCMSBanner = true;
                                    $scope.showBothDocs = false;
                                    $scope.showCMSACA = false;
                                    $scope.showCMSTax = false;
                                    var renewalNotice = 0;
                                    var taxDoc = 0;
                                    //var grabACAToken;
                                    //var grabTaxToken = '';
                                    for (var cmsCount=0; cmsCount < convertResult.onDemandDocuments.length; cmsCount++) {
                                        var thisDoc = convertResult.onDemandDocuments[cmsCount];
                                        if (thisDoc.effective === 'true') {
                                            if (thisDoc.documentType === 'ACA Plan Information') {
                                                renewalNotice = 1;
                                                var grabACAToken = thisDoc.documentToken;

                                                //ACA PDF LOGIC

                                                $scope.grabACApdf = function(){
                                                    var pdfParams = {
                                                        "umi": ums.umi,
                                                        "documentToken": grabACAToken,
                                                        "groupNumber": currGrp,
                                                        "documentType": "CMSDOC"
                                                    };
                                                    services.rbsmbl.retrieveCMSpdf().getPDF(pdfParams).then(function (result) {
                                                        var pdfToConvert = result.data.onDemandDocuments[0].documentData;
                                                        var fileName = 'ACA Plan Information.pdf';
                                                        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                                                            var byteCharacters = atob(pdfToConvert);
                                                            var byteNumbers = new Array(byteCharacters.length);
                                                            for (var i =0; i < byteCharacters.length; i++){
                                                                byteNumbers[i] = byteCharacters.charCodeAt(i);
                                                            }
                                                            var byteArray = new Uint8Array(byteNumbers);
                                                            var blob = new Blob([byteArray], {type: 'application/pdf'});
                                                            window.navigator.msSaveOrOpenBlob(blob, fileName);
                                                        } else {
                                                            var pdfArr = base64ToArrayBuffer(pdfToConvert);
                                                            saveByteArray(fileName, pdfArr);
                                                        }
                                                    });
                                                };
                                            }
                                            if (thisDoc.documentType === 'Tax Credit Information') {
                                                taxDoc = 1;
                                                var grabTaxToken = thisDoc.documentToken;

                                                //TAX PDF LOGIC
                                                $scope.grabTaxPdf = function() {
                                                    var pdfParams = {
                                                        "umi": ums.umi,
                                                        "documentToken": grabTaxToken,
                                                        "groupNumber": currGrp,
                                                        "documentType": "CMSDOC"
                                                    };
                                                    services.rbsmbl.retrieveCMSpdf().getPDF(pdfParams).then(function (result) {
                                                        var pdfToConvert = result.data.onDemandDocuments[0].documentData;
                                                        var fileName = 'Tax Credit Information.pdf';
                                                        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                                                            var byteCharacters = atob(pdfToConvert);
                                                            var byteNumbers = new Array(byteCharacters.length);
                                                            for (var i =0; i < byteCharacters.length; i++){
                                                                byteNumbers[i] = byteCharacters.charCodeAt(i);
                                                            }
                                                            var byteArray = new Uint8Array(byteNumbers);
                                                            var blob = new Blob([byteArray], {type: 'application/pdf'});
                                                            window.navigator.msSaveOrOpenBlob(blob, fileName);
                                                        } else {
                                                            var pdfArr = base64ToArrayBuffer(pdfToConvert);
                                                            saveByteArray(fileName, pdfArr);
                                                        }
                                                    });
                                                };
                                            }

                                        }
                                    }

                                    if (renewalNotice === 1 && taxDoc === 0) {
                                        $scope.showCMSACA = true;
                                    } else if (renewalNotice === 0 && taxDoc === 1){
                                        $scope.showCMSTax = true;
                                    } else if (renewalNotice === 1 && taxDoc === 1){
                                        $scope.showBothDocs = true;
                                    } else {
                                        $scope.showCMSBanner = false;
                                        //if (sessionStorage.getItem("benefitBookletBannerCoverage")  === 'true' || sessionStorage.getItem("benefitBookletBannerCoverage") == undefined){
                                            $scope.showBenefitBookletBanner = true;
                                        //} else {
                                        //    this.model.showBenefitBookletBanner = false;
                                        //}
                                    }



                                } else {
                                    if ($scope.benefitBookletChanged()) {
                                        //if (sessionStorage.getItem("benefitBookletBannerCoverage") === 'true' || sessionStorage.getItem("benefitBookletBannerCoverage") == undefined){
                                            $scope.showBenefitBookletBanner = true;
                                        //} else {
                                        //    this.model.showBenefitBookletBanner = false;
                                        //}
                                        //this.model.$apply();
                                    }
                                    $scope.showCMSBanner = false;


                                }
                            } else {
                                $scope.showCMSBanner = false;

                            }
                        });
                    } else {
                        $scope.showCMSBanner = false;
                        if ($scope.benefitBookletChanged()) {
                            $scope.showBenefitBookletBanner = true;

                        }
                    }





                    $scope.closeMessage = function() {
                        $scope.showCMSBanner = false;
                        if ($scope.benefitBookletChanged()) {
                            //if (sessionStorage.getItem("benefitBookletBannerCoverage") === 'true' || sessionStorage.getItem("benefitBookletBannerCoverage") == undefined) {
                                $scope.showBenefitBookletBanner = true;
                            //} else {
                            //    this.showBenefitBookletBanner = false;
                            //}
                        } else {
                            $scope.showBenefitBookletBanner = false;
                        }
                    };


                    if ((cookies.get('mbrGroupNumber') || '') != '') {
                        for (var iClntIdx = 0; iClntIdx < ums.clientList.length; ++iClntIdx) {
                           var curClnt = ums.clientList[iClntIdx];
                            for (var iGrpIdx = 0; iGrpIdx < curClnt.groupList.length; ++iGrpIdx) {
                                var curGrp = curClnt.groupList[iGrpIdx];


                                if (cookies.get('mbrGroupNumber') == curGrp.groupNumber)  {
                                    for (var iPrdIdx = 0; iPrdIdx < curGrp.productList.length; ++iPrdIdx) {
                                        var strBenefitPlanName = '' ;
                                        if((curGrp.productList[iPrdIdx].benefitPlanType === "Spending Accounts")||( curGrp.productList[iPrdIdx].benefitPlanType == "Wellness Programs")){
                                            strBenefitPlanName = curGrp.productList[iPrdIdx].benefitPlanName;
                                        }
                                        else {
                                            strBenefitPlanName = ( (curGrp.hiosName || '') != '' ? curGrp.qhpLevel + " - " + curGrp.hiosName : curGrp.productList[iPrdIdx].benefitPlanName) ;

                                        }
                                        var objProdPtr = {"ums": ums, "PlanName": strBenefitPlanName, "iClntIdx": iClntIdx, "iGrpIdx": iGrpIdx, "iPrdIdx": iPrdIdx};
                                        var curPBMIndicator = curGrp.pharmacyBenefitProviderIndicator;

                                        
                                        $scope.mbShow = false;
                                        $scope.mbHeader = "Need coverage info from a previous year?";

                                        if(ums.brand.toLowerCase() === 'mincr'){
                                            $scope.mbShow = true;
                                            $scope.mbText = "Please call the customer service number on the back of your member ID card.";
                                        
                                        }else if(ums.brand.toLowerCase() === 'wybcbs'){
                                            $scope.mbShow = true;
                                            // $scope.mbText = "We've transitioned your member site. Please <a href=' https://id.bcbswy.com:8443/nidp/idff/sso?id=ProdMembers&sid=0&option=credential&sid=0&target=https://members.bcbswy.com/' target='_blank'><font color=blue>log in</font></a> to the previous site.";
                                        }


                                        switch(curGrp.productList[iPrdIdx].benefitPlanType)
                                        {
                                            // Lets put everything in the array. We can decide late on which to show (active, past etc.)
                                            case "Medical": medCovs.push(objProdPtr) ;

                                                            $scope.medDataShow= true;

                                                            //Get the plan name
                                                            $scope.strPlanName = objProdPtr.PlanName;
                                                var dtStartConDate = new Date(curGrp.productList[iGrpIdx].coverageConBeginDate); //?? why duplicate logic - chk
                                                var dtToday = new Date();
                                                if (dtStartConDate > dtToday)
                                                {
                                                    $scope.bPlanDatesValid = false;
                                                }else{
                                                    $scope.bPlanDatesValid = true;
                                                }

                                                            //Get the plan valid dates

                                                           var curPrd = curGrp.productList[iPrdIdx];



                                                    break;
                                            case "Drug": drugCovs.push(objProdPtr);
                                                var currPrd = curGrp.productList[iPrdIdx];

                                                if ((currPrd.rxClaims === "true") || (currPrd.isBlueExtraBasic === "true") || (currPrd.isBlueExtraPlus === "true")) {
                                                    $scope.bShowDrug = false;
                                                } else if (((curPBMIndicator === "None") || (curPBMIndicator == null)) && (currPrd.rxClaims === "true")) {
                                                    $scope.bShowDrug = false;
                                                } else {
                                                    $scope.drugShow= true;
                                                }

                                                break;
                                            case "Dental": dentalCovs.push(objProdPtr) ;
                                                $scope.dentShow= true;
                                                break;
                                            case "Vision": visionCovs.push(objProdPtr) ;
                                                $scope.visShow=true;
                                                break;
                                            case "Spending Accounts":
                                                if (arrSpendingCovNames.indexOf(strBenefitPlanName) == -1) {
                                                    SpendingCovs.push(objProdPtr) ;
                                                    arrSpendingCovNames.push(strBenefitPlanName) ;
                                                    $scope.spenShow= true;
                                                }
                                                break;
                                            case "Wellness Programs":
                                                if ((arrrWellnessCovNames.indexOf(strBenefitPlanName) == -1) && !(brand.current.sharedBrandCode.toLowerCase() === 'ndbcbs' || brand.current.sharedBrandCode.toLowerCase() === 'wybcbs')) {
                                                    wellnessCovs.push(objProdPtr) ;
                                                    arrrWellnessCovNames.push(strBenefitPlanName) ;
                                                    $scope.welShow= true;
                                                }
                                                break;
                                            default:
                                                $scope.noShow = true;
                                                break;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                });

            }
        ]);
    };