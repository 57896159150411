/**
 * Created by lidwaai on 08/08/2020.
 */
module.exports = function(bootstrap){
    bootstrap.run(['Service', 'appId', function(Service, appId){
        new Service({
            name : 'eobDocForDentalClaim',
            group : 'rbsmbl',
            url : '/rbsmbl/x-services/secure/documents/v1/dentaleob',
            checkIfClaimHasEOB: function(endDate, agreementId, claimNumber){
                var data = {
                    "payload" : {
                        "claimFnlDate" : endDate,
                        "contractId" : agreementId,
                        "claimNumber" : claimNumber
                    }
                }
                this.headers.APPID = 'CHMHOM';
                return this.post(data);
            },
            downloadDentalEOB: function(documentToken, documentType){
                var data = {
                    "payload" : {
                        "documentToken" : documentToken,
                        "documentType" : documentType
                    }
                }
                this.headers.APPID = 'CHMHOM';
                return this.post(data);
            }
        });
    }]);
};
