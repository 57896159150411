module.exports = function(app){
	app.service("coverageServices", ["services", "$q", function(services, $q){
		var COVERAGE_IDS = {
			"MBR_COVERAGE_CHANGE_HEADER": "MBR_COVERAGE_CHANGE_HEADER",
			"MBR_COVERAGE_CHANGE_SHOP": "MBR_COVERAGE_CHANGE_SHOP",
			"MBR_COVERAGE_CHANGE_CUSTOMER_SERVICE": "MBR_COVERAGE_CHANGE_CUSTOMER_SERVICE",
			"MBR_COVERAGE_CHANGE_RETAIL": "MBR_COVERAGE_CHANGE_RETAIL",
			"MBR_COVERAGE_CHANGE_AGENT": "MBR_COVERAGE_CHANGE_AGENT"
		};
		this.canMakeChanges = function(indicators) {
			return !!(indicators.hcrProduct
				&& !indicators.blueEdgeDentalIndv
				&& indicators.directPayIndicator
				&& indicators.isSubscriber);
		};
		var parseContent = function(content) {
			var DEFAULT_RESPONSE = {};
			if (content && content.data && content.data.payload && content.data.payload.contentResponse) {
				var listOfItems = content.data.payload.contentResponse;
				var i = listOfItems.length;
				var parsedItems = {};
				while(i--) {
					try {
						var contentItem = JSON.parse(listOfItems[i].value);
						parsedItems[listOfItems[i].name] = contentItem;
					} catch(error) {
						parsedItems[listOfItems[i].name] = listOfItems[i].value;
					}
				}
				return parsedItems;
			}
			return DEFAULT_RESPONSE;
		};
		this.updateContent = function(brand){
			var defer = $q.defer();
			services.rbsmbl.contentInfo().getContent(COVERAGE_IDS, { "brand": brand }).then(
				function(response){
					defer.resolve(parseContent(response));
				},
				function(response){
					defer.reject(response);
				}
			);
			return defer.promise;
		};
	}]);
	app.controller("pages.coverage.changeMyCoverage", ["$scope", "coverageServices", "session", "$window", "brand",
		function($scope, coverageServices, session, $window, brand){

		var REDIRECT_URL = '/mbr/#!/coverage/coverage-summary';

		if (!coverageServices.canMakeChanges(session.data)) {
			$window.location = REDIRECT_URL;
		}

		$scope.pageHeading = "";
		$scope.pageDescription = "";
		$scope.content = {
			shop: {},
			customerService: {},
			retail: {},
			agent: {}
		};

		$scope.hasContent = function(name){
			console.log(name + " : " + !!($scope.content[name] && $scope.content[name].teaserTitle));
			return !!($scope.content[name] && $scope.content[name].teaserTitle);
		};
		$scope.getUrl = function(name) {
			return $scope.content[name].teaserLink;
		};

		$scope.isMn = brand.current.sharedBrandCode.toLowerCase() === "mincr";


		coverageServices.updateContent(session.data.memberInfo.brand).then(
			function(coverageInfo){
				$scope.pageHeading = coverageInfo.MBR_COVERAGE_CHANGE_HEADER.title;
				$scope.pageDescription = coverageInfo.MBR_COVERAGE_CHANGE_HEADER.content;
				$scope.content = {
					shop: coverageInfo.MBR_COVERAGE_CHANGE_SHOP || {},
					customerService: coverageInfo.MBR_COVERAGE_CHANGE_CUSTOMER_SERVICE || {},
					retail: coverageInfo.MBR_COVERAGE_CHANGE_RETAIL || {},
					agent: coverageInfo.MBR_COVERAGE_CHANGE_AGENT || {}
				};
			},
			function(){
				$window.location = REDIRECT_URL;
			}
		);

	}]);
};