module.exports = function(app) {
    app.directive('myChartEspot', ['$rootScope', 'coremetrics', 'myChartUtil', 'sessionData',
        function($rootScope, coremetrics, myChartUtil, sessionData) {
            return {
                    restrict: 'A',
                    template: "<button data-ng-click='goToMyChart()' data-ng-if='showMyChartEspot'>" +
                        "<img src='images/claims/myChart_espot.png' alt='My Chart' />" +
                    "</button>",
                scope: {},
                link: function(scope) {
                    scope.goToMyChart = function() {
                        coremetrics.elementTag('GO TO MYCHART', 'MEMBER:CLAIMS');
                        window.open("/my-chart/#/home", "_self");
                    };

                    $rootScope.$on('updateMyChartVisibility', function(data, summary){
                        scope.showMyChartEspot = myChartUtil.hasEligibleClaims(summary) &&
                            sessionData.memberInfo.brand.toLowerCase() == 'hmbcbs';
                    });
                }
            };
        }
    ]);
};