module.exports = function (bootstrap) {
	bootstrap.run(['Service', function(Service) {
        new Service({
        	name: "acclaris",
        	group: "rbsmbl",
        	url: "/rbsmbl/x-services/singleSignon/acclaris",

        	postData: function (payload) {
        		var opts = payload || {};
        		return this.post(opts);
        	}
        });
    }]);
};