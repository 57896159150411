module.exports = function (app) {
    require("./prenatal/prenatalPlusForm")(app);

    app.controller("pages.prenatal", ["$scope", function ($scope) {
        $scope.headings = "PRENATAL PLUS";
        $scope.sidebar.links = [];

        $scope.sidebar.links.push({
            heading: "PRENATAL PLUS FORM",
            label: "PRENATAL PLUS",
            active: true,
            description: "Whether you're expecting your first child or your fifth, Blue Cross Blue Shield of North Dakota's Prenatal Plus Program can provide you with valuable information and support.",
            state: "prenatal"
        });
    }]);

    return [
        {
            state: "prenatal",
            url: "/prenatal",
            postPassword: true,
            views: {
                "body@": {
                    template: bootstrap.templates["page-templates/categoryCustom.html"],
                    controller: "pages.prenatal"
                },
                "content@prenatal": {
                    template: require("./prenatal/prenatalPlusForm.html"),
                    controller: "pages.prenatal.prenatalPlusForm"
                }
            }
        },
    ]
};