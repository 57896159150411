module.exports = function(app) {
    app.directive('claimsSinceLastVisit', function() {

        return {
            restrict: 'A',
            replace: true,

            scope: {
                newClaimsCount: '=claimsSinceLastVisit'
            },

            template:   '<article data-ng-show="newClaimsCount" class="claims-since-last-visit-banner">' +
                            '<p><span class="font24">You have {{newClaimsCount}} new {{newClaimsCount===1 ? "claim" : "claims"}}</span></span>&nbsp;&nbsp;since your last visit here</p>' +
                        '</article>'
        };
    });
};