module.exports = function(app) {
    app.factory('claimDownloadService', ['services', 'invisibleFormService', 'modal', '$window',
        function(services, invisibleFormService, modal, $window) {
            var
                getDCD,
                getDocument,
                getDentalEobDoc;


            getDCD = function(claim) {
                return invisibleFormService.submit(claim);
            };


            getDocument = function(payload) {
                var statementPromise = services.rbsmbl.statementFile().fetch(payload);

                modal.open(
                    {
                        heading: 'Please Wait',
                        template: '<p>File Download In Progress...</p>'
                    }
                );
                return statementPromise.then(
                    function(response) {
                        modal.close();
                        $window.open(response.url);
                    },
                    function(err) {
                        modal.close();
                        modal.open(
                            {
                                heading: 'Error',
                                template: '<p>Your File Could Not Be Downloaded At This Time.</p>'
                            }
                        );
                        console.error('getDocument failed with error', err);
                        return statementPromise;
                    }
                );
            };

            getDentalEobDoc = function(documentToken, documentType) {
                var downloadDentalEob = services.rbsmbl.eobDocForDentalClaim().downloadDentalEOB(documentToken, documentType);
                modal.open(
                    {
                        heading: 'Please Wait',
                        template: '<p>File Download In Progress...</p>'
                    }
                );
                return downloadDentalEob.then(
                    function(response) {
                        modal.close();
                        var base64ToArrayBuffer = function(convertBase64PDF) {
                            var binaryString = window.atob(convertBase64PDF);
                            var binaryLen = binaryString.length;
                            var conversionBytes = new Uint8Array(binaryLen);
                            for (var i = 0; i < binaryLen; i++){
                                var ascii = binaryString.charCodeAt(i);
                                conversionBytes[i] = ascii;
                            }
                            return conversionBytes;
                        };

                        var pdfArr = base64ToArrayBuffer(response.data.onDemandDocuments[0].documentData);
                        var blob = new Blob([pdfArr], {type: 'application/pdf'});
                        var fileURL = $window.URL.createObjectURL(blob, 'EOB');
                        $window.open(fileURL);
                    },
                    function(err) {
                        modal.close();
                        modal.open(
                            {
                                heading: 'Error',
                                template: '<p>Your File Could Not Be Downloaded At This Time.</p>'
                            }
                        );
                        console.error('getDocument failed with error', err);
                        return downloadDentalEob;
                    }
                );
            };

            return {
                getDCD: getDCD,
                getDocument: getDocument,
                getDentalEobDoc: getDentalEobDoc,
            };
        }
    ]);
};