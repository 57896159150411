module.exports = function(app) {
    // TODO: messageService needs to be completed.
    app.factory('messageService', ['$q', 'services', '$filter', 'brand',
        function($q, services, $filter, brand) {
            var
                _parseMessages,
                getMessages,
                getCategories,
                getMembers,
                _formatForRequestObject,
                alreadySetPriorAuth,
                deleteMessages,
                setStatuses,
                setStatus,
                _formatBrand,
                notifyDependent;


            _parseMessages = function(messages) {

                var _replaceMessageBrokerResponseVariables = function(message) {
                    message.messageContent.Subject = message.messageContent.Subject || '';
                    message.messageContent.Body = message.messageContent.Body || '';

                    if (message.firstName) {
                        message.messageContent.Subject = message.messageContent.Subject.replace(/\$(\{|\<)recipientFirstName(\}|\>)/g, $filter('capitalize')(message.firstName));
                        message.messageContent.Body = message.messageContent.Body.replace(/\$(\{|\<)recipientFirstName(\}|\>)/g, $filter('capitalize')(message.firstName));
                    }
                    
                    if (message.priorAuthAddress) {
                         var addr = message.priorAuthAddress;
                         message.map = {};
                         message.getDirections = "http://maps.google.com/maps?z=12&t=m&q=" +
                                                 (addr.address1 ? addr.address1+"+" : '') +
                                                 (addr.address2 ? addr.address2+"+" : '') +
                                                 (addr.city ? addr.city+"+" : '')+
                                                 (addr.state ? addr.state +"+" : '') +
                                                 (addr.zipCode.base ? addr.zipCode.base : '');
                    }

                    if (message.msgtx3) {
                        message.messageContent.Subject = message.messageContent.Subject.replace(/\$(\{|\<)subDate(\}|\>)/g, message.msgtx3);
                        message.messageContent.Body = message.messageContent.Body.replace(/\$(\{|\<)subDate(\}|\>)/g, message.msgtx3);
                    }

                    if (message.msgtx1 && message.msgtx2) {
                        message.messageContent.Body = message.messageContent.Body.replace(/\$(\{|\<)accumCount(\}|\>)/g, message.msgtx1);
                        message.messageContent.Body = message.messageContent.Body.replace(/\$(\{|\<)accumThreshold(\}|\>)/g, message.msgtx2);
                    }

                    if(message.messageCategory.toLowerCase() === 'pra' && message.msgtx5 === 'updated'){
                        message.updated = true;
                    }
                };

                var _getMessageType = function(category) {
                    var messageTypeMap = {
                        wellness: [ 'wln', 'cln' ],
                        coverage: [ 'cls', 'gps', 'dsc', 'elg', 'trn', 'wts' ],
                        whatsNew: [ 'glb', 'mkt', 'opn', 'prm' ],
                        priorAuth: ['pra']
                    };

                    var makeCategoryMatcher = function(list) {
                        list = Array.isArray(list)
                             ? list
                             : [];

                        return function(category) {
                            return list.some(function(item) {
                                return item === category;
                            });
                        };
                    };

                    var isWellness = makeCategoryMatcher(messageTypeMap.wellness);
                    var isCoverage = makeCategoryMatcher(messageTypeMap.coverage);
                    var isWhatsNew = makeCategoryMatcher(messageTypeMap.whatsNew);
                    var isPriorAuth = makeCategoryMatcher(messageTypeMap.priorAuth);

                    if (isWellness(category)) {
                        return 'Wellness';
                    }
                    else if (isCoverage(category)) {
                        return 'Coverage';
                    }
                    else if (isWhatsNew(category)) {
                        return "What's New";
                    }
                    else if (isPriorAuth(category)) {
                        return "Prior Authorization";
                    }
                    else {
                        return "What's New";
                    }
                };

                var _getShortMonth = function(monthNumber) {
                    //*** this method is necessary for IE8 ***//
                    switch(monthNumber) {
                        case 0: return 'jan';
                        case 1: return 'feb';
                        case 2: return 'mar';
                        case 3: return 'apr';
                        case 4: return 'may';
                        case 5: return 'jun';
                        case 6: return 'jul';
                        case 7: return 'aug';
                        case 8: return 'sep';
                        case 9: return 'oct';
                        case 10: return 'nov';
                        case 11: return 'dec';
                        default: return '';
                    }
                };

                return messages.map(function (message) {
                    Object.keys(message).forEach(function (key) {
                        if (key === 'messageCategory' && typeof message[key] === 'string') {
                            message[key] = message[key].toLowerCase();

                            if (message[key] === 'special') {
                                message.messageFilterID = 'specialMessage' + $filter('date')(new Date(message.messageDate), 'ddMMyyyy');
                                message.last = true;
                            }
                            else {
                                message.messageFilterID = message.messageID + $filter('date')(new Date(message.messageDate), 'ddMMyyyy');
                            }
                            message.messageType = _getMessageType(message[key]);
                        }
                        if (key === 'messageContent') {
                            try {
                                message[key] = JSON.parse(message[key]);
                            }
                            catch (e) {
                                "I'm a little catch block, short and stout...";
                            }
                            _replaceMessageBrokerResponseVariables(message);
                        }
                        if (key === 'messageStatus' && typeof message[key] === 'string') {
                            message[key] = message[key].toLowerCase();
                        }

                        if (key === 'messageDate') {
                            var messageDate = message[key];
                            messageDate = new Date(messageDate);
                            message.dayOfMonth = messageDate.getUTCDate();
                            message.month = _getShortMonth(messageDate.getMonth());
                        }
                    });
                    return message;
                });
            };


            getMessages = function (startDate, endDate) {
                startDate = startDate || Number((new Date() - (1000*60*60*24*365 * 1)));
                endDate = endDate || Number(new Date());

                var
                    memberInfoPromise = services.rbsmbl.memberInfo().fetch(),
                    volatileInfoPromise = services.rbsmbl.volatileMemberInfo().fetch(),
                    memberInfoAndVolatileInfoPromiseAll = $q.all({ memberInfo: memberInfoPromise, volatileInfo: volatileInfoPromise });

                return memberInfoAndVolatileInfoPromiseAll.then(
                    function (response) {
                        var
                            lastMessageCenterVisitDate = response.volatileInfo.data.payload.lastMessageCenterVisitDate,
                            getMessagesPromise = services.rbsmbl.getMessages().fetch(startDate, endDate, response.memberInfo.data.payload, brand.current.ubk);

                        return getMessagesPromise.then(
                            function (response) {
                                var messages = response.data.payload;
                                var specialMessage = messages.specialMessage;
                                var messageList = messages.messageList
                                                ? angular.copy(messages.messageList)
                                                : [];
                                var totalCount;
                                var unread;

                                if (specialMessage) { messageList.unshift(angular.copy(specialMessage)); }

                                messageList = _parseMessages(messageList);
                                totalCount = messages.totalCount || '';
                                unread = messages.unread || '';

                                return {
                                    messageList: messageList,
                                    totalCount: totalCount,
                                    unread: unread,
                                    lastMessageCenterVisitDate: lastMessageCenterVisitDate
                                };
                            },
                            function(err) {
                                console.error('rbsmbl.getMessages.fetch failed with error:', err);
                                return getMessagesPromise;
                            }
                        );
                    },
                    function(err) {
                        console.error('messageService.getMessages failed with error:', err);
                        return memberInfoAndVolatileInfoPromiseAll;
                    }
                );
            };


            getCategories = function(messageList) {
                messageList = Array.isArray(messageList)
                            ? messageList
                            : [];

                var countsByCategory = messageList.reduce(function(categories, y) {
                    var category = typeof y.messageType === 'string'
                                 ? y.messageType.toLowerCase()
                                 : 'Not Available';

                    categories[category] = ++categories[category] || 1;

                    return categories;
                }, {});

                return Object.keys(countsByCategory).map(
                    function(category) {
                        return {
                            name: $filter('capitalize')(category),
                            messageCount: countsByCategory[category]
                        };
                    }
                );
            };


            getMembers = function(messageList) {
                messageList = Array.isArray(messageList)
                            ? messageList
                            : [];

                var countsByName = messageList.reduce(function(members, y) {
                    // Must first handle specialMessages
                    if (typeof y.messageCategory === 'string' && y.messageCategory.toLowerCase() === 'special') {
                        var memberList = y.messageContent && y.messageContent.memberList || [];

                        memberList.forEach(function(firstName) {
                            firstName = typeof firstName === 'string'
                                      ? firstName.toLowerCase()
                                      : 'Not Available';

                            members[firstName] = ++members[firstName] || 1;
                        });
                    }
                    else {
                        var firstName = typeof y.firstName === 'string'
                            ? y.firstName.toLowerCase()
                            : 'Not Available';

                        members[firstName] = ++members[firstName] || 1;
                    }
                    return members;
                }, {});

                return Object.keys(countsByName).map(
                    function(name) {
                        return {
                            name: name,
                            messageCount: countsByName[name]
                        };
                    }
                );
            };


            // TODO: Simplify this for the good of all people
            _formatForRequestObject = function(process) {
                var processStatus;

                switch (process) {
                    case 'forDeletion': processStatus = 'D';
                        break;
                    case 'asRead': processStatus = 'R';
                        break;
                    default: processStatus = '';
                }

                return function(message) {
                    message = message || {};

                    var messageID = typeof message.messageID === 'string'
                                  ? message.messageID.toUpperCase()
                                  : '';

                    var pendingStatus = typeof message.pendingStatus === 'string'
                                      ? message.pendingStatus.toUpperCase()
                                      : '';


                    return {
                        "messageSequenceID": message.messageSequenceID,
                        "ecid": message.ecid,
                        "umi": message.umi,
                        "messageID": messageID,
                        "processStatus": processStatus || pendingStatus,
                        "messageRcdMntnId": message.messageRcdMntnId || 'QDINT0E7' // I don't know why this value is hardcoded in mhs, but it is
                    };
                };
            };


            deleteMessages = function(messageList) {
                var payloadMessageList = messageList.map(_formatForRequestObject('forDeletion'));
                var deleteMessagePromise = services.rbsmbl.messageDeleteFlag().update(payloadMessageList);

                return deleteMessagePromise.then(
                    function() {
                        services.rbsmbl.getMessages().flushCache();
                    },
                    function(err) {
                        console.error('messageDeleteFlag().update() failed with error', err);
                    }
                );
            };


            setStatuses = function(messageList) {
                var payloadMessageList = messageList.map(_formatForRequestObject('asRead'));

                return services.rbsmbl.messageReadFlag().update(payloadMessageList);
            };


            setStatus = function(message, newStatus) {
                message.pendingStatus = newStatus;

                return setStatuses([message]);
            };


            _formatBrand = function(aBrand) {
                var theBrand = aBrand.current.umsBrandCode || aBrand.current.sharedBrandCode;

                return typeof theBrand === 'string'
                    ? theBrand.toUpperCase()
                    : '';
            };


            notifyDependent = function(notificationType, phoneNumberOrEmailAddress) {
                var memberInfoPromise = services.rbsmbl.memberInfo().fetch();

                return memberInfoPromise.then(
                    function(response) {
                        var
                            bvUserId,
                            formattedBrand,
                            payload;

                        bvUserId = response.data.payload.bvUserId;
                        formattedBrand = _formatBrand(brand);

                        payload = {
                            "bvuserid": bvUserId,
                            "brand": formattedBrand,
                            "communicationType": notificationType,
                            "communicationList": [phoneNumberOrEmailAddress]
                        };

                        return services.rbsmbl.dependentNotification().fetch(payload);
                    },

                    function(err) {
                        console.error('memberInfo.fetch failed in notifyDependent with error', err);
                        return memberInfoPromise;
                    }
                );
            };


            return {
                getMessages: getMessages,
                deleteMessages: deleteMessages,
                setStatuses: setStatuses,
                setStatus: setStatus,
                notifyDependent: notifyDependent,
                getCategories: getCategories,
                getMembers: getMembers
            };
        }
    ]);
};