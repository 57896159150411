module.exports = function (app) {
    require("./hipaa-authorization/authorization")(app);

    app.controller("pages.HIPAA", ["$scope", function ($scope) {
        $scope.headings = "HIPAA";
        $scope.sidebar.links = [];

        $scope.sidebar.links.push({
            heading: "HIPAA Authorization",
            label: "HIPAA",
            active: true,
            description: "Please complete this form and attach it as part of a request through the Message Center's Contact Us feature. When filling out the form please select \"General - Other\" as your topic.",
            state: "HIPAA"
        },{
            heading: "CONTACT US",
            label: "CONTACT US",
            active: false,
            onClick: function(){
                window.location.href="/msg/#!/contact-us";
            }
        });
    }]);

    return [
        {
            state: "HIPAA",
            url: "/HIPAA",
            resolve: {
                resourcesList: ["contentResourceServices", function(contentResourceServices){
                    var CONTENT_TYPE = "Bluecore Resources";
                    return contentResourceServices.getResourceIdListAsync(CONTENT_TYPE);
                }]
            },
            postPassword: true,
            views: {
                "body@": {
                    template: bootstrap.templates["page-templates/categoryCustom.html"],
                    controller: "pages.HIPAA"
                },
                "content@HIPAA": {
                    template: require("./hipaa-authorization/authorization.html"),
                    controller: "pages.HIPAA.authorizationCtrl"
                }
            }
        },
    ]
};