module.exports = function(app) {
    app.directive('adamView',
        function($window,$location,$http,$compile,$sce) {
            return {
                restrict: 'EA',
                controller : ['$scope','$element','services', function ($scope,$element,services) {
                    $scope.$watch('projectTypeID', function () {
                        var contentPromise=services.rbsmbl.adamContentXML().fetch($scope.productID,$scope.projectTypeID,$scope.genContentID)
                        contentPromise.then(function(response){
                            $scope.templateLoaded=true;
                            $element.html(response.data.payload.xmlString);
                            angular.forEach($element.contents().find("dt"),function(dt){
                                angular.element(dt).addClass("CGindexSubhead");
                            });
                            angular.forEach($element.contents().find("dd"),function(dd){
                                angular.element(dd).addClass("CGDD");
                            });
                            if($scope.projectTypeID === '28'){
                                angular.forEach($element.contents().find("p"), function (p) {
                                    if(p.innerHTML.indexOf("click \"next\" below.")>0){
                                        angular.element(p).css("display","none");
                                    }
                                });
                                if($scope.genContentID === '000370'){
                                    angular.forEach($element.contents().find("b"), function (b) {
                                        if(b.innerHTML.indexOf("red buttons")>0){
                                            b.innerHTML="Click the blue buttons below to learn more about these problems.";
                                        }
                                    });
                                }

                            }
                            $compile($element.contents())($scope);
                        });
                    });

                }]
            };
        }
    );
};