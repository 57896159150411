module.exports = function(app) {
    app.directive('dateRangeValidation', ["$window", "$compile", function($window, $compile) {
        return {
            restrict: "A",
            require: "ngModel",
            link: function (scope, elm, attrs, ctrl) {

                var createDate = function(dateString) {
                    // YYYY-MM-DD format
                    var dateParts = dateString.split('-');
                    return new Date(dateParts[0], dateParts[1]-1, dateParts[2]);
                };

                ctrl.$validators.dateRangeMaxValidation = function(modelValue, viewValue) {
                    var max = createDate(attrs.dateRangeMax);
                    var enteredDate = createDate(viewValue);

                    if (ctrl.$isEmpty(modelValue)) {
                        // consider empty models to be valid
                        return true;
                    }
                    if (enteredDate <= max) {
                        return true;
                    }
                    return false;
                };

                ctrl.$validators.dateRangeMinValidation = function(modelValue, viewValue) {
                    var min = createDate(attrs.dateRangeMin);
                    var enteredDate = createDate(viewValue);

                    if (ctrl.$isEmpty(modelValue)) {
                        // consider empty models to be valid
                        return true;
                    }
                    if (enteredDate >= min) {
                        return true;
                    }
                    return false;
                };
            }
        };
    }]);
}