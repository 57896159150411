module.exports = function(app) {
    app.directive('adamSideBarNav',
        function() {
            return {
                restrict: 'A',
                link: function (scope, element, attrs) {
                    scope.goToSection(2);
                }
            };
        }
    );
};