module.exports = function(app) {
    app.controller('pages.wellness.ccsSignUp', ['$scope', '$state','teamsite','$stateParams','modal','services','coremetrics', 'sessionData', "session", "brand", "$window",
        function ($scope, $state, teamsite, $stateParams, modal, services, coremetrics, sessionData,  session, brand, $window) {
            $scope.ccsFlag = false;
            $scope.ccsEnhancedFlag = false;
            $scope.dependentInfo = false;
            $scope.showCCS = false;
            $scope.isMINCR = (brand.current.sharedBrandCode === 'mincr') ? true : false;
            $scope.checkboxesChecked = [];
            $scope.SignUpFail = false;
            $scope.termsAccepted = false;
            $scope.termsError = false;

            var payload= null;
            $scope.cancel = function(){
                $state.go("wellness.ccs");
            };

            $scope.getSelectedSubscribers = function(){
                var cbNames = document.getElementsByName("nameCheck");
                var checkedSubs = [];

                for (var i=0; i<cbNames.length; i++) {
                    if (cbNames[i].checked) {
                        checkedSubs.push(cbNames[i]);
                    }
                }

                return checkedSubs;
            };

            $scope.subscribersSelected = function(){
                $scope.checkboxesChecked = $scope.getSelectedSubscribers();

                if($scope.checkboxesChecked.length>0){
                    $scope.nameError = false;
                }else{
                    $scope.nameError = true;
                }

                return ($scope.nameError == false);
            };

            $scope.getSubscribersStatus = function(){
                var cbNames = document.getElementsByName("nameCheck");
                var allSubs = [];

                for (var i=0; i<cbNames.length; i++) {
                    if(cbNames[i].value.length > 0) {
                        allSubs.push({id: cbNames[i].id, value: cbNames[i].value, isChecked: cbNames[i].checked});
                    }
                }

                return allSubs;
            };

            $scope.enhancedContactHasError = function(){
                var phoneNumberRadio = document.getElementsByName("phoneInfo");
                var phoneNumbersList;

                for(var i = 0; i < phoneNumberRadio.length; i++) {
                    if(phoneNumberRadio[i].checked)
                        phoneNumbersList = phoneNumberRadio[i].value;
                }
                if(phoneNumbersList == null || phoneNumbersList == undefined || phoneNumbersList == ""){
                    $scope.extraContactError = true;
                }else{
                    $scope.extraContactError = false;
                }

                return $scope.extraContactError;
            };

            $scope.normalContactHasError = function() {
                var normalContact = document.getElementsByName("contactInfo");
                var phoneNos;

                for (var i = 0; i < normalContact.length; i++) {
                    if (normalContact[i].checked)
                        phoneNos = normalContact[i].value;
                }

                if (phoneNos == null || phoneNos == undefined || phoneNos == "") {
                    $scope.normalContactError = true;
                }
                else {
                    $scope.normalContactError = false;
                }

                return $scope.normalContactError;
            };

            $scope.getChosenECCSNumber = function(){
                var phoneNumberRadio = document.getElementsByName("phoneInfo");
                var phoneNumbersList;

                for(var i = 0; i < phoneNumberRadio.length; i++) {
                    if(phoneNumberRadio[i].checked) {
                        var numberType = phoneNumberRadio[i].id;
                        numberType = numberType.replace("eCCS","").replace("Phone"," Phone Number: ");
                        phoneNumbersList = {"type": numberType, "number": phoneNumberRadio[i].value};
                    }
                }

                return phoneNumbersList;
            };

            $scope.getChosenCCSContact = function(){
                var normalContact = document.getElementsByName("contactInfo");
                var selectedContactMethod;
                var phoneNos;

                for(var i = 0; i < normalContact.length; i++) {
                    if (normalContact[i].checked) {
                        selectedContactMethod = normalContact[i];
                        phoneNos = document.querySelector("#" + selectedContactMethod.id + " + span > .labelCustom").innerHTML;
                        phoneNos = phoneNos.replace("Email","Email: ").replace("Home","Home Phone Number: ").replace("Work","Work Phone Number: ").replace("Mobile","Mobile Phone Number: ")
                    }
                }

                if (phoneNos == null || phoneNos == undefined || phoneNos == "") {
                    $scope.normalContactError = true;
                }
                else {
                    $scope.normalContactError = false;
                }

                return phoneNos;
            };

            $scope.showConfirmationModal = function() {
                var termsAccepted = document.getElementById("termsCheckbox").checked;
                $scope.termsError = !termsAccepted;

                var subsSelected = $scope.subscribersSelected();
                var enhContactSelected = $scope.enhancedContactHasError() == false;
                var normalContactSelected = $scope.normalContactHasError() == false;
                // added an extra bucket to show all the subscribers in the pop up and whether they were checked
                $scope.subsStatusList = $scope.getSubscribersStatus();

                if($scope.ccsEnhancedFlag && subsSelected && enhContactSelected && termsAccepted && normalContactSelected){
                    // if ecmr then test only both eccs and ccs and terms error
                    $scope.chosenECCSNumber = $scope.getChosenECCSNumber();
                    $scope.chosenCCSContact = $scope.getChosenCCSContact();
                    modal.open({
                        heading: "Thank you for signing up",
                        template: require('modals/confirmVBBSignUpModal.html'),
                        scope: $scope
                    });
                }else if ($scope.ccsFlag && subsSelected && termsAccepted && normalContactSelected){
                    // if cmr then test only normalContactHasError and terms error
                    $scope.chosenECCSNumber = $scope.getChosenECCSNumber();
                    $scope.chosenCCSContact = $scope.getChosenCCSContact();
                    modal.open({
                        heading: "Thank you for signing up",
                        template: require('modals/confirmVBBSignUpModal.html'),
                        scope: $scope
                    });
                }
            };

            $scope.confirmModal = function(){
                $scope.submit();
            };

            $scope.closeModal = function(){
                modal.close();
            };

            $scope.getDependentsArray = function(memData,subIncluded){
                var tempArray = [];
                var i = (subIncluded) ? 1:0;

                for (i; i < memData.memberList.length; i++) {
                    var memberPkg = memData.memberList[i];

                    if(memberPkg.packages[0].conditions[0].memberSignedUp == false){
                        memberPkg.fullName = (memberPkg.firstName.length > 0 || memberPkg.lastName.length > 0)? (memberPkg.firstName + ' ' + memberPkg.lastName) : '';
                        tempArray.push(memberPkg);
                    }
                }
                return tempArray;
            };

            $scope.memberInfoInvoke = function(){
            services.rbsmbl.vbbMemberInfo()
                .getData()
                .then(function(memberData) {
                    if(memberData && memberData.data && memberData.data.payload){
                        $scope.vbbMemberInformation = memberData.data.payload;
                        $scope.subscriberOnly = false;
                        $scope.dependentOnlyOne = false;
                        var memData = memberData.data.payload;
                        var tempArray = [];
                        var subscriberIncluded = false;

                        $scope.nameArray = [];

                        if(memData.memberList[0].relationship == "Subscriber" && memData.memberList[0].packages[0].conditions[0].memberSignedUp == false){
                            $scope.subscriberName = memData.memberList[0];
                            $scope.showSubscriber = true;
                            $scope.subscriberOnly = memData.memberList.length == 1;
                            $scope.subscriberName.fullName = ($scope.subscriberName.firstName.length > 0 || $scope.subscriberName.lastName.length > 0)? ($scope.subscriberName.firstName + ' ' + $scope.subscriberName.lastName) : '';

                            if(memData.memberList.length > 0) {
                                $scope.dependentInfo = true;
                                subscriberIncluded = true;
                                tempArray = $scope.nameArray = $scope.getDependentsArray(memData,subscriberIncluded);

                                if(tempArray == null || tempArray == undefined || tempArray == ""){
                                    $scope.dependentInfo = false;
                                    $scope.subscriberOnly = true;
                                }
                            }
                        }else if(memData.memberList[0].relationship == "Subscriber" && memData.memberList[0].packages[0].conditions[0].memberSignedUp == true){
                            $scope.showSubscriber = false;
                            if(memData.memberList.length > 0) {
                                $scope.dependentInfo = true;
                                subscriberIncluded = true;
                                $scope.nameArray = $scope.getDependentsArray(memData,subscriberIncluded);
                                $scope.dependentOnlyOne = ($scope.nameArray.length == 1);
                            }
                        }else if(memData.memberList[0].relationship == "Dependent" && memData.memberList[0].packages[0].conditions[0].memberSignedUp == false){
                            $scope.showSubscriber = false;
                            if(memData.memberList.length > 0) {
                                $scope.dependentInfo = true;
                                $scope.nameArray = $scope.getDependentsArray(memData,subscriberIncluded);
                                $scope.dependentOnlyOne = true;
                            }

                        }

                        document.querySelector("body").classList.add("ccs-sign-up");

                        if(memData.memberList[0].packages[0].packageCode == "CONDITION MNGT"){
                            $scope.ccsFlag = true;
                            coremetrics.pageviewTag('CMR SIGNUP', 'WELLNESS: CMR SIGNUP');
                        }
                        else if(memData.memberList[0].packages[0].packageCode == "PROTOCOL COMPL"){
                            $scope.ccsEnhancedFlag = true;
                            coremetrics.pageviewTag('ECMR SIGNUP', 'WELLNESS: ECMR SIGNUP');
                        }
                        else{
                            $scope.showCCS = false;
                        }

                        if($scope.isMINCR && $scope.ccsFlag || $scope.ccsEnhancedFlag){
                            $scope.showCCS = true;
                        }
                        else{
                            $scope.showCCS = false;
                        }

                        var a = [];
                        for(var i=0; i<memData.memberList.length; i++){
                            if(memData.memberList[i].packages[0].conditions[0].memberSignedUp == false){
                                a.push(true);
                            }else{
                                a.push(false);
                            }
                        }
                        var fail = false;
                        for(var index in a){
                            if(a[index]){
                                fail = true;
                            }
                        }
                        if(fail){
                            $scope.showCCS = true;
                        }
                        else{
                            $scope.showCCS = false;
                        }

                        if(memData.memberList[0].packages[0].packageName == "Ongoing Protocol Compliance"){
                            $scope.packageName = "Enhanced Care Management Reward";
                        }else{
                            $scope.packageName = "Care Management Reward";
                        }
                    }
            });
            };

            $scope.memberInfoInvoke();

            $scope.accountSettingsRetrieveFunction = function()
            {
                services.rbsmbl.AccountSettingsRetrive()
                    .retriveAccountSettings(session.data.groupNumber, "ccsAccountSettings", session.data.memberInfo.brand, session.data.memberInfo.userName)
                    .then(function(accountSettingsData) {
                        if (accountSettingsData && accountSettingsData.data && accountSettingsData.data.payload) {

                            $scope.accountRetrieveData = accountSettingsData.data.payload;
                            var acctData = accountSettingsData.data.payload;
                            $scope.homePhone = acctData.homePhone;
                            $scope.workPhone = acctData.workPhone;
                            $scope.mobilePhone = acctData.mobilePhone;
                            $scope.emailAddress = acctData.emailAddress;
                        }
                    });
            };

            $scope.accountSettingsRetrieveFunction();

            $scope.accountSettingsSaveFunction = function(homeNumber,workNumber,mobileNumber,requestFrom){
                var payload = {
                    "brand": session.data.memberInfo.brand,
                    "groupNumber":session.data.groupNumber,
                    "address1":$scope.accountRetrieveData.address1,
                    "address2":$scope.accountRetrieveData.address2,
                    "address3":$scope.accountRetrieveData.address3,
                    "address4":$scope.accountRetrieveData.address4,
                    "city":$scope.accountRetrieveData.city,
                    "state":$scope.accountRetrieveData.state,
                    "zip":$scope.accountRetrieveData.zip,
                    "mobilePhone": mobileNumber,
                    "intakeRequest":{
                        "bdUserId":session.data.memberInfo.bvUserId,
                        "contactFirstName":session.data.memberInfo.clientList[0].groupList[0].productList[0].memberList[0].contact.firstName,
                        "contactLastName":session.data.memberInfo.clientList[0].groupList[0].productList[0].memberList[0].contact.lastName,
                        "memberFirstName":session.data.memberInfo.firstName,
                        "memberLastName":session.data.memberInfo.lastName,
                        "birthDate":session.data.memberInfo.clientList[0].groupList[0].productList[0].memberList[0].contact.birthDate,
                        "gender":session.data.memberInfo.clientList[0].groupList[0].productList[0].memberList[0].gender,
                        "relationshipCode":session.data.memberInfo.clientList[0].groupList[0].productList[0].memberList[0].relationshipCode,
                        "enrollmentSourceCode":session.data.memberInfo.clientList[0].enrollmentSourceCode,
                        "ecId":session.data.memberInfo.clientList[0].groupList[0].productList[0].memberList[0].ecId,
                        "umi":session.data.memberInfo.umi,
                        "lob":session.data.memberInfo.clientList[0].groupList[0].productList[0].lob,
                        "alphaPrefix":session.data.memberInfo.clientList[0].groupList[0].productList[0].alphaPrefix,
                        "groupNumber":session.data.memberInfo.clientList[0].groupList[0].groupNumber,
                        "benefitPlanName":session.data.memberInfo.clientList[0].groupList[0].productList[0].benefitPlanName,
                        "coverageBeginDate":session.data.memberInfo.clientList[0].groupList[0].productList[0].coverageBeginDate,
                        "coverageEndDate":session.data.memberInfo.clientList[0].groupList[0].productList[0].coverageEndDate,
                        "agreementId":session.data.memberInfo.agreementId,
                        "clientName":session.data.memberInfo.clientList[0].clientName,
                        "clientId":session.data.memberInfo.clientList[0].clientId,
                        "coveredIndividualId":session.data.memberInfo.clientList[0].groupList[0].productList[0].memberList[0].coveredIndividualId,
                        "internalGroupNumber":session.data.memberInfo.clientList[0].groupList[0].internalGroupNumber
                    },
                    "homePhone":homeNumber,
                    "workPhone":workNumber
                };

                  services.rbsmbl.AccountSettingsRetrive()
                 .saveAccountSettings(payload, "ccsAccountSettings", session.data.memberInfo.brand, session.data.memberInfo.userName)
                 .then(function(accountSettingsDataResponse) {
                          if(requestFrom == "eCCS"){
                              var timer = setTimeout(function() {
                                  document.getElementById("eCCSHide").style.display = "none";
                                  $scope.eCCSNumberField = "";
                              }, 3000);
                              $scope.eCCSSucces = true;
                          }
                          else{
                              var timer = setTimeout(function() {
                                  document.getElementById("CCSHide").style.display = "none";
                                  $scope.commonNumberField = "";
                              }, 3000);
                              $scope.CCSSucces = true;
                          }
                          $scope.accountSettingsRetrieveFunction();
                 });
            };

            $scope.$parent.backText = "Back";

            $scope.$parent.backAction = function(){
                $state.go("wellness.ccs");
            }

            $scope.eCCSNumberSave = function(){
                if($scope.eCCSNumberField == null || $scope.eCCSNumberField == undefined || $scope.eCCSNumberField == "") {
                    $scope.eCCSNumberError = true;
                    $scope.eCCSSucces = false;
                }
                else{
                    var res = $scope.eCCSNumberField.match(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/);
                    if(res != null)
                    {
                        var mobileNumber = "";
                        var homeNumber = "";
                        var workNumber = "";
                        var phoneType = document.getElementById("eCCSPhoneType").value;
                        switch(phoneType){
                            case "Home":
                                homeNumber = $scope.eCCSNumberField;
                                mobileNumber = $scope.mobilePhone;
                                workNumber = $scope.workPhone;
                                break;
                            case "Work":
                                workNumber = $scope.eCCSNumberField;
                                homeNumber = $scope.homePhone;
                                mobileNumber = $scope.mobilePhone;
                                break;
                            case "Mobile":
                                mobileNumber = $scope.eCCSNumberField;
                                homeNumber = $scope.homePhone;
                                workNumber = $scope.workPhone;
                                break;
                        }
                        $scope.eCCSNumberError = false;
                        $scope.eCCSSucces = false;
                        $scope.extraContactError = false;
                        document.getElementById("eCCSHide").style.display = "block";
                        $scope.accountSettingsSaveFunction(homeNumber,workNumber,mobileNumber,"eCCS");
                    }
                    else{
                        $scope.eCCSNumberError = true;
                        $scope.eCCSSucces = false;
                    }

                }
            };

            $scope.commonNumberSave = function(){
                if($scope.commonNumberField == null || $scope.commonNumberField == undefined || $scope.commonNumberField == "") {
                    $scope.CCSNumberError = true;
                    $scope.CCSSucces = false;
                }
                else{
                    var res = $scope.commonNumberField.match(/^\D?(\d{3})\D?\D?(\d{3})\D?(\d{4})$/);
                    if(res != null)
                    {
                        var mobileNumber = "";
                        var homeNumber = "";
                        var workNumber = "";
                        var phoneType = document.getElementById("CCSPhoneType").value;
                        switch(phoneType){
                            case "Home":
                                homeNumber = $scope.commonNumberField;
                                mobileNumber = $scope.mobilePhone;
                                workNumber = $scope.workPhone;
                                break;
                            case "Work":
                                workNumber = $scope.commonNumberField;
                                homeNumber = $scope.homePhone;
                                mobileNumber = $scope.mobilePhone;
                                break;
                            case "Mobile":
                                mobileNumber = $scope.commonNumberField;
                                homeNumber = $scope.homePhone;
                                workNumber = $scope.workPhone;
                                break;
                        }
                        $scope.CCSNumberError = false;
                        $scope.CCSSucces = false;
                        $scope.normalContactError = false;
                        document.getElementById("CCSHide").style.display = "block";
                        $scope.accountSettingsSaveFunction(homeNumber,workNumber,mobileNumber,"CCS");
                    }
                    else{
                        $scope.CCSNumberError = true;
                        $scope.CCSSucces = false;
                    }

                }
            };

            $scope.submit = function(){
                $scope.termsError = false;
                $scope.normalContactError = false;
                $scope.extraContactError =false;
               var termsBool = document.getElementById("termsCheckbox").checked;
                if(termsBool){
                    $scope.termsError = false;
                }else{
                    $scope.termsError = true;
                }

                var a = document.getElementsByName("nameCheck");
                for (var i=0; i<a.length; i++) {
                    if (a[i].checked) {
                        $scope.checkboxesChecked.push(a[i]);
                    }
                }
                if($scope.checkboxesChecked.length>0){
                    $scope.nameError = false;
                }else{
                    $scope.nameError = true;
                }

                if($scope.ccsEnhancedFlag){
                    var phoneNumberRadio = document.getElementsByName("phoneInfo");
                    var phoneNumbersList;

                    for(var i = 0; i < phoneNumberRadio.length; i++) {
                        if(phoneNumberRadio[i].checked)
                            phoneNumbersList = phoneNumberRadio[i].value;
                    }
                    if(phoneNumbersList == null || phoneNumbersList == undefined || phoneNumbersList == ""){
                        $scope.extraContactError = true;
                    }else{
                        $scope.extraContactError = false;
                    }

                    var normalContact = document.getElementsByName("contactInfo");
                    var phoneNos;

                    for(var i = 0; i < normalContact.length; i++) {
                        if(normalContact[i].checked)
                            phoneNos = normalContact[i].value;
                    }
                    if(phoneNos == null || phoneNos == undefined || phoneNos == ""){
                        $scope.normalContactError = true;
                    }
                    else{
                        $scope.normalContactError = false;
                    }
                }
                else if($scope.ccsFlag){
                    var normalContact = document.getElementsByName("contactInfo");
                    var phoneNos;

                    for(var i = 0; i < normalContact.length; i++) {
                        if(normalContact[i].checked)
                            phoneNos = normalContact[i].value;
                    }
                    if(phoneNos == null || phoneNos == undefined || phoneNos == ""){
                        $scope.normalContactError = true;
                    }
                    else{
                        $scope.normalContactError = false;
                    }
                }

                if(!$scope.termsError && !$scope.nameError){
                    if($scope.ccsFlag){
                        if(!$scope.normalContactError){
                            $scope.vbbSignUp();
                        }
                    }else if($scope.ccsEnhancedFlag){
                        if(!$scope.extraContactError){
                            $scope.vbbSignUp();
                        }
                    }


                }
            };

            $scope.vbbSignUp = function(){
                payload = {
                    "groupNumber":$scope.vbbMemberInformation.groupNumber,
                    "insuranceLineCode":$scope.vbbMemberInformation.insuranceLineCode,
                    "dataDifferentiator":$scope.vbbMemberInformation.dataDifferentiator,
                    "memberList":[

                    ]
                };
                for(var i=0; i<$scope.checkboxesChecked.length; i++){
                    for(var j=0; j<$scope.vbbMemberInformation.memberList.length;j++){
                        if($scope.vbbMemberInformation.memberList[j].coveredIndividualId == $scope.checkboxesChecked[i].id) {
                            payload.memberList.push({
                                "coveredIndividualId": $scope.vbbMemberInformation.memberList[j].coveredIndividualId,
                                "relationship": $scope.vbbMemberInformation.memberList[j].relationship,
                                "packages": [{
                                    "packageCode": $scope.vbbMemberInformation.memberList[j].packages[0].packageCode,
                                    "benefitBeginDate": $scope.vbbMemberInformation.memberList[j].packages[0].benefitBeginDate,
                                    "conditions": [{
                                        "conditionCode": $scope.vbbMemberInformation.memberList[j].packages[0].conditions[0].conditionCode,
                                        "memberSignedUp": $scope.vbbMemberInformation.memberList[j].packages[0].conditions[0].memberSignedUp
                                    }]
                                }]
                            });
                        }
                        else{
                            continue;
                        }
                    }

                }
                $scope.vbbSignUpCall(payload);
            };

            $scope.vbbSignUpCall = function(payload){
                $scope.closeModal();
                services.rbsmbl.vbbMemberSignUp()
                    .fetch(payload)
                    .then(function (response){
                        if(response && response.data && response.data.payload){
                            if(response.data.payload.signupStatus == true){
                                $scope.SignUpPass = true;
                                var timer = setTimeout(function() {
                                    if($scope.ccsFlag == true) {
                                        coremetrics.elementTag("CMR SIGNUP", "CMR STATUS SIGNUP");
                                    } else {
                                        coremetrics.elementTag("ECMR SIGNUP", "ECMR STATUS SIGNUP");
                                    }
                                    $state.go("wellness.ccs");
                                }, 5000);
                                var timer1 = setTimeout(function() {
                                    $window.location.reload();
                                }, 6000);
                            }else{
                                $scope.SignUpFail = true;
                            }

                        }
                    }, function(err) {
                        $scope.SignUpFail = true;
                    });
            };
        }
    ]);
    app.filter('tel', function () {
        return function (tel) {
            if (!tel) { return ''; }

            var value = tel.toString().trim().replace(/^\+/, '');

            if (value.match(/[^0-9]/)) {
                return tel;
            }

            var country, city, number;

            switch (value.length) {
                case 1:
                case 2:
                case 3:
                    city = value;
                    break;

                default:
                    city = value.slice(0, 3);
                    number = value.slice(3);
            }

            if(number){
                if(number.length>3){
                    number = number.slice(0, 3) + '-' + number.slice(3,7);
                }
                else{
                    number = number;
                }

                return ("(" + city + ") " + number).trim();
            }
            else{
                return "(" + city;
            }

        };
    });
};

