module.exports = function (app) {
    app.controller("pages.phishingNotification.phishingNotification", ["$state", "$scope", "services", "session", "brand","sessionData","$cookies", "modal", "$window", "coremetrics",
        function ($state, $scope, services, session, brand, sessionData, $cookies, modal, $window, coremetrics) {
                    $scope.backText = "back";
                    $scope.backAction = function(){
                                 window.history.back();
                             }
        }
    ]);
};








