module.exports = function (app) {

	'use strict';

    app.directive('otherSearches', [function () {

        return {
            restrict: 'A',
            replace: true,
			template: require('directives/preFindDoctor/otherSearches.html'),
			controller: ["$scope", "$q", '$compile', "teamsite", "sessionData",
				function ($scope, $q, $compile, teamsite, sessionData) {
					$scope.items = [];

                    $scope.items = [
                        {
                            title: "<a href='https://www.medicare.gov/physiciancompare/search.html?cm_sp=Medicare.gov%20Provider%20Search-_-FIND%20A%20DOCTOR-_-HOME' target='_blank' rel='noopener noreferrer'>Medicare.gov <span class='iconHeightZero hm-icon icon-openInNewWindow'></span></a>",
                            content: " "
                        },
                        {
                            title: "<a href='http://provider.bcbs.com/' target='_blank' rel='noopener noreferrer'>National Doctor and Hospital Finder <span class='iconHeightZero hm-icon icon-openInNewWindow'></span></a>",
                            content: " "
                        }
                    ];

                    if(!sessionData.isMedicare) {
                        $scope.items.push({
                            title: "<a href='http://www.ihs.gov/forpatients/findhealthcare/?cm_sp=Indian%20Health%20Service%20Provider%20Search-_-FIND%20A%20DOCTOR-_-HOME' target='_blank' rel='noopener noreferrer'>Indian Health Service <span class='iconHeightZero hm-icon icon-openInNewWindow'></span></a>",
                            content: " "
                        });
                    }
				}
			]
        };
    }]);
};
