module.exports = function(bootstrap) {
    bootstrap.run(['Service', 'appId','$cookies', function(Service, appId, $cookies) {
        new Service(
            {
                name: 'messageReadFlag',
                group: 'rbsmbl',
                url: '/rbsmbl/member-info/v1/ext/member/messages/v1/readstatusflag',
                headers:{ Authorization: 'Bearer '+  $cookies.get("Oauth_access_token")},

                update: function(messageList) {
                    var requestPayload = {
                        "reqUpdateMessageReadFlagRequest": {
                            "baseMessage": {
                                "exchangeType": "SENDREQUEST",
                                "headerVersion": "1.0",
                                "username": appId
                            },
                            "payload": {
                                "reqUpdateMessageReadFlagData": messageList
                            }
                        }
                    };
                    var hostnameArray = window.location.hostname.split('.');
                    var subDomain = hostnameArray[0];
                    if (subDomain == "www" || subDomain == "penv") var axwayHost= "https://api.hmhs.com";
                    var axwayHost = "https://apiqa.hmhs.com";
                    this.url= axwayHost + this.url;


                    return this.post(requestPayload);
                }
            }
        );
    }]);
};