module.exports = ['$scope', '$window', 'sessionData', function($scope, $window, sessionData) {
	$scope.loggedIn = !!sessionData.loggedIn;

	$scope.toClose = function (form) {
		$scope.login2(form).then(function (success) {
			$scope.closeInstructions();
		}, function (failure) {
			// Don't close the modal.
		});
	}

	$scope.redirectUser = function (form) {
		$window.location = form.interactiveLink;
		$scope.closeInstructions(form);
	}
}];