module.exports = function (app) {
    var _enabledCostSavingTools = [
        "MBR_CLAIMS_AND_SPENDING_CST_CARE_COST_ESTIMATOR",
        "MBR_CLAIMS_AND_SPENDING_CST_RX_COST_COMPARISON",
        "MBR_CLAIMS_AND_SPENDING_CST_SAVINGS_OPPORTUNITIES",
        "MBR_SPENDING_CST_CASLIGHT"
    ];

    app.controller('pages.claimsAndSpending.casCostSavingTools',
        ['cceService', 'contentResourceServices', 'resourcesList',  'rxccService', 'savOppService', 'services', 'sessionData', '$scope', '$state', '$q', 'finder-logic2',
            function (cceService, contentResourceServices, resourcesList, rxccService, savOppService, services, sessionData, $scope, $state, $q, finderLogic) {

                $scope.vitalsCCELink = finderLogic.getSapphireSSOUrl(sessionData.memberInfo.brand.toLowerCase());

        // Get display information for certain Cost Saving Tools
        function updateToolVisibility () {
            $q.all([cceService.shouldDisplay(), rxccService.shouldDisplay(), savOppService.shouldDisplay()]).then(function (resolution) {

                //setting this to false for RF664648
                //SP 1280- Remove Castlight link on the GMI post-login member
                var shouldDisplayCaslight = false;

                $scope.enabledTools = {
                    cce: {
                        shouldDisplay: resolution[0] && !shouldDisplayCaslight
                    },

                    rxcc: {
                        shouldDisplay: resolution[1],
                        doSSO: function() { rxccService.doSSORequest(); }
                    },
                    savopp: {
                        shouldDisplay: resolution[2],
                        doSSO: function () { savOppService.doSSORequest(); }
                    }
                };
            }, function (rejection) {
                /* Don't do anything. They'll all resolve. I promise. */
            });
        }


        // Determine whether or not the user's account has multiple plans associated with it.
        var isUserWithMultiplePlans = function () {
            var result = false;

            if (sessionData.loggedIn) {
                var clientList = sessionData.memberInfo && sessionData.memberInfo.clientList ? sessionData.memberInfo.clientList : [];
                result = clientList.length > 1;

                clientList.forEach(function (client) {
                    if (client.groupList.length > 1) {
                        result = true;
                    }
                });
            }

            return result;
        };

        updateToolVisibility();

        $scope.session = sessionData;
        $scope.multiplePlans = isUserWithMultiplePlans();
        $scope.timestamp = + new Date();
        $scope.navigateToViewSiteByPlan = function () {
            $state.go('vsbp', {redirect: '/login/#/claims-and-spending/cost-saving-tools'});
        };

        var filterFunction = function (item) {
            return item.sections
                && item.sections.memberClaimsAndSpendingCostTools;
        }

        $scope.$watch("session.loggedIn", function (newValue, oldValue) {
            $scope.tools = [];

            if (newValue !== oldValue) {
                // $scope.enabledTools = getEnabledToolsDisplayInfo();
                updateToolVisibility();
                $scope.multiplePlans = isUserWithMultiplePlans();
            }

            contentResourceServices.updateResourcesAsync(resourcesList, filterFunction).then(function (resources) {
                var costSavingTools = resources;
                $scope.tools = costSavingTools.filter(function (tool) {
                    return _enabledCostSavingTools.indexOf(tool["id"]) === -1;
                })
            }, function (error) {
                $scope.tools = [];
            });
        });
    }]);
};
