module.exports = function(app) {
    app.controller('pages.claimsAndSpending.casStatementArchive', ['$scope', 'statementArchiveService', 'coremetrics', 'storage', '$filter', 'session', '$state',
        function($scope, statementArchiveService, coremetrics, storage, $filter, session, $state) {
            if (!session.data.isSubscriber) {
                $state.go('404');
            }
            $scope.heading = 'STATEMENT ARCHIVE';
            $scope.viewState = 'loading';

            $scope.pagination = {
                itemsPerPage: '10'
            };
            $scope.pagination.page = 1;
            $scope.$watch('pagination.page', function(v) {
                storage.set('archiveRefinePage', v);
            });

            var createDateRange = function(startDaysAgo, endDaysAgo) {
                var
                    startDate = $filter('date')(new Date() - startDaysAgo*60*60*24*1000, 'yyyyMMdd'),
                    endDate = endDaysAgo
                        ? $filter('date')(new Date() - endDaysAgo*60*60*24*1000, 'yyyyMMdd')
                        : $filter('date')(new Date(), 'yyyyMMdd');

                return JSON.stringify({ startDate: startDate, endDate: endDate });
            };

            $scope.sidebar.archiveDateRangeOptions = [
                { label: "Last 12 Months", value: createDateRange(365) },
                { label: "Last 6 Months", value: createDateRange(180) },
                { label: "Last 90 Days", value: createDateRange(90) },
                { label: "Last 60 Days", value: createDateRange(60) },
                { label: "Last 30 Days", value: createDateRange(30) }
            ];

            var value = storage.get('archiveRefineDateRange');
            if (value) {
                var parsedValue = JSON.parse(value);
                var label =
                    parsedValue.startDate.substr(4,2) + '/' +
                    parsedValue.startDate.substr(6,2) + '/' +
                    parsedValue.startDate.substr(0,4) + ' - ' +
                    parsedValue.endDate.substr(4,2) + '/' +
                    parsedValue.endDate.substr(6,2) + '/' +
                    parsedValue.endDate.substr(0,4);

                var found = false;
                for (var i = 0; i < $scope.sidebar.archiveDateRangeOptions.length; i++) {
                    if ($scope.sidebar.archiveDateRangeOptions[i].value === value) {
                        $scope.sidebar.archiveDateRange = value;
                        found = true;
                    }
                }
                if (!found) {
                    $scope.sidebar.archiveDateRangeOptions.push({ label: label, value: value });
                    $scope.sidebar.archiveDateRange = value;
                }
            }
            else {
                $scope.sidebar.archiveDateRange = createDateRange(365);
            }


            $scope.sidebar.fetchStatements = function(dateRange) {
                dateRange = JSON.parse(dateRange || createDateRange(365));
                var startDate = dateRange.startDate.substr(0,4) + '-' + dateRange.startDate.substr(4,2) + '-' + dateRange.startDate.substr(6,2),
                    endDate = dateRange.endDate.substr(0,4) + '-' + dateRange.endDate.substr(4,2) + '-' + dateRange.endDate.substr(6,2);

                var filterStatementsByDateRange = function(statementList, dateRange) {
                    var start = Number(dateRange.startDate);
                    var end = Number(dateRange.endDate);

                    return statementList.filter(function(statement) {
                        var statementDate = Number(statement.endDate);
                        return start <= statementDate && statementDate <= end;
                    });
                };

                statementArchiveService.getStatements(startDate, endDate).then(
                    function(response) {
                        /*Fire off refine tag once per page visit*/
                        coremetrics.elementTag('REFINE:CLAIMS STATEMENT ARCHIVES','MEMBER:CLAIMS AND SPENDING');
                        $scope.viewState = 'success';
                        $scope.archive = filterStatementsByDateRange(response, dateRange);
                    },
                    function(err) {
                        $scope.viewState = 'error';
                        console.error('getStatements failed with error', err);
                    }
                );
            };

            $scope.$watch('sidebar.archiveDateRange', function(r) {
                for (var i = 0, options = $scope.sidebar.archiveDateRangeOptions, value; i < options.length; i++) {
                    value = options[i].value;

                    if (value === r) {
                        if (i < 5) {
                            options.splice(5, 1);
                        }
                    }
                }
                $scope.sidebar.fetchStatements($scope.sidebar.archiveDateRange);
            });
        }
    ]);
};