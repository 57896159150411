module.exports = function(app) {
    app.factory('educationalMaterialService', ['teamsite', 'brand', 'services', 'session','contentResourceServices','$q',
        function(teamsite, brand, services, session, contentResourceServices,$q) {

			// TODO: I think most of this can be removed. depreciated now that we have contentResourceServices
            var
                getAllEducationalMaterial,
                getFilteredEducationalMaterial,
                _filterMaterialList,
                getFilteredEducationalMaterialV2,
                getResourceIds,
                transformResponse,
                filterEntitlement,
                filterBlueEdgeDental,
                section,
                getKeywordRefineOptions,
                getFilteredMaterials,
                keywordFilter,
                originalMaterials=[],
                contentResourcePromise,
                contentResourceIdsPromise,
                filterParams= {};

            _filterMaterialList = function(materialList, isBlueEdge, currentBrand) {
                materialList = Array.isArray(materialList)
                             ? materialList
                             : [];

                var
                    _filterForBlueEdge,
                    _filterForBrand;

                _filterForBlueEdge = function(materialList) {
                    return materialList.filter(function(material) {
                        return material.planType === 'blueEdgeDental';
                    });
                };

                _filterForBrand = function(materialList) {
                    var _doesMatchBrand = function(theBrand, listOfBrands) {
                        return listOfBrands.some(function (aBrand) {
                            return aBrand.toLowerCase() === theBrand.toLowerCase();
                        });
                    };

                    return materialList.filter(
                        function (material) {
                            if (material.only && material.only.length) {
                                return _doesMatchBrand(currentBrand, material.only);
                            }
                            if (material.excludes && material.excludes.length) {
                                return !(_doesMatchBrand(currentBrand, material.excludes));
                            }
                            return true;
                        }
                    );
                };

                if (isBlueEdge) {
                    materialList = _filterForBlueEdge(materialList);
                }

                return _filterForBrand(materialList);
            };

            filterEntitlement = function(item) {
                //flag to add or remove the item from the list based on entitlement
                var add=false;
                var allUnchecked=true;
                var sectionVisible=item && item.sections && item.sections[section];
                if(item.excludeGeneric){
                    return false;
                }
                if (sectionVisible && item.entitlements) {
                    for(var key in item.entitlements){
                        allUnchecked= ( !allUnchecked || item.entitlements[key] ) ? false: true;
                        add= ( add || (item.entitlements[key] && filterParams[key]) ) ? true : false;
                    }

                }
                return (add || (allUnchecked && sectionVisible && session.data.loggedIn));
            };

            filterBlueEdgeDental = function(materialList) {
                return materialList.filter(function(item){
                    return item && item.sections && item.sections[section] && item.entitlements && item.entitlements.hasBlueEdgeDental && session.data.blueEdgeDentalIndv
                });
            };


            transformResponse = function(resources){
                resources.forEach(function(resource){
                    var optionalFields=resource.optionalFields ?  resource.optionalFields : {};
                    resource.linkHref=resource.href;
                    resource.embedUrl=optionalFields.embedUrl;
                    resource.dimensions=optionalFields.dimensions;
                    resource.width=optionalFields.width;
                    resource.height=optionalFields.height;
                    resource.duration=optionalFields.duration;
                });
            };

            getFilteredEducationalMaterialV2 = function(resourcesList,sectionName,loggedInWatchTriggered,spendingAccounts){
                var benefits = session.data.benefits || {};
                filterParams.hasPrePassword = !session.loggedIn;
                filterParams.hasDental = benefits.dental;
                filterParams.hasDrug = benefits.drug;
                filterParams.hasFSA = spendingAccounts.hasFSA;
                filterParams.hasHSA = spendingAccounts.hasHSA;
                filterParams.hasHRA = spendingAccounts.hasHRA;
                filterParams.hasMedical = benefits.medical || benefits.medicalActive;
                filterParams.hasPharmacy = false; // is this different than drug?
                filterParams.hasSpending = benefits.spending;
                filterParams.hasVision = benefits.vision;

                //if we are coming from spending state to claims state then we need to filter the material list again
                var stateChange=section !== sectionName ? true :false;

                section=sectionName;

                if(!contentResourcePromise || stateChange || loggedInWatchTriggered){
                    originalMaterials=[];
                    contentResourcePromise=contentResourceServices.updateResourcesAsync(resourcesList, filterEntitlement).then(function(resources){
                        transformResponse(resources);
                        originalMaterials=session.data.blueEdgeDentalIndv ? filterBlueEdgeDental(resources) : resources;
                        return originalMaterials;
                    });
                }

                return contentResourcePromise;
            };


            keywordFilter = function(keywordOptions,material){
                var keywords=material.keywords ? material.keywords : [];
                for(var i=0;i<keywords.length;i++){
                    if(keywordOptions[keywords[i].toUpperCase()]){
                        return true;
                    }
                }

                var allUnChecked=true;
                for(var key in keywordOptions){
                    if(keywordOptions[key]){
                        allUnChecked=false;
                    }
                }

                return allUnChecked;
            };

            getFilteredMaterials = function(sidebar){
                var keywordRefine=sidebar.keywordRefine;
                var keywordOptions=sidebar.keywordOptions;
                var value=keywordRefine ? keywordRefine.value : "";
                var newMaterials=[];

                originalMaterials.forEach(function(material){
                    if(material.title && material.title.toLowerCase().indexOf(value.toLowerCase())>=0 && keywordFilter(keywordOptions,material)){
                        newMaterials.push(material);
                    }
                });

                return newMaterials;
            };

            getKeywordRefineOptions = function(sidebarKeywordOptions){
                var keywordOptions={};
                originalMaterials.forEach(function(material){
                    var keywords=material.keywords ? material.keywords : [];
                    for(var i=0; i<keywords.length; i++){
                        keywordOptions[keywords[i].toUpperCase()]=sidebarKeywordOptions[keywords[i].toUpperCase()] ? true : false;
                    }
                });

                return keywordOptions;
            };

            getResourceIds = function(){
                if(!contentResourceIdsPromise){
                    contentResourceIdsPromise=contentResourceServices.getResourceIdListAsync();
                }
                return contentResourceIdsPromise;
            };

            return {
                getFilteredEducationalMaterialV2: getFilteredEducationalMaterialV2,
                getKeywordRefineOptions: getKeywordRefineOptions,
                getFilteredMaterials: getFilteredMaterials,
                getResourceIds: getResourceIds
            };
        }
    ]);
};