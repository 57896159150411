module.exports = function(app) {
    app.directive('prescriptsPriorAuthorization', function() {

        return {
            restrict: 'A',
            replace: true,
             template: require('directives/prescriptsPriorAuthorization.html'),
            // scope: {},
            controller: ['$scope', 'session', 'brand', 'teamsite',
                function($scope, session, brand, teamsite) {
                    //var showPriorAuthorization = $scope.directiveOptions.showPriorAuthorization;
               
                    var showPriorAuthorization = true;
                    var fullMemberInfo = session.data.memberInfo;
                    var theBrand = brand.current.sharedBrandCode;
                    var isHighmark = false;
                    var isOver65 = false;
                    var isMincr = false;

                    $scope.isMN = (theBrand === 'mincr');
                    $scope.isND = (theBrand === 'ndbcbs');
                    $scope.isWY = (theBrand === 'wybcbs');
                  // MINCR goes under highmark case in HTML logic, controlled in Teamsite.
                  switch(theBrand){
                    case "hmbcbs":
                    case "hbs":
                    case "pbs":
                    case "hmbcbsde":
                    case "hmbcbswv":
                    case "mincr":
                      case "ndbcbs":
                      isHighmark = true;
                    break;
                  }

                   if(fullMemberInfo && fullMemberInfo.clientList[0] && fullMemberInfo.clientList[0].groupList[0].productList[0] && fullMemberInfo.clientList[0].groupList[0].productList[0].memberList[0])
                  {
                    var birthDate = new Date(fullMemberInfo.clientList[0].groupList[0].productList[0].memberList[0].contact.birthDate);
                  }
                  var todaysDate = new Date();
                  var timeDiff = Math.abs(todaysDate.getTime() - birthDate.getTime());
                  var diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24*360)); 

                   if( diffDays >= 65)
                  {
                      var isOver65 = true;
                  }
                    $scope.uiModules.PriorAuthorization = {
                        over65 : isOver65,
                        isHighmark : isHighmark,
                        isMincr : isMincr,
                        hasBlueBackground: false,
                        isAct207Module: false,
                        isFormsAndActions: false,
                        isGenericModule: showPriorAuthorization,
                        isFAQBottom: false
                  };
                    teamsite.get('MBR_PRESCRIPTION_MEDEXCEPTREQ_LINK').then(function(response){
                        if(response != null || response!= undefined) $scope.showMedex = true;
                        else $scope.showMedex = false;
                    });
               }

            ]
        };
    });
};