module.exports = function (app) {
    // global vars may have to go here
    app.controller('pages.vision-health.vision-library', ["$state", "$scope", "teamsite", "$q", "contentResourceServices", "modal", function ($state, $scope, teamsite, $q, contentResourceServices, modal) {

        var visionArticles = [];
        teamsite.getAll("Bluecore Article").then(function (response) {
            visionArticles = response;

            var filterByVisionLibrary = function (item) {
                return item && item.sections && item.sections.memberVisionWellness;
            };

            contentResourceServices.updateResourcesAsync(visionArticles, filterByVisionLibrary, "Bluecore Article")
                .then(function (items) {
                    $scope.articles = items;
                });

        });

    }]);
};