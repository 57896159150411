module.exports = function(app) {
    app.directive('wellnessTreatmentTools', function() {

        return {
            restrict: 'A',
            replace: true,
            template: require('directives/wellnessTreatmentTools.html'),
            controller: ['$scope','coremetrics',
                function($scope, coremetrics) {

                    $scope.cmTreatmentToolClick = function(metricsId) {
                        if (metricsId && metricsId !== '') {
                            coremetrics.elementTag(metricsId, 'MEMBER:WELLNESS');
                        }
                    };

                }
            ]
        };
    });
}