module.exports = function(app) {
    app.directive('visualContent',
        function($compile) {
            return {
                restrict: 'EA',
                link: function (scope, element, attrs) {
                    var productID = scope.productID;
                    var projectID = scope.projectTypeID;
                    var genContentID = scope.genContentID;
                    var resourceConfig=scope.resourceConfig;

                    processImageParent();
                    processThumGlobals();
                    processRolloverGlobals();
                    processGlobalImage();
                    processMMParent();
                    processPDF();

                    //$compile(element.contents())(scope);

                    function isImageSeries(){
                        if(element.context.parentElement.title === '*IMAGE SERIES'){
                            return true;
                        }else{
                            return false;
                        }
                    }

                    function processImageParent(){
                        if(attrs.graphictype === "image_parent"){
                            if((productID === '101' && projectID === '3')||(productID ==='13')) {
                                scope.presentationImage[attrs.ordinal] = "<div ><img class='adamImage' src='"+scope.adam_domain_url+resourceConfig.image_parent + attrs.gencontentid + "." + attrs.mediatype + "'></img></div>";
                            }else if(isImageSeries()){
                                scope.subTitles[scope.numberOfImages] = element[0].innerHTML;
                                scope.presentationImage[scope.numberOfImages] = "<div ><img class='adamImage' src='"+scope.adam_domain_url+resourceConfig.image_parent + attrs.gencontentid + "." + attrs.mediatype + "'></img></div>";
                                scope.numberOfImages++;
                            }else if(productID === '117' && projectID === '2'){
                                scope.imageHTML="<div  ><img class='adamImage' src='"+scope.adam_domain_url+resourceConfig.image_parent + attrs.gencontentid + "." + attrs.mediatype + "'></img></div>";
                                scope.imageDescription=attrs.description;
                            }
                            else {
                                element.html("<img class='adamImage' src='" + scope.adam_domain_url + resourceConfig.image_parent + attrs.gencontentid + "." + attrs.mediatype + "' />");
                            }
                        }
                    }

                    function processThumGlobals(){
                        if(attrs.graphictype === "thumb_globals"){
                            element.html("");
                            //element.html("<div class='thumb-global-wrapper'><img class='thumb-globals' src='"+scope.adam_domain_url+ resourceConfig.thumbnail+attrs.gencontentid+"t."+attrs.mediatype+"' /> </div>");
                        }
                    }
                    function processRolloverGlobals(){
                        if(attrs.graphictype === "rollover_globals"){
                            element.html("");
                            //element.html("<img class='rollover_globals' src='"+scope.adam_domain_url+ resourceConfig.rollover_globals+attrs.gencontentid+"r."+attrs.mediatype+"' />");
                        }
                    }
                    function processGlobalImage(){
                        if(attrs.graphictype === "global_image"){

                        }

                    }
                    function processMMParent(){
                        if(attrs.graphictype === "mm_parent"){
                            if(attrs.mediatype === 'mp4' || attrs.mediatype === 'flv' || attrs.mediatype === 'mov' || attrs.mediatype === 'dcr' || attrs.mediatype === 'ogv'){
                                element.html("<video class='video-player' controls>"+"<source src='"+scope.adam_domain_url+resourceConfig.mm_parent+attrs.gencontentid+"/"+attrs.gencontentid+".mp4'"+ " type='video/mp4'> Your browser doesnot support video player </video>");
                                scope.videoSrc="<video class='video-player' controls>"+"<source src='"+scope.adam_domain_url+resourceConfig.mm_parent+attrs.gencontentid+"/"+attrs.gencontentid+".mp4'"+ " type='video/mp4'> Your browser doesnot support video player </video>";
                            }else if(attrs.mediatype === 'webm' ){
                                element.html("<video class='video-player' controls>"+"<source src='"+scope.adam_domain_url+resourceConfig.mm_parent+attrs.gencontentid+"/"+attrs.gencontentid+".webm'"+ " type='video/webm'>"+"<source src='"+scope.adam_domain_url+resourceConfig.mm_parent+attrs.gencontentid+"/"+attrs.gencontentid+".mp4'"+ " type='video/mp4'> Your browser doesnot support video player </video>");
                            }

                        }
                    }

                    function processPDF(){
                        if(attrs.graphictype === "pdf"){
                            element.html("<a href='" + scope.adam_domain_url + resourceConfig.pdf + attrs.gencontentid + "." + attrs.mediatype + "' >"+element[0].innerHTML+"</a>");
                        }
                    }
                }
            };
        }
    );
};