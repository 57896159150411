module.exports = function (app) {
    app.directive('contactUsOnlineForm', function () {
        return {
            restrict: 'A',
            replace: true,
            scope: {},
            template: require('directives/contactUsOnlineForm.html'),
            controller: ['$scope', '$filter', 'services', 'teamsite', 'brand', 'modal', function ($scope, $filter, services, teamsite, brand, modal) {
                $scope.namePattern="^[a-zA-Z][a-zA-Z '\-]+$";
                $scope.numberPattern="^[0-9][0-9]+$";
                $scope.phonePattern="^[0-9(][0-9) \-]+$";

                $scope.csToolTip = "<span>Your member ID is found on your ID card. Enter the numeric portion only.</span>";
                $scope.contactUsForm = {
                    firstName: "",
                    lastName: "",
                    umi: "",
                    contactMethod: "e",
                    reason: "",
                    comments: "",
                    phone: "",
                    emailAddress: "",
                    brand: brand.current.sharedBrandCode,
                    leaveMessage: "yes"
                };
                $scope.formData = {};

                $scope.formatPhone = function(){
                    var phoneNumber = $scope.contactUsForm.phone;
                    phoneNumber =  phoneNumber.replace(/[^\d+]/gi, '');
                    $scope.contactUsForm.phone =  $filter('formatPhone')(phoneNumber.substring(0,10));
                };

                //Message Topics
                teamsite.get("MBR_CONTACTUS_MESSAGE_TOPICS").then(function (response) {
                    var messageTopic = response.split(";");
                    $scope.topics = [];
                    angular.forEach(messageTopic, function (value) {
                        var topic = {
                            value: value,
                            label: value
                        };
                        $scope.topics.push(topic);
                    });
                });

                //Location
                teamsite.get("MBR_CONTACTUS_LOCATIONS").then(function (response) {
                    var locations = JSON.parse(response);
                });


                $scope.submit = function () {
                    if($scope.contactUsForms.$valid){
                        $scope.formData.firstName = $scope.contactUsForm.firstName;
                        $scope.formData.lastName = $scope.contactUsForm.lastName;
                        $scope.formData.umi = $scope.contactUsForm.umi;
                        $scope.formData.contactMethod = $scope.contactUsForm.contactMethod;
                        $scope.formData.reason = $scope.contactUsForm.reason;
                        $scope.formData.comments = $scope.contactUsForm.comments;
                        $scope.formData.phone = $scope.contactUsForm.phone.replace('(', '').replace(')', '').replace(/\s/g,'').replace('+', '').replace('-', '');
                        $scope.formData.emailAddress = $scope.contactUsForm.emailAddress;
                        $scope.formData.brand = $scope.contactUsForm.brand;
                        $scope.formData.leaveMessage = $scope.contactUsForm.leaveMessage;

                        services.rbsmbl.contactUsService().post({ payload: $scope.formData }).then(function (response) {
                            $scope.contactUsForm = {};
                            $scope.contactUsForms.$setPristine();
                            $scope.contactUsForms.$setUntouched();
                            modal.open({
                                template: '<p>THANK YOU. Your message has been received. A representative will contact you.</p>' +
                                    '<div class="margin-top text-right">' +
                                    '<button data-ng-click="closeModal()" class="primary">OK</button>' +
                                    '</div>'
                                });
                            },
                            function (error) {
                                modal.open({
                                    template: '<p>Unable to submit. Please try again later.</p>' +
                                        '<div class="margin-top text-right">' +
                                        '<button data-ng-click="closeModal()" class="primary">OK</button>' +
                                        '</div>'
                                });
                            });
                    }
                };

                $scope.cancel = function () {
                    $scope.contactUsForm = {};
                    $scope.contactUsForms.$setPristine();
                    $scope.contactUsForms.$setUntouched();
                };
            }]
        };
    });
};