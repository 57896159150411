// TODO: IMPORTANT: I mostly ripped this from stack overflow. Someone needs to make an informed decision here.
// TODO: Update: I've made some alterations

module.exports = function(bootstrap) {
    bootstrap.factory('uuidService', function() {
        var getUUID;

        getUUID = function(){
            var
                d = new Date().getTime(),
                uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx';

            uuid = uuid.replace(/[xy]/g, function(c) {
                var r = (d + Math.random()  *16) % 16 | 0;
                d = Math.floor(d/16);

                return c === 'x'
                    ? r
                    : (r & 0x3 | 0x8).toString(16);
            });

            return uuid;
        };

        return { getUUID : getUUID };
    });
}