module.exports = function(app) {
    app.controller('pages.wellness.ccs', ['$scope', '$state','teamsite','$stateParams','modal','services','coremetrics','$window', 'brand',
        function ($scope, $state, teamsite, $stateParams, modal, services, coremetrics, $window, brand) {
            $scope.ccsFlag = false;
            $scope.ccsEnhancedFlag = true;
            $scope.showSignUp = true;
            $scope.$parent.backText = "Back";
            $scope.isMINCR = (brand.current.sharedBrandCode === 'mincr') ? true : false;


            $scope.memberInfoInvokeCCS = function(){
                services.rbsmbl.vbbMemberInfo()
                    .getData()
                    .then(function(memberData) {
                        if(memberData && memberData.data && memberData.data.payload){
                            var memData = memberData.data.payload;
                            var pkgCode = memData.memberList[0].packages[0].packageCode;

                            $scope.ccsFlag = (pkgCode == "CONDITION MNGT");
                            $scope.ccsEnhancedFlag = (pkgCode == "PROTOCOL COMPL");
                            $scope.showCCS = false;

                            if($scope.ccsFlag || $scope.ccsEnhancedFlag){
                                if($scope.ccsFlag) {
                                    coremetrics.pageviewTag('CMR', 'WELLNESS: CMR');
                                    $scope.$parent.backCoreMetricsCategory = "CMR Sign up";
                                    $scope.showCCS = ($scope.isMINCR);
                                } else {
                                    coremetrics.pageviewTag('ECMR', 'WELLNESS: ECMR');
                                    $scope.$parent.backCoreMetricsCategory = "ECMR Sign up";
                                    $scope.showCCS = true;
                                }
                                $scope.$parent.backCoreMetricsElID = "Sign up: Identification Back button";
                            }

                            var showSignupLink = false;
                            for(var i=0; i<memData.memberList.length; i++){
                                var theMember = memData.memberList[i];
                                theMember.signedUp = theMember.packages[0].conditions[0].memberSignedUp;
                                if(theMember.signedUp == false){ showSignupLink = true; }
                                theMember.status = (theMember.signedUp) ? "Signed up" : "Not signed up";
                                memberDates = $scope.getDatesForMember(theMember, ($scope.ccsFlag));
                                theMember.signUpStatusDate = memberDates.benefitSignUpDate;
                                theMember.benefitEarnedDate = memberDates.benefitEarnedDate;
                                theMember.benefitExpiryDate = memberDates.benefitExpiryDate;
                            }

                            $scope.showSignUp = showSignupLink;
                            $scope.returnArray = memData.memberList;
                        }
                    });

            };

            $scope.getFormattedDate = function(dateString){
                var dteFormatter = new Date(dateString);
                var mon, day, yr;
                mon = dteFormatter.getMonth()+1;
                day = dteFormatter.getUTCDate();
                yr = dteFormatter.getFullYear();

                return mon + "/" + day + "/" + yr;
            };

            $scope.getDatesForMember = function(member, isCMR){
                var memberDates;
                var memberCondition = member.packages[0].conditions[0];
                var sortedByDateDesc = memberCondition.durations.sort(function(a,b){
                    a = new Date(a.benefitExpiryDate);
                    b = new Date(b.benefitExpiryDate);
                    return a>b ? -1 : a<b ? 1: 0; });
                var today = new Date();

                if(isCMR){
                    memberDates = {
                        benefitSignUpDate: $scope.getFormattedDate(memberCondition.benefitSignUpDate),
                        benefitEarnedDate: (sortedByDateDesc[0].benefitEarnedDate.length == 0) ? "Not yet earned" : $scope.getFormattedDate(sortedByDateDesc[0].benefitEarnedDate),
                        benefitExpiryDate: $scope.getFormattedDate(sortedByDateDesc[0].benefitExpiryDate)
                    }
                } else {
                    var nearestQuarter = null;
                    for(var iDur = 0; iDur < sortedByDateDesc.length; iDur++){
                        var qtr = sortedByDateDesc[iDur];
                        nqDate = new Date(qtr.benefitExpiryDate);
                        if(today > nqDate){
                            break;
                        } else {
                            nearestQuarter = qtr;
                        }
                    }
                    memberDates = {
                        benefitSignUpDate: $scope.getFormattedDate(memberCondition.benefitSignUpDate),
                        benefitEarnedDate: (nearestQuarter.benefitEarnedDate.length == 0) ? "Not yet earned" : $scope.getFormattedDate(nearestQuarter.benefitEarnedDate),
                        benefitExpiryDate: $scope.getFormattedDate(nearestQuarter.benefitExpiryDate)
                    }
                }
                return memberDates;
            };

            $scope.$parent.backAction = function(){
                $state.go("wellness");
            };

            $scope.signUp =function(){
                if($scope.ccsEnhancedFlag === true) {
                    coremetrics.elementTag('ECMR Sign up', 'Sign up: Identification Sign up Button');
                } else {
                    coremetrics.elementTag('CMR Sign up', 'Sign up: Identification Sign up Button');
                }
                $state.go("wellness.ccsSignUp");
            };

            $scope.$on('$stateChangeSuccess', function () {
                $scope.memberInfoInvokeCCS();
            });
        }
    ]);

};

