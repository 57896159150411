module.exports = function (app) {
	/**
	* @ngdoc ngController
	* @name pages.claimsAndSpending.hsaGroupSelection
	*
	* @requires services, $state, sessionData, $cookies, $scope, teamsite, $stateParams, modal, manualHsaService
	* @description
	* The pages.claimsAndSpending.hsaGroupSelection controller serves as a
	* child page controller to Claims & Spending.
	*/

	app.controller("pages.claimsAndSpending.hsaGroupSelection", 
		["services", "$state", "sessionData", "$cookies", "$scope", "teamsite", "$stateParams", "modal", 'manualHsaService',
		function (services, $state, sessionData, $cookies, $scope, teamsite, $stateParams, modal, manualHsaService) {
			var submitEnabled = true;
			if ($stateParams.hsaGroups.length > 0) {
				$scope.hsaGroups = $stateParams.hsaGroups;
				//object needed here, as the template's ng-repeat creates a 
				//child scope and breaks our $scope reference on submit
				$scope.userInput = { 
					selectedGroup: $scope.hsaGroups[0]
				};
			} else {
				$state.go('claimsAndSpending');
			}

			var refreshGroupsHsaAsPending = function(groupNum) {
				manualHsaService.setEnrollmentCookie(groupNum);
				$state.go('claimsAndSpending');
			};

			$scope.submitHsaEnrollment = function() {
				if (submitEnabled) {
					submitEnabled = false;
					var payload = {
						umi: sessionData.memberInfo.umi,
						groupNumber: $scope.userInput.selectedGroup.groupNumber,
						clientNumber: $scope.userInput.selectedGroup.clientNumber
					};
					services.rbsmbl.hsaEnrollment().submit(payload).then(function() {
						refreshGroupsHsaAsPending(payload.groupNumber);
					}, function(error) {
						if (error.data.baseMessage.returnCodeDescription === "HSA-TERMSCONDNOTACCPT") {
							var tnc = {
								"accepted" : true
							};
							services.rbsmbl.hsaTermsAndConditions().submit(tnc).then(function() {
								services.rbsmbl.hsaEnrollment().submit(payload).then(function() {
									refreshGroupsHsaAsPending(payload.groupNumber);
								}, function() {
									openErrorModal();
									submitEnabled = true;
								});
							}, function() {
								openErrorModal();
								submitEnabled = true;
							});
						} else {
							openErrorModal();
							submitEnabled = true;
						}
					});
				}
			};

			var openErrorModal = function(content) {
				var templateContent = '<p><span class="hm-icon icon-warning"></span> '
					+ (content || '<span data-teamsite="MBR_SPENDING_HSA_GROUP_SELECTION_ERROR_BODY"></span>') + "</p>";
				modal.open({
					heading: 'Spending Accounts',
					classes: "hsa-error-modal",
					template: templateContent
				});
			};
		}
	]);
};