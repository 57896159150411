// https://denv3.highmarkbcbs.com/login/#/coverage/health-insurance-101
module.exports = function(app) {

    app.controller('pages.coverage.healthInsurance101', ['$scope', '$state', '$q', '$cookies', '$anchorScroll', '$location', '$timeout', 'services', 'teamsite', 'coremetrics', 'brand', 'sessionData', 'finder-logic2', 'resourcesList', 'contentResourceServices', 'ssoHelper', 'cceService','location','modal',
        function($scope, $state, $q, $cookies, $anchorScroll, $location, $timeout, services, teamsite, coremetrics, brand, sessionData, finderLogic, resourcesList, contentResourceServices, ssoHelper, cceService, location, modal) {

            $scope.showSection = {
                healthPlans: false,
                glossary: false,
                save: false,
                ppo: false,
                epo: false,
                hmo: false,
                inNetwork: false,
                essential: false
            };
            $scope.easyPlanContent = false;
            window.document.title = "Health Insurance 101";

            var memberData = {
                "brand" : brand.current.sharedBrandCode,
                "memberQualifierInfo": {
                    "clientNumber": sessionData.memberInfo.clientList[0].clientNumber,
                    "groupNumber": sessionData.groupNumber,
                    "qualifierList": ["easyBlueIndicator", "referralRequiredIndicator"]

                }};
            services.rbsmbl.memberService().getData(memberData).then(function(response){
                $scope.easyPlanContent = response.data.payload.qualifierInfo.easyBlueIndicator;
                $scope.refReqInd = response.data && response.data.payload && response.data.payload.qualifierInfo &&
                    response.data.payload.qualifierInfo.referralRequiredIndicator == 'true' ? true : false;
            },function(error){
                $scope.easyPlanContent = false;
                $scope.refReqInd = false;
            });
            
            $scope.homeHostSuppression = false;
            if(brand.current.sharedBrandCode !== "mincr" ||brand.current.sharedBrandCode !== "wybcbs" || brand.current.sharedBrandCode !== "ndbcbs"){
                var homeHostClientNumber = [];
                teamsite.get("HOME_HOST_CLIENT_NUMBERS").then(function(content) {
                    homeHostClientNumber = JSON.parse(content);
                    var isHomeHostClientIndex = (homeHostClientNumber.indexOf($cookies.get("mbrClientNumber")) > -1);
                    if(isHomeHostClientIndex){
                        var portalSelectedPlan = services.rbsmbl.memberInfo().getPortalSelectedPlan();
                        for(var i = 0; i < portalSelectedPlan.optOutList.length; i++) {
                            var optOutListInfo = portalSelectedPlan.optOutList[i];
                            if(optOutListInfo.code === "904"){
                                $scope.homeHostSuppression = true;
                            }
                        }
                    }
                });
            }

            $scope.drawers = [];
            $scope.edubrand = brand.current.sharedBrandCode;
            // Set up isVitalsActive flag.
            $scope.isVitalsActive = (sessionData.appConfig && sessionData.appConfig.PROVIDER && sessionData.appConfig.PROVIDER.VITALS_LIVE) ? sessionData.appConfig.PROVIDER.VITALS_LIVE.booleanValue : false;
            $scope.isVitalsActive = $scope.isVitalsActive && brand.current.isVitalsActive;
            $scope.isVitalsLiveCCE = (sessionData.appConfig && sessionData.appConfig.PROVIDER && sessionData.appConfig.PROVIDER.VITALS_LIVE_CCE) ? sessionData.appConfig.PROVIDER.VITALS_LIVE_CCE.booleanValue : false;

            if($scope.edubrand==='wybcbs'){
                $scope.sizeBrand = true;
            }

            // Senior Markets gets different content for Health Plan Resources (the first drawer).
            var isMedicare = finderLogic.hasSeniorProduct() || finderLogic.isOver65();

            var filterHealthPlanResources = function(item) {
                if (item && item.sections && item.sections.memberHealthPlanResources) {
                      return true;
                }
                return false;
            };

			var CONTENT_TYPE = "Bluecore Resources";
			// TODO: Add additional entitlement to allow this to be content managed
            contentResourceServices.updateResourcesAsync(resourcesList, filterHealthPlanResources, CONTENT_TYPE).then(
                function(resources){
                    if(isMedicare){
                        var authFilter = resources.filter(function(arrayItem){ return arrayItem.priorAuth === true && !arrayItem.filterText; });
                        $scope.drawers[0] = authFilter[0];
                    } else {
                        var preauthFilter = resources.filter(function(arrayItem){ return arrayItem.priorAuth === true && arrayItem.filterText === 'preauth';});
                        $scope.drawers[0] = preauthFilter[0];
                    }
                    $scope.drawers[0].medPolicy = finderLogic.getPropertyByBrandCode(brand.current.sharedBrandCode, 'regionIsDe');
                    
                    $scope.drawers = $scope.drawers.concat(resources.filter(function(arrayItem){ return !arrayItem.hasOwnProperty('priorAuth');}));

                    for(var i = 0; i < $scope.drawers.length; i++){
                        $scope.drawers[i].mediaIcon = ($scope.drawers[i].type === "video" ? "icon-video":"icon-product-info" );
                        $scope.drawers[i].alt_Text = ($scope.drawers[i].type === "video" ? "Play video" :"open PDF");
                        $scope.drawers[i].target = ($scope.drawers[i].type === "video" ? "_self" : "_blank") ;
                        $scope.drawers[i].isVideo = ($scope.drawers[i].type === "video" ? true : false);
                        $scope.drawers[i].isExpanded = false;
                        if($scope.drawers[i].title == 'null' || $scope.drawers[i].title === ''){
                            $scope.drawers[i].hide = true;
                        }
                    }
            });

            $scope.$on('drawerOpen', function (event, args) {
                var isExpanded = event.targetScope.drawer.isOpen;
                $scope.drawers[event.targetScope.$index].isExpanded = isExpanded;
            });

            $scope.$on('drawerClose', function (event, args) {
                var isExpanded = event.targetScope.drawer.isOpen;
                $scope.drawers[event.targetScope.$index].isExpanded = isExpanded;
            });

            $scope.chcClick = function(){
                ssoHelper.chcSsoCall();
            };
            
            $scope.hppOpen = function () {
                setTimeout($scope.drawers[0].isOpen = true,400);
            };

            $scope.CoremetricsLab = function(){
                coremetrics.elementTag('Find an in-network lab','MEMBER');
            };
            $scope.CoremetricsXRay = function(){
                coremetrics.elementTag('Find an in-network imaging center','MEMBER');
            };
            $scope.CoremetricsUrgentCare =  function(){
                coremetrics.elementTag('Find an in-network urgent care center','MEMBER');
            };
            $scope.CoremetricsCCE =  function(){
                coremetrics.elementTag('Care Cost Estimator','MEMBER');
            };
            $scope.CoremetricsCHC =  function(){
                coremetrics.elementTag('Change Health Care','MEMBER');
            };
            $scope.CoremetricsClaims = function(){
                coremetrics.elementTag('See Claims','MEMBER');
            };
            $scope.CoremetricsHW =  function(){
                coremetrics.elementTag('Health & Wellness','MEMBER');
            };
            $scope.CoremetricsBlue365 =  function(){
                coremetrics.elementTag('Blue 365','MEMBER');
            };
            $scope.CoremetricsCoverageSummary = function(){
                coremetrics.elementTag('Visit Coverage Summary','MEMBER');
            };

            $scope.cmPreAuthList = function(path) {
                coremetrics.elementTag('Prior Authorization Procedures', 'MEMBER: YOUR COVERAGE');
                window.open(path,'_blank');

            };
            $scope.cmDEMedPolicy = function() {
                coremetrics.elementTag('Medical Policy', 'MEMBER: YOUR COVERAGE');
            };

            $scope.isPlanCancelled = function(currentProd) { //todo - sessionData or memberInfo, perhaps?
                var bRetVal = false;
                var nMemberIdx = 0;
                var mbr = currentProd.memberList[nMemberIdx];
                if (mbr.coverageCancellationDate != '')
                    bRetVal = ( (new Date(mbr.coverageCancellationDate)) < (new Date()) );

                return bRetVal;
            };
            $scope.navigateToDrawerLink = function(path){
                window.open(path,'_blank');
            };
            // init the loc object
            $scope.loc = {
                lat:null,lng:null
            };

            // if($scope.isVitalsActive) {
            //     // if SD code is active, we need to get the location
            //     location.getLocationFromBrowser().then(function (res) {
            //         location.location = res;
            //         location.uniqueId++;

            //         location.getLocationFromLatLng(res.location.lat, res.location.lng).then(function (res) {
            //             // set lat lng on the local scope to be used later
            //             $scope.loc.lat = location.location.location.lat;
            //             $scope.loc.lng = location.location.location.lng;
            //         });
            //     });
            // }

            $scope.showVitalsDisclaimerModal = function(specialty){
                // loc lat / lng has to be included in these calls
                // var geo_loc = $scope.loc.lat + "," + $scope.loc.lng;
                // var urlTemplate = "";
                /* TODO: All references to uat.highmark need to be modified to take out uat. for production
                 * so, going forward, needs code to take out the "uat." when in the Prod environment.
                 */
                if(typeof(specialty) != "undefined"){
                    // var domain = $location.host();
                    // var urlPrefix = (domain.search(/env\d?\./gi) > -1) ? "uat." : "";
                    // var ssoLinkBase = '/rbsmbl/x-services/public/sapphire/sso/' + $scope.brandData.sharedBrandCode + '/member?zip=' + sessionData.memberInfo.zipCode + '&url=';
                    // urlTemplate = 'https://' + urlPrefix + 'highmark.sapphirecareselect.com/search/search_specialties/[*SPECIALTY*]/1/%7B"limit":10,"radius":"25","guided_search_filters":true,"sort":"relevancy%20desc,%20random"%7D?ci=highmark&network_id=1000022&geo_location=' + geo_loc + '&locale=en_us';
                    // $scope.destUrl = ssoLinkBase + encodeURI(urlTemplate.split("[*SPECIALTY*]").join(specialty));
                } else {
                    $scope.destUrl = $scope.CCEUrl;
                }
                $scope.continueToDestination = function(){
                    window.open($scope.destUrl);
                    $scope.closeModal();
                };
                $scope.siteToLeave = window.location.host.indexOf("mybenefitshome.com") !== -1 ?"Your health plan": "Highmark";
                modal.open({
                    heading: "YOU WILL BE REDIRECTED TO SAPPHIRE DIGITAL",
                    template: require('modals/vitalsDisclaimerModal.html'),
                    scope:$scope
                });
            };

            var getTeamsiteContent = function() {
                // These IDs are hardcoded to values for the various types of direct links we need
                // TODO: make sure that these IDs point to the proper ones once all data is solidified
                var teamsiteLinks = {
                    'id1': teamsite.get("MBR_HEALTHINSURANCE101_GLOSSARY_SUBSECTION_COB_LINKURL"),
                    'id2': '980000065',
                    'id3': '980000040',
                    'id4': '980000046',
                    'id5': teamsite.get("MBR_HEALTHINSURANCE101_SAVE_MEDICALBILLS_LINKURL"),
                    'id6': teamsite.get("MBR_HEALTHINSURANCE101_SAVE_PLANWORKS_LINKURL"),
                    'id7': teamsite.get("MBR_HEALTHINSURANCE101_SAVE_GETBLOODTEST_LINKURL")
                };

                $q.all(teamsiteLinks).then(
                    function(content){
                        $scope.glossaryCobUrl = content.id1;
                        $scope.saveIndependentLabs = content.id2;
                        $scope.saveXraysImging = content.id3;
                        $scope.saveUrgentCare = content.id4;
                        $scope.saveMedicalBillsUrl = content.id5;
                        $scope.savePlanWorksUrl = content.id6;
                        $scope.saveGetBloodTestUrl = content.id7;

                        $scope.pvdLink = ($scope.isVitalsActive && ($scope.bIsMN || $scope.bIsWY || $scope.bIsND)) ? finderLogic.getSapphireSSOUrl(brand.current.sharedBrandCode) : $scope.saveGetBloodTestUrl;

                    },
                    function(error){

                    }
                );
            },
                scrollToHash = function(){
                    if($location.hash()){
                        $timeout(function(){$anchorScroll()},1500); //TODO - is it possible to get a better read on when all teamsite calls resolve?
                    }
                };

            getTeamsiteContent();

            $scope.IsActiveMedicalCoverage = function(thePrd) {
                var bRetval = false ;
                if (thePrd.benefitPlanType === "Medical") {
                    var dtToday = new Date(), dtBeginDate = new Date(thePrd.coverageBeginDate), dtEndDate = new Date(thePrd.coverageEndDate) ;
                    var strCancelDate =  ((thePrd.memberList) && (thePrd.memberList.length > 0) && (thePrd.memberList[0].coverageCancellationDate != '') ? thePrd.memberList[0].coverageCancellationDate : '' ) ;
                    var bCurrentlyCancelled = (strCancelDate == '' ? false : (new Date(strCancelDate)) < dtToday);
                    var bCurrentlyActive = (dtBeginDate <= dtToday) && (dtEndDate >= dtToday);

                    bRetval = bCurrentlyActive && ! bCurrentlyCancelled ;
                    bRetval = true ;
                }

                return bRetval ;
            };

            var checkForCCE = function(curGrp, curPrd, theRegion) {
                cceService.shouldDisplay().then( function(hasCCE) {
                    $scope.bShowCCE = hasCCE;
                }, function(error) {
                    $scope.bShowCCE = false;
                });
            };

            var initialize = function() {
                //basic info - sessionData.basicInfo
                //memberInfo - sessionData.memberInfo
                //serverdate?
                responses = [{}, {}, new Date(), {}]; //todo - factor out. included temporarily to avoid flow-breaking errors
                var serverDate = new Date(responses[2]) || new Date();
                var newData = sessionData.memberInfo;
               //var newData = responses[1].data;
                //newData.loginId = responses[0].data.payload.basicInfo.loginId;
                newData.loginId = sessionData.basicInfo.loginId;

                $scope.toolTip_deductable ="<p>If you do not have a deductible, your plan starts<br/> paying part of your care from the beginning. See phase  2 below.</p>";
                $scope.toolTip_emergency ="Emergency care services are an exception. <br/>They are covered at the same in-network rate" + "<span>&mdash;</span>" + "even if you go out-of-network.";

                var ums = newData;

                var nNumDental = 0 , nNumVision = 0 , nNumRx = 0, nNumMedical = 0, nNumSA = 0, nNumWP = 0, strBrand = ums.brand, nNumProducts, bDirectPayIndicator = '', nNumDP=0;
                var nIntTrueDental = 0 , nIntTrueVision = 0 , nIntTrueRx = 0, nIntTrueMedical = 0, strBrand = ums.brand, nNumProducts ;
                var nIntFalseDental = 0 , nIntFalseVision = 0 , nIntFalseRx = 0, nIntFalseMedical = 0, strBrand = ums.brand, nNumProducts ;
                var nNumRxClaimsTrue = 0 , nNumRxClaimsFalse = 0 ;
                var nBEBTrue = 0, nBEBFalse = 0, nBEPTrue = 0 , nBEPFalse = 0 ; nBlueDentalTrue = 0; nBlueVisionTrue = 0;
                var nHcrProdEmpty = 0 , nHcrProdNotEmpty = 0 ;
                var nHMO = 0, nPOS = 0; nHMOPOS = 0, nPPO= 0; nEPO = 0; nEPOExists = 0; nBDTC=0; nBDC=0;nBDCPlus=0;
                var bIsSubscriber = false ; // init to false
                var bShowPPO =false;
                strBrand= strBrand.toUpperCase();
                $scope.bIsShowImages = (strBrand !== "MINCR");
                $scope.bShowCCE = false;
                $scope.bShowCHC = false;
                $scope.bIsMN = (strBrand == "MINCR");
                $scope.bIsWY = (strBrand.toUpperCase() == "WYBCBS");
                $scope.bIsND = (strBrand.toUpperCase() == "NDBCBS");

                var curClnt = null, curGrp = null ;
                for (iClntIdx = 0 ; iClntIdx < ums.clientList.length ; ++iClntIdx) {
                    curClnt = ums.clientList[iClntIdx];
                    for (iGrpIdx = 0 ; iGrpIdx < curClnt.groupList.length ; ++iGrpIdx){
                        if (curClnt.groupList[iGrpIdx].groupNumber == $cookies.get("mbrGroupNumber")) {
                            curGrp = curClnt.groupList[iGrpIdx];
                            nNumProducts = curGrp.productList.length ;
                            bDirectPayIndicator = curGrp.directPayIndicator;
                            var bdcIndicatorVal = curGrp.bdcIndicator;
                            var bdcPlusIndicatorVal = curGrp.bdcPlusIndicator;
                            var bdtcIndicatorVal = curGrp.bdtcIndicator;
                            var bEpoExistsIndicator = (curGrp.atLeastOneEpoExistsIndicator == 'true');
                            var bVisionCancelled = false , bRxCancelled = false;
                            var TMOOPExists = false, OOPExists = false, BPExists = false;

                            if (  (curGrp.productList.length > 0) && (curGrp.productList[0].memberList.length > 0))
                                bIsSubscriber = (curGrp.productList[0].memberList[0].relationship == "Subscriber") ; //confirmed with Bryan

                            for (iPrdIdx = 0 ; iPrdIdx < curGrp.productList.length ; ++iPrdIdx){
                                var curPrd = curGrp.productList[iPrdIdx] ;

                                if (curPrd.benefitPlanType === "Medical") nNumMedical++ ;
                                if (curPrd.benefitPlanType === "Dental") nNumDental++ ;
                                if (curPrd.benefitPlanType === "Vision") nNumVision++ ;
                                if (curPrd.benefitPlanType === "Drug") nNumRx++ ;
                                if (curPrd.benefitPlanType === "Wellness Programs") nNumWP++;
                                if  (curPrd.benefitPlanType === "Spending Accounts") nNumSA++;

                                if ((curPrd.benefitPlanType === "Medical") && (curPrd.integratedCoverageIndicator == "true")) nIntTrueMedical++ ;
                                if ((curPrd.benefitPlanType === "Medical") && (curPrd.integratedCoverageIndicator == "false")) nIntFalseMedical++ ;
                                if ((curPrd.benefitPlanType === "Dental") && (curPrd.integratedCoverageIndicator == "true")) nIntTrueDental++ ;
                                if ((curPrd.benefitPlanType === "Dental") && (curPrd.integratedCoverageIndicator == "false")) nIntFalseDental++ ;
                                if ((curPrd.benefitPlanType === "Vision") && (curPrd.integratedCoverageIndicator == "true")) nIntTrueVision++ ;
                                if ((curPrd.benefitPlanType === "Vision") && (curPrd.integratedCoverageIndicator == "false")) nIntFalseVision++ ;

                                if ((curPrd.benefitPlanType === "Vision") && (curPrd.isBlueBrandedVision == "true")) nBlueVisionTrue++;
                                if ((curPrd.benefitPlanType === "Drug") && (curPrd.integratedCoverageIndicator == "true")) nIntTrueRx++ ;
                                if ((curPrd.benefitPlanType === "Drug") && (curPrd.integratedCoverageIndicator == "false")) nIntFalseRx++ ;


                                if (curPrd.benefitPlanType === "Drug") bRxCancelled = $scope.isPlanCancelled(curPrd);
                                if (curPrd.benefitPlanType === "Vision") bVisionCancelled = $scope.isPlanCancelled(curPrd);

                                if (curPrd.blueEdgeDentalIndv === "true") nBlueDentalTrue++ ;


                                if (curPrd.rxClaims === "true") nNumRxClaimsTrue++ ;
                                if (curPrd.rxClaims === "false") nNumRxClaimsFalse++ ;

                                if (curPrd.isBlueExtraBasic === "true") nBEBTrue++ ;
                                if (curPrd.isBlueExtraBasic === "false") nBEBFalse++ ;

                                if (curPrd.isBlueExtraPlus === "true") nBEPTrue++ ;
                                if (curPrd.isBlueExtraPlus === "false") nBEPFalse++ ;

                                if (curPrd.hcrProduct === "") nHcrProdEmpty++ ;
                                if (curPrd.hcrProduct !== "") nHcrProdNotEmpty++ ;

                                if (bDirectPayIndicator === "true") nNumDP++ ;

                                if(curPrd.lob == "HMO") nHMO++;
                                if((curPrd.lob == "HMO")&& ((curPrd.alphaPrefix == "JOL")||(curPrd.alphaPrefix == "JOF"))) nHMOPOS++;
                                if(curPrd.lob == "POS") nPOS++;
                                if(curPrd.lob == "PPO") nPPO++;
                                if(curPrd.lob == "EPO") nEPO++;


                                //if(curGrp.bdtcIndicator == "true")nBDTC++;
                                if(curGrp.bdcIndicator == "true")nBDC++;
                                if(curGrp.bdcPlusIndicator == "true")nBDCPlus++;

                                if ((curPrd.benefitPlanType === "Medical") && (curPrd.memberList.length > 0)) {
                                    $scope.CCEUrl = $scope.isVitalsLiveCCE ? finderLogic.getSapphireSSOUrl(strBrand.toLowerCase()) : "/cce/landing-page.html?gn=" + curGrp.groupNumber + "&ed=" + curPrd.memberList[0].coverageEffectiveDate.replace(/\//gi, '');
                                }

                                if (curPrd.benefitPlanType == "Medical") {
                                    checkForCCE()
                                }
                            } // end of product loop

                            $scope.bShowCHC = curGrp.chcIndicator === "true" ? true : false;
                            $scope.bEPOIndicatorExists = bEpoExistsIndicator;
                            $scope.bPPOExists = (nPPO > 0);
                            $scope.bHMOExists = (nHMO > 0);
                            $scope.bHMOPOSExists = (nHMOPOS > 0);
                            $scope.bEPOExists =  (nEPO > 0);
                            $scope.bShowPPO = (($scope.bPPOExists) && (!$scope.bEPOIndicatorExists));
                        } // if group number matches cookie valie
                    }
                }

                if (curGrp != null) {
                    // Find the medical prod, call scb and resolve the deffered to indicate we are all done.
                    var csbParams = { "payload": {"startDate": "", "endDate": "", "groupNum": "", "lob": "M", "productId": "", "memberPin": ""}};

                    for (iPrdIdx = 0 ; iPrdIdx < curGrp.productList.length ; ++iPrdIdx){
                        var curPrd = curGrp.productList[iPrdIdx] ;
                        if ($scope.IsActiveMedicalCoverage(curPrd))  {
                            csbParams = {
                                "payload": {
                                    "startDate": formatCoverageDate(curPrd.coverageBeginDate), // "05012014",  // curPrd.coverageBeginDate,  // 05/01/2014
                                    "endDate": formatCoverageDate(curPrd.coverageEndDate),  // "04302015",   // curPrd.coverageEndDate   // 04/30/2015
                                    "groupNum": curGrp.groupNumber,
                                    "lob": "M",
                                    "productId": curPrd.productNumber,
                                    "memberPin": curPrd.memberList[0].pinNumber
                                }
                            };
                            break ;
                        }
                    }

                    $scope.TMOOPExists = false;
                    $scope.OOPExists = false;
                    $scope.bMoreThan1Tier = false;
                    $scope.BPExists = false;

					// TODO: This call can be removed when there is no cbsParams
                    services.rbsmbl.costShareBenefit().fetch(csbParams).then(function(csbResp) {
                        $scope.TMOOPExists = csbResp.data.payload.coverageSummary.hasTMOOP;
                        $scope.OOPExists = csbResp.data.payload.coverageSummary.hasOOP;
                        $scope.bMoreThan1Tier = (csbResp.data.payload.coverageSummary.numberOfTiers > 1);
                        $scope.BPExists = (csbResp.data.payload.coverageSummary.accumulations.breakpointI || csbResp.data.payload.coverageSummary.accumulations.breakpointF) ? true:false;
                    }, function (csbFail) {
                        console.error(csbFail);
                    });

					// TODO: this needs to be refactored. Most of this can be handled in content management
                    $scope.bShowHPP = (
                    (nNumMedical + nNumVision + nNumRx > 0) &&     // Can not be ONLY DENTAL
                    (nNumMedical + nNumDental + nNumRx > 0) &&     // Can not be ONLY VISION
                    (nNumMedical + nNumDental + nNumVision > 0)&&  //  Can not be ONLY RX
                    (nBlueDentalTrue == 0) &&                      // BlueDental check
                    ((strBrand == "HMBCBS") || (strBrand == "PBS") || (strBrand == "HBS") || (strBrand == "DE") || (strBrand == "MS") || (strBrand == "WV") || (strBrand == "HHIC") || (strBrand == "MINCR") || (strBrand == "WYBCBS"))
                    ) ;

                    // If MN, always show HPP
                    if (strBrand == "MINCR" || strBrand == "WYBCBS")
                        $scope.bShowHPP = true ;

                    if ($scope.bShowHPP) {
                        for(var i = 0; i < $scope.drawers.length; i++){
                            $scope.drawers[i].mediaIcon = ($scope.drawers[i].type === "video" ? "icon-video":"icon-product-info" );
                            $scope.drawers[i].alt_Text = ($scope.drawers[i].type === "video" ? "Play video" :"open PDF");
                            $scope.drawers[i].target = ($scope.drawers[i].type === "video" ? "_self" : "_blank") ;
                            $scope.drawers[i].isVideo = ($scope.drawers[i].type === "video" ? true : false);
                            if($scope.drawers[i].title == 'null' || $scope.drawers[i].title === ''){
                                $scope.drawers[i].hide = true;
                            }
                        }
                    }

                    //for video
                    $scope.videoClick=function() {
                        new Modal('<iframe width="100%" height="315"  src="https://www.youtube.com/embed/ljw0OMrCQF0" frameborder="0" allowfullscreen></iframe>', {heading :'Understand terms used to describe your coverage', gridSize :'gridsize'});
                    };



                     var nQuesPos = document.location.hash.indexOf('?') ;
                     if (nQuesPos > 0) {
                         var objParams = ioQuery.queryToObject(document.location.hash.slice(nQuesPos+1));
                         if ((objParams['scrollTo'] != undefined) && (objParams['scrollTo'] != null)) {
                            document.getElementById(objParams['scrollTo']).scrollIntoView();
                         }
                     }

                }
                scrollToHash();
            };
            initialize();

            function formatCoverageDate(date){
                return date && date.replace(/\//g, '');
            }
        }
    ]);
};

