module.exports = function(app) {
    app.controller('pages.claimsAndSpending.casEducationalMaterial', ['$scope', 'educationalMaterialService', 'storage', 'updateVisited', 'coremetrics','resourcesList','contentResourceServices','$state','session','spendingResourceServices',
        function($scope, educationalMaterialService, storage, updateVisited, coremetrics,resourcesList,contentResourceServices,$state,session,spendingResourceServices) {
            $scope.viewState = 'loading';

            $scope.pagination = {
                itemsPerPage: '10'
            };
            $scope.pagination.page = 1;
            $scope.sidebar.keywordOptions={};
            $scope.session = session.data;
            $scope.keywordSearchRefineActive=false;

            $scope.$watch('pagination.page', function(v) {
                storage.set('educationalMaterialPage', v);
            });

            if($state.current.name === 'claimsAndSpending.casEducationalMaterial'){
                $scope.section='memberClaimsEducationalMaterials';
                $scope.isClaims=true;

                $scope.$on('prevClick', function() {
                    var prevPage = $scope.pagination.page;
                    prevPage -= 1;
                    coremetrics.pageviewTag('CLAIMS AND SPENDING:CLAIMS AND PAYMENTS ' + prevPage,'MEMBER:CLAIMS AND SPENDING');
                    coremetrics.elementTag('PREV:CLAIMS AND SPENDING','MEMBER:CLAIMS AND SPENDING');
                });

                $scope.$on('nextClick', function() {
                    var nextPage = $scope.pagination.page;
                    nextPage += 1;
                    coremetrics.pageviewTag('CLAIMS AND SPENDING:CLAIMS AND PAYMENTS ' + nextPage,'MEMBER:CLAIMS AND SPENDING');
                    coremetrics.elementTag('NEXT:CLAIMS AND SPENDING','MEMBER:CLAIMS SPENDING');
                });

                $scope.$on('goToPageClick', function() {
                    coremetrics.elementTag('SELECT PAGE NUMBER:CLAIMS AND SPENDING','MEMBER:CLAIMS AND SPENDING');
                });
            }else{
                $scope.section='memberSpendingEducationalMaterials';

                $scope.$watch('sidebar.links',function(oldValue,newValue){
                    updateSidebar();
                });
            }

            $scope.materials = contentResourceServices.resources;

            var updateSidebar=function(){
                $scope.sidebar.links.forEach(function(item){
                    if (item.state === "claimsAndSpending.casEducationalMaterial") {
                        item.refineTemplate = ($scope.materials && $scope.materials.length === 0) ? null : "pages/claims-and-spending/educational-material/casEducationalMaterialRefine.html";
                    }
                });
            };

            $scope.$watch("sidebar.keywordRefine.value",function(newValue,oldValue){
                if(newValue!==oldValue){
                    $scope.materials=educationalMaterialService.getFilteredMaterials($scope.sidebar);
                    $scope.keywordSearchRefineActive=true;
                }
            });

            $scope.sidebar.refineOptions=function(){
                $scope.materials=educationalMaterialService.getFilteredMaterials($scope.sidebar);
            };

            $scope.$watch("session.loggedIn", function(){
                $scope.keywordSearchRefineActive=false;
                var getSpendingAccountsPromise=spendingResourceServices.getSpendingAccounts();
                getSpendingAccountsPromise.then(
                    function(spendingAccounts){
                        educationalMaterialService.getFilteredEducationalMaterialV2(resourcesList,$scope.section,true,spendingAccounts).then(function(resources){
                            $scope.materials = resources;
                            if($scope.materials[0]){
                                $scope.materials[0].isOpen = true;
                            }

                            $scope.sidebar.keywordOptions=educationalMaterialService.getKeywordRefineOptions($scope.sidebar.keywordOptions);
                            $scope.sidebar.keywordsFilterActive=Object.keys($scope.sidebar.keywordOptions).length > 0;
                            updateSidebar();
                            $scope.viewState='success';
                        });
                    },
                    function(err) {
                        console.error('getSpendingAccounts failed with error', err);
                        $scope.viewState = 'error';
                    }
                );
            });

            $scope.sidebar.clearAll=function(){
                for(var key in $scope.sidebar.keywordOptions){
                    $scope.sidebar.keywordOptions[key]=false;
                }
                if(!$scope.sidebar.keywordRefine || $scope.sidebar.keywordRefine.value === ""){
                    $scope.materials=educationalMaterialService.getFilteredMaterials($scope.sidebar);
                }else{
                    // this will trigger watch function that will update the refine options
                    $scope.sidebar.keywordRefine.value="";
                }
            };

            if($scope.isClaims){
                updateVisited.educationalMaterial();
            }

        }
    ]);
};