module.exports = function(app) {
    app.directive('wellnessPreventiveSchedule', function() {

        return {
            restrict: 'A',
            replace: true,
            template: require('directives/wellnessPreventiveSchedule.html'),

            // scope: {},
            controller: ['$scope', 'preventiveScheduleService', 'services',
                function($scope, preventiveScheduleService, services) {

                    $scope.showDownloadIcon = true;
                    $scope.noIconClass = "";
                    $scope.preventiveURL = "";
                    if ($scope.isMINCR) {
                        $scope.showDownloadIcon = false;
                        $scope.noIconClass = "noIconSection";
                    }

                    $scope.getPSPDF = function(){
                        $scope.cmElementTagClick('preventiveSchedule');
                        preventiveScheduleService.getPreventiveSchedulePDF().then(function(response){//Nov Release changes
                            $scope.preventiveURL= response.data.payload.url;
                        });//Nov Release changes
                        };
                    }
                    ]
                };
        });
}