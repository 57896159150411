module.exports = function(app) {

    app.service('vsbpLogic', [ '$q', 'services',
        function($q, services) {
            this.MakePlanMembersArray = function(allMembers, thisPlan, isLoggedInUplId) {

                // Iterate the plans in the time frame ----------------------------
                // for (var i = 0; i < thisPlan.length; i++) {
                var arrSelectedMembers = [];

                // Iterate the members in the allMembers property -------------
                for (var j = 0; j < allMembers.length; j++) {
                    // allMembers[j].isLoggedIn
                    // Iterate the members coverage map -----------------------
                    // var arrCoverageMap = allMembers[j].coverageMap;
                    for (var k = 0; k < allMembers[j].coverageMap.length; k++) {
                        var aCoverageMap = allMembers[j].coverageMap[k];
                        // a coverageMap is an array that represents the member's plans
                        if (thisPlan.uplid == aCoverageMap.uplid) {
                            allMembers[j].memberId = aCoverageMap.suffixedUmi;
                            // allMembers[j].isLoggedIn = (aCoverageMap.uplid == isLoggedInUplId) ? true : false;
                            arrSelectedMembers.push(allMembers[j]);
                        }
                    }
                }
                // }
                if (arrSelectedMembers && arrSelectedMembers.length  && arrSelectedMembers.length > 0 ) {
                    for (var i = 0; i < arrSelectedMembers.length; i++) {
                        // we make it lower case here so that the css can capitalize the first letter (a quirk in css)
                        arrSelectedMembers[i].firstName = arrSelectedMembers[i].firstName.toLowerCase();
                    }
                }
                return arrSelectedMembers;
            }
        }  ]  )

    app.factory('emailConfirmationAlert', ['$q', 'services',
        function ($q, services) {
            var theResponse;
            theResponse = function (theBrand) {
                return services.rbsmbl.emailConfirmationAlert().getEAlert(theBrand).then(
                    function (response) {
                        if (response) {
                            return response;
                        } else {
                            return null;
                        } },
                    function (err) {
                        $q.reject(err);
                        return err;
                    }
                );
            }
            return {
                theAlert: theResponse
            };
        }
    ]);

    app.factory('ebillUserExists', ['$q', 'services',
        function ($q, services) {
            var theResponse;
            theResponse = function (theBrand) {
                return services.rbsmbl.ebillUserExists().getTargetUrl(theBrand).then(
                    function (response) {
                        if (response) {
                            return response;
                        } else {
                            return null;
                        } },
                    function (err) {
                        $q.reject(err);
                        return err;
                    }
                );
            }
            return {
                theUrl: theResponse
            };
        }
    ]);

    app.factory('spendingActionsNeeded', ['$q', 'services',
        function ($q, services) {
            var theResponse;
            theResponse = function (umi, clientNumber, theBrand) {
                return services.rbsmbl.spendingActionsNeeded().getActionsNeeded(umi, clientNumber, theBrand).then(
                    function (response) {
//                        if (response  && response.data && response.data.payload && response.data.payload.planInfo) {
//                            var planInfo = response.data.payload.planInfo;
//                            return planInfo;
                        if (response) {
                            // var planInfo = response.data.payload.planInfo;
                            return response;
                        } else {
                            return null;
                        }
                    },
                    function (err) {
                        $q.reject(err);
                        return err;
                    }
                );
            }
            return {
                theActions: theResponse
            };
        }
    ]);

    app.factory('getPlans', ['$q', 'services',
        function ($q, services) {
            var theResponse;
            theResponse = function (theBrand) {
                return services.rbsmbl.vsbpPlans().fetch(theBrand).then(
                    function (response) {
                        if (response && response.data && response.data.payload && response.data.payload.planInfo) {
                            var planInfo = response.data.payload.planInfo;
                            return planInfo;
                        } else {
                            return null;
                        }
                    },
                    function (err) {
                        $q.reject(err);
                        return err;
                    }
                );
            }
            return {
                thePlans: theResponse
            };
        }
    ]);
    app.factory('wellnessProfile', ['$q', 'services',
        function ($q, services) {
            var theResponse;
            theResponse = function (memberProperties) {
                return services.rbsmbl.wellnessProfile().getData(memberProperties).then(
                    function (theInfo) {
                        if (theInfo.data.payload && theInfo.data.payload && theInfo.data.payload) {
                            return theInfo;
                        } else {
                            return null;
                        }
                    },
                    function (err) {
                        $q.reject(err);
                        return err;
                    }
                );
            }
            return {
                ssoStuff: theResponse
            };
        }
    ]);





}