module.exports = function(app) {
    app.directive('dppOverview', function() {

        return {
            restrict: 'A',
            replace: true,
            template: require('directives/dppOverview.html'),
            controller: ['$scope', 'services', '$state',
                function($scope, services, $state) {

                    $scope.showDownloadIcon = true;
                    $scope.noIconClass = "";

                    $scope.getDPPDetails = function(){
                        $scope.cmElementTagClick('dppDetails');
                        $state.go("wellness.dppDetails",{wellnessOverviewModule:true});

                    }
                }

            ]
        };
    });
}
