module.exports = function  (bootstrap) {
    bootstrap.run(["Service", function (Service) {
        new Service({
            name: "visionSso",
            group: "rbsmbl",
            url: "/rbsmbl/x-services/secure/vision/davis/1", // TODO: Add new service endpoint.
            get: function (payload) {
                return this.get(payload); // TODO: If it's a post request, change this.get to this.post.
            }
        });
    }]);
};