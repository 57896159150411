module.exports = function(app) {
    app.directive('educationalVideoPanel', function() {

        return {
            restrict: 'A',
            replace: true,
            scope: {},

            template: require('directives/educationalVideoPanel.html'),

            controller: ['$scope', '$element', 'services', 'teamsite', 'brand', 'updateVisited', 'coremetrics',
                function($scope, $element, services, teamsite, brand, updateVisited, coremetrics) {


                    var videoClicked;
                    $scope.emASPlayVideoCoremetrics = function(){
                        if(!videoClicked){
                            coremetrics.elementTag('CLAIMS:PLAY EDUCATIONAL VIDEO:','MEMBER:CLAIMS');
                            videoClicked = true;
                        }
                    };

                    var populatePanel = function() {
                        teamsite.get('MBR_Claims_HowToNavigate_BCBS').then(
                            function(videoData) {
                                var
                                    currentBrand = brand.current.sharedBrandCode,
                                    mustExclude;

                                try {
                                    videoData = JSON.parse(videoData);
                                }
                                catch(err) {
                                    videoData = videoData instanceof Object
                                              ? videoData
                                              : {};
                                }

                                if (Array.isArray(videoData.excludes)) {
                                    mustExclude = videoData.excludes.some(function(brand) {
                                        return currentBrand.toLowerCase() === brand.toLowerCase();
                                    });
                                }
                                else {
                                    mustExclude = false;
                                }
                                var current_brand = (brand.current.sharedBrandCode).toLowerCase();
                                if(current_brand==='wybcbs' ){
                                    $scope.iswybcbs= true;
                                }if(current_brand==='ndbcbs' ){
                                    $scope.isndbcbs= true;
                                }
                                $scope.shouldShow = !mustExclude;
                                $scope.video = videoData;
                            },

                            function(err) {
                                console.error('teamsite.get failed with error', err);
                            }
                        );
                    };

                    services.rbsmbl.volatileMemberInfo().fetch().then(
                        function(response) {
                            var hasVisited = response.data && response.data.payload && response.data.payload.lastEdMaterialVisitDate;

                            if (!hasVisited) {
                                populatePanel();
                            }
                        },
                        function(err) {
                            console.error('volatileMemberInfo().fetch() failed with error', err);
                        }
                    );

                    $scope.closeMessage = function() {
                        updateVisited.educationalMaterial().then(
                            function() {
                                $element.css({ display: "none" });
                            },
                            function(err) {
                                console.error('updateVisited.educationalMaterial failed with error', err);
                            }
                        );
                    };
                }
            ]
        };
    });
};