module.exports = function(app) {
    app.directive('saveMoney', ["session","sessionData",function(session,sessionData) {

        return {
            restrict: 'A',
            replace: true,
            controller : ['network','services','$scope',
				function (network, services, $scope) {
					$scope.enabled = true;
				}
			],
            templateUrl: 'directives/preFindDoctor/saveMoneyTeamsite.html'
        };
    }]);
};