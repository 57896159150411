module.exports = function (app) {
    app.directive('claimSummaryDownload', function () {

        return {
            restrict: 'A',
            replace: true,
            scope: {},

            template: require('directives/claimSummaryDownload.html'),

            controller: ['$http', 'claimService', 'invisibleFormService', '$scope', '$cookies', 'storage',
                function ($http, claimService, invisibleFormService, $scope, $cookies, storage) {

                    // var
                    //     _createExcelServicePayload,
                    //     downloadSummary;


                    // _createExcelServicePayload = function(summary) {
                    //     summary = Array.isArray(summary)
                    //             ? summary
                    //             : [];

                    //     var payload = summary.map(function(claim) {
                    //         var
                    //             claimStatusText = claimService.getClaimStatus(claim.bilgProvClmStaCd).description,

                    //             bilgProvOrgNm = typeof claim.bilgProvOrgNm === 'string'
                    //                             ? claim.bilgProvOrgNm.toLowerCase()
                    //                             : '',

                    //             bilgProvTotMbrLiab = claim.bilgProvTotMbrLiab
                    //                                ? claim.bilgProvTotMbrLiab.toFixed(2)
                    //                                : 0;

                    //         return {
                    //             "mbrFirstNm": claim.mbrFirstNm,
                    //             "mbrMiddleNm": claim.mbrMiddleNm || "",
                    //             "mbrLastNm": claim.mbrLastNm,
                    //             "mbrSuffixNm": claim.mbrSuffixNm || "",
                    //             "mbrBirthDate": claim.mbrBirthDate,
                    //             "bilgProvOrgNm" : bilgProvOrgNm,
                    //             "bilgProvClmStaCd": "1",
                    //             "bilgProviderId": claim.bilgProviderId,
                    //             "clnCnRuleSetClaimsPcsgNo": claim.displayGroupNumber,
                    //             "claimNumber": claim.claimNumber,
                    //             "bilgProvSvcBgnDt": claim.bilgProvSvcBgnDt,
                    //             "bilgProvTotMbrLiab": bilgProvTotMbrLiab == 0 ? "0.00" : bilgProvTotMbrLiab,
                    //             "claimStatusText": claimStatusText,
                    //             "paymentInfo": claim.paymentInfo
                    //         };
                    //     });

                    //     return {
                    //         name: 'GetClaimXLS',
                    //         method: 'POST',
                    //         action: '/rbsmbl/x-services/download/claimdetails/file',
                    //         inputs: {
                    //             payload: JSON.stringify({ 'payload': payload })
                    //         }
                    //     };
                    // };


                    var downloadSummary = function () {
                        var claimsAndPaymentsDateRange = JSON.parse(storage.get('claimRefineDateRange'));
                        var hostnameArray = window.location.hostname.split('.');
                        var subDomain = hostnameArray[0];
                        var loginId = $cookies.get("loginId")? $cookies.get("loginId") : $cookies.get("emkt_userName").slice(6);
                        if (subDomain == "www" || subDomain == "penv") {
                            var axwayHost = "https://api.hmhs.com";

                            return $http({
                                method: 'GET',
                                headers: {
                                    Authorization: 'Bearer ' + $cookies.get("Oauth_access_token"),
                                    'brand': $cookies.get("umsBrand").toLowerCase(),
                                    'fromdp': loginId
                                },
                                responseType: 'blob',
                                url: axwayHost + '/rbsmbl/v2/ext/download/claimdetails/file?startDate=' + claimsAndPaymentsDateRange.startDate +
                                    '&endDate=' + claimsAndPaymentsDateRange.endDate,///rbsmbl/x-services/download/claimdetails/file',

                            });
                        } else {
                            var axwayHost = "https://apiqa.hmhs.com";
                            return $http({
                                method: 'GET',
                                headers: {
                                    Authorization: 'Bearer ' + $cookies.get("Oauth_access_token"),
                                    'x-hmhs-test-backend': 'wasintertenv' + subDomain.slice(-1) + '.highmark.com',
                                    'brand': $cookies.get("umsBrand").toLowerCase(),
                                    'fromdp': loginId
                                },
                                responseType: 'blob',
                                url: axwayHost + '/rbsmbl/v2/ext/download/claimdetails/file?startDate=' + claimsAndPaymentsDateRange.startDate +
                                    '&endDate=' + claimsAndPaymentsDateRange.endDate,///rbsmbl/x-services/download/claimdetails/file',

                            });

                        }


                        // invisibleFormService.submit(
                        //     {
                        //         name: 'GetClaimXLS',
                        //         method: 'GET',
                        //         action: axwayHost + '/rbsmbl/claims/v3/download/claimdetails/file',///rbsmbl/x-services/download/claimdetails/file',
                        //         target: '_blank',
                        //         inputs: {
                        //             Authorization: 'Bearer '+  $cookies.get("Oauth_access_token"),
                        //             startDate :claimsAndPaymentsDateRange.startDate,
                        //             endDate : claimsAndPaymentsDateRange.endDate
                        //         }
                        //     }
                        // );




                        /*var claimsAndPaymentsDateRange = storage.get('claimRefineDateRange');
                        claimService.getSummary(claimsAndPaymentsDateRange).then(
                            function(response) {
                               coremetrics.elementTag('DOWNLOAD SUMMARY DOWNLOAD:CLAIMS','MEMBER:CLAIMS');
                               invisibleFormService.submit(
                                   _createExcelServicePayload(response)
                               );
                            },

                            function(err) {
                                console.error('getSummary failed with error', err);
                            }
                        );*/
                    };

                    $scope.downloadSummary = function (){
                        downloadSummary().then(function(response){
                        var url = URL.createObjectURL(
                            new Blob([response.data], {
                              type: "application/vnd.ms-excel"
                            })
                          );
                          var link = document.createElement("a");
                         
                          link.href = url;
                          link.setAttribute("download", "claimsSummary.xls");
                          document.body.appendChild(link);
                          window.open(link,"_blank");
                         
                          
                    });
                }
                }
            ]
        };
    });
};
