module.exports = function (app) {
    app.controller("pages.postEnrollment.postEnroll", ["$state", "$scope", "services", "session", "brand","sessionData","$cookies", "modal", "$window", "coremetrics",
        function ($state, $scope, services, session, brand, sessionData, $cookies, modal, $window, coremetrics) {
        	coremetrics.pageviewTag('MEMBER:POST ENROLLMENT', 'MEMBER'); 

            $scope.isDE = brand.current.sharedBrandCode == "hmbcbsde";
            $scope.isWPA = brand.current.sharedBrandCode == "hmbcbs";
            window.document.title = "Post enroll";

            $scope.cmRegisterClick = function(){
                coremetrics.elementTag('MEMBER REGISTRATION','MEMBER');
            }

            $scope.cmPayPremiumClick = function(){
                coremetrics.elementTag('PRE EFFECTUATED: PAY PREMIUM','PRE EFFECTUATED'); 
            }

            $scope.cmFindDoctorClick = function(){
                coremetrics.elementTag('PRE EFFECTUATED: FIND A DOCTOR','PRE EFFECTUATED'); 
            }

            $scope.cmDoctorRightClick = function(){
                coremetrics.elementTag('PRE EFFECTUATED: MATCH DR RIGHT','PRE EFFECTUATED'); 
            }
 
            $scope.$watch(function () {
                return sessionData.loggedIn;
            }, function (newValue, oldValue) {
                if (newValue) {
                    $scope.isUserLoggedIn = !!newValue;
                }
            });
        }
    ]);
};
