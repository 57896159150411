module.exports = function (app){
	app.service("manualHsaService", ['$cookies', function($cookies){
        var today = new Date();

        /** Helper functions :) **/
        this.hasSubscriberInfo = function(productInfo) {
            var hasSubscriberInfo = false;
            productInfo.memberList.forEach(function(member) {
                if (member.relationship == "Subscriber") { hasSubscriberInfo = true; }
            });
            return hasSubscriberInfo;
        };

        this.isSubscriberCancelled = function(productInfo) {
            var isSubscriberCancelled = false;
            var self = this;
            productInfo.memberList.forEach(function(member) {
                if (member.relationship == "Subscriber" && member.coverageCancellationDate != "" && !isSubscriberCancelled) {
                    isSubscriberCancelled = self.isDateStringBeforeToday(member.coverageCancellationDate);
                }
            });
            return isSubscriberCancelled;
        };

        this.isCoverageCancelled = function(productInfo) {
            var self = this;
            if (productInfo.coverageVoid == "true") {
                return true;
            } else if ( productInfo.coverageCancelDate != "" ) {
                return self.isDateStringBeforeToday(productInfo.coverageCancelDate);
            }
            return false;
        };

        this.isDateStringBeforeToday = function(cancelString) {
            var cancelDateParts = cancelString.split("/");
            var cancelDate = new Date(cancelDateParts[2], cancelDateParts[0] - 1, cancelDateParts[1]);
            return cancelDate.getTime() < today.getTime();
        };

        this.setEnrollmentCookie = function(groupNum) {
            var expiration = new Date(Date.now() + (2*60*60*1000)); //2 hours in future, in ms
            $cookies.put('hsaEnrollmentSuccess', groupNum, {expires: expiration});
        };

        this.isUserEnrolledInCookie = function(memberData) {
            var group = $cookies.get('hsaEnrollmentSuccess');
            if(group && Array.isArray(memberData.clientList)) {
                for(var x = 0; x < memberData.clientList.length; x++) {
                    var client = memberData.clientList[x];
                    for(var y = 0; y < client.groupList.length; y++) {
                        if (client.groupList[y].groupNumber === group) {
                            return true;
                        }
                    }
                }
            }
            return false;
        };

        this.shouldUserSeeHSAPage = function(memberData) {
            var self = this;
            // MN Core members not eligible, plus data check for loops below
            if (memberData.brand.toLowerCase() === "mincr" || !Array.isArray(memberData.clientList)) {
                return false;
            }

            /**  Rules for determining if member is eligible for HSA
             * 1.) Must have medical coverage that is HDHP or PPO
             * 2.) Must be in manual enroll group
             * 3.) Must not be in blackout or group setup 
             * 4.) Must not already have an HSA for the particular client
             * 5.) Medical Coverage must be active with no end date or cancellation date
             * 6.) Must be the subscriber
             */
            var shouldSeeHSAPage = false;
            memberData.clientList.forEach(function(client) {
                //never eligible for manual enroll during black out
                if (client.spendingAccountBlackoutIndicator !== "true") { 
                    var hsaExistForClient = false;
                    var clientEligibleForHSA = false;

                    client.groupList.forEach(function(groupItem) {
                        if (groupItem.hsaExistsIndicator == "true" || groupItem.hsaEnrollmentInProcessIndicator == "true") {
                            hsaExistForClient = true;
                        }

                        if (!hsaExistForClient &&
                                groupItem.autoEnrollIndicator == "false" &&
                                groupItem.hsaSetupIndicator == "true" &&
                                groupItem.openHsaAccessIndicator == "true") {
                            groupItem.productList.forEach(function(productItem) {
                                if (self.hasSubscriberInfo(productItem) 
                                    && !self.isSubscriberCancelled(productItem)) {
                                    //don't allow members to open HSAs for expired coverages
                                    if (productItem.coverageEndDate != "" 
                                        && productItem.coverageEndDate != null 
                                        && !self.isDateStringBeforeToday(productItem.coverageEndDate)) {
                                        //don't allow members with void/cancelled dates to open HSAs
                                        if (!self.isCoverageCancelled(productItem)) {
                                            if (productItem.benefitPlanType.indexOf("Medical") != -1 && productItem.hdhpIndicator == "true") {
                                                clientEligibleForHSA = true;
                                            }
                                        }
                                    }
                                }
                            });
                        }
                    });
                    if (hsaExistForClient || clientEligibleForHSA) {
                        shouldSeeHSAPage = true;
                    }
                }
            });
            return shouldSeeHSAPage;
        };

    }]);
};