module.exports = function(app) {
    app.directive('messageDrawer', ['$state', 'messageService', '$timeout', 'modal', 'sidebar',
        function($state, messageService, $timeout, modal, sidebar) {

            return {
                restrict: 'A',
                replace: true,
                template: require('directives/messageDrawer.html'),

                scope: {
                    message: '=messageDrawer',
                    memberList: '=?'
                },


                controller: ['$scope', 'validationService', 'coremetrics', '$element', '$sce', '$timeout', 'teamsite',
                    function($scope, validationService, coremetrics, $element, $sce, $timeout, teamsite) {
                        var
                            message = $scope.message,
                            setMessageStatus;

                        var priorAuthTS = {
                           'approved' : 'PRIOR_AUTH_UI_APPROVED',
                           'denied': 'PRIOR_AUTH_UI_DENIED',
                           'partial approval': 'PRIOR_AUTH_UI_PARTIALLY_APPROVED',
                           'pend': 'PRIOR_AUTH_UI_IN_REVIEW',
                           'cancelled': 'PRIOR_AUTH_UI_CANCELLED'
                        };



                        if(message.priorAuthData && message.priorAuthData.status){
                            teamsite.get(priorAuthTS[message.priorAuthData.status]).then(function (response){
                                var messageContent = JSON.parse(response.replace(/\r?\n|\r/g, ''));
                                $scope.body = messageContent.Body;
                                $scope.subject = messageContent.Subject;
                                $scope.priorAuthData = message.priorAuthData;

                                if(message.priorAuthData.status === 'approved' || message.priorAuthData.status === 'partial approval'){
                                    $scope.priorAuthData.showCCE = $scope.displayCCE(message.priorAuthData.minAvgCost,message.priorAuthData.maxAvgCost);
                                    $scope.priorAuthData.facilityExist = !$scope.isNullOrEmptyOrUndefined(message.priorAuthData.facilityVendorName.trim());
                                    $scope.priorAuthData.avgCostRange = $scope.isRangeEqual(message.priorAuthData.minAvgCost,message.priorAuthData.maxAvgCost);
                                    $scope.priorAuthData.dCostRange = $scope.isRangeEqual(message.priorAuthData.minDeductibleCost,message.priorAuthData.maxDeductibleCost);
                                    $scope.priorAuthData.oopCostRange = $scope.isRangeEqual(message.priorAuthData.minOOPCost,message.priorAuthData.maxOOPCost);
                                    $scope.priorAuthData.cpCostRange = $scope.isRangeEqual(message.priorAuthData.minCoPay,message.priorAuthData.maxCoPay);
                                    $scope.priorAuthData.hideCoPay = $scope.hideRow(message.priorAuthData.minCoPay,message.priorAuthData.maxCoPay);
                                    $scope.priorAuthData.ciCostRange = $scope.isRangeEqual(message.priorAuthData.minCoInsurance,message.priorAuthData.maxCoInsurance);
                                    $scope.priorAuthData.hideCoInsurance = $scope.hideRow(message.priorAuthData.minCoInsurance,message.priorAuthData.maxCoInsurance);
                                }else{
                                    $scope.priorAuthData.showCCE=false;
                                }
                            }, function(error){
                                console.error('messageDrawer : get Prior Authorization Messages from teamsite failed with error', error);
                            });
                        } else {
                            $scope.body = message.messageContent.Body;
                            $scope.subject = message.messageContent.Subject;
                        }
                        $scope.displayCCE = function(minCost,maxCost){
                            return ($.trim(maxCost)>0 || $.trim(minCost)>0);

                        };
                        $scope.isRangeEqual = function(minCost,maxCost){
                            return ($.trim(maxCost)== $.trim(minCost));
                        };
                        $scope.hideRow=function(minCost,maxCost){
                            if( !$scope.isNullOrEmptyOrUndefined(minCost) && !$scope.isNullOrEmptyOrUndefined(maxCost) ){
                                return ($.trim(maxCost)==0 && $scope.isRangeEqual(minCost, maxCost));
                            }
                            return true;
                        };

                        $scope.isNullOrEmptyOrUndefined = function (value) {
                            return !value;
                        };
                        setMessageStatus = function(isOpen) {

                            //Fix for Google Map rendering
                            $timeout(function(){
                                    var resizeEvent = window.document.createEvent('UIEvents');
                                    resizeEvent.initUIEvent('resize', true, false, window, 0);
                                    window.dispatchEvent(resizeEvent);
                                }, 500);

                            if (message.isSpecial) { return; } // Do not attempt to mark special messages as read

                            if (isOpen && message.messageStatus === 'u') {
                                coremetrics.elementTag('CLAIMS MESSAGE READ:CLAIMS','MEMBER:CLAIMS');
                                messageService.setStatus(message, 'R').then(
                                    function() {
                                        message.messageStatus = 'R';
                                        sidebar.decrementStarburst(sidebar.getLinkByLabel('Messages'));
                                    },
                                    function(err) {
                                        console.error('messageStatus.setStatus failed with error:', err);
                                    }
                                );
                            }
                        };

                        message.isSpecial = typeof message.messageCategory === 'string'
                                          ? message.messageCategory.toLowerCase() === 'special'
                                          : false;

                        if (message.isSpecial) {
                            $scope.memberList = $scope.memberList || message.messageContent.memberList || [];
                            var specialMessageMemberList = message.messageContent.memberList;

                            $scope.$watchCollection('memberList', function(currentMemberList) {
                                $scope.currentSpecialMembers = currentMemberList.filter(function(member) {
                                    return specialMessageMemberList.some(function(specialMember) {
                                        return member.toLowerCase() === specialMember.toLowerCase();
                                    });
                                }).map(function(member) {
                                    member = member.toLowerCase();
                                    return member.charAt(0).toUpperCase() + member.slice(1);
                                });

                                $scope.subject = message.messageContent.Subject.replace(/\$(\{|\<)Names(\}|\>)/g, $scope.currentSpecialMembers.join(', '));
                                $scope.body = message.messageContent.Body.replace(/\$(\{|\<)Names(\}|\>)/g, $scope.currentSpecialMembers.join(', '));

                            });
                            $scope.radioValue = 'SMS'; // sets placeholder default value
                        }

                        message.iconUnicode = message.messageContent.Image1
                                            ? '&#x' + message.messageContent.Image1 + ';'
                                            : '';

                        //message.isOpen = message.messageFilterID === $state.params.id;
                        message.isOpen = (message.isOpen) ? message.isOpen : message.messageFilterID === $state.params.id;

                        if (message.messageFilterID === $state.params.id) {
                            $scope.$parent.$evalAsync(
                                function() {
                                    $('html, body').animate({ scrollTop: ($element[0].offsetTop + $scope.$parent.$index * $element.height()) }, 1);
                                }
                            );
                        }

                        $scope.$watch('message.isOpen', function(newVal) {
                            setMessageStatus(newVal);
                        });

                        $scope.notify = function(notificationType, phoneNumberOrEmailAddress, isInvalid) {

                            if (isInvalid) {
                                return;
                            }

                            messageService.notifyDependent(notificationType, phoneNumberOrEmailAddress).then(
                                // TODO: possibly move modal content into templates; discuss styling with Frank
                                function() {
                                    modal.open(
                                        {
                                            heading: 'Thank You',
                                            template: '<p>Your notification has been sent.</p>'
                                        }
                                    );
                                },
                                function() {
                                    modal.open(
                                        {
                                            heading: 'Error',
                                            template: '<p>We are unable to send your message now. Please try again later.</p>'
                                        }
                                    );
                                }
                            );
                        };

                        $scope.emailPattern = validationService.patterns.email;
                        $scope.emailPatternMessage = validationService.messages.email.pattern;
                        $scope.emailRequiredMessage = validationService.messages.email.required;
                        $scope.phonePattern = validationService.patterns.phone;
                        $scope.phonePatternMessage = validationService.messages.phone.pattern;
                        $scope.phoneRequiredMessage = validationService.messages.phone.required;
                    }
                ]
            };
        }
    ]);
};