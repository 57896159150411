/* globals module, require */
module.exports = function(app) {

    "use strict";

    app.directive('siteDisclaimer', ["services", "$state", "modal",
        function(services, $state, modal){

            return {
                restrict: 'A',
                replace: true,
                scope: {
                    referrer: "=",
                    isProfessional: '=?'
                },

                template: require('./siteDisclaimer.html'),

                link: function(scope) {

                    scope.disclaimer = function () {
                        modal.open({
                            template: require("modals/siteDisclaimerModal.html"),
                            scope : scope
                        });
                    };

                    scope.openSecondaryModal = function () {
                        modal.open({
                            template: require("modals/siteDisclaimerModalSecondary.html"),
                            scope : scope
                        });
                    };
                },

                controller: ['$scope', '$state', 'coremetrics', function ($scope, $state, coremetrics) {

                    /*
                     Note: This function triggers once on page load, whether isProfessional is defined or not. If you're on a page that
                     requires a isProfessional check (such as newReview), a coremetrics call will not execute on the first trigger.
                     Once the parent page loads, the $watch will re-trigger the function with a defined isProfessional value, and it
                     will execute the function and coremetrics call. If the page doesn't require a isProfessional check, the coremetrics tag
                     will fire on the first trigger and then stop. This set up prevents multiple coremetrics calls from happening.
                     */
                    $scope.triggerCoremetrics = function () {

                        //Professional Details Page
                        if ($state.current.name === 'providers.professional') {
                            coremetrics.elementTag('FIND A DOCTOR:DETAILS:PHYSICIAN MARKETPLACE NETWORK DESIGN DESCRIPTION', 'FIND A DOCTOR:DETAILS');
                        }

                        //Facility Details Page
                        else if ($state.current.name === 'providers.facility') {
                            coremetrics.elementTag('FIND A DOCTOR:DETAILS:FACILITIES MARKETPLACE NETWORK DESIGN DESCRIPTION', 'FIND A DOCTOR:DETAILS');
                        }

                        //Professional New Review Page
                        else if ($state.current.name === 'providers.newReview' && $scope.isProfessional) {
                            coremetrics.elementTag('FIND A DOCTOR:REVIEW PROVIDER:MARKETPLACE NETWORK DESIGN DESCRIPTION', 'FIND A DOCTOR:REVIEW PROVIDER');
                        }

                        //Facility New Review Page
                        else if ($state.current.name === 'providers.newReview' && !$scope.isProfessional && $scope.statusDefined) {
                            coremetrics.elementTag('FIND A DOCTOR:REVIEW FACILITIES:MARKETPLACE NETWORK DESIGN DESCRIPTION', 'FIND A DOCTOR:REVIEW FACILITIES');
                        }

                        //Review Disclaimer Site Disclaimer
                        else if ($state.current.name === 'providers.reviewDisclaimer') {
                            coremetrics.elementTag('FIND A DOCTOR:WRITE A REVIEW ATTESTATION:MARKETPLACE NETWORK DESIGN DESCRIPTION', 'FIND A DOCTOR:WRITE A REVIEW ATTESTATION');
                        }

                        //Professional All Reviews Site Disclaimer
                        else if ($state.current.name === 'providers.allReviews' && $scope.isProfessional) {
                            coremetrics.elementTag('FIND A DOCTOR:SEE ALL REVIEWS PHYSICIAN: MARKETPLACE NETWORK DESIGN DESCRIPTION', 'FIND A DOCTOR:SEE ALL REVIEWS');
                        }

                        //Facility All Reviews Site Disclaimer
                        else if ($state.current.name === 'providers.allReviews' && !$scope.isProfessional && $scope.statusDefined) {
                            coremetrics.elementTag('FIND A DOCTOR:SEE ALL REVIEWS FACILITIES: MARKETPLACE NETWORK DESIGN DESCRIPTION', 'FIND A DOCTOR:SEE ALL REVIEWS');
                        }
                    };

                    $scope.$watch('isProfessional', function () {
                        $scope.statusDefined = $scope.isProfessional !== 'undefined';
                        $scope.triggerCoremetrics();
                    });

                }]
            };
        }
    ]);
};