module.exports = function (app) {
    // global vars may have to go here
    app.controller('pages.vision-health.vision-library.vision-library-content', ["$state", "$scope", "teamsite", "$q", function ($state, $scope, teamsite, $q) {
        var articleID = $state.params.articleID;
        // //$scope.currentState = $state.$current.name;
        // debugger;
        $scope.article = "MBR_VISION_LIBRARY_" + articleID.toUpperCase().replace(/-/g, "_");
        console.log("SIDEBAR LINKS",$scope.sidebar.links);

    }]);
};