module.exports = function(app) {
    app.directive('smartMessage', function() {

        return {
            restrict: 'A',
            replace: true,

            template:   '<article data-teamsite="MBR_Claims_Smart_Message as message" data-ng-show="message.id">' +
                            '<div class="smart-message" data-ng-bind-html="message.message"></div>' +
                        '</article>'
        };
    });
};