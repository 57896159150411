module.exports = function(app) {
    app.directive('oralHealthAddCondition', function() {

        return {
            restrict: 'A',
            replace: true,
            template: require('directives/oralHealthAddCondition.html'),

            scope: {
                existingConditionFilter: '=',   // Object containing a list of existing conditions for each member that should be filtered out.
                conditionAdd: '&',              // Reference to add function that will be called when a condition is added.
                errorHandler: '&'               // Reference to error function that will be called when an error occurs.
            },

            controller: ['$scope', 'services', 'sessionData', 'teamsite', 'medicalDentalService', 'coremetrics',
                function($scope, services, sessionData, teamsite, medicalDentalService, coremetrics) {
                    var memberInfo = sessionData.memberInfo;

                    $scope.showErrorMsg = false;
                    $scope.showForm = false;
                    $scope.disableMemberSelect = false;
                    $scope.selectedTemplates = {
                        pregnancy: false,
                        treatment: false,
                        transplant: false,
                        standard: false
                    };
                    $scope.selectedMember = "";
                    $scope.selectedCondition = "";
                    $scope.newCondition = {
                        templateSpecificDate: "",
                        treatmentIndicator: null,
                        acceptTerms: false
                    };
                    $scope.memberSelect = {
                        options: [],
                        defaultOption: "Select a Name"
                    };
                    $scope.conditionSelect = {
                        options: [],
                        defaultOption: "Select a Condition"
                    };


                    teamsite.get('MBR_COVERAGE_ORAL_HEALTH_VALIDATION').then(function (data) {
                        $scope.validation = JSON.parse(data);
                    });

                    medicalDentalService.getConditionTypes(memberInfo.clientList[0].enrollmentSourceCode, sessionData.groupNumber).then(function(conditionList) {
                        $scope.conditionSelect = {
                            options: conditionList,
                            defaultOption: "Select"
                        };
                    }, function(error) {
                        $scope.errorHandler({
                            message: "An error occurred while fetching the list of eligible condition types.",
                            error: error,
                            isConditionTypes: true
                        });
                    });

                    // Get unique list of all members.
                    var uniqueMembers = services.rbsmbl.memberInfo().getUniqueMemberNamesArray(memberInfo);
                    if (uniqueMembers && uniqueMembers.length > 0) {
                        for (var i=0; i < uniqueMembers.length; i++) {
                            if (uniqueMembers[i].entireMemberObj.relationshipCode === "20" || uniqueMembers[i].entireMemberObj.relationshipCode === "18") {
                                uniqueMembers[i].entireMemberObj.relationship = "Self";
                            }
                            $scope.memberSelect.options.push({
                                name: uniqueMembers[i].firstName + " " + uniqueMembers[i].lastName,
                                value: uniqueMembers[i].entireMemberObj
                            });
                        }
                    }

                    var setupMemberSelect = function() {
                        if ($scope.memberSelect.options.length === 1) {
                            $scope.selectedMember = $scope.memberSelect.options[0];
                            $scope.disableMemberSelect = true;
                        } else {
                            $scope.disableMemberSelect = false;
                        }
                    };
                    // Call on page load
                    setupMemberSelect();

                    var resetSelectedTemplates = function() {
                        $scope.selectedTemplates = {
                            pregnancy: false,
                            treatment: false,
                            transplant: false,
                            standard: false
                        };
                    };

                    $scope.updateViewableTemplates = function() {
                        $scope.newCondition.templateSpecificDate = "";
                        $scope.newCondition.treatmentIndicator = null;
                        $scope.newCondition.acceptTerms = false;
                        // Reset all templates to false every time the selected condition is changed.
                        resetSelectedTemplates();
                        resetFormValidation();

                        // Select the appropriate templates to show based on the selected condition.
                        if ($scope.selectedTemplates[$scope.selectedCondition.value.templateName] !== undefined) {
                            $scope.selectedTemplates[$scope.selectedCondition.value.templateName] = true;
                        } else {
                            $scope.selectedTemplates.standard = true;
                        }
                    };

                    $scope.openConditionForm = function() {
                        $scope.showForm = true;
                        coremetrics.elementTag('Add New Condition', 'MEMBER:YOUR COVERAGE:MY ORAL HEALTH');
                    };

                    $scope.addCondition = function() {
                        coremetrics.elementTag('Submit New Condition: ' + $scope.selectedCondition.name, 'MEMBER:YOUR COVERAGE:MY ORAL HEALTH');

                        if ($scope.addConditionForm.$valid) {
                            $scope.disableButtons = true;

                            medicalDentalService.addCondition($scope.newCondition, $scope.selectedCondition.value, $scope.selectedMember.value, memberInfo.agreementId).then(function(conditionAdded) {
                                // Pass the condition that was added to the parent controller.
                                $scope.conditionAdd({ condition: conditionAdded, coveredIndividualId: $scope.selectedMember.value.coveredIndividualId });
                                $scope.resetForm();
                                $scope.disableButtons = false;

                            }, function(error) {
                                $scope.disableButtons = false;
                                $scope.showErrorMsg = true;
                            });
                        }
                    };

                    $scope.conditionFilter = function(item) {
                        var valid = false;

                        if ($scope.selectedMember && $scope.selectedMember.value && $scope.selectedMember.value) {
                            // Filter conditions by gender for selected member.
                            if (item.value.gender === "ALL" ||
                                    (item.value.gender === "MALE" && $scope.selectedMember.value.gender === "M") ||
                                    (item.value.gender === "FEMALE" && $scope.selectedMember.value.gender === "F")) {
                                valid = true;
                            }

                            // Filter out existing conditions for selected member.
                            if ($scope.existingConditionFilter[$scope.selectedMember.value.pinNumber] &&
                                    $scope.existingConditionFilter[$scope.selectedMember.value.pinNumber][item.name] &&
                                    $scope.existingConditionFilter[$scope.selectedMember.value.pinNumber][item.name] !== undefined) {

                                if (item.value.renewable === false) {
                                    // If condition exists and is not renewable, check if it was canceled before today.
                                    var endDate = new Date($scope.existingConditionFilter[$scope.selectedMember.value.pinNumber][item.name].endDate);
                                    var today = new Date();
                                    valid = (endDate.getTime() < new Date(today.getFullYear(), today.getMonth(), today.getDate()).getTime()) ? true : false;
                                } else {
                                    valid = false;
                                }
                            }
                        }
                        return valid;
                    };

                    var resetFormValidation = function() {
                        $scope.addConditionForm.$setUntouched();
                        $scope.addConditionForm.$setPristine();
                        $scope.showErrorMsg = false;
                    };

                    $scope.resetConditionSelect = function(){
                        $scope.selectedCondition = "";
                        $scope.newCondition = {
                            templateSpecificDate: "",
                            treatmentIndicator: null,
                            acceptTerms: false
                        };
                        resetSelectedTemplates();
                        resetFormValidation();
                    };

                    $scope.resetForm = function() {
                        $scope.showErrorMsg = false;
                        $scope.selectedMember = "";
                        $scope.showForm = false;
                        $scope.disableMemberSelect = false;
                        $scope.resetConditionSelect();
                        setupMemberSelect();
                    };

                }
            ]
        };
    });
};