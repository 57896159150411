module.exports = function(app) {
	app.directive('updateBrowser', function() {
		return {
			restrict: 'A',
			replace: true,
			template: require('directives/splash/updateBrowser.html'),

			controller: ['$scope', '$window', '$element', function($scope, $window, $element) {

				$scope.shouldUpdateBrowser = (function(window) {
					return ~window.navigator.appName.indexOf('Microsoft') &&
							parseInt(window.navigator.userAgent.match(/msie (\d)/i)[1], 10) < 11;
				})($window);

				$scope.browserLinks = [
					{
						image: 'resources/img/splash/chrome.png',
						text: 'Chrome',
						url: 'http://www.google.com/chrome'
					},
					{
						image: 'resources/img/splash/firefox.png',
						text: 'Firefox',
						url: 'http://www.firefox.com'
					},
					{
						image: 'resources/img/splash/IE.png',
						text: 'Internet Explorer',
						url: 'http://windows.microsoft.com/ie'
					}
				];

				$scope.closeMessage = function() {
                   	$element.parent().find('.updateBrowser').remove();
                };
			}]
		};
	});
};
