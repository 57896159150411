module.exports = function(app) {
    app.controller('pages.prescriptions.medication-exception-form', ['$scope', '$state', 'services', 'coremetrics', 'sessionData', 'userService', 'prescriptionLogic', '$window',
        function ($scope, $state, services, coremetrics, sessionData, userService, prescriptionLogic, $window) {
            $scope.headings = "PRESCRIPTIONS";
            $scope.backText = "Back";
            $scope.medExceptionRequest={};
            $scope.medExceptionRequest.memberID = sessionData.memberInfo.umi;

            $scope.backAction = function () {
                if ($state.previous.name === 'formsLibrary') {
                    $window.location = '/mbr/#!/landing';
                } else {
                    $state.go('prescriptions');
                }
            };

            $scope.footerMessage = {
                header: "",
                message: "",
                iconClass: "",
                show: false
            };
            $scope.showOkButton = false;
            $scope.disableButtons = false;
            $scope.footerMessage.show = false;

            $scope.submit = function(medicationExceptionForm, medExceptionRequest) {
                coremetrics.elementTag('Medication exception request submit', 'Member:Prescriptions');

                if (medicationExceptionForm.$valid) {
                    $scope.disableButtons = true;
                    $scope.footerMessage.show = false;

                    prescriptionLogic.submitMedicationExceptionRequest(medExceptionRequest).then( function(response) {
                        $scope.disableButtons = false;
                        $scope.showOkButton = true;
                        userService.showFooterMessage($scope.footerMessage, "", '<span data-teamsite="MBR_PRESCRIPTIONS_WV_STEP_THERAPY_FORM_SUCCESS_MSG"></span>', false);

                    }, function(error) {
                        $scope.disableButtons = false;
                        userService.showFooterMessage($scope.footerMessage, "", '<span data-teamsite="MBR_TROUBLE_PROCESSING_REQUEST"></span>', true);
                    });

                    $scope.disableButtons = false;
                    $scope.showOkButton = true;
                }
            };

            $scope.cancel = function(){
                $scope.footerMessage.show = false;
                $state.go('prescriptions');
            };

            $scope.okButton = function() {
                $scope.disableButtons = true;
                $scope.footerMessage.show = false;
                $state.go("prescriptions");
            };

            var uniqueMembers = services.rbsmbl.memberInfo().getUniqueMemberNamesArray();
            $scope.memberList = [];
            for (var i = 0; i < uniqueMembers.length; i++){
                uniqueMembers[i].fullName = uniqueMembers[i].firstName +" "+uniqueMembers[i].lastName;
                $scope.memberList.push(uniqueMembers[i]);
            }
            $scope.medExceptionRequest.selectedMember = $scope.memberList[0];

        }
    ]);
};
