module.exports = function (app) {
	app.directive('spendingBlackoutMessage', function () {
		return {
			restrict: 'A',
			template: require('./spendingBlackoutMessage.html'),
			controller: ['$scope', 'sessionData', function ($scope, sessionData) {
				$scope.shouldShowBlackoutMessage = sessionData.spendingAccountBlackoutIndicator === true || sessionData.spendingAccountBlackoutIndicator === 'true';
				$scope.startDate = new Date(sessionData.memberInfo.clientList[0].spendingAccountBlackoutstartdate);
				$scope.endDate = new Date(sessionData.memberInfo.clientList[0].spendingAccountBlackoutenddate);
			}],
			link: function (scope, element, attrs) {
				if (!scope.shouldShowBlackoutMessage) {
					element.addClass('hide-msg');
				}

				element.addClass('pre-blackout-msg');
			}
		};
	});
}; 