module.exports = function (app) {
	app.controller("pages.ebill.register", ["$state", "$scope", "services", "teamsite", "$q","session","sessionData","$cookies", "modal", "$window", "coremetrics",
		function ($state, $scope, services, teamsite, $q, session, sessionData, $cookies, modal, $window, coremetrics) {
			//Redirect edirect the user to member landing
			//if(!(sessionData && sessionData.directPayIndicator && sessionData.loggedIn)){
			//	return $window.location = "/mbr/#!/landing";
			//}
			$scope.hidePage = false;
			var payPremiumEnabledBrands = ['hmbcbs', 'pbs', 'ms', 'de', 'hbs', 'hmbcbswv', 'hhic', 'fl', 'lou', 'az', 'neb', 'exc', 'regbcbs', 'regbs', 'wi', 'hz', 'inac', 'hmbcbsde', 'mn', 'pp', '6011', 'mincr', 'la','wybcbs','ub'];
			var shouldGoToBilling = (payPremiumEnabledBrands.indexOf(sessionData.memberInfo.brand.toLowerCase()) !== -1);

			//CR00765125
			var currentPlan = services.rbsmbl.memberInfo().getPortalSelectedPlan(sessionData.memberInfo);
			if(shouldGoToBilling) {
				services.rbsmbl.ebillUserExists().getTargetUrl(sessionData.memberInfo.brand).then(
					function (response) {
						if (response) {
							var unregistered = response &&
								response.data &&
								response.data.payload &&
								response.data.payload.ebillAccountexists === "N";
							if (unregistered) {

								services.rbsmbl.AccountSettingsRetrive()
									.retriveAccountSettings(session.data.groupNumber, "ccsAccountSettings", session.data.memberInfo.brand, session.data.memberInfo.userName)
									.then(function(accountSettingsData) {
										if (accountSettingsData && accountSettingsData.data && accountSettingsData.data.payload) {

											$scope.accountRetrieveData = accountSettingsData.data.payload;
											var acctData = accountSettingsData.data.payload;
											$scope.zip = acctData.zip;

										}
									},function(error){
										$scope.hidePage = true;
									});

								setTimeout(function(){
									//$scope.umiSuffix = sessionData.memberInfo.clientList[0].groupList[0].umiAgreementIdSuffix;
									//CR00765125
									var umiSuffixFoundFlag = false;
									angular.forEach(sessionData.memberInfo.clientList, function(client){
										angular.forEach(client.groupList, function(group){
											if(group.groupNumber === currentPlan.groupNumber && !umiSuffixFoundFlag){
												$scope.umiSuffix = group.umiAgreementIdSuffix;
												umiSuffixFoundFlag = true;
											}
										});
									});
									services.rbsmbl.searchEbillAccounts().getAccounts(sessionData.memberInfo.umi+$scope.umiSuffix, sessionData.memberInfo.firstName, $scope.zip, sessionData.memberInfo.brand).then(
										function (response) {
											if (response && response.data && response.data.payload) {
												var billingID = response.data.payload.billingIDs;
												var payload = {
													"payload": {
														"middleName":"",
														"lastName":"",
														"birthDate": sessionData.memberInfo.dob,
														"zip":"",
														"city":"",
														"state":"",
														"primaryPhoneNumber":"",
														"memberId": sessionData.memberInfo.umi+$scope.umiSuffix,
														"billingId": billingID,
														"firstName":"",
														"email":"",
														"primaryExtension":"",
														"address1":"",
														"address2":"",
														"address3":"",
														"challengeQuestion":""
													}
												};

												services.rbsmbl.eBillRegistration().fetch(payload).then(function(response){
													$scope.hidePage = false;
													$window.location = "/billing/invoices"+"?cm_sp=Ebill-_-Pay Monthly Premium-_-PAY MONTHLY PREMIUM";
													coremetrics.elementTag('MEMBER:MEMBER EBILL:EBILL SSO BUTTON', 'MEMBER:MEMBER EBILL');
												}, function(error){
													$scope.hidePage = true;
												});
											}
										},
										function(error){
											$scope.hidePage = true;
										}
									);
								}, 2500);
							}
							else {
								$scope.hidePage = false;
								$window.location = "/billing/invoices";
							}
						}
					},
					function (err) {
						$scope.hidePage = true;
					}
				);
			}else{
				$scope.hidePage = true;
			}

			$scope.eBillbrandVisibility = false;

			if (sessionData.memberInfo !== undefined) {
				var brandName = sessionData.memberInfo.brand;
				if (brandName !== undefined && (brandName.toLowerCase() === ("MINCR").toLowerCase())
					|| brandName.toLowerCase() === "wybcbs") {
					$scope.eBillbrandVisibility = true;
                }
			}

		$scope.billingId = "";
		teamsite.get('MBR_MBRHOME_HEALTHTOOLS_PAYPREMIUM_REGISTERED').then(function (response){
			var jsonContent;
			try {
				jsonContent = JSON.parse(response);
			} catch (e) {}

			if (jsonContent.href) {
				$scope.eBillRegistrationURL = jsonContent.href;
			}
		}, function(error){
		});

		if(shouldGoToBilling){
			$scope.eBillRegistrationURL = "/billing/invoices";
		}

		coremetrics.pageviewTag('MEMBER:MEMBER EBILL:EBILL REGISTRATION', 'MEMBER:MEMBER EBILL');

		//$scope.umiSuffix = sessionData.memberInfo.clientList[0].groupList[0].umiAgreementIdSuffix;
			//CR00765125
			var umiSuffixFoundFlag = false;
			angular.forEach(sessionData.memberInfo.clientList, function(client){
				angular.forEach(client.groupList, function(group){
					if(group.groupNumber === currentPlan.groupNumber && !umiSuffixFoundFlag){
						$scope.umiSuffix = group.umiAgreementIdSuffix;
						umiSuffixFoundFlag = true;
					}
				});
			});
		$scope.dob = sessionData.memberInfo.dob;
		$scope.memberId = session.data.memberInfo.umi + $scope.umiSuffix;
		$scope.eBillCancelForm = function(){
			$window.close();
		};
		$scope.eBillRegisterSubmit = function(){
			if ($scope.billingId == "") {
				return;
			}

			var payload = {
				"payload": {
					"middleName":"",
					"lastName":"",
                    "birthDate":$scope.dob,
					"zip":"",
					"city":"",
					"state":"",
					"primaryPhoneNumber":"",
					"memberId": $scope.memberId,
					"billingId": $scope.billingId,
					"firstName":"",
					"email":"",
					"primaryExtension":"",
					"address1":"",
					"address2":"",
					"address3":"",
					"challengeQuestion":""
				}
			};

			services.rbsmbl.eBillRegistration().fetch(payload).then(function(response){
				if (shouldGoToBilling) {
					$window.location = '/billing/invoices?cm_sp=Ebill-_-Pay Monthly Premium-_-PAY MONTHLY PREMIUM';
				} else {
					$window.location = '/emssvc/x-services/secure/sso/outbound/auto/v1/vendor/EBILL?cm_sp=Ebill-_-Pay%20Monthly%20Premium-_-PAY%20MONTHLY%20PREMIUM';
				}		
				coremetrics.elementTag('MEMBER:MEMBER EBILL:EBILL SSO BUTTON', 'MEMBER:MEMBER EBILL');
			}, function(error){
				if(error.data && error.data.baseMessage && error.data.baseMessage.returnCode && error.data.baseMessage.returnCodeDescription){
					var errorCode = error.data.baseMessage.returnCode;
					var errorDesc = error.data.baseMessage.returnCodeDescription;

					if(errorCode){
						$scope.incorrectInfo = errorDesc;
					}
				}else{
					$scope.viewError = 'error';
				}
			});
		};
	}]);
};
