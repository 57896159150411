module.exports = function (app) {
    app.controller("pages.faqs", ["$scope", "$state", "teamsite", function($scope, $state, teamsite){
        $scope.headings = "FAQs";
        $scope.topics = [];

        $scope.sidebar.links = [];
        var sidebarResponse = {};

        teamsite.get("MBR_FAQS_SIDEBAR_LINK1").then(function(sideResponse){
            sidebarResponse = JSON.parse(sideResponse);
            teamsite.get("MBR_FAQS").then(function(response){
                var jsonResponse = JSON.parse(response);
                $scope.faqs = jsonResponse;
                angular.forEach(jsonResponse, function(item){
                    if($scope.topics.indexOf(item.tags) === -1){
                        $scope.topics.push(item.tags);
                        var link = {
                            heading:"",
                            label: item.tags,
                            href:"/login/#/faqs#" + item.tags,
                            description:""
                        }
                        if(link.label=="Health Care Basics"){
                            link.state=sidebarResponse.state;
                            link.heading=sidebarResponse.heading;
                            link.description=sidebarResponse.description;
                        }
                        $scope.sidebar.links.push(link);
                    }
                });
            });
        });        

    }]);

    return [
        {
            state: "faqs",
            postPassword:true,
            url: "/faqs",
            views: {
                "body@": {
                    template: bootstrap.templates["page-templates/categoryCustom.html"],
                    controller: "pages.faqs"
                },
                "content@faqs":{
                    template: require("./faqs.html")
                }
            }
        }
    ]

};

