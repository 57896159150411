module.exports = function (app) {
	app.controller('pages.wellness.dentalHealthCenter', ["sessionData", "services", "$scope", "$state", "wellnessArticles", "contentResourceServices",
		function (sessionData, services, $scope, $state, wellnessArticles, contentResourceServices) {

			var redirectWithoutEntitlements = function(userProfile) {
				if (userProfile && userProfile.benefits && !userProfile.benefits.dental) {
					$state.go("^");
				}
			};

			redirectWithoutEntitlements(sessionData);

			var contentType = "Bluecore Article";
			var filterByDentalCenter = function(item) {
				return item && item.sections && item.sections.memberDentalHealthCenter;
			};
			$scope.articles = [];
			$scope.filterText = {};
			$scope.pagination = {
				page: 1,
				itemsPerPage: "10"
			};
			contentResourceServices.updateResourcesAsync(wellnessArticles, filterByDentalCenter, contentType)
				.then(function(items){
					$scope.articles = items;
				});
		}
	]);
};