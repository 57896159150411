module.exports = function(bootstrap) {
    bootstrap.run(['Service', function(Service) {
        new Service(
            {
                name: 'statementFile',
                group: 'rbsmbl',
                url: '/rbsmbl/x-services/statement/file',
                transformResponse: [],

                fetch: function(queryParams) {
                    var filePromise = this.get(queryParams);
                    return filePromise.then(
                        function(response) {
                            return {
                                url: response.config.url + '?' + $.param(response.config.params)
                            };
                        },
                        function(err) {
                            console.error('statementFile.fetch failed with error', err);
                            return filePromise;
                        }
                    );
                }
            }
        );
    }]);
};