module.exports = function(app) {
    app.directive('dentalProcedure', function() {

        return {
            restrict: 'A',
            replace: true,

            scope: {
                procedure: '=dentalProcedure'
            },

            template: require('directives/dentalProcedure.html'),


            controller: ['$scope', function($scope) {
                var
                    makeListAggregator,
                    getToothNumberList,
                    getToothSurfaceList;


                makeListAggregator = function(aListOfProperties) {
                    return function(aProcedure) {
                        var list = [];

                        aListOfProperties.forEach(function (property) {
                            if (aProcedure[property]) {
                                list.push(aProcedure[property]);
                            }
                        });

                        return list;
                    };
                };

                getToothNumberList = makeListAggregator(
                    [
                        'pfToothNum1',
                        'pfToothNum2',
                        'pfToothNum3',
                        'pfToothNum4',
                        'pfToothNum5',
                        'pfToothNum6'
                    ]
                );

                getToothSurfaceList = makeListAggregator(
                    [
                        'pfSurfacesNum1',
                        'pfSurfacesNum2',
                        'pfSurfacesNum3',
                        'pfSurfacesNum4',
                        'pfSurfacesNum5',
                        'pfSurfacesNum6'
                    ]
                );


                $scope.procedure.toothNumberList = getToothNumberList($scope.procedure);
                $scope.procedure.toothSurfaceList = getToothSurfaceList($scope.procedure);
            }]
        };
    });
}