module.exports = function (app) {

    'use strict';

    app.directive('paperDirectory', [function () {

        return {
            restrict: 'A',
            replace: false,
            template: require('directives/preFindDoctor/paperDirectory.html')
        };
    }]);
};
