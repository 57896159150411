module.exports = function(app) {
    app.controller('pages.claims.partial.payClaim.confirm', ['$scope', '$state','coremetrics', 'services', '$q', 'storage', 'claimService',
        function($scope, $state,coremetrics, services, $q, storage, claimService) {
            if (!$state.params.payFrom) {
                // refresh detected, return user to service selection page
                $state.go('^.partialPay');
                return;
            }

            $scope.payToName = $state.params.payTo.label;
            var payToValue = $state.params.payTo.value;
            $scope.payFromName = $state.params.payFrom;

            $scope.payment = parseFloat($state.params.partialPayAmt);
            $scope.remainingBal = parseFloat($state.params.remainingBal);

            $scope.goBack = function() {
                coremetrics.elementTag('BACK:CLAIMS','MEMBER:CLAIMS');
                $state.go('claims.claimsAndPayments.partialPay', { claimNumber: $state.params.claimNumber , selectedPayTo:$state.params.payTo, submittedPayment: $state.params.partialPayAmt }, { location: true });
            };

            $scope.confirm = function() {
                coremetrics.elementTag('CONFIRM:CLAIMS','MEMBER:CLAIMS');
                $scope.isSubmitting = true;
                    coremetrics.pageviewTag('CLAIMS:PAY CLAIM COMPLETE','MEMBER:CLAIMS');

                        var partailPayInfo = {
                            "claimNumber": $state.params.claimNumber, //done
                           // "claimNumber":20331792455, //testing
                            "clientId": $state.params.clientNumber, //done
                          //  "clientId":'031691',  //testing
                            "partialPayAmt": $scope.payment.toFixed(2),//done
                            "payMode": payToValue //done

                        };

                   services.rbsmbl.partialPayClaims().submit(partailPayInfo).then(

                        function (response) {
                            $scope.isSubmitted = true;
                            if(response && response.status===200){
                                   $scope.isSuccess = true;
                                   //flush cache of summary, detail and let the user close the browser
                                   services.rbsmbl.claimSummary().flushCache();
                                   services.rbsmbl.claimDetail().flushCache();
                            }
                        },
                        function(err) {
                            $scope.isSubmitted = true;
                            $scope.isError = true;
                            console.error('partial payment failed with error', err);
                        }
                    );

                coremetrics._conversionEventTagWithAttrs(2, "PAY CLAIM PROCESS", "MEMBER:CLAIMS", "CLAIMS:PAY CLAIM COMPLETE");
            };

            $scope.close = function() {
                coremetrics.elementTag('CLOSE:CLAIMS','MEMBER:CLAIMS');
                $state.go('claims.claimsAndPayments');
            };
        }
    ]);
};