module.exports = function(app) {
	"use strict";
	app.service('benefitSearchService', ['services', 'teamsite', '$q', 'sessionData', '$cookies', 'finder-logic2',  function(services, teamsite, $q, sessionData, $cookies, finderLogic) {

		this.findAllBenefits = function () {
			var deferred = $q.defer();

			services.rbsmbl.benefitSearch().findAllBenefits().then(function (response) {
				var synonyms  = (response 
					&& response.data
					&& response.data.payload
					&& response.data.payload.benefitTerms)
				? response.data.payload.benefitTerms
				: [];
		
				deferred.resolve(synonyms);
			}, function (error) {
				error["isError"] = true;
				deferred.resolve(error);
			});
			return deferred.promise;

		};

		this.searchForBenefit = function (payload) {
			var deferred = $q.defer();
		
			services.rbsmbl.benefitSearch().postSearch(payload).then(function (response) {
				var searchResponse1 = (response // Change when synonyms mapping to ICIS is fixed 
					&& response.data 			//and take out if/else 
					&& response.data.payload)
					? response.data.payload
					: response.data;
				if(searchResponse1 !== response.data) {
					var searchResponse2 = (searchResponse1
						&& searchResponse1.results)
						? searchResponse1.results
						: [];

					var result = searchResponse2;

					deferred.resolve(result);
				} else {
					var notFound;
					notFound = searchResponse1.baseMessage;
					notFound["isError"] = true;
					deferred.reject(notFound);
				}	
			}, function (error) {
				deferred.reject(error);  
			});
			return deferred.promise;
		};

		this.getBenefitBooklet = function () {
			var deferred = $q.defer();
			var benefits = sessionData.benefits || {};
			var memberInfo = sessionData.memberInfo || {};
			var clientId = memberInfo.clientList.filter(function (client) {
				return client.clientNumber === $cookies.get('mbrClientNumber');
			}).map(function (client) {
				return client.clientId;
			})[0];
            var retrieveBenefitBookletBoolean = teamsite.get('MBR_BENEFIT_BOOKLET_SHOWBANNER_LADINGCOVERAGE_PAGE');
			var curPrd = finderLogic.portalSelectedPlanSpecificProductByLatestCoverageEffectiveDate("Medical");
			if(curPrd){
            retrieveBenefitBookletBoolean.then(function (teamsiteBenefitBookletBoolean) {
                services.rbsmbl.BenefitBooklet().fetch({payload: {
                    "icisClientId": clientId,
                    "icisClientNumber": $cookies.get('mbrClientNumber'),
                    "groupNumber": $cookies.get('mbrGroupNumber'),
                    "curEffDate": (curPrd.coverageBeginDate != ""
                        ? curPrd.coverageBeginDate
                        : "00/00/0000"),
                    "coverageCancelDate": (curPrd.memberList[0].coverageCancellationDate != ""
                        ? curPrd.memberList[0].coverageCancellationDate
                        : "00/00/0000"),
                    "drugCoverage": benefits.drug,
                    "medicalCoverage": benefits.medical,
                    "visionCoverage": benefits.vision,
                    "dentalCoverage": benefits.dental,
                    "alertCounterReq": teamsiteBenefitBookletBoolean
                }}).then(function (data) {
                    var bbResponse = data && data.data && data.data.payload || {};
                    deferred.resolve(bbResponse);
                }, function (error) {
                    deferred.resolve(error);
                });
            });}

			return deferred.promise; /*services.rbsmbl.benefitBooklet().fetch();*/
		};

	}]);

};