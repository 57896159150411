module.exports = function (app) {
    app.directive('changePassword', function () {
        return {
            template: require('./changePassword.html'),
            scope: {
                changePassword: "=",
                title: '@'
            },
            controller: ['$scope', 'services', function ($scope, services) {

                $scope.resetValidity = function(form){
                   // reset validity  for invalid current password since that check is always made in the backend.
                   // So need to make it valid while the user is fixing it.
                    if(form.currentPassword.$error.invalidPassword == true)
                    {
                        form.currentPassword.$setValidity("invalidPassword",true);
                    }
                    //console.log("new password",$scope.changePassword.password);
                    //Check if the current password is same as the new password
                    if (form.currentPassword.$modelValue === $scope.changePassword.password){
                        console.log("passowrd cannot be same");
                        form.newPassword.$setValidity("sameString",false);

                    }
                };

                $scope.passwordRules = {};
                var functionType = 'whatever';
                services.rbsmbl.securityPassword({"strengthMethod": functionType}).fetch().then(function (rules) {
                    if (rules.data && rules.data.payload) {
                        $scope.passwordRules.minLength = rules.data.payload.securityStrengthRule.passwordLengthRule.minLength;
                        $scope.passwordRules.maxLength = rules.data.payload.securityStrengthRule.passwordLengthRule.maxLength;
                    } else {
                        $scope.passwordRules.minLength = 8;
                        $scope.passwordRules.maxLength = 64;
                    }

                    $scope.showPasswordStrength = true;
                    $scope.showPasswordOld = false;
                    $scope.disableInputs = false;
                         
                    if ($scope.changePassword.password === $scope.changePassword.currentPassword) {
                        $scope.passwordRules.samePassword = true;

                    } else {
                        $scope.passwordRules.samePassword = false;
                    }

                });
            }]
        };
    });

    /*app.directive('sameString', function () {
        return {
            restrict: "A",
            require: "ngModel",
            scope: {
                sameString: '='
            },
            link: function(scope, element, attrs, ctrl) {
                ctrl.$validators.sameString = function (modelValue, viewValue) {                    
                    return scope.sameString !== modelValue;

                }
            }
        };
    });*/

    app.directive('memberPasswordValidator2', function () {
        return {
            restrict: "A",
            require: "ngModel",
            scope: {
                criteria: '=memberPasswordValidator2',
                sameString: '='
            },
            link: function (scope, element, attrs, ctrl) {
                scope.criteria = scope.criteria || {};

                ctrl.$validators.sameString = function (modelValue, viewValue) {                    
                    
                    return scope.sameString !== modelValue;
                };
                ctrl.$validators.passwordRules = function (modelValue, viewValue) {
                    var count = 0;
                    scope.criteria = {};

                    if (viewValue && viewValue !== undefined) {
                        if (viewValue.length >= attrs.minlength && viewValue.length <= attrs.maxlength) {
                            scope.criteria.hasValidLength = true;
                        }
                        if (/[0-9]/.test(viewValue)) {
                            scope.criteria.hasOneNumber = true;
                            count++;
                        }
                        if (/[#!@$%^&*\(\)\-_=+|;:'",<.>?`~\\\/\[\]\{\}]/.test(viewValue)) {
                            scope.criteria.hasSpecialCharacter = true;
                            count++;
                        }
                        if (viewValue.toLowerCase() !== viewValue) {
                            scope.criteria.hasUpperCase = true;
                            count++;
                        }
                        if (viewValue.toUpperCase() !== viewValue) {
                            scope.criteria.hasLowerCase = true;
                            count++;
                        }
                        
                        scope.criteria.hasPassed = (count > 2);

                        return !!scope.criteria.hasPassed;
                    } 
                    return true;
                };

                ctrl.$validators.containSpace = function (modelValue, viewValue) {
                    if (viewValue && viewValue !== undefined) {
                        var trimmedPassword = viewValue.replace(/\s/g, '');
                        return trimmedPassword === viewValue;
                    }
                    return true;
                };
            }
        };
    });
};