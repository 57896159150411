module.exports = function(app) {
    app.controller('pages.coverage.myOralHealth', ['$scope', 'session', 'coremetrics', 'displayOrHideItemsWrapper', 'brand', 'medicalDentalService', 'updateVisited',
        function($scope, session, coremetrics, displayOrHideItemsWrapper, brand, medicalDentalService, updateVisited) {

            $scope.users = [];
            $scope.conditions = [];
            $scope.existingConditionFilter = {};
            $scope.showErrorMsg = false;
            $scope.showAddModule = true;
            $scope.showLoadingBar = true;
            $scope.showNotUsingBenefitsBlock = false;
            $scope.showUsingBenefitsBlock = false;
            $scope.showInGracePeriodBlock = false;
            
            medicalDentalService.getMemberStatus()
            var memberConditionsObj = {};

            var addExistingCondition = function(condition) {
                if (!$scope.existingConditionFilter.hasOwnProperty(condition.member.pin)) {
                    // If the existing condition filter doesn't have any conditions for the given member, then create an empty object for the member.
                    $scope.existingConditionFilter[condition.member.pin] = {};
                }

                if ($scope.existingConditionFilter[condition.member.pin][condition.description] !== undefined) {
                    // If the condition type being added to the filter already exists for the given member, get the one with the latest end date.
                    var dupCondition = $scope.existingConditionFilter[condition.member.pin][condition.description];

                    if (new Date(dupCondition.endDate).getTime() < new Date(condition.cancelDate).getTime()) {
                        $scope.existingConditionFilter[condition.member.pin][condition.description] = { 'endDate': condition.cancelDate, 'dup': true };
                    }
                } else {
                    $scope.existingConditionFilter[condition.member.pin][condition.description] = { 'endDate': condition.cancelDate };
                }
            };

            var updateCurrentConditions = function(updatedConditions) {
                if (updatedConditions !== undefined) {
                    memberConditionsObj[updatedConditions.member.pin] = updatedConditions;
                }
                var tempArray = [];
                angular.forEach(memberConditionsObj, function(value, key) {
                    for (var i = 0; i < value.conditions.length; i++ ) {
                        var condition = value.conditions[i];
                        condition.member = value.member;
                        tempArray.push(condition);
                        addExistingCondition(condition);
                    }
                });
                $scope.conditions = tempArray;
            };

            $scope.getLoadingBarControls = function(controlsObject) {
                medicalDentalService.getCurrentConditions().then(function(currentConditions) {
                    memberConditionsObj = currentConditions;
                    updateCurrentConditions();

                    controlsObject.fillBar().then(function(){
                        $scope.showLoadingBar = false;
                    });

                }, function (error) {
                    controlsObject.stopBar().then(function() {
                        $scope.showLoadingBar = false;
                        $scope.handleError("An error has occurred while fetching existing condition list.", error);
                    });
                });
            };

            var refreshMemberConditionsOnUpdate = function(coveredIndividualId) {
                medicalDentalService.refreshMemberConditions(coveredIndividualId).then(function(memberConditionObj) {
                   console.log("THIS IS THE MEMBER CONDITION OBJECT", memberConditionObj);
                	if (memberConditionObj && memberConditionObj.member) {
                        updateCurrentConditions(memberConditionObj);
                    }
                }, function (error) {
                	console.log("THIS", error)
                    $scope.handleError("An error has occurred while fetching existing condition list.", error);
                });
            };

            $scope.handleError = function(message, error, isConditionTypes) {
                $scope.showErrorMsg = true;
                $scope.showAddModule = (isConditionTypes === true ? false : true);
            };

            $scope.conditionAdd = function(condition, coveredIndividualId) {
                // For any logic that needs run when a condition is added.
                refreshMemberConditionsOnUpdate(coveredIndividualId);
            };

            $scope.conditionCancel = function(condition, coveredIndividualId) {
                // For any logic that needs run when a condition is canceled.
                refreshMemberConditionsOnUpdate(coveredIndividualId);
            };

            $scope.conditionRenew = function(condition, coveredIndividualId) {
                // For any logic that needs run when a condition is renewed.
                refreshMemberConditionsOnUpdate(coveredIndividualId);
            };
            
            var ucWellnessIndicator = (session.data
            		&& session.data.appConfig
            		&& session.data.appConfig.COVERAGE
            		&& session.data.appConfig.COVERAGE.UC_WELLNESS_OVERRIDE
            		&& session.data.appConfig.COVERAGE.UC_WELLNESS_OVERRIDE.overrideValue) ? session.data.appConfig.COVERAGE.UC_WELLNESS_OVERRIDE.overrideValue : null;
            if(!ucWellnessIndicator) {
            	var callDisplayHide = displayOrHideItemsWrapper.displayOrHideItemsCall(session.data.memberInfo.clientList[0].clientNumber, session.data.groupNumber, ["ucWellnessUsageIndicator"], brand.current.sharedBrandCode);
                callDisplayHide.then(function (data) {
                	if(data && data.qualifierInfo) {
                		ucWellnessIndicator = data.qualifierInfo.ucWellnessUsageIndicator;
                	}
                });
            }
        	$scope.showUsingBenefitsBlock = ucWellnessIndicator === 'USING_BENEFITS';
        	$scope.showInGracePeriodBlock = ucWellnessIndicator === 'WITHIN_GRACE_PERIOD';
        	$scope.showNotUsingBenefitsBlock = ucWellnessIndicator === 'NOT_USING_BENEFITS';
            
        	updateVisited.myOralHealth();
        }
    ]);
};