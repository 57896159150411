module.exports = function(app) {

    app

        .controller('customClientCtrl', ['$scope', '$state', 'teamsite', 'session', '$sce', 'displayOrHideItemsWrapper', '$window', '$timeout',
        function ($scope, $state, teamsite, session, $sce, displayOrHideItemsWrapper, $window, $timeout) {


            $scope.articleOrder = {
                'MBR_CustomClient_ArticleOne': 0,
                'MBR_CustomClient_ArticleTwo': 0,
                'MBR_CustomClient_ArticleThree': 0,
                'MBR_CustomClient_ArticleFour': 0,
                'MBR_CustomClient_ArticleFive': 0
            };

            var sidebarContentIds = [
                "MBR_CustomClient_MainArticleTease",
                "MBR_CustomClient_ArticleOne",
                "MBR_CustomClient_ArticleTwo",
                "MBR_CustomClient_ArticleThree",
                "MBR_CustomClient_ArticleFour",
                "MBR_CustomClient_ArticleFive"];

            if (!$scope.type) {
                $scope.type = 'viewer';
            }

            if ($scope.type === 'viewer') {
                $scope.showReadMore = true;
            }

            if (session.data && session.data.memberInfo) {
                var getClientInfo = function (memberInfo, groupNumber) {
                    var clientInfo = {};
                    if (memberInfo && memberInfo.clientList && Array.isArray(memberInfo.clientList)) {
                        for (var i = 0; i < memberInfo.clientList.length; i++) {
                            var groupList = memberInfo.clientList[i].groupList;
                            for (var j = 0; j < groupList.length; j++) {
                                if (groupList[j].groupNumber == groupNumber) {
                                    clientInfo.clientNumber = memberInfo.clientList[i].clientNumber;
                                    clientInfo.isCustomClient = memberInfo.clientList[i].isCustomClient;
                                    console.log("clientinfo is custom client", clientInfo.isCustomClient);
                                    console.log("clientinfo is cusom client number", clientInfo.clientNumber);
                                    if(clientInfo.isCustomClient == true )
                                    {
                                        $scope.isCustomClient = true;
                                        $scope.showCustomClient = true;


                                    }
                                    else
                                    {$scope.isCustomClient = false;}
                                }
                            }
                        }
                    }
                    return clientInfo;
                };

                var clientInfo = getClientInfo(session.data.memberInfo, session.data.groupNumber);

                var displayOrHideItemsResponse = displayOrHideItemsWrapper.displayOrHideItemsCall(clientInfo.clientNumber, session.data.groupNumber, ['webMDRewardsIndicator'], session.data.memberInfo.brand);
                displayOrHideItemsResponse.then(function (servicePayload) {
                    if (servicePayload && servicePayload.qualifierInfo && servicePayload.qualifierInfo.webMDRewardsIndicator && servicePayload.qualifierInfo.webMDRewardsIndicator == 'true') {
                        teamsite.setQualifier('webMDRewards', true);
                    } else {
                        teamsite.setQualifier('webMDRewards', false);
                    }
                });

                $scope.isCustomClient= clientInfo.isCustomClient;
                teamsite.setQualifier('CustomClientID', clientInfo.clientNumber);

                /*$scope.isCustomClient=true;
                 teamsite.setQualifier('CustomClientID', "2276140000");
                 //teamsite.setQualifier('webMDRewards', true);*/
            }

            if ($scope.type === 'categoryViewer' && $scope.sidebar && $scope.isCustomClient) {
                $scope.sidebar.links = [];
                // reset the description, it was copied from other tabs
                $scope.sidebar.description = '';
                $scope.contentId = $state.params.id ? $state.params.id : 'MBR_CustomClient_MainArticleTease';
                $scope.detailContentId = sidebarContentIds.indexOf($state.params.id) >= 0 ? $state.params.id + "_Detail" : $state.params.id;

                var goToArticle = function (id, link) {

                    if (!link || link === '' || id === 'MBR_CustomClient_MainArticleTease') {
                        $state.go('customClientArticleViewer', { id: id });
                    } else {
                        $window.open(link, '_self');
                    }
                };

                var populateSideBar = function (id) {
                    teamsite.get(id).then(function (response) {
                                              var articleContent = {};
                                              try {
                                                  articleContent = JSON.parse(response);
                                                  var title = articleContent.title || articleContent.teaserTitle;
                                                  var headerTemplate = '<div data-ng-if="isCustomClient" class="sidebarImageContainer"><img class="client-logo1" data-cms-img="MBR_CustomClient_ImageOne" src="" alt=""/>' +
                                                      '<a data-teamsite="MBR_CustomClient_ImageTwoLink as imgLink" href="{{imgLink.href}}" target="{{imgLink.target}}" >' +
                                                      '<img src="" class="client-logo2" data-cms-img="MBR_CustomClient_ImageTwo" alt="" /></a></div>';
                                                  var labelTemplate = '<span>' + title + '</span>';
                                                  if (id === $state.params.id || (articleContent.teaserLink && $state.params.id.length !== 0 && articleContent.teaserLink.indexOf($state.params.id) >= 0)) {
                                                      if (articleContent.teaserLink && articleContent.teaserLink.indexOf('/login/#/custom-client-article/') < 0) {
                                                          $window.open(articleContent.teaserLink, '_self');
                                                      }
                                                      $scope.sidebar.links.push({
                                                          heading: headerTemplate,
                                                          label: labelTemplate,
                                                          state: 'customClientArticleViewer',
                                                          id: id
                                                      });
                                                  } else {
                                                      $scope.sidebar.links.push({
                                                          heading: headerTemplate,
                                                          label: labelTemplate,
                                                          onClick: function () {
                                                              goToArticle(id, articleContent.teaserLink);
                                                          },
                                                          id: id
                                                      });
                                                  }

                                                  // order the sidebar links from one,two,..,five
                                                  $scope.sidebar.links.sort(function (link1, link2) {
                                                      return (sidebarContentIds.indexOf(link1.id) - sidebarContentIds.indexOf(link2.id));
                                                  });
                                              }
                                              catch (e) {
                                                  console.log("unable to parse json", e);
                                              }
                                          },
                                          function (err) {
                                              console.log("unable to get customClientArticle from teamsite", err);
                                          });
                };

                angular.forEach(sidebarContentIds, function (id) {
                    populateSideBar(id);
                });
            }

            $scope.rmClick = function () {
                $scope.showReadMore = !$scope.showReadMore;
            };

            $scope.success = function (contentId) {
                if ($scope.type === 'categoryViewer' && (contentId === $state.params.id || contentId === 'MBR_CustomClient_MainArticleTease')) {
                    $scope.articleFound = true;
                } else {
                    $scope.articleOrder[contentId] = 1;

                    var order = 1;
                    angular.forEach($scope.articleOrder, function (value, key) {
                        if (value > 0) {
                            $scope.articleOrder[key] = order++;
                        }
                    });
                    $scope.showArrow = true;
                }
            };

            $timeout(function () {
                $scope.displayMessage = true;
            }, 2000);
        }])

        .directive('customClientArticle', function () {
                       return {
                           restrict: 'A',
                           templateUrl: function (element, attrs) {
                               if (attrs.customClientArticle === 'categoryViewer') {
                                   return 'directives/landing/customClientArticleCategory.html';
                               } else {
                                   return 'directives/landing/customClientArticle.html';
                               }
                           },
                           scope: {
                               type: '@customClientArticle',
                               sidebar: '=articleSideBar'
                           }
                       };
                   })
};