module.exports = function(app) {

    require('./home/federalEmployees')(app);

    app.controller('pages.home', ['$scope', 'session', 'espotResponse', '$window',
                function($scope, session, espotResponse, $window) {

                    $scope.$watch(function() { return session.data.loggedIn; },
                        function(loggedIn){
                            if (loggedIn) {
                                $window.location.href = "/login/#/landing";
                            }
                            else if($window.location.hostname.split('.')[1]=="bluecrossmnonline"){
                                $window.location.href = "/login/#/custom-landing";
                            }
                            else{
                                $window.location = "/home";
                            }
                        });
                    $scope.heading = 'Welcome';
                    // if (espotResponse.data) {
                    //     $scope.hero = null;
                    //     $scope.espots = null;
                    //  }
                    // else {
                    //     $scope.hero = espotResponse.slice(0, 1)[0]; // preserve original response for return from back button
                    //     $scope.espots = espotResponse.slice(1); // same
                    // }
                }]
        );

    return [
        {
            state: 'home',
            appConfigMaintenanceApp: 'SPLASH',
            appConfigMaintenanceKey: 'FULL_MAINTENANCE',
            url: '/home',
            views: {
                'body@': {
                    template: require('pages/home.html'),
                    controller: 'pages.home'
                },
                'content@home': {
                    template:   '<article data-ng-if=hero data-splash-espot="hero" data-hero="true"  class="splashHero"></article>' +
                        '<article data-ng-if=espots data-splash-espot-group="espots" class="splashEspots"></article>' +
                         '<article data-splash-links class="splashLinks"></article>'
                }
            },
            resolve: {
                espotResponse: ['services', function(services) {
                        return services.wcs.espot().fetch([1, 5, 6, 7]).then(function(response) {
                            return response
                            },
                            function(error) {
                                return error
                            })
                }]
            }
        },
        {
            state: 'home.federalEmployees',
            appConfigMaintenanceApp: 'SPLASH',
            appConfigMaintenanceKey: 'FEDERAL_EMPLOYEES_MAINTENANCE',
            url: '/federalEmployees',
            views: {
                'body@': {
                    template: require('pages/home/federalEmployeesBody.html'),
                    controller: 'pages.home.federalEmployees'
                },
                'leftColumn@home.federalEmployees': {
                    template: require('pages/home/federalEmployees.sidebarLeft.html')
                },
                'content@home.federalEmployees': {
                    template: require('pages/home/federalEmployees.html')
                },
                'rightColumn@home.federalEmployees': {
                    // nothing goes here
                }
            }
        }
    ];
};
