module.exports = ['$scope', '$http', function($scope, $http) {

    $scope.custom = true;

    $scope.goToSite = function(theTarget, isHref) {
         $scope.myCloseModal();
        if (isHref) {
            window.open(theTarget);

        }
    }
    $scope.myCloseModal = function() {
        this.closeModal();
        }

}];