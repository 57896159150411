module.exports = function(app) {
    app.controller('pages.home.federalEmployees', ['$scope', '$state', 'brand',
        function($scope, $state, brand) {

        	$scope.sharedBrandCode = brand.current.sharedBrandCode;
            $scope.headings = $scope.sharedBrandCode === 'hmbcbswv' ?'Federal Employee Program': 'Federal Employee Programs';
            $scope.backText = "&larr;" + "BACK TO HOME";
            $scope.sidebar.heading = $scope.headings;
            $scope.sidebar.description = 'Benefits and Services just for you.';
            $scope.backAction = function() { $state.go('^'); };
            window.sharedBrandCode = $scope.sharedBrandCode;
        }
    ]);
};
