module.exports = function(app) {
	app.controller("pages.spending.forms", ["$scope", "$state", "session", "$window", "resourcesList", "contentResourceServices", "spendingResourceServices","coremetrics",
		function($scope, $state, session, $window, resourcesList, contentResourceServices, spendingResourceServices, coremetrics){

		// attach session for pre- and post- password display
		$scope.session = session.data;
		$scope.pagination = {
			page: 1,
			itemsPerPage: '10'
		};
            window.document.title = "Forms";

		var benefits, hasDental, hasDrug, hasFSA, hasHSA, hasHRA, hasMedical,
			hasPharmacy, hasSpending, hasVision, hasDirectPay, hasPrimeVendor;

		var filterFunction = function(item) {
			if (item && item.sections && item.sections.memberSpendingForms
				&& item.entitlements) {
				if (item.entitlements.hasPrePassword && hasPrePassword) { return true; }
				if (item.entitlements.hasDental && hasDental) { return true; }
				if (item.entitlements.hasDrug && hasDrug && !hasPrimeVendor) { return true; }
				if (item.entitlements.hasMedical && hasMedical ) { return true; }
				if (item.entitlements.hasPharmacy && hasPharmacy ) { return true; }
				if (item.entitlements.hasSpending && hasSpending ) { return true; }
				if (item.entitlements.hasVision && hasVision ) { return true; }
				if (item.entitlements.hasDirectPay && hasDirectPay) { return true; }
				if (item.entitlements.hasFSA && hasFSA) { return true; }
				if (item.entitlements.hasHSA && hasHSA) { return true; }
				if (item.entitlements.hasHRA && hasHRA) { return true; }
			}
			return false;
		};

		$scope.$on('drawerOpen', function (event, args) {
			$scope.isExpanded = event.targetScope.item.open;
		});

		$scope.$on('drawerClose', function (event, args) {
			$scope.isExpanded = event.targetScope.item.open;
		});

		$scope.$watch("sidebar.links", function(newValue, oldValue){
			newValue.forEach(function(item){
				if (item.state === "spending.forms") {
					item.refineTemplate = "pages/spending/formsRefine.html";
				}
			});
		});

		$scope.resources = [];
		$scope.$watch("session.loggedIn", function(){

			benefits = session.data.benefits || {};
			hasPrePassword = !!session.loggedIn;
			hasDental = benefits.dental;
			hasDrug = benefits.drug;
			hasPrimeVendor = session.data.pharmacyBenefitProviderIndicator? session.data.pharmacyBenefitProviderIndicator.toLowerCase()=='prime': false;
			hasFSA = false;
			hasHSA = false;
			hasHRA = false;
			hasMedical = benefits.medical || benefits.medicalActive;
			hasPharmacy = false; // is this different than drug?
			hasSpending = benefits.spending;
			hasVision = benefits.vision;
			hasDirectPay = session.data.directPayIndicator;

			spendingResourceServices.getSpendingAccounts().then(function(response){
				hasFSA = response.hasFSA;
				hasHSA = response.hasHSA;
				hasHRA = response.hasHRA;
			}).then(function(){
				contentResourceServices.updateResourcesAsync(resourcesList, filterFunction).then(
					function(resources){
						$scope.resources = resources;
				});

			});
		});

		$scope.download = function(item){
			if (item && item.href) {
				coremetrics.elementTag(item.title, "MEMBER:SPENDING");
				window.open(item.href);
			}
		}
	}]);
};