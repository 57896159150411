module.exports = function(app) {
    app.directive('splashLinks', function() {
        return {
            restrict: 'A',
            replace: true,
            scope: {},

            template:   '<div data-ng-show="links.length" class="splashHelpfulLinks"><h2 class="font20">Helpful Links</h2>' +
                            '<ul class="left font15"><li data-ng-repeat="link in leftLinks track by $index"><a data-helpful-link="link"></a></li></ul>' +
                            '<ul class="right font15"><li data-ng-repeat="link in rightLinks track by $index"><a data-helpful-link="link"></a></li></ul>' +
                        '</div>',

            controller: ['$scope', 'splashLinkService', function($scope, splashLinkService) {
                var links = splashLinkService.getLinks();
                var splitIndex = Math.ceil(links.length / 2);
                var leftLinks = links.slice(0, splitIndex);
                var rightLinks = links.slice(splitIndex);

                $scope.links = links;
                $scope.leftLinks = leftLinks;
                $scope.rightLinks = rightLinks;
            }
            ]
        };
    });

    app.directive('helpfulLink', function() {
        return {
            restrict: 'A',
            replace: true,
            scope: {
                link: '=helpfulLink'
            },

            template:   '<a data-ng-href="{{href}}" target="{{target}}">{{text}}</a>',

            link: function(scope) {
                var link = scope.link instanceof Object
                         ? scope.link
                         : {};

                scope.href = link.href;
                scope.text = link.text;
                scope.target = link.target || '_self';
            }
        };
    });
};
