module.exports = function (app) {
    app.directive('searchBarAutoComplete', function () {

        return {
            restrict: 'A',
            template: require('directives/benefit-search/searchBarAutoComplete.html'),
            scope: {
                synonyms: '=',
                messageOne: '@',
                messageTwo: '@',
                search: '=?',
                searchInput: '=?',
                placeHolder: '@',
                coremetricsOne: '@',
                coremetricsTwo: '@',
                notFound: '=?'
            },
            
           controller: ['$scope', '$sce', 'coremetrics',
                function ($scope, $sce, coremetrics) {

                $scope.searchInput = '';
                $scope.searchResults = [];
                $scope.searchResultsName = {};
                $scope.placeHolder = $scope.placeHolder;
                    
                var counter = 0;
                //FocusOnTerms and ArrowScroll just allow you to scroll though list of benefits. To use this you need to 
                // add the move1 and move id tags to your elements. 
                $scope.focusOnTerms = function(keyEvent) {
                    var inputElement = document.getElementById('move1');

                    var hasFocus = (document.activeElement === inputElement); 
                    
                    if(keyEvent.which === 40) {
                        document.getElementById('move').focus();
                        // keyEvent.preventDefault();
                        $scope.counter = 0;
                        counter = 0;
                    } else if (hasFocus) {
                        $scope.counter = -1;
                    } 
                }    

                $scope.arrowScroll = function(keyEvent, index, autoComplete) {

                    var autoCompleteList = autoComplete;
                   
                    if (keyEvent.which === 38) {
                        if(counter > -1) { 
                            counter = counter - 1;
                            keyEvent.preventDefault();
                        } else {    
                            counter = -1;
                            document.getElementById('move1').select();
                            keyEvent.preventDefault();
                        }
                    }
                    else if (keyEvent.which === 40) {

                        if(counter <= 15) {
                            counter = counter + 1;
                            if (counter >= autoCompleteList.length) {
                                counter = 0;
                                keyEvent.preventDefault();
                                
                            }
                            keyEvent.preventDefault();
                        }
                    }
                    else if (keyEvent.which === 13) {
                        var stringTerm = autoCompleteList[counter];
                        $scope.fillTextbox(stringTerm);
                    } 
                    $scope.counter = counter;             
                };

                
                /*findAllbenefits allows you to filter on the synonyms list based on user input. It also checks for results 
                based on what the user types first, then any synonym that has that test in it gets appended to the list. */
                $scope.findAllBenefits = function (searchInput) {
                    var synonymList = $scope.synonyms; 
                    var allTerms = synonymList.sort();
                    var outputTerms = [];
                    var outputTerms2 = [];
                    var concatedArray = [];
                    var newTerms = [];
                    var newLength;

                    allTerms.map(function (term) {

                        if(term.toLowerCase().startsWith(searchInput.toLowerCase())) {
                            if(outputTerms.length <= 15) {
                               return outputTerms.push(term);
                            }
                        } 
                    });

                    newTerms = allTerms.slice(0);

                    //Added additionality functionality to look for duplicates and delete them. 
                    for(var j = 0; j < outputTerms.length; j++){
                        for(var i = 0; i < newTerms.length; i++){

                            if(newTerms[i] === outputTerms[j]) {
                                
                                newTerms.splice(i, 1);
                            }
                        }            
                    };
                    //This looks for any other instance of the word the user is typing and adds those terms to final list.
                    for(var i = 0; i < newTerms.length; i++){ 
                        var userInput = searchInput; 
                        var myReg = new RegExp("( "+ userInput +")", 'ig');
                        var termMatch = newTerms[i].match(myReg);
                       
                        if(termMatch != null) {
                            newLength = outputTerms.length + outputTerms2.length;
                            if(newLength <= 15){
                                outputTerms2.push(newTerms[i]);
                            }
                        }
                    };   
                   

                    // for(var i = 0; i < newTerms.length; i++){    

                    //     if(newTerms[i].toLowerCase().indexOf(searchInput.toLowerCase()) >=0){
                    //         newLength = outputTerms.length + outputTerms2.length;

                    //        if(newLength <= 15) { 
                    //             outputTerms2.push(newTerms[i]);
                    //        } 
                    //     } else {
                    //     //    $scope.notFound = false;
                    //     }    
                    // };
                                   
                    concatedArray = outputTerms.concat(outputTerms2);
                    // concatedArray = outputTerms;

                    if(concatedArray.length > 0) {
                        $scope.autoComplete =  concatedArray;
                        $scope.errorMessage = false;
                        $scope.notFound = false;
                    } else {
                        concatedArray = [];
                        $scope.autoComplete =  concatedArray;
                        $scope.errorMessage = true; 
                    }
                }

                $scope.fillTextbox= function(stringTerm){
                    $scope.searchInput = stringTerm;
                    if($scope.searchInput.length === stringTerm.length) {
                        coremetrics.elementTag($scope.coremetricsOne, $scope.coremetricsTwo);
                        $scope.search($scope.searchInput);
                    
                        $scope.autoComplete = [];
                    } else {
                        $scope.searchInput = "";
                        $scope.autoComplete = [];
                    }
                }
                
                
                // This checks for the change to the notFound value from benefitSearch to make sure it catches each 
                // change if a benefit is not after hitting the ICIS service. If using the directive you might not need.
                $scope.$parent.$watch('notFound', function (parentScope) {
                    if (parentScope) {
                        $scope.notFound = parentScope;
                    }
                });
           }]
        }  
   });   
    app.filter('highlight', function($sce) {
        return function(synonym, phrase) {
          if (phrase) synonym = synonym.replace(new RegExp('('+phrase+')', 'gi'),
            '<span class="highlighted">$&</span>')

            return $sce.trustAsHtml(synonym)
        }
    });
}        
