module.exports = function (app) {
    require("./forms-library/formsLib")(app);
    require("./forms-library/aemForms")(app);
    app.controller("pages.formsLibrary", ["$scope", "$window", function ($scope, $window) {
        $scope.headings = "Forms Library";
        $scope.backText = 'Back';


        $scope.backAction = function() {
                $window.open('/mbr/#!/landing', '_self');   
        };
    }]);
    //Test
    return [
        {
            state: "formsLibrary",
            url: "/forms",
            appConfigMaintenanceApp: 'FORMS_LIBRARY',
            appConfigMaintenanceKey: 'FULL_MAINTENANCE',
            postPassword: false,
            views: {
                "body@": {
                    template: bootstrap.templates['page-templates/contentCustom.html'],
                    controller: "pages.formsLibrary"
                },
                "content@formsLibrary": {
                    template: require("./forms-library/formsLib.html"),
                    controller: "pages.formsLibrary.formsLib"
                }
            },
            resolve: {
                forms: ["formsLibraryService", function(formsLibraryService) {
                    return formsLibraryService.getAllForms();
                }]
            }
        }, 

        {
            state: "formsLibrary.zostavax",
            url: "/zostavax",
            postPassword: true,
            views: {
                "body@": {
                    template: bootstrap.templates['page-templates/detailCustom.html'],
                    controller: "pages.formsLibrary"
                },
                "content@formsLibrary.zostavax": {
                    template: require("./forms-library/aemForms.html"),
                    controller: "pages.formsLibrary.aemForms"
                }
            },
        }
    ]
};