module.exports = function(bootstrap) {
    bootstrap.run(['Service', function (Service) {
        new Service({
            name: 'createProspect',
            group: 'cmcrst',
            url: '/cmcrst/x-services/prospect',
            fetch: function(requestInfo) {
                var requestPayload = {
                    payload: {
                        prospect: {
                            firstName: requestInfo.firstName,
                            lastName: requestInfo.lastName,
                            dob: new Date(requestInfo.dateOfBirth).getTime(),
                            address1: requestInfo.addressLine[0],
                            address2: requestInfo.addressLine[1],
                            shoppingInfo: {
                                brand: requestInfo.shoppingBrand,
                                zipCode: requestInfo.shoppingZipCode ? requestInfo.shoppingZipCode : requestInfo.zipCode
                            },
                            city: requestInfo.city,
                            state: requestInfo.state,
                            zipCode: requestInfo.zipCode,
                            email: requestInfo.email1,
                            applicationType: 'ONEX'
                        }
                    }
                };

                return this.put(requestPayload);
                //TODO: redirectPostRegistration(), url = 'shop.highmark.com/#!/marketplace/application-status';
            }
        });
    }]);
};
