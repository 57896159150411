module.exports = function(app) {
    app.controller('pages.HIPAA.authorizationCtrl', ['$scope', 'hipaaService', 'storage', 'updateVisited', 'coremetrics','resourcesList','contentResourceServices','$state','session',
        function($scope, hipaaService, storage, updateVisited, coremetrics,resourcesList,contentResourceServices,$state,session) {
            $scope.viewState = 'loading';

            $scope.pagination = {
                itemsPerPage: '10'
            };
            $scope.pagination.page = 1;
            $scope.sidebar.keywordOptions={};
            $scope.session ='memberAccountSettingsHipaaAuthorization';
            $scope.keywordSearchRefineActive=false;

            $scope.$watch('pagination.page', function(v) {
                storage.set('HipaaMaterialPage', v);
            });

            $scope.section='memberAccountSettingsHipaaAuthorization';
            $scope.materials = contentResourceServices.resources;
            $scope.$watch("session.loggedIn", function(){
                $scope.keywordSearchRefineActive=false;
                hipaaService.getFilteredHipaaMaterialV2(resourcesList,$scope.section,true).then(function(resources){
                    $scope.materials = resources;
                    if($scope.materials[0]){
                        $scope.materials[0].isOpen = true;
                    }

                    $scope.sidebar.keywordOptions=hipaaService.getKeywordRefineOptions($scope.sidebar.keywordOptions);
                    $scope.sidebar.keywordsFilterActive=Object.keys($scope.sidebar.keywordOptions).length > 0;
                    //  updateSidebar();
                    $scope.viewState='success';
                });
            });

            $scope.sidebar.clearAll=function(){
                for(var key in $scope.sidebar.keywordOptions){
                    $scope.sidebar.keywordOptions[key]=false;
                }
                if(!$scope.sidebar.keywordRefine || $scope.sidebar.keywordRefine.value === ""){
                    $scope.materials=hipaaService.getFilteredMaterials($scope.sidebar);
                }else{
                    // this will trigger watch function that will update the refine options
                    $scope.sidebar.keywordRefine.value="";
                }
            };
        }
    ]);
};