module.exports = function (app) {
   app.directive('adamPrintContent',
       function ($compile) {
           return {
               restrict: 'EA',
               scope: false,
               link: function (scope, element, attrs) {
                   scope.$watch('noOfTimesPrintClicked', function (newValue,oldValue) {
                       if (newValue !== oldValue) {
                           scope.printContent=element[0].innerHTML;
                           var win = window.open();
                           win.document.open();
                           win.document.write('<'+'html'+'><'+'body'+'>');
                           win.document.write("<h2>" + scope.title.toUpperCase() + "</h2>");
                           win.document.write(scope.printContent);
                           win.document.write('<'+'/body'+'><'+'/html'+'>');
                           win.document.close();
                           win.print();
                           win.close();
                       }
                   });
               }

           };
       }
   );
};