module.exports = function(bootstrap) {
    bootstrap.directive('manualHsa', function() {

        return {
            restrict: 'A',
            replace: true,
            template: require('directives/manualHsa.html'),
            scope: {
                hsaClients: '=',
                hsaPendingSetup: '='
            },

            controller: ['$scope', 'services', '$filter', 'sessionData', 'teamsite', '$state', "manualHsaService", 
                function($scope, services, $filter, sessionData, teamsite, $state, manualHsaService) {
                    $scope.highlightContent = true; //TODO: determine if in pre-coverage stage
                    var groupContent = [];

                    $scope.hsaClients.forEach(function(client) {
                        client.groupList.forEach(function(groupItem) {
                            var groupAlreadyAdded = false;
                            if (groupItem.autoEnrollIndicator == "false" && 
                                groupItem.hsaSetupIndicator == "true" && 
                                groupItem.openHsaAccessIndicator == "true") {
                                
                                groupItem.productList.forEach(function(productItem) {
                                    if (manualHsaService.hasSubscriberInfo(productItem) && !manualHsaService.isSubscriberCancelled(productItem)) {
                                        //don't allow members to open HSAs for expired coverages
                                        if (productItem.coverageEndDate != "" && productItem.coverageEndDate != null && !manualHsaService.isDateStringBeforeToday(productItem.coverageEndDate)) {
                                            //don't allow members with void/cancelled dates to open HSAs
                                            if (!manualHsaService.isCoverageCancelled(productItem)) {
                                                if (!groupAlreadyAdded
                                                    && productItem.benefitPlanType.indexOf("Medical") !== -1
                                                    && productItem.hdhpIndicator === "true") { 
                                                    groupContent.push({
                                                        clientNumber: client.clientNumber,
                                                        planName: productItem.benefitPlanName,
                                                        organization: client.clientName,
                                                        groupNumber: groupItem.groupNumber
                                                    });
                                                    groupAlreadyAdded = true;
                                                }
                                            }
                                        }
                                    }
                                });
                            }
                        });
                    });

                    $scope.selectGroupForHsa = function() {
                        $state.go('claimsAndSpending.hsaGroupSelection', {hsaGroups: groupContent});
                    };

                }
            ]
        };
    });
};