module.exports = function (app){
	app.service("contentNavigationService", ["$q", "services", "brand", "teamsite", function($q, services, brand, teamsite){

        // utilities
        var sortByOrder = function(a,b){
            if (a.order > b.order) { return 1; }
            if (a.order < b.order) { return -1;	}
            return 0;
        };
        var parseItem = function(obj) {
            var nav;
            try {
                nav = !!obj ? JSON.parse(obj.value) : null;
            } catch(response) {
                nav = null;
            }
            return nav;
        };


        // instantiation
        this.navigation = [];

        // service methods
        this.getNavigationIdListAsync = function() {
            var defer = $q.defer();
            var listOfIds = {};
            console.log("getting all navigation items. This should be cached and only run once.");
            teamsite.getAll("Bluecore Navigation").then(function(response){
                for(var i in response) {
                    if (!listOfIds[i]) {
                        listOfIds[i] = true;
                    }
                }
                defer.resolve(listOfIds);
            });
            return defer.promise;
        };

        this.updateNavigationAsync = function(listOfIds, filterFunction) {
            var defer = $q.defer(),
                self = this;
            services.rbsmbl.contentInfo().getContent(listOfIds, { "brand": (brand.current.umsBrandCode || brand.current.sharedBrandCode).toUpperCase()}).then(function(qualifiedResponses){
                self.navigation = [];
                var ids = qualifiedResponses && qualifiedResponses.data && qualifiedResponses.data.payload && qualifiedResponses.data.payload.contentResponse;
                for(var index in ids) {
                    var item = parseItem(ids[index]);
                    item["id"] = ids[index]["name"];
                    if (filterFunction(item)) {
                        self.navigation.push(item);
                    }
                }
                self.navigation.sort(sortByOrder);
                defer.resolve(self.navigation);
            });
            return defer.promise;
        }
    }]);
};