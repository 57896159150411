module.exports = function(app) {
    app.directive('whyRegister', function() {

        return {
            restrict: 'A',
            replace: true,
            scope: {},

            template:   '<article class="why-register">' +
                            '<section>' +
                                '<h4 class="margin-bottom">Why Register?</h4>' +
                                '<img src="bootstrap/v4/resources/img/sample-beforeyoushopheader.jpg" alt="">' +
                                '<p class="margin-top">Registering for our products provides you with a few valuable benefits. It allows you to :</p>' +
                                '<ul class="margin-top"><li data-ng-repeat="reason in reasons">{{reason}}</li></ul>' +
                            '</section>' +
                        '</article>',

            controller: ['$scope', 'brand', function($scope, brand) {
                var reasons = [
                    'Log in when you visit our site eliminating the need to reenter your personal information each time you return.',
                    'Sign up for email notifications so you can get information about your needs and interests.'
                ];
                var applyOnline = 'Apply for and purchase health insurance plans entirely online.';
                var browsePlans = 'Browse plans at your own pace.  You can view, compare and share plans anytime from the privacy of your own home.';

                var domain = (function(brand) {
                    var domain = brand.current.domain;
                    return domain.split('.')[0];
                }(brand));

                var showBrowsePlans = function(domain) {
                    return domain !== 'mybenefitshome' && domain !== 'independencenationalalliance';
                };
                if (showBrowsePlans(domain)) {
                    reasons.unshift(browsePlans);
                }

                var showApplyOnline = function(domain) {
                    return domain !== 'mybenefitshome' && domain !== 'independencenationalalliance';
                };
                if (showApplyOnline(domain)) {
                    reasons.unshift(applyOnline);
                }

                $scope.reasons = reasons;
            }]
        };
    });
};