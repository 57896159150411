module.exports = function(app) {
    app.controller("pages.spending.claimsMatchingConfirm", ["$state", "$scope", "$rootScope", "session", "$location", "services", "coremetrics", "storage", "comboPDFSubmitService",
        function($state, $scope, $rootScope, session, $location, services, coremetrics, storage, comboPDFSubmitService) {

            var fullMemberInfo = session.data.memberInfo;

            $rootScope.confirmationState = true;

            $scope.$parent.backText = 'Back to Spending Claims';
            $scope.$parent.backAction = function() {
                if($scope.claimType) {
                    window.location.href = "/spa/#/spendingClaims?path=" + $scope.claimType;
                } else{
                    window.location.href = "/spa/#/spendingClaims";
                }
            };

            $scope.goToSpendingClaims = function() {
                window.location.href = "/spa/#/spendingClaims";
            };

            $scope.viewState = 'loading';

            $scope.pagination = {
                itemsPerPage: '10'
            };
            $scope.pagination.page = 1;

            $scope.comboPDFSubmission = false;
            $scope.comboPDFLoad = false;
            $scope.comboPDFError = false;
            $scope.comboPDFSuccess = false;

            var claimStorage = JSON.parse(sessionStorage.hmSessionStorage2).summaryData.data;
            var matchClaimStorage = storage.get("confirmationClaims");
            $scope.spendingClaimData = claimStorage ? claimStorage : false;
            $scope.claimType = ($scope.spendingClaimData && $scope.spendingClaimData.claimCategory && $scope.spendingClaimData.claimCategory.toLowerCase() == "debit card") ? "debit" : "manual";
            $scope.matchClaimData = matchClaimStorage ? matchClaimStorage : false;

            if ($scope.matchClaimData) {

                $scope.claimsForConfirmation = $scope.matchClaimData;

                $scope.viewState = 'success';

            } else {
                $scope.viewState = 'error';
            }

            $scope.backToVerify = function () {
                storage.set("backToVerify",true);
                window.location.href = "/login/#/spending/receiptRequired";

            };

            $scope.sendComboPDF = function () {

                coremetrics.elementTag('SUBMIT: SPENDING', 'MEMBER:SPENDING');

                $scope.comboPDFSubmission = true;
                $scope.comboPDFLoad = true;
                $scope.comboPDFError = false;
                $scope.comboPDFSuccess = false;

                var claimsMatchPayload = {
                    // to be added claimStorage.groupCode
                    brand: session.data.memberInfo.brand,
                    spendingClaimId: claimStorage.claimID,
                    spendingTransactionId: claimStorage.transactionID,
                    savePdf: true,
                    groupNumber: claimStorage.groupCode,
                    medicalClaimIds: []
                    // end
                };

                if ($scope.claimsForConfirmation.length) {
                    for (var i = 0; i < $scope.claimsForConfirmation.length; i++){

                        // get product line code
                        var productLineCodeMap = {
                            "medical": "M",
                            "dental": "D",
                            "vision": false,
                            "drug": "M",
                            "ods": $scope.claimsForConfirmation[i].sysSrcCode
                            },
                            productLineCode = productLineCodeMap[$scope.claimsForConfirmation[i].sysSrcCode];

                        var addInClaimData = {
                            payload: {
                                reqClaimAccumActivityDetail: {
                                    contractId : fullMemberInfo.agreementId,
                                    umi : fullMemberInfo.umi + "0",
                                    claimNumber : $scope.claimsForConfirmation[i].claimNumber,
                                    billingProviderId : $scope.claimsForConfirmation[i].bilgProviderId,
                                    callActivitiesV2Flag : $scope.claimsForConfirmation[i].callActivitiesV2Flag,
                                    paymentIntegrationFlag: $scope.claimsForConfirmation[i].paymentIntegrationFlag,
                                    productLineCode: productLineCode,
                                    includeIgnored: "Yes",
                                    claimSource: "PassThru",
                                    includePassThru: "Yes",
                                    sourceVendor: $scope.claimsForConfirmation[i].sourceVendor,
                                    startDate: $scope.claimsForConfirmation[i].bilgProvSvcBgnDt,
                                    endDate: $scope.claimsForConfirmation[i].bilgProvSvcEndDt,
                                    bilgProvClmStaCd: $scope.claimsForConfirmation[i].bilgProvClmStaCd,
                                    inputMedium: $scope.claimsForConfirmation[i].inputMedium,
                                    clmPartialSupressInd: $scope.claimsForConfirmation[i].clmPartialSupressInd
                                }
                            }
                        };

                        claimsMatchPayload.medicalClaimIds.push(addInClaimData);
                    }
                }

                console.log("claims match request payload",claimsMatchPayload);


                comboPDFSubmitService.submitClaimsMatchData(claimsMatchPayload).then(function(response) {

                        response = response.data;
                        console.log("combo service response",response);

                        $scope.comboPDFLoad = false;

                    var returnCode = (response && response.repUploadImageReply && response.repUploadImageReply.baseMessage && response.repUploadImageReply.baseMessage.returnCode) ? response.repUploadImageReply.baseMessage.returnCode : "return code error",
                        returnCodeDescription = (response && response.repUploadImageReply && response.repUploadImageReply.baseMessage && response.repUploadImageReply.baseMessage.returnCodeDescription) ? response.repUploadImageReply.baseMessage.returnCodeDescription : "error";

                    if (returnCode === "200" && returnCodeDescription === "OK") {
                        $scope.comboPDFSuccess = true;
                        coremetrics.elementTag('MATCH CLAIM: SPENDING', 'MEMBER:SPENDING');
                    } else {
                        $scope.comboPDFSubmission = false;
                        $scope.comboPDFError = true;
                    }
                },
                function(err) {
                    $scope.comboPDFSubmission = false;
                    $scope.comboPDFError = true;
                    console.error('comboPDF failed with error', err);
                });
            };


        }
    ]);
};