module.exports = function(app) {
    app.directive('simpleLoadingBar', function() {

        return {
            restrict: 'A',
            replace: true,

            scope: {
                startPercentage: '=?',
                endPercentage: '=?',
                interval: '=?',
                duration: '=?'
            },

            template:   '<article class="simple-loading-bar">' +
                            '<div class="container">' +
                                '<div class="fill">' +
                                    '<span>{{percentageLoaded}}%</span>' +
                                '</div>' +
                            '</div>' +
                        '</article>',

            controller: [ '$scope', '$element', '$interval', function($scope, $element, $interval) {
                var $fill = $element.find('.fill');
                var intervalID;

                var startPercentage = $scope.startPercentage
                                    ? Number($scope.startPercentage)
                                    : 5;

                var endPercentage = $scope.endPercentage
                                  ? Number($scope.endPercentage)
                                  : 99;

                var interval = $scope.interval
                             ? Number($scope.interval)
                             : 20;

                var duration = $scope.duration
                             ? Number($scope.duration)
                             : 8000;


                $scope.load = function(width, max, interval, totalTime) {
                    var remaining = max - width;
                    var increment = remaining / (totalTime/interval);

                    intervalID = $interval(function() {
                        if (width < max) {
                            width += increment;
                            if (width > max) {
                                width = max;
                                $interval.cancel(intervalID);
                            }
                            $scope.percentageLoaded = String(Math.round(width));
                            $fill.css({ width: width + '%' });
                        }
                        else {
                            $interval.cancel(intervalID);
                        }
                    }, interval);
                };

                $scope.load(startPercentage, endPercentage, interval, duration);
            }]
        };
    });
};