module.exports = function (app) {
    "use strict";
    app.controller("pages.coverage.benefitSearch", ['synonymsList', 'benefitSearchService', 'services', 
        '$scope', 'modal', '$sce', "coremetrics", "teamsite","sessionData","finder-logic2",
        function (synonymsList, benefitSearchService, services, $scope, modal, $sce, coremetrics, teamsite, sessionData, finderLogic) {

        var fileName;
        $scope.synonymsList = synonymsList;
        $scope.notFoundMessageOne = "Sorry this benefit service is not available at this time.";
        $scope.notFoundMessageTwo = "If you need immediate assistance please call the number on the back of your member ID card.";
        $scope.coremetricsOne = "MEMBER:BENEFIT SEARCH:BENEFIT TERM";
        $scope.coremetricsTwo = "MEMBER:BENEFIT SEARCH";
        $scope.placeHolder = "Search medical benefits";
        $scope.searchInput = '';
        $scope.searchResults = [];
        $scope.searchResultsName = {};
        $scope.showDisclaimer = false;
        var definitions = {};
        getTeamSite();

        $scope.openQualtrics = function() {
        
          modal.open({
              template: require('./benefitSearchSurveyModel.html')
          });
          
        };

        coremetrics.pageviewTag('MEMBER:BENEFIT SEARCH:BENEFIT SEARCH LANDING', 'MEMBER:BENEFIT SEARCH');

        var myModal = require("../../modals/benefitBookletError");
            var bbResponse;
            var ums = sessionData.memberInfo;
            var curGrp = finderLogic.getPortalSelectedPlan(ums);
            //Medical Benefit search disclaimer check
            var brand = sessionData.memberInfo.brand;
            var highmarkBrands = ["hmbcbs", "hbcbs", "highmarkbcbs", "hbs", "pbs", "hmbs", "hmbcbsde", "highmarkbcbsde", "de", "ms", "hmbcbswv"];
            var isHighmarkBrand = (highmarkBrands.indexOf(brand.toLowerCase()) > -1) ? true : false;

            $scope.hideBenefitBooklet = curGrp.productList[0].lob == "AG";
            // Move to benefit booklet to service
            benefitSearchService.getBenefitBooklet().then(function (response) {
                bbResponse = response;
                fileName = bbResponse.url;
                $scope.linkName = bbResponse.linkName;
                $scope.bShowANOC = bbResponse.showHeaderLabel;
            }, function (error) {
                bbResponse = {};
                
            }); 

            var uniqueProducts = services.rbsmbl.memberInfo().getUniqueProducts();
            var medicalProducts = uniqueProducts.forEach(function (product) {
                var product = product;
                var productData = product.productData || {};
                //Medical Benefit search disclaimer check
                if(productData.benefitPlanType.toUpperCase() === 'MEDICAL'){
                    $scope.showDisclaimer = isHighmarkBrand;
                }
                if(productData.benefitPlanType.toUpperCase() === 'MEDICAL' && !$scope.productNumber && productData.marketingStatusDescription !== "Canceled") {
                    $scope.benefitPlanName = productData.benefitPlanName;
                    $scope.productNumber = productData.productNumber; 
                    $scope.benefitType = productData.benefitPlanType;
                }
                else {
                    productData = {};
                }
            });
            
            function getTeamSite() {
                
                teamsite.get('BENEFIT_SEARCH_CONDITIONS_CATEGORY_DEFIN').then(function (response) {
                    definitions["conditions"] = response;
                });

                teamsite.get('BENEFIT_SEARCH_PROFESSIONAL_SERVICES_CATEGORY_DEFIN').then(function (response) {
                    definitions["professionalServices"] = response;
                });

                teamsite.get('BENEFIT_SEARCH_PREVENTIVE_CARE_CATEGORY_DEFIN').then(function (response) {
                    definitions["preventiveCare"] = response;
                });

                teamsite.get('BENEFIT_SEARCH_OUTPATIENT_FACILITY_CATEGORY_DEFIN').then(function (response) {
                    definitions["outPatientFacility"] = response;
                });

                teamsite.get('BENEFIT_SEARCH_INPATIENT_FACILITY_CATEGORY_DEFIN').then(function (response) {
                    definitions["inPatientFacility"] = response;
                });

                teamsite.get('BENEFIT_SEARCH_OTHER_SERVICES_CATEGORY_DEFIN').then(function (response) {
                    definitions["otherServices"] = response;
                });

                teamsite.get('BENEFIT_SEARCH_PRODUCT_WIDE_CATEGORY_DEFIN').then(function (response) {
                    definitions["productWide"] = response;
                });
                
                teamsite.get('BENEFIT_SEARCH_HEALTH_EDUCATION_CATEGORY_DEFIN').then(function (response) {
                    definitions["healthEducation"] = response;
                });

                teamsite.get('BENEFIT_SEARCH_VALUE_BASED_CATEGORY_DEFIN').then(function (response) {
                    definitions["valueBased"] = response;
                });

                teamsite.get('BENEFIT_SEARCH_PREFERRED_PROVIDER_CATEGORY_DEFIN').then(function (response) {
                    definitions["preferredProvider"] = response;
                });
                return definitions;
            };
           
            var benefitsByCategory = function(category) {
                var defineObject = getTeamSite();
                var categoryDefinition;
                switch (category) {
                    case "Conditions":
                        categoryDefinition = defineObject.conditions;
                        return categoryDefinition;
                    case "Professional Services":
                        categoryDefinition = defineObject.professionalServices;
                        return categoryDefinition;   
                    case "Preventive Care":
                        categoryDefinition = defineObject.preventiveCare;
                        return categoryDefinition;    
                    case "Outpatient Facility Services":
                        categoryDefinition = defineObject.outPatientFacility;
                        return categoryDefinition;    
                    case "Inpatient Facility Services":
                        categoryDefinition = defineObject.inPatientFacility;
                        return categoryDefinition;    
                    case "Other Services":
                        categoryDefinition = defineObject.otherServices;
                        return categoryDefinition;
                    case "Product Wide Provisions":
                        categoryDefinition = defineObject.productWide;
                        return categoryDefinition;    
                    case "Health Education Services":
                        categoryDefinition = defineObject.healthEducation;
                        return categoryDefinition; 
                    case "Value Based Benefits":
                        categoryDefinition = defineObject.valueBased;
                        return categoryDefinition;                  
                    case "Preferred Provider Administration":
                        categoryDefinition = defineObject.preferredProvider;
                        return categoryDefinition;                  
                };
            };
            //Search looks for the benefit 
            $scope.search = function (input) {
                if (input.length == 0) {
                    $scope.showSearchResults = false;
                    $scope.searchResults = [];
                } else {
                    var payload = {
                        "productId": $scope.productNumber,
                        "keyword": input
                    };
                    $scope.notFound = false;
                    benefitSearchService.searchForBenefit(payload).then(function (response) {
                     
                        //Need to check for service path, becuase some of the benefits coming from ICIS do not have servicePath.
                        $scope.notFound = false;
                        $scope.searchResults = response || [];
                        
                        if($scope.searchResults !== []) {

                            coremetrics.elementTag('MEMBER:BENEFIT SEARCH:BENEFIT SEARCH BAR', 'MEMBER:BENEFIT SEARCH');
                             
                            $scope.searchResults.forEach(function (results) {
                                var category = results.benefitCategory;
                                results["categoryDefinition"] = benefitsByCategory(category);

                                var benefits = results.benefits; 
                                for(var i = 0; i < benefits.length; i++) {
                                   //Had to add this if/else statement because when there is one benefit or more with no servicePath 
                                   //it was failing.  
                                   if(benefits[i].servicePath) {
                                        var inputString, newString;
                                        inputString = benefits[i].servicePath;
                                        newString = inputString.replace(/^.+?(?=\/)\//, ""); 
                                        newString = newString.replace(/\//gi, "-");
                                        benefits[i].servicePath = newString;
                                        $scope.showSearchResults = true; 
                                        
                                    } else {
                                        $scope.showSearchResults = true; 
                                        
                                    }
                                }; 
                            });
                        } else {
                            $scope.showSearchResults = false; 
                            $scope.notFound = true;
                        }   
                    }, function (error) {
                        $scope.showSearchResults = false;
                        console.log(error.returnCode + " " + error.returnCodeDescription);
                        $scope.notFound = true;
                    });
                }
            }

            $scope.openBenefitBooklet = function () {
                if (!fileName) {
                    modal.open({
                        template: require('modals/benefitBookletError.html'),
                        controller: myModal,
                        scope: $scope
                    });
                } else {
                    coremetrics.elementTag('MEMBER:BENEFIT SEARCH:BENEFIT BOOK DOWNLOAD', 'MEMBER:BENEFIT SEARCH');
                    window.open(fileName);
                }
            } 
    }]);
};
