var app = angular.module('app', ['bootstrap.larg', 'bootstrap.main']);
app.value("appId", "CHMHOM");

require('./local-services/finder-logic2')(app);
require('./local-services/finderService')(app);
require('./local-services/vsbpServices')(app);
require('./local-services/isTruthyOrZero')(app);
require('./local-services/benefitsDeserializer')(app);
require('./local-services/claimService')(app);
require('./local-services/claimDownloadService')(app);
require('./local-services/formsLibraryService')(app);
require('./local-services/pdfRequestPrepService')(app);
require('./local-services/dentalService')(app);
require('./local-services/manualHsaService')(app);
require('./local-services/messageService')(app);
require('./local-services/statementArchiveService')(app);
require('./local-services/educationalMaterialService')(app);
require('./local-services/hipaaService')(app);
require('./local-services/invisibleFormService')(app);
require('./local-services/updateVisited')(app);
require('./local-services/prescriptionLogic')(app);
require('./local-services/wellnessLogic')(app);
require('./local-services/wellnessUtility')(app);
require('./local-services/preventiveScheduleService')(app);
require('./local-services/receiptImageDataService')(app);
require('./local-services/receiptRequiredSubmitService')(app);
require('./local-services/comboPDFSubmitService')(app);
require('./local-services/contentNavigationService')(app);
require('./local-services/benefitSearchService')(app);
require('./local-services/memberProfileService')(app);

require('./local-services/virusScanService')(app);

require('./local-services/landing/landingServices')(app);

require('./local-services/splashLinkService')(app);
require('./local-services/sidebar')(app);
//require('./local-services/userService')(app);
require('./local-services/xml2json')(app);
require('./local-services/medicalDentalService')(app);
require('./local-services/partialPayService')(app);

require('./local-services/waysToSaveUtil')(app);

require('./local-services/myChartUtil')(app);

require('./local-services/RuleEngine')(app);
require('./local-services/spending/cceService')(app);
require('./local-services/spending/rxccService')(app);
require('./local-services/spending/savOppService')(app);

require('./local-services/ssoHelper')(app);
require('./local-services/contactUsService')(app);
require('./local-services/requestAPrintedCopyService')(app);
require('./local-services/passwordSecurityService')(app);
require('./local-services/messageCenterService')(app);
// TODO: FRANK, Please move to bootstrap, maybe...
require('./DO_NOT_USE/rbsmbl/dentalProcedureHistory')(app);
require('./DO_NOT_USE/rbsmbl/messageReadFlag')(app);//TODO: decommission
require('./DO_NOT_USE/rbsmbl/messageDeleteFlag')(app); //TODO: decommission
require('./DO_NOT_USE/rbsmbl/dependentNotification')(app);
require('./DO_NOT_USE/rbsmbl/eobDocByClaim')(app);
require('./DO_NOT_USE/rbsmbl/eobDocForDentalClaim')(app);
require('./DO_NOT_USE/rbsmbl/planProgress')(app);
require('./DO_NOT_USE/rbsmbl/statementFile')(app);
require('./DO_NOT_USE/rbsmbl/spendingAccountIgnore')(app);
require('./DO_NOT_USE/rbsmbl/spendingAccountUnignore')(app);
require('./DO_NOT_USE/uuidService')(app);
require('./DO_NOT_USE/validationService')(app);
require('./DO_NOT_USE/cmcrst/countyList')(app);
require('./DO_NOT_USE/rbsmbl/coverage')(app);
require('./DO_NOT_USE/cmcrst/exactTarget')(app);
require('./DO_NOT_USE/rbsmbl/userLogin_old')(app);
require('./DO_NOT_USE/cmcrst/createProspect')(app);
require('./DO_NOT_USE/rbsmbl/visitedMHSLanding')(app);
require('./DO_NOT_USE/rbsmbl/visitedMyOralHealth')(app);
require('./DO_NOT_USE/rbsmbl/visitedClaimsAndPayments')(app);
require('./DO_NOT_USE/rbsmbl/visitedMessageCenter')(app);
require('./DO_NOT_USE/rbsmbl/visitedEducationalMaterial')(app);
require('./DO_NOT_USE/rbsmbl/statementArchiveDocument')(app);
require('./DO_NOT_USE/rbsmbl/eobDocByUmi')(app);
require('./DO_NOT_USE/rbsmbl/preventiveSchedulePDF')(app);
require('./DO_NOT_USE/wcs/espot')(app);
require('./DO_NOT_USE/rbsmbl/adamContentXML')(app);
require('./DO_NOT_USE/rbsmbl/visionClaims')(app);
require('./DO_NOT_USE/rbsmbl/spendingAccountAcclaris')(app);
require('./DO_NOT_USE/rbsmbl/eBillRegistration')(app);
require('./DO_NOT_USE/rbsmbl/prenatalFormService')(app);

require('./DO_NOT_USE/rbsmbl/requestPrintedCopy')(app);
require('./DO_NOT_USE/rbsmbl/benefitSearch')(app);
require('./DO_NOT_USE/rbsmbl/pdfPrepopulate')(app);
require('./DO_NOT_USE/rbsmbl/updateUserProfile')(app);
require('./DO_NOT_USE/rbsmbl/demographicsGet')(app);
require('./DO_NOT_USE/rbsmbl/demographicsSave')(app);



// Directives
//Claims and Spending - Claims History directives
require('./directives/casClaimsHistory/claimsHistoryDrawer')(app);
require('./directives/casClaimsHistory/claimsHistoryDrawerDownload')(app);
require('./directives/casClaimsHistory/claimsHistoryMatchingDrawer')(app);
require('./directives/casClaimsHistory/claimsHistorySummaryDownload')(app);

require('./directives/splash/splashEspot')(app);
require('./directives/splash/splashEspotGroup')(app);
require('./directives/splash/splashLinks')(app);
require('./directives/claimDrawer')(app);
require('./directives/claimsMatchingDrawer')(app);
require('./directives/spendingClaimDrawer')(app);
require('./directives/claimDrawerDownloads')(app);
require('./directives/claimSummaryDownload')(app);
require('./directives/downloadReportMessage')(app);
require('./directives/claimStatus')(app);
require('./directives/claimInfo')(app);
require('./directives/claimsSinceLastVisit')(app);
require('./directives/dentalProcedure')(app);
require('./directives/manualHsa')(app);
require('./directives/messageSummary')(app);
require('./directives/messageDrawer')(app);
require('./directives/archiveStatement')(app);
require('./directives/educationDrawer')(app);
require('./directives/hipaaDrawer')(app);
require('./directives/educationSummary')(app);
require('./directives/smartMessage')(app);
require('./directives/educationalVideoPanel')(app);
require('./directives/checkPwdStrength')(app);
require('./directives/simpleLoadingBar')(app);
require('./directives/prescriptsCoveredDrugPreAuth')(app);
require('./directives/prescriptsDrugList')(app);
require('./directives/prescriptsFindPharmacy')(app);
require('./directives/prescriptsMailOrder')(app);
require('./directives/prescriptsFaqs')(app);
require('./directives/prescriptsPriorAuthorization')(app);
require('./directives/prescriptsForms')(app);
require('./directives/prescriptsChipMinutes')(app);
require('./directives/progressMeter2')(app);
require('./directives/prescriptsActTwoOhSeven')(app);
require('./directives/wellDivOne')(app);
require('./directives/wellnessBlog')(app);
require('./directives/dppOverview')(app);
require('./directives/wellnessConnected')(app);
require('./directives/wellnessRecommended')(app);
require('./directives/wellnessNeedSomeHelp')(app);
require('./directives/wellnessPreventiveSchedule')(app);
require('./directives/wellnessHealthyLinks')(app);
require('./directives/chronicConditionSupplement')(app);
require('./directives/wellnessTreatmentTools')(app);
require('./directives/vsbpPlanDir')(app);
require('./directives/pbsTvTopicList')(app);
require('./directives/adamIframe')(app);
require('./directives/numberRangeValidation')(app);
require('./directives/whyRegister')(app);
require('./directives/superiorTooltip')(app);
require('./directives/splash/updateBrowser')(app);
require('./directives/adamContent')(app);
require('./directives/adamTextLink')(app);
require('./directives/adamVisualContent')(app);
require('./directives/adamTemplate')(app);
require('./directives/adamTextContent')(app);
require('./directives/adamView')(app);
require('./directives/adamVersionInfo')(app);
require('./directives/adamPrintContent')(app);
require('./directives/adamSideBarNav')(app);
require('./directives/footerMessage')(app);
require('./directives/dateOfBirthValidation')(app);
require('./directives/dateRangeValidation')(app);
require('./directives/landing/customClientArticle')(app);
require('./directives/userNotification')(app);
require('./directives/spendingBlackoutMessage')(app);
require('./directives/compileHtml')(app);
require('./directives/claimsFormsLibrary')(app);

//Pre Password Find a Doctor page
require('./directives/preFindDoctor/findTheBest')(app);
require('./directives/preFindDoctor/otherSearches')(app);
require('./directives/preFindDoctor/paperDirectory')(app);
require('./directives/preFindDoctor/saveMoney')(app);
require('./directives/preFindDoctor/siteDisclaimer')(app);
require('./directives/preFindDoctor/virtualMemberServices')(app);

//Landing Page Directives
// require('./directives/landing/topBlock')(app);
// require('./directives/landing/landingPagePlanSummaryDirective')(app);
// require('./directives/landing/coverageUi')(app);
// require('./directives/landing/alertsUi')(app);
// require('./directives/landing/programsUi')(app);
// require('./directives/landing/toolsUi')(app);
// require('./directives/landing/espotSection')(app);
// require('./directives/landing/healthyPrograms')(app);
// require('./directives/landing/tempTeamsite')(app);
// require('./directives/landing/healthcareTools')(app);
// require('./directives/landing/formsLibraryBanner')(app);
// require('./directives/landing/preEffectuatedBanner')(app);
// require('./directives/landing/spendingAlerts')(app);
// require('./directives/landing/spendingPreBlackout')(app);


require('./directives/myOralHealthMessage')(app);
require('./directives/oralHealthCondition')(app);
require('./directives/oralHealthAddCondition')(app);
require('./directives/oralHealthAddRenewCondition')(app);
require('./directives/slidedownInternalCloseButton')(app);
require('./directives/myChartEspot')(app);
require('./directives/cms/cmsImg')(app);
require('./directives/cms/cmsArticle')(app);
require('./directives/contactUsOnlineForm')(app);
require('./directives/forms-library/prePwdFormLogin.js')(app);

// Coverage.
// require('./directives/coverage-summary/medicalCoverage')(app);
// require('./directives/coverage-summary/wellnessPrograms')(app);
// require('./directives/coverage-summary/prescriptionCoverage')(app);
// require('./directives/coverage-summary/visionCoverage')(app);
// require('./directives/coverage-summary/dentalCoverage')(app);
// require('./directives/coverage-summary/spendingCoverage')(app);
// require('./directives/coverage-summary/noProductsError')(app);
// require('./directives/coverage-summary/benefitBooklet')(app);

//Benefit Search
require('./directives/benefit-search/searchBarAutoComplete')(app);


// Account Settings
require('./pages/account')(app);

// Message Center
//require('./directives/message-center/messageCenterDrawer')(app);

// require('./directives/message-center/messageSummary')(app);


// Filters
require('./filters/fromyyyyMMdd')(app);
require('./filters/capitalize')(app);
require('./filters/lineStatus')(app);
require('./filters/removeHtmlTags')(app);
require('./filters/adamFilter')(app);
require('./filters/formatPhone')(app);

// Routes
var routes = [];
routes = routes.concat(require("./pages/home")(app));


routes = routes.concat(require("./pages/finder")(app));
routes = routes.concat(require("./pages/prescription")(app));
routes = routes.concat(require("./pages/virtual-medicine")(app));
routes = routes.concat(require("./pages/preFindDoctor")(app));
routes = routes.concat(require("./pages/claims")(app));
routes = routes.concat(require("./pages/spending")(app));
require('./pages/spending/spendingForms')(app);
routes = routes.concat(require("./pages/coverage")(app));
require('./pages/coverage/medical-benefits')(app);
require('./pages/coverage/myOralHealth')(app);

routes = routes.concat(require("./pages/account")(app));
//routes = routes.concat(require("./pages/account/contact-information/contact-information")(app));
//routes = routes.concat(require("./pages/account/contact-preferences/contact-preferences")(app));
//routes = routes.concat(require("./pages/account/member-profile/member-profile")(app));
//routes = routes.concat(require("./pages/account/membership-information/membership-information")(app));
//routes = routes.concat(require("./pages/account/other-insurance/other-insurance")(app));
routes = routes.concat(require("./pages/account/partner-products/partner-products")(app));
routes = routes.concat(require("./pages/account/password-security/password-security")(app));
routes = routes.concat(require("./pages/account/physician-information/physician-information")(app));
routes = routes.concat(require("./pages/account/terms-and-conditions/terms-and-conditions")(app));
routes = routes.concat(require("./pages/account/tobacco-information/tobacco-information")(app));
//routes = routes.concat(require("./pages/account/view-id-card/view-id-card")(app));

routes = routes.concat(require("./pages/coverage/coverage-summary")(app)); //coverage-summary routes: Work in progress

routes = routes.concat(require("./pages/coverage/centersOfExcellence")(app));
routes = routes.concat(require("./pages/coverage/imagePath")(app));
routes = routes.concat(require("./pages/coverage/specilityContent")(app));
routes = routes.concat(require("./pages/coverage/health-insurance-101")(app));
routes = routes.concat(require("./pages/secure-registration")(app));
routes = routes.concat(require("./pages/custom-landing")(app));
routes = routes.concat(require("./pages/wellness")(app));
routes = routes.concat(require("./pages/wellness/dppDetails")(app));
routes = routes.concat(require("./pages/wellness/ccs")(app));
routes = routes.concat(require("./pages/wellness/ccsSignUp")(app));
routes = routes.concat(require("./pages/wellness/mySharecare")(app));
routes = routes.concat(require("./pages/preFindDoctor/prePassFindDoctor")(app));
routes = routes.concat(require("./pages/landing")(app));
routes = routes.concat(require("./pages/vsbp")(app));
routes = routes.concat(require("./pages/logout")(app));
routes = routes.concat(require("./pages/customerService")(app));
routes = routes.concat(require("./pages/same-day-appointment")(app));
routes = routes.concat(require("./pages/ebill")(app));
routes = routes.concat(require("./pages/ebill/register.js")(app));
routes = routes.concat(require("./pages/coverage/benefitSearch.js")(app));
routes = routes.concat(require("./pages/hipaa")(app));
routes = routes.concat(require("./pages/hipaa-authorization/authorization.js")(app));
routes = routes.concat(require("./pages/prenatal")(app));
routes = routes.concat(require("./pages/prenatal/prenatalPlusForm.js")(app));
routes = routes.concat(require("./pages/vision-health")(app));
routes = routes.concat(require("./pages/vision-provider")(app));
routes = routes.concat(require("./pages/vision-provider/vision-provider-search.js")(app));
routes = routes.concat(require("./pages/faqs/faqs")(app));
routes = routes.concat(require("./pages/formsLibrary")(app));
routes = routes.concat(require("./pages/forms-library/formsLib.js")(app));
routes = routes.concat(require("./pages/postEnrollment")(app));
routes = routes.concat(require("./pages/post-enroll/postEnroll.js")(app));

routes = routes.concat(require("./pages/dispute.js")(app));

routes = routes.concat(require("./pages/phishingNotification.js")(app));
routes = routes.concat(require("./pages/webmdpreferences.js")(app));
routes = routes.concat(require("./pages/sapphire-landing")(app));
routes = routes.concat(require("./pages/claimsAndSpending")(app));
//routes = routes.concat(require("./pages/messageCenter")(app));

app.value('routes', routes);
