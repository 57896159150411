module.exports = function(app) {
    app.value('claimsData', {
    });
    app.factory('claimService', ['$http', '$q', 'services', '$filter', 'pdfRequestPrepService', 'claimsData','$cookies',
        function($http, $q, services, $filter, pdfRequestPrepService, claimsData, $cookies) {
            window.services = services;

            var
                _getDisplayGroupNumbers,
                _getMemberPin,
                _formatClaimTypes,
                _parseAcclarisPayments,
                findClaimTypes,
                findStatusTypes,
                findPaymentStatusTypes,
                findMembers,
                getSummary,
                refreshSummary,
                getDetails,
                getClaimStatus;




            findStatusTypes = function(claimSummaryData) {
                var types = [];
                var statusTypes = {
                    "1": 0,
                    "2": 0,
                    "3": 0,
                    "4": 0,
                    "5": 0
                };
                for (var i = 0; i < claimSummaryData.length; i++) {
                    var claim = claimSummaryData[i];
                    statusTypes[claim.bilgProvClmStaCd] = statusTypes[claim.bilgProvClmStaCd] || 0;
                    statusTypes[claim.bilgProvClmStaCd]++;
                }
                // Build and return array
                for (var n in statusTypes) {
                    if (statusTypes.hasOwnProperty(n)) {
                        switch(n) {
                            case '1':
                                types.push({name: 'Approved', data: {bilgProvClmStaCd: n, count: statusTypes[n]}});
                                break;
                            case '2':
                                types.push({name: 'Partially Approved', data: {bilgProvClmStaCd: n, count: statusTypes[n]}});
                                break;
                            case '3':
                                types.push({name: 'Denied', data: {bilgProvClmStaCd: n, count: statusTypes[n]}});
                                break;
                            case '4':
                                types.push({name: 'Adjusted', data: {bilgProvClmStaCd: n, count: statusTypes[n]}});
                                break;
                            case '5':
                                types.push({name: 'In Process', data: {bilgProvClmStaCd: n, count: statusTypes[n]}});
                                break;
                        }
                    }
                }
                return types;
            };

            findPaymentStatusTypes = function(claimSummaryData) {
                var partiallyPaidData = { partiallyPaid: 'y', count: 0 };

                return claimSummaryData.reduce(function(types, claim) {
                    if (typeof claim.partiallyPaid === 'string' && claim.partiallyPaid.toLowerCase() === 'y') {
                        if (!partiallyPaidData.count) {
                            types.push({ name: 'Partially Paid', data: partiallyPaidData });
                        }
                        partiallyPaidData.count++;
                    }
                    return types;
                }, []);
            };

            findClaimTypes = function(claimSummaryData, fullMemberInfo) {
                // Always show medical and drug filters
                var claimTypes = {
                    'medical': 0
                };
                var types = [];
                var plans = services.rbsmbl.memberInfo().getUniqueProducts(fullMemberInfo);
                // Look for dental/drug coverage
                for (var x = 0; x < plans.length; x++) {
                    var plan = plans[x];
                    if (plan.productData.dentalCoverage === "true") {
                        claimTypes['dental'] = 0;
                    }
                    // TODO: should we check lob or benefitPlanType for drug as well?
                    if (plan.productData.drugCoverage === "true") {
                        claimTypes['ods'] = 0;
                    }
                }
                for (var i = 0; i < claimSummaryData.length; i++) {
                    var claim = claimSummaryData[i];
                    claimTypes[claim.sysSrcCode] = claimTypes[claim.sysSrcCode] || 0;
                    claimTypes[claim.sysSrcCode]++;
                }
                // Build and return array
                for (var n in claimTypes) {
                    if (claimTypes.hasOwnProperty(n)) {
                        switch(n) {
                            case 'medical':
                                types.push({name: 'Medical', data: {count: claimTypes[n]}});
                                break;
                            case 'dental':
                                types.push({name: 'Dental', data: {count: claimTypes[n]}});
                                break;
                            case 'vision':
                                types.push({name: 'Vision', data: {count: claimTypes[n]}});
                                break;
                            case 'ods':
                                types.push({name: 'Drug', data: {count: claimTypes[n]}});
                        }
                    }
                }
                return types;
            };


            findMembers = function(claimSummaryData, fullMemberInfo) {
                // TODO: Sort order?
                var uniqueMembers = {};
                var nameClashes = {};
                var members = [];
                // Create a list of each unique member
                for (var i = 0; i < claimSummaryData.length; i++) {
                    var claim = claimSummaryData[i];
                    if (uniqueMembers[claim.mbrFirstNm.toLowerCase()+'-'+claim.mbrLastNm.toLowerCase()+'-'+claim.mbrBirthDate]) {
                        uniqueMembers[claim.mbrFirstNm.toLowerCase()+'-'+claim.mbrLastNm.toLowerCase()+'-'+claim.mbrBirthDate].count++;
                    }
                    else {
                        uniqueMembers[claim.mbrFirstNm.toLowerCase()+'-'+claim.mbrLastNm.toLowerCase()+'-'+claim.mbrBirthDate] = {
                            mbrFirstNm: claim.mbrFirstNm.toLowerCase(),
                            mbrLastNm: claim.mbrLastNm.toLowerCase(),
                            mbrBirthDate: claim.mbrBirthDate,
                            count: 1
                        };
                    }
                }

                // Sprinkle in any members found in the ums, matching the ridiculous formatting imposed by the claims service, especially on birthdate
                var umsMembers = services.rbsmbl.memberInfo().getUniqueMemberNames(fullMemberInfo);
                for (var x in umsMembers) {
                    if (umsMembers.hasOwnProperty(x)) {
                        var um = umsMembers[x];
                        um.birthDate = $filter('date')(new Date(um.birthDate), 'yyyyMMdd');
                        if (!uniqueMembers[um.firstName.toLowerCase()+'-'+um.lastName.toLowerCase()+'-'+um.birthDate]) {
                            uniqueMembers[um.firstName.toLowerCase()+'-'+um.lastName.toLowerCase()+'-'+um.birthDate] = {
                                mbrFirstNm: um.firstName.toLowerCase(),
                                mbrLastNm: um.lastName.toLowerCase(),
                                mbrBirthDate: um.birthDate,
                                count: 0
                            };
                        }
                    }
                }

                // Trim the list down to unique names, disregarding birthdates
                for (var mem in uniqueMembers) {
                    if (uniqueMembers.hasOwnProperty(mem)) {
                        var key = uniqueMembers[mem].mbrFirstNm+'-'+uniqueMembers[mem].mbrLastNm;
                        nameClashes[key] = nameClashes[key] || [];
                        nameClashes[key].push(uniqueMembers[mem]);
                    }
                }

                // Build our final name list, adding birthdate if necessary
                for (var n in nameClashes) {
                    if (nameClashes.hasOwnProperty(n)) {
                        for (var j = 0; j < nameClashes[n].length; j++) {
                            var member = nameClashes[n][j];
                            // var name = member.mbrFirstNm + ' ' + member.mbrLastNm;
                            var name = member.mbrFirstNm;
                            if (nameClashes[n].length > 1) {
                                // Two members with same name, add birthdate
                                name = name + ' ('+ member.mbrBirthDate.substr(4,2) + '/' + member.mbrBirthDate.substr(6,2) + '/' + member.mbrBirthDate.substr(0,4) +')';
                            }
                            members.push({name: name, data: member});
                        }
                    }
                }
                return members;
            };

            // **** getSummary helper methods **** //
            _getDisplayGroupNumbers = function(summary, uniqueProducts) {
                summary.forEach(function(claim) {
                    var
                        groupNumber = claim.clnCnRuleSetClaimsPcsgNo,
                        groups = uniqueProducts,
                        currentGroupData;

                    claim.displayGroupNumber = groupNumber;

                    for (var i = 0, len = groups.length; i < len; ++i) {
                        currentGroupData = uniqueProducts[i].groupData;

                        if (currentGroupData.groupNumber === groupNumber && currentGroupData.idCardEobGroupNumber) {
                            claim.displayGroupNumber = currentGroupData.idCardEobGroupNumber;
                            break;
                        }
                    }
                });
            };

            _getMemberPin = function(claim, uniqueProducts) {
                var
                    groupNumber = claim.clnCnRuleSetClaimsPcsgNo,
                    firstName = claim.mbrFirstNm.toUpperCase(),
                    lastName = claim.mbrLastNm.toUpperCase(),
                    birthDate = $filter('fromyyyyMMdd')(claim.mbrBirthDate),
                    pinNumber;

                uniqueProducts.forEach(function(product) {
                    if (product.groupData.groupNumber === groupNumber) {
                        product.productData.memberList.forEach(function(member) {
                            if (member.contact.birthDate === birthDate && member.contact.firstName === firstName && member.contact.lastName === lastName) {
                                pinNumber = member.pinNumber;
                            }
                        });
                    }
                });

                return pinNumber;
            };

            _formatClaimTypes = function(summary, uniqueProducts) {
                var configureDrugMathLines = function(aClaim) {
                    aClaim.mathLines = [
                        {
                            name: 'Provider Charge',
                            value: Number(aClaim.bilgTotCharge).toFixed(2) || ''
                        },
                        {
                            name: 'Member Discounted Rate',
                            value: Number(aClaim.drugAllChrAmt).toFixed(2) || ''
                        },
                        {
                            name: 'Plan Paid',
                            value: Number(aClaim.drugTotAprPymAt).toFixed(2) || ''
                        },
                        {
                            name: 'Your Total Cost',
                            value: Number(aClaim.bilgProvTotMbrLiab).toFixed(2) || ''
                        }
                    ];
                };

                summary.forEach(function(claim) {
                    var claimType = claim.sysSrcCode
                                  ? claim.sysSrcCode.toLowerCase()
                                  : '';

                    claim.claimType = {};

                    switch( claimType ) {

                        case 'medical':
                            claim.claimType.isMedical = true;
                            break;

                        case 'dental':
                            claim.claimType.isDental = true;
                            claim.pinNumber = _getMemberPin(claim, uniqueProducts);
                            break;

                        case 'vision':
                            claim.claimType.isVision = true;
                            break;

                        case 'ods':
                            claim.claimType.isDrug = true;
                            configureDrugMathLines(claim);
                            break;

                        default:
                            claim.claimType.isUndefined = true;
                    }
                });
            };
            // **** END getSummary helper methods **** //

            getClaimStatus = function(statusCode, scope) {
                statusCode = statusCode
                           ? String(statusCode)
                           : '';

                scope = scope || {};

                var status = scope.status || {};

                switch (statusCode) {
                    case '1':
                        status.description = 'Approved';
                        status.icon = 'icon-approved';
                        break;

                    case '2':
                        status.description = 'Partially Approved';
                        status.icon = 'icon-alert mhs-icon';
                        break;

                    case '3':
                        status.description = 'Denied';
                        status.icon = 'icon-denied';
                        break;

                    case '4':
                        status.description = 'Adjusted';
                        status.icon = 'icon-adjusted-claim';
                        break;

                    case '5':
                        status.description = 'In Process';
                        status.icon = 'icon-in-process';
                        break;

                    default:
                        status.description = 'Info Not Available';
                        status.icon = '';
                }
                return status;
            };

            getSummary = function(dateRange,provider) {
                dateRange = JSON.parse(dateRange || JSON.stringify({startDate: $filter('date')(new Date() - 365 * 60 * 60 * 24 * 1000, 'yyyyMMdd'), endDate: $filter('date')(new Date(), 'yyyyMMdd')}));

                var startDate = dateRange.startDate,
                    endDate = dateRange.endDate,
                    claimSummaryPromise = provider ? services.rbsmbl.claimSummary().fetch(startDate, endDate, provider) : services.rbsmbl.claimSummary().fetch(startDate, endDate);

                return claimSummaryPromise.then(
                    function(claimSummaryResponse) {
                        claimSummaryResponse = angular.copy(claimSummaryResponse);

                        var spendingAccountError = false;
                        if (claimSummaryResponse.data && claimSummaryResponse.data.baseMessage && claimSummaryResponse.data.baseMessage.statusMsgs && claimSummaryResponse.data.baseMessage.statusMsgs.messages) {
                            for (var i = 0; i < claimSummaryResponse.data.baseMessage.statusMsgs.messages.length; i++) {
                                var message = claimSummaryResponse.data.baseMessage.statusMsgs.messages[i];
                                if (message.id.indexOf("Acclaris") > -1) {
                                    if (message.text.toLowerCase() !== "okay") {
                                        if ($cookies.get('umsBrand').toLowerCase() == 'wybcbs'){
                                            spendingAccountError = false;

                                        }else{
                                            spendingAccountError = true;
                                            }
                                    }
                                }
                            }
                        }

                        var
                            summary = claimSummaryResponse.data.payload.getClaimAccumActivitySummaryData || [],
                            uniqueProducts = services.rbsmbl.memberInfo().getUniqueProducts();

                        summary.forEach(function(claim) {
                            claim.originalData = angular.copy(claim);
                        });

                        summary.spendingAccountError = spendingAccountError;

                        _getDisplayGroupNumbers(summary, uniqueProducts);
                        _formatClaimTypes(summary, uniqueProducts);
                        summary.totalClaims = claimSummaryResponse.data.payload.totalClaims;
                        summary.totalClaimsSinceLastVisit = claimSummaryResponse.data.payload.totalClaimsSinceLastVisit;
                        claimsData.summary = summary;
                        return claimsData;
                    },
                    function(err) {
                        console.error('claimSummary.fetch failed with error', err);
                        return claimSummaryPromise;
                    }
                );
            };

            refreshSummary = function(dateRange) {
                services.rbsmbl.claimSummary().flushCache();
                return getSummary(dateRange);
            };

            // **** getDetails helper methods **** //
            _parseAcclarisPayments = function( claimDetails, claim ) {
                for (var x = 0; x < claimDetails.length; x++) {
                    var line = claimDetails[x];
                    var paymentList = line.acclarisPaymentList;

                    line.showAcclarisInfo = false;
                    line.isIgnored = false;
                    line.isPayable = false;

                    // Hopefully this can be removed if the claimDrawer Download Claim Details service is simplified
                    pdfRequestPrepService.augmentAcclarisPaymentLine(line, claim, getClaimStatus);


                    if (paymentList) {
                        line.parsedAcclarisPaymentList = paymentList.map(
                            function(payment) {
                                var
                                    isPartiallyPaid = Boolean(payment.partialPaymentInfo),
                                    isPayNow = typeof payment.status === 'string' && payment.status.toLowerCase() === 'pay now',
                                    isPaid = typeof payment.paymentStatus === 'string' && payment.paymentStatus.toLowerCase() === 'paid',
                                    isDenied = typeof payment.status === 'string' && payment.status.toLowerCase() === 'denied',
                                    isPending = typeof payment.paymentInfo === 'string' && payment.paymentInfo.toLowerCase().match(/pending|in process/) || false,
                                    payMode;

                                if (isPaid || isPartiallyPaid || isDenied || isPending) {
                                    line.showAcclarisInfo = true;
                                }
                                if (isPartiallyPaid) {
                                    line.partialPaymentInfo = payment.partialPaymentInfo;
                                }
                                if (isPayNow) {
                                    line.payMode = payMode = payment.payMode.toLowerCase();
                                    line.amountOwed = payment.amountOwed;
                                    line.isPotentiallyPayable = true;

                                    if (payment.isIgnored.toLowerCase() === 'yes') {
                                        line.isIgnored = true;
                                    }
                                    else {
                                        line.isPayable = true;
                                    }
                                }




                                return {
                                    isPaid: isPaid,
                                    isPartiallyPaid: isPartiallyPaid,
                                    isDenied: isDenied,
                                    isPending: isPending,
                                    paymentInfo: payment.paymentInfo,
                                    paymentAmount: payment.paymentAmount,
                                    paymentMethod: payment.paymentMethod,
                                    denialReason: payment.denialReason,
                                    payMode: payMode
                                };
                            }
                        );
                    }
                }
            };
            // **** END getDetails helper methods **** //

            getDetails = function(claim) {
                var memberInfoPromise = services.rbsmbl.memberInfo().fetch();

                return memberInfoPromise.then(
                    function(umsResponse) {
                        var claimDetailPromise = services.rbsmbl.claimDetail().fetch(claim, umsResponse.data.payload);

                        return claimDetailPromise.then(
                            function(claimDetailResponse) {
                                claimDetailResponse = angular.copy(claimDetailResponse);
                                var
                                    payload = claimDetailResponse.data.payload,       // this is for drug claims: 'ods'
                                    details = payload.getClaimAccumActivityDetailData || [payload];

                                // FIXME: this is an array, but we're treating it ilke an object below
                                details.originalData = angular.copy(details);
                                claim.totalCount = payload.totalCount;
                                claim.currentMember = services.rbsmbl.memberInfo().currentMember(umsResponse.data.payload);
                                claim.umi13 = umsResponse.data.payload.umi + services.rbsmbl.memberInfo().umiSuffix(umsResponse.data.payload);
                                claim.umi = umsResponse.data.payload.umi;
                                _parseAcclarisPayments(details, claim);
                                return details;
                            },

                            function(err) {
                                console.error('claimDetail.fetch failed with error', err);
                                return claimDetailPromise;
                            }
                        );
                    },

                    function(err) {
                        console.error('memberInfo.fetch failed with error', err);
                        return memberInfoPromise;
                    }
                );
            };


            return {
                findClaimTypes: findClaimTypes,
                findStatusTypes: findStatusTypes,
                findPaymentStatusTypes: findPaymentStatusTypes,
                findMembers: findMembers,
                getSummary: getSummary,
                refreshSummary: refreshSummary,
                getDetails: getDetails,
                getClaimStatus: getClaimStatus
            };
        }
    ]);
};