module.exports = function(bootstrap) {
    bootstrap.run(['Service', function(Service) {
        new Service(
            {
                name: 'adamContentXML',
                group: 'rbsmbl',
                cache: true,
                url: '/rbsmbl/x-services/secure/wellness/v2/content',

                fetch: function(productID,projectTypeID,genContentID) {
                    return this.get(
                        {
                            productID: productID,
                            projectTypeID: projectTypeID,
                            genContentID: genContentID
                        }
                    );
                }
            }
        );
    }]);
};