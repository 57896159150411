module.exports = function(app) {
    app.directive('hipaaDrawer',
        function() {

            return {
                restrict: 'A',
                replace: true,
                template: require('directives/hipaaDrawer.html'),

                scope: {
                    material: '=hipaaDrawer',
                    section: '='
                },


                controller: ['$scope','coremetrics', function($scope,coremetrics) {
                    var material = $scope.material;
                    if (material.isOpen === undefined || material.isOpen === false) {
                        $scope.isExpanded = false;
                    } else {
                        $scope.isExpanded = true;
                    }

                    $scope.$on('drawerOpen', function (event, args) {
                        $scope.isExpanded = material.isOpen;
                    });

                    $scope.$on('drawerClose', function (event, args) {
                        $scope.isExpanded = material.isOpen;
                    });

                    $scope.isVideo = material.type === 'video';
                    $scope.isPDF = material.type === 'pdf';

                    var videoClicked;
                    $scope.emPlayVideoCoremetrics = function(){

                    };

                    $scope.emDownloadPDFCoremetrics = function(){


                    };

                    if ($scope.isPDF) {
                        material.url = material.linkHref;
                    }
                }]
            };
        }
    );
};