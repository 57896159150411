module.exports = function(app) {

	require('./claims/activitySummary')(app);
	require('./claims/claimsAndPayments')(app);
    require('./claims/payClaim')(app);
    require('./claims/payClaim.confirm')(app);
    require('./claims/dentalProcedureHistory')(app);
    require('./claims/messageCenter')(app);
    require('./claims/statementArchive')(app);
    require('./claims/educationalMaterial')(app);
    require('./claims/partial/payClaim')(app);
    require('./claims/partial/payClaim.confirm')(app);
    require('./claims/healthPlans')(app);
    require('./claims/healthPlansRefine')(app);

    app.controller('pages.claims', ["$scope", "messageService", "claimService", "coremetrics", "session", "$filter", "$state", "finder-logic2","brand", "storage", "$rootScope",
        function ($scope, messageService, claimService, coremetrics, session, $filter, $state, finderLogic,brand, storage, $rootScope) {
            var claimsAndPaymentsDateRange = storage.get('claimRefineDateRange');
            console.log("claims and payments date range", claimsAndPaymentsDateRange)
            claimService.getSummary(claimsAndPaymentsDateRange).then(
                function (claims) {
                    $scope.sidebar.links[1].starburst = Number(claims.totalClaimsSinceLastVisit);
                }
            );

            $scope.backText = 'Back to Claims';
            $scope.backAction = function () {
                coremetrics.elementTag('BACK TO CLAIMS AND PAYMENTS', 'MEMBER:CLAIMS');
                $state.go('claims.claimsAndPayments');
            };

            $scope.$on('SPAWidgetArrowClick', function () {
                coremetrics.elementTag('CLAIMS:SPENDING ACCOUNTS ARROW', 'MEMBER:CLAIMS');
            });

            $scope.headings = "Claims";

            $scope.sidebar.links = [];
            $scope.sidebar.data = {};
            $scope.sidebar.data.accumsFilter = 'activePlans';
            $scope.isMINCR = "mincr" == brand.current.sharedBrandCode.toLowerCase();
            $scope.isWYBrandCode = brand.current.sharedBrandCode.toLowerCase() === 'wybcbs';

            var theDomain = 'https://' + window.location.host;
            var NDVisionUrl = theDomain + "/emssvc/x-services/secure/sso/outbound/auto/v1/vendor/VSP";
            var isNdMedicaid = finderLogic.getPortalSelectedPlan().isNDMedicaid;


            if(("wybcbs" === brand.current.sharedBrandCode.toLowerCase())||
                ("ndbcbs" === brand.current.sharedBrandCode.toLowerCase()) ||
                $scope.isMINCR){
                $scope.shouldShowLegacyVersion = false;
            }else{
                $scope.shouldShowLegacyVersion = true;
            }

            if (brand.current.sharedBrandCode === "ndbcbs"){
                $scope.sidebar.links.push({
                    heading: '<span data-teamsite="MBR_Claims_LeftNav_ClaimsHeadline"></span>',
                    label: "Activity Summary",
                    state: "claims.activitySummary",
                    description: isNdMedicaid ? '<span data-teamsite="MBR_Claims_LeftNav_NDMedicaidClaimsHeadlineDesc"></span>' :'<span data-teamsite="MBR_Claims_LeftNav_ClaimsHeadlineDesc"></span>' + '<a class = "ndVision" href = " ' + NDVisionUrl +  ' " target = "_blank" rel="noopener noreferrer" >VSP.</a>'
                });
            }else{
                $scope.sidebar.links.push({
                    heading: '<span data-teamsite="MBR_Claims_LeftNav_ClaimsHeadline"></span>',
                    label: "Activity Summary",
                    state: "claims.activitySummary",
                    description: '<span data-teamsite="MBR_Claims_LeftNav_ClaimsHeadlineDesc"></span>'
                });
            }

            if (brand.current.sharedBrandCode === "ndbcbs") {
                $scope.sidebar.links.push({
                    heading: '<span data-teamsite="MBR_Claims_LeftNav_ClaimsAndPmtsHeadline"></span>',
                    label: "Claims & Payments",
                    state: "claims.claimsAndPayments",
                    description: isNdMedicaid ? '<span data-teamsite="MBR_Claims_LeftNav_NDMedicaidClaimsAndPmtsHeadlineDesc"></span>' : '<span data-teamsite="MBR_Claims_LeftNav_ClaimsAndPmtsHeadlineDesc"></span>' +   '<a class = "ndVision" href = " ' + NDVisionUrl +  ' " target = "_blank" rel="noopener noreferrer" >VSP. </a>',
                    refineTemplate: "pages/claims/refine.html",
                    refineController: "claims.claimsAndPaymentsRefine"
                });
            }else{
                $scope.sidebar.links.push({
                    heading: '<span data-teamsite="MBR_Claims_LeftNav_ClaimsAndPmtsHeadline"></span>',
                    label: "Claims & Payments",
                    state: "claims.claimsAndPayments",
                    description: '<span data-teamsite="MBR_Claims_LeftNav_ClaimsAndPmtsHeadlineDesc"></span>',
                    refineTemplate: "pages/claims/refine.html",
                    refineController: "claims.claimsAndPaymentsRefine"
                });
            }
            // CR00600617 - add Health Plans in for MN and WY
            // if (brand.current.sharedBrandCode === "mincr" ||brand.current.sharedBrandCode === "wybcbs") {
            //     $scope.sidebar.links.push({
            //         heading: '<span data-teamsite="MBR_Claims_LeftNav_HealthPlansHeadline"></span>',
            //         label: "Health Plans",
            //         href: "/mhs/#/healthPlans",
            //         description: '<span data-teamsite="MBR_Claims_LeftNav_HealthPlansHeadlineDesc"></span>'
            //     });
            // }
            // Below links to the angular version of Health Plans when ready to migrate
            // if (brand.current.sharedBrandCode === "mincr" ||brand.current.sharedBrandCode === "wybcbs" || $state.current.name === "claims.healthPlans") {
            //     $scope.sidebar.links.push({
            //         heading: '<span data-teamsite="MBR_Claims_LeftNav_HealthPlansHeadline"></span>',
            //         label: "Health Plans",
            //         state: "claims.healthPlans",
            //         description: '<span data-teamsite="MBR_Claims_LeftNav_HealthPlansHeadlineDesc"></span>',
            //         refineTemplate: "pages/claims/healthPlansRefine.html",
            //         refineController: "claims.claimsAndPaymentsRefine"
            //     });
            // }
            if (!session.data.blueEdgeDentalIndv) {
                var nl = $scope.sidebar.links.push({
                    heading: '<span data-teamsite="MBR_Claims_LeftNav_MessagesHeadline"></span>',
                    label: "Messages",
                    state: "claims.messageCenter",
                    description: '<span data-teamsite="MBR_Claims_LeftNav_MessagesHeadlineDesc"></span>',
                    refineTemplate: 'pages/claims/messageCenterRefine.html'
                });
                messageService.getMessages().then((function(pos) { return function(messages) {
                    $scope.sidebar.links[pos].starburst = Number(messages.unread);
                }; })(nl - 1));
            }
            if (session.data.isSubscriber && brand.current.sharedBrandCode !== "wybcbs" && brand.current.sharedBrandCode !== "ndbcbs") {
	            $scope.sidebar.links.push({
	                heading: '<span data-teamsite="MBR_Claims_LeftNav_StmtArchiveHeadline"></span>',
	                label: "Statement Archive",
                    state: "claims.statementArchive",
                    description: 'Access health plan statements. The information here is as of ' + $filter('date')(new Date(), 'MM/dd/yy') + '.',
                    refineTemplate: 'pages/claims/statementArchiveRefine.html'
	            });
            }
            if (brand.current.sharedBrandCode === "ndbcbs") {
                $scope.sidebar.links.push({
                    heading: '<span data-teamsite="MBR_Claims_LeftNav_EducationalMaterials_Headline"></span>',
                    label: "Forms & Resources",
                    state: "claims.educationalMaterial",
                    description: '<span data-teamsite="MBR_Claims_LeftNav_EducationalMaterials_HeadlineDesc"></span>'
                });
            }else{
                $scope.sidebar.links.push({
                    heading: '<span data-teamsite="MBR_Claims_LeftNav_EducationalMaterials_Headline"></span>',
                    label: "Educational Material",
                    state: "claims.educationalMaterial",
                    description: '<span data-teamsite="MBR_Claims_LeftNav_EducationalMaterials_HeadlineDesc"></span>'
                });
            }

            $rootScope.$on('$stateChangeError', function (ev, to, toParams, from, fromParams) {
                console.log('i heard it...');
            });
        }
    ]);
	return [
		{
			state: "claims",
			postPassword: true,
            appConfigMaintenanceApp: 'CLAIMS',
            appConfigMaintenanceKey: 'FULL_MAINTENANCE',
			url: "/claims",
            params: {
                claimNumber: ''
            },
            resolve: {
                redirect: ['sessionData', '$q', '$window', '$cookies', function (sessionData, $q, $window, $cookies) {
                    var deferred = $q.defer();
                    if (sessionData.isClaimsAndSpending) {
                        if ($cookies.get('statePriorToClaims')) {
                            deferred.reject();
                        } else {
                            var route = $window.location.hash;
                            route = route.replace('#', '');
                            route = route.replace('/claims', '');
                            route = route.replace('/', '');

                            switch (route) {
                            case "":
                            case "activitySummary":
                            default:
                                $window.location = "#/claims-and-spending/claims-history";
                            }

                            deferred.reject();
                        }
                    } else {
                        deferred.resolve();
                    }

                    return deferred.promise;
                }]
            },
			views: {
				'body@': {
					template: bootstrap.templates['page-templates/categoryCustom.html'],
					controller: "pages.claims"
				},
				"content@claims": {
                    template:   '<article data-spending-blackout-message></article>' +
					            '<article data-educational-video-panel class="margin-bottom"></article>' +
                                '<article data-spending-account-balance class="margin-bottom"></article>' +
                                // '<article data-ng-if="shouldShowLegacyVersion" data-plan-progress-V-3="active" class="margin-btm-ten"></article>'+
                                '<div class="margin-btm-ten"><mbr-plan-progress></mbr-plan-progress></div>'+
                                '<article data-ng-include="\'pages/claims/activitySummary.html\'"></article>' +
                                '<article data-smart-message class="margin-top margin-bottom"></article>',
					controller: "pages.claims.activitySummary"
				},
				"rightColumn@claims": {
					template: require('./claims/rightSidebar.html'),
                    controller: "pages.claims"
				}
			}
		},
		{
			state: "claims.activitySummary",
            appConfigMaintenanceApp: 'CLAIMS',
            appConfigMaintenanceKey: 'ACTIVITY_SUMMARY_MAINTENANCE',
			url: "/activitySummary",
            isWCAGCompliant: true,
			views: {
			}
		},
		{
			state: "claims.claimsAndPayments",
            appConfigMaintenanceApp: 'CLAIMS',
            appConfigMaintenanceKey: 'CLAIMS_AND_PAYMENTS_MAINTENANCE',
			url: "/claimsAndPayments",
            isWCAGCompliant: false,
			views: {
				"content@claims": {
                    template: require('pages/claims/claimsAndPayments.html'),
					controller: "pages.claims.claimsAndPayments"
				},
				"rightColumn@claims": {
					template: "<article ng-if='isWYBrandCode' class='claimsSupplemental' data-smart-stack> <article class='claimsSupplemental' data-download-report-message></article></article> " +
                    "<article data-my-chart-espot></article>"
				}
			}
		},
        {
			state: 'claims.claimsAndPayments.select',
			url: '/:claimNumber/select',
            isWCAGCompliant: true,
			views: {
                'body@': {
					template: bootstrap.templates['page-templates/detailCustom.html'],
					controller: 'pages.claims'
				},
                'leftColumn@claims.claimsAndPayments.select': {
                    template: '<article data-claim-info></article>',
                    controller: 'pages.claims.payClaim'
                },
				'content@claims.claimsAndPayments.select': {
					template: require('pages/claims/payClaim.html'),
					controller: 'pages.claims.payClaim'
				},
				"rightColumn@claims.claimsAndPayments.payClaim": {
					// Necessary to ensure a blank right sidebar
				}
			}
		},
        {
			state: 'claims.claimsAndPayments.confirm',
			url: '/:claimNumber/confirm/:payData',
            params: { umi: '', claim: {}, selectedServices: [], payTo: {}, payFrom: '' },
			views: {
                'body@': {
					template: bootstrap.templates['page-templates/detailCustom.html'],
					controller: 'pages.claims'
				},
                'leftColumn@claims.claimsAndPayments.confirm': {
                    template: '<article data-claim-info></article>',
                    controller: 'pages.claims.payClaim'
                },
				'content@claims.claimsAndPayments.confirm': {
					template: require('pages/claims/payClaim.confirm.html'),
					controller: 'pages.claims.payClaim.confirm'
				},
				"rightColumn@claims.claimsAndPayments.confirm": {
					// Necessary to ensure a blank right sidebar
				}
			}
		},
        //startpartialpay
        {
            state:'claims.claimsAndPayments.partialPay',
            url:'/:claimNumber/partialPay/select',
            params:{selectedPayTo:'',submittedPayment:''},
            views:{
                'body@':{
                    template: bootstrap.templates['page-templates/detailCustom.html'],
                    controller:'pages.claims'
                },
                'leftColumn@claims.claimsAndPayments.partialPay':{
                    template:'<article data-claim-info></article>',
                    controller:'claims.claimsAndPayments.partial.payClaim'
                },
                'content@claims.claimsAndPayments.partialPay':{
                    template: require('pages/claims/partial/payClaim.html'),
                    controller:'claims.claimsAndPayments.partial.payClaim'
                },
                "rightColumn@claims.claimsAndPayments.partialPay":{//Necessarytoensureablankrightsidebar
                }
            }
        },
        {
            state:'claims.claimsAndPayments.partialPayConfirm',
            url:'/:claimNumber/partialPay/confirm/:payData',
            params:{claim:{},payTo:{},payFrom:'',partialPayAmt:'',remainingBal:'',clientNumber:''},
            views:{
                'body@':{
                    template: bootstrap.templates['page-templates/detailCustom.html'],
                    controller:'pages.claims'
                },
                'leftColumn@claims.claimsAndPayments.partialPayConfirm':{
                    template:'<article data-claim-info></article>',
                    controller:'claims.claimsAndPayments.partial.payClaim'
                },
                'content@claims.claimsAndPayments.partialPayConfirm':{
                    template: require('pages/claims/partial/payClaim.confirm.html'),
                    controller:'pages.claims.partial.payClaim.confirm'
                },
                "rightColumn@claims.claimsAndPayments.partialPayConfirm":{//Necessarytoensureablankrightsidebar
                }
            }
        },
       //endpartialpay

        {
			state: 'claims.dentalProcedureHistory',
			url: '/dentalProcedureHistory/:groupNumber',
            params: { previousState: '', pinNumber: '' },
			views: {
                'body@': {
                    template: bootstrap.templates['page-templates/categoryDetail.html'],
                    controller: 'pages.claims.dentalProcedureHistory'
                },
				'content@claims.dentalProcedureHistory': {
					template: require('pages/claims/dentalProcedureHistory.html'),
					controller: 'pages.claims.dentalProcedureHistory'
				},
                'rightColumn@claims.dentalProcedureHistory': {
                    // Keeping it blank
                }
			}
		},
        {
			state: 'claims.messageCenter',
            appConfigMaintenanceApp: 'CLAIMS',
            appConfigMaintenanceKey: 'MESSAGE_CENTER_MAINTENANCE',
			url: '/messageCenter?filterType',
            isWCAGCompliant: true,
            params: { id: '' },
			views: {
				'content@claims': {
					template: require('pages/claims/messageCenter.html'),
					controller: 'pages.claims.messageCenter'
				},
                'rightColumn@claims': {
                    // Keeping it blank
                }
			},
            resolve: {
                priorAuthTemplates: ["teamsite", function(teamsite){
                    //Fetching templates ahead of time. Subsequent calls to TS for templates can be fetched from Cached.
                    teamsite.get('PRIOR_AUTH_UI_APPROVED').then(function (response){}, function(error){});
                    teamsite.get('PRIOR_AUTH_UI_DENIED').then(function (response){}, function(error){});
                    teamsite.get('PRIOR_AUTH_UI_PARTIALLY_APPROVED').then(function (response){}, function(error){});
                    teamsite.get('PRIOR_AUTH_UI_IN_REVIEW').then(function (response){}, function(error){});
                    teamsite.get('PRIOR_AUTH_UI_CANCELLED').then(function (response){}, function(error){});
                }]
            }
		},
        {
			state: 'claims.statementArchive',
            appConfigMaintenanceApp: 'CLAIMS',
            appConfigMaintenanceKey: 'STATEMENT_ARCHIVE_MAINTENANCE',
			url: '/statementArchives',
            isWCAGCompliant: true,
			views: {
				'content@claims': {
					template: require('pages/claims/statementArchive.html'),
					controller: 'pages.claims.statementArchive'
				},
                'rightColumn@claims': {}
			}
		},
        {
			state: 'claims.educationalMaterial',
            appConfigMaintenanceApp: 'CLAIMS',
            appConfigMaintenanceKey: 'EDUCATIONAL_MATERIAL_MAINTENANCE',
			url: '/educational-materials',
            resolve: {
                resourcesList: ["contentResourceServices", function(contentResourceServices){
					var CONTENT_TYPE = "Bluecore Resources";
                    return contentResourceServices.getResourceIdListAsync(CONTENT_TYPE);
                }]
            },
			views: {
				'content@claims': {
					template: require('pages/claims/educationalMaterial.html'),
					controller: 'pages.claims.educationalMaterial'
				},
                'rightColumn@claims': {}
			}
		}/*,
        {
            state: "claims.healthPlans",
            appConfigMaintenanceApp: 'CLAIMS',
            appConfigMaintenanceKey: 'HEALTH_PLANS_MAINTENANCE',
            url: "/healthPlans",
            views: {
                'content@claims': {
                    template: require('pages/claims/healthPlans.html'),
                    controller: 'pages.claims.healthPlans'
                }
            }
        }*/
	];

};