module.exports = function(app) {
    app.directive('chronicConditionSupplement', function() {

        return {
            restrict: 'A',
            replace: true,
            template: require('directives/chronicConditionSupplement.html'),

            // scope: {},
            controller: ['$scope', 'services', 'coremetrics',
                function($scope, services, coremetrics) {
                    services.rbsmbl.vbbMemberInfo()
                        .getData()
                        .then(function(memberData) {
                            if(memberData && memberData.data && memberData.data.payload){
                                var memData = memberData.data.payload;
                                $scope.ccsFlag = false;
                                $scope.ccsEnhancedFlag = false;
                                $scope.showCCS = false;

                                $scope.tagSignUp = function () {
                                    coremetrics.elementTag('Sign up: Identification Fill out Form Link', 'Sign up ECMR');
                                };

                                if(memData.memberList[0].packages[0].packageCode == "CONDITION MNGT"){
                                    $scope.ccsFlag = true;
                                }
                                else if(memData.memberList[0].packages[0].packageCode == "PROTOCOL COMPL"){
                                    $scope.ccsEnhancedFlag = true;
                                }
                                else{
                                    $scope.showCCS = false;
                                }

                                if($scope.isMINCR && $scope.ccsFlag || $scope.ccsEnhancedFlag){
                                    $scope.showCCS = true;
                                }
                                else{
                                    $scope.showCCS = false;
                                }
                            }
                        });
                }
            ]
        };
    });
}