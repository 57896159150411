module.exports = function(app) {
    app.factory('preventiveScheduleService', ['services', 'invisibleFormService', '$window',
        function(services, invisibleFormService, $window) {
            var getPreventiveSchedulePDF;

            getPreventiveSchedulePDF = function() {
                var preventiveSchedulePromise = services.rbsmbl.preventiveSchedulePDF().fetch();

                return preventiveSchedulePromise.then(
                    function(response) {
                        return response;//Nov Release changes
                    },
                    function(err) {
                        console.error('preventiveSchedule failed with error', err);
                        return preventiveSchedulePromise;
                    }
                );
            };

            return {
                getPreventiveSchedulePDF: getPreventiveSchedulePDF
            };
        }
    ]);
};
