module.exports = function(app) {

    app.controller('pages.wellness.mySharecare', ['$scope', 'session', 'services',  'finder-logic2', 'teamsite', 'modal', 'wellnessUtility', '$state', 'brand',
        function($scope, session, services, finderLogic, teamsite, modal, wellnessUtility, $state, brand) {
            window.document.title = "My Sharecare";
        $scope.getsharecareLink =function(){

           var sharcareURL= "https://mycare.sharecare.com";
            window.open(sharcareURL, '_blank');
        };



        }


    ]);
};