module.exports = function(app) {
    app.directive('prescriptsFindPharmacy', function() {

        return {
            restrict: 'A',
            replace: true,
            template: require('directives/prescriptsFindPharmacy.html'),
            // scope: {},
            controller: ['$scope', 'finder-logic2',
                function($scope, finderLogic) {
                    $scope.showLongTerm = finderLogic.showLongTerm();
                    $scope.getNewRx = function () {
                        window.open(finderLogic.newRxHREF(),"searchRXWindow");
                    };
                }
            ]
        };
    });
}