module.exports = function(app) {
    app.directive('adamTemplate',
        function($stateParams) {
            return {
                restrict: 'A',
                templateUrl: function(){
                    var projectID=$stateParams.projectTypeID;
                    var productID=$stateParams.productID;
                    var genContentID=$stateParams.genContentID;
                    if ((productID === '101' && projectID === '3') || (productID === '13')) {
                        return "pages/wellness/adamPresentation.html";
                    } else if (productID === '117' && projectID === '2') {
                        return "pages/wellness/adamImagePage.html";
                    } else if (productID === '117' && projectID === '57') {
                        return "pages/wellness/adamVideoPage.html";
                    } else if (projectID === '10') {
                        return "pages/wellness/adamInDepthReport.html";
                    } else if (productID === '28' && (genContentID === '000180' || genContentID === '000424' || genContentID === '000285' )) {
                        return "pages/wellness/adamBMIChart.html";
                    } else {
                        return "pages/wellness/adamDefault.html";
                    }
                }
            };
        }
    );
};