module.exports = function (app) {
    require('./directives/changePassword')(app);
    app.controller('pages.account.password-security', ['$scope', 'session', 'brand', '$timeout', 'services', '$q', 'passwordSecurityService',
        function ($scope, session, brand, $timeout, services, $q, passwordSecurityService) {

            $scope.securityQuestions = [
                {label: 'In what city were you born?', value: 'In what city were you born?'},
                {label: 'What was the name of your first pet?', value: 'What was the name of your first pet?'},
                {label: 'What is your favorite food?', value: 'What is your favorite food?'},
                {label: 'What was your first car?', value: 'What was your first car?'},
                {label: 'What is your favorite vacation spot?', value: 'What is your favorite vacation spot?'}];

            $scope.heading = "Password & Security";
            $scope.passwordEdit = false;
            $scope.editSecurityQuestion = false;
            $scope.message = {};
            $scope.changeEmail = {};
            $scope.changePassword = {};
            $scope.changeSecurityQuestion = {};
            $scope.memberInfo = session.data.memberInfo;
            $scope.showSuccessCheckQuestion = false;
            $scope.showSuccessCheckPassword = false;
            $scope.showPasswordMessage = false;
            $scope.showSecurityMessage = false;
            //$scope.editSecurityQuestionForm;
            //window.$scope = $scope;

            $scope.editSwitch = function (key) {

                $scope[key] = !$scope[key];
            };
            services.rbsmbl.volatileMemberInfo().fetch().then(function(resp){
                $scope.memberSecurityQuestion =resp.data.payload.securityQuestion;
                //console.log("volatile info resp in account js",$scope.memberSecurityQuestion);
                return $scope.memberSecurityQuestion;
            });


            $scope.switchToFalse = function (key) {
                $scope[key] = false;
               // $scope.showSuccessCheckQuestion = false;
            };
  
            $scope.clearForm = function() {
                $scope.changePassword.currentPassword = '';
                $scope.changePassword.password = '';
                $scope.changePassword.verifiedPassword = '';
            };
            $scope.clearFields = function() {
                $scope.changeSecurityQuestion.newSecurityQuestion= "";
                $scope.changeSecurityQuestion.newSecurityQuestionAnswer="";

            };

            $scope.submitNewPassword = function (formName) {

                if(formName.$valid) {
                    $scope.changePassword.disable = true;
                    passwordSecurityService.updatePassword(formName.newPasswordForm.currentPassword.$modelValue,
                                                           formName.newPasswordForm.newPassword.$modelValue,
                                                           formName.newPasswordForm.newPasswordVerify.$modelValue).then(function (response) {
                        $scope.changePassword.disable = false;
                        $scope.showSuccessCheckPassword = true;
                        //console.log("submission successful",response);
                        $scope.editSwitch('passwordEdit');

                    }, function (error) {
                        $scope.changePassword.disable = false;
                        $scope.showSuccessCheckPassword = false;
                        //console.log("update password failed",error);
                        //console.log(formName);
                        formName.newPasswordForm.currentPassword.$setValidity("invalidPassword",false);
                        formName.newPasswordForm.currentPassword.$modelValue="";


                    });
                }
            };

            $scope.submitNewSecurityQuestion = function (formName) {

                //console.log("security form",formName);

                //$scope.showSecurityMessage = true;
               if (formName.$valid){
                   $scope.changeSecurityQuestion.disable = true;
                    passwordSecurityService.updateSecurityQuestions($scope.changeSecurityQuestion.newSecurityQuestion.value, $scope.changeSecurityQuestion.newSecurityQuestionAnswer).then(function (response) {
                        var successMessage = (response && response.status) ? response.status : 'Success';
                        $scope.changeSecurityQuestion.disable = false;
                        $scope.message.changeSecurityQuestion = successMessage;
                        $scope.showSuccessCheckQuestion = true;
                       // console.log("question change successful",$scope.showSuccessCheckQuestion);
                        $scope.memberSecurityQuestion = $scope.changeSecurityQuestion.newSecurityQuestion.value;
                        $scope.editSwitch('editSecurityQuestion');
                    }, function (error) {
                        //console.log("security question error",error);
                        $scope.showSuccessCheckQuestion = false;
                        $scope.changeSecurityQuestion.disable = false;
                        $scope.message.changeSecurityQuestion = 'Unable to update your security question.';
                    });
            }
            };
        }
    ]);
};
