module.exports = function (app) {
	require("./ebill/register")(app);

	app.controller("pages.ebill", ["$scope", "sessionData", function ($scope, sessionData) {
		$scope.headings = "eBill";
		$scope.sidebar.links = [];
		var description = "Register for eBill to pay your online bills.";
		
		var brand = sessionData && sessionData.memberInfo && sessionData.memberInfo.brand;
		if(brand.toUpperCase() == 'MINCR'){
			description = "Register for eBill to pay your health insurance premium online.";
		}

		$scope.sidebar.links.push({
			heading: "<span data-teamsite='MBR_EBILL_REGISTRATION_HEADING'></span>",
			label: "Register",
			active: true,
			description: description,
			state: "ebill"
		});
	}]);

	return [
		{
			state: "ebill",
			url: "/ebill",
			postPassword: true,
			views: {
				"body@": {
					template: bootstrap.templates["page-templates/categoryCustom.html"],
					controller: "pages.ebill"
				},
				"content@ebill": {
					template: require("./ebill/register.html"),
					controller: "pages.ebill.register"
				}
			}
		},
		{
			state: "ebill.register",
			url: "/register",
			views: {}
		}
	]
};