module.exports = function(app) {
    app.directive('myOralHealthMessage', function() {

        return {
            restrict: 'A',
            replace: true,
            template: require('directives/myOralHealthMessage.html'),

            controller: ['$scope', 'teamsite', function($scope, teamsite) {
                $scope.showBenefits = false;

                teamsite.get('MBR_COVERAGE_ORAL_HEALTH_MESSAGE_4').then(function (data) {
                    $scope.contactInfoMsg = data;
                });
            }]
        };
    });
};