module.exports = function(app) {

    app.service('vsbpLogic', [ '$q', 'services',
                    function($q, services) {
                        this.MakePlanMembersArray = function(allMembers, thisPlan, isLoggedInUplId) {

                            // Iterate the plans in the time frame ----------------------------
                            // for (var i = 0; i < thisPlan.length; i++) {
                                var arrSelectedMembers = [];

                                // Iterate the members in the allMembers property -------------
                                for (var j = 0; j < allMembers.length; j++) {
                                    // allMembers[j].isLoggedIn
                                    // Iterate the members coverage map -----------------------
                                    // var arrCoverageMap = allMembers[j].coverageMap;
                                    for (var k = 0; k < allMembers[j].coverageMap.length; k++) {
                                        var aCoverageMap = allMembers[j].coverageMap[k];
                                        // a coverageMap is an array that represents the member's plans
                                        if (thisPlan.uplid == aCoverageMap.uplid) {
                                            allMembers[j].memberId = aCoverageMap.suffixedUmi;
                                            // allMembers[j].isLoggedIn = (aCoverageMap.uplid == isLoggedInUplId) ? true : false;
                                            arrSelectedMembers.push(allMembers[j]);
                                        }
                                    }
                                }
                            // }
                            if (arrSelectedMembers && arrSelectedMembers.length  && arrSelectedMembers.length > 0 ) {
                                for (var i = 0; i < arrSelectedMembers.length; i++) {
                                    // we make it lower case here so that the css can capitalize the first letter (a quirk in css)
                                    arrSelectedMembers[i].firstName = arrSelectedMembers[i].firstName.toLowerCase();
                                }
                            }
                            return arrSelectedMembers;
                        }
                    }  ]  )

    app.factory('vsbpGetPlans', ['$q', 'services',
        function ($q, services) {
            var setParam;
            setParam = function (theBrand) {
            //}
            //<editor-fold desc="_____ Begin ws stub ____________________" >

            return services.rbsmbl.vsbpPlans().fetch(theBrand).then(
                function (response) {
                    if (response && response.data && response.data.payload && response.data.payload.planInfo) {
                        var planInfo = response.data.payload.planInfo;
                        return planInfo;
                    } else {
                        return null;
                    }
                },
                function (err) {
                    //   console.error('fetch failed in getCurrentMember', err);
                    $q.reject(err);
                    return err;
                }
            );

        }
            return {
                vsbpPlans: setParam

            };
        }
    ]);

    app.factory('vsbpSetCookie', ['$q', 'services', 'session',
        function ($q, services, session) {
            var hitService;
            hitService = function (theParams) {
                return services.rbsmbl.vsbpSetCookie().fetch(theParams).then(
                    function (response) {
                        if (response && response.data && response.data.payload && response.data.payload.status) {
                            var myBoo = (response.data.payload.status == 'SUCCESS') ? true : false;
                            return myBoo;
                        } else {
                            return $q.reject('We did not get the response.data.payload we expected in vsbpSetCookie.');
                        }
                    },
                    function (err) {
                        return $q.reject(err);
                    }
                );
            };
            return {
                booCookieIsSet: hitService
            };
        }
    ]);

}