module.exports = function(app) {
    app.directive('claimInfo', function() {

        return {
            restrict: 'A',
            replace: true,

            template: require('directives/claimInfo.html')
        };
    });
};