module.exports = function (app) {
    require("./post-enroll/postEnroll")(app);

    app.controller("pages.postEnrollment", ["$scope", function ($scope) {
        $scope.headings = "Post Enrollment",
        $scope.backText = "back";
        $scope.backAction = function(){
            window.location = "/home/";  
        }
    }]);

    return [
        {
            state: "postEnrollment",
            url: "/post-enroll",
            postPassword: false,
            views: {
                "body@": {
                    template: require('./post-enroll/page-templates/contentCustom.html'),
                    controller: "pages.postEnrollment"
                },
                "content@postEnrollment": {
                    template: require("./post-enroll/postEnroll.html"),
                    controller: "pages.postEnrollment.postEnroll"
                }
            }
        }
    ];
};