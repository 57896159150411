module.exports = function(app) {
    app.service('sidebar', [ '$rootScope',
        function($rootScope) {
            var getLinkByLabel = function(label) {
                var links = $rootScope.sidebar.links;
                label = typeof label === 'string' && label.toLowerCase();
                for (var i = 0; i < links.length; ++i) {
                    if (links[i].label && links[i].label.toLowerCase() === label) {
                        return links[i];
                    }
                }
            };

            var decrementStarburst = function(sidebarLink) {
                if (typeof sidebarLink.starburst === 'number') {
                    --sidebarLink.starburst;
                }
            };

            return {
                getLinkByLabel: getLinkByLabel,
                decrementStarburst: decrementStarburst
            };
        }
    ]);
};
