module.exports = function (app) {

    app.controller("pages.coverage.centersOfExcellence", ['$scope', "brand", "navigation", "services", "session", "$window", function ($scope, brand, navigation, services, session, $window) {

        $scope.brandData = brand.current;
        $scope.navigation = navigation.data;
        $scope.hasMessageCenter = (typeof $scope.brandData.messageCenter !== "undefined") ? $scope.brandData.messageCenter : true;

        // if ($scope.hasMessageCenter && session.data.memberInfo) {
        //     services.rbsmbl.csPhoneNumber().fetch(session.data.memberInfo).then(function (response) {
        //         if (response && response.data && response.data.payload) {

        //             var CSPhoneNumber = response.data.payload.CSPhoneNumber;

        //             if (CSPhoneNumber && CSPhoneNumber.replace(/\s/g, '').length > 0) {
        //                 var last_four, middle_three, area_code, international, len;
        //                 len = CSPhoneNumber.length;
        //                 last_four = CSPhoneNumber.substring(len - 4, len);
        //                 middle_three = CSPhoneNumber.substring(len - 7, len - 4);
        //                 area_code = CSPhoneNumber.substring(len - 10, len - 7);
        //                 international = (len == 11) ? CSPhoneNumber.substring(len - 11, len - 10) + '-' : '';
        //                 CSPhoneNumber = international + area_code + '-' + middle_three + '-' + last_four;
        //                 $scope.CSPhoneNumber = CSPhoneNumber;
                    
        //             } else {
                        
        //             }
        //         }
        //     });
            
        // }

        if ($scope.hasMessageCenter && session.data.memberInfo) {
            $scope.CSGetNumberFromCard = "the number on the back of your member Id card.";
            services.rbsmbl.ccoeDetails().fetch().then(function (response) {
                $scope.specialities = response.data.payload.specialties;
                })
        };

        $scope.downloadFAQPDF = function () {
            var PDFurl = "/rbsmbl/x-services/secure/provider/v1/ccoe/document/faqs";
            $window.open(PDFurl);

        };

        $scope.downloadCostComparisonPDF = function () {
            var PDFurl = "/rbsmbl/x-services/secure/provider/v1/ccoe/document/flyers";
            $window.open(PDFurl);
        }

    }]);

};