module.exports = function(app) {

    app.controller('pages.secureRegistration.secureRegister.error', ["$state", "$scope", "$http", "$cookies","coremetrics", "modal",
        function($state, $scope, $http, $cookies, coremetrics, modal) {

            var errorCode = $state.params.status || $state.params.errorCode || 500;
            var errorStatus = $state.params.statusText || "";
            $scope.isWY = $scope.brandData.ubk == '6012' ? true : false;

            if($scope.brandData.sharedBrandCode.toLowerCase() === "mincr"){
                $scope.techNumber = $scope.brandData.phoneNumbers.mcutechincal;
            }
            else {
                $scope.techNumber = $scope.brandData.phoneNumbers.technical;
            }

            $scope.tty = $scope.brandData.phoneNumbers.technicalTTY || "";
            //CR00779941 - Wrong phone number issue
            if(($scope.brandData.sharedBrandCode.toLowerCase() !== "ibcah" || $scope.brandData.sharedBrandCode.toLowerCase() !== "ibccr") && $scope.brandData.phoneNumbers.languageEmployer[0] === '1-866-823-0925'){
                $scope.csPhoneNumber = "1-866-306-1059";
            }
            else{
                $scope.csPhoneNumber = $scope.brandData.phoneNumbers.customerService || $scope.brandData.phoneNumbers.languageEmployer[0];
            }
            $scope.isMINCR = $scope.brandData.sharedBrandCode.toLowerCase() === "mincr";
            //TODO: Error messages need to be moved into Teamsite, to better handle brand specific error messaging.

            // 185650        -->  Invalid User ID.
            // 185652        -->  Exceeds maximum number of retry attempts.
            // 400           -->  Invalid Token.
            // CANCEL_TERMS  -->  Cancel from terms & conditions.
            // CANCEL_PWD    -->  Cancel from create a password.

            if (errorCode === "185650" || errorCode === 185650) {
                $scope.errorCode = 2; // Invalid User ID.

            } else if (errorCode === "185652" || errorCode === 185652) {
                $scope.errorCode = 3; // Exceeds maximum number of retry attempts.

            } else if (errorCode === "CANCEL_TERMS") {
                $scope.errorCode = 4; // Cancel on terms & conditions (step2)

            } else if (errorCode === "CANCEL_PWD") {
                $scope.errorCode = 5; // Cancel on create password (step3)

            } else {
                $scope.errorCode = 1; // Handle any unknown errors
            }

            $scope.back = function () {
                location.replace("/home/");
            };
        }
    ]);

};