module.exports = function(app) {
    app.factory('medicalDentalService', ['$http', '$q','services', 'invisibleForm', 'sessionData',
        function($http, $q, services, invisibleForm, sessionData) {

            var getConditionTypes,
                addCondition,
                _addOrRenewCondition,
                getCurrentConditions,
                getMemberCurrentConditions,
                getMemberStatus,
                refreshMemberConditions,
                cancelCondition,
                renewCondition,
                formatDate;

            getConditionTypes = function(enrollmentSourceCode, groupNumber) {
                var deferred = $q.defer();
                var params = {
                    enrollmentsourcecode: enrollmentSourceCode,
                    groupnumber: groupNumber
                };

                services.rbsmbl.conditionTypes().fetch(params).then(function(response) {
                    var conditionTypeList = services.rbsmbl.conditionTypes().parse(response);
                    var medicalConditions = [];

                    for (var x = 0; x < conditionTypeList.length; x++) {
                        var condition = conditionTypeList[x];

                        // Safety check that coverage period in months is provided.
                        if (condition.coveragePeriodInMonths === undefined || condition.coveragePeriodInMonths <= 0) {
                            condition.coveragePeriodInMonths = (condition.templateName === "TRANSPLANT" || condition.templateName === "TREATMENT" ? 60 : 12);
                        }

                        medicalConditions.push({
                            name: condition.description,
                            value:  {
                                description: condition.description,
                                code: condition.code,
                                groupClaimInfoId: condition.groupClaimInfoId,
                                templateName: condition.templateName.toLowerCase(),
                                gender: condition.gender.toUpperCase(),
                                monthCountCoveragePeriod: condition.coveragePeriodInMonths,
                                renewable: condition.renewable
                            }
                        });
                    }
                    deferred.resolve(medicalConditions);

                }, function(error) {
                    var baseMessage = services.rbsmbl.conditionList().parseBase(error);

                    if  (baseMessage && baseMessage.returnCode === 404) {
                        // Service returns a 404 when no condition types are found, so just resolve as an empty list.
                        deferred.resolve([]);

                    } else {
                        deferred.reject(error);
                    }
                });

                return deferred.promise;
            };

            getCurrentConditions = function() {
                var deferred = $q.defer();
                var memberInfo = sessionData.memberInfo;

                var uniqueMembers = services.rbsmbl.memberInfo().getUniqueMemberNamesArray(memberInfo);
                if (uniqueMembers && uniqueMembers.length > 0) {
                    var promiseArray = [];

                    for (var i = 0; i < uniqueMembers.length; i++) {
                        promiseArray.push(this.getMemberCurrentConditions(uniqueMembers[i].entireMemberObj.coveredIndividualId));
                    }

                    $q.all(promiseArray).then(function(response) {
                        var allConditions = {};
                        var errorList = [];

                        for (var x = 0; x < response.length; x++) {
                            if (response[x] && response[x].member) {
                                allConditions[response[x].member.pin] = response[x];

                            } else if (response[x] && response[x].isError === true) {
                                errorList.push(response[x].error);
                            }
                        }

                        if (errorList.length > 0) {
                            // If at least one member can't retrieve their conditions, then show an error.
                            // Without the list of conditions, we can't filter the condition list of the add module.
                            deferred.reject(errorList);
                        } else {
                            deferred.resolve(allConditions);
                        }

                    }, function(error) {
                        deferred.reject(error);
                    });
                } else {
                    deferred.resolve( {} );
                }

                return deferred.promise;
            };

            getMemberCurrentConditions = function(coveredIndividualId) {
                var deferred = $q.defer();
                var that = this;
                var params = { coveredindividualid: coveredIndividualId };

                services.rbsmbl.conditionList().fetch(params).then(function(response) {
                    var payload = services.rbsmbl.conditionList().parse(response);

                    if (payload && payload.member) {
                        var memberConditions = {
                            member: payload.member,
                            conditions: []
                        };
                        memberConditions.member.coveredIndividualId = coveredIndividualId;

                        if (memberConditions.member && memberConditions.member.relationship && memberConditions.member.relationship.toLowerCase() === "subscriber") {
                            memberConditions.member.relationship = "Self";
                        }

                        if (payload.conditions) {
                            for (var i = 0; i < payload.conditions.length; i++) {
                                memberConditions.conditions.push(
                                    {
                                        code: payload.conditions[i].conditionCode,
                                        description: payload.conditions[i].conditionCodeDescription,
                                        treatmentIndicator: (payload.conditions[i].treatmentIndicator === "Y"),
                                        effectiveDate: that.formatDate(new Date(payload.conditions[i].effectiveDate)),
                                        cancelDate: that.formatDate(new Date(payload.conditions[i].cancelDate)),
                                        maintenanceDate: that.formatDate(new Date(payload.conditions[i].maintenanceDate)),
                                        status: payload.conditions[i].status
                                    }
                                );
                            }
                        }
                        deferred.resolve(memberConditions);

                    } else {
                        // If response is missing member data...
                        deferred.resolve({ "isError": true, "error": payload });
                    }

                }, function(error) {
                    var baseMessage = services.rbsmbl.conditionList().parseBase(error);

                    if (baseMessage && baseMessage.returnCode === 404) {
                        // Service returns a 404 when no conditions are found for a member, so resolve the promise.
                        deferred.resolve({});
                    } else {
                        deferred.resolve({ "isError": true, "error": error });
                    }
                });

                return deferred.promise;
            };

            refreshMemberConditions = function(coveredIndividualId) {
                var deferred = $q.defer();

                this.getMemberCurrentConditions(coveredIndividualId).then(function(response) {
                    if (response && response.isError === true) {
                        // If the response is a valid error, reject the deferred.
                        deferred.reject(response);
                    } else {
                        deferred.resolve(response);
                    }
                }, function(error) {
                    deferred.reject(error);
                });

                return deferred.promise;
            };
            
            getMemberStatus = function(ciid) {
//            	debugger;
                var deferred = $q.defer();
                var that = this;
                var params = { ciid: ciid };

                services.rbsmbl.memberStatus().fetch(params).then(function(response) {
            		if(response && response.isError === true) {
            			deferred.reject(response);
            		} else {
            			deferred.resolve(response);
            		}
                }, function(error) {
            		deferred.reject(error);
            		
            	});
                return deferred.promise;
            }

            _addOrRenewCondition = function(condition, conditionType, member, isRenew) {
                var deferred = $q.defer();
                var beginFromDate = new Date();

                var conditionToAddOrRenew = {
                    'coveredIndividualId': member.coveredIndividualId,
                    'conditionCode': conditionType.code,
                    'coveragePeriodInMonths': conditionType.monthCountCoveragePeriod,
                    'treatmentIndicator': ((condition.treatmentIndicator === true) ? "Y" : "N")
                };

                if (conditionType.templateName !== "standard") {
                    beginFromDate = new Date(condition.templateSpecificDate);
                }
                beginFromDate.setDate(beginFromDate.getDate() + 1);
                conditionToAddOrRenew.coverageBeginDate = formatDate(beginFromDate, 'YYYY-MM-DD');

                if (isRenew) {
                    var effectiveDate = new Date(condition.effectiveDate);
                    effectiveDate.setDate(effectiveDate.getDate() + 1);
                    conditionToAddOrRenew.effectiveDate = formatDate(effectiveDate, 'YYYY-MM-DD');
                }

                services.rbsmbl.memberCondition().postData(conditionToAddOrRenew, isRenew).then(function(response) {
                    var baseMessage = services.rbsmbl.conditionList().parseBase(response);
                    deferred.resolve(response);

                }, function(error) {
                    deferred.reject(error);
                });

                return deferred;
            };

            addCondition = function(newCondition, newConditionType, member) {
                var deferred = _addOrRenewCondition(newCondition, newConditionType, member, false);
                return deferred.promise;
            };

            renewCondition = function(updates, conditionType, condition) {
                updates.effectiveDate = condition.effectiveDate;
                var deferred = _addOrRenewCondition(updates, conditionType.value, condition.member, true);
                return deferred.promise;
            };

            cancelCondition = function(condition) {
                var deferred = $q.defer();

                var effectiveDate = new Date(condition.effectiveDate);
                effectiveDate.setDate(effectiveDate.getDate() + 1);

                var conditionToCancel = {
                    'conditionCode': condition.code,
                    'effectiveDate': formatDate(effectiveDate, 'YYYY-MM-DD'),
                    'coveredIndividualId': condition.member.coveredIndividualId
                };

                services.rbsmbl.memberCondition().deleteData(conditionToCancel).then(function(response) {
                    var baseMessage = services.rbsmbl.conditionList().parseBase(response);
                    deferred.resolve(response);

                }, function(error) {
                    deferred.reject(error);
                });

                return deferred.promise;
            };

            formatDate = function(date, format) {
                var month = date.getMonth() + 1;
                var day = date.getDate();

                if (month < 10) {
                    month = '0' + month;
                }
                if (day < 10) {
                    day = '0' + day;
                }

                if (format === 'YYYY-MM-DD') {
                    return (date.getFullYear() + '-' + month + '-' + day);
                } else {
                    return (month + '/' + day + '/' + date.getFullYear());
                }
            };

            return {
                getConditionTypes : getConditionTypes,
                addCondition : addCondition,
                getCurrentConditions : getCurrentConditions,
                getMemberCurrentConditions : getMemberCurrentConditions,
                refreshMemberConditions: refreshMemberConditions,
                getMemberStatus: getMemberStatus,
                cancelCondition : cancelCondition,
                renewCondition : renewCondition,
                formatDate : formatDate
            };
        }
    ]);
};