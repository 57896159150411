module.exports = function(app) {
    app.directive('vsbpPlanDir', function() {

        return {
            restrict: 'A',
            replace: true,
            template: require('directives/vsbpPlanDir.html'),
            scope: true,
            controller: function($scope, $element, $attrs, vsbpGetPlans, vsbpLogic, vsbpSetCookie, session, coremetrics, $location, services, sessionData) {
                var thePlans = {};
                var theBrand = session.data.memberInfo.brand;
                $scope.isCancelled =false;
                // we can fail the plan info service with this in the querystring  ?showError2=true
                if($location.search().showError2 == 'true') {
                    theBrand = 'fail';
                }
                $scope.isVBSPExpanded = false;

                $scope.toggleAria = function() {
                    $scope.isVBSPExpanded = !$scope.isVBSPExpanded;
                }
                $scope.theBrand = theBrand;
                $scope.$parent.showPlanInfoServiceFailedMessage = false;
                    vsbpGetPlans.vsbpPlans(theBrand).then(function(planInfo) {
                    // the service sends a nice neat object back if it fails. That obj has the following properties
                    if ((!planInfo) || (planInfo.data && planInfo.data.returnCode && planInfo.data.returnCode != 200)) {
                        $scope.$parent.showPlanInfoServiceFailedMessage = true;
                        console.log('$scope.showPlanInfoServiceFailedMessage = ' + $scope.showPlanInfoServiceFailedMessage);
                        return;
                    }

                    thePlans.futurePlans = planInfo.plans.future;
                    thePlans.currentPlans = planInfo.plans.current;
                    thePlans.pastPlans = planInfo.plans.past;
                    // the next value is sent here from the element that is calling the directive
                    //              - the element where the directive is applied
                    var timeFrame = $attrs.thetimeframe;
                    var arrPlansInThisTimeFrame;

                    switch(timeFrame) {
                        case "futurePlans":
                            arrPlansInThisTimeFrame = thePlans.futurePlans;
                            $scope.showFuturePlans = (thePlans.futurePlans.length > 0) ? true : false;
                            break;
                        case "currentPlans":
                            arrPlansInThisTimeFrame = thePlans.currentPlans;
                            $scope.showCurrentPlans = (thePlans.currentPlans.length > 0) ? true : false;
                            break;
                        case "pastPlans":
                            arrPlansInThisTimeFrame = thePlans.pastPlans;
                            $scope.showPastPlans = (thePlans.pastPlans.length > 0) ? true : false;
                            break;
                        default:
                            throw new Error('The VSBP Plan directive could not tell what time frame to use.')
                            break;
                    }
                    var theMembers = planInfo.members.all;
                    var loggedInMemberUplId = '';
                    if (planInfo.members.loggedIn && planInfo.members.loggedIn.coverageMap && planInfo.members.loggedIn.coverageMap[0]
                        && planInfo.members.loggedIn.coverageMap[0].uplid) {
                            loggedInMemberUplId = planInfo.members.loggedIn.coverageMap[0].uplid;
                    }
                    // var selectedMembers = vsbpLogic.MakePlanMembersArray(theMembers, arrPlansInThisTimeFrame, loggedInMemberUplId)

                    // We iterate through the plans in the timeframe to make any needed edits
                    for (var i = 0; i < arrPlansInThisTimeFrame.length; i++) {
                        var selectedMembers = vsbpLogic.MakePlanMembersArray(theMembers, arrPlansInThisTimeFrame[i], loggedInMemberUplId)
                        arrPlansInThisTimeFrame[i].theMembers = selectedMembers;
                        arrPlansInThisTimeFrame[i].timeFrame = timeFrame;
                        arrPlansInThisTimeFrame[i].planName.display.vsbp = arrPlansInThisTimeFrame[i].planName.display.vsbp;
                        // use idCardEOBGroupNumber if it exists
                        if (arrPlansInThisTimeFrame[i].idCardEOBGroupNumber && arrPlansInThisTimeFrame[i].idCardEOBGroupNumber.length &&
                            arrPlansInThisTimeFrame[i].idCardEOBGroupNumber.length > 0) {
                            arrPlansInThisTimeFrame[i].displayGroupNumber = arrPlansInThisTimeFrame[i].idCardEOBGroupNumber;
                        } else {
                            arrPlansInThisTimeFrame[i].displayGroupNumber = arrPlansInThisTimeFrame[i].groupNumber;
                        }
                        //show cancelled date if exists
                        if(arrPlansInThisTimeFrame[i].memberCancellationDate && arrPlansInThisTimeFrame[i].memberCancellationDate !==''){
                            arrPlansInThisTimeFrame[i].isCancelled = true;
                        }
                        else{
                            arrPlansInThisTimeFrame[i].isCancelled = false;
                        }
                        // employer/clientName needs to be edited and have to be made lowercase - Accessibility Defect
                        arrPlansInThisTimeFrame[i].clientName = arrPlansInThisTimeFrame[i].clientName.toLowerCase();
                        
                    }
                    $scope.plansInThisTimeFrame = arrPlansInThisTimeFrame;

                }, function(err) {
                        // this is the on Error function for the planInfo service call
                        $scope.$parent.showPlanInfoServiceFailedMessage = true;
                    } );
                $scope.funcs = {};
                var fireCoremetricsElemTag = function(timeFrame) {
                    switch(timeFrame) {
                        case "futurePlans":
                            coremetrics.elementTag('VSBP FUTURE VIEW PLAN', 'MEMBER:VSBP');
                            break;
                        case "currentPlans":
                            coremetrics.elementTag('VSBP CURRENT VIEW PLAN', 'MEMBER:VSBP');
                            break;
                        case "pastPlans":
                            coremetrics.elementTag('VSBP PAST VIEW PLAN', 'MEMBER:VSBP');
                            break;
                        default:
                            throw new Error('The coremetrics element tag method could not tell what time frame to use.')
                            break;
                    }
                };
                
                $scope.funcs.getMemberId = function (groupNumber) {
                    this.groupNumber = groupNumber;
                    this.memberInfo = session.data.memberInfo;
                    this.memberId = null;

                    angular.forEach(this.memberInfo.clientList, function (client) {
                        this.client = client;

                        angular.forEach(client.groupList, function (group) {
                            if (group.groupNumber === this.groupNumber) {
                                return this.memberId = group.clientAssignedId ? group.clientAssignedId : this.memberInfo.umi;
                            }
                        }, this);

                        if (this.memberId) {
                            return;
                        }
                    }, this);

                    return this.memberId || this.memberInfo.umi;
                };

                $scope.funcs.changePlanCookie = function(cn, gn, timeFrame) {
                    fireCoremetricsElemTag(timeFrame);
                    var theParams = {
                        clientNo: cn,  // '099918',
                        groupNo: gn,  // '08149300',
                        brand: $scope.theBrand
                    }
                    // the next method is in file = local-services/vsbpServices.js
                    var theCookie = vsbpSetCookie.booCookieIsSet(theParams).then(function(newPlanCookieIsSet) {
                        // examine the response
                        if($location.search().showError == 'true') {
                            newPlanCookieIsSet = false;
                        }
                        if (newPlanCookieIsSet) {
        services.rbsmbl.vsbpPlans().flushCache();
                            $scope.showServiceFailedMessage = false;
                            if($scope.theBrand === "MINCR"){
                                $scope.redirectURL = "/member/home";
                            }
                            var theTarget = window.location.origin + $scope.redirectURL; //'/mbr/#!/landing'
                            window.open(theTarget, '_self');
                            // alert('The group cookie is set.')
                        } else {
                            $scope.showServiceFailedMessage = true;
                        }
                    }, function(err) {
                            $scope.showServiceFailedMessage = true;

                        }
                    );
                }
            }
        };
    });
}