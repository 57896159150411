module.exports = function (app) {
    app.directive('textLink',
       function ($window, $location, $http, $compile) {
           return {
               restrict: 'AE',
               replace: true,
               link: function (scope, element, attrs) {
                   var linkParams={};
                   linkParams.projectTypeID=attrs.projecttypeid;
                   linkParams.productID=attrs.productid?attrs.productid:scope.productID;
                   linkParams.genContentID=attrs.gencontentid;
                   linkParams.linked=true;
                   var href="#/wellness/contentDetail?";
                   for (var key in linkParams) {
                       if (linkParams[key]) {
                           href = href.concat(key + "=" + linkParams[key] + "&");
                       }
                   }
                   var elementValid=element[0] || element[0].innerHTML || (element[0].innerHTML.length !== 0);
                   removeQuickTimeLink();
                   removeShockWaveLink();
                   processPregnancyTVLink();
                   processExtLink();
                   processNormalLink();

                   function removeQuickTimeLink(){
                       if(elementValid && attrs.extlink === 'http://www.apple.com/quicktime/download'){
                           element.html("");
                           angular.element(element.context.parentElement).css("display","none");
                       }
                   }

                   function removeShockWaveLink(){
                       if(elementValid && attrs.extlink === 'http://www.macromedia.com/shockwave/download'){
                           element.html("");
                           angular.element(element.context.parentElement).css("display","none");
                       }
                   }

                   function processPregnancyTVLink(){
                       if(elementValid && attrs.linktype!=='ext' && scope.genContentID == '000078' &&scope.projectTypeID == '14'){//pregnancy tv
                           element[0].href=href;
                           angular.element(element[0]).addClass("linkBlock");
                       }
                       else if(elementValid && attrs.linktype!=='ext' && attrs.gencontentid == '000104' && attrs.projecttypeid == '14'){
                           var liElement=angular.element(element.context.parentElement);
                           liElement.css("display","none");
                           element.html("");
                       }
                   }

                   function processExtLink(){
                       if(elementValid && attrs.linktype==='ext'){
                           element[0].href=attrs.extlink;
                           element[0].target="_blank";
                       }
                   }

                   function processNormalLink(){
                       if(elementValid && attrs.linktype==='int'){
                           element[0].href=href;
                           processVideoLink();
                           processReadLink();
                       }
                   }

                   function processVideoLink(){
                       if(element[0].innerHTML.indexOf("Watch") === 0){
                           element.html("<span class='hm-icon icon-video adam-icon-video'></span><span class='videoText'>"+element[0].innerHTML+"</span>");
                       }
                   }

                   function processReadLink(){

                   }
               }
           };
       }
    );
};