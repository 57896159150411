module.exports = function(app) {
    app.directive('prescriptsChipMinutes', function() {

        return {
            restrict: 'A',
            replace: true,
            template: require('directives/prescriptsChipMinutes.html'),

            // scope: {},

            controller: ['$scope',
                function($scope) {

                }
            ]
        };
    });
}