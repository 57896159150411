module.exports = function (app) {
    app.service('prescriptionLogic', ['$q', 'services',
        function ($q, services) {

            this.userSeesAct207Module = function(region){
                //   ['hmbcbs', 'hbcbs', 'highmarkbcbs'];
                switch (region) {
                    case 'hmbcbs':
                    case 'hbcbs':
                    case 'highmarkbcbs':
                    case 'hbs':
                    case 'pbs':
                    case 'hmbs':
                        return true;
                        break;
                    default:
                        return false;
                        break;
                }
            };

            this.regionIsMn = function(region){
                switch (region) {
                    case 'mincr':
                    case 'mn':
                        return true;
                        break;
                    default:
                        return false;
                        break;
                }

            };

            this.submitStepTherapyRequest = function(stepTherapyRequest) {
                // Populate service request payload with form data.
                var payload = {
                    'memberName': stepTherapyRequest.selectedMember.fullName,
                    'memberId': stepTherapyRequest.memberID,
                    'memberDob': stepTherapyRequest.selectedMember.birthDate,
                    'memberPhoneNo': stepTherapyRequest.phoneNumber,
                    'prescriberName': stepTherapyRequest.prescriberName,
                    'prescriberPhoneNo': stepTherapyRequest.prescriberPhoneNumber,
                    'drugName': stepTherapyRequest.drugName,
                    'strength': stepTherapyRequest.drugStrength,
                    'quantity': stepTherapyRequest.drugQuantity,
                    'reasonForRequest': stepTherapyRequest.requestExplanation,
                };

                return services.rbsmbl.medicationException({ type: 'steptherapy' }).postData(payload);
            };

            this.submitMedicationExceptionRequest = function(medExceptionRequest) {
                // Populate service request payload with form data.
                var payload = {
                    'memberName':medExceptionRequest.selectedMember.fullName,
                    'memberId': medExceptionRequest.memberID ,
                    'memberPhoneNo': medExceptionRequest.phoneNumber,
                    'prescriberName': medExceptionRequest.prescriberName,
                    'prescriberPhoneNo': medExceptionRequest.prescriberPhoneNumber,
                    'prescriberFaxNo': medExceptionRequest.faxNumber,
                    'addr1': medExceptionRequest.address1,
                    'addr2': medExceptionRequest.address2,
                    'city': medExceptionRequest.city,
                    'state': medExceptionRequest.state.abbreviation,
                    'memberDob': medExceptionRequest.selectedMember.birthDate,
                    'zipCode': medExceptionRequest.zipcode,
                    'medicationName': medExceptionRequest.medicationName,
                    'reasonForMedication': medExceptionRequest.requestExplanation
                };
                return services.rbsmbl.medicationException({ type: 'medicationexception' }).postData(payload);
            };
        }
    ]);
}