module.exports = function (app) {
    app.controller("bdcController", ['$scope', 'sessionData', 'modal', '$window', 'services', '$filter', '$state', 'brandConfig', function ($scope, sessionData, modal, $window, services, $filter, $state, brandConfig) {
        var disclaimerModal = require('./json/bdcDisclaimer.json');
        disclaimerModal.classes = 'width-80';
        modal.open(disclaimerModal);

        $scope.content = require('./json/bdcContent.json');
        $scope.specialtyContent = require('./json/bdSpecialtiesContent.json');
        $scope.benefitsOverview = {};

        var currentYear = new Date().getFullYear();
        // Get Benefit Grid
        services.rbsbdc.memberService().fetch({
            userName: sessionData.userName,
            group: sessionData.groupNumber,
            targetSite: 'CHMBDC'
        }).then(function (res) {
            //            var benefitGridData = results[0][1],
            $scope.member = res.data.payload;
        });
        services.rbsbdc.getBooklet().fetch({userName: sessionData.userName}).then(function (res) {
            $scope.benefitBooklets = res.data.payload;
            //                benefitBookData = results[1][1],
        });
        services.rbsbdc.getVideos().fetch({userName: sessionData.userName}).then(function (res) {
            $scope.videos = res.data.payload;
            //                coverageVideosData = results[2][1],
        });

        services.rbsbdc.getSpecialties().fetch({
            userName: sessionData.userName,
            group: sessionData.groupNumber
        }).then(function (res) {
            $scope.specialties = res.data.payload.designations[0].specialties;

            //            buildBdSpecialties(bdSpecialtiesData);
        });

        services.rbsbdc.getBenefitGrid().fetch({
            userName: sessionData.userName
        }).then(function (res) {
            //            var benefitGridData = results[0][1],
            $scope.benefitGrid = res.data.payload;
        });

        $scope.dateFormattedBDC = function (dateString) {
            if (dateString === '00-00-0000') return '*';
            return $filter('date')(new Date(dateString), 'MMM d, yyyy');
        };


        $scope.openSpecialtiesModal = function (specialty) {

            modal.open({
                template: '<h3>{{special}}</h3> <hr class="thick"><p>{{specialtyContent.header}}</p><ul class="list-bullets"><li data-ng-repeat="specialty in specialtyContent.servicesList">{{specialty}}</li></ul><p>{{specialtyContent.footer}}</p>',
                controller: ["$scope", function ($scope) {
                    var content = require('./json/bdSpecialtiesContent.json');
                    $scope.special = specialty;
                    $scope.specialtyContent = content[specialty];
                }]
            });
        };

        $scope.bytesToSize = function (bytes, precision) {
            var kilobyte = 1024,
                megabyte = kilobyte * 1024,
                gigabyte = megabyte * 1024,
                terabyte = gigabyte * 1024;

            if ((bytes >= 0) && (bytes < kilobyte)) {
                return bytes + ' B';
            }

            if ((bytes >= kilobyte) && (bytes < megabyte)) {
                return (bytes / kilobyte).toFixed(precision) + ' KB';
            }

            if ((bytes >= megabyte) && (bytes < gigabyte)) {
                return (bytes / megabyte).toFixed(precision) + ' MB';
            }

            if ((bytes >= gigabyte) && (bytes < terabyte)) {
                return (bytes / gigabyte).toFixed(precision) + ' GB';
            }

            if (bytes >= terabyte) {
                return (bytes / terabyte).toFixed(precision) + ' TB';
            }

            return bytes + ' B';
        };

        $scope.findADoctorLink = function () {

            var brand = brandConfig.getBrandFromDomain();
            switch (brand.ubk) {
                case 6010:
                    window.location = '/find-a-doctor/home';
                    break;
                default:
                    $state.go('finder');
            }
        };
        //END OF CONTROLLER
    }]);
};
