module.exports = function(app) {
    app.directive('prescriptsDrugList', function() {

        return {
            restrict: 'A',
            replace: true,
            template: require('directives/prescriptsDrugList.html'),

            // scope: {},

            controller: ['$scope', 'displayOrHideItemsWrapper', 'session',
                function($scope, displayOrHideItemsWrapper, session) {
                    $scope.uiModules.drugList = {};
                    var o = $scope.directiveOptions;
                    var myIsMn = (o && o.theBrand && (o.theBrand == 'mincr' )) ? true : false;
                    var displayOrHideItemsResponse = displayOrHideItemsWrapper.displayOrHideItemsCall(o.glbClientNumber, o.glbGroupNumber, ['drugFormularyDisplay'], o.theBrand);
                    displayOrHideItemsResponse.then(function (servicePayload) {
                        var showDrugFormulary = (servicePayload.qualifierInfo.drugFormularyDisplay == 'true') ? true : false;
                        console.log(showDrugFormulary);
                        // var showDrugFormulary = false;
                        $scope.uiModules.drugList =          {
                            showThisMod: showDrugFormulary,
                            isMn: myIsMn
                        }
                    });

                }
            ]
        };
    });
}