module.exports = function(app) {
    var securityUpdatesModal = require("../../modals/securityUpdatesModal");
    app.controller('pages.customLanding.memberLogin', ["$state", "$scope", "$location", "services", "teamsite", "navigation", "brand", "brandConfig", "coremetrics", "modal", "storage", "session", "storageOld",
        function($state, $scope, $location, services, teamsite, navigation, brand, brandConfig, coremetrics, modal, storage, session, storageOld) {
            $scope.username = "";
            $scope.password = "";
            $scope.loginErrorTitle = null;
            $scope.loginErrorText = null;

            $scope.guideWellRedirect = $location.search().returnTo;
            $scope.webmdNDRedirect = $location.search().returnTo;

            $scope.theDomain = function() {
                    var theDomain = 'https://' + window.location.host;
                    return theDomain;
                }


            var origPrePasswordMemberFooter = {};
            var origPrePasswordMemberHeader = {};
            removeNavigation();

            $scope.goToRegistration = function () {
                storageOld.set('customLanding', 'true');
                window.location = '/login/#/register';
            };

            $scope.login = function () {

                coremetrics.startConversion('Custom Landing Page Login','Login');
                $scope.isDeepLinkIdCard = window.location.hash.search('idCard') > 1;
                $scope.msgCenterDeepLink = window.location.hash.search('msgCenter') > 1;
                $scope.isBcbsNdPortal = window.location.host.split('.')[1] === 'bcbsndportals';

                $scope.loginErrorTitle = null;
                $scope.loginErrorText = null;
                if ($scope.memberLoginForm.$valid) {
                    $scope.loginDisable = true;
                    services.rbsmbl.loginversion().fetch($scope.username.toUpperCase()).then(function (response) {
                        var payload = ((response && response.data && response.data.payload) ? response.data.payload : ((response) ? response.payload : null));

                        if (payload.convertCase) {
                            $scope.password = $scope.password.toUpperCase();
                        }
                        services.rbsmbl.login().fetch($scope.username.toUpperCase(), $scope.password).then(function (response) {
                            restoreNavigation();
                            coremetrics.finishConversion('Custom Landing Page Login','Login');

                            var payload = ((response && response.data && response.data.payload) ? response.data.payload : ((response) ? response.payload : null));
                            if (payload && payload.loginInfo && payload.loginInfo.isTempPassword && payload.loginInfo.isTempPassword === 'true') {
                                location.replace("/login/#/change-password");
                            } else if (payload && payload.loginInfo.securityStrengthEnforcement === 'FORCED') {
                                storage.set("forcedPassword", true);
                                modal.open({
                                    template: require('modals/securityUpdatesModal.html'),
                                    onClose: function() {
                                        window.location = "/login/#/change-password";
                                    }
                                });
                            } else {
                               /* code commented for Medicaid project
                               if(data.payload.loginInfo.brand == "9014"){
                                    location.replace("/medicaid/mbr/#!/landing");

                                }else {}*/
                                 if ($scope.guideWellRedirect === '/emssvc/x-services/secure/sso/outbound/auto/v1/vendor/GUIDEWELLMEMBER'){
                                    //redirect to Guidewell
                                    location.href =  $scope.theDomain() + $scope.guideWellRedirect;
                                 } else if ($scope.webmdNDRedirect === '/emssvc/x-services/secure/sso/outbound/auto/v1/vendor/WEBMDND?StartID=87') {
                                    //redirect to WebMD
                                    location.href =  $scope.theDomain() + $scope.webmdNDRedirect;
                                 } else {
                                     if($scope.isBcbsNdPortal && $scope.isDeepLinkIdCard) {
                                         location.href =  $scope.theDomain() + "/mbr/#!/account/view-id-card?c=1-0-0-0"
                                     } else if($scope.isBcbsNdPortal && $scope.msgCenterDeepLink) {
                                         location.href =  $scope.theDomain() + "/msg/"
                                     } else {
                                         //redirect to Member Landing Page
                                         location.href = "/mbr/#!/landing";
                                     }
                                 }
                            }
                        }, function (error) {
                            $scope.loginDisable = false;
                            handleErrorResponse(error);
                        });
                    }, function (error) {
                        $scope.loginDisable = false;
                        handleErrorResponse(error);
                    });
                }
            };

            $scope.forgotPassword = function () {
                coremetrics.elementTag('FORGOT: USERNAME OR PASSWORD', 'FORGOT');
                restoreNavigation();
            };

            function removeNavigation() {
                brand.current.socialUpper = [];
                origPrePasswordMemberFooter = angular.copy(navigation.prePasswordMemberFooter);
                for (var x in navigation.prePasswordMemberFooter) {
                    if (navigation.prePasswordMemberFooter.hasOwnProperty(x)) {
                        navigation.prePasswordMemberFooter[x] = {};
                    }
                }
                origPrePasswordMemberHeader = angular.copy(navigation.prePasswordMemberHeader);
                for (var y in navigation.prePasswordMemberHeader) {
                    if (navigation.prePasswordMemberHeader.hasOwnProperty(y)) {
                        navigation.prePasswordMemberHeader[y] = {};
                    }
                }
                navigation.update();
            }

            function restoreNavigation() {
                navigation.prePasswordMemberFooter = origPrePasswordMemberFooter;
                navigation.prePasswordMemberHeader = origPrePasswordMemberHeader;
                brandConfig.update();
                navigation.update();
            }

            function handleErrorResponse(error) {
                var specificErrorContent;

                if (error && error.data) {
                    var response = error.data;
                    var errorMessage = response.errors ? response.errors[0].errorMessage : response.baseMessage.returnCodeDescription;

                    // Handle mapping of service error to error messages
                    if (errorMessage === 'GEN-SUSPEND') {
                        var appConfig = (session.data && session.data.appConfig && session.data.appConfig.FORGOT) ? session.data.appConfig.FORGOT : {};
                        var isAccountRecoveryActive = (appConfig && appConfig.ACCOUNT_RECOVERY && appConfig.ACCOUNT_RECOVERY.booleanValue === true) ? true : false;
                        specificErrorContent = (isAccountRecoveryActive ? "errorContent.accountLockoutRecovery" : "errorContent.accountLockout");

                    } else if (errorMessage === 'GEN-NOACCESS') {
                        var errorSpecifics = response.baseMessage.additionalInfo[0].value;

                        if (errorSpecifics === 'NO_ACCESSIBLE_COVERAGE_EXCEPTION') {
                            specificErrorContent = "errorContent.cancelledCoverageOverThreeSixFive";

                        } else if (errorSpecifics === 'OVER_CHIP14') {
                            specificErrorContent = "errorContent.chipOverFourteen";

                        } else if (errorSpecifics === 'CHIP14_GUARDIAN_VERIFICATION_FAIL') {
                            specificErrorContent = "errorContent.chipUnderFourteen";

                        } else {
                            specificErrorContent = "errorContent.genError";
                        }
                    } else if (errorMessage === 'GEN-CONFCOMM') {
                        specificErrorContent = "errorContent.confidentialCommunication";

                    } else if (errorMessage === 'GEN-NOMATCH' ||
                            errorMessage === 'GEN-NORECCHMECS' ||
                            errorMessage === 'USER-NOT-ON-FILE') {
                        specificErrorContent = "errorContent.noMatch";

                    } else if (errorMessage === 'ACC-ECS') {
                        specificErrorContent = "errorContent.accECS";

                    } else if (errorMessage === 'ACC-ICIS') {
                        specificErrorContent = "errorContent.accICIS";

                    } else if (errorMessage === 'ACC-CRMPP') {
                        specificErrorContent = "errorContent.accCRMPP";

                    } else if (errorMessage === 'INVALID-LOGIN-BRANDCONFLICT') {
                        specificErrorContent = "errorContent.brandingRedirectError";

                    } else if (errorMessage === 'ERROR_BAD_REQUEST') {
                        specificErrorContent = "errorContent.csrBadRequest";

                    } else if (errorMessage === 'UNAUTHORIZED_SIMULATION_ACCESS') {
                        specificErrorContent = "errorContent.csrUnauthAccess";

                    } else if (errorMessage === 'NO_BRAND_ACCESS') {
                        specificErrorContent = "errorContent.csrBrandAccess";

                    } else if (errorMessage === 'NO_INTRACOMPANY_ACCESS') {
                        specificErrorContent = "errorContent.csrIntraAccess";

                    } else if (errorMessage === 'VALID-LOGIN-BRANDCONFLICT') {
                        specificErrorContent = "errorContent.csrBrandRedirect";

                    } else {
                        specificErrorContent = "errorContent.genError";
                    }
                } else {
                    specificErrorContent = "errorContent.genError";
                }
                $scope.loginErrorTitle = "<span class='hm-icon icon-alert' aria-hidden='true'></span><span data-teamsite='MBR_LOGIN_ERROR_MESSAGES as errorContent'>{{" + specificErrorContent + ".blockTitle}}</span>";
                $scope.loginErrorText = "<span data-teamsite='MBR_LOGIN_ERROR_MESSAGES as errorContent' data-compile='"  + specificErrorContent + ".blockText'></span>";
            }

        }
    ]);
};