module.exports = function(app) {
    app.directive('claimStatus', ['$filter', 'claimService', function($filter, claimService) {

        return {
            restrict: 'A',
            replace: true,

            scope: {
                statusCode: '=',
                claimType: '=',
                providerName: '='
            },

            template:   '<ul class="claim-icons">' +
                            '<li class="approval-icon"><span class="hm-icon {{status.icon}}"></span><span>{{status.description}}</span></li>' +
                            '<li><span class="hm-icon {{planIcon}} status-plan-icon"></span><span>{{providerName}}</span></li>' +
                        '</ul>',

            controller: ['$scope', function($scope) {

                var getPlanIcon = function(claimType) {
                    claimType = claimType || {};

                    if (claimType.isDental) {
                        return 'icon-dental';
                    }
                    else if (claimType.isVision) {
                        return 'icon-vision';
                    }
                    else if (claimType.isDrug) {
                        return 'icon-pharmacy';
                    }
                    else if (claimType.isMedical) {
                        return 'icon-provider';
                    }
                    else {
                        return '';
                    }
                };

                var getProviderName = function(providerName) {
                    return providerName
                        ? $filter('capitalize')(providerName)
                        : 'Provider Name Unavailable';
                };

                var getStatus = claimService.getClaimStatus;

                $scope.$watch(
                    function(scope) {
                        return scope.claimType;
                    },
                    function(newVal) {
                        $scope.planIcon = getPlanIcon(newVal);
                    }
                );

                $scope.$watch(
                    function(scope) {
                        return scope.providerName;
                    },
                    function(newVal) {
                        $scope.providerName = getProviderName(newVal);
                    }
                );

                $scope.$watch(
                    function(scope) {
                        return scope.statusCode;
                    },
                    function(newVal) {
                        $scope.status = getStatus(newVal, $scope);
                    }
                );
            }]
        };
    }]);
}