module.exports = function(app) {
    app.directive('splashEspot', ['$compile', function($compile) {
        var createTemplate = function(scope) {
            scope.target = scope.externalTarget() == true ? "_blank" : "_self";
			scope.url = scope.url ? scope.url.replace("[EXTERNAL]","") : null;
			scope.url = scope.url ? scope.url.replace(/^\/#!/i,"/home/#!") : null;
            scope.url = scope.hero ?  scope.urlWithCoremetrics(scope.url, "Hero", 1) : scope.url;
            var tagStart = scope.url ? '<a ' : '<div ';
            var tagEnd = scope.url ? '</a>' : '</div>';
            var $template = $(tagStart + 'data-ng-href="{{url}}" style="font-size: 0;" title="{{title}}" data-ng-click="doCoremetrics()" target="{{target}}"><img src="{{src}}" alt="{{alt}}">' + tagEnd);
            $compile($template)(scope);
            return $template;
        };

        return {
            restrict: 'A',
            replace: false,
            scope: {
                espot: '=splashEspot',
                hero: '@?'
            },

            link: function(scope, elem) {
                elem.html(createTemplate(scope));
                $compile(elem.contents())(scope);
            },

            controller: ['$scope', '$window', '$compile', 'brand', 'coremetrics', function($scope, $window, $compile, brand, coremetrics) {
                $scope.alt = $scope.espot.eSpotContent;
                var parser = new DOMParser;
                var dom = parser.parseFromString(
                    '<!doctype html><body>' + $scope.espot.eSpotContent,
                    'text/html');
                $scope.title = dom.body.textContent;
                $scope.urlWithCoremetrics = function(url, espotType, espotNumber) {
                    var newUrl = '';
                    var realEstateVersionList = [
                        {
                            brand: 'hmbcbs',
                            version: 'Highmarkbcbs',
                            pageArea: 'Bottom E-spot Row'
                        },
                        {
                            brand: 'hbs',
                            version: 'Highmarkblueshield',
                            pageArea: 'Bottom E-spot Row'
                        },
                        {
                            brand: 'hmbcbswv',
                            version: 'Highmarkbcbswv',
                            pageArea: 'Bottom E-spot Row'
                        },
                        {
                            brand: 'hmbcbsde',
                            version: 'Highmarkbcbsde',
                            pageArea: 'Bottom E-spot Row'
                        },
                        {
                            brand: 'pp',
                            version: 'mybenefitshome',
                            pageArea: 'Bottom E-spot Row'
                        }
                    ];
                    var infoToUse = realEstateVersionList.filter(function(item){
                        if (item.brand == brand.current.sharedBrandCode) {
                            return true;
                        }
                        else return false
                    })
                    if(url != null && infoToUse.length > 0){
                        var separator = url.indexOf('?') > -1 ? '&' : '?';
                        newUrl = url+separator+"cm_re="+infoToUse[0].version+"-_-"+infoToUse[0].pageArea+"-_-"+espotType+"-"+espotNumber;
                    }
                    else {
                        newUrl = url
                    }
                    return newUrl
                }
                $scope.$parent.$parent.$parent.espots.forEach(function(espot, i) {
                    if (espot && espot.url && espot.url.indexOf("Espot") == -1) {
                        espot.url = $scope.urlWithCoremetrics(espot.url, "Espot", i + 1)
                    }
                });
                $scope.url = $scope.espot.url;
                $scope.externalTarget = function() {
                    if ($scope.url && $scope.url.indexOf("EXTERNAL") >= 0) {
                        return true
                    }
                }
                $scope.doCoremetrics = function() {
                    if ($scope.espot && $scope.espot.url) {
                        coremetrics.createManualLinkTag($scope.espot.url, "", 'HOME PAGE');
                    }
                }

                if ($scope.hero) {
                    var getHeroImage = function(espot, viewSize) {
                        if (espot.image) {
                            return espot.image; // mybenefitshome only returns this one image on the service call.
                        } else {
                            if (viewSize < 628) {
                                return espot.imageMobile;
                            }
                            else if (viewSize < 936) {
                                return espot.imageTablet;
                            }
                            //else if (viewSize < 1244) {
                            //    return espot.imageNotebook;
                            //}
                            //else {
                            //    return espot.imageDesktop;
                            //}
                            // If we are using the 604px center content area...
                            else {
                                return espot.imageNotebook;
                            }
                        }
                    };

                    var setHeroImage = function () {
                      $scope.src = '/' + getHeroImage($scope.espot, $window.innerWidth || $($window).width());
                    };

                    setHeroImage();

                    $($window).resize(function () {
                        setHeroImage();
                    });
                }
                else {
                    if ($scope.espot.image.charAt() == '/')  {
                        $scope.src = $scope.espot.image;
                    }
                    else {
                        $scope.src = '/' + $scope.espot.image;
                    }
                }
            }]
        };
    }]);
};
