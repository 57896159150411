module.exports = function(bootstrap) {
    bootstrap.run(['Service', function(Service) {
        new Service(
            {
                name: 'planProgress',
                group: 'rbsmbl',
                url: '/rbsmbl/x-services/plan/v2/progress',

                fetch: function() {
                    return this.get();
                }
            }
        );
    }]);
};