module.exports = function(app) {
    app.directive('prescriptsMailOrder', function() {

        return {
            restrict: 'A',
            replace: true,
            template: require('directives/prescriptsMailOrder.html'),

            // scope: {},

            controller: ['$scope','brand',
                function($scope,brand) {
                    var o = $scope.directiveOptions;
                    $scope.uiModules.mailOrder =
                    {
                        theTitle: 'MBR_PRESCRIPTION_MAILORDER_HEADLINE',  // Save With Mail Order
                        theText: "MBR_PRESCRIPTION_MAILORDER_TEXT",
                        isMailOrderText: true,
                        subItems:  [
                            {
                                textAboveClickable: 'MBR_PRESCRIPTION_MAILORDER_TRANSFER_TEXT',
                                showTextAboveClickable: true,
                                leftClickable: 'MBR_PRESCRIPTION_MAILORDER_SAVINGS_LINKTEXT',
                                PRESCRIPTION_TRANSFERS: true,
                                useFunction: true
                            },
                            {
                                textAboveClickable: 'MBR_PRESCRIPTION_MAILORDER_NEWPRESCRIP_TEXT',
                                showTextAboveClickable: true,
                                leftClickable: 'MBR_PRESCRIPTION_MAILORDER_HELPCENTER_LINKTEXT',
                                PRESCRIPTION_MAIL_ORDER_FORMS: true,
                                useFunction: true
                            }
                        ],
                        hasSecondLinkText: true,
                        secondText: 'MBR_PRESCRIPTION_MAILORDER_NEWPRESCRIP_TEXT',  //  Start a new prescription
                        secondLinkText: 'MBR_PRESCRIPTION_MAILORDER_HELPCENTER_LINKTEXT',   // How It Works
                        hasBlueBackground: true,
                        showThisMod: (o.userHasMedco || o.userHasPrime) && (o.theBrand !== 'wybcbs') && (o.glbClientNumber !== '098024'),
                        coreMetricsTag: 'mailOrderSavings'
                    }

                }
            ]
        };
    });
}