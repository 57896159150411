
module.exports = function(app) {
    app.directive('oralHealthAddRenewCondition', ["modal", function(modal) {

        return {
            restrict: 'A',
            replace: true,
            require: ['^form'],
            template: require('directives/oralHealthAddRenewCondition.html'),

            scope: {
                selectedTemplate: '=',  // Type of template to show: standard, pregnancy, treatment, or transplant
                conditionObject: '=',   // Condition object
                validationObject: '=',  // Validation Content
                conditionType: '=',     // Type of Condition used for Date Logic.
                isCancel: '=',          // If cancel is true, None of the template specific content is shown.
                uniqueId: '=?'          // Unique identifier for form input "id" attributes.
            },

            link: function(scope, element, attrs, ctrls) {
                scope.myForm = ctrls[0];

            },

            controller: ['$scope', 'medicalDentalService',
                function($scope, medicalDentalService) {
                    var oneDay = 24*60*60*1000;
                    var today = new Date();

                    var calculateDiffDays = function(monthCount) {
                        var diffDate = new Date();
                        diffDate.setMonth(diffDate.getMonth() - monthCount);
                        var diffDays = Math.round(Math.abs((today.getTime() - diffDate.getTime())/(oneDay)));
                        return diffDays - 1;
                    };

                    $scope.$watch(function() {
                        return $scope.conditionType;

                    }, function(newValue, oldValue) {
                        if (newValue && newValue.templateName !== null && newValue.templateName !== undefined) {
                            $scope.maxDays = 0;
                            $scope.minDays = 0;

                            if ($scope.conditionType.templateName === 'pregnancy') {
                                $scope.maxDays = calculateDiffDays($scope.conditionType.monthCountCoveragePeriod);
                            } else {
                                $scope.minDays = calculateDiffDays($scope.conditionType.monthCountCoveragePeriod);
                            }

                            $scope.dateRangeMax = new Date(today.getTime() + $scope.maxDays * oneDay);
                            $scope.dateRangeMax = medicalDentalService.formatDate($scope.dateRangeMax, 'YYYY-MM-DD');

                            $scope.dateRangeMin = new Date(today.getTime() - $scope.minDays * oneDay);
                            $scope.dateRangeMin = medicalDentalService.formatDate($scope.dateRangeMin, 'YYYY-MM-DD');
                        }
                    });

                    $scope.openCalendar = function($event) {
                        $event.preventDefault();

                        modal.open({
                            heading: 'SELECT A DATE',
                            classes: "oralHealthCalendarModal",
                            scope: $scope,
                            controller: ["$scope", function ($scope) {
                                $scope.calendarDate = $scope.conditionObject.templateSpecificDate;

                                $scope.save = function () {
                                    $scope.conditionObject.templateSpecificDate = $scope.calendarDate;
                                    $scope.closeModal();
                                };
                            }],
                            template:   '<div class="oralHealthDateCalendar">' +
                                            '<div data-calendar="calendarDate" data-date-max="{{maxDays}}" data-date-min="{{minDays}}"></div>' +
                                        '</div>' +
                                        '<div class="margin-top text-right">' +
                                            '<button data-ng-click="closeModal()" class="secondary">CANCEL</button> <button data-ng-click="save()" class="primary">OK</button>' +
                                        '</div>'
                        });
                };
            }]

        };
    }]);
}