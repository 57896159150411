module.exports = function(app) {
    app.controller('pages.claims.activitySummary', ['$scope', '$cookies', 'claimService', 'services', 'coremetrics', 'updateVisited',
        function($scope, $cookies, claimService, services, coremetrics, updateVisited) {
            $scope.headings = 'Recent Claims';
            $scope.viewState = 'loading';
            $scope.$watch('summary', function(v) {
                if (v && v.length === 0) {
                    services.rbsmbl.capabilityInfo().persistMTMInfo('', 'claimSummary');
                }
            });

            var isClicked;
            $scope.$on('drawerOpen', function() {
                if(!isClicked){
                    coremetrics.elementTag('CLAIM DETAIL EXPAND','MEMBER:CLAIMS');
                    isClicked = true;
                }
            });

            /*Coremetrics ELement Tag for Claims Summary Arrow link/button*/
            $scope.claimsSummaryArrowCoremetrics = function(){
                coremetrics.elementTag('CLAIMS:CLAIMS AND PAYMENTS SUMMARY ARROW','MEMBER:CLAIMS');
            };

            $scope.fetchClaims = function(reload) {
                if (reload) {
                    services.rbsmbl.claimSummary().flushCache();
                }
                claimService.getSummary().then(
                    function(claimsData) {
                        if (claimsData.summary.spendingAccountError) {
                            $scope.viewState = 'spendingAccountError';
                        }
                        else {
                            $scope.viewState = 'success';
                        }
                        $scope.summary = claimsData.summary;
                        $scope.newClaimsCount = Number(claimsData.summary.totalClaimsSinceLastVisit);
                        if (!$cookies.get('visitedClaimsActivitySummaryThisSession')) {
                            $cookies.put('visitedClaimsActivitySummaryThisSession', true);
                           // FIXME: this opens claim 1, which might not be the 1st claim on the page due to filters/pagination
                           // The whole idea of opening the first claim is flawed, we need to talk with UX about what they expect
                           // My suggestion is that we open the 1st claim once, and reset the counter when the user logs out
                           if ($scope.summary[0]) {
                               // Autoopen first claim feature is no longer necessary in the latest patterns
                               $scope.summary[0].isOpen = true;
                           }
                        }
                    },
                    function(err) {
                        $scope.viewState = 'error';
                        console.error('getSummary failed with error', err);
                    }
                );
            };
            $scope.fetchClaims();

            updateVisited.mhsLanding();
        }
    ]);
};