module.exports = function (app) {
	/**
	* @ngdoc ngController
	* @name pages.claimsAndSpending.spendingAccounts
	*
	* @requires services, spendingResourceServices, $state, sessionData, $cookies, $scope, teamsite, $location, $window, invisibleForm, claimsAndSpendingLogic, brand, coremetrics
	* @description
	* The pages.claimsAndSpending.spendingAccounts controller serves as the
	* child page controller to Claims & Spending > Spending Accounts.
	*/
	app.controller('pages.claimsAndSpending.spendingAccounts', ['$compile', 'services', 'spendingResourceServices', '$state', 'sessionData', '$cookies', '$scope', 'teamsite', '$location', '$window', 'invisibleForm', 'claimsAndSpendingLogic', 'brand', 'coremetrics', 'manualHsaService', function ($compile, services, spendingResourceServices, $state, sessionData, $cookies, $scope, teamsite, $location, $window, invisibleForm, claimsAndSpendingLogic, brand, coremetrics, manualHsaService) {
		if (claimsAndSpendingLogic.redirect) {
			$state.go(claimsAndSpendingLogic.redirect);
		}

		coremetrics.pageviewTag('MEMBER:CLAIMS AND SPENDING:SPENDING ACCOUNTS', 'MEMBER:CLAIMS AND SPENDING');

		var umi = sessionData.memberInfo.umi + '0';
		var vendorDetails = sessionData.vendorDetails || [];
		var today = new Date();
		var supressionDate = new Date('06/01/2019');
		$scope.shouldShowWelcomeMessage = !$cookies.get("hideSpendingAccountMessage") && today < supressionDate;

		//Forms Library link Display Login
		$scope.csFormsLibraryAccess = false;
		var csFormsLibraryBrands = ['hmbcbs', 'hbs', 'hmbcbswv', 'hmbcbsde', 'pp', 'de'];
        var csBrandCode = sessionData.memberInfo.brand.toLowerCase();
        if (csFormsLibraryBrands.indexOf(csBrandCode) > -1) {         
            $scope.csFormsLibraryAccess = true;
        }
        
        var isEligibleForHSA = function(memberData) {
            // MN Core members not eligible
            if (memberData.brand.toLowerCase() === "mincr") {
                return false;
            }

			/**  Rules for determining if member is eligible for HSA
			 * 1.) Must have medical coverage that is HDHP or PPO
			 * 2.) Must be in manual enroll group
			 * 3.) Must not be in blackout or group setup 
			 * 4.) Must not already have an HSA for the particular client
			 * 5.) Medical Coverage must be active with no end date or cancellation date
			 * 6.) Must be the subscriber
			 */
			var eligibleForHSA = false;
			memberData.clientList.forEach(function(client) {
				//never eligible for manual enroll during black out
				if (client.spendingAccountBlackoutIndicator !== "true") { 
					var hsaExistForClient = false;
	                var clientEligibleForHSA = false;

					client.groupList.forEach(function(groupItem) {
	                    if (groupItem.hsaExistsIndicator == "true" || groupItem.hsaEnrollmentInProcessIndicator == "true") {
	                        hsaExistForClient = true;
	                        if (groupItem.hsaEnrollmentInProcessIndicator == "true") {
	                        	$scope.hsaPendingSetup = true;
	                        }
	                    }

	                    if (manualHsaService.isUserEnrolledInCookie(memberData)) {
	                    	$scope.hsaPendingSetup = true;
	                    }

	                    if (!hsaExistForClient &&
		                    	groupItem.autoEnrollIndicator == "false" &&
		                        groupItem.hsaSetupIndicator == "true" &&
		                        groupItem.openHsaAccessIndicator == "true") {
	                        groupItem.productList.forEach(function(productItem) {
	                            if (manualHsaService.hasSubscriberInfo(productItem) 
	                            	&& !manualHsaService.isSubscriberCancelled(productItem)) {
	                                //don't allow members to open HSAs for expired coverages
		                            if (productItem.coverageEndDate != "" 
		                            	&& productItem.coverageEndDate != null 
		                            	&& !manualHsaService.isDateStringBeforeToday(productItem.coverageEndDate)) {
		                                //don't allow members with void/cancelled dates to open HSAs
			                            if (!manualHsaService.isCoverageCancelled(productItem)) {
			                                if (productItem.benefitPlanType.indexOf("Medical") != -1 && productItem.hdhpIndicator == "true") {
				                                clientEligibleForHSA = true;
				                            }
			                            }
		                            }
	                            }
	                        });
	                    }
					});
					if (!hsaExistForClient && clientEligibleForHSA) {
	                    eligibleForHSA = true;
                        $scope.manualHsaData.push(client);
	                }
				}
			});
			return eligibleForHSA;
		};

		//TODO: for post-testing
		$scope.manualHsaData = []; 
		$scope.hsaPendingSetup = false;
		$scope.eligibleForHSA = Array.isArray(sessionData.memberInfo.clientList)
			? isEligibleForHSA(sessionData.memberInfo) //this method populates $scope.manualHsaData & $scope.hsaPendingSetup as it goes
			: false; 

		$scope.closeMessage = function(){
			$cookies.put('hideSpendingAccountMessage', 'true');
			$scope.shouldShowWelcomeMessage = false;
		};

		var _parseDate = function (str) {
			var DATE_FORMAT = /^\d{2}\/\d{2}\/\d{4}$/;
			var EXPECTED_STR_FORMAT = /^\d{8}$/;

			if (DATE_FORMAT.test(str)) {
				return new Date(str);
			} else if (!EXPECTED_STR_FORMAT.test(str)) {
				throw new Exception('Invalid date format.');
			}

			var year = str.substr(0, 4);
			var month = str.substr(4, 2);
			var day = str.substr(6, 2);

			return new Date(month + "/" + day + "/" + year);
		};


		// Welcome Banner Display and Dismissal Logic

		$scope.shouldShowWelcomeMessage = !$cookies.get('hideSpendingAccountMessage') && today < supressionDate;
		
		$scope.closeMessage = function() {
			$cookies.put('hideSpendingAccountMessage', 'true');
			$scope.shouldShowWelcomeMessage = false;
		};

		$scope.ssoToSpendingAccount = function () {
			// TODO: Does this link need to be set dynamically/through CMS?
			var spendingURL = '/emssvc/x-services/secure/sso/outbound/auto/v1/vendor/ALEGEUS'
				+ '?cm_sp=SPENDING%20SSO-_-ALEGEUS%20SSO-_- ACCESS%20SPENDING%20ACCOUNT';
			$window.open(spendingURL, '_blank');
			coremetrics.elementTag('MEMBER:CLAIMS AND SPENDING:ALEGEUS SSO', 'MEMBER:CLAIMS AND SPENDING');
		};

		$scope.priorYearToolTip = '<span data-teamsite="HOM_CLAIMSANDSPENDING_PRIOR_YEAR_TOOLTIP"></span>';		

		teamsite.get("PLANPROGRESS_SUPRESSED_GROUPS").then(function(response) {      
            if (response) {
                response.split(",");
				suppressionList = response;
				var currentGroupNumber = $cookies.get('mbrGroupNumber');
				var showPlannedProgressModule = (suppressionList.indexOf(currentGroupNumber) === -1) ? true :  false;
				$scope.shouldShowPlanProgress = showPlannedProgressModule;
				if (!showPlannedProgressModule) {
					teamsite.get("HOM_CLAIMSANDSPENDING_PLANPROGRESSUNAVAILABLE_TITLE").then(function(response) {
						if (response) {
							$scope.planProgressUnavailableTitle = response;
						} else {
							$scope.planProgressUnavailableTitle = 'Current Planned Progress';
						}
					});
				}
            } else {
				console.log('Teamsite Failed.');
			}
        });

		// Current Year Display Logic
		var foundEffectiveAlegeusAccount = false;
		var vendorDetails = sessionData.vendorDetails;
		var currentClientList = sessionData.memberInfo.clientList;
		var groupNumberCookie = $cookies.get('mbrGroupNumber');
		var foundSpend = false;
		var currentSpending;

		for (var i = 0; i < currentClientList.length; i++) {
				var client = currentClientList[i];
				for (var j = 0; j < client.groupList.length; j++) {
					var group = client.groupList[j];
					if ((group.groupNumber === groupNumberCookie) && (group.spendingAccountList !== [])) {
							for (var k = 0; k < group.spendingAccountList.length; k++) {
								var spending = group.spendingAccountList[k];
								if(spending.processorID === "ALEGEUS") {
									foundSpend = true;
									currentSpending = spending.processorID;
									break;
								}
							}		
						if (foundSpend) {
							break;
						}
					} else {
						currentSpending = [];
					}
				}

				if (foundSpend) {
					break;
				}
		}
		
		for (var i = 0; i < vendorDetails.length; i++) {
			var account = vendorDetails[i];
			var startDate = new Date(account.effectiveStartDate);
			if (startDate.getHours() != 0) {
				startDate = new Date(startDate.getTime() + (startDate.getTimezoneOffset()*60*1000));
			}
			
			if ((account.vendorName.toUpperCase() === 'ALEGEUS')
				&& (today >= startDate) && (currentSpending === "ALEGEUS")) {
				foundEffectiveAlegeusAccount = true;
				break;
			}
		}

		if (sessionData.spendingAccountBlackoutIndicator) {
			$scope.shouldShowMaintenanceErrorMsg = true;
			$scope.shouldShowWelcomeMessage = false;
			$scope.shouldShowPlanProgress = false;

			var groupFound = false;
			var groupNumber = $cookies.get('mbrGroupNumber');
			var clientList = sessionData.memberInfo.clientList;
			
			for (var i = 0; i < clientList.length; i++) {
				var client = clientList[i];
				for (var j = 0; j < client.groupList.length; j++) {
					var group = client.groupList[j];
					if (group.groupNumber === groupNumber) {
						$scope.beginDate = new Date(client.spendingAccountBlackoutstartdate + 'Z-500');
						$scope.endDate = new Date(client.spendingAccountBlackoutenddate + 'Z-500');
						groupFound = true;
						break;
					}
				}

				if (groupFound) {
					break;
				}
			}
		} else if (foundEffectiveAlegeusAccount) {
			$scope.shouldShowCurrentYear = true;
			$scope.shouldShowCurrentYearError = false;
			$scope.alegeusAccountType = [];

			// sessionData.memberInfo.clientList.forEach(function (client) {

				services.rbsmbl.spendingAccountBalance().fetch().then(function(response){
                    if (response
                        && response.data
                        && response.data.baseMessage && response.data.payload
                        && response.data.baseMessage.returnCode == 200){

                        var responseAccountInfoReply = response.data.payload;
                        $scope.accountInfo = responseAccountInfoReply;
                        var employeeAccountDetails = responseAccountInfoReply;

						if (!employeeAccountDetails.length) {
							$scope.shouldShowCurrentYearError = true;
						} else {
							$scope.accountTypes = {};
							employeeAccountDetails.map(function (account) {
								return account.accountTypeCode;
							}).forEach(function (type) {
								$scope.accountTypes[type] = false;
							});

							services.rbsmbl.contentInfo().getContent($scope.accountTypes, {
								brand: (brand.current.umsBrandCode || brand.current.sharedBrandCode).toUpperCase()
							}).then(function (response) {
								var theResponse = (response
									&& response.data
									&& response.data.payload
									&& response.data.payload.contentResponse) || {};

								var validContent = theResponse.map(function (response) {
									return response.name;
								});
								
								for(var i = 0; i < theResponse.length; i++) {
									$scope.accountTypes[theResponse[i].name] = theResponse[i].value;
								}

								for (var key in $scope.accountTypes) {
									if (!$scope.accountTypes[key]) {
										$scope.shouldShowCurrentYearError = true;
										break;
									}
								}

								employeeAccountDetails.forEach(function (account) {
									var accountTypeCode = account.accountTypeCode;
									var balance = account.availableBalance;
									var currentPlanStartDate, currentPlanEndDate;
									var causesError = (typeof balance === 'undefined'
										|| balance === ''
										|| isNaN(Number(balance)))
										|| !$scope.accountTypes[accountTypeCode];
									
									try {
										currentPlanStartDate = _parseDate(account.planYearStartDate);
										currentPlanEndDate = _parseDate(account.planYrEndDate);
									} catch (error) {
										causesError = true;
									}

                                    /* RF789855 - Member unable to access terminated spending account. Added condition to
                                    access spending account for 1 year. */
									var terminationYear = (account.terminationDate).slice(0,4);
                                    var terminationMonth = (account.terminationDate).slice(4,6);
                                    var terminationDate = (account.terminationDate).slice(6,8);
                                    var termDate = new Date(terminationYear, terminationMonth - 1, terminationDate);

                                    // filter accountStatus - only Active accounts are pushed to array
                                    if ((account.accountStatus.toUpperCase() !== 'TERMINATED' &&
                                        account.accountStatus.toUpperCase() !== 'PERMINACTIVE') ||
                                        (account.accountStatus.toUpperCase() === 'TERMINATED' && ((new Date() - termDate)/86400000) < 365)) {
                                        if (!causesError) {
                                            $scope.alegeusAccountType.push({
                                                accountType: accountTypeCode,
                                                accountName: $scope.accountTypes[accountTypeCode],
                                                balance: balance,
                                                startDate: currentPlanStartDate,
                                                endDate: currentPlanEndDate
                                            });
                                        } else {
                                            $scope.shouldShowCurrentYearError = true;
                                        }
                                    }
								});
								
								// if there are no active alegeusAccount hide component
								if ($scope.alegeusAccountType.length === 0) 
								{
									$scope.shouldShowCurrentYear = false;		
								}
							}, function (error) {
								$scope.shouldShowCurrentYearError = true;
							});
						}
					}
				}, function (error) {
					$scope.shouldShowCurrentYearError = true;
				});	
			// });
		}

		
		// Prior Year Display Logic		
		var fullMemberInfo = sessionData.memberInfo;
		var clientLength = fullMemberInfo.clientList.length;
		$scope.shouldShowPriorYear = false;

		// checking the number of clients
		if(clientLength > 0){
			for(var i = 0; i < clientLength; i++){
				var clientList = fullMemberInfo.clientList[i]; // Fetching the complete client list
				var groupLength = clientList.groupList.length; // Group Length
				
				for(var j = 0; j < groupLength; j++){
					var groupList = clientList.groupList[j]; // Fetching the complete group list
					var groupNumbrList = groupList.groupNumber;
					var productLength = groupList.productList.length;
					var spendingAccountLength = groupList.spendingAccountList.length;
					var spendingAccountExists = groupList.spendingAccountExistsIndicator; // Check if they have spending account

					// Should check for the latest spending account product
					for (var p = 0; p < productLength; p++){
						var productList = groupList.productList[p];										
						var benefitType = productList.benefitPlanType; // Spending Account check
						
						// determining the latest spending account
						if((benefitType == "Spending Accounts") && spendingAccountExists){
							for(var k = 0; k < spendingAccountLength; k++){
								var account = group.spendingAccountList[k]; // Spending Account list 

								/**
								* Prior year spending account display conditions:
								* If the processor Id is Acclaris - the prior year spending account should not be displayed after the contract end date (July 31,2020)
								* If the processor id is '' - the prior year spending account should be displayed only till 1 year from the asa end date.
								* */
                                if(account.processorID.toUpperCase() !== 'ALEGEUS') {
                                    if (account.processorID.toUpperCase() === 'ACCLARIS'){ //Acclaris contract ended on July 31,2020
                                    	$scope.shouldShowPriorYear = false;
                                    } else if (account.processorID.toUpperCase() === ""){ // if processorID is Acclaris and empty
                                        var asaEndDateLatest = new Date(account.asaEndDate);
                                        var dateOffset = (24 * 60 * 60 * 1000); //Date offset to calculate the number of days
                                        var asaEndDateWithinaYear = (Math.abs(today - asaEndDateLatest) / dateOffset);

                                        if (asaEndDateLatest > today || asaEndDateWithinaYear < 365) {
                                            var cntrdIdGroup = account.cntrID;
                                            var groupCheck = (groupNumbrList.indexOf(cntrdIdGroup) > -1); // Checking the group to compare against the list

                                            if (groupCheck) {
                                                $scope.shouldShowPriorYear = !$scope.shouldShowMaintenanceErrorMsg;
                                                var clientNumber = clientList.clientNumber;
                                                break;
                                            }
                                        }
                                    }
                                }
								if ($scope.shouldShowPriorYear) {
									break;
								}
							}		
						}
					}				
				}				
			}
		}

		// SSO To Acclaris - prior year account
		/*$scope.ssoToPriorYearSpeningAccount = function () {
			// Build the payload to SSO URL
			var acclarisSsoClientNumber = clientNumber;

			var thePayload = {
				groupId: acclarisSsoClientNumber,
				memberId: umi,
				csr: false,
				DLParams: 'obj~EEPRFLSMRYPAGE-Page',
				userRole: 'MEMBER'
			};

			coremetrics.elementTag('MEMBER:CLAIMS AND SPENDING:ACCLARIS SSO', 'MEMBER:CLAIMS AND SPENDING');

			services.rbsmbl.acclaris().postData({payload: thePayload}).then(function (success) {
				var ssoFormConfig = {
                    name: 'acclarisSSOpriorYearForm',
                    id: 'acclarisSSOpriorYearFormId',
                    formTarget: 'InvisibleSsoSvcs',
                    method: 'POST',
                    action: success.data.payload.redirectionURL
                    	+ '?cm_sp=SPENDING%20SSO-_-ACCLARIS%20SSO-_-ACCESS%20PRIOR%20PLAN%20YEAR',
                    target: 'acclarisWindow',
                    inputs: {
                    	cookieData: success.data.payload.cookieData,
                    	userName: success.data.payload.userName,
                    	destinationURL: success.data.payload.destinationURL,
                    	URLparms: success.data.payload.URLparms,
						oamId: success.data.payload.oamId
                    }
                };                     
            	invisibleForm.submit(ssoFormConfig);
			}, function (error) {
				coremetrics.elementTag('MEMBER:CLAIMS AND SPENDING:ACCLARIS ERROR', 'MEMBER:CLAIMS AND SPENDING:SPENDING ACCOUNTS');
			});
		};

		// Prior year module
		if ($scope.shouldShowPriorYear) {
			services.dpsext.spendingAccountGlance().fetch(fullMemberInfo, clientNumber, 30, "All").then(function (response) {
				var accountInfo = response
					&& response.data
	                && response.data.repGetAccountsAtAGlanceReply
	                && response.data.repGetAccountsAtAGlanceReply.payload
	                && response.data.repGetAccountsAtAGlanceReply.payload.repGetAccountsAtAGlanceData
	                && response.data.repGetAccountsAtAGlanceReply.payload.repGetAccountsAtAGlanceData.accountList
	                && response.data.repGetAccountsAtAGlanceReply.payload.repGetAccountsAtAGlanceData.accountList.accountInfo
	                	? response.data.repGetAccountsAtAGlanceReply.payload.repGetAccountsAtAGlanceData.accountList.accountInfo : [];

	        	if (!accountInfo.length) {
	        		$scope.shouldShowPriorYearError = true;
	        		$scope.priorYearInfoId = 'GENERAL';
	        	} else {
	        		$scope.shouldShowPriorYearError = false;
		            if (accountInfo.length == 1
		            	&& accountInfo[0].accountType.toUpperCase() === 'HSA') {
		            	$scope.priorYearInfoId = 'GENERAL';
		            }

		            $scope.acclarisAccountType = [];
					
					var theAcclarisViewDateArray = [];
					var theAcclarisRunOutDateArray = [];

		 			for (var i = 0; i < accountInfo.length; i++) {
		 				var account = accountInfo[i];
						var acclarisAccountType = account.accountType;
						var acclarisAccountName = account.accountDisplayName
						acclarisAccountName = acclarisAccountName.replace(/\(.*\)/g, '');

		            	for (var j = 0; j < account.electionList.electionDetails.length; j++) {
		            		var detail = account.electionList.electionDetails[j];
							var acclarisBalance = detail.currentBalance;
		            		var theStartDate = new Date(detail.electionPeriodStartDate);
		            		var theEndDate = new Date(detail.electionPeriodEndDate);
		            		var theRunOutEndDate = new Date(detail.runOutEndDate);
							theAcclarisRunOutDateArray.push(theRunOutEndDate);
		            		theAcclarisViewDate = new Date(detail.electionPeriodEndDate);
		            		if (acclarisAccountType.toUpperCase() !== 'HSA') {
								theAcclarisViewDateArray.push(theAcclarisViewDate);
							}						
							var dateCheck = new Date('December 31, 2025');
							var today = new Date();

							var individualRunoutFlag = null;
							if (isNaN(theRunOutEndDate)
								|| acclarisAccountType.toUpperCase() === 'HSA') {
								$scope.priorYearInfoId = 'GENERAL';
							}

							if (theRunOutEndDate > dateCheck
								|| today > theRunOutEndDate
								|| isNaN(theRunOutEndDate)) {
								individualRunoutFlag = false;
							} else {
								individualRunoutFlag = true;	
							}

							var hideDate = new Date(theEndDate);
							hideDate.setFullYear(theEndDate.getFullYear()+1);
							if (hideDate >= today) {
								var acclarisPush = {
									runOutFlag: individualRunoutFlag,
									acclarisAccountName: acclarisAccountName,
									acclarisAccountType: acclarisAccountType,
									acclarisBalance: acclarisBalance,
									priorPlanStartDate: theStartDate,
									priorPlanEndDate: theEndDate,
									acclarisRunOutEndDate: theRunOutEndDate,
									shouldShowAcclarisBalance: true
								};

								if (acclarisAccountType.toUpperCase() === 'HSA'
									|| acclarisBalance == '0'
									|| acclarisBalance == '0.0'
									|| today >= theRunOutEndDate) {
									acclarisPush.shouldShowAcclarisBalance = false;
								}

								$scope.acclarisAccountType.push(acclarisPush);
							}
		            	}
		            }

					var maxDate = new Date(Math.max.apply(null, theAcclarisViewDateArray));
					$scope.acclarisViewDate = maxDate.setFullYear(maxDate.getFullYear() + 1);
					
					var runOutEndDateLatest = new Date(Math.max.apply(null, theAcclarisRunOutDateArray));
					if ($scope.priorYearInfoId !== 'GENERAL') {
						if (today <= runOutEndDateLatest) {
							$scope.priorYearInfoId = 'INRUNOUT';
						} else {
							$scope.priorYearInfoId = 'AFTERRUNOUT';
						}
					}
	        	}
			}, function (error) {
				coremetrics.elementTag('MEMBER:CLAIMS AND SPENDING:ACCLARIS ERROR', 'MEMBER:CLAIMS AND SPENDING:SPENDING ACCOUNTS');
				$scope.shouldShowPriorYearError = true;
				$scope.priorYearInfoId = 'GENERAL';
			});
		}*/

	}]);
};