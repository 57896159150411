module.exports = function(app) {
    app.directive('oralHealthCondition', function() {

        return {
            restrict: 'A',
            replace: true,
            template: require('directives/oralHealthCondition.html'),

            scope: {
                condition: '=',         // Condition Object
                conditionCancel: '&',   // Reference to cancel function that will be called when a condition is canceled.
                conditionRenew: '&',    // Reference to renew function that will be called when a condition is renewed.
                errorHandler: '&'       // Reference to error function that will be called when an error occurs.
            },

            controller: ['$scope', 'services', 'sessionData', 'medicalDentalService', 'teamsite', 'coremetrics',
                function($scope, services, sessionData, medicalDentalService, teamsite, coremetrics) {
                    var memberInfo = sessionData.memberInfo;
                    var today = new Date();
                    var maintenanceDate = new Date($scope.condition.maintenanceDate);
                    today.setHours(0,0,0,0);
                    maintenanceDate.setHours(0,0,0,0);

                    $scope.showErrorMsg = false;
                    $scope.showUpdateForm = false;
                    $scope.updateFormForCancel = false;
                    $scope.showUpdateMessage = false;
                    $scope.disableButtons = false;
                    $scope.showUpdateButton = false;
                    $scope.uniqueId = $scope.condition.code + $scope.condition.member.pin + $scope.condition.effectiveDate;

                    $scope.conditionUpdates = {
                        templateSpecificDate: "",
                        treatmentIndicator: null,
                        acceptTerms: false
                    };
                    $scope.selectedTemplate = {
                        pregnancy: false,
                        treatment: false,
                        transplant: false,
                        standard: false
                    };
                    $scope.conditionType = {};
                    $scope.templateSpecificDateDisplay = "";


                    teamsite.get('MBR_COVERAGE_ORAL_HEALTH_VALIDATION').then(function (data) {
                        $scope.validation = JSON.parse(data);
                    });

                    var setTemplateSpecificDateForDisplay = function() {
                        if (!$scope.selectedTemplate.standard && $scope.condition && $scope.conditionType && $scope.conditionType.value) {
                            var displayDate = new Date($scope.condition.cancelDate);
                            displayDate.setMonth(displayDate.getMonth() - $scope.conditionType.value.monthCountCoveragePeriod);
                            $scope.templateSpecificDateDisplay = medicalDentalService.formatDate(displayDate);
                        }
                    };

                    medicalDentalService.getConditionTypes(memberInfo.clientList[0].enrollmentSourceCode, sessionData.groupNumber).then(function(conditionList) {
                        for (var i = 0; i < conditionList.length; i++) {
                            if (conditionList[i].value.code === $scope.condition.code) {
                                $scope.conditionType = conditionList[i];
                                break;
                            }
                        }
                        if ($scope.condition && $scope.conditionType && $scope.conditionType.value) {
                            $scope.selectedTemplate[$scope.conditionType.value.templateName] = true;
                            $scope.showUpdateButton = $scope.conditionType.value.renewable;

                            if (!$scope.selectedTemplate.standard) {
                                // Initialize template specific date, and then watch for any changes to the cancel date.
                                // Only need to watch the cancel date for conditions with a non-standard template.
                                setTemplateSpecificDateForDisplay();

                                $scope.$watch(function () {
                                    return $scope.condition.cancelDate;
                                }, function (newValue, oldValue) {
                                    // If the cancel date changes, update the template specific date.
                                    setTemplateSpecificDateForDisplay();
                                });
                            }
                        }
                    }, function(error) {
                        $scope.errorHandler({
                            message: "An error occurred while fetching the list of eligible condition types.",
                            error: error,
                            isConditionTypes: true
                        });
                    });

                    var showUpdateMessage = function(action) {
                        var showMsg = true;
                        if (action === "new") {
                            $scope.updateMessage = '<span data-teamsite="MBR_COVERAGE_ORAL_CONDITION_ACTIVE"></span>';
                        } else if (action === "renew") {
                            $scope.updateMessage = '<span data-teamsite="MBR_COVERAGE_ORAL_CONDITION_RENEWED"></span>';
                        } else if (action ==="cancel") {
                            $scope.updateMessage = '<span data-teamsite="MBR_COVERAGE_ORAL_CONDITION_NOT_ACTIVE"></span>';
                        } else {
                            showMsg = false;
                        }
                        $scope.showUpdateMessage = showMsg;
                    };

                    // If the condition was created/updated today, check if any message should display.
                    if (today.toDateString() === maintenanceDate.toDateString()) {
                        var effectiveDate = new Date($scope.condition.effectiveDate);
                        effectiveDate.setHours(0,0,0,0);

                        if ($scope.condition.status === "INACTIVE") {
                            showUpdateMessage("cancel");
                        } else if (today.toDateString() === effectiveDate.toDateString()) {
                            showUpdateMessage("new");
                        } else if (effectiveDate.getTime() < new Date(today.getFullYear(), today.getMonth(), today.getDate()).getTime()) {
                            showUpdateMessage("renew");
                        }
                    }

                    var resetForm = function() {
                        $scope.disableButtons = false;
                        $scope.showErrorMsg = false;
                        $scope.showUpdateForm = false;
                        $scope['updateConditionForm' + $scope.uniqueId].$setUntouched();
                        $scope['updateConditionForm' + $scope.uniqueId].$setPristine();
                        $scope.conditionUpdates.templateSpecificDate = "";
                        $scope.conditionUpdates.treatmentIndicator = null;
                        $scope.conditionUpdates.acceptTerms = false;
                    };

                    var cancelCondition = function() {
                        coremetrics.elementTag('Cancel Condition: ' + $scope.condition.description, 'MEMBER:YOUR COVERAGE:MY ORAL HEALTH');

                        medicalDentalService.cancelCondition($scope.condition).then(function(cancelledCondition) {
                            // Let parent controller know of the cancelled condition.
                            $scope.conditionCancel({ condition: $scope.condition, coveredIndividualId: $scope.condition.member.coveredIndividualId });
                            resetForm();
                            showUpdateMessage("cancel");

                        }, function(error) {
                            $scope.disableButtons = false;
                            $scope.showErrorMsg = true;
                        });
                    };

                    var renewCondition = function() {
                        coremetrics.elementTag('Renew Condition: ' + $scope.condition.description, 'MEMBER:YOUR COVERAGE:MY ORAL HEALTH');

                        medicalDentalService.renewCondition($scope.conditionUpdates, $scope.conditionType, $scope.condition).then(function(response) {
                            //Let parent controller know of the renewed condition.
                            $scope.conditionRenew({ condition: $scope.condition, coveredIndividualId: $scope.condition.member.coveredIndividualId });
                            $scope.showUpdateButton = false;
                            resetForm();
                            showUpdateMessage("renew");

                        }, function(error) {
                            $scope.disableButtons = false;
                            $scope.showErrorMsg = true;
                        });
                    };

                    $scope.showFormForUpdate = function(isCancel) {
                        $scope.showUpdateForm = true;
                        $scope.updateFormForCancel = isCancel;
                    };

                    $scope.cancelUpdate = function() {
                        resetForm();
                    };

                    $scope.updateCondition = function () {
                        if ($scope['updateConditionForm' + $scope.uniqueId].$valid) {
                            $scope.disableButtons = true;
                            if ($scope.updateFormForCancel) {
                                cancelCondition();
                            } else {
                                renewCondition();
                            }
                        }
                    };

                }
            ]
        };
    });
};