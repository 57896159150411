module.exports = function(bootstrap) {
    bootstrap.run(['Service', 'appId', function(Service, appId) {
        new Service(
            {
                name: 'requestPrintedCopy',
                group: 'rbsmbl',
                url: '/rbsmbl/x-services/secure/eocFormulary/v1/eocAndFormulary',
                fetch: function(data) {
                    var params = {"EocOrFormularyIndicator" : "FORMULARY"};
                    if(data){
                        params.year = data;
                    }
                    return this.get(params);
                }
            }
        );
    }]);
};
