module.exports = function(bootstrap) {
    bootstrap.factory('validationService', function() {
        var
            patterns = {},
            messages = {};

        patterns.email = /^[a-z0-9_.+-]+@[a-z0-9-]+\.[a-z0-9-.]+$/i;
        messages.email = {
            required: 'Enter an email address',
            pattern: 'Enter a valid email address'
        };

        patterns.phone = /^(?:([1])[- /.]?)?(?:(?:[(]([2-9][0-9]{2})[)])|(?:([2-9][0-9]{2})))[- /.]?([0-9]{3})[- /.]?([0-9]{4})$/;
        messages.phone = {
            required: 'Enter a phone number',
            pattern: 'Enter a valid phone number'
        };


        return {
            patterns: patterns,
            messages: messages
        };
    });
};