module.exports = function(app) {
    app.factory('virusScanService', ['$q', 'services', 'xml2json',
        function($q, services, xml2json) {

            var formData;

            var getData = function(fileBinary) {
                formData = {
                    "virusScanRequestMessage": {
                        "baseMessage": {
                            "exchangeType": "SENDREQUEST",
                            "headerVersion": "1.0",
                            "username": "appmhs"
                        },
                        "payload": {
                            "virusScanMessageData" : {
                                "file": fileBinary
                            }
                        }
                    }
                };

                formData = xml2json.json2xml_str(formData);

                //console.log("eeee",formData);

                return services.rbsmbl.virusScan().fetch(formData).then(
                    function(response) {
                        return response;
                    },
                    function(err) {
                        console.error('fetch failed in getData', err);
                        return err;
                    }
                );
            };

            return {
                getData: getData
            };
        }
    ]);
};