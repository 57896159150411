module.exports = function(app) {
    app.directive('wellnessRecommended', function() {

        return {
            restrict: 'A',
            replace: true,
            template: require('directives/wellnessRecommended.html'),

            //              NOTE: localWebmdRecommendations is in local-services/ finderService.js
            controller: ['$scope', 'localWebmdRecommendations', 'wellnessUtility',
                function($scope, localWebmdRecommendations, wellnessUtility) {
                    $scope.webmdRecs.booShowMore = false;
                    $scope.webmdRecs.showMoreLessButtonText = 'Show More';
                    $scope.webmdRecs.showMoreFunc = function() {
                        $scope.webmdRecs.booShowMore = !$scope.webmdRecs.booShowMore;
                        $scope.webmdRecs.showMoreLessButtonText = ($scope.webmdRecs.booShowMore) ? 'Show Less' : 'Show More';
                    }
                  //   $scope.webmdRecs.showThis = false;
                    var g = wellnessUtility.wellnessSessionInfo;
                    // only hit the service if $scope.webmdRecs.theRecs is not populated
                    // if (!($scope.webmdRecs.theRecs)) {
                        var recs = localWebmdRecommendations.getWebmdRecommendations(g.groupNumber, g.clientNumber, g.brand);
                        recs.then(function (thePayload) {
                                      // ToDo: the isArray test is needed cause the code comes through here twice. Make it only come through here once.
                                      if (thePayload && thePayload.recommendations && (thePayload.recommendations.length > 0)
                                          && (!Array.isArray($scope.webmdRecs.theRecs))) {
                                          var arrRecs = thePayload.recommendations;
                                          // add CM site promotion name-value pair
                                          var initialCharacter;
                                          for (var i = 0; i < arrRecs.length; i++) {
                                              initialCharacter = (arrRecs[i].linkUrl.indexOf('?') > -1) ? '&' : '?';
                                              arrRecs[i].linkUrl = arrRecs[i].linkUrl + initialCharacter + 'cm_sp=WebMD-_-Recommended%20For%20You-_-Recommended%20For%20You'
                                          }
                                          var arrThreeRecs;
                                          $scope.webmdRecs.showThis = true;
                                          if (arrRecs.length <= 3) {
                                              $scope.webmdRecs.theRecs = arrRecs;
                                              $scope.webmdRecs.overflowRecs = null;
                                          } else {
                                              // put only top three items in arrThreeRecs; the next 7 have to go in the hidden div
                                              //    ( there will always be at least 4 items in arrRecs )
                                              arrThreeRecs = arrRecs.splice(0, 3);
                                              $scope.webmdRecs.theRecs = arrThreeRecs;
                                              var sevenOrLess = (arrRecs.length > 7) ? 7 : arrRecs.length;
                                              var theSeven = arrRecs.splice(0, sevenOrLess)
                                              $scope.webmdRecs.overflowRecs = theSeven;
                                          }
                                      }
                                      // else is not needed because showThis will not get set true if array length = 0
                                  },
                                  function (err) {
                                      return;
                                  }
                        )
                    // }


                }
            ]
        };
    });
}