module.exports = function(app) {

    app.controller('pages.sameDayAppointment', ["$scope", "$state", "$timeout", "$q", "$window", "brand", function($scope, $state, $timeout, $q, $window, brand) {
        window.document.title = "Same day appointments";
        $scope.backAction = function () {
            $window.history.back();
        };

    }]);

    return [{
        state: "sameDayAppointment",
        url: "/same-day-appointments",
        appConfigMaintenanceApp: "SAME_DAY_APPOINTMENT",
        appConfigMaintenanceKey: "FULL_MAINTENANCE",
        views: {
            'body@': {
                template: require('pages/same-day-appointment.html'),
                controller: 'pages.sameDayAppointment'
            }
        },
        resolve: {
            brandCheck: function ($q, $state, $timeout, brand) {
                var deferred = $q.defer();
                if (brand && brand.current && brand.current.ubk === 9001) {
                    deferred.resolve();
                } else {
                    $timeout( function() { $state.go('404'); } );
                    deferred.reject();
                }
                return deferred.promise;
            }
        }
    }];

};