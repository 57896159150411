module.exports = ['$scope', '$http', function($scope, $http) {
    $scope.testclick = function() {
        alert('worked uh huh2');
    }
    $scope.passportOkClick = function(theTarget, isHref) {
        this.closeModal();
        if (isHref) {
            $scope.cmElementTagClick('passportRewards');
            window.open(theTarget);
                }
            }
    $scope.redirectHome = function(){
        location.replace("/home/");
    }
}];

