module.exports = function(app) {
    app.filter('lineStatus', function() {

        return function(statusCode) {
            statusCode = String(statusCode);

            switch (statusCode) {

                case '1':
                    return 'Approved';
                case '3':
                    return 'Denied';
                case '4':
                    return 'Adjusted';
                case '6':
                    return 'Transferred';
                default:
                    return '';
            }
        };
    });
};