module.exports = function (bootstrap) {
    bootstrap.run(['Service','$cookies', function(Service, $cookies) {
        new Service({
            name: "prenatalFormService",
            group: "rbsmbl",
            url: "/rbsmbl/account-settings/v3/ext/contactus/addMessage",
            headers: { Authorization: 'Bearer '+  $cookies.get("Oauth_access_token")},
            fetch: function (params) {
                var hostnameArray = window.location.hostname.split('.');
                var subDomain = hostnameArray[0];
                //var loginId = $cookies.get("loginId")? $cookies.get("loginId") : $cookies.get("emkt_userName").slice(6);
                if (subDomain == "www" || subDomain == "penv") {
                    var axwayHost= "https://api.hmhs.com";
                }else{
                   this.headers["x-hmhs-test-backend"] = "wasintertenv" + subDomain.slice(-1) + ".highmark.com" ;
                   var axwayHost = "https://apiqa.hmhs.com";
    
                }
                this.headers["brand"]  = $cookies.get("umsBrand").toLowerCase(),
                //this.headers["fromdp"] =  loginId
                
                this.url= axwayHost + this.url;
                var opts = params || {};
                this.withCredentials = false;
                return this.post(opts);
            }
        });
    }]);
};