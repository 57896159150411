module.exports = function(app) {
    app.directive('imagePath', function() {

        return {
            restrict: 'A',
            replace: true,
            template: '<img data-ng-src="{{pathOfIcon}}" alt=""/>',
            scope: {
              imagePath: '=imagePath'
            },
            // scope: {},

            controller: ['$scope',
                function($scope) {
                    $scope.specilityName= $scope.imagePath.toLocaleLowerCase();
                    $scope.imageName = $scope.specilityName.replace(/\s/g, '-');
                    $scope.pathOfIcon = "images/coverage/ccoe/"+$scope.imageName+".png";
                }
            ]
        };
    });
}
