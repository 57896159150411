module.exports = function (app) {
	app.controller('pages.wellness.dentalHealth.article', ["services", "sessionData", "$rootScope", "$sce", "$scope", "$state", "$timeout", function (services, sessionData, $rootScope, $sce, $scope, $state, $timeout) {
		// If the user does not have dental coverage, redirect them to the Wellness Summary page.
		if (sessionData && sessionData.benefits && !sessionData.benefits.dental) {
			$state.go("wellness");
		}

		// Update the active sidebar link and sidebar information.
		var DEEP_WATCH = true;
		$scope.$watch("sidebar.links", function (newValue) {
			if (newValue) {
				var currentStateName = $state.current.name;
				var parentStateName = currentStateName.substring(0, currentStateName.lastIndexOf("."));

				// Get the parent link from the list of links on the sidebar.
				var dentalHealthLink = $scope.sidebar.links.filter(function (item) {
					return item.state === parentStateName;
				})[0];

				// Reset the current sidebar link on scope.
				$scope.sidebar.current.active = false;
				$scope.sidebar.current = dentalHealthLink;
				$scope.sidebar.current.active = true;

				// Update sidebar information based off of details in dentalHealthLink.
				$scope.sidebar.heading = dentalHealthLink.heading;
				$scope.sidebar.description = dentalHealthLink.description;
				$scope.sidebar.selectedLink = $scope.sidebar.current.label;
			}
		}, DEEP_WATCH);

		// Setup the contentInfo request based off of the articleID state parameter.
		var articleID = $state.params.articleID;
		var requestIDs = {};
		var brand = sessionData && sessionData.memberInfo && sessionData.memberInfo.brand;

		requestIDs[articleID]  = articleID;

		// Request the content from contentInfo.
		services.rbsmbl.contentInfo().getContent(requestIDs, { "brand": brand }).then(function (response) {
			var isArticleContent = true;

			var content = (response
				&& response.data
				&& response.data.payload
				&& response.data.payload.contentResponse
				&& response.data.payload.contentResponse.length)
					? response.data.payload.contentResponse[0]["value"] : null;

			// If there is no content, redirect the user.
			if (!content) {
				isArticleContent = false;
			}

			// Ensure that the content is parsed as JSON.
			try {
				content = JSON.parse(content);
			} catch (e) {
				isArticleContent = false;
			}

			// Ensure that the content has the necessary properties to display article content, including a memberDentalHealth section.
			if (isArticleContent
				&& content["title"]
				&& content["content"]
				&& content.sections
				&& content.sections.memberDentalHealthCenter) {

				// TODO: revisit these qualifications
				$scope.article = content;
				$scope.article.content = $sce.trustAsHtml($scope.article.content);
			} else {
				return $state.go("^");
			}
		}, function (error) {
			// If there's an error with the contentInfo call, redirect the user.
			$state.go("^");
		});

		
	}]);
};