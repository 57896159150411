module.exports = function (bootstrap) {
    bootstrap.run(['Service', 'brand', function(Service, brand){
        new Service({
            name: 'demographicsSave',
            group: 'rbsmbl',
            url: '/rbsmbl/x-services/demographics/info',

            
            saveDemographicInfo: function(data) {
                if (brand.current.umsBrandCode) {
                    var currentBrand = brand.current.umsBrandCode;
                }
                else {
                    var currentBrand = brand.current.sharedBrandCode;
                }
                var appId = this.headers.APPID;
                this['headers'] = {"APPID": appId, "BRAND": currentBrand};


                return this.post({
                    'payload': data
                });
            }
        });

    }]);
};