module.exports = function (app) {
	app.run(['Service', function (Service) {
		new Service({
			name: 'updateUserProfile',
			group: 'rbsmbl',
			url: '/rbsmbl/x-services/updateProfile/updateUserProfile',
			update: function (payload) {
				return this.post(payload);
			}
		});
	}]);	
};