module.exports = function(bootstrap) {
    bootstrap.run(['Service', function(Service) {
        new Service(
            {
                name: 'preventiveSchedulePDF',
                group: 'rbsmbl',
                url: '/rbsmbl/x-services/file/v1/preventiveSchedule',

                fetch: function() {
                    return this.get();
                }
            }
        );
    }]);
};
