module.exports = function(app) {
    app.controller('pages.claims.payClaim', ['$scope', '$state', 'claimService', 'spendingAccountService', 'coremetrics', 'storage',
        function($scope, $state, claimService, spendingAccountService, coremetrics, storage) {
            $scope.headings = 'PAY CLAIMS';
            $scope.viewState = 'loading';
            $scope.payToOptions = [];


            var _configurePayToOptions = function(claimDetails, accountsList) {
                var
                    payModes,
                    hasSelf,
                    hasOther,
                    hasBoth,
                    optionForSelf,
                    optionForOther;

                optionForSelf = { label: 'Myself', value: 'self' };
                optionForOther = { label: 'Provider', value: 'provider' };

                payModes = claimDetails.map(function(detail) {
                    return detail.payMode;
                });

                hasSelf = payModes.some(function(payMode) {
                    return payMode === 'self';
                });

                hasOther = payModes.some(function(payMode) {
                    return payMode === 'other';
                });

                hasBoth = payModes.some(function(payMode) {
                    return payMode === 'both';
                });

                if (hasBoth || (hasSelf && hasOther)) {
                    accountsList.push(optionForSelf);
                    accountsList.push(optionForOther);
                    return;
                }
                if (hasSelf) {
                    accountsList.push(optionForSelf);
                    return;
                }
                if (hasOther) {
                    accountsList.push(optionForOther);
                    return;
                }
            };

            var _getPayFromAccount = function(clientId, claimNumber) {
                spendingAccountService.getSpendingAccount(clientId, claimNumber).then(
                    function(response) {
                        $scope.payFromAccountName = response.accountDisplayName;
                    },
                    function(err) {
                        $scope.viewState = 'error';
                        console.error('getSpendingAccount failed in _getPayFromAccount', err);
                    }
                );
            };

            claimService.getSummary(storage.get('claimRefineDateRange')).then(
                function(claimsData) {
                    var claim;
                    for (var i = 0, len = claimsData.summary.length; i < len; ++i) {
                        if (claimsData.summary[i].claimNumber === $state.params.claimNumber) {
                            $scope.claim = claim = claimsData.summary[i];

                            // TODO: THIS IS AN ASYNC METHOD JUST FLOATING HERE ALL SUSPECT. Should this be inlined or $q.all-ed?
                            // There is currently no way to determine an overall error or success state for this page. If there
                            // is a discrepancy, the error state determination is a race.
                            _getPayFromAccount(claim.clientId, claim.claimNumber);

                            claimService.getDetails(claim).then(
                                function(details) {
                                    var claimDetails;
                                    $scope.claimDetails = claimDetails = details;

                                    _configurePayToOptions(claimDetails, $scope.payToOptions);

                                    $scope.ignoredLines = claimDetails.filter(function(line) {
                                        return line.isIgnored;
                                    });

                                    $scope.payableLines = claimDetails.filter(function(line) {
                                        return line.isPayable;
                                    });

                                    $scope.viewState = 'success';
                                },
                                function(err) {
                                    $scope.viewState = 'error';
                                    console.error('claimDetails.getDetails failed within payClaim', err);
                                    return err;
                                }
                            );
                            break;
                        }
                    }
                },
                function(err) {
                    $scope.viewState = 'error';
                    console.error('getSummary failed with error', err);
                }
            );


            $scope.totalSelectedLines = function(payableLines) {
                payableLines = payableLines || [];

                var
                    checkedLines,
                    totalAmount;

                checkedLines = $scope.checkedLines = payableLines.filter(
                    function(line) {
                        return line.isChecked;
                    }
                );

                totalAmount = checkedLines.reduce(
                    function(x, y) {
                        return x + parseFloat(y.amountOwed, 10);
                    }, 0
                );

                return totalAmount;
            };

            $scope.checkedFiveLimit = function(payableLines) {
                var checkedCount = 0;
                for (var i = 0; i < payableLines.length; i++) {
                    if (payableLines[i].isChecked) {
                        checkedCount++;
                    }
                }
                return Boolean(checkedCount >= 5);
            };

            $scope.cancel = function() {
                coremetrics.elementTag('CANCEL:CLAIMS','MEMBER:CLAIMS');
                $state.go('claims.claimsAndPayments', {}, { location: true });
            };

            $scope.next = function(payClaimForm, payTo, payFrom, linesToPay, claim) {
                coremetrics.elementTag('NEXT:CLAIMS','MEMBER:CLAIMS');
                if (payClaimForm.$valid) {
                    $state.go('^.confirm', { selectedServices: linesToPay, payTo: payTo, payFrom: payFrom, claim: claim }, { location: true });
                }
            };
        }
    ]);
};