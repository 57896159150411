module.exports = function(app) {
    app.run(['Service', function(Service) {
            new Service({
                name: 'contactUsService',
                group: "rbsmbl",
                url: "/rbsmbl/x-services/contactus/sendmessage",

                post: function (payload) {
                    return this.post(payload);
                }
            });
        }
    ])
}