/**
 * Created by LID8NTO on 11/13/2018.
 */


module.exports = function (app) {<!--Krishna Modified code start -->
    app.controller("pages.webmd", ["$scope", "services","session", function ($scope,services,session) {
            $scope.headings = "ACCOUNT SETTINGS";
            $scope.sidebar.links = [];
            $scope.submitResultFlag = false;
        //    console.log("10:::services:", services);
        //    console.log("11:::$scope:", $scope);
        //    console.log("13:::session:", session.data);
        //console.log("14::::session.data.groupNumber:::",session.data.groupNumber);
        //console.log("15::session.data.memberInfo.brand::",session.data.memberInfo.brand);
        //console.log("16::session.data.memberInfo.userName:::",session.data.memberInfo.userName);
        $scope.accountSettingsRetrieveFunction = function() {
          //  console.log("18>>>>>>>>>>>>>>>>>>accountSettingsRetrieveFunction>>>>>>>>>>>>>>");
            //services.rbsmbl.AccountSettingsRetrive().retriveAccountSettings("10280016", "Y", "NDBCBS", "PALAKGARWAL").then(function (response) {
            services.rbsmbl.AccountSettingsRetrive().retriveAccountSettings(session.data.groupNumber, "Y", session.data.memberInfo.brand, session.data.memberInfo.userName)
                .then(function (accountSettingsData) {
                 //   console.log("21:AccountSettingsSave:accountSettingsData.data.payload>11>:" + accountSettingsData.data.payload);
                    if (accountSettingsData && accountSettingsData.data && accountSettingsData.data.payload) {
                        $scope.accountRetrieveData = accountSettingsData.data.payload;
                        var acctData = accountSettingsData.data.payload;
                        console.log("25:acctData:::" + acctData);
                        $scope.homePhone = acctData.homePhone;
                        $scope.workPhone = acctData.workPhone;
                        $scope.mobilePhone = acctData.mobilePhone;
                        $scope.emailAddress = acctData.emailAddress;
                        //console.log("30:$scope.homePhone:::" + $scope.homePhone);
                        //console.log("30:$scope.selectedSendClaimsToPersonalHealthRecord:::" + $scope.accountRetrieveData.selectedSendClaimsToPersonalHealthRecord);
                        //console.log("33::::button:::",document.getElementById("wellnesssubmit").disabled);
                        //if(!(document.getElementById("wellnesssubmit").disabled)){
                        //    document.getElementById("wellnesssubmit").disabled =true;
                        //}
                        //$scope.accountRetrieveData.selectedSendClaimsToPersonalHealthRecord ="";
                        if($scope.accountRetrieveData.selectedSendClaimsToPersonalHealthRecord==="NY"){
                         //   console.log("34:NY::" );
                            document.getElementById("NY").checked = true;
                        }
                        else if($scope.accountRetrieveData.selectedSendClaimsToPersonalHealthRecord==="NO"){
                           // console.log("38:NO:::" );
                            document.getElementById("NO").checked = true;
                        }
                    }
                    //console.log("13:::response.data.payload:", response.data.payload);
                    //$scope.payload1 = response.data.payload;
                    //console.log("13:: $scope.payload1>>", $scope.payload1);
                    //document.getElementById("NY").checked = true;
                    //console.log("13:: $scope.selectedSendClaimsToPersonalHealthRecord>>", $scope.payload1.selectedSendClaimsToPersonalHealthRecord);
                    // document.getElementById("NY").checked
                    //if(response && response.data && response.data.payload){
                    //    //var result = response.data.payload;
                    //    console.log("12:::11;AccountSettingsSave:response.data.payload>11>:" + response.data.payload);
                    //    //console.log("12:::;AccountSettingsSave:result>>:" + JSON.stringify(result));
                    //    //console.log("17::::",JSON.stringify(result).selectedSendClaimsToPersonalHealthRecord);
                    //}

                });
        };
        $scope.accountSettingsRetrieveFunction();
            $scope.sidebar.links.push({
                heading: "WELLNESS PROGRAMS",
                label: "WELLNESS PROGRAMS",
                active: true,
                description: "Please review your Wellness Program Settings.",
                state: "webmd"
            }, {
                heading: "ACCOUNT SETTINGS",
                label: "ACCOUNT SETTINGS",
                active: false,
                onClick: function () {
                    window.location.href = "/mbr/#!/account/membership-information";
                }
            });
$scope.radiobuttonChange = function() {
         //   console.log("78:::radion changed ::::::Successfully:::",document.getElementById("wellnesssubmit").disabled);
    document.getElementById("wellnesssubmit").disabled = false;
   // console.log("80:::radion changed ::::::Successfully:::",document.getElementById("wellnesssubmit").disabled);
        }
            $scope.submit = function () {<!--Krishna Modified code start -->
             //   console.log("79:::$scope.submitResultFlag:::",$scope.submitResultFlag);
             //   console.log("80:::$scope.wellnesssubmit:::",document.getElementById("wellnesssubmit").disabled);
                document.getElementById("wellnesssubmit").disabled = true;
                $scope.submitResultFlag = true;
              //  console.log("79::Submitted:::$scope.submitResultFlag:::",$scope.submitResultFlag);
                //console.log("78>>", document.querySelector('input[name="rate"]:checked').value);
                //console.log("79:$scope.selectedSendClaimsToPersonalHealthRecord:::" + $scope.accountRetrieveData.selectedSendClaimsToPersonalHealthRecord);
                //console.log("63::session.data.memberInfo.userName:::",session.data.memberInfo.userName);
                var payload = {
                    "brand": session.data.memberInfo.brand,
                    "groupNumber":session.data.groupNumber,
                    "address1":$scope.accountRetrieveData.address1,
                    "address2":$scope.accountRetrieveData.address2,
                    "address3":$scope.accountRetrieveData.address3,
                    "address4":$scope.accountRetrieveData.address4,
                    "city":$scope.accountRetrieveData.city,
                    "state":$scope.accountRetrieveData.state,
                    "zip":$scope.accountRetrieveData.zip,
                    "mobilePhone":$scope.accountRetrieveData.mobilePhone,
                    "selectedSendClaimsToPersonalHealthRecord":document.querySelector('input[name="rate"]:checked').value,
                    "intakeRequest":{
                        "bdUserId":session.data.memberInfo.bvUserId,
                        "contactFirstName":session.data.memberInfo.clientList[0].groupList[0].productList[0].memberList[0].contact.firstName,
                        "contactLastName":session.data.memberInfo.clientList[0].groupList[0].productList[0].memberList[0].contact.lastName,
                        "memberFirstName":session.data.memberInfo.firstName,
                        "memberLastName":session.data.memberInfo.lastName,
                        "birthDate":session.data.memberInfo.clientList[0].groupList[0].productList[0].memberList[0].contact.birthDate,
                        "gender":session.data.memberInfo.clientList[0].groupList[0].productList[0].memberList[0].gender,
                        "relationshipCode":session.data.memberInfo.clientList[0].groupList[0].productList[0].memberList[0].relationshipCode,
                        "enrollmentSourceCode":session.data.memberInfo.clientList[0].enrollmentSourceCode,
                        "ecId":session.data.memberInfo.clientList[0].groupList[0].productList[0].memberList[0].ecId,
                        "umi":session.data.memberInfo.umi,
                        "lob":session.data.memberInfo.clientList[0].groupList[0].productList[0].lob,
                        "alphaPrefix":session.data.memberInfo.clientList[0].groupList[0].productList[0].alphaPrefix,
                        "groupNumber":session.data.memberInfo.clientList[0].groupList[0].groupNumber,
                        "benefitPlanName":session.data.memberInfo.clientList[0].groupList[0].productList[0].benefitPlanName,
                        "coverageBeginDate":session.data.memberInfo.clientList[0].groupList[0].productList[0].coverageBeginDate,
                        "coverageEndDate":session.data.memberInfo.clientList[0].groupList[0].productList[0].coverageEndDate,
                        "agreementId":session.data.memberInfo.agreementId,
                        "clientName":session.data.memberInfo.clientList[0].clientName,
                        "clientId":session.data.memberInfo.clientList[0].clientId,
                        "coveredIndividualId":session.data.memberInfo.clientList[0].groupList[0].productList[0].memberList[0].coveredIndividualId,
                        "internalGroupNumber":session.data.memberInfo.clientList[0].groupList[0].internalGroupNumber
                    },
                    "homePhone":$scope.accountRetrieveData.homePhone,
                    "workPhone":$scope.accountRetrieveData.workPhone
                };
                ///console.log("44:::payload:",payload);
                //services.rbsmbl.AccountSettingsRetrive().retriveAccountSettings(1321321,"Helloo","NDBCBS","palakagarwal").
                //    then(function(response){
                //    console.log(response);
                //});
                services.rbsmbl.AccountSettingsRetrive()
                    .saveAccountSettings(payload, "ccsAccountSettings", session.data.memberInfo.brand, session.data.memberInfo.userName)
                    .then(function(accountSettingsDataResponse) {
                        //console.log("114::accountSettingsDataResponse::",accountSettingsDataResponse);
                        //if(requestFrom == "eCCS"){
                        //    var timer = setTimeout(function() {
                        //        document.getElementById("eCCSHide").style.display = "none";
                        //        $scope.eCCSNumberField = "";
                        //    }, 3000);
                        //    $scope.eCCSSucces = true;
                        //}
                        //else{
                        //    var timer = setTimeout(function() {
                        //        document.getElementById("CCSHide").style.display = "none";
                        //        $scope.commonNumberField = "";
                        //    }, 3000);
                        //    $scope.CCSSucces = true;
                        //}
                        $scope.accountSettingsRetrieveFunction();
                    });
            }

    }]);<!--Krishna Modified code end -->

    return [
        {
            state: "webmd",
            url: "/webmdpreferences",
            resolve: {
                resourcesList: ["contentResourceServices", function(contentResourceServices){
                    var CONTENT_TYPE = "Bluecore Resources";
                    return contentResourceServices.getResourceIdListAsync(CONTENT_TYPE);
                }]
            },
            postPassword: true,
            views: {
                "body@": {
                    template: bootstrap.templates["page-templates/categoryCustom.html"],
                    controller: "pages.webmd"
                },
                "content@webmd": {
                    template: require("./webmdpreferences.html")
                }
            }
        },
    ]
};