module.exports = function (app) {
    app.directive('loginBox2', ['brand', '$cookies', 'session', function (brand, $cookies, session) {

        return {
            restrict: 'A',
            replace: true,
            template: require('directives/forms-library/prePwdFormLogin.html'),
            scope: {
                loggingIn: '=',
                showInvalidUserPass: '=',
                errorText: '=',
                loginInfo: '=',
                loginBoxClass: '=?',
                forgotAction: '&',
                customHeading: '@'
            },

            link: function ($scope, element, attrs, ctrls) {
                //smart message section check
                $scope.smartMessage = (brand.current.sharedBrandCode.toLowerCase() === 'mincr' || brand.current.sharedBrandCode.toLowerCase() === 'ndbcbs' || brand.current.sharedBrandCode.toLowerCase() === 'wybcbs') ? true : false;
                if (brand.current.sharedBrandCode.toLowerCase() === 'ndbcbs') {
                    $scope.whyRegisterHref = "#";
                    $scope.registrationText="Remember my username";
                }
                else if (brand.current.sharedBrandCode.toLowerCase() === 'wybcbs') {

                    $scope.whyRegisterHref = "https://www.bcbswy.com/members/whatsinside/";
                    $scope.registrationText="Remember my username";
                }
                else {
                    $scope.whyRegisterHref = "https://www.bluecrossmn.com/hosted/site-change-info";
                    $scope.registrationText="Remember your username";
                }
                $scope.siteOutage = session.data.siteOutage;
                $scope.loginInfo.username = $cookies.get('rememberUsername');
                $scope.loginInfo.rememberUsername = Boolean($scope.loginInfo.username);

                $scope.$watch('loginInfo.rememberUsername', function (v) {
                    if (v) {
                        $cookies.put('rememberUsername', $scope.loginInfo.username);
                    }
                });

                $scope.$watch('loginInfo.username', function (v) {
                    if ($scope.loginInfo.rememberUsername) {
                        $cookies.put('rememberUsername', $scope.loginInfo.username);
                    }
                });

                //    TODO: remove me once Provider updates header. Because this is horrible but completely impossible to configure this widget otherwise.
                $scope.newForgotPassword = $scope.$parent.$parent.newForgotPassword;
            },
            controller: ['$scope','services','invisibleForm', function ($scope, services, invisibleForm) {
                $scope.customHeading = $scope.customHeading || "Log in to your account";
                $scope.performLogin = function () {
                    /*if ($scope.config.login && typeof $scope.config.login === "function") {
                        return $scope.config.login($scope.signInRegisterForm);
                    }*/
                    $scope.loggingIn = true;
                    $scope.showInvalidUserPass = false;
                    // $scope.postLoginAction = $scope.postLoginAction || function () {};
                    services.rbsmbl.loginversion().fetch($scope.loginInfo.username).then(function (response) {
                        if (response && response.data && response.data.payload) {
                            if (response.data.payload.loginVersion == 'MER_LOGIN_V1') {
                                services.rbsmbl.login().loginWithRedirect($scope.loginInfo.username, response.data.payload.convertCase ? $scope.loginInfo.password.toUpperCase() : $scope.loginInfo.password, null, null, true).then(function (response2) {

                                    coremetricsConversionTracker.finish('LOGIN', 'LOGIN');
                                    // Yay
                                    $scope.loggingIn = false;
                                }, function (error) {
                                    $scope.loginInfo.username = "";
                                    $scope.loginInfo.password = "";
                                    $scope.loggingIn = false;
                                    $scope.loginErrorContent = error.specificErrorContent;

                                    // If the error wasn't just due to a wrong user/pass combo
                                    $scope.showLoginBox = $scope.showMobileLoginBox = false;
                                    $scope.showLoginMessage = true;

                                    // If error was simply due to wrong username or password, hide the loginMessageBox and display the error inside the login form
                                    if (error.data && error.data.baseMessage) {
                                        switch (error.data.baseMessage.returnCodeDescription) {
                                            case "GEN-NORECCHMECS":
                                            case "GEN-NOMATCH":
                                            case "USER-NOT-ON-FILE":
                                                $scope.showLoginBox = $scope.showMobileLoginBox = true;
                                                $scope.showInvalidUserPass = true;
                                                $scope.showLoginMessage = false;
                                        }
                                    }
                                });
                            } else if (response.data.payload.loginVersion === 'BV_LOGIN') {

                                if (response.data.payload.convertCase === true) {
                                    $scope.loginInfo.username = $scope.loginInfo.username.toUpperCase();
                                    $scope.loginInfo.password = $scope.loginInfo.password.toUpperCase();
                                }
                                services.wcs.ltpalogin({"storeId": brand.current.storeId}).postData({},
                                    {
                                        username: $scope.loginInfo.username,
                                        password: $scope.loginInfo.password
                                    }).then(function (response) {
                                        session.start().then(function () {
                                            var returnDefer = $q.defer();
                                            returnDefer.resolve(response);
                                        });
                                        coremetricsConversionTracker.finish('LOGIN', 'LOGIN');
                                        $scope.loggingIn = false;
                                    },
                                    function (error) {
                                        $scope.loginInfo.username = "";
                                        $scope.loginInfo.password = "";
                                        $scope.loggingIn = false;
                                        if (error.data && error.data.baseMessage) {
                                            $scope.loginErrorContent = services.rbsmbl.login().getErrorMessage(error.data.baseMessage.returnCodeDescription) || {};
                                        } else {
                                            $scope.loginErrorContent = {};
                                        }

                                        // If the error wasn't just due to a wrong user/pass combo
                                        $scope.showLoginBox = $scope.showMobileLoginBox = false;
                                        $scope.showLoginMessage = true;

                                        // If error was simply due to wrong username or password, hide the loginMessageBox and display the error inside the login form
                                        if (error.data && error.data.baseMessage) {
                                            switch (error.data.baseMessage.returnCodeDescription) {
                                                case "GEN-NORECCHMECS":
                                                case "GEN-NOMATCH":
                                                case "USER-NOT-ON-FILE":
                                                    $scope.showLoginBox = $scope.showMobileLoginBox = true;
                                                    $scope.showInvalidUserPass = true;
                                                    $scope.showLoginMessage = false;
                                            }
                                        }
                                    });
                            }
                            else {
                                $scope.loggingIn = false;
                            }
                        }
                        else {
                            // TODO FIXME handle error gracefully
                            $scope.loggingIn = false;
                        }
                    }, function (error) {
                        // TODO FIXME handle error
                        $scope.loggingIn = false;
                    });
                };

                $scope.performRegister = function () {
                    if ($scope.config.register && typeof $scope.config.register === "function") {
                        return $scope.config.register();
                    }
                };
                $scope.show = function () {
                    if ($scope.config.show && typeof $scope.config.show === "function") {
                        return $scope.config.show();
                    }

                    return true;
                };
            }]
        };
    }]);
};