module.exports = ['$scope', "$window", '$http', function($scope, $window, $http) {
    $scope.redirectToContact = function(){
        $scope.closeModal();
        location.replace("/msg/");
    };
    $scope.closeModal = function($event){
    	$scope.closeModal();
        location.replace("/msg/");
    };
}];

  