module.exports = function(bootstrap) {
    bootstrap.run(['Service', '$q', function (Service, $q) {
        new Service({
            name: 'benefitSearch',
            group: 'rbsmbl',
            url: '/rbsmbl/x-services/secure/benefits/v1/benefitSearch',

            postSearch: function (params) { // Wasn't calling this because we called it post.
                var opt = params || {};
                var payload = {"payload": opt};

                this.url = '/rbsmbl/x-services/secure/benefits/v1/benefitSearch';
                return this.post(payload);
            },

            getAutoComplete: function (keyword) {
                this.url = '/rbsmbl/x-services/secure/benefits/v1/autocomplete?keyword=' + keyword;
                return this.get();
            },

            findAllBenefits: function () {

                this.url = '/rbsmbl/x-services/secure/benefits/v1/findAllBenefits';
                return this.get()
            }
        });
    }]);
};