module.exports = function(app){
	app.directive('cmsImg', ['teamsite', function(teamsite){
		return {
			restrict: 'A',
			scope: {
				contentId: '@cmsImg'
			},
			link: function($scope, element, attrs){
				if(element[0].tagName !== 'IMG'){
					console.error('You cannot use the cmsImg directive on non-image tags.');
				} else {
					teamsite.get($scope.contentId).then(function(response){
						var image = JSON.parse(response);

						attrs.$set('src', image['src']);
						if ([null, ''].indexOf(image['src']) > -1) {
							attrs.$set('alt', image['alt']);
						}
					});
				}
			}
		};
	}]);
};