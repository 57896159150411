module.exports = function(app) {

    app.controller('pages.claims.claimsAndPayments', ['sessionData', '$scope', '$rootScope', '$q', '$cookies', 'claimService', 'services', '$timeout', 'storage', 'coremetrics', '$filter', 'updateVisited', 'session', 'waysToSaveUtil','$location',
        function(sessionData, $scope, $rootScope, $q, $cookies, claimService, services, $timeout, storage, coremetrics, $filter, updateVisited, session, waysToSaveUtil,$location) {


            $scope.headings = "Claims And Payments";
            $scope.viewState = 'loading';
            $scope.sidebar.members = [];
            $scope.sidebar.claimTypes = [];
            $scope.sidebar.statusTypes = [];
            $scope.sidebar.paymentStatusTypes = [];
            $scope.pagination = {
                itemsPerPage: '10'
            };
            window.document.title = "Claims and payments";
            $scope.pagination.page = 1;
            $scope.$watch('pagination.page', function(v) {
                storage.set('claimsRefinePage', v);
            });
            $scope.$watch('summary', function(v) {
                if (v && v.length === 0) {
                    services.rbsmbl.capabilityInfo().persistMTMInfo('', 'claimSummary');
                }
            });

            $scope.showWaysToSave = waysToSaveUtil.showWaysToSave();

            var isClicked;
            $scope.$on('drawerOpen', function() {
                if(!isClicked){
                    coremetrics.elementTag('CLAIM DETAIL EXPAND','MEMBER:CLAIMS');
                    isClicked = true;
                }
            });

            $scope.$on('prevClick', function() {
                var prevPage = $scope.pagination.page;
                prevPage -= 1;
                coremetrics.pageviewTag('CLAIMS:CLAIMS AND PAYMENTS '+prevPage,'MEMBER:CLAIMS');
                coremetrics.elementTag('PREV:CLAIMS','MEMBER:CLAIMS');
            });

            $scope.$on('nextClick', function() {
                var nextPage = $scope.pagination.page;
                nextPage += 1;
                coremetrics.pageviewTag('CLAIMS:CLAIMS AND PAYMENTS '+nextPage,'MEMBER:CLAIMS');
                coremetrics.elementTag('NEXT:CLAIMS','MEMBER:CLAIMS');
            });

            $scope.$on('goToPageClick', function() {
                coremetrics.elementTag('SELECT PAGE NUMBER:CLAIMS','MEMBER:CLAIMS');
            });

            var returnFromPay = storage.get('payClaimBackButtonDetails');
            if (returnFromPay) {
                $scope.pagination.page = storage.get('claimsRefinePage') || 1;
                $timeout(function() {
                    $('html, body').animate({scrollTop: returnFromPay.scrollTop}, 1); // Cross browser friendly way to control scroll top.  Duration of 1ms because we don't want flicker.
                }, 500);
            }

            function updateFilter(original, updated, keyCheck) {
                var retVal = angular.copy(updated);
                for (var i = 0; i < original.length; i++) {
                    for (var x = 0; x < retVal.length; x++) {
                        if (angular.equals(original[i][keyCheck], retVal[x][keyCheck])) {
                            retVal[x].value = original[i].value;
                        }
                    }
                }
                return retVal;
            }

            function lowerTierOrOONResultsExist(){

                var deferred = $q.defer();

                waysToSaveUtil.getCsbParams().then(
                    function(csbParams){
                        var csbPromise = services.rbsmbl.costShareBenefit().fetch(csbParams),
                            costShareBenefit,
                            responseObj = {
                                usedOONProvider: false,
                                usedLowerTierProvider: false
                            },
                            claimResponse,
                            i = 0;

                        csbPromise.then(
                            function(response) {
                                costShareBenefit = response.data.payload;

                                if(waysToSaveUtil.memberEligibleForWaysToSave(costShareBenefit)){
                                    $scope.bestTier = waysToSaveUtil.getBestTierAvailable(costShareBenefit);
                                    $scope.bestTierText = waysToSaveUtil.getTierText($scope.bestTier);
                                    $scope.hasTiers = waysToSaveUtil.hasTiers(costShareBenefit);

                                    for(i; i<$scope.summary.length; i++){
                                        if(!waysToSaveUtil.claimEligibleForWaysToSave($scope.summary[i])){
                                            continue;
                                        }

                                        $scope.summary[i].tierPealbndtCd = $scope.summary[i].tierPealbndtCd && $scope.summary[i].tierPealbndtCd.toUpperCase() || "";
                                        $scope.summary[i].isLowerTier = false;
                                        $scope.summary[i].isOON = false;

                                        $scope.summary[i].tierText = waysToSaveUtil.getTierText($scope.summary[i].tierPealbndtCd);

                                        claimResponse = waysToSaveUtil.checkForLowerTierOrOON($scope.summary[i].pealbndtCd, $scope.summary[i].tierPealbndtCd, $scope.bestTier);

                                        $scope.summary[i].isLowerTier = claimResponse.usedLowerTierProvider;
                                        $scope.summary[i].isOON = claimResponse.usedOONProvider;

                                        if(claimResponse.usedOONProvider && !responseObj.usedOONProvider){
                                            responseObj.usedOONProvider = true;
                                        }
                                        if(claimResponse.usedLowerTierProvider && !responseObj.usedLowerTierProvider){
                                            responseObj.usedLowerTierProvider = true;
                                        }
                                    }

                                    deferred.resolve(responseObj);
                                }
                                else{
                                    deferred.reject(false);
                                }
                            },
                            function(error){
                                console.error(error);
                                deferred.reject(false);
                            }
                        );
                    },
                    function(error){
                        console.error(error);
                        deferred.reject(false);
                    }
                );

                return deferred.promise;
            }

            $scope.sidebar.filtersChanged = function() {
                $scope.pagination.page = 1;
            };
            $scope.fetchClaims = function() {
                services.rbsmbl.claimSummary().flushCache();
                $scope.sidebar.fetchClaims(storage.get('claimRefineDateRange'));
            };
            $scope.sidebar.fetchClaims = function(dateRange) {
                claimService.getSummary(dateRange).then(function(claimsData) {
                    /*Fire off refine tag once per page visit*/
                    coremetrics.elementTag('REFINE:CLAIMS AND PAYMENTS','MEMBER:CLAIMS');

                    var fullMemberInfo = session.data.memberInfo;
                    var returnFromPay = storage.get('payClaimBackButtonDetails');
                    if (claimsData.summary.spendingAccountError) {
                        $scope.viewState = 'spendingAccountError';
                    }
                    else {
                        $scope.viewState = 'success';
                    }
                    $scope.summary = claimsData.summary;

                    if($scope.summary && $scope.summary.length && $scope.showWaysToSave){
                         lowerTierOrOONResultsExist().then(
                             function(response){
                                 if(response){
                                     $scope.usedLowerTierProvider = response.usedLowerTierProvider;
                                     $scope.usedOONProvider = response.usedOONProvider;
                                 }
                             }
                         );
                    }

                        $rootScope.$broadcast('updateMyChartVisibility', $scope.summary);
                    if (returnFromPay) {
                        // If we're returning from the payment page, return user to where they left off
                        for (var i = 0; i < $scope.summary.length; i++) {
                            if ($scope.summary[i].claimNumber === returnFromPay.claimNumber) {
                                $scope.summary[i].isOpen = returnFromPay.isOpen;
                            }
                        }
                        // And then destroy the evidence
                        storage.del('payClaimBackButtonDetails');
                    }else if($location.search().clmNum){
                        var claimMatch = false;
                        for (var i = 0; i < $scope.summary.length; i++) {
                            if ($scope.summary[i].claimNumber.trim() === $location.search().clmNum.trim()) {
                                claimMatch=true;
                                $scope.summary[i].isOpen = true;
                                var pageNum = Math.ceil((i+1)/$scope.pagination.itemsPerPage);
                                $scope.pagination.page = pageNum;
                                break;
                            }
                        }
                        if(!claimMatch){
                            if (!$cookies.get("visitedClaimsThisSession")) {
                                $cookies.put("visitedClaimsThisSession", true);
                                // FIXME: this opens claim 1, which might not be the 1st claim on the page due to filters/pagination
                                // The whole idea of opening the first claim is flawed, we need to talk with UX about what they expect
                                // My suggestion is that we open the 1st claim once, and reset the counter when the user logs out
                                if ($scope.summary[0]) {
                                    // Autoopen first claim feature is no longer necessary in the latest patterns
                                    $scope.summary[0].isOpen = true;
                                }
                            }
                        }
                    }
                    else if (!$cookies.get("visitedClaimsThisSession")) {
                        $cookies.put("visitedClaimsThisSession", true);
                       // FIXME: this opens claim 1, which might not be the 1st claim on the page due to filters/pagination
                       // The whole idea of opening the first claim is flawed, we need to talk with UX about what they expect
                       // My suggestion is that we open the 1st claim once, and reset the counter when the user logs out
                       if ($scope.summary[0]) {
                           // Autoopen first claim feature is no longer necessary in the latest patterns
                           $scope.summary[0].isOpen = true;
                       }
                    }

                    if (!$scope.firstLoad) {
                        // If it's not the first load, then this code is running because the user changed the date range, so put them back on page 1
                        $scope.pagination.page = 1;
                    }

                    $scope.sidebar.members = updateFilter($scope.sidebar.members, claimService.findMembers(claimsData.summary, fullMemberInfo), 'data');
                    $scope.sidebar.statusTypes = updateFilter($scope.sidebar.statusTypes, claimService.findStatusTypes(claimsData.summary, fullMemberInfo), 'name');
                    $scope.sidebar.paymentStatusTypes = updateFilter($scope.sidebar.paymentStatusTypes, claimService.findPaymentStatusTypes(claimsData.summary), 'name');
                    $scope.sidebar.claimTypes = updateFilter($scope.sidebar.claimTypes, claimService.findClaimTypes(claimsData.summary, fullMemberInfo), 'name');
                    $timeout(function() {
                        $scope.firstLoad = false;
                    }, 10);
                },
                function(err) {
                    $scope.viewState = 'error';
                    console.error('getSummary failed with error', err);
                });
            };
            $scope.firstLoad = true;

            $scope.sidebar.dateRangeOptions = [
                { label: "Last 12 Months", value: JSON.stringify({startDate: $filter('date')(new Date() - 365 * 60 * 60 * 24 * 1000, 'yyyyMMdd'), endDate: $filter('date')(new Date(), 'yyyyMMdd')}) },
                { label: "Last 6 Months", value: JSON.stringify({startDate: $filter('date')(new Date() - 180 * 60 * 60 * 24 * 1000, 'yyyyMMdd'), endDate: $filter('date')(new Date(), 'yyyyMMdd')}) },
                { label: "Last 90 Days", value: JSON.stringify({startDate: $filter('date')(new Date() - 90 * 60 * 60 * 24 * 1000, 'yyyyMMdd'), endDate: $filter('date')(new Date(), 'yyyyMMdd')}) },
                { label: "Last 60 Days", value: JSON.stringify({startDate: $filter('date')(new Date() - 60 * 60 * 60 * 24 * 1000, 'yyyyMMdd'), endDate: $filter('date')(new Date(), 'yyyyMMdd')}) },
                { label: "Last 30 Days", value: JSON.stringify({startDate: $filter('date')(new Date() - 30 * 60 * 60 * 24 * 1000, 'yyyyMMdd'), endDate: $filter('date')(new Date(), 'yyyyMMdd')}) }
            ];

            var value = storage.get('claimRefineDateRange');
            if (value) {
                var parsedValue = JSON.parse(value);
                var label = parsedValue.startDate.substr(4,2) + '/' + parsedValue.startDate.substr(6,2) + '/' + parsedValue.startDate.substr(0,4) + ' - ' + parsedValue.endDate.substr(4,2) + '/' + parsedValue.endDate.substr(6,2) + '/' + parsedValue.endDate.substr(0,4);

                var found = false;
                for (var i = 0; i < $scope.sidebar.dateRangeOptions.length; i++) {
                    if ($scope.sidebar.dateRangeOptions[i].value === value) {
                        $scope.sidebar.dateRange = value;
                        found = true;
                    }
                }
                if (!found) {
                    $scope.sidebar.dateRangeOptions.push({ label: label, value: value });
                    $scope.sidebar.dateRange = value;
                }
            }
            else {
                $scope.sidebar.dateRange = $scope.sidebar.dateRangeOptions[0].value;
            }

            $scope.$watch('sidebar.dateRange', function(r) {
                var i = $scope.sidebar.dateRangeOptions.length;
                while (i--) {
                    if ($scope.sidebar.dateRangeOptions[i].value === r) {
                        if (i < 5) {
                            $scope.sidebar.dateRangeOptions.splice(5, 1);
                        }
                    }
                }
                $scope.sidebar.fetchClaims($scope.sidebar.dateRange);
                $scope.maximumDateRange = $scope.sidebar.dateRange === $scope.sidebar.dateRangeOptions[0].value;
            });

            $scope.claimsFilter = function(claim) {
                var visible = $scope.claimsNameFilter(claim) && $scope.claimsTypeFilter(claim) && $scope.claimsStatusFilter(claim) && $scope.claimsPaymentStatusFilter(claim);
                claim.visible = visible;
                return visible;
            };
            $scope.claimsNameFilter = function(claim) {
                var checkedCount = 0;
                for (var i = 0; i < $scope.sidebar.members.length; i++) {
                    if ($scope.sidebar.members[i].value) {
                        checkedCount++;
                    }
                }
                if (checkedCount === 0 || checkedCount === $scope.sidebar.members.length) {
                    // If none are checked, or all are checked, everything should pass the filter
                    return true;
                }
                for (var i = 0; i < $scope.sidebar.members.length; i++) {
                    var item = $scope.sidebar.members[i];
                    if ((item.data.mbrFirstNm.toLowerCase() === claim.mbrFirstNm.toLowerCase()) && (item.data.mbrLastNm.toLowerCase() === claim.mbrLastNm.toLowerCase()) && (item.data.mbrBirthDate === claim.mbrBirthDate)) {
                        return Boolean(item.value);
                    }
                }
            };
            $scope.claimsTypeFilter = function(claim) {
                var checkedCount = 0;
                for (var i = 0; i < $scope.sidebar.claimTypes.length; i++) {
                    if ($scope.sidebar.claimTypes[i].value) {
                        checkedCount++;
                    }
                }
                if (checkedCount === 0 || checkedCount === $scope.sidebar.claimTypes.length) {
                    // If none are checked, or all are checked, everything should pass the filter
                    return true;
                }
                var check = '';
                switch(claim.sysSrcCode) {
                    case 'medical':
                        check = 'Medical';
                        break;
                    case 'dental':
                        check = 'Dental';
                        break;
                    case 'vision':
                        check = 'Vision';
                        break;
                    case 'ods':
                        check = 'Drug';
                        break;
                }
                for (var i = 0; i < $scope.sidebar.claimTypes.length; i++) {
                    var item = $scope.sidebar.claimTypes[i];
                    if (check === item.name) {
                        return Boolean(item.value);
                    }
                }
            };
            $scope.claimsStatusFilter = function(claim) {
                var checkedCount = 0;
                for (var i = 0; i < $scope.sidebar.statusTypes.length; i++) {
                    if ($scope.sidebar.statusTypes[i].value) {
                        checkedCount++;
                    }
                }
                if (checkedCount === 0 || checkedCount === $scope.sidebar.statusTypes.length) {
                    // If none are checked, or all are checked, everything should pass the filter
                    return true;
                }
                for (var i = 0; i < $scope.sidebar.statusTypes.length; i++) {
                    var item = $scope.sidebar.statusTypes[i];
                    if (claim.bilgProvClmStaCd === item.data.bilgProvClmStaCd) {
                        return Boolean(item.value);
                    }
                }
            };

            $scope.claimsPaymentStatusFilter = function(claim) {
                var checkedCount = 0;
                for (var i = 0; i < $scope.sidebar.paymentStatusTypes.length; i++) {
                    if ($scope.sidebar.paymentStatusTypes[i].value) {
                        checkedCount++;
                    }
                }
                if (checkedCount === 0) { //|| checkedCount === $scope.sidebar.paymentStatusTypes.length) {
                    // If none are checked, or all are checked, everything should pass the filter
                    return true;
                }
                for (i = 0; i < $scope.sidebar.paymentStatusTypes.length; i++) {
                    var item = $scope.sidebar.paymentStatusTypes[i];
                    if (claim.partiallyPaid === item.data.partiallyPaid) {
                        return Boolean(item.value);
                    }
                }
            };

            updateVisited.claimsAndPayments();
        }
    ]);
};
