module.exports = function(app) {
    app.controller('claims.claimsAndPayments.partial.payClaim', ['$scope', '$state', 'claimService', 'partialPayService', 'coremetrics', 'storage',
        function($scope, $state, claimService, partialPayService, coremetrics, storage) {
            $scope.viewState = 'loading';
            $scope.payToOptions = [];
            $scope.payToSelection;
            $scope.toPayClaims;
            $scope.claimNumber=$state.params.claimNumber;
            console.log('partialPay claim',$state.params.claimNumber);

            var selectPreviousPayTo = function() {
                if ($state.params.selectedPayTo) {
                    for (var i = 0; i < $scope.payToOptions.length; i++) {
                        if ($state.params.selectedPayTo.value === $scope.payToOptions[i].value) {
                            $scope.payToSelection = $scope.payToOptions[i];
                            break;
                        }
                    }
                }
            };

            var _configurePayToOptions = function(claimDetails, accountsList) {
                var
                    payModes,
                    hasSelf,
                    hasOther,
                    hasBoth,
                    optionForSelf,
                    optionForOther;

                optionForSelf = { label: 'Myself', value: 'self' };
                optionForOther = { label: 'Provider', value: 'provider' };

                payModes = claimDetails.map(function(detail) {
                    return detail.payMode;
                });

                hasSelf = payModes.some(function(payMode) {
                    return payMode === 'self';
                });

                hasOther = payModes.some(function(payMode) {
                    return payMode === 'other';
                });

                hasBoth = payModes.some(function(payMode) {
                    return payMode === 'both';
                });

                if (hasBoth || (hasSelf && hasOther)) {
                    accountsList.push(optionForSelf);
                    accountsList.push(optionForOther);
                    return;
                }
                if (hasSelf) {
                    accountsList.push(optionForSelf);
                    return;
                }
                if (hasOther) {
                    accountsList.push(optionForOther);
                    return;
                }
            };

            claimService.getSummary(storage.get('claimRefineDateRange')).then(
                function(claimsData) {
                    var claim;
                    for (var i = 0, len = claimsData.summary.length; i < len; ++i) {
                        if (claimsData.summary[i].claimNumber === $state.params.claimNumber) {
                            $scope.claim = claim = claimsData.summary[i];
                            //console.log("selected claim : ", claim);
                            // TODO: THIS IS AN ASYNC METHOD JUST FLOATING HERE ALL SUSPECT. Should this be inlined or $q.all-ed?
                            // There is currently no way to determine an overall error or success state for this page. If there
                            // is a discrepancy, the error state determination is a race.
                          //  _getPayFromAccount(claim.clientId, claim.claimNumber);
                            $scope.clientNumber=claim.clientId;
                            claimService.getDetails(claim).then(
                                function(details) {
                                //    console.log('pay claim detail', details);
                                    $scope.toPayClaims=[];
                                    for(var line=0;line<details.length;line++){
                                        var serviceLine={};
                                        serviceLine.procedureShortDesc=details[line].procedureShortDesc;
                                        serviceLine.procedureCode=details[line].procedureCode;
                                        serviceLine.memberCost=details[line].memberCost;
                                        var paymentList=details[line].acclarisPaymentList;
                                        serviceLine.showPayment=false;
                                        if(details[line].acclarisPaymentList){
                                            for(var index=0;index<paymentList.length;index++){
                                                var
                                                    payment=paymentList[index];
                                                    //below property will be used for display in ui
                                                    payment.isPartiallyPaid=Boolean(payment.partialPaymentInfo),
                                                    payment.isPaid=typeof payment.paymentStatus==='string'&& payment.paymentStatus.toLowerCase()==='paid',
                                                    payment.isPending=typeof payment.paymentInfo==='string'&& payment.paymentInfo.toLowerCase().match(/pending|in process/)||false;

                                                    if(!serviceLine.showPayment && (payment.isPartiallyPaid||payment.isPaid||payment.isPending)){
                                                        serviceLine.showPayment=true;
                                                    }
                                                   paymentList[index]=payment;
                                            }
                                         }
                                        serviceLine.acclarisPaymentList=paymentList;

                                        $scope.toPayClaims.push(serviceLine);
                                    }
                                   // console.log('claims to pay ',$scope.toPayClaims)
                                    _configurePayToOptions(details, $scope.payToOptions);
                                     selectPreviousPayTo();

                                    $scope.payableLines = details.filter(function(line) {
                                        return line.isPayable;
                                    });
                                    //console.log('payable lines', $scope.payableLines);
                                    //medical,dental,vision and drug claims are considered as medical eligible
                                    var isMedicalEligible=_isMedicalEligible(claim.claimType);
                                    _estimatePay($scope.payableLines,claim.clientId,isMedicalEligible,claim.bilgProvSvcBgnDt);
                                    $scope.viewState = 'success';
                                },

                                function(err) {
                                    $scope.viewState = 'error';
                                    console.error('claimDetails.getDetails failed within payClaim', err);
                                    return err;
                                }
                            );
                            break;
                        }
                    }
                },
                function(err) {
                    $scope.viewState = 'error';
                    console.error('getSummary failed with error', err);
                }
            );

            var _estimatePay=function(payableLines, clientNumber,isMedicalEligible,claimsDateOfService){

                //TODO: need to identify how much time it will take for payment to happen
                //clear account Bal from scope
                $scope.accountBal= parseFloat(0.0);
                //clientNumber=198760;
              //  isMedicalEligible=false;
                partialPayService.getSpendingAccount(clientNumber,isMedicalEligible,claimsDateOfService).then(
                    function(partialPayAccount){
                        console.log("partialPayAccount ",partialPayAccount);
                        $scope.accountBal=parseFloat(partialPayAccount.currentBalance);
                        $scope.accountDisplayName=partialPayAccount.accountDisplayName;
                        payableLines = payableLines || [];
                        var remainingBal = payableLines.reduce(  //TODO: pendingAmount deduction
                            function (x, y) {
                               // console.log('amount owed ',y.amountOwed);
                                return x + parseFloat(y.amountOwed);
                            }, 0
                        );
                        //testing
                        $scope.remainingBal=parseFloat(remainingBal.toFixed(2));
                        if ($scope.accountBal > $scope.remainingBal) {
                            $scope.max = $scope.remainingBal;
                        } else {
                            $scope.max = $scope.accountBal;
                        }
                        if ($state.params.submittedPayment) {
                            $scope.payment = $state.params.submittedPayment;
                        } else {
                            $scope.payment = $scope.max;
                        }
                    }
                );
            };


            $scope.cancel = function() {
                coremetrics.elementTag('CANCEL:CLAIMS','MEMBER:CLAIMS');
                $state.go('claims.claimsAndPayments', {}, { location: true });
            };

            $scope.next = function(payClaimForm,payTo, payFrom, claimNumber, partialPayAmt, remainingBal,clientNumber,claim) {
                coremetrics.elementTag('NEXT:CLAIMS','MEMBER:CLAIMS');
                if (payClaimForm.$valid) {
                    $state.go('^.partialPayConfirm', {payTo: payTo, payFrom: payFrom, claimNumber: claimNumber, partialPayAmt: partialPayAmt, remainingBal: remainingBal,clientNumber: clientNumber,claim: claim}, { location: true });
                }
            };

            var _isMedicalEligible=function(claimType){
                if(claimType.isDental || claimType.isMedical ||claimType.isVision ||claimType.isDrug){
                    return true;
                }else{
                    return false;
                };
            }
        }
    ]);
};