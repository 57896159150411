module.exports = function (app) {
    app.factory('statementArchiveService', ['services', 'session', '$q', '$filter',
        function (services, session, $q, $filter) {
            var
                _getMhsMem,
                _getSrmEob,
                _getUmiEob,
                getStatements,
                _createStatementsFromSrmEobResponse,
                _createStatementsFromMhsMemResponse,
                _createStatementsFromUmiEobResponse,
                _getApd,
                _getRealDate;


            _getRealDate = function(yyyyMMdd) {
                var year = yyyyMMdd.substring(0,4);
                var month = yyyyMMdd.substring(4,6);
                var day = yyyyMMdd.substring(6,8);

                return new Date(year, month - 1, day);
            };

            _getApd = function(apdInfo) {
                return apdInfo.reduce(function(apd, info) {
                    apd[info.name] = info.value;

                    return apd;
                }, {});
            };

            _createStatementsFromSrmEobResponse = function(dataList) {
                var filteredList = dataList.filter(function(data) {
                    if (data instanceof Object && typeof data.docTypeGroup === 'string') {
                        var docTypeGroup = data.docTypeGroup.toUpperCase();

                        return docTypeGroup === 'HMN' || docTypeGroup === 'SRM' || docTypeGroup === 'MEDC';
                    }
                });

                return filteredList.map(function(data) {
                    var apd = _getApd(data.APDInfo);
                    var beginDate = _getRealDate(apd.ST_BEGIN);
                    var endDate = _getRealDate(apd.ST_END);
                    var cycleDate = _getRealDate(apd.CYCLE_DATE);

                    var _getQueryDocType = function(docTypeGroup) {
                        return docTypeGroup.toUpperCase() === 'HMN' ? 'HMN' : 
                            docTypeGroup.toUpperCase() === 'SRM' ? 'SRM'
                            : 'COM';
                    };

                    var _getQueryEndDate = function(docTypeGroup) {
                        if (docTypeGroup.toUpperCase() === 'HMN') {
                            return $filter('date')(cycleDate, 'yyyy-MM-dd');
                        }
                        else {
                            return $filter('date')(endDate, 'yyyy-MM-dd');
                        }
                    };

                    var queryParams = {
                        pltNumber: apd.PLT,
                        SUBSCRIBER_ID: apd.UMI,
                        ODFolder: apd.FOLDER,
                        END_DATE: _getQueryEndDate(data.docTypeGroup),
                        BEGIN_DATE: $filter('date')(beginDate, 'yyyy-MM-dd'),
                        docType: _getQueryDocType(data.docTypeGroup)
                    };

                    var title = 'Quarterly Statement - ' + $filter('date')(beginDate, 'MMMM y') + ' thru ' + $filter('date')(endDate, 'MMMM y');

                    return {
                        title: title,
                        deliveryDate: $filter('date')(endDate, 'MM/dd/yyyy'),
                        endDate: $filter('date')(endDate, 'yyyyMMdd'),
                        queryParams: queryParams
                    };
                });
            };

            _createStatementsFromMhsMemResponse = function(dataList) {
                var filteredList = dataList.filter(function(data) {
                    if (data instanceof Object && typeof data.docTypeGroup === 'string') {
                        var docTypeGroup = data.docTypeGroup.toUpperCase();

                        return docTypeGroup === 'MHS';
                    }
                });

                return filteredList.map(function(data) {
                    var apd = _getApd(data.APDInfo);
                    var beginDate = _getRealDate(apd.ST_BGN_DATE);
                    var endDate = _getRealDate(apd.ST_END_DATE);

                    var queryParams = {
                        pltNumber: apd.PLT,
                        SUBSCRIBER_ID: apd.UMI,
                        ODFolder: 'MHSSTATEMENTS',
                        END_DATE: $filter('date')(endDate, 'yyyy-MM-dd'),
                        BEGIN_DATE: $filter('date')(beginDate, 'yyyy-MM-dd'),
                        docType: 'MHS'
                    };

                    var title = 'Health Statement - ' + $filter('date')(beginDate, 'MMMM y') + ' thru ' + $filter('date')(endDate, 'MMMM y');

                    return {
                        title: title,
                        deliveryDate: $filter('date')(endDate, 'MM/dd/yyyy'),
                        endDate: $filter('date')(endDate, 'yyyyMMdd'),
                        queryParams: queryParams
                    };
                });
            };

            _createStatementsFromUmiEobResponse = function(umiDataList) {
                if (!Array.isArray(umiDataList)) { return []; }

                var filteredList = umiDataList.map(function(eob) {
                    return eob.documentKeys.filter(function(document) {
                        document.docOdFolderName = eob.docOdFolderName;

                        return document instanceof Object &&
                            typeof document.docTypeCode === 'string'
                            && (document.docTypeCode.toLowerCase() === 'com' || document.docTypeCode.toLowerCase() === 'hmn'
                            	 || document.docTypeCode.toLowerCase() === 'srm');
                    });
                });

                var flattenedList = filteredList.reduce(function(documentList, document) {
                    return Array.isArray(document)
                        ? documentList.concat(document)
                        : documentList;
                }, []);

                var documents = flattenedList.map(function(document) {
                    var beginDate = _getRealDate(document.docBeginDate.split('-').join(''));
                    var endDate = _getRealDate(document.docEndDate.split('-').join(''));
                    var statementDate = _getRealDate(document.docKey3Value);

                    var queryParams = {
                        pltNumber: document.docKey2Value,
                        SUBSCRIBER_ID: document.docKey1Value,
                        ODFolder: document.docOdFolderName,
                        END_DATE: $filter('date')(endDate, 'yyyy-MM-dd'),
                        BEGIN_DATE: $filter('date')(beginDate, 'yyyy-MM-dd'),
                        docType: document.docTypeCode.toUpperCase()
                    };

                    var title = "";
                    if(queryParams.docType === 'HMN') {
                    	var stmtDate = new Date(endDate);
                    	stmtDate.setMonth(stmtDate.getMonth()-1);
                    	title = 'Monthly Statement for ' + $filter('date')(stmtDate, 'MMMM y');
                    } else if(queryParams.docType === 'SRM') {
                    	title = 'Quarterly Statement for ' + $filter('date')(beginDate, 'MMM y') + ' thru ' + $filter('date')(endDate, 'MMM y');
                    } else {
                    	title = 'Payment Only Statement - ' + $filter('date')(statementDate, 'MMMM y');
                    }
                    

                    return {
                        title: title,
                        deliveryDate: $filter('date')(endDate, 'MM/dd/yyyy'),
                        endDate: $filter('date')(endDate, 'yyyyMMdd'),
                        queryParams: queryParams
                    };
                });

                return documents;
            };

            _getMhsMem = function(startDate, endDate) {
                var mhsMemPromise = services.rbsmbl.statementArchiveDocument().fetch('mhsmem', startDate, endDate);

                return mhsMemPromise.then(
                    function(response) {
                        if (response && response instanceof Object && response.data && response.data instanceof Object && Array.isArray(response.data.documentData)) {
                            return _createStatementsFromMhsMemResponse(response.data.documentData);
                        }
                        else {
                            return [];
                        }
                    },
                    function(err) {
                        console.error('srmEob promise failed with error', err);
                        return mhsMemPromise;
                    }
                );
            };

            _getSrmEob = function(startDate, endDate) {
                var srmEobPromise = services.rbsmbl.statementArchiveDocument().fetch('hmn', startDate, endDate);

                return srmEobPromise.then(
                    function(response) {
                        if (response && response instanceof Object && response.data && response.data instanceof Object && Array.isArray(response.data.documentData)) {
                            return _createStatementsFromSrmEobResponse(response.data.documentData);
                        } else {
                            return [];
                        }
                    },
                    function(err) {
                        console.error('srmEob promise failed with error', err);
                        return srmEobPromise;
                    }
                );
            };

            _getUmiEob = function() {
                var umiEobPromise = services.rbsmbl.eobDocByUmi().fetch();

                return umiEobPromise.then(
                    function(response) {
                        if (response && response instanceof Object &&
                            response.data && response.data instanceof Object &&
                            response.data.repEobDocumentByUmiResp && response.data.repEobDocumentByUmiResp instanceof Object &&
                            response.data.repEobDocumentByUmiResp.payload && response.data.repEobDocumentByUmiResp.payload instanceof Object &&
                            Array.isArray(response.data.repEobDocumentByUmiResp.payload.repEobDocumentByUmiData)
                        ) {
                            return _createStatementsFromUmiEobResponse(response.data.repEobDocumentByUmiResp.payload.repEobDocumentByUmiData);
                        }
                        else {
                            return [];
                        }
                    },
                    function(err) {
                        console.error('_getUmiEob failed with error', err);
                        return umiEobPromise;
                    }
                );
            };

            getStatements = function(startDate, endDate) {
                var
                    mhsMem = _getMhsMem(startDate, endDate),
                    umiEob = _getUmiEob();

                var statementsPromise = $q.all([ umiEob, mhsMem ]);

                return statementsPromise.then(
                    function(responses) {
                        return responses.reduce(function(responseList, response) {
                            return Array.isArray(response)
                                ? responseList.concat(response)
                                : responseList;
                        }, []);
                    },

                    function(err) {
                        console.error('statementsPromise failed with error', err);
                        return statementsPromise;
                    }
                );
            };

            return {
                getStatements: getStatements
            };
        }
    ]);
};