module.exports = function(app) {
    app.factory('wellnessUtility', ['session', 'displayOrHideItemsWrapper',
        function(session, displayOrHideItemsWrapper) {

            var wellnessTargetTypes = {},
                wellnessSessionInfo = {},
                getQualifierServiceResults,
                wellnessAdamContentUrlStr,
                wellnessCategory = '',
                qualifierArray = [],
                callDisplayHide,
                sd = null,
                glbGroupNumber = null,
                glbClientNumber = null,
                theBrand = null;

            sd = session.data;
            glbGroupNumber = sd.groupNumber;

            // getting the client number this way works when user changes plan views at View Site By Plan
            if (sd.memberInfo && sd.memberInfo.clientList && Array.isArray(sd.memberInfo.clientList)) {
                var arrVsbpItems = sd.memberInfo.clientList;  // clients are what is displayed on the VSBP screen
                for (var i = 0; i < arrVsbpItems.length; i++) {
                    // each item in arrVsbpItems ^ has an array called groupList
                    var arrGroups = arrVsbpItems[i].groupList;
                    for (var j = 0; j < arrGroups.length; j++) {
                        if (arrGroups[j].groupNumber == glbGroupNumber) {
                            glbClientNumber = arrVsbpItems[i].clientNumber;
                        } } } }

            if (sd.memberInfo && sd.memberInfo.brand) {
                theBrand = sd.memberInfo.brand;
            }

            wellnessCategory = "WELLNESS";

            wellnessAdamContentUrlStr = '#/wellness/contentDetail?id=';

            wellnessSessionInfo = {
                clientNumber: glbClientNumber,
                groupNumber: glbGroupNumber,
                brand: theBrand
            };

            wellnessTargetTypes = {
                wellnessProfile: 'WELLNESS_PROFILE',
                wellnessRewards: 'WELLNESS_REWARDS',
                symptomChecker: 'WELLNESS_SYMPTOM_CHECKER',
                healthAssistant: 'WELLNESS_HEALTH_ASSISTANT',
                healthTracker: 'WELLNESS_HEALTH_TRACKER',
                personalHealthRecord: 'WELLNESS_PERSONAL_HEALTH_RECORD',
                healthTopics: 'WELLNESS_HEALTH_TOPICS',
                medEncyclopedia: 'WELLNESS_MEDICAL_ENCYCLOPEDIA',
                interactives: 'WELLNESS_INTERACTIVES',
                weighToday: 'WELLNESS_WEIGH_TODAY',
                dailyVitory:'WELLNESS_DAILY_VICTORY'
            };

            qualifierArray = [
                "eligibleForHSAIndicator",      "drugFormularyDisplay",         "pharmacyDisplayIndicator",
                "telaDocIndicator",             "isHCRPreEffectuated",          "preventiveScheduleIndicator",
                "webMDChallengesIndicator",     "webMDRewardsIndicator",        "webMDCoachingIndicator",       "highmarkBlogIndicator",
                "wellnessProfileIndicator",     "wellnessRewardIndicator",      "wellnessRewardType",           "webMDCoachingIndicator",
                "personalHealthCoachIndicator", "advanceCarePdfIndicator",      "maternityResourcesIndicator",  "pbsHealthShowIndicator",
                "wellnessProgramsIndicator",    "wiserTogetherIndicator",       "fitnessIndicator",             "incentiveFitnessIndicator",
                "nurseAdviceLineIndicator",     "employeeAssistanceIndicator",  "learnToLiveIndicator",         "chooseHealthyIndicator",
                "vacationsSavingIndicator",     "hingeHealthIndicator"
            ];
            getQualifierServiceResults = function(){
                callDisplayHide = displayOrHideItemsWrapper.displayOrHideItemsCall(glbClientNumber, glbGroupNumber, qualifierArray, theBrand);
                return callDisplayHide.then(function(theResults) {
                    return theResults.qualifierInfo;
                });
            };

            return {
                wellnessTargetTypes: wellnessTargetTypes,
                wellnessCategory: wellnessCategory,
                wellnessSessionInfo: wellnessSessionInfo,
                getQualifierServiceResults: getQualifierServiceResults,
                wellnessAdamContentUrlStr: wellnessAdamContentUrlStr
            };
        }]);
};
