module.exports = function(bootstrap) {
    bootstrap.run(['Service', function(Service) {
        new Service(
            {
                name: 'visitedMyOralHealth',
                group: 'rbsmbl',
                cache: false,
                url: '/rbsmbl/x-services/secure/oralhealthvisited',

                fetch: function(userId) {
                    return this.put({}, { userId: userId });
                }
            }
        );
    }]);
};