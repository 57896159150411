module.exports = function (app) {

    app.controller("pages.vision-provider", ["$scope", "$state", "services", "teamsite", "$q", "$sce", "sessionData", "$timeout", "invisibleFormService", "$window", "modal", function($scope, $state, services, teamsite, $q, $sce, sessionData, $timeout, invisibleFormService, $window, modal){

        $scope.headings = "Vision";
        $scope.sidebar.links = $scope.sidebar.links || [];

        $scope.sidebar.links.push({
            heading: "Vision Provider Search",            
            description: ($scope.brandData.sharedBrandCode.toLowerCase() === 'wybcbs') ? 'Search for a network eye care provider.' :'Search for the vision provider'
        });
    }]);

    return [
        {
            state: "vision-provider",
            url: "/vision-provider-search",
            views: {
                "body@": {
                    template: bootstrap.templates["page-templates/categoryCustom.html"],
                    controller: "pages.vision-provider"
                },
                "content@vision-provider":{
                    template: require("./vision-provider/vision-provider-search.html"),
                    controller: "pages.vision-provider.vision-provider-search"
                }
            }
        }
    ]
};

